import React, { Fragment } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './test.scss';

const Test = () => {
    const events = [
        {
            id: 0,
            title: 'Board meeting',
            start: new Date(2023, 11, 18, 19, 0, 0),
            end: new Date(2023, 11, 18, 20, 0, 0),
            staff_id: 1,
        },
        {
            id: 1,
            title: 'MS training',
            start: new Date(2023, 11, 18, 19, 0, 0),
            end: new Date(2023, 11, 18, 20, 0, 0),
            staff_id: 2,
        },
        {
            id: 2,
            title: 'Team lead meeting',
            start: new Date(2023, 11, 18, 19, 0, 0),
            end: new Date(2023, 11, 18, 20, 0, 0),
            staff_id: 3,
        },
        {
            id: 3,
            title: 'Birthday Party',
            start: new Date(2023, 11, 18, 20, 0, 0),
            end: new Date(2023, 11, 18, 21, 0, 0),
            staff_id: 3,
        },
    ];

    const resourceMap = [
        { staff_id: 1, newTitle: 'Staff 1' },
        { staff_id: 2, newTitle: 'Staff 2' },
        { staff_id: 3, newTitle: 'Staff 5' },
        { staff_id: 4, newTitle: 'Staff 4' },
    ];

    // Custom localizer using moment.js
    const localizer = momentLocalizer(moment);

    return (
        <Fragment>
            <Calendar
                events={events}
                startAccessor="start"
                // resourceIdAccessor="staff_id" // Updated to "staff_id"
                resources={resourceMap}
                resourceTitleAccessor="newTitle"
                resourceIdAccessor="staff_id"
                resourceAccessor={(resource) => resource.staff_id}
                endAccessor="end"
                localizer={localizer}
                view="day"
                timeslots={4}
                className="custom-calendar"
                step={15}
            />
        </Fragment>
    );
};

export default Test;
