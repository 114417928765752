import { Tooltip } from 'flowbite-react';
import { FC } from 'preact/compat';
import React, { useEffect, useState } from 'react';
import { RxInfoCircled } from 'react-icons/rx';
import { Images } from 'src/theme';
import { getShortName } from 'src/utils/global-functions';
interface AdminCardProps {
    adminDetails: any;
}
const AdminCard: FC<AdminCardProps> = ({ adminDetails }) => {
    const [servicesString, setServicesString] = useState(null);
    const [isImageError, setIsImageError] = useState<boolean>(false);

    useEffect(() => {
        const locationData = adminDetails.locations;
        const serviceNames = locationData.length > 1 ? locationData.map((entry: any) => entry.name) : null;
        const servicesString2 = serviceNames ? serviceNames.join(', ') : null;
        setServicesString(servicesString2);
    }, []);
    return (
        <div className="text-center">
            {!isImageError && adminDetails.profile_image_url ? (
                <figure className="w-[80px] h-[80px] mx-auto rounded-full bg-primary mb-4 relative">
                    <img src={adminDetails.profile_image_url} alt="" className="w-full h-full object-cover rounded-full" onError={() => setIsImageError(true)} />
                </figure>
            ) : (
                <figure className="w-[80px] h-[80px] mx-auto rounded-full bg-primary mb-4 relative">
                    <div className="text-white font-me leading-[140%] -tracking-[0.24px] rounded-full text-2xl w-full h-full flex items-center justify-center">
                        {getShortName(`${adminDetails.first_name ?? ''} ${adminDetails.last_name ?? ''}`)}
                    </div>
                    <div className="absolute bg-white right-0 bottom-0 w-6 h-6 border border-lineColor rounded-full flex items-center justify-center">
                        <img src={Images.iconWallClockOutlinePrimary} alt="" className="w-3 h-3" />
                    </div>
                </figure>
            )}

            <h3 className="text-base font-bold text-mainTextColor leading-[140%] -tracking-[0.4px] mb-1 cursor-pointer">{`${adminDetails.first_name} ${adminDetails.last_name}`}</h3>

            <p className="mb-5 text-sm font-medium text-primary leading-[140%] -tracking-[0.3px] flex justify-center items-center">
                <i className="icon mr-2 w-4 h-4">
                    <img src={Images.mapLocationPin} alt="" className="w-full" />
                </i>
                {adminDetails.locations[0].name}

                {servicesString && (
                    <Tooltip className="bg-mainTextColor text-xs " content={servicesString}>
                        <RxInfoCircled className="text-sm text-secondaryTxtColor ml-2" />
                    </Tooltip>
                )}
            </p>
        </div>
    );
};

export default AdminCard;
