import React, { useState, useEffect, useMemo } from 'react';
import Account from './Account';
import { useAppSelector } from 'src/redux/hooks';
import { selectUserInfo } from '../Auth/Login/Login.slice';
import Company from './Company/Company';
import Location from './Location/Location';
import WorkingHours from './WorkingHours/WorkingHours';
import BookingPayment from './BookingPayment/BookingPayment';
import Stepper from 'src/components/Stepper';
import { ICountStep, IStep } from 'src/components/Stepper/Stepper.interface';
import FlairPayment from './FlairPayment';
import PaymentMethod from './PaymentMethod/PaymentMethod';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'src/constants/routes';
import BarLoader from 'src/components/BarLoader';
import { useTranslation } from 'react-i18next';

const Signup = () => {
    const user = useAppSelector(selectUserInfo);
    const { t } = useTranslation();
    const signupStep = user.shops[0].account_step;
    const [step, setStep] = useState<ICountStep>(0);
    const navigate = useNavigate();
    const STEPS: IStep[] = useMemo(
        () => [
            {
                name: t('Business Account'),
                step: 1,
            },
            {
                name: t('Company Details'),
                step: 2,
            },
            {
                name: t('Location'),
                step: 3,
            },
            {
                name: t('Working Hours'),
                step: 4,
            },
            {
                name: t('Booking Payment'),
                step: 5,
            },
            {
                name: t('Stripe'),
                step: 6,
            },
            {
                name: t('Card Details'),
                step: 7,
            },
        ],
        [],
    );
    useEffect(() => {
        // Update the step when the component mounts
        if (!signupStep.complete_account) {
            setStep(1);
        } else if (!signupStep.company) {
            setStep(2);
        } else if (!signupStep.location) {
            setStep(3);
        } else if (!signupStep.working_hours) {
            setStep(4);
        } else if (!signupStep.booking_payment) {
            setStep(5);
        } else if (!signupStep.stripe_account) {
            setStep(6);
        } else if (!signupStep.payment) {
            setStep(7);
        } else {
            navigate(ROUTES.DASHBOARD);
        }
    }, []);

    const renderStepComponent = () => {
        if (step === 1) {
            return <Account setStep={setStep} />;
        } else if (step === 2) {
            return <Company setStep={setStep} />;
        } else if (step === 3) {
            return <Location setStep={setStep} />;
        } else if (step === 4) {
            return <WorkingHours setStep={setStep} />;
        } else if (step === 5) {
            return <BookingPayment setStep={setStep} />;
        } else if (step === 6) {
            return <FlairPayment setStep={setStep} />;
        } else if (step === 7) {
            return <PaymentMethod setStep={setStep} />;
        } else {
            return null;
        }
    };

    return (
        <>
            {step !== 0 ? (
                <>
                    <div className="mt-16 w-[700px] 2xl:w-[996px] h-[46px]">
                        <Stepper activeStep={step} steps={STEPS} />
                    </div>
                    {renderStepComponent()}
                </>
            ) : (
                <BarLoader />
            )}
        </>
    );
};

export default Signup;
