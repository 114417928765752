import React, { useEffect, useState } from 'react';
import UpcomingAppointment from './Tables/UpcomingAppointment';
import UpcomingAppointmentDrawer from './Drawers/UpcomingAppointment';
import StaffPerfomance from './Tables/StaffPerfomance';
import LatestBookingActivity from './Tables/LatestBookingActivity';
import TopServices from './Tables/TopServices';
import WrapperHeading from 'src/components/WrapperHeading';
import SelectBox from 'src/components/SelectBox/SelectBox';
import { Images } from 'src/theme';
import { IActionType, IDashboardDetails } from './Dashboard.Interface';
import KpiCard from 'src/components/KpiCard';
import CommissionPopup from './CommissionPopup';
import EmptyMsgWithBtn from 'src/components/EmptyMsgWithBtn';
import LatestBookingActivityDrawer from './Drawers/LatestBookingActivityDrawer';
import AmChart from '../Analytics/AmChart';
import { axiosGet } from 'src/utils/requestClient';
import { API } from 'src/constants/api';
import { errorCode } from 'src/constants/errorCode';
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { selectShopInfo, selectShopLocation } from '../Auth/Login/Login.slice';
import BarLoader from 'src/components/BarLoader';
import StaffPerfomanceDrawer from './Drawers/StaffPerfomanceDrawer';
import { formatKpiContentLable, getSelectBoxOptionsIdAsValue } from 'src/utils/global-functions';
import { ISelectedLocation } from '../Staff/Staff.interface';
import { locationList } from '../Staff/Staff.slice';
import CustomDropdownWithDate from 'src/components/CustomDropdownWithDate/CustomDropdownWithDate';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const initAction: IActionType = {
    upcomingAppointment: false,
    staffPerfomance: false,
    latestBookingActivity: false,
    topServices: false,
};
interface IPayload {
    type: string;
    location_id?: number;
    end_date?: any;
    start_date?: any;
}
interface ITimeFileterOptions {
    label: string;
    value: string;
    endDate?: any;
    startDate?: any;
}

const timeFileterOptions: ITimeFileterOptions[] = [
    { label: 'All time', value: 'all' },
    { label: 'Today', value: 'today' },
    { label: 'Yesterday', value: 'yesterday' },
    { label: 'Weekly', value: 'weekly' },
    { label: 'Monthly', value: 'monthly' },
    { label: 'Quarterly', value: 'quarterly' },
    { label: 'Last Month', value: 'last_month' },
];
const selectAllOption: ISelectedLocation = {
    value: 0,
    label: 'All locations ',
};
const Dashboard = () => {
    const { t } = useTranslation();
    const [action, setAction] = useState(initAction);
    const [dashboardDetails, setDashboardDetails] = useState<IDashboardDetails | null>(null);
    const shop: any = useAppSelector(selectShopInfo);
    const shopId = shop.id;
    const shopLocationList: any = useAppSelector(selectShopLocation);
    const [isOpenCommissionStatementPopup, setIsOpenCommissionStatementPopup] = useState<boolean>();

    const [locationOptions, setLocationOptions] = useState<any>([]);
    const [timeFileterSelected, setTimeFileterSelected] = useState(timeFileterOptions[0]);
    const [selectedLocation, setSelectedLocation] = useState<ISelectedLocation | null>(selectAllOption);

    // const getLocationOptions = async () => {
    //     const data = {
    //         shop_id: shopId,
    //     };

    //     const result = await dispatch(locationList(data));
    //     if (result.type === locationList.fulfilled.toString()) {
    //         const locationData: any = getSelectBoxOptionsIdAsValue(result.payload.data);

    //         const updatedLocationData = [selectAllOption, ...locationData];
    //         setSelectedLocation(updatedLocationData[0]);
    //         setLocationOptions(updatedLocationData);
    //     }
    // };
    useEffect(() => {
        const locationData: any = getSelectBoxOptionsIdAsValue(shopLocationList);
        const updatedLocationData = [selectAllOption, ...locationData];
        setSelectedLocation(updatedLocationData[0]);
        setLocationOptions(updatedLocationData);
    }, []);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    const handleViewClick = (drawerName: string) => async (event: { preventDefault: () => void }) => {
        setAction((prevAction) => ({
            ...initAction,
            [drawerName]: true,
        }));
        setIsDrawerOpen(true);
    };

    const createBooking = () => {};
    const getDashboard = async () => {
        try {
            let payload: IPayload = {
                type: 'all',
            };
            if (selectedLocation && selectedLocation.value !== 0) {
                payload.location_id = selectedLocation.id;
            }
            if (timeFileterSelected) {
                if (timeFileterSelected.value === 'custom') {
                    payload.start_date = moment(timeFileterSelected.startDate).format('YYYY-MM-DD');
                    payload.end_date = moment(timeFileterSelected.endDate).format('YYYY-MM-DD');
                }
                payload.type = timeFileterSelected.value;
            }
            const response = await axiosGet(
                API.DASHBOARD.GET,
                {
                    shop_id: shopId,
                },
                payload,
            );
            if (response.data?.status === errorCode.success || response.data?.status === errorCode.updateSuccess) {
                setDashboardDetails(response.data.data);
                return;
            }

            throw response.data;
        } catch (err: any) {
            toast.error(err?.message);
        } finally {
        }
    };
    // useEffect(() => {
    //     getLocationOptions();
    // }, []);
    useEffect(() => {
        getDashboard();
    }, [selectedLocation, timeFileterSelected]);

    return (
        <>
            {dashboardDetails ? (
                <>
                    <div className="flex items-center mb-8">
                        <WrapperHeading content={t('Dashboard')} />
                        <div className="ml-auto flex -mx-2">
                            <div className="px-2">
                                <div className="w-[290px] ">
                                    <SelectBox
                                        options={locationOptions}
                                        classComp="outline-select-box bg-white "
                                        value={selectedLocation}
                                        onChangeFunc={(e: any) => {
                                            setSelectedLocation(e);
                                        }}
                                        openOnFocus={true}
                                        autofocus={true}
                                        allowIcon={<img className="me-1" src={Images.mapLocationPin} alt="map-pin" />}
                                    />
                                </div>
                            </div>
                            <div className="px-2 ">
                                <div className="w-[290px]">
                                    <CustomDropdownWithDate
                                        data={timeFileterOptions}
                                        handleChangeValue={(e: any) => {
                                            setTimeFileterSelected(e);
                                        }}
                                        value={timeFileterSelected}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mb-3 flex -mx-[6px]">
                        <div className="px-[6px] w-[20%]">
                            <div className="bg-white rounded-3xl border border-lineColor p-4">
                                <KpiCard
                                    headerIcon={Images.IconMoney}
                                    headerTitle={t('Total Sales')}
                                    contentLable={`$${formatKpiContentLable(dashboardDetails?.total?.amount)}`}
                                    growth={dashboardDetails?.total?.amount_growth}
                                    fromDate={dashboardDetails?.from_date}
                                />
                            </div>
                        </div>
                        <div className="px-[6px] w-[20%]">
                            <div className="bg-white rounded-3xl border border-lineColor p-4">
                                <KpiCard
                                    headerIcon={Images.IconProfit}
                                    headerTitle={t('Total Profit')}
                                    contentLable={`$${formatKpiContentLable(dashboardDetails?.total?.profit)}`}
                                    growth={dashboardDetails?.total?.profit_growth}
                                    fromDate={dashboardDetails?.from_date}
                                />
                            </div>
                        </div>
                        <div className="px-[6px] w-[20%]">
                            <div className="bg-white rounded-3xl border border-lineColor p-4">
                                <KpiCard
                                    headerIcon={Images.IconCalendar}
                                    headerTitle={t('Total Bookings')}
                                    contentLable={`${dashboardDetails?.total?.bookings}`}
                                    contentDescription="bookings"
                                    growth={dashboardDetails?.total?.bookings_growth}
                                    fromDate={dashboardDetails?.from_date}
                                />
                            </div>
                        </div>
                        <div className="px-[6px] w-[20%]">
                            <div className="bg-white rounded-3xl border border-lineColor p-4">
                                <KpiCard
                                    headerIcon={Images.IconProductivity}
                                    headerTitle={t('Productivity')}
                                    contentLable={`${dashboardDetails?.total?.productivity}%`}
                                    growth={dashboardDetails?.total?.productivity_growth}
                                    fromDate={dashboardDetails?.from_date}
                                />
                            </div>
                        </div>
                        <div className="px-[6px] w-[20%]">
                            <div className="bg-white rounded-3xl border border-lineColor p-4">
                                <KpiCard
                                    headerIcon={Images.IconRefreshUser}
                                    headerTitle={t('Retention Rate')}
                                    contentLable={`${dashboardDetails?.total?.retention_rate}%`}
                                    growth={dashboardDetails?.total?.retention_rate_growth}
                                    fromDate={dashboardDetails?.from_date}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="bg-white rounded-3xl border border-lineColor  mb-3 p-4">
                        <h3 className="text-xl font-bold -tracking-[0.4px]">{t('Sales Analytics')}</h3>
                        {dashboardDetails.sales_line_graph.length > 0 ? (
                            <AmChart salesLineGraph={dashboardDetails.sales_line_graph} />
                        ) : (
                            <EmptyMsgWithBtn title={t('No Sales Data')} description={t('No_Sales_Data_description')} btnLabel={t('Create booking')} onClick={createBooking} />
                        )}

                        {/* <AmChartPie /> */}
                        {/* <Chart /> */}
                    </div>

                    {/* <button onClick={handleViewClick('UpcomingAppointment')}>
                view all
            </button> */}

                    {/* <button onClick={handleViewClick('StaffPerfomance')}>
                view all2
            </button> */}
                    <div className="-mx-[6px] flex mb-3">
                        <div className="px-[6px] w-7/12">
                            <UpcomingAppointment handleViewClick={handleViewClick} upcomingData={dashboardDetails.upcoming} />
                        </div>
                        <div className="px-[6px] w-5/12">
                            <StaffPerfomance staffData={dashboardDetails.staff} handleViewClick={handleViewClick} />
                        </div>
                    </div>
                    <div className="-mx-[6px] flex mb-3">
                        <div className="px-[6px] w-7/12">
                            <LatestBookingActivity handleViewClick={handleViewClick} latestData={dashboardDetails.past} />
                        </div>
                        <div className="px-[6px] w-5/12">
                            <TopServices services={dashboardDetails.services} handleViewClick={handleViewClick} timeFileterSelected={timeFileterSelected} />
                        </div>
                    </div>

                    {action.upcomingAppointment && <UpcomingAppointmentDrawer isOpen={isDrawerOpen} handleClose={() => setIsDrawerOpen(false)} />}
                    {action.latestBookingActivity && <LatestBookingActivityDrawer isOpen={isDrawerOpen} handleClose={() => setIsDrawerOpen(false)} />}
                    {action.staffPerfomance && <StaffPerfomanceDrawer isOpen={isDrawerOpen} handleClose={() => setIsDrawerOpen(false)} />}

                    <CommissionPopup
                        isOpenPopup={isOpenCommissionStatementPopup}
                        handleClose={() => {
                            setIsOpenCommissionStatementPopup(false);
                        }}
                    />
                </>
            ) : (
                <BarLoader />
            )}
        </>
    );
};

export default Dashboard;
