import React from 'react';
import { MdAccessTime } from 'react-icons/md';
import { IoMdCloseCircle } from 'react-icons/io';
import { FaArrowsRotate, FaCircleCheck, FaClock } from 'react-icons/fa6';
import moment from 'moment';
import { usePhone } from 'src/hooks/usePhone';

const EventCard = ({ eventInfo }: any) => {
    const eventDetails = eventInfo.event;
    const currentDateTime = moment();
    const bookingStartDateTime = moment(`${eventDetails.booking_date} ${eventDetails.booking_start_time}`, 'YYYY-MM-DD HH:mm:ss');
    const bookingEndDateTime = moment(`${eventDetails.booking_date} ${eventDetails.booking_end_time}`, 'YYYY-MM-DD HH:mm:ss');
    const slotTimeInfo = `${bookingStartDateTime.format('hh:mm A')}-` + `${bookingEndDateTime.format('hh:mm A')}`;
    const { getCustomFormatPhone } = usePhone();

    // Check if the current time is between the booking start and end times
    const eventCurrentDateTime = currentDateTime.isBetween(bookingStartDateTime, bookingEndDateTime);

    if (eventDetails.status === 'new') {
        return (
            <div className="event-container-wrap  h-full ">
                <div className="rounded-[4px] pt-2 px-1 pb-1 text-[10px] relative flex flex-col h-full">
                    <p className="font-bold leading-[140%] -tracking-[0.1px] mb-[6px]">Add new</p>
                </div>
            </div>
        );
    }
    if (eventDetails.status === 'block_time') {
        return (
            <div className="event-container-wrap  h-full ">
                <div className="rounded-[4px] pt-2 px-1 pb-1 text-[10px] relative flex flex-col h-full">
                    <p className="font-bold leading-[140%] -tracking-[0.1px] mb-[6px]">Block Time</p>
                    <p className="font-bold leading-[140%] -tracking-[0.1px] mb-[6px]">{eventDetails.name}</p>
                </div>
            </div>
        );
    }
    return (
        <div className={`event-container-wrap  h-full  event-${eventCurrentDateTime && eventDetails.status === 'confirmed' ? 'current' : eventDetails.status}`}>
            <div className="rounded-[4px] py-2 px-1  text-[10px] relative flex flex-col h-full">
                <p className="font-bold leading-[140%] -tracking-[0.1px] mb-[4px]">
                    {eventDetails.user.first_name && eventDetails.user.last_name
                        ? `${eventDetails.user.first_name} ${eventDetails.user.last_name}`
                        : getCustomFormatPhone(eventDetails.user?.phone, eventDetails.user?.phone_country_code)}
                </p>
                <div className="flex-1">
                    {/* <p>{eventDetails.status}</p> */}
                    <p className="leading-[150%] -tracking-[0.12px] mb-0">{`${eventDetails.booking_services[0].service.name}`}</p>
                    <p className="text-xs leading-[150%] -tracking-[0.12px]">{`$${eventDetails.total}`}</p>
                </div>
                <p className="text-[8px] font-medium leading-[150%] -tracking-[0.08px]">{`${slotTimeInfo}`}</p>
                <i className="absolute right-1 top-2 text-sm">
                    {eventCurrentDateTime && eventDetails.status === 'confirmed' ? (
                        <></>
                    ) : eventDetails.status === 'pending' ? (
                        <MdAccessTime className="text-dangerText" />
                    ) : eventDetails.status === 'canceled' ? (
                        <IoMdCloseCircle className="text-dangerText" />
                    ) : eventDetails.status === 'confirmed' ? (
                        <FaClock className="text-primary" />
                    ) : // ...
                    eventDetails.status === 'completed' ? (
                        <FaCircleCheck className="text-green" />
                    ) : eventDetails.status === 'no_show' ? (
                        <FaArrowsRotate className="text-dangerText" />
                    ) : // ...
                    eventDetails.status === 'declined' ? (
                        <FaArrowsRotate className="text-primary" />
                    ) : eventDetails.status === 'request_canceled' ? (
                        <FaArrowsRotate className="text-primary" />
                    ) : (
                        <FaArrowsRotate className="text-primary" />
                    )}
                </i>
            </div>
        </div>
    );
};

export default EventCard;
