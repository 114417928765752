import React from 'react';

import HeaderBar from './HeaderBar';
import SidebarNav from './SidebarNav';

const DashboardLayout = ({ children }: any) => (
    <div className="dashbaord_wrapper flex bg-baseBg w-full min-h-full">
        <HeaderBar />
        <SidebarNav />
        <div className="dashboard_content_wrapper">
            <div className="dashboard_content">{children}</div>
        </div>
    </div>
);

export default DashboardLayout;
