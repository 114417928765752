import React, { FC } from 'react';
import { Controller } from 'react-hook-form';

import SelectBox from 'src/components/SelectBox/SelectBox';
import { ICommossioFrequency } from './MyAccount.interface';

const CommossioFrequency: FC<ICommossioFrequency> = ({ control, errors, frequencyVal }) => {
    const commissionFrequencyOption = [
        {
            value: 'weekly',
            label: 'Weekly',
        },
        {
            value: 'bi_weekly',
            label: 'Bi-Weekly',
        },

        {
            value: 'monthly',
            label: 'Monthly',
        },
    ];
    const paymentDayOption = [
        {
            value: 'sunday',
            label: 'Sunday',
        },
        {
            value: 'monday',
            label: 'Monday',
        },
        {
            value: 'tuesday',
            label: 'Tuesday',
        },
        {
            value: 'wednesday',
            label: 'Wednesday',
        },
        {
            value: 'thursday',
            label: 'Thursday',
        },
        {
            value: 'friday',
            label: 'Friday',
        },
        {
            value: 'saturday',
            label: 'Saturday',
        },
    ];
    return (
        <>
            <Controller
                name="frequency"
                control={control}
                render={({ field: { onChange, value } }: any) => (
                    <div className="mb-5">
                        <SelectBox
                            name="frequency"
                            label="Frequency"
                            options={commissionFrequencyOption}
                            onChangeFunc={(selectedOption: any) => {
                                onChange(selectedOption.value);
                            }}
                            value={commissionFrequencyOption.find((option) => option.value === value)}
                            placeholder="No frequency selected"
                        />
                        {errors?.frequency && <p className="text-error">{errors?.frequency?.message}</p>}
                    </div>
                )}
            />
            {(frequencyVal === 'weekly' || frequencyVal === 'bi_weekly') && (
                <Controller
                    name="commission_payment_day"
                    control={control}
                    render={({ field: { onChange, value } }: any) => (
                        <>
                            <SelectBox
                                name="commission_payment_day"
                                label="Payment day"
                                options={paymentDayOption}
                                onChangeFunc={(selectedOption: any) => {
                                    onChange(selectedOption.value);
                                }}
                                value={paymentDayOption.find((option) => option.value === value)}
                                labelClassname=""
                                placeholder="No Payment day selected"
                            />
                            {errors?.commission_payment_day && <p className="text-error">{errors?.commission_payment_day?.message}</p>}
                        </>
                    )}
                />
            )}
        </>
    );
};

export default CommossioFrequency;
