import AWS from 'aws-sdk';

export const s3Upload = (fileObj: any, path: any) =>
    new Promise((resolve) => {
        // const fileName = `${new Date().getTime().toString()}.${fileObj.name
        //     .split('.')
        //     .pop()}`;

        AWS.config.update({
            accessKeyId: process.env.REACT_APP_ACCESS_ID,
            secretAccessKey: process.env.REACT_APP_ACCESS_KEY,
            region: process.env.REACT_APP_REGION,
        });
        const s3 = new AWS.S3();

        const params = {
            Bucket: 'flairmedia',
            Key: `${path}`,
            Body: fileObj,
            ContentType: fileObj.type,
        };

        const options = { partSize: 500 * 1024 * 1024, queueSize: 1 };

        s3.upload(params, options, (err, data) => {
            if (err) {
                resolve({ status: 401 });
            }
            if (data) {
                resolve({ status: 201, data });
            } else {
                resolve({ status: 401 });
            }
        });
    });
