import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { API } from 'src/constants/api';
import { axiosPost } from 'src/utils/requestClient';
import { IInitialState } from './NewPassword.interface';
import { errorCode } from 'src/constants/errorCode';
import { RootState } from 'src/redux/store';

interface ErrorType {
    message: string;
    status?: string;
    data?: any;
}

const initialState: IInitialState = {
    loading: false,
    data: null,
    error: null,
};

export const newPassword = createAsyncThunk('shop/password', async (payload: any, { rejectWithValue }) => {
    try {
        const response = await axiosPost(API.USER.NEW_PASSWORD, payload);
        if (response.data.status === errorCode.success) {
            return response.data;
        }
        return rejectWithValue({
            data: response,
        } as any);
    } catch (err: any) {
        if (!err.response) {
            throw err;
        }

        return rejectWithValue({
            message: err.response.data,
        } as any);
    }
});

export const NewPasswordSlice = createSlice({
    name: 'newPassword',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(newPassword.pending, (state) => {
                state.loading = true;
            })
            .addCase(newPassword.fulfilled, (state, { payload }: any) => {
                state.loading = false;
                state.data = payload;
            })
            .addCase(newPassword.rejected, (state, { payload, error }: any) => {
                state.loading = false;
                state.data = payload;
                if (payload) {
                    state.error = payload;
                } else {
                    state.error = error as ErrorType;
                }
            });
    },
});

export const selectPasswordInfo = (state: RootState) => state.NewPassword.data;
export const selectLoading = (state: RootState) => state.NewPassword.loading;
export const selectError = (state: RootState) => state.NewPassword.error;
