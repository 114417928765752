/* eslint-disable max-lines-per-function */
import React, { useState } from 'react';
import { Checkbox, Label, Radio } from 'flowbite-react';
import { Controller, useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const OpeningHour: React.FC<any> = ({ errors, control, setValue, setError, resetField, isDisabled = false, extraClassName = '' }) => {
    const formContext = useFormContext();
    const { t } = useTranslation();

    errors = errors || formContext?.formState?.errors;
    control = control || formContext?.control;
    setValue = setValue || formContext?.setValue;

    const { fields } = useFieldArray({
        control,
        keyName: 'uuid',
        name: 'hours',
    });
    const valueWatch = useWatch({
        name: 'hours',
        control,
    });

    function capitalizeFirstLetter(str: string) {
        return str.length > 0 ? str[0].toUpperCase() + str.slice(1) : str;
    }
    const [lastUpdatedWorkingHours, setLastUpdatedWorkingHours] = useState({
        from_time_hours: '',
        from_time_minute: '00',
        to_time_hours: '',
        to_time_minute: '00',
        from_time_type: 'am',
        to_time_type: 'pm',
    });

    const handleTimeTypeChange = (onChange: any, field: string) => (event: any) => {
        const selectedTimeType = event.target.value;
        onChange(event.target.value);
        if (field === 'from') {
            setLastUpdatedWorkingHours((prevWorkingHours) => ({
                ...prevWorkingHours,
                from_time_type: selectedTimeType,
            }));
        } else {
            setLastUpdatedWorkingHours((prevWorkingHours) => ({
                ...prevWorkingHours,
                to_time_type: selectedTimeType,
            }));
        }
    };
    return (
        // opening-hours-small
        // opening-hours-xsmall
        <div className={`${extraClassName} opening-hours-block`}>
            {fields.map((item: any, index) => (
                <div className={`opening-hours-row ${fields?.length === index + 1 ? 'pb-0 border-0' : null} `} key={item.uuid}>
                    <div className="flex items-center day-checkbox">
                        <Controller
                            key={`hours.[${index}].status`}
                            name={`hours.[${index}].status`}
                            control={control}
                            render={({ field: { onChange, value } }: any) => (
                                <>
                                    <Checkbox
                                        className="day-check-box"
                                        id={`day_${item.day}`}
                                        name={`hours.[${index}].status`}
                                        value={value}
                                        disabled={isDisabled}
                                        onChange={(e) => {
                                            if (e.target.checked) {
                                                setValue(`hours[${index}].from_time_hours`, lastUpdatedWorkingHours.from_time_hours);
                                                setValue(`hours[${index}].from_time_minute`, lastUpdatedWorkingHours.from_time_minute);
                                                setValue(`hours[${index}].to_time_hours`, lastUpdatedWorkingHours.to_time_hours);
                                                setValue(`hours[${index}].to_time_minute`, lastUpdatedWorkingHours.to_time_minute);
                                                setValue(`hours[${index}].from_time_type`, lastUpdatedWorkingHours.from_time_type);
                                                setValue(`hours[${index}].to_time_type`, lastUpdatedWorkingHours.to_time_type);
                                            } else {
                                                setValue(`hours[${index}].from_time_hours`, '00');
                                                setValue(`hours[${index}].from_time_minute`, '00');
                                                setValue(`hours[${index}].to_time_hours`, '00');
                                                setValue(`hours[${index}].to_time_minute`, '00');
                                                setValue(`hours[${index}].from_time_type`, 'am');
                                                setValue(`hours[${index}].to_time_type`, 'pm');
                                            }
                                            onChange(e);
                                        }}
                                        checked={value}
                                    />
                                    <Label className="day-checkbox-label" htmlFor={`day_${item.day}`}>
                                        {capitalizeFirstLetter(item.day)}
                                    </Label>

                                    {errors.start_time_hours && errors.start_time_hours.message && (
                                        <>
                                            <p className="text-error">{errors.start_time_hours.message}</p>
                                        </>
                                    )}
                                </>
                            )}
                        />
                    </div>
                    {valueWatch?.[index]?.status ? (
                        <div className="flex flex-col">
                            <div className="opening-hour-right ">
                                <span className="label-from">From</span>
                                <div className={`time-input-block ${errors.hours && errors.hours[index] && errors.hours[index].from_time_hours && 'is-invalid'}`}>
                                    <Controller
                                        name={`hours.[${index}].from_time_hours`}
                                        control={control}
                                        render={({ field: { onChange, value } }: any) => (
                                            <>
                                                <input
                                                    className="input-control border-r border-lineColor "
                                                    placeholder="00"
                                                    value={value}
                                                    maxLength={2}
                                                    minLength={2}
                                                    disabled={isDisabled}
                                                    onChange={(e) => {
                                                        const enteredValue = e.target.value;

                                                        if (!isNaN(Number(enteredValue))) {
                                                            let stringValue = String(enteredValue);
                                                            if (stringValue.length === 1 && Number(stringValue) > 1 && Number(stringValue) <= 9) {
                                                                stringValue = `0${stringValue}`;
                                                            }
                                                            if (stringValue <= '12') {
                                                                onChange({
                                                                    target: {
                                                                        value: stringValue,
                                                                    },
                                                                });
                                                                setLastUpdatedWorkingHours((prevWorkingHours) => ({
                                                                    ...prevWorkingHours,
                                                                    from_time_hours: stringValue,
                                                                }));
                                                            } else {
                                                                /* console.error(
                                                                        'Invalid input. Please enter a number between 00 and 12.',
                                                                    ); */
                                                            }
                                                        } else {
                                                            /* console.error(
                                                                    'Invalid input. Please enter a numeric value between 00 and 12.',
                                                                ); */
                                                        }
                                                    }}
                                                    name={`hours.[${index}].from_time_hours`}
                                                />
                                            </>
                                        )}
                                    />
                                    <Controller
                                        control={control}
                                        name={`hours.[${index}].from_time_minute`}
                                        render={({ field: { onChange, value } }: any) => (
                                            <>
                                                <input
                                                    className="input-control "
                                                    placeholder="00"
                                                    maxLength={2}
                                                    disabled={isDisabled}
                                                    minLength={2}
                                                    onChange={(e) => {
                                                        const enteredValue = e.target.value;
                                                        if (!isNaN(Number(enteredValue))) {
                                                            const numericValue = Number(enteredValue);
                                                            let stringValue = String(enteredValue);
                                                            if (stringValue.length === 1 && numericValue >= 6 && numericValue <= 9) {
                                                                stringValue = `0${stringValue}`;
                                                            }

                                                            if (numericValue <= 59) {
                                                                onChange({
                                                                    target: {
                                                                        value: stringValue,
                                                                    },
                                                                });
                                                                setLastUpdatedWorkingHours((prevWorkingHours) => ({
                                                                    ...prevWorkingHours,
                                                                    from_time_minute: stringValue,
                                                                }));
                                                            } else {
                                                                /* console.error(
                                                                        'Invalid input. Please enter a number between 00 and 59.',
                                                                    ); */
                                                            }
                                                        } else {
                                                            /* console.error(
                                                                    'Invalid input. Please enter a numeric value between 00 and 59.',
                                                                ); */
                                                        }
                                                    }}
                                                    onBlur={(e) => {
                                                        const enteredValue = e.target.value;
                                                        if (enteredValue.length === 1) {
                                                            onChange({
                                                                target: {
                                                                    value: `0${enteredValue}`,
                                                                },
                                                            });
                                                        }
                                                    }}
                                                    value={value}
                                                    name={`hours.[${index}].from_time_minute`}
                                                />
                                            </>
                                        )}
                                    />
                                </div>
                                <div className="custom-square-radio-block ">
                                    <Controller
                                        name={`hours.[${index}].from_time_type`}
                                        control={control}
                                        defaultValue={'am'}
                                        render={({ field: { onChange, value } }: any) => (
                                            <>
                                                <Radio
                                                    className="hidden custom-radio radio-am"
                                                    id={`from_am_${item.day}`}
                                                    name={`hours.[${index}].from_time_type`}
                                                    value="am"
                                                    onChange={handleTimeTypeChange(onChange, 'from')}
                                                    disabled={isDisabled}
                                                    checked={value === 'am'}
                                                />
                                                <Label className="custome-label" htmlFor={`from_am_${item.day}`}>
                                                    {t('AM')}
                                                </Label>
                                                <Radio
                                                    className="hidden custom-radio radio-pm"
                                                    id={`from_pm${item.day}`}
                                                    name={`hours.[${index}].from_time_type`}
                                                    value="pm"
                                                    disabled={isDisabled}
                                                    // onChange={onChange}
                                                    checked={value === 'pm'}
                                                    onChange={handleTimeTypeChange(onChange, 'from')}
                                                />
                                                <Label className="custome-label" htmlFor={`from_pm${item.day}`}>
                                                    {t('PM')}
                                                </Label>
                                                <span className="switch-base"></span>

                                                {errors.from_time_type && errors.from_time_type.message && (
                                                    <>
                                                        <p className="text-error">{errors.from_time_type.message}</p>
                                                    </>
                                                )}
                                            </>
                                        )}
                                    />
                                </div>
                                <span className="label-to">to</span>
                                <div className={`time-input-block ${errors.hours && errors.hours[index] && errors.hours[index].to_time_hours && 'is-invalid'}`}>
                                    <Controller
                                        control={control}
                                        name={`hours.[${index}].to_time_hours`}
                                        render={({ field: { onChange, value } }: any) => (
                                            <>
                                                <input
                                                    className="input-control border-r border-lineColor"
                                                    placeholder="00"
                                                    value={value}
                                                    maxLength={2}
                                                    disabled={isDisabled}
                                                    minLength={2}
                                                    onChange={(e) => {
                                                        const enteredValue = e.target.value;
                                                        if (!isNaN(Number(enteredValue))) {
                                                            let stringValue = String(enteredValue);
                                                            if (stringValue.length === 1 && Number(stringValue) > 1 && Number(stringValue) <= 9) {
                                                                stringValue = `0${stringValue}`;
                                                            }
                                                            if (stringValue <= '12') {
                                                                onChange({
                                                                    target: {
                                                                        value: stringValue,
                                                                    },
                                                                });
                                                                setLastUpdatedWorkingHours((prevWorkingHours) => ({
                                                                    ...prevWorkingHours,
                                                                    to_time_hours: stringValue,
                                                                }));
                                                                // onChange(e);
                                                            } else {
                                                                /* console.error(
                                                                        'Invalid input. Please enter a number between 00 and 12.',
                                                                    ); */
                                                            }
                                                        } else {
                                                            /* console.error(
                                                                    'Invalid input. Please enter a numeric value between 00 and 12.',
                                                                ); */
                                                        }
                                                    }}
                                                    name={`hours.[${index}].to_time_hours`}
                                                />
                                            </>
                                        )}
                                    />
                                    <Controller
                                        name={`hours.[${index}].to_time_minute`}
                                        control={control}
                                        render={({ field: { onChange, value } }: any) => (
                                            <>
                                                <input
                                                    className="input-control "
                                                    placeholder="00"
                                                    maxLength={2}
                                                    minLength={2}
                                                    disabled={isDisabled}
                                                    value={value}
                                                    onChange={(e) => {
                                                        const enteredValue = e.target.value;
                                                        if (!isNaN(Number(enteredValue))) {
                                                            const numericValue = Number(enteredValue);

                                                            let stringValue = String(enteredValue);
                                                            if (stringValue.length === 1 && numericValue >= 6 && numericValue <= 9) {
                                                                stringValue = `0${stringValue}`;
                                                            }
                                                            if (numericValue <= 59) {
                                                                onChange({
                                                                    target: {
                                                                        value: stringValue,
                                                                    },
                                                                });
                                                                setLastUpdatedWorkingHours((prevWorkingHours) => ({
                                                                    ...prevWorkingHours,
                                                                    to_time_minute: stringValue,
                                                                }));
                                                            } else {
                                                                /* console.error(
                                                                        'Invalid input. Please enter a number between 00 and 59.',
                                                                    ); */
                                                            }
                                                        } else {
                                                            /* console.error(
                                                                    'Invalid input. Please enter a numeric value between 00 and 59.',
                                                                ); */
                                                        }
                                                    }}
                                                    onBlur={(e) => {
                                                        const enteredValue = e.target.value;
                                                        if (enteredValue.length === 1) {
                                                            onChange({
                                                                target: {
                                                                    value: `0${enteredValue}`,
                                                                },
                                                            });
                                                        }
                                                    }}
                                                    name={`hours.[${index}].to_time_minute`}
                                                />
                                            </>
                                        )}
                                    />
                                </div>
                                <div className="custom-square-radio-block">
                                    <Controller
                                        name={`hours.[${index}].to_time_type`}
                                        control={control}
                                        defaultValue={'am'}
                                        render={({ field: { onChange, value } }: any) => (
                                            <>
                                                <Radio
                                                    className="hidden custom-radio radio-am"
                                                    id={`to_am_${item.day}`}
                                                    name={`hours.[${index}].to_time_type`}
                                                    value="am"
                                                    disabled={isDisabled}
                                                    onChange={handleTimeTypeChange(onChange, 'to')}
                                                    checked={value === 'am'}
                                                />
                                                <Label className="custome-label" htmlFor={`to_am_${item.day}`}>
                                                    {t('AM')}
                                                </Label>
                                                <Radio
                                                    className="hidden custom-radio radio-pm"
                                                    id={`to_pm_${item.day}`}
                                                    name={`hours.[${index}].to_time_type`}
                                                    value="pm"
                                                    disabled={isDisabled}
                                                    // onChange={onChange}
                                                    onChange={handleTimeTypeChange(onChange, 'to')}
                                                    checked={value === 'pm'}
                                                />
                                                <Label className="custome-label" htmlFor={`to_pm_${item.day}`}>
                                                    {t('PM')}
                                                </Label>
                                                <span className="switch-base"></span>
                                            </>
                                        )}
                                    />
                                </div>
                            </div>
                            {errors.hours && errors.hours[index] ? (
                                errors.hours[index].from_time_hours && errors.hours[index].from_time_hours.message ? (
                                    <p className="text-error">{errors.hours[index].from_time_hours.message}</p>
                                ) : errors.hours[index].from_time_minute && errors.hours[index].from_time_minute.message ? (
                                    <p className="text-error">{errors.hours[index].from_time_minute.message}</p>
                                ) : errors.hours[index].to_time_hours && errors.hours[index].to_time_hours.message ? (
                                    <p className="text-error">{errors.hours[index].to_time_hours.message}</p>
                                ) : (
                                    errors.hours[index].to_time_minute && errors.hours[index].to_time_minute.message && <p className="text-error">{errors.hours[index].to_time_minute.message}</p>
                                )
                            ) : null}
                        </div>
                    ) : (
                        <div className="flex items-center flex-1">
                            <span className="pl-2">{t('Not working on this day')}</span>
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
};

export default OpeningHour;
