import { ComponentType } from 'react';
import { ROUTES } from './routes';

export const APP_FULL_NAME: string = process.env.REACT_APP_FULL_NAME as string;
export const REGION_CODE: any = process.env.REACT_APP_REGION_CODE;
export const TIMEZONE: any = process.env.REACT_APP_TIMEZONE;

export const DATE_FORMAT: string = 'YYYY-MM-DD';
export const DATE_TIME_FORMAT: string = 'YYYY-MM-DD hh:mm:ss';
export const TIME_FORMAT: string = 'hh:mm:ss';

export enum ERouteType {
    GUARDED = 'GUARDED',
    NOT_GUARDED = 'NOT_GUARDED',
    DEFAULT = 'DEFAULT',
}

export enum LayoutAction {
    NonLayout = 'NonLayout',
    Simple = 'SimpleLayout',
    Dashboard = 'DashboardLayout',
}

export type RouteItem = {
    type: ERouteType;
    path: string;
    component: ComponentType;
    title?: string;
    layout?: LayoutAction;
    shop_details?: boolean;
    view?: string;
    permission?: string;
};

export const somethingWentWrongError = 'We are sorry, but something went wrong. Please try again later.';

export const errorCodeWithMessages: { [key: number]: string } = {
    400: 'page not found',
    401: 'Unauthorized',
    403: 'Forbidden',
    422: 'Unprocessable Content',
    500: somethingWentWrongError,
};

export const signupStepAndRoutes = [
    {
        step: 'complete_account',
        route: ROUTES.ACCOUNT_STEP.COMPLETE_ACCOUNT,
        order: 1,
    },
    { step: 'company', route: ROUTES.ACCOUNT_STEP.COMPANY, order: 2 },
    { step: 'location', route: ROUTES.ACCOUNT_STEP.LOCATION, order: 3 },
    {
        step: 'working_hours',
        route: ROUTES.ACCOUNT_STEP.WORKING_HOURS,
        order: 4,
    },
    {
        step: 'booking_payment',
        route: ROUTES.ACCOUNT_STEP.BOOKING_PAYMENT,
        order: 5,
    },
    {
        step: 'stripe_account',
        route: ROUTES.ACCOUNT_STEP.STRIPE_ACCOUNT,
        order: 6,
    },
];
