import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { ROUTES } from 'src/constants/routes';

const Footer = () => {
    const { t } = useTranslation();
    return (
        <footer className="flex py-6 px-10 justify-between">
            <span className="text-secondaryTxtColor text-xs font-normal leading-4 -tracking-[0.12px]">@ {new Date().getFullYear()} flair.io</span>
            <div className="flex gap-6 leading-4">
                <NavLink to={ROUTES.HOME} className="text-xs font-normal text-primary -tracking-[0.12px]">
                    {t('Terms & Conditions')}
                </NavLink>
                <NavLink to={ROUTES.HOME} className="text-xs font-normal text-primary -tracking-[0.12px]">
                    Privacy Policy
                </NavLink>
            </div>
        </footer>
    );
};

export default Footer;
