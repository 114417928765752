import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { API } from 'src/constants/api';
import { axiosPost } from 'src/utils/requestClient';
import { ErrorType, IInitialState } from './Account.interface';
import { RootState } from 'src/redux/store';
import { errorCode } from 'src/constants/errorCode';

const initialState: IInitialState = {
    data: null,
    loading: false,
    error: null,
};

export const account = createAsyncThunk('/shop/account', async (payload: any, { rejectWithValue }) => {
    try {
        const response = await axiosPost(API.USER.COMPLETE_ACCOUNT, payload);
        if (response.data.status === errorCode.success || response.data.status === errorCode.updateSuccess) {
            return response.data;
        }

        return rejectWithValue(response as ErrorType);
    } catch (err: any) {
        if (!err.response) {
            throw err;
        }
        return rejectWithValue(err.response.data as ErrorType);
    }
});

export const accountSlice = createSlice({
    name: 'shop/details',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(account.pending, (state) => {
                state.loading = true;
            })
            .addCase(account.fulfilled, (state, { payload }: any) => {
                state.loading = false;
                state.data = {
                    ...state.data,
                    first_name: payload.data.first_name,
                    last_name: payload.data.last_name,
                    profile_image_name: payload.data.profile_image_name,
                };
            })
            .addCase(account.rejected, (state, action: any) => {
                state.loading = false;
            });
    },
});
export const { reducer: accountReducer } = accountSlice;

export const selectAccountData = (state: RootState) => state.AccountDetails.data;
export const selectAccountLoading = (state: RootState) => state.AccountDetails.loading;
export const selectAccountError = (state: RootState) => state.AccountDetails.error;
