import React, { FC, useEffect, useState } from 'react';
import { IActionType, initAction } from './WaitList.interface';
import List from './List';
import Form from './Form';
import Default from './Default';

const Waitlist: FC<any> = () => {
    const [action, setAction] = useState<IActionType>(initAction);

    const handleAction = (type: string) => {
        console.log('type', type);

        setAction({ ...initAction, [type]: true });
    };

    return (
        <>
            {action.list ? (
                <List handleAction={handleAction} />
            ) : action.create ? (
                <Form handleAction={handleAction} />
            ) : action.default ? (
                <Default handleAction={handleAction} />
            ) : (
                <Default handleAction={handleAction} />
            )}
        </>
    );
};

export default Waitlist;
