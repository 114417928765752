import React, { useEffect, useState } from 'react';
import Tabs from './Tabs';
import WrapperHeading from 'src/components/WrapperHeading';
import { useLocation } from 'react-router-dom';

const Payment = () => {
    const tabData = [
        {
            name: 'My Account',
            data: {},
        },
        {
            name: 'Commision Payouts',
            data: {},
        },
        {
            name: 'Rent Collection',
            data: {},
        },
        {
            name: 'Transactions History',
            data: {},
        },
        // {
        //     name: 'Cash Register',
        //     data: {},
        // },
        {
            name: 'My Plan',
            data: {},
        },
    ];

    const location = useLocation();
    const [activeTab, setActiveTab] = useState(tabData[0]);
    const [locationState, setLocationState] = useState(location.state ?? null);

    useEffect(() => {
        if (locationState) {
            const navigateTabData = tabData.find((tab) => tab.name === locationState);
            if (navigateTabData) {
                setActiveTab(navigateTabData);
            }
        }
        return () => {
            setLocationState(null);
        };
    }, [locationState]);

    const handleTabClick = (tab: any) => {
        setActiveTab(tab);
    };

    return (
        <div className="inner-page-wrape">
            <WrapperHeading content="Payment" />
            <div className="fl-tab-underline w-full my-8">
                {tabData.map((tab, index) => (
                    <button type="button" key={index} className={`fl-tab-link ${activeTab.name === tab.name ? 'active' : ''}`} onClick={() => handleTabClick(tab)}>
                        {tab.name}
                    </button>
                ))}
            </div>
            <Tabs activeTab={activeTab} />
        </div>
    );
};

export default Payment;
