import React from 'react';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { IFieldKey, ISupportField } from './Support.interface';
import { contactUs } from './Support.slice';
import { errorCode } from 'src/constants/errorCode';
import { toast } from 'react-toastify';
import Button from 'src/components/Button';
import InputWithLabel from 'src/components/InputWithLabel';
import { selectUserInfo } from 'src/app/Auth/Login/Login.slice';
import { Images } from 'src/theme';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Support = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const user = useAppSelector(selectUserInfo);
    const shop = user.shops[0];

    const schema = Yup.object().shape({
        shop_id: Yup.number().required('This field is required'),
        name: Yup.string().required('This field is required'),
        email: Yup.string().email('Please enter valid email address').required('This field is required'),
        message: Yup.string().required('This field is required'),
    });

    const {
        handleSubmit,
        control,
        formState: { errors },
        setError,
        reset,
    } = useForm<ISupportField>({
        resolver: yupResolver(schema),
        defaultValues: {
            shop_id: shop.id,
            name: '',
            email: '',
            message: '',
        },
    });

    const handleSave = async (data: ISupportField, event: any) => {
        const result: any = await dispatch(contactUs(data));
        if (result.type === contactUs.fulfilled.toString()) {
            reset();
        } else if (result.type === contactUs.rejected.toString()) {
            const response = result.payload.data.data;
            if (response.status === errorCode.unprocessable) {
                if (response.data) {
                    Object.keys(response.data).forEach((field) => {
                        setError(field as IFieldKey, {
                            type: 'manual',
                            message: response.data[field][0],
                        });
                    });
                }
                return;
            }
            toast.error(response.message);
        }
    };

    return (
        <div className="w-[full] rounded-2xl bg-white border-[1px] border-lineColor">
            <div className="detail-page-title-block">
                <h2 className="text-xl font-bold leading-[140%] -tracing-[0.2px]">{t('Support')}</h2>
            </div>

            <form onSubmit={handleSubmit(handleSave)}>
                <div className="detail-page-data-block border-0">
                    <div className="title-block">
                        <h3 className="title-h3">{t('Find us at')}</h3>
                        <div className="text-primary mb-8 flex items-center">
                            <i className="mr-3">
                                <img src={Images.mapLocationPin} alt="" className="w-5" />
                            </i>
                            <span className="leading-[140%] -tracking-[1px] text-base">{t('1234 Post Avenue Remington')}</span>
                        </div>
                        <h3 className="title-h3">{t('Reach out to us at')}</h3>
                        <div className="flex">
                            <div className="flex items-center mr-8">
                                <i className="mr-3">
                                    <img src={Images.IconEnvelop} alt="" className="w-5" />
                                </i>
                                <Link to={'mailto:contact@flair.com'} className="leading-[140%] -tracking-[1px] text-base">
                                    {t('contact@flair.com')}
                                </Link>
                            </div>
                            <div className="flex items-center">
                                <i className="mr-3">
                                    <img src={Images.IconPhone} alt="" className="w-5" />
                                </i>
                                <Link to={'tel:+1 234 567 890'} className="leading-[140%] -tracking-[1px] text-base">
                                    {t('+1 234 567 890')}
                                </Link>
                            </div>
                        </div>
                    </div>

                    <div className="data-wrap-block max-w-[755px]">
                        <div className="w-full h-full rounded-2xl bg-white border-[1px] border-lineColor p-8 drop-shadow-cardShadow">
                            <h2 className="text-center font-bold text-[20px] leading-[140%] -tracking-[0.2px] mb-8">{t('Get in touch with u')}s</h2>
                            <div className="flex -mx-[10px] mb-5">
                                <Controller
                                    name="name"
                                    control={control}
                                    render={({ field: { onChange, value } }: any) => (
                                        <div className="w-1/2 px-[10px]">
                                            <InputWithLabel name="name" label={t('Your Name')} placeholder={t('Your Name')} value={value} onChange={onChange} error={!!errors.name} />
                                            {errors.name && <p className="text-error">{errors.name.message}</p>}
                                        </div>
                                    )}
                                />

                                <Controller
                                    name="email"
                                    control={control}
                                    render={({ field: { onChange, value } }: any) => (
                                        <div className="w-1/2 px-[10px]">
                                            <InputWithLabel name="email" label={t('Email')} placeholder={t('Email Address')} value={value} onChange={onChange} error={!!errors.email} />
                                            {errors.email && <p className="text-error">{errors.email.message}</p>}
                                        </div>
                                    )}
                                />
                            </div>
                            <Controller
                                name="message"
                                control={control}
                                render={({ field: { onChange, value } }: any) => (
                                    <div className="w-full">
                                        <InputWithLabel
                                            textArea
                                            rows={5}
                                            name="message"
                                            label={t('Message')}
                                            textAearClassName="rounded-3xl h-[220px]"
                                            placeholder={t('Your Message')}
                                            value={value}
                                            onChange={onChange}
                                            error={!!errors.message}
                                        />
                                        {errors.message && <p className="text-error">{errors.message.message}</p>}
                                    </div>
                                )}
                            />
                            <Button className="fl-btn btn_primary mt-8">{t('Submit')}</Button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default Support;
