import React, { useCallback, useEffect, useState } from 'react';
import Card from 'src/components/Card';
import { MdOutlineChevronRight } from 'react-icons/md';
import { TfiPencilAlt } from 'react-icons/tfi';
import { IoCalendarClear } from 'react-icons/io5';
import Popup from 'src/components/Popup';
import MyAccountMethods from './MyAccountMethods';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { selectShopInfo } from '../../Auth/Login/Login.slice';
import BookingPayment from './BookingPayment';
import Frequency from './Frequency';
import { listStripeCard } from './MyAccount.slice';
import { capitalizeFirstLetterAndUnderscore, getCardTypeImage } from 'src/utils/global-functions';
import { locationListAction, selectListing } from 'src/app/Location/Location.slice';
import { axiosGet } from 'src/utils/requestClient';
import { API } from 'src/constants/api';
import { errorCode } from 'src/constants/errorCode';

const initAction = {
    commission: false,
    rent: false,
    bookingpayment: false,
    stripe: false,
};
const MyAccount = ({ activeTab }: any) => {
    const shop: any = useAppSelector(selectShopInfo);
    const locationList: any = useAppSelector(selectListing);
    const dispatch = useAppDispatch();
    const [balance, setBalance] = useState<number>(0);
    const getBalance = async () => {
        try {
            const response = await axiosGet(API.PAYMENT.BALANCE);
            if (response.data?.status === errorCode.success || response.data?.status === errorCode.updateSuccess) {
                setBalance(response?.data?.data?.total_amount);
                return;
            }
            throw response.data;
        } catch (err: any) {
            console.error(err?.message);
        }
    };

    const forCommissionFrequency = shop.shop_payment === null ? 'None' : shop?.shop_payment?.commission_frequency;
    const forRentFrequency = shop.shop_payment === null ? 'None' : shop?.shop_payment?.rent_frequency;

    const [action, setAction] = useState(initAction);
    const [defaultCard, setDefaultCard] = useState<any>(null);
    const [listCard, setListCard] = useState<any>();

    const [paymentType, setPaymentType] = useState<string>();

    const handleAction = (type: string) => () => {
        setAction((old) => ({ ...old, [type]: true }));
    };
    const handleModalClose = useCallback(() => {
        setAction(initAction);
    }, []);
    useEffect(() => {
        getCard();
        getBalance();
    }, []);
    useEffect(() => {
        dispatch(locationListAction({ shop_id: shop?.id }));
    }, []);

    const isOpenFrequencyPopup = (frequency: string) => async (event: { preventDefault: () => void }) => {
        event.preventDefault();
        if (frequency === 'commission') {
            const commissionHandler = handleAction('commission');
            commissionHandler();
            setPaymentType('commission');
        } else {
            const rentHandler = handleAction('rent');
            rentHandler();
            setPaymentType('rent');
        }
    };
    const getCard = async () => {
        const result = await dispatch(listStripeCard());
        if (result.type === listStripeCard.fulfilled.toString()) {
            let filterData = result.payload.data.cards.filter((items: any) => items.id === result.payload.data.default_card_id);
            setListCard(result.payload.data.cards);
            setDefaultCard(filterData[0]);
        }
    };

    return (
        <div className=" flex-1 flex flex-col">
            <div className="w-full h-full flex flex-col flex-1 rounded-2xl bg-white border-[1px]  border-lineColor ">
                <div className="py-5 px-8 border-b border-lineColor">
                    <h2 className="flex items-center text-xl leading-[140%] -tracking-[0.4px] font-bold mr-2 ">{activeTab.name}</h2>
                </div>
                <div className="flex flex-1">
                    <div className="flex-1 p-6 px-8">
                        <span className="font-medium -tracking-[0.3px]">{shop.business_name} Balance</span>
                        <h2 className="text-[38px] leading-[124%] -tracking-[0.864px] font-bold mb-5 mt-1">CA ${balance}</h2>

                        <Card className="rounded-3xl bg-white p-4 mb-3 flex">
                            <div className="mr-3 w-10">
                                <i className="p-[9px] bg-inputBg inline-block rounded-2xl text-primary">
                                    <IoCalendarClear size={22} />
                                </i>
                            </div>
                            <div className="flex-1">
                                <div className="mb-4">
                                    <p className="flex text-base items-center -tracking-[0.32px]">
                                        Commission payout frequency: <span className="font-bold ml-1">{capitalizeFirstLetterAndUnderscore(forCommissionFrequency)}</span>
                                        <TfiPencilAlt className="ml-2 text-primary cursor-pointer" size={16} onClick={isOpenFrequencyPopup('commission')} />
                                    </p>
                                    <p className="text-xs text-secondaryTxtColor -tracking-[0.3px]">Set the frequency at which your staff will automatically be paid based on their commission split</p>
                                </div>
                                <div className="">
                                    <p className="flex text-base items-center -tracking-[0.32px]">
                                        Rent collection frequency:
                                        <span className="font-bold  ml-1">{capitalizeFirstLetterAndUnderscore(forRentFrequency)}</span>
                                        <TfiPencilAlt className="ml-2 text-primary cursor-pointer" size={16} onClick={isOpenFrequencyPopup('rent')} />
                                    </p>
                                    <p className="text-xs text-secondaryTxtColor -tracking-[0.3px]">
                                        Set the frequency at which you wish to automatically collect payments for all of your staff on rent
                                    </p>
                                </div>
                            </div>
                        </Card>

                        {/* <div className="text-center">
                            <button className="text-primary font-medium leading-[140%] -tracking-[0.3px]">View wallet history</button>
                        </div> */}
                    </div>
                    <div className="w-[299px] xl:w-[359px] 2xl:w-[543px] grow-0 shrink-0 basis-[299px] xl:basis-[359px] 2xl:basis-[543px] rounded-bl-2xl border-l  border-lineColor">
                        <div className="flex flex-col">
                            <div className="p-6 w-ful flex items-center  border-b border-lineColor">
                                <div className="">
                                    <h2 className="flex items-center text-base leading-[140%] -tracking-[0.32px] font-bold">{shop.business_name}</h2>
                                    <p className="text-xs text-secondaryTxtColor leading-[150%] -tracking-[0.3px] flex items-center">{locationList.length} locations</p>
                                </div>
                                <div className="account-img-block w-[70px] h-[70px] border border-lineColor rounded-full p-2 drop-shadow-cardShadow bg-white ml-auto flex items-center justify-center">
                                    <img src={shop.logo_image_url} alt="" className="w-full" />
                                </div>
                            </div>
                            <div className="flex flex-col flex-1 p-6 ">
                                <div className="text-primary mb-6 font-medium cursor-pointer  -tracking-[0.3px]" onClick={handleAction('bookingpayment')}>
                                    Booking payment requirements
                                </div>
                                <div className="text-primary mb-6 font-medium  -tracking-[0.3px] opacity-50">My Stripe Account </div>
                                <div className="mb-6 cursor-pointer" onClick={handleAction('stripe')}>
                                    <div className="mb-2 text-primary font-medium  -tracking-[0.3px]">Payment methods</div>
                                    {defaultCard && (
                                        <div className="text-secondaryTxtColor flex items-center  -tracking-[0.3px]">
                                            <i className="icon w-[30px] mr-1">
                                                <img src={getCardTypeImage(defaultCard?.card?.brand)} alt="" className="w-full " />
                                            </i>
                                            **** - **** - **** - {defaultCard.card.last4}
                                        </div>
                                    )}
                                </div>
                                {/* <div className="text-primary font-medium  -tracking-[0.3px] opacity-50">Monthly statements and fees</div> */}
                            </div>
                        </div>

                        {action.bookingpayment && <Popup size="max-w-[936px]" className="p-8 pb-10" handleClose={handleModalClose} content={<BookingPayment handleClose={handleModalClose} />} />}

                        {action.commission && (
                            <Popup
                                size="max-w-[600px]"
                                className="p-8 pb-10"
                                handleClose={handleModalClose}
                                content={
                                    <>
                                        <Frequency paymentType={paymentType} setPaymentType={setPaymentType} handleClose={handleModalClose} />
                                    </>
                                }
                            />
                        )}

                        {action.rent && (
                            <Popup
                                size="max-w-[600px]"
                                className="p-8 pb-10"
                                handleClose={handleModalClose}
                                content={
                                    <>
                                        <Frequency paymentType={paymentType} setPaymentType={setPaymentType} handleClose={handleModalClose} />
                                    </>
                                }
                            />
                        )}

                        <MyAccountMethods defaultCard={defaultCard} setDefaultCard={setDefaultCard} listCard={listCard} getCard={getCard} isOpen={action.stripe} handleClose={handleModalClose} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MyAccount;
