import React, { FC, useCallback, useEffect, useState } from 'react';
import Button from 'src/components/Button';
import { FaArrowLeftLong } from 'react-icons/fa6';
import flairPay from '../../../assets/img/flairpay.png';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { getStripeAccount, selectLoading, verifyStripeAccount } from './FlairPayment.slice';
import { selectUserInfo, shopDetail } from 'src/app/Auth/Login/Login.slice';
import { errorCode } from 'src/constants/errorCode';
import { IPops } from './FlairPayment.interface';
import Popup from 'src/components/Popup';
import StripeConnect from './StripeConnect';
import { useTranslation } from 'react-i18next';

let newWindow: any;
const initAction = {
    stripe: false,
};
const FlairPayment: FC<IPops> = ({ setStep }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const loading = useAppSelector(selectLoading);

    const user = useAppSelector(selectUserInfo);
    const [isStripeVerified, setIsStripeVerified] = useState('');
    const [action, setAction] = useState(initAction);
    const handleModalClose = useCallback(() => {
        setAction(initAction);
    }, []);

    const handleWindowClose = (message: any) => {
        if (message === 'stripe_connect_verified') {
            //
        } else if (message === 'stripe_connect_not_verified') {
            //
        }
        newWindow.close();
        setIsStripeVerified(message);
    };

    useEffect(() => {
        const shop = user.shops[0];
        const stripeAccount = shop.account_step.stripe_account;
        switch (stripeAccount) {
            case true:
                setIsStripeVerified('stripe_connect_verified');
                break;
            case 'pending':
                setIsStripeVerified('stripe_connect_pending');
                break;
            default:
                setIsStripeVerified('stripe_connect_not_verified');
                break;
        }
    }, [user]);

    useEffect(() => {
        window.addEventListener('message', (event) => {
            if (event.source === newWindow) {
                handleWindowClose(event.data);
            }
        });
        return () => {
            newWindow = null;
            window.removeEventListener('message', handleWindowClose);
        };
    }, []);

    const connect = async () => {
        const result = await dispatch(verifyStripeAccount());
        if (result.type === verifyStripeAccount.fulfilled.toString() || isStripeVerified === 'stripe_connect_pending') {
            dispatch(shopDetail());
            setAction({ stripe: true });
        } else if (result.type === verifyStripeAccount.rejected.toString()) {
            const response = result.payload.data;
            if (response.status === errorCode.unprocessable) {
                const generateConnectUrl = await dispatch(getStripeAccount());
                if (generateConnectUrl.type === getStripeAccount.fulfilled.toString()) {
                    const url = generateConnectUrl.payload.data.url;
                    const height = 800;
                    const width = 1200;
                    const leftPosition = window.screen.width / 2 - (width / 2 + 10);
                    const topPosition = window.screen.height / 2 - (height / 2 + 50);
                    newWindow = window.open(
                        url,
                        'Window2',
                        `status=no,height=${height},width=${width},resizable=yes,left=${leftPosition},top=${topPosition},screenX=${leftPosition},screenY=${topPosition},toolbar=no,menubar=no,scrollbars=no,location=no,directories=no`,
                    );
                }
            }
        }
    };
    return (
        <>
            <div className="w-[690px] flex-1 flex flex-col mt-[52px] justify-center items-center">
                <div className="w-[600px] flex flex-col ">
                    <div className="text-center">
                        <h3 className="fwpo_heading_title w-[480px] mx-auto">{t('FLAIR Pay')}</h3>
                        <p className="text-secondaryTxtColor font-normal leading-[140%] mt-4 text-sm">{t('FLAIR_pay_description')}</p>
                    </div>
                    <div className="mt-[18px] text-center">
                        <p className="text-primary text-sm leading-[140%] -tracking-[0.14px] font-medium">&gt; {t('Details about FLAIR Pay and Stripe')}</p>
                        <p className="text-primary text-sm leading-[140%] -tracking-[0.14px] font-medium">&gt; {t('Link to documentation about how to connect')}</p>
                    </div>
                </div>
                <div className="img">
                    <img src={flairPay} alt="" />
                </div>
            </div>
            <div className="flex w-full px-10 justify-center">
                <div className="back-button absolute left-10">
                    <Button onClick={() => setStep(5)} type="button" className="rounded-full bg-transparent border-black border-2 text-black py-[14px] px-10 flex items-center gap-3 text-sm">
                        <FaArrowLeftLong size={18} className="mr-2" /> {t('Back')}
                    </Button>
                </div>
                <Button
                    onClick={connect}
                    isLoading={loading}
                    className="fl-btn text-base  bg-primary text-white -tracking-[0.16px] font-bold w-[420px] 2xl:w-[572px] mb-5 rounded-full hover:bg-blue-800 transition-all ease-in-out delay-75"
                >
                    {t('Connect to FLAIR Pay')}
                </Button>
            </div>
            {action.stripe && <Popup size="max-w-[610px]" handleClose={handleModalClose} content={<StripeConnect setStep={setStep} />} className="p-[68px] pt-[21px]" />}
        </>
    );
};

export default FlairPayment;
