import React, { useEffect, useState } from 'react';
import SelectBox from 'src/components/SelectBox/SelectBox';
import { Images } from 'src/theme';
import { getSelectBoxOptionsIdAsValue } from 'src/utils/global-functions';
import { ISelectedLocation } from '../Staff.interface';
import OpeningHour from 'src/app/Signup/WorkingHours/OpningHour';
import { useForm } from 'react-hook-form';
import { IWorkingHoursForm, workingHoursData } from 'src/app/Signup/WorkingHours/WorkingHours.interface';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const WorkingHours = ({ staffInfo }: { staffInfo: any }) => {
    const { t } = useTranslation();
    const [selectedLocation, setSelectedLocation] = useState<ISelectedLocation | null>(null);
    const [locationOptions, setLocationOptions] = useState([]);
    const [locationDataDetails, setLocationDataDetails] = useState<any>({});
    const [locationList, setLocationList] = useState([]);

    useEffect(() => {
        const LocationOption: any = getSelectBoxOptionsIdAsValue(staffInfo.locations);
        setSelectedLocation(LocationOption[0]);
        setLocationOptions(LocationOption);
        setLocationDataDetails(staffInfo.locations[0]);
        setLocationList(staffInfo.locations);
    }, []);

    const handleChangeLocation = (e: ISelectedLocation) => {
        setSelectedLocation(e);
        const locationData: any = locationList.filter((item: any) => item.id === e?.id);

        // setValue('hours', locationData && locationData[0]?.staff_working_hours);
        setLocationDataDetails(locationData[0]);
    };
    const {
        control,
        formState: { errors },
        setError,
        setValue,
        resetField,
    } = useForm<IWorkingHoursForm>({
        defaultValues: {
            id: 1,
            hours: workingHoursData,
        },
    });

    useEffect(() => {
        if (Object.keys(locationDataDetails).length !== 0) {
            const workingHours = locationDataDetails.staff_working_hours;
            if (workingHours.length) {
                const existingWorkingHours = workingHours.map((workingHour: any) => {
                    const momentFromTime = moment(workingHour.from, 'HH:mm:ss');
                    const momentToTime = moment(workingHour.to, 'HH:mm:ss');

                    const fromTimeHours = momentFromTime.format('hh');
                    const fromTimeMinute = momentFromTime.format('mm');
                    const fromTimeType = momentFromTime.format('a');

                    const toTimeHours = momentToTime.format('hh');
                    const toTimeMinute = momentToTime.format('mm');
                    const toTimeType = momentToTime.format('a');

                    return {
                        day: workingHour.day,
                        status: workingHour.status,
                        from_time_hours: fromTimeHours,
                        from_time_minute: fromTimeMinute,
                        to_time_hours: toTimeHours,
                        to_time_minute: toTimeMinute,
                        from_time_type: fromTimeType,
                        to_time_type: toTimeType,
                    };
                });
                setValue('hours', existingWorkingHours);
            } else {
                setValue('hours', workingHoursData);
            }
        }
    }, [locationDataDetails]);

    return (
        <div className="drawer-content-block">
            <div className="py-6 px-8">
                <div className="flex justify-between items-center mb-5">
                    <h2 className="text-xl font-bold -tracking-[0.4px] ">{t('Working Hours')}</h2>
                    <div className="w-[293px] ml-6">
                        <SelectBox
                            options={locationOptions}
                            classComp="outline-select-box"
                            value={selectedLocation}
                            onChangeFunc={handleChangeLocation}
                            openOnFocus={true}
                            autofocus={true}
                            allowIcon={<img src={Images.mapLocationPin} alt="map-pin" />}
                        />
                    </div>
                </div>

                <OpeningHour control={control} errors={errors} setValue={setValue} setError={setError} resetField={resetField} isDisabled={true} extraClassName="opening-hours-small" />
            </div>
        </div>
    );
};

export default WorkingHours;
