import React from 'react';
import { Images } from 'src/theme';

const Header = () => (
    <>
        <img src={Images.Logo} alt="" />
    </>
);

export default Header;
