import React, { useState } from 'react';
import InputWithLabel from 'src/components/InputWithLabel';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { IAddClientForm } from './Client.interface';
import { CountryCode } from 'libphonenumber-js';
import * as Yup from 'yup';
import Number from 'src/components/Number';
import Popup from 'src/components/Popup';
import Button from 'src/components/Button';
import { addClient } from './Client.slice';
import { errorCode } from 'src/constants/errorCode';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { selectUserInfo } from '../Auth/Login/Login.slice';
import { toast } from 'react-toastify';
import SelectBox from 'src/components/SelectBox/SelectBox';
import { useTranslation } from 'react-i18next';
type FieldKey = 'first_name' | 'last_name' | 'phone' | 'phone_country_code' | 'email' | 'gender';

const Add = ({ isOpenPopup, handleClose, getClientList }: any) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const user: any = useAppSelector(selectUserInfo);
    const shopId = user.shops[0].id;
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const schema = Yup.object({
        first_name: Yup.string().required(`${t('This field is required')}`),
        last_name: Yup.string().required(`${t('This field is required')}`),
        gender: Yup.string().required(`${t('This field is required')}`),
        email: Yup.string().required(`${t('This field is required')}`),
        phone: Yup.string().required(`${t('This field is required')}`),
        phone_country_code: Yup.string().required(`${t('This field is required')}`),
    }).required();

    const genderList = [
        { label: 'Male', value: 'male' },
        { label: 'Female', value: 'female' },
        { label: 'Non-binary', value: 'non-binary' },
        { label: 'Prefer not to respond', value: 'prefer not to respond' },
    ];

    const {
        handleSubmit,
        control,
        formState: { errors },
        setError,
        setValue,
        reset,
    } = useForm<IAddClientForm>({
        resolver: yupResolver(schema),
        defaultValues: {
            first_name: '',
            last_name: '',
            phone: '',
            gender: 'male',
            email: '',
            phone_country_code: 'US',
        },
    });

    const onSubmit = async (data: any) => {
        setIsLoading(true);
        const submitData = {
            payload: data,
            shop_id: shopId,
        };
        const result = await dispatch(addClient(submitData));
        if (result.type === addClient.fulfilled.toString()) {
            handleClose();
            reset();
            getClientList();
            setIsLoading(false);
        }

        if (result.type === addClient.rejected.toString()) {
            const response = result.payload.data;
            if (response.status === errorCode.unprocessable) {
                if (response.data) {
                    Object.keys(response.data).forEach((field) => {
                        setError(field as FieldKey, {
                            type: 'manual',
                            message: response.data[field][0],
                        });
                    });
                }
                setIsLoading(false);
                return;
            }

            toast.error(response.message);
        }
    };

    const handleNumberChange = (
        country: CountryCode,
        phone: string,
        code: string,
        isValid: boolean,
        // country: CountryCode,
        // phone: string,
        // isValid: boolean,
    ) => {
        if (isValid) {
            setValue(`phone`, `+${code}${phone}`);
            setValue(`phone_country_code`, country);
        } else {
            setValue(`phone`, '');
        }
    };

    const addClientContent = (
        <>
            <h3 className="text-[28px] font-bold -tracking-[1.6px] leading-[124%] text-center mb-8 ">{t('Add a new client')}</h3>

            <form onSubmit={handleSubmit(onSubmit)} className="h-full">
                <div className="flex -mx-[10px] flex-wrap">
                    <div className="px-[10px] mb-5 w-1/2 grow-0 shrink-0 basis-[50%]">
                        <Controller
                            name="first_name"
                            control={control}
                            render={({ field: { onChange, value } }: any) => (
                                <>
                                    <InputWithLabel
                                        label={t('First Name')}
                                        placeholder={t('Enter Client First Name')}
                                        onChange={onChange}
                                        value={value}
                                        name="first_name"
                                        error={!!errors.first_name}
                                        isFocus={true}
                                    />
                                    {errors?.first_name && <p className="text-error">{errors?.first_name?.message}</p>}
                                </>
                            )}
                        />
                    </div>
                    <div className="px-[10px] mb-5 w-1/2">
                        <Controller
                            name="last_name"
                            control={control}
                            render={({ field: { onChange, value } }: any) => (
                                <>
                                    <InputWithLabel label={t('Last Name')} placeholder={t('Enter Client Last Name')} onChange={onChange} value={value} name="last_name" error={!!errors.last_name} />
                                    {errors?.last_name && <p className="text-error">{errors?.last_name?.message}</p>}
                                </>
                            )}
                        />
                    </div>
                    <div className="px-[10px] mb-5 w-1/2 grow-0 shrink-0 basis-[50%]">
                        <label htmlFor="phone" className="fl-field-title-label">
                            {t('Phone Number')}
                        </label>
                        <Controller
                            name="phone"
                            control={control}
                            render={({ field: { onChange } }: any) => (
                                <div className="flex flex-col ">
                                    <Number getNumber={onChange} errors={errors} onNumberChange={handleNumberChange} />
                                </div>
                            )}
                        />
                    </div>
                    <div className="px-[10px] mb-5 w-1/2">
                        <Controller
                            name="email"
                            control={control}
                            render={({ field: { onChange, value } }: any) => (
                                <>
                                    <InputWithLabel label={t('E-mail')} placeholder={t('Enter Client email')} onChange={onChange} value={value} name="email" />
                                </>
                            )}
                        />
                        {errors?.email && <p className="text-error">{errors?.email?.message}</p>}
                    </div>
                    <div className="px-[10px] w-1/2">
                        <Controller
                            name="gender"
                            control={control}
                            render={({ field: { onChange, value } }: any) => (
                                <SelectBox
                                    name="gender"
                                    label={t('Gender')}
                                    options={genderList}
                                    value={genderList.filter((item: any) => item.value === value)}
                                    errorText={errors.gender && true}
                                    onChangeFunc={(selectedOption: any) => onChange(selectedOption.value)}
                                    placeholder={t('Select Gender')}
                                />
                            )}
                        />
                        {errors?.gender && <p className="text-error">{errors?.gender?.message}</p>}
                    </div>

                    <div className="flex w-full justify-center mt-10 px-[10px]">
                        <Button className="fl-btn btn_primary w-full" isLoading={isLoading} disabled={isLoading}>
                            {t('Continue')}
                        </Button>
                    </div>
                </div>
            </form>
        </>
    );
    return (
        <div>
            {isOpenPopup && (
                <Popup
                    size="max-w-[780px]"
                    className="p-8 pb-10"
                    handleClose={() => {
                        handleClose();
                        reset();
                    }}
                    content={addClientContent}
                />
            )}
        </div>
    );
};

export default Add;
