import React, { useState } from 'react';
import InputWithLabel from 'src/components/InputWithLabel';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import Button from 'src/components/Button';
import Popup from 'src/components/Popup';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { selectUserInfo } from '../Auth/Login/Login.slice';
import { importExcel, selectClientLoading } from './Client.slice';
import { errorCode } from 'src/constants/errorCode';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
type FieldKey = 'file';
const Import = ({ isOpenImportPopup, handleClose, downloadExcel, isFileUpload, setIsFileUpload, loadLazyData, loading }: any) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const user = useAppSelector(selectUserInfo);
    const isUploadLoading = useAppSelector(selectClientLoading);
    const shopId = user.shops[0].id;
    const [fileName, setFileName] = useState<string>('');
    const schema = Yup.object({
        file: Yup.mixed().required(`${t('Please select file')}`),
    }).required();

    const {
        handleSubmit,
        control,
        formState: { errors },
        setError,
    } = useForm<any>({
        resolver: yupResolver(schema),
        defaultValues: {
            file: '',
        },
    });

    const onSubmit = async (data: any) => {
        const data1 = {
            file: { file: data.file },
            shop_id: shopId,
        };

        const result = await dispatch(importExcel(data1));
        if (result.type === importExcel.fulfilled.toString()) {
            toast.success(result.payload.message);
            handleClose();
            loadLazyData();
        }

        if (result.type === importExcel.rejected.toString()) {
            const response = result.payload.data;
            if (response.status === errorCode.unprocessable) {
                if (response.data) {
                    Object.keys(response.data).forEach((field) => {
                        setError(field as FieldKey, {
                            type: 'manual',
                            message: response.data[field][0],
                        });
                    });
                }
                return;
            }
            toast.error(response.message);
        }
    };

    const importPopupContent = isFileUpload ? (
        <>
            <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
                <div className="flex flex-col w-full text-start mt-5">
                    <div className="mb-5 w-full">
                        <Controller
                            name="file"
                            control={control}
                            render={({ field: { onChange, value } }: any) => (
                                <div className="w-full">
                                    <InputWithLabel
                                        type="file"
                                        label={t('Upload File Label')}
                                        // onChange={onChange}
                                        onChange={(e: any) => {
                                            onChange(e.target.files[0]);
                                            setFileName(e.target.files[0].name);
                                        }}
                                        fileName={fileName}
                                        value=""
                                        name="file"
                                        error={!!errors.file}
                                    />
                                    {errors?.file && <p className="text-error">{errors?.file?.message as React.ReactNode}</p>}
                                </div>
                            )}
                        />
                    </div>
                </div>
                <Button isLoading={isUploadLoading} disabled={isUploadLoading} className="w-full fl-btn text-base font-bold btn_primary px-[10px] justify-center mt-5">
                    {t('Import')}
                </Button>
            </form>
        </>
    ) : (
        <div className="">
            <h3 className="mb-7 text-xl font-bold text-mainTextColor leading-[140%] -tracking-[0.2px] text-center">{t('Importing clients to FLAIR')}</h3>
            <div className="flex items-center mb-5">
                <span className="w-[46px] h-[46px] basis-[46px] grow-0 shrink-0 text-xl border text-primary font-bold rounded-2xl flex justify-center items-center">1</span>
                <p className="pl-5 leading-[140%] -tracking-[0.14px]">{t('import_client_description1')}</p>
            </div>
            <div className="flex items-center ">
                <span className="w-[46px] h-[46px] basis-[46px] grow-0 shrink-0 text-xl border text-primary font-bold rounded-2xl flex justify-center items-center">2</span>
                <p className="pl-5 leading-[140%] -tracking-[0.14px]">{t('import_client_description2')}</p>
            </div>
            <div className="w-full mt-10">
                <Button className="fl-btn btn_primary" onClick={() => downloadExcel('import_xlsx')} isLoading={loading} disabled={loading}>
                    {t('Download Import Template')}
                </Button>
                <Button className="w-full fl-btn text-base font-bold btn_outline_black px-[10px] justify-center mt-2" onClick={() => setIsFileUpload(true)}>
                    {t('Skip Download')}
                </Button>
            </div>
        </div>
    );

    return <div>{isOpenImportPopup && <Popup size="max-w-[501px]" className="px-10 pt-8 pb-10" handleClose={handleClose} content={importPopupContent} />}</div>;
};

export default Import;
