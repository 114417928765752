import React, { useLayoutEffect, useRef } from 'react';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import * as am5percent from '@amcharts/amcharts5/percent';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';

const PaymentChartPie = (props: any) => {
    const { paymentMethod, timeFileterSelected } = props;

    const chartRef = useRef<am5xy.XYChart | null>(null);

    useLayoutEffect(() => {
        let root = am5.Root.new('chartpie-div');
        if (root._logo) {
            root._logo.dispose();
        }
        root.setThemes([am5themes_Animated.new(root)]);

        let chart = root.container.children.push(
            am5percent.PieChart.new(root, {
                innerRadius: am5.percent(80),
                layout: root.horizontalLayout,
                paddingLeft: 0,
                paddingRight: 0,
                paddingBottom: 0,
                paddingTop: 0,
                marginLeft: 0,
                marginRight: 0,
            }),
        );

        chart.onPrivate('width', function (width) {
            if (width) {
                var availableSpace = Math.max(width - chart.height() - 150, 100);
                legend.labels.template.setAll({
                    width: availableSpace,
                    maxWidth: availableSpace,
                });
            }
        });

        const capitalizedPaymentMethod = paymentMethod.map((item: any) => ({
            ...item,
            name: item.name.charAt(0).toUpperCase() + item.name.slice(1),
        }));

        let series = chart.series.push(
            am5percent.PieSeries.new(root, {
                alignLabels: false,
                valueField: 'total',
                categoryField: 'name',
                legendLabelText: '{category}',
                legendValueText: "{valuePercentTotal.formatNumber('0.00p')} (${value})",
            }),
        );
        series?.get('colors')?.set('colors', [am5.color('#1642C5'), am5.color('#1AD598'), am5.color('#6200EE'), am5.color('#D602EE'), am5.color('#FFB536')]);
        series.slices.template.set('toggleKey', 'none');
        series.slices.template.set('hoverOnFocus', false);
        series.labels.template.set('visible', false);
        series.ticks.template.set('visible', false);
        capitalizedPaymentMethod.sort((a: any, b: any) => b?.total - a?.total);

        series.data.setAll(capitalizedPaymentMethod);

        series.appear(1000, 100);
        chart.seriesContainer.children.push(
            am5.Label.new(root, {
                textAlign: 'center',
                centerY: am5.p50,
                centerX: am5.p50,
                text: `[fontSize:25px]Total Sales[/]:\n[bold fontSize:30px]${
                    timeFileterSelected.label === 'All time' ? 'All Time' : timeFileterSelected.label === 'Custom' ? `Custom` : timeFileterSelected.label
                }[/]`,
            }),
        );

        let legend = chart.children.push(
            am5.Legend.new(root, {
                centerY: am5.percent(50),
                y: am5.percent(50),
                layout: root.verticalLayout,
                clickTarget: 'none',
            }),
        );
        legend.valueLabels.template.setAll({ textAlign: 'right' });
        legend.labels.template.setAll({
            maxWidth: 200,
            width: 200,
            oversizedBehavior: 'wrap',
        });

        legend.data.setAll(series.dataItems);
        am5.ready(function () {
            removeCredits();
        });

        return () => {
            root.dispose();
        };
    }, [paymentMethod]);

    const removeCredits = () => {
        const titles = Array.from(document.querySelectorAll('title'));

        for (const ele of titles) {
            if (ele.textContent && ele.textContent.includes('Chart created using amCharts library')) {
                const id = ele.id;
                const elementWithAriaLabel = document.querySelector<HTMLElement>(`[aria-labelledby=${id}]`);

                if (elementWithAriaLabel) {
                    elementWithAriaLabel.style.display = 'none';
                }
            }
        }
    };

    return <div id="chartpie-div" style={{ width: '95%', height: '300px' }}></div>;
};

export default PaymentChartPie;
