import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { errorCode } from 'src/constants/errorCode';
import { toast } from 'react-toastify';
import { selectShopInfo, shopDetail } from 'src/app/Auth/Login/Login.slice';
import { useTranslation } from 'react-i18next';
import { ICountStep, IPinSetup, IPinSetupConfirm } from './ReceptionistMode.interface';
import PinSetupStep1 from './PinSetup/PinSetupStep1';
import PinSetupStep2 from './PinSetup/PinSetupStep2';
import { axiosPost } from 'src/utils/requestClient';
import { API } from 'src/constants/api';

const PinSetup = ({ handleClose }: any) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const shop = useAppSelector(selectShopInfo);
    const shopId = shop.id;
    const [counter, setCounter] = useState(59);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        const timer: any = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
        return () => clearInterval(timer);
    }, [counter]);
    const handleNext = async () => {
        const isStepValid = await trigger();
        if (isStepValid) setStep((prevActiveStep) => prevActiveStep + 1);
    };
    const handleSave = async (data: any) => {
        setIsLoading(true);
        if (step === 1) {
            setStep(2);
            setIsLoading(false);
        }

        const payload = {
            ...data,
            pin: data.pin ? data.pin.join('') : '',
            pin_confirmation: data.pin_confirmation ? data.pin_confirmation.join('') : '',
        };
        try {
            const response = await axiosPost(API.RECEPTIONIST_MODE.PIN_SETUP, payload, {
                shop_id: shopId,
            });
            if (response.data?.status === errorCode.success || response.data?.status === errorCode.updateSuccess) {
                await dispatch(shopDetail());
                handleClose();
                return;
            }

            throw response.data;
        } catch (err: any) {
            toast.error(err?.message);
        } finally {
            setIsLoading(false);

            // setIsLoading(false);
        }
        console.log(data, step);
    };

    const defaultValues = {
        pin: new Array(4).fill(''),
        pin_confirmation: new Array(4).fill(''),
    };
    const validationSchema = [
        //validation for step1
        Yup.object({
            pin: Yup.array()
                .of(Yup.string().required('PIN digit is required').matches(/^\d$/, 'Each PIN digit must be a number'))
                .test('is-valid-pin', 'PIN is required and must be 4 digits', (pinArray) => {
                    if (!pinArray || pinArray.length === 0) {
                        return false;
                    }
                    const pinString = pinArray.join('');
                    const isValid = /^\d{4}$/.test(pinString);
                    return isValid;
                })
                .required('PIN is required'),
        }).required(),
        //validation for step2
        Yup.object({
            pin_confirmation: Yup.array()
                .of(Yup.string().required('PIN Confirmation digit is required').matches(/^\d$/, 'Each PIN Confirmation digit must be a number'))
                .test('pin-confirmation-length', 'PIN Confirmation must be exactly 4 digits', (pinArray) => {
                    if (!pinArray || pinArray.length === 0) {
                        return false;
                    }
                    const pinString = pinArray.join('');
                    const isValid = /^\d{4}$/.test(pinString);
                    return isValid;
                })
                .test('pins-match', 'PIN and PIN Confirmation must match', function (pinConfirmationArray) {
                    const pinArray = this.parent.pin;
                    if (!pinArray || !pinConfirmationArray) {
                        return false;
                    }
                    const pin = pinArray.join('');
                    const pinConfirmation = pinConfirmationArray.join('');
                    return pin === pinConfirmation;
                })
                .required('PIN Confirmation is required'),
        }),
    ];
    const [step, setStep] = useState<number | ICountStep>(1);

    const currentValidationSchema = validationSchema[step - 1] as unknown as Yup.ObjectSchema<IPinSetup | IPinSetupConfirm, Record<string, any>, any, ''>;

    const methods = useForm({
        shouldUnregister: false,
        defaultValues,
        resolver: yupResolver<any>(currentValidationSchema),
        mode: 'onBlur',
        reValidateMode: 'onSubmit',
    });
    const { handleSubmit, reset, trigger, setError } = methods;

    return (
        <FormProvider {...methods}>
            <form onSubmit={handleSubmit(handleSave)}>{step === 1 ? <PinSetupStep1 onClick={handleNext} /> : <PinSetupStep2 isLoading={isLoading} />}</form>
        </FormProvider>
    );
};

export default PinSetup;
