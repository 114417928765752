export const API = {
    USER: {
        LOGIN_WITH_EMAIL: '/shop/login',
        LOGIN_WITH_PHONE: '/shop/phone/login',
        PHONE_VERIFY_OTP: '/shop/phone/verify-otp',
        LOGOUT: '/shop/phone/verify-otp',
        FORGOT_PASSWORD: '/shop/forgot-password',
        NEW_PASSWORD: '/shop/new-password',
        CHANGE_PASSWORD: '/shop/change-password',
        PROFILE: '/shop/profile',
        UPDATE_EMAIL: '/shop/email',
        VERIFY_EMAIL: '/shop/email/verify',
        UPDATE_PHONE: '/shop/phone',
        VERIFY_PHONE: '/shop/phone/verify',
        ACCOUNT: '/shop/account',
        COMPLETE_ACCOUNT: '/shop/complete-account',
        COMPANY: '/shop/company',
        CREATE_CONTACT_US: '/shop/{shop_id}/contact-us',
        ACCOUNT_LOCATION: '/shop/account/location',
        ADD_LOCATION_WORKING_HOURS: '/shop/account/location/{id}/working-hours',
        BRANDING: '/shop/branding',
        APP_LOGO: '/shop/app-logo',
        CONTRACT: '/shop/contract',
        BOOKING_PAYMENT: '/shop/booking-payment',
        SHOP_DETAIL: '/shop/me',
        GET_STRIPE_ACCOUNT: '/shop/stripe/account/get',
        VERIFY_STRIPE_ACCOUNT: '/shop/stripe/account/verify',
        UPDATE_STRIPE_ACCOUNT_STATUS: '/shop/stripe/account/status',
        HYPERLINKS: '/shop/{shop_id}/hyperlinks',
    },
    STRIPE: {
        LIST: '/shop/stripe/card/list',
        CREATE: '/shop/stripe/card/add',
        DELETE: '/shop/stripe/card/remove',
        ACCOUNT: '/shop/stripe/account',
        DEFAULT: '/shop/stripe/card/default',
    },
    THEME: {
        LIST: '/shop/theme/list',
        UPDATE_APPEARANCE: '/shop/{shop_id}/appearance',
    },
    PLAN: {
        UPDATE: '/shop/{shop_id}/plan/upgrade',
        GET: '/shop/plans',
    },
    DASHBOARD: {
        GET: '/shop/{shop_id}/analytics/dashboard',
    },
    ANALYTICS: {
        GET: '/shop/{shop_id}/analytics',
    },
    CATEGORY: {
        LIST: '/shop/{shop_id}/category',
        CREATE: '/shop/{shop_id}/category',
        UPDATE: '/shop/{shop_id}/category/{id}',
        DELETE: '/shop/{shop_id}/category/{id}',
        GET: '/shop/{shop_id}/category/{id}',
    },
    LOCATION: {
        LIST: '/shop/{shop_id}/location',
        CREATE: '/shop/{shop_id}/location',
        UPDATE: '/shop/{shop_id}/location/{id}',
        DELETE: '/shop/{shop_id}/location/{id}',
        COUNTRY: '/shop/countries',
        STATE: '/shop/states',
        CITY: '/shop/cities',
    },
    PAYMENT: {
        UPDATE: '/shop/{shop_id}/payment/account',
        RENT_LIST: '/shop/{shop_id}/payment/rent',
        RENT_RETRY: '/shop/{shop_id}/payment/rent/{id}/retry',
        COMMISSION_LIST: '/shop/{shop_id}/payment/commission',
        COMMISSION_RETRY: '/shop/{shop_id}/payment/commission/{id}/retry',
        RECEIPT: '/shop/{shop_id}/payment/{id}/receipt',
        BALANCE: '/shop/stripe/account/balance',
    },
    TRANSACTION_HISOTRY: {
        LIST: '/shop/{shop_id}/booking/transactions',
    },
    SERVICE: {
        LIST: '/shop/{shop_id}/service',
        CREATE: '/shop/{shop_id}/service',
        UPDATE: '/shop/{shop_id}/service/{id}',
        DELETE: '/shop/{shop_id}/service/{id}',
        GET: '/shop/{shop_id}/service/{id}',
    },
    ADMINS: {
        LIST: '/shop/{shop_id}/admins',
        CREATE: '/shop/{shop_id}/admins',
        GET: '/shop/{shop_id}/admins/{id}',
        UPDATE: '/shop/{shop_id}/admins/{id}',
        DELETE: '/shop/{shop_id}/admins/{id}',
    },
    STAFF: {
        LIST: '/shop/{shop_id}/staff',
        CREATE: '/shop/{shop_id}/staff',
        UPDATE: '/shop/{shop_id}/staff/{id}',
        DELETE: '/shop/{shop_id}/staff/{id}',
        GET: '/shop/{shop_id}/staff/{id}',
        BOOKING: '/shop/{shop_id}/staff/{id}/bookings/details',
        APPOINTMENT: '/shop/{shop_id}/staff/{id}/bookings',
        PERFORMANCE: '/shop/{shop_id}/staff/performance',
    },
    RECEPTIONIST_MODE: {
        PIN_SETUP: '/shop/pin',
        PIN_VERIFY: '/shop/pin/verify',
        PIN_FORGOT: '/shop/pin/forgot',
    },
    CLIENT: {
        LIST: '/shop/{shop_id}/client',
        CREATE: '/shop/{shop_id}/client',
        GET: '/shop/{shop_id}/client/{id}',
        DELETE: '/shop/{shop_id}/client/{id}',
        EXPORT: '/shop/{shop_id}/client/export',
        IMPORT: '/shop/{shop_id}/client/import',
        SAMPLE: '/shop/{shop_id}/client/sample',
        UPDATE: '/shop/{shop_id}/client/{id}',
        APPOINTMENT: '/shop/{shop_id}/client/{id}/bookings',
        BOOKING: '/shop/{shop_id}/client/{id}/bookings/details',
        CARD_LIST: '/shop/{shop_id}/client/{id}/cards',
        NOTE: '/shop/{shop_id}/client/{client_id}/client-note',
    },
    STAFF_ROLE: {
        LIST: '/shop/{shop_id}/staff/role',
        CREATE: '/shop/{shop_id}/staff/role',
        UPDATE: '/shop/{shop_id}/staff/role/{id}',
        DELETE: '/shop/{shop_id}/staff/role/{id}',
    },
    SALES: {
        LIST: '/shop/sales-tax',
    },
    BOOKING: {
        LIST: '/shop/{shop_id}/booking',
        LIST_BY_DATE: '/shop/{shop_id}/booking/date',
        CREATE: '/shop/{shop_id}/location/{location_id}/booking',
        UPDATE: '/shop/{shop_id}/location/{location_id}/booking/{id}',
        UPDATE_STATUS: '/shop/{shop_id}/booking/{id}/status',
        RECEIPT: '/shop/{shop_id}/booking/{id}/receipt',
        GET: '/shop/{shop_id}/booking/{id}',
    },
    STAFF_BLOCK_TIME: {
        LIST: '/shop/{shop_id}/staff/{staff_id}/block-time',
        CREATE: '/shop/{shop_id}/staff/{staff_id}/block-time',
        UPDATE: '/shop/{shop_id}/staff/{staff_id}/block-time/{id}',
        DELETE: '/shop/{shop_id}/staff/{staff_id}/block-time/{id}',
    },
    WAITLIST: {
        LIST: '/shop/{shop_id}/waitlist',
        CREATE: '/shop/{shop_id}/waitlist',
        UPDATE: '/shop/{shop_id}/waitlist/{id}',
        DELETE: '/shop/{shop_id}/waitlist/{id}',
        GET: '/shop/{shop_id}/waitlist/{id}',
    },
    NOTIFICATION: {
        USER_NOTIFICATION_CREATE_UPDATE: '/shop/notification',
        LIST: '/shop/notification',
        REMINDER: '/shop/{shop_id}/notification/reminder',
    },
    REFER: {
        CREATE_OR_UPDATE: '/shop/{shop_id}/refer',
        REFER_STATUS: '/shop/{shop_id}/refer/status',
        CONFIG_CREATE_OR_UPDATE: '/shop/{shop_id}/refer/config',
        CONFIG_STATUS: '/shop/{shop_id}/refer/config/status',
    },
    SETTING: {
        GET: '/shop/{shop_id}/setting',
    },
};
