import React, { useEffect, useState } from 'react';
import Card from 'src/components/Card';
import Toggle from 'src/components/Toggle/Toggle';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { clientCardList, selectClientLoading, serviceList } from '../Client.slice';
import { selectUserInfo } from 'src/app/Auth/Login/Login.slice';
import { getSelectBoxOptionsIdAsValue } from 'src/utils/global-functions';
import SelectBox from 'src/components/SelectBox/SelectBox';
import Button from 'src/components/Button';
import { IDisocunt } from './ClientDetails.interface';
import { IoMdClose } from 'react-icons/io';
import InputWithToggle from 'src/components/InputWithToggle';
import PaymentCard from './Client.PaymentCard';
import BarLoader from 'src/components/BarLoader';
import { useTranslation } from 'react-i18next';
const PaymentMethods = ({ clientInfo }: any) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const user = useAppSelector(selectUserInfo);
    const shopId = user.shops[0].id;
    const loading = useAppSelector(selectClientLoading);
    // const [isBookWithCard, setIsBookWithCard] = useState<boolean>(true);

    const [discountRow, setDiscountRow] = useState<IDisocunt[]>([{ id: 1, services: null, discount: null, discount_type: '$' }]);
    const [isClientDiscount, setIsClientDiscount] = useState(false);
    const [serviceOptions, setServiceOptions] = useState<any>();
    const [clientCardArray, setClientCardArray] = useState<any>();
    const handleToggle = () => {
        setIsClientDiscount(!isClientDiscount);
    };

    const getService = async () => {
        const data = {
            shop_id: shopId,
        };
        const result = await dispatch(serviceList(data));
        if (result.type === serviceList.fulfilled.toString()) {
            const serviceOptionList: any = getSelectBoxOptionsIdAsValue(result.payload.data);
            setServiceOptions(serviceOptionList);
        }
    };

    const getClientCard = async () => {
        const data = {
            shop_id: shopId,
            id: clientInfo?.id,
        };
        const result = await dispatch(clientCardList(data));
        if (result.type === clientCardList.fulfilled.toString()) {
            setClientCardArray(result.payload.data.cards);
        }
    };

    useEffect(() => {
        getClientCard();
        getService();
    }, []);

    const addRow = (item: IDisocunt) => {
        // const newRow = { id: discountRow.length + 1, field1: '', field2: '' };
        const newRow = {
            id: discountRow.length + 1,
            services: null,
            discount: null,
            discount_type: '$',
        };
        setDiscountRow([...discountRow, newRow]);
    };

    const handleInputChange = (id: number, fieldName: string, value: number) => {
        setDiscountRow((prevRows) => prevRows.map((row, index) => (index === id ? { ...row, [fieldName]: value } : row)));
    };

    const removeRow: any = (id: any) => {
        const updatedRows = discountRow.filter((row, index) => index !== id);
        setDiscountRow(updatedRows);
    };

    return (
        <div className="w-full">
            <BarLoader className="p-0" isLoading={loading} />
            <div className="p-8">
                <h2 className="text-xl font-bold mb-3 leading-[140%] -tracking-[0.4px]">{t('Cards on file')}</h2>
                {clientCardArray && clientCardArray.length > 0 ? (
                    <>
                        {clientCardArray.map((item: any, index: number) => (
                            <Card key={index} content={<PaymentCard cardData={item} />} className="rounded-3xl bg-white py-5 px-5 mb-5 last:mb-0" />
                        ))}
                        <div className="w-full flex justify-between mt-6 mb-5 items-center">
                            <div>
                                <h2 className="font-bold text-sm leading-[140%] -tracking-[0.32px] cursor-pointer mb-1">{t('Client specific discounts')}</h2>
                                <p className="text-center text-xs text-secondaryTxtColor leading-[150%] -tracking-[0.3px]">{t('client_discount_description')}</p>
                            </div>

                            <Toggle
                                // label="Out of town"
                                labelPosition="right"
                                id="out_of_town"
                                isChecked={isClientDiscount}
                                onChange={handleToggle}
                                className=""
                                switchClassName="bg-secondaryTxtColor"
                            />
                        </div>

                        {isClientDiscount && (
                            <>
                                {discountRow.map((item: any, index: number) => (
                                    <div className="flex -mx-[10px] mb-5" key={index}>
                                        <div className="w-1/2 px-[10px]">
                                            <SelectBox
                                                label={t('Select services')}
                                                name="services"
                                                placeholder={t('Select services')}
                                                value={serviceOptions.filter((srvc: any) => item.services === srvc.id)}
                                                options={serviceOptions}
                                                classComp=""
                                                onChangeFunc={(e: any) => {
                                                    handleInputChange(index, 'services', e.id);
                                                }}
                                            />
                                        </div>
                                        <div className="w-1/2 px-[10px] flex">
                                            <InputWithToggle
                                                label={t('Enter Discount Amount')}
                                                type="number"
                                                name="discount"
                                                placeholder={t('Enter Discount Amount')}
                                                toggleClassName="bg-white text-mainTextColor drop-shadow-cardShadow"
                                                value={item.discount}
                                                toggleValue={item.discount_type}
                                                setselected={(e: any) => {
                                                    handleInputChange(index, 'discount_type', e);
                                                }}
                                                onChange={(e: any) => {
                                                    handleInputChange(index, 'discount', e.target.value);
                                                }}
                                            />
                                            <div className="ml-2 h-[48px] flex items-center self-end">
                                                <IoMdClose size={20} onClick={() => removeRow(index)} className="text-dangerText cursor-pointer" />
                                            </div>
                                        </div>
                                    </div>
                                ))}

                                <div className="flex justify-end ">
                                    <Button className="btn text-primary text-sm font-medium max-w-[140px]" onClick={addRow}>
                                        + {t('Add Discount')}
                                    </Button>
                                </div>
                            </>
                        )}
                    </>
                ) : !loading && clientCardArray ? (
                    <p className="text-center text-secondaryTxtColor">{t('No added card')}</p>
                ) : (
                    ''
                )}
            </div>
        </div>
    );
};

export default PaymentMethods;
