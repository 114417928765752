import React, { FC, useState } from 'react';
import { IDefaultProp, IEntryOption, IInfoProp } from './WaitList.interface';
import { Link } from 'react-router-dom';
import { Images } from 'src/theme';
import DropDownWithIcon from 'src/components/DropDownWithIcon';
import { MdOutlineMoreVert } from 'src/assets/customsvg';
import { IoMdClose } from 'react-icons/io';
import { SlArrowRight } from 'react-icons/sl';
import ClientDetail from '../../ClientDetail';
import moment from 'moment';
import ServiceDetail from '../../ServiceDetail';
import { axiosDelete } from 'src/utils/requestClient';
import { API } from 'src/constants/api';
import { useAppSelector } from 'src/redux/hooks';
import { selectShopInfo } from 'src/app/Auth/Login/Login.slice';
import { errorCode } from 'src/constants/errorCode';
import { toast } from 'react-toastify';
import Button from 'src/components/Button';
import Popup from 'src/components/Popup';
export const actionBtnIcon = [
    {
        label: <span>Remove</span>,
        value: 'remove_entry',
        icon1: <IoMdClose className="text-dangerText" size={20} />,
        icon1Position: 'left',
        icon2: <SlArrowRight className="text-secondaryTxtColor" />,
        icon2Position: 'right',
    },
];
const Info: FC<IInfoProp> = ({ handleAction, listDetail, setListDetail, getWaitlist }) => {
    const shop: any = useAppSelector(selectShopInfo);
    const shopId = shop.id;
    const handleWaitlist = () => {
        handleAction('list');
    };
    console.log('listDetail', listDetail);
    const [isRemove, setIsRemove] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleAppointmentEvent = async (e: any) => {
        const value = e.value;
        console.log('value', value);
        if (value === 'remove_entry') {
            setIsLoading(true);
            setIsRemove(true);
            hanldeRemoveEntry();
        }
    };

    const confirmCancelPopupContent = (
        <div className="text-center">
            <h3 className="mb-5 text-xl font-bold text-mainTextColor -tracking-[0.6px]">Cancel appointment</h3>
            <p className="mb-8 text-sm font-normal text-secondaryTxtColor -tracking-[0.3px]">Are you sure you want to cancel this appointment?</p>
            <div className="w-full">
                <Button className="w-full fl-btn px-[10px] btn_primary text-base text-center justify-center font-bold mb-3" onClick={() => setIsRemove(false)}>
                    Not now
                </Button>
                <Button className="w-full fl-btn text-base font-bold btn_outline_black px-[10px] justify-center" disabled={isLoading} onClick={() => hanldeRemoveEntry}>
                    {"Yes, I'm sure"}
                </Button>
            </div>
        </div>
    );

    const [appointmentOptions, setAppointmentOptions] = useState<IEntryOption[]>(actionBtnIcon);
    const hanldeRemoveEntry = async () => {
        try {
            const response = await axiosDelete(API.WAITLIST.DELETE, undefined, {
                shop_id: shopId,
                id: listDetail.waitListDetail.id,
            });
            if (response.data?.status === errorCode.success || response.data?.status === errorCode.updateSuccess) {
                await getWaitlist();
                await setListDetail(null);
                return;
            }

            throw response.data;
        } catch (err: any) {
            toast.error(err?.message);
        } finally {
            setIsLoading(true);
        }
    };
    return (
        <>
            <div className="p-4 flex items-center">
                <Link
                    className="border border-lineColor rounded-lg p-1 mr-3"
                    to=""
                    onClick={() => {
                        setListDetail(null);
                        // handleAction('list');
                    }}
                >
                    <img src={Images.IconChevronRight} alt="" className="" />
                </Link>

                <h4 className="text-base font-bold leading-[140%] -tracking-[0.16px]">Waitlist Entry</h4>

                <DropDownWithIcon
                    dropwDownContainerClass="ml-auto"
                    className=""
                    labelIcon={<MdOutlineMoreVert stroke="#1642C5" currentColor="#1642C5" />}
                    labelActiveIcon={<MdOutlineMoreVert stroke="#E3E7EF" currentColor="#1F293C" />}
                    dropDownMenuClassName="w-[324px] top-[100%] py-6"
                    dropDownMenuItemClassName="border-b text-left py-5 last:border-0"
                    dropDownMenuLinkClassName="mx-3"
                    data={appointmentOptions}
                    setselected={handleAppointmentEvent}
                />
            </div>

            <div className="p-4 flex-1 overflow-y-auto">
                <ClientDetail sidebarState={listDetail} isNewAppoinment={false} className=" mb-4" />
                {listDetail.waitListDetail.waitlist_services.map((item: any, index: any) => {
                    const serviceDetail = {
                        qty: item.quantity,
                        price: item.price,
                        is_custom_price: item.is_custom_price,
                        name: item.staff_service.service.name,
                        staff_name: listDetail.selectedOption.staff_name,
                        time_slot: listDetail.selectedOption.time_slot,
                        duration: item.staff_service.duration,
                    };
                    return (
                        <ServiceDetail
                            key={index}
                            index={index}
                            serviceInfo={serviceDetail}
                            handleRemoveService={() => {}}
                            handleQtyChange={() => {}}
                            handleCustomPriceChange={() => {}}
                            handleIsCustomPrice={() => {}}
                            isWaitList={true}
                        />
                    );
                })}
                <div className="flex items-center my-4">
                    <i className="mr-3">
                        <img src={Images.iconWallClockOutlinePrimary} alt="" className="w-4" />
                    </i>
                    <p className="font-medium">
                        <span className="text-secondaryTxtColor">Created on:</span>
                        {` ${moment(`${listDetail.waitListDetail.wait_date}`).format('yyyy-MM-DD')}`}
                    </p>
                </div>
                <div className="flex items-center mb-4">
                    <i className="mr-3">
                        <img src={Images.IconUserOutline} alt="" className="w-4" />
                    </i>
                    <p className="font-medium">
                        <span className="text-secondaryTxtColor">Created by:</span>
                        {` ${listDetail.waitListDetail.created_by}`}
                    </p>
                </div>
            </div>
            {/* {isRemove && <Popup size="max-w-[407px]" className="p-8 pb-10" handleClose={() => setIsRemove(false)} content={confirmCancelPopupContent} />} */}
        </>
    );
};

export default Info;
