import React from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import InputWithLabel from 'src/components/InputWithLabel';

const OnlineLinks = ({ control, errors }: any) => {
    const { t } = useTranslation();

    return (
        <div className="flex flex-col">
            <Controller
                name="website"
                control={control}
                render={({ field: { onChange, value } }: any) => (
                    <div className="flex flex-col w-full mb-5">
                        <InputWithLabel name="website" label={t('Website URL')} placeholder={t('www.yoursite.com')} value={value} onChange={onChange} error={!!errors.website} />
                        {errors.website && <p className="text-error">{errors.website.message}</p>}
                    </div>
                )}
            />
            <Controller
                name="google_review"
                control={control}
                render={({ field: { onChange, value } }: any) => (
                    <div className="flex flex-col w-full mb-5">
                        <InputWithLabel
                            name="google_review"
                            label={t('Google Reviews')}
                            placeholder={t('www.google.com/search?q=your_business_name')}
                            value={value}
                            onChange={onChange}
                            error={!!errors.google_review}
                        />
                        {errors.google_review && <p className="text-error">{errors.google_review.message}</p>}
                    </div>
                )}
            />
            <Controller
                name="facebook_page"
                control={control}
                render={({ field: { onChange, value } }: any) => (
                    <div className="flex flex-col w-full mb-5">
                        <InputWithLabel name="facebook_page" label={t('Facebook Page')} placeholder={t('www.facebook.com/yoursite')} value={value} onChange={onChange} error={!!errors.facebook_page} />
                        {errors.facebook_page && <p className="text-error">{errors.facebook_page.message}</p>}
                    </div>
                )}
            />
            <Controller
                name="instagram_page"
                control={control}
                render={({ field: { onChange, value } }: any) => (
                    <div className="flex flex-col w-full">
                        <InputWithLabel
                            name={t('Instagram Account')}
                            label="Instagram page"
                            placeholder={t('www.instagram.com/yourprofile')}
                            value={value}
                            onChange={onChange}
                            error={!!errors.instagram_page}
                        />
                        {errors.instagram_page && <p className="text-error">{errors.instagram_page.message}</p>}
                    </div>
                )}
            />
        </div>
    );
};

export default OnlineLinks;
