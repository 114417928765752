import { ROUTES } from 'src/constants/routes';
import { Images } from 'src/theme';

export const DefaultNavSections = [
    {
        title: 'Management',
        items: [
            { to: ROUTES.DASHBOARD, icon: Images.HomeIcon, text: 'Home', permission: 'dashboard.read' },
            { to: ROUTES.CALENDAR, icon: Images.Calendar, text: 'Calendar', permission: 'calendar.read' },
            { to: ROUTES.SETTINGS.CLIENT, icon: Images.Clients, text: 'Clients', permission: 'client.read' },
            { to: ROUTES.ANALYTICS, icon: Images.Analytics, text: 'Analytics', permission: 'analytics.read' },
            { to: ROUTES.SETTINGS.PAYMENT, icon: Images.Payment, text: 'Payments', permission: 'payment.read' },
            { to: '#', icon: Images.Marketing, text: 'Marketing', badge: 'Coming soon', disabled: true, permission: 'marketing.read' },
            { to: '#', icon: Images.Store, text: 'Store', badge: 'Coming soon', disabled: true, permission: 'store.read' },
        ],
    },
    {
        title: 'Settings',
        items: [
            { to: ROUTES.SETTINGS.GENERAL_SETTING, icon: Images.Setting, text: 'General Settings', permission: 'setting.read' },
            { to: ROUTES.SETTINGS.STAFF, icon: Images.Staff, text: 'Staff', permission: 'staff.read' },
            { to: ROUTES.SETTINGS.ADMIN, icon: Images.Staff, text: 'Admin', permission: 'admin.read' },
            { to: ROUTES.SETTINGS.LOCATION, icon: Images.Location, text: 'Location', permission: 'location.read' },
            { to: ROUTES.SETTINGS.SERVICES, icon: Images.Service, text: 'Services', permission: 'services.read' },
        ],
    },
    {
        title: 'Customization',
        items: [{ to: ROUTES.CUSTOMIZATION.APPEARANCE, icon: Images.Web, text: 'Appearance', permission: 'appearance.read' }],
    },
];
