import React, { useMemo, useState } from 'react';
import WrapperHeading from 'src/components/WrapperHeading';
import AccountSettings from './AccountSettings/AccountSettings';
import BusinessDetails from './BusinessDetails/BusinessDetails';
import Support from './Support/Support';
import Notifications from './Notifications/Notifications';
import Hyperlinks from './Hyperlinks/Hyperlinks';
import LoyaltyReferral from './LoyaltyReferral/LoyaltyReferral';
import { useTranslation } from 'react-i18next';

const Setting = () => {
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useState({
        name: t('Account settings'),
        component: <AccountSettings />,
    });

    const tabs = useMemo(
        () => [
            {
                name: t('Account settings'),
                component: <AccountSettings />,
            },
            {
                name: t('Business details'),
                component: <BusinessDetails />,
            },
            {
                name: t('Notifications'),
                component: <Notifications />,
            },
            {
                name: t('Loyalty & Referral'),
                component: <LoyaltyReferral />,
            },
            {
                name: t('Hyperlinks'),
                component: <Hyperlinks />,
            },
            {
                name: t('Support'),
                component: <Support />,
            },
        ],
        [],
    );

    const handleTabClick = (tab: any) => async (event: { preventDefault: () => void }) => {
        event.preventDefault();
        setActiveTab(tab);
    };

    return (
        <div className="inner-page-wrape">
            <div className="flex items-center">
                <WrapperHeading content="Settings" />
            </div>
            <div className="fl-tab-underline w-full mt-8">
                {tabs.map((tab, index) => (
                    <button key={index} type="button" className={`fl-tab-link ${activeTab.name === tab.name ? 'active' : ''}`} onClick={handleTabClick(tab)}>
                        {tab.name}
                    </button>
                ))}
            </div>
            <div className="mt-8">{activeTab.component}</div>
        </div>
    );
};

export default Setting;
