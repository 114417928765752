import React, { useEffect, useRef } from 'react';
import { HiOutlineX } from 'react-icons/hi';
import { IDrawerProps } from './Drawer.interface';
import BarLoader from '../BarLoader';

const Drawer = ({
    children,
    isOpen,
    handleClose,
    title,
    isEditable,
    isEditClose,
    handleEditable,
    classDrawer,
    classDrawerHeader,
    drawerHeight,
    refName,
    handleClickOutside,
    isDisplayLoading = false,
    isLoading = false,
    isDrawerWidth = false,
}: IDrawerProps) => {
    const detailRef = useRef<HTMLDivElement>(null);
    const onOutsideClick = (event: any) => {
        const withinDetailRef = detailRef.current && detailRef.current.contains(event.target);
        const withinDeletePopup = event.target.closest('.deletePopup');
        const withinClearIndicator = event.target.closest('.fwpo_select__clear-indicator');

        if (!withinDetailRef && !withinDeletePopup && !withinClearIndicator) {
            handleClickOutside();
        }
    };
    useEffect(() => {
        if (handleClickOutside) {
            document.addEventListener('mousedown', onOutsideClick);
            return () => {
                document.removeEventListener('mousedown', onOutsideClick);
            };
        }
    }, []);

    return (
        <main
            className={`fixed overflow-hidden z-40 bg-mainTextColor bg-opacity-30 left-0 top-0 right-0 bottom-0 transform ease-in-out ${
                isOpen ? ' transition-opacity opacity-100 duration-500 w-full' : ' delay-500 opacity-0 translate-x-full'
            }`}
        >
            <section
                ref={detailRef}
                className={`${classDrawer} bg-white ${isDrawerWidth ? '' : 'w-[1086px]'} ml-auto shadow-xl h-screen delay-400 duration-500 ease-in-out transition-all transform${
                    isOpen ? ' translate-x-0 ' : ' translate-x-full '
                }`}
            >
                <article className={`${classDrawer} relative h-screen `}>
                    <header className={`${classDrawerHeader} py-5 px-11 border-b border-lineColor flex justify-between items-center `}>
                        <h2 className="font-bold text-xl -tracking-[0.4px]">{title}</h2>
                        {/* {isEditable && (
                            <span className="text-primary ms-3 cursor-pointer">
                                <BiSolidEdit
                                    size={24}
                                    onClick={handleEditable}
                                />
                            </span>
                        )}
                        {isEditClose && (
                            <span className="text-primary ms-3 cursor-pointer">
                                <BiSolidXSquare
                                    size={24}
                                    onClick={handleEditable}
                                />
                            </span>
                        )} */}
                        <div className="rounded-md transition-all delay-100 cursor-pointer hover:bg-border300" onClick={handleClose}>
                            <HiOutlineX size={24} />
                        </div>
                    </header>

                    <div className={`${drawerHeight} drawer_body h-[calc(100vh-60px)] max-h-screen overflow-y-auto  `}>
                        {isDisplayLoading && <BarLoader isLoading={isLoading} />}
                        <div className="flex min-h-full">{children}</div>
                    </div>
                </article>
            </section>
        </main>
    );
};

Drawer.defaultProps = {
    isOpen: false,
    title: '',
    classDrawer: '',
    drawerHeight: '',
    isEditable: false,
};

export default Drawer;
