import { Tooltip } from 'flowbite-react';
import React, { FC } from 'preact/compat';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RxInfoCircled } from 'react-icons/rx';
import { Images } from 'src/theme';
import { capitalizeFirstLetter, getShortName } from 'src/utils/global-functions';
interface StaffCardProps {
    staffDetails: any;
}
const StaffCard: FC<StaffCardProps> = ({ staffDetails }) => {
    const { t } = useTranslation();
    const [servicesString, setServicesString] = useState(null);
    const [isImageError, setIsImageError] = useState<boolean>(false);

    useEffect(() => {
        const locationData = staffDetails.locations;

        const serviceNames = locationData.length > 1 ? locationData.map((entry: any) => entry.name) : null;
        const servicesString2 = serviceNames ? serviceNames.join(', ') : null;
        setServicesString(servicesString2);
    }, []);
    return (
        <div className="text-center">
            {!isImageError && staffDetails.profile_image_url ? (
                <figure className="w-[80px] h-[80px] mx-auto rounded-full bg-primary mb-4 relative">
                    <img src={staffDetails.profile_image_url} alt="" className="w-full h-full object-cover rounded-full cursor-pointer" onError={() => setIsImageError(true)} />
                </figure>
            ) : (
                <figure className="w-[80px] h-[80px] mx-auto rounded-full bg-primary mb-4 relative">
                    <div className="text-white font-me leading-[140%] -tracking-[0.24px] rounded-full text-2xl w-full h-full flex items-center justify-center cursor-pointer">
                        {getShortName(`${staffDetails.first_name ?? ''} ${staffDetails.last_name ?? ''}`)}
                    </div>
                    <div className="absolute bg-white right-0 bottom-0 w-6 h-6 border border-lineColor rounded-full flex items-center justify-center">
                        <img src={Images.iconWallClockOutlinePrimary} alt="" className="w-3 h-3 cursor-pointer" />
                    </div>
                </figure>
            )}
            {/* <figure className="w-[80px] h-[80px] mx-auto rounded-full bg-primary mb-4 relative">
                <div className="text-white font-me leading-[140%] -tracking-[0.24px] rounded-full text-2xl w-full h-full flex items-center justify-center">
                    AB
                </div>
                <div className="absolute bg-white right-0 bottom-0 w-6 h-6 border border-lineColor rounded-full flex items-center justify-center">
                    <img
                        src={Images.iconWallClockOutlinePrimary}
                        alt=""
                        className="w-3 h-3"
                    />
                </div>
            </figure> */}

            <h3 className="text-base font-bold text-mainTextColor leading-[140%] -tracking-[0.4px] mb-1 cursor-pointer">{`${staffDetails.first_name} ${staffDetails.last_name}`}</h3>

            <p className="mb-2 text-xs font-normal text-secondaryTxtColor leading-[150%] -tracking-[0.3px]">{staffDetails.role.name}</p>
            <p className="mb-5 text-sm font-medium text-primary leading-[140%] -tracking-[0.3px] flex justify-center items-center">
                <i className="icon mr-2 w-4 h-4">
                    <img src={Images.mapLocationPin} alt="" className="w-full " />
                </i>
                {staffDetails.locations[0].name}

                {servicesString && (
                    <Tooltip className="bg-mainTextColor text-xs " content={servicesString}>
                        <RxInfoCircled className="text-sm text-secondaryTxtColor ml-2" />
                    </Tooltip>
                )}
            </p>
            <div className="flex justify-center w-full">
                <div className="py-[4px] w-1/2 pr-10 border-r border-lineColor flex justify-end">
                    <div className="">
                        <p className="text-xs text-left font-normal text-secondaryTxtColor leading-[150%] -tracking-[0.12px] mb-2">{t('Clients')}</p>
                        <div className="flex items-center ">
                            <i className="icon mr-2 w-4 h-4">
                                <img src={Images.iconClientOutlinePrimary} alt="" className="w-full " />
                            </i>
                            <span className="text-xs font-medium leading-[140%]">{staffDetails.users_count}</span>
                        </div>
                    </div>
                </div>
                <div className="py-[4px] w-1/2 pl-10 flex justify-start">
                    <div className="">
                        <p className="text-xs text-left font-normal text-secondaryTxtColor leading-[150%] -tracking-[0.12px] mb-2">
                            {capitalizeFirstLetter(staffDetails.pay_structure.pay_structure_type)}
                        </p>
                        <div className="flex items-center ">
                            <i className="icon mr-2 w-4 h-4">
                                <img
                                    src={staffDetails.pay_structure.pay_structure_type === 'commission' ? Images.iconMoneyOutlinePrimary : Images.iconRentOutlinePrimary}
                                    alt=""
                                    className="w-full h-full object-cover"
                                />
                            </i>
                            <span className="text-xs font-medium leading-[140%]">
                                {staffDetails.pay_structure.pay_structure_type === 'commission' ? `%${staffDetails.pay_structure.commission}` : `$${staffDetails.pay_structure.rent_money}`}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StaffCard;
