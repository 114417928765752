import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { API } from 'src/constants/api';
import { axiosGet } from 'src/utils/requestClient';

const initialState = {
    plandetails: [],
    selectedPlan: {},
    loading: false,
    hasErrors: false,
    hasErrorsList: {},
};

export const ChoosePlan = createAsyncThunk('/shop/plans', async (_, { rejectWithValue }) => {
    try {
        const response: any = await axiosGet(API.PLAN.GET);
        if (response.data.status === 200) {
            return response.data;
        }

        return rejectWithValue({
            data: response,
        } as any);
    } catch (err: any) {
        if (!err.response) {
            throw err;
        }

        return rejectWithValue({
            message: err.response.data,
        } as any);
    }
});

export const MyPlanSlice = createSlice({
    name: 'my-plan',
    initialState,
    reducers: {
        setAccountDeatil: (state, { payload }) => {
            // Modify payload to action
            // state.plandetails[payload.id] = {
            //     ...state.plandetails,
            //     name: payload.data.first_name,
            //     price: payload.data.last_name,
            //     description: payload.data.email,
            // };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(ChoosePlan.pending, (state) => {
                state.loading = true;
                state.hasErrors = false;
            })
            .addCase(ChoosePlan.fulfilled, (state, { payload }) => {
                const PlanArr = payload.data;
                state.plandetails = PlanArr;
                state.loading = false;
                state.hasErrors = false;
            })
            .addCase(ChoosePlan.rejected, (state, { payload }: { payload: any }) => {
                state.loading = false;
                state.hasErrors = true;
            });
    },
});

export const { setAccountDeatil } = MyPlanSlice.actions;
