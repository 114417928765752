import { createSlice } from '@reduxjs/toolkit';
import { IInitialState } from './Notification.interface';
import { RootState } from 'src/redux/store';

const initialState: IInitialState = {
    data: {
        notificationToken: null,
    },
    loading: false, // Add the loading property here
    error: null,
};

export const NotificationSlice = createSlice({
    name: 'shop/notification',
    initialState,
    reducers: {
        setNotificationToken: (state, { payload }) => {
            state.data.notificationToken = payload;
        },
    },
    extraReducers: (builder) => {},
});
export const { setNotificationToken } = NotificationSlice.actions;

export const { reducer: accountReducer } = NotificationSlice;
export const notificationInfo = (state: RootState) => state.Notification.data;
