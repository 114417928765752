import React, { useCallback, useState } from 'react';
import Button from 'src/components/Button';
import Popup from 'src/components/Popup';
import Toggle from 'src/components/Toggle/Toggle';
import Loyalty from './Loyalty';
import Referral from './Referral';
import { Images } from 'src/theme';
import Disable from './Disable';
import { axiosPatch } from 'src/utils/requestClient';
import { API } from 'src/constants/api';
import { selectShopInfo, selectShopSetting, shopDetail } from 'src/app/Auth/Login/Login.slice';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { errorCode } from 'src/constants/errorCode';
import { toast } from 'react-toastify';

const initAction = {
    loyalty: false,
    referral: false,
    disable_loyalty: false,
    disable_referral: false,
};

const LoyaltyReferral = () => {
    const [action, setAction] = useState(initAction);
    const shop: any = useAppSelector(selectShopInfo);
    const shopSetting: any = useAppSelector(selectShopSetting);
    const dispatch = useAppDispatch();
    const shopId = shop.id;
    const handleAction = (type: string) => () => {
        setAction((old) => ({ ...old, [type]: true }));
    };
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleModalClose = useCallback(() => {
        setAction(initAction);
    }, []);

    const handleStatusUpdate = async (type: boolean) => {
        action.disable_loyalty && setUpdateLoyalty(type);
        action.disable_referral && setUpdatereferral(type);
    };
    const setUpdateLoyalty = async (type: boolean) => {
        setIsLoading(true);
        try {
            const response = await axiosPatch(
                API.REFER.REFER_STATUS,
                { status: type },
                {
                    shop_id: shopId,
                },
            );
            if (response.data?.status === errorCode.success || response.data?.status === errorCode.updateSuccess) {
                await dispatch(shopDetail());
                handleModalClose();
                return;
            }

            throw response.data;
        } catch (err: any) {
            toast.error(err?.message);
        } finally {
            setIsLoading(false);
        }
    };
    const setUpdatereferral = async (type: boolean) => {
        setIsLoading(true);

        try {
            const response = await axiosPatch(
                API.REFER.CONFIG_STATUS,
                { status: type },
                {
                    shop_id: shopId,
                },
            );
            if (response.data?.status === errorCode.success || response.data?.status === errorCode.updateSuccess) {
                await dispatch(shopDetail());
                handleModalClose();
                return;
            }

            throw response.data;
        } catch (err: any) {
            toast.error(err?.message);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="w-[full] rounded-2xl bg-white border-[1px] border-lineColor ">
            <div className="detail-page-title-block">
                <h2 className="text-xl font-bold leading-[140%] -tracing-[0.2px]">Loyalty & Referral</h2>
            </div>
            <>
                <div className="detail-page-data-block">
                    <div className="title-block">
                        <h3 className="title-h3">Configure loyalty points</h3>
                        <p className="text-xs text-secondaryTxtColor leading-[150%] -tracking-[0.12px]">Customers can use the earned loyalty points to receive the service.</p>
                    </div>
                    <div className="data-wrap-block max-w-[512px] 2xl:max-w-[700px]">
                        <div className="flex flex-wrap">
                            <div className="border border-lineColor px-5 py-6 flex items-center rounded-3xl flex-1 mr-8">
                                <div className="flex items-center">
                                    <i className="icon w-[52px] h-[52px] basis-[52px] grow-0 shrink-0 flex items-center justify-center mr-4 bg-inputBg rounded-2xl">
                                        <img src={Images.IconLoyaltiy} alt="" className="w-8" />
                                    </i>
                                    <p className="text-base font-bold leading-[140%] -tracking-[0.16px]">Loyalty points</p>
                                </div>
                                <Button className="fl-btn btn_primary w-[156px] ml-auto text-sm max-h-[40px]" onClick={handleAction('loyalty')}>
                                    Configure loyalty
                                </Button>
                            </div>
                            <div className="ml-auto">
                                <Toggle
                                    id="first_reminder"
                                    labelPosition="left"
                                    isChecked={shopSetting?.loyalty_spend?.status ?? false}
                                    isDisabled={shopSetting?.loyalty_spend ? false : true}
                                    onChange={handleAction('disable_loyalty')}
                                    switchClassName="bg-secondaryTxtColor"
                                    labelClassName="mt-10"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="detail-page-data-block border-0">
                    <div className="title-block">
                        <h3 className="title-h3">Configure referral program</h3>
                        <p className="text-xs text-secondaryTxtColor leading-[150%] -tracking-[0.12px]">Customers can use the earned loyalty points to receive the service.</p>
                    </div>
                    <div className="data-wrap-block max-w-[512px] 2xl:max-w-[700px]">
                        <div className="flex flex-wrap">
                            <div className="border border-lineColor px-5 py-6 flex items-center rounded-3xl flex-1 mr-8">
                                <div className="flex items-center">
                                    <i className="icon w-[52px] h-[52px] basis-[52px] grow-0 shrink-0 flex items-center justify-center mr-4 bg-inputBg rounded-2xl">
                                        <img src={Images.IconReferral} alt="" className="w-8" />
                                    </i>
                                    <p className="text-base font-bold leading-[140%] -tracking-[0.16px]">Referral program</p>
                                </div>
                                <Button className="fl-btn btn_primary w-[156px] ml-auto text-sm max-h-[40px]" onClick={handleAction('referral')}>
                                    Configure referral
                                </Button>
                            </div>
                            <div className="ml-auto">
                                <Toggle
                                    id="first_reminder"
                                    labelPosition="left"
                                    isChecked={shopSetting?.referral_config?.status ?? false}
                                    isDisabled={shopSetting?.referral_config ? false : true}
                                    value={shopSetting?.referral_config?.status ?? false}
                                    onChange={handleAction('disable_referral')}
                                    switchClassName="bg-secondaryTxtColor"
                                    labelClassName="mt-10"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </>

            {action.loyalty && <Popup size="max-w-[896px]" handleClose={handleModalClose} content={<Loyalty handleClose={handleModalClose} />} />}
            {action.referral && <Popup size="max-w-[896px]" handleClose={handleModalClose} content={<Referral handleClose={handleModalClose} />} />}
            {(action.disable_loyalty || action.disable_referral) && (
                <Popup
                    size="max-w-[407px]"
                    className="p-8 pb-10"
                    handleClose={handleModalClose}
                    content={
                        <Disable
                            handleClose={handleModalClose}
                            handleStatusUpdate={(type: boolean) => handleStatusUpdate(type)}
                            isLoading={isLoading}
                            value={action.disable_loyalty ? shopSetting?.loyalty_spend?.status ?? false : shopSetting?.referral_config?.status ?? false}
                        />
                    }
                />
            )}
        </div>
    );
};

export default LoyaltyReferral;
