import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { API } from 'src/constants/api';
import { axiosPost } from 'src/utils/requestClient';
import { IInitialState } from '../AccountSettings/ChangePassword.interface';
import { errorCode } from 'src/constants/errorCode';

interface ErrorType {
    message: string;
    status?: string;
    data?: any;
}

const initialState: IInitialState = {
    loading: false,
    data: null,
    error: null,
};

export const contactUs = createAsyncThunk('/shop/contact-us', async (payload: any, { rejectWithValue }) => {
    try {
        const response = await axiosPost(API.USER.CREATE_CONTACT_US, payload, { shop_id: payload.shop_id });
        if (response.data.status === errorCode.updateSuccess) {
            return response.data;
        }

        return rejectWithValue(response as ErrorType);
    } catch (err: any) {
        if (!err.response) {
            throw err;
        }

        return rejectWithValue(err.response.data as ErrorType);
    }
});

export const ContactUsSlice = createSlice({
    name: 'contactUs',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(contactUs.pending, (state) => {
                state.loading = true;
            })
            .addCase(contactUs.fulfilled, (state, { payload }: any) => {
                state.loading = false;
                state.data = payload;
            })
            .addCase(contactUs.rejected, (state, { payload, error }: any) => {
                state.loading = false;
                state.data = payload;
                if (payload) {
                    state.error = payload;
                } else {
                    state.error = error as ErrorType;
                }
            });
    },
});
