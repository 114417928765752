import React, { useCallback, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import Button from 'src/components/Button';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { profile } from './Profile.slice';
import { errorCode } from 'src/constants/errorCode';
import { IFieldKey, IProfileField } from './Profile.interface';
import InputWithLabel from 'src/components/InputWithLabel';
import { toast } from 'react-toastify';
import { selectUserInfo, shopDetail } from '../../Auth/Login/Login.slice';
import CropperModal from 'src/components/CropperModal/CropperModal';
import { Images } from 'src/theme';
import { ReactComponent as Cloud } from 'src/assets/svgs/upload 1.svg';
import { convertBase64ToFile } from 'src/utils/global-functions';
import { s3Upload } from 'src/utils/s3Operations';
import Popup from 'src/components/Popup';
import UpdateEmail from './UpdateEmail';
import UpdatePhone from './UpdatePhone';
import { TfiPencilAlt } from 'react-icons/tfi';
import { useTranslation } from 'react-i18next';

// const SUPPORTED_FORMATS = ['image/jpg', 'image/png'];
// const FILE_SIZE_LIMIT = 1 * 1024 * 1024; // 4 MB

const initAction = {
    email: false,
    phone: false,
};

const Profile = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const user = useAppSelector(selectUserInfo);
    const profileImageUrl = user.profile_image_url;

    const [cropData, setCropData] = useState<any>(profileImageUrl || '');
    const [image, setImage] = useState<any>(profileImageUrl || '');
    const [upload, setUpload] = useState(false);
    const [file, setFile] = useState<any>({ convertedFile: '', filename: '' });

    const [action, setAction] = useState(initAction);

    const schema = Yup.object({
        first_name: Yup.string().required(t('First name required')),
        last_name: Yup.string().required(t('Last name required')),
        email: Yup.string().required(t('Email required')),
        phone_country_code: Yup.string().required('This field is required'),
        phone: Yup.string().required(t('Phone number required')),
        language: Yup.string().required(t('This field is required')),
        profile_image_name: Yup.string().required(t('Profile required')),
        /* profile_image_file: Yup.mixed()
            .required('This field is required')
            .test(
                'fileSize',
                'File size is too large',
                (value: any) => value && value.size <= FILE_SIZE_LIMIT,
            )
            .test(
                'fileFormat',
                'Unsupported file format',
                (value: any) => value && SUPPORTED_FORMATS.includes(value.type),
            ), */
    }).required();

    const {
        handleSubmit,
        control,
        formState: { errors },
        setError,
        setValue,
    } = useForm<IProfileField>({
        resolver: yupResolver(schema),
        defaultValues: {
            first_name: user.first_name,
            last_name: user.last_name,
            email: user.email,
            phone_country_code: user.phone_country_code,
            phone: user.phone,
            profile_image_name: user.profile_image_name,
            language: 'english',
        },
    });

    useEffect(() => {
        setValue('email', user.email);
        setValue('phone_country_code', user.phone_country_code);
        setValue('phone', user.phone);
    }, [user]);

    const handleSave = async (data: IProfileField) => {
        const result: any = await dispatch(profile(data));
        if (result.type === profile.fulfilled.toString()) {
            dispatch(shopDetail());
        } else if (result.type === profile.rejected.toString()) {
            const response = result.payload.data.data;
            if (response.status === errorCode.unprocessable) {
                if (response.data) {
                    Object.keys(response.data).forEach((field) => {
                        setError(field as IFieldKey, {
                            type: 'manual',
                            message: response.data[field][0],
                        });
                    });
                }
                return;
            }
            toast.error(response.message);
        }
    };

    const handleChange = async (data: IProfileField) => {
        if (file.convertedFile && file?.filename) {
            const imgUploadResult: any = await s3Upload(file?.convertedFile, `images/admin_profile/${file?.filename}`);
            if (imgUploadResult.status === 201) {
                const updatedData = {
                    ...data,
                    profile_image_name: file.filename,
                };
                handleSave(updatedData);
            }
        } else {
            handleSave(data);
        }
    };

    const imageUpload = (data: any) => {
        const appLogoImage = data;
        const fileInfo = convertBase64ToFile(appLogoImage);
        setFile(fileInfo);
        if (fileInfo && fileInfo.filename) {
            setValue('profile_image_name', fileInfo.filename);
        }
    };

    const onChangeCrop = (e: any) => {
        e.preventDefault();
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        // setValue('profile_image_file', files[0]);

        const reader = new FileReader();
        reader.onloadstart = () => {
            setImage('');
            setUpload(false);
        };
        reader.onloadend = () => {
            setImage(reader.result as any);
            setUpload(true);
        };
        reader.readAsDataURL(files[0]);
        e.target.value = null;
    };

    const handleAction = (type: string) => () => {
        setAction((old) => ({ ...old, [type]: true }));
    };

    const handleModalClose = useCallback(() => {
        setAction(initAction);
    }, []);

    return (
        <>
            <form onSubmit={handleSubmit(handleChange)}>
                <div className="flex flex-wrap">
                    <div className="w-full mb-6">
                        <div
                            className={`h-[180px] w-[180px] rounded-full bg-inputBg mx-auto broder border-inputBg flex justify-center items-center relative ${
                                errors.profile_image_name && 'is-invalid'
                            }`}
                        >
                            <div className="bg-contentBackground w-full">
                                <div className="w-[full]">
                                    <div className="w-auto">
                                        {!cropData ? (
                                            <div className="relative overflow-hidden">
                                                <input type="file" id="profile_image_name" className="upload-pic-input" onChange={onChangeCrop} accept="image/png, image/jpg, image/jpeg" />
                                                <label htmlFor="profile_image_name" className="upload-pic-label h-full w-full rounded-full relative flex justify-center items-center">
                                                    {''}
                                                    <div className="text-center">
                                                        <img src={Images.AddImgIcon} alt="" className="mb-2 w-10 h-10 inline-block" />
                                                        <p className="font-bold text-sm mb-1">Your image</p>
                                                        <p className="text-secondaryTxtColor ">
                                                            JPG or PNG.
                                                            <br /> Max size of 800K
                                                        </p>
                                                    </div>
                                                </label>
                                            </div>
                                        ) : (
                                            <>
                                                <div className="w-full h-full rounded-full relative overflow-hidden">
                                                    <img src={cropData} alt="" className="w-full h-full" />
                                                </div>
                                                <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center pr-[8px]">
                                                    <div className="bg-inputBg p-2 w-14 h-14 rounded-full flex justify-center items-center">
                                                        <Cloud width={20} height={20} />
                                                    </div>
                                                </div>
                                                <label htmlFor="profile_image_name" className="absolute left-0 top-0 z-20 cursor-pointer rounded-md px-8 py-2 overflow-hidden h-full w-full">
                                                    {''}
                                                    <input
                                                        type="file"
                                                        id="profile_image_name"
                                                        className="imgupload  w-full rounded-md text-mainTextColor px-8 py-2 mt-8 bg-btnclr"
                                                        style={{
                                                            visibility: 'hidden',
                                                        }}
                                                        onChange={onChangeCrop}
                                                        accept="image/png, image/jpg, image/jpeg"
                                                    />
                                                </label>
                                            </>
                                        )}
                                    </div>
                                    {errors.profile_image_name && <p className="text-error text-center w-full p-0">{errors.profile_image_name.message}</p>}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-wrap -mx-[10px]">
                        <div className="px-[10px] mb-5 w-1/2">
                            <Controller
                                name="first_name"
                                control={control}
                                render={({ field: { onChange, value } }: any) => (
                                    <div className="w-full">
                                        <InputWithLabel name="first_name" label={t('First Name')} placeholder={t('First Name')} value={value} onChange={onChange} error={!!errors.first_name} />
                                        {errors.first_name && <p className="text-error">{errors.first_name.message}</p>}
                                    </div>
                                )}
                            />
                        </div>
                        <div className="px-[10px] mb-5 w-1/2">
                            <Controller
                                name="last_name"
                                control={control}
                                render={({ field: { onChange, value } }: any) => (
                                    <div className="w-full">
                                        <InputWithLabel name="last_name" label={t('Last Name')} placeholder={t('Last Name')} value={value} onChange={onChange} error={!!errors.last_name} />
                                        {errors.last_name && <p className="text-error">{errors.last_name.message}</p>}
                                    </div>
                                )}
                            />
                        </div>
                        <div className="px-[10px] mb-5 w-1/2">
                            <Controller
                                name="email"
                                control={control}
                                render={({ field: { onChange, value } }: any) => (
                                    <div className="w-full relative">
                                        <InputWithLabel readOnly name="email" label={t('Email')} value={value} onChange={onChange} error={!!errors.email} />
                                        {errors.email && <p className="text-error">{errors.email.message}</p>}
                                        <div className=" absolute right-0 top-1">
                                            <TfiPencilAlt size={16} className="ml-2 text-primary cursor-pointer" onClick={handleAction('email')} />
                                        </div>
                                    </div>
                                )}
                            />
                        </div>
                        <div className="px-[10px] mb-5 w-1/2">
                            <Controller
                                name="phone"
                                control={control}
                                render={({ field: { onChange, value } }: any) => (
                                    <div className="w-full relative">
                                        <InputWithLabel readOnly name="phone" label={t('Phone Number')} value={value} onChange={onChange} error={!!errors.phone} />
                                        {errors.phone && <p className="text-error">{errors.phone.message}</p>}
                                        <div className=" absolute right-0 top-1">
                                            <TfiPencilAlt size={16} className="ml-2 text-primary cursor-pointer" onClick={handleAction('phone')} />
                                        </div>
                                    </div>
                                )}
                            />
                        </div>
                    </div>
                </div>
                <Button className="fl-btn btn_primary">{t('Save changes')}</Button>
                {upload && <CropperModal defaultCropType="Square" isCropType={false} imageUrl={image} imageUpload={imageUpload} setUpload={setUpload} setCropData={setCropData} />}
            </form>
            {action.email && <Popup size="max-w-[600px]" handleClose={handleModalClose} content={<UpdateEmail handleClose={handleModalClose} />} />}
            {action.phone && <Popup size="max-w-[600px]" handleClose={handleModalClose} content={<UpdatePhone handleClose={handleModalClose} />} />}
        </>
    );
};

export default Profile;
