import React, { FC, useState } from 'react';
import { FaRegTrashAlt } from 'react-icons/fa';
import Button from 'src/components/Button';
import { IPaymentMethod } from './MyAccount.interface';
import BarLoader from 'src/components/BarLoader';
import Toggle from 'src/components/Toggle/Toggle';
import { toast } from 'react-toastify';
import { axiosPost } from 'src/utils/requestClient';
import { API } from 'src/constants/api';
import { errorCode } from 'src/constants/errorCode';

const PaymentMethod: FC<IPaymentMethod> = ({ getCard, listCard, setPaymentMethodId, handleClose, handleAction, isLoading, setDefaultCard, defaultCard }) => {
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');

    const openAddPayMethodPopup = () => {
        if (!loading) {
            const paymentHandler = handleAction('payment');
            paymentHandler();
            handleClose();
        }
    };

    const removeCard = (itemId: any) => (event: { preventDefault: () => void }) => {
        if (!loading) {
            if (defaultCard?.id === itemId) {
                setMessage('You must set a default payment card. Please set another card as default to delete this card');
            } else {
                const removeCardHandler = handleAction('removepayment');
                setPaymentMethodId(itemId);
                setMessage('');
                removeCardHandler();
            }
        }
    };

    const handleDefaultCard = (cardItem: any) => async (event: any) => {
        if (defaultCard?.id === cardItem.id) {
            setMessage('You must set a default payment card. Please set another card as default to disable this card');
        } else {
            setLoading(true);
            try {
                const response = await axiosPost(API.STRIPE.DEFAULT, { payment_method_id: cardItem.id });

                if (response.data?.status === errorCode.success || response.data?.status === errorCode.updateSuccess) {
                    getCard();
                    setDefaultCard(cardItem);
                    setMessage('');
                    setLoading(false);
                    return;
                }

                throw response.data;
            } catch (err: any) {
                toast.error(err?.message);
            } finally {
                setDefaultCard(cardItem);
                setMessage('');
                setLoading(false);
            }
        }
    };

    return (
        <>
            <h2 className="text-[32px] text-center font-semibold mb-6 leading-[124%] -tracking-[1.2px]">Payment methods</h2>
            <div className="h-2">
                <BarLoader isLoading={isLoading || loading} />
            </div>
            <p className=" text-dangerText mb-2 text-center">{message}</p>
            {listCard?.length
                ? listCard.map((item: any) => (
                      <>
                          <div className={`block p-6 w-full border border-gray-200 drop-shadow-cardShadow rounded-3xl bg-white py-5 mb-3`}>
                              <div className="flex mb-[6px]">
                                  <h2 className="items-center text-lg font-bold">
                                      {item.card.brand} ending in {item.card.last4}
                                  </h2>
                                  <span onClick={() => {}} className="border border-lineColor rounded-xl w-9 h-9 flex items-center justify-center ml-auto cursor-pointer">
                                      {/* <TfiPencilAlt
                                      className=" text-primary"
                                      size={16}
                                  /> */}
                                      <FaRegTrashAlt className=" text-primary" size={16} onClick={removeCard(item.id)} />
                                  </span>
                              </div>
                              <div className="w-full flex justify-between items-end text-xs ">
                                  <div className="flex">
                                      <div className="mr-10">
                                          <div className="text-secondaryTxtColor mb-2">Expires</div>
                                          <span className="">
                                              {' '}
                                              {item.card.exp_month}/{item.card.exp_year}
                                          </span>
                                      </div>
                                      <div className="">
                                          <div className="text-secondaryTxtColor mb-2">Date added</div>
                                          <span className="w-44">{new Date(item.created * 1000).toDateString()}</span>
                                      </div>
                                  </div>
                                  <div className="">
                                      <Toggle
                                          id="default_card"
                                          labelPosition="left"
                                          label="Set as default"
                                          isChecked={item.id === defaultCard?.id ?? false}
                                          onChange={handleDefaultCard(item)}
                                          switchClassName="bg-secondaryTxtColor"
                                          labelClassName=""
                                      />
                                  </div>
                              </div>
                          </div>
                      </>
                  ))
                : ''}

            <div className="flex  justify-end ">
                <span className="font-bold text-primary cursor-pointer leading-[140%] -tracking-[0.14px]" onClick={openAddPayMethodPopup}>
                    +Add more payment method
                </span>
            </div>
            <div className="flex w-full px-10 justify-center mt-10">
                <Button className="fl-btn btn_primary w-[420px] " onClick={handleClose}>
                    Close
                </Button>
            </div>
        </>
    );
};

export default PaymentMethod;
