import React from 'react';
import { Badge } from 'flowbite-react';
import { NavLink } from 'react-router-dom';
import { useAppSelector } from 'src/redux/hooks';
import { selectRoleInfo } from 'src/redux/RolePermission/RolePermission.slice';
import { DefaultNavSections } from './SidebarNav.interface';

const SidebarNav = () => {
    const role = useAppSelector(selectRoleInfo);
    const hasPermission = (permissionName: string) => role.permissions.includes(permissionName);
    return (
        <aside className="sidebar_Nav">
            <div className="sidebar_Nav_Wrap">
                <nav>
                    {DefaultNavSections.map((section, index) => {
                        const filteredItems = section.items.filter((item) => hasPermission(item.permission));
                        return (
                            filteredItems.length > 0 && (
                                <React.Fragment key={index}>
                                    <p className="text-xs text-secondaryTxtColor mb-2 -tracing-[-0.12px] leading-[150%]">{section.title}</p>
                                    <ul className="sidebar_Nav_List flex flex-col text-sm mb-3">
                                        {filteredItems.map((item, itemIndex) => (
                                            <li key={itemIndex}>
                                                {item.disabled ? (
                                                    <a className="pointer-events-none opacity-50">
                                                        <i className="icon">
                                                            <img src={item.icon} alt={item.text} />
                                                        </i>
                                                        <span className="text">
                                                            {item.text} {item.badge && <Badge color="gray">{item.badge}</Badge>}
                                                        </span>
                                                    </a>
                                                ) : (
                                                    <NavLink to={item.to}>
                                                        <i className="icon">
                                                            <img src={item.icon} alt={item.text} />
                                                        </i>
                                                        <span className="text">{item.text}</span>
                                                    </NavLink>
                                                )}
                                            </li>
                                        ))}
                                    </ul>
                                </React.Fragment>
                            )
                        );
                    })}
                </nav>
            </div>
        </aside>
    );
};
export default SidebarNav;
