/* eslint-disable import/no-anonymous-default-export */
import HomeIcon from 'src/assets/img/sidenavIconImg/home.png';
import Analytics from 'src/assets/img/sidenavIconImg/analytics.png';
import AnalyticsFilled from 'src/assets/img/sidenavIconImg/analyticsFilled.png';
import Calendar from 'src/assets/img/sidenavIconImg/calendar.png';
import CalendarFilled from 'src/assets/img/sidenavIconImg/calendarFilled.png';
import Clients from 'src/assets/img/sidenavIconImg/clients.png';
import ClientsFilled from 'src/assets/img/sidenavIconImg/clientsFilled.png';
import Home from 'src/assets/img/sidenavIconImg/home.png';
import HomeFilled from 'src/assets/img/sidenavIconImg/homeFilled.png';
import Location from 'src/assets/img/sidenavIconImg/location.png';
import LocationFilled from 'src/assets/img/sidenavIconImg/locationFilled.png';
import Marketing from 'src/assets/img/sidenavIconImg/marketing.png';
import MarketingFilled from 'src/assets/img/sidenavIconImg/marketingFilled.png';
import Mobile from 'src/assets/img/sidenavIconImg/mobile.png';
import Payment from 'src/assets/img/sidenavIconImg/payment.png';
import PaymentBlue from 'src/assets/img/icons/icon-payment-blue.svg';
import PaymentFilled from 'src/assets/img/sidenavIconImg/paymentFilled.png';
import Service from 'src/assets/img/sidenavIconImg/service.png';
import Setting from 'src/assets/img/sidenavIconImg/setting.png';
import SettingFilled from 'src/assets/img/sidenavIconImg/settingFilled.png';
import Staff from 'src/assets/img/sidenavIconImg/staff.png';
import StaffFilled from 'src/assets/img/sidenavIconImg/staffFilled.png';
import Store from 'src/assets/img/sidenavIconImg/store.png';
import Web from 'src/assets/img/sidenavIconImg/web.png';
import WebFilled from 'src/assets/img/sidenavIconImg/webFilled.png';
import PDF from 'src/assets/svgs/pdf-file.svg';
import Logo from 'src/assets/svgs/Logo.svg';
import SearchInputIcon from 'src/assets/svgs/vuesax/search-normal.svg';
import ProfileImg from 'src/assets/img/persons/profile.jpg';
import DashboardIcon from 'src/assets/img/othersImg/dashboard.png';
import EyeIcon from 'src/assets/svgs/eye.svg';
import EyeOffIcon from 'src/assets/svgs/eye-off.svg';
import Plus from 'src/assets/svgs/plus 2.svg';
import Exit from 'src/assets/svgs/exit.svg';
import LoaderIcon from 'src/assets/svgs/loader.svg';
import LoaderDarkIcon from 'src/assets/svgs/loader-dark.svg';
import Tick from 'src/assets/svgs/bluetick.svg';
import USFlag from 'src/assets/flags/US.svg';
import Search from 'src/assets/img/sidenavIconImg/search.png';
import mapLocationPin from 'src/assets/img/sidenavIconImg/mapLocation.png';
import img from 'src/assets/img/sidenavIconImg/placeholder.png';
import taxesLocation from 'src/assets/img/icons/icon-location-tax.svg';
import groupLocation from 'src/assets/img/icons/icon-location-client.svg';
import image1Location from 'src/assets/img/thumbnail/image1Location.png';
import image2Location from 'src/assets/img/thumbnail/image2Location.png';
import image3Location from 'src/assets/img/thumbnail/image3Location.png';
import image4Location from 'src/assets/img/thumbnail/image4Location.png';
import image5Location from 'src/assets/img/thumbnail/image5Location.png';
import staffImg1 from 'src/assets/img/thumbnail/staff-img-1.jpg';
import staffImg2 from 'src/assets/img/thumbnail/staff-img-2.jpg';
import staffImg3 from 'src/assets/img/thumbnail/staff-img-3.jpg';
import staffImg4 from 'src/assets/img/thumbnail/staff-img-4.jpg';
import staffImg5 from 'src/assets/img/thumbnail/staff-img-5.jpg';
import staffImg6 from 'src/assets/img/thumbnail/staff-img-6.jpg';
import staffImg7 from 'src/assets/img/thumbnail/staff-img-7.jpg';
import staffImg8 from 'src/assets/img/thumbnail/staff-img-8.jpg';
import { ReactComponent as Cloud } from 'src/assets/svgs/upload 1.svg';
import { ReactComponent as NewLogo } from 'src/assets/svgs/newlogo.svg';
import { ReactComponent as ArrowDown } from 'src/assets/svgs/arrodown.svg';
import { ReactComponent as ArrowUp } from 'src/assets/svgs/arroup.svg';
import AddImgIcon from 'src/assets/svgs/icon-add-image.svg';
import mapImg from 'src/assets/img/map/map-img.jpg';
import IconChevronRight from 'src/assets/img/icons/icon-chevron-right.svg';
import locationImg1 from 'src/assets/img/location/location-img-1.jpg';
import IconDrag from 'src/assets/img/icons/icon-drag.svg';
import BackArrow from 'src/assets/img/icons/back-arrow.svg';
import personImg1 from 'src/assets/img/persons/person-img-1.jpg';
import iconClientOutlinePrimary from 'src/assets/img/icons/icon-client-outline-primary.png';
import iconMoneyOutlinePrimary from 'src/assets/img/icons/icon-money-outline-primary.png';
import iconRentOutlinePrimary from 'src/assets/img/icons/icon-rent-outline-primary.png';
import iconWallClockOutlinePrimary from 'src/assets/img/icons/icon-wall-clock-outline-primary.png';
import iconEditOutlinePrimary from 'src/assets/img/icons/icon-edit-outline-primary.png';
import iconDateCalendar from 'src/assets/img/icons/icon-date-calendar.png';
import WebAppUploadLogo from 'src/assets/img/web-app-upload-logo.png';
import PaymentPageLogo from 'src/assets/img/othersImg/paymet-page-logo.png';
import IconMasterCard from 'src/assets/img/icons/icon-master-card.png';
import IconReferral from 'src/assets/img/icons/icon-referral.png';
import IconLoyaltiy from 'src/assets/img/icons/icon-loyaltiy.png';
import IconCopy from 'src/assets/img/icons/icon-copy.svg';
import ImgQrCode from 'src/assets/img/img-qr-code.png';
import IconPhone from 'src/assets/img/icons/icon-phone.svg';
import IconEnvelop from 'src/assets/img/icons/icon-envelope.svg';
import IconUserOutline from 'src/assets/img/icons/icon-user-outline.png';
import IconBankNote from 'src/assets/img/icons/icon-banknote.png';
import IconKiosk from 'src/assets/img/icons/icon-kiosk.png';
import IconCreditCard from 'src/assets/img/icons/icon-credit-card.png';
import IconGift from 'src/assets/img/icons/icon-gift.png';
import IconOther from 'src/assets/img/icons/icon-other.png';
import IconInvoice from 'src/assets/img/icons/icon-invoice.png';
import IconMoney from 'src/assets/img/icons/icon-money.svg';
import IconGrowth from 'src/assets/img/icons/icon-growth.svg';
import IconCalendar from 'src/assets/img/icons/icon-calendar.svg';
import IconTime from 'src/assets/img/icons/icon-time.svg';
import IconRefreshUser from 'src/assets/img/icons/icon-refresh-user.svg';
import IconArrowUp from 'src/assets/img/icons/icon-arrow-up.svg';
import IconArrowDown from 'src/assets/img/icons/icon-arrow-down.svg';
import SendIcon from 'src/assets/img/icons/send.svg';
import IconNewClient from 'src/assets/img/icons/icon-new-client.svg';
import IconProductivity from 'src/assets/img/icons/icon-productivity.svg';
import IconClientRotation from 'src/assets/img/icons/icon-client-rotation.svg';
import IconProfit from 'src/assets/img/icons/icon-profit.svg';

export default {
    HomeIcon,
    Analytics,
    AnalyticsFilled,
    Calendar,
    CalendarFilled,
    Clients,
    ClientsFilled,
    Home,
    HomeFilled,
    Location,
    LocationFilled,
    Marketing,
    MarketingFilled,
    Mobile,
    Payment,
    PaymentBlue,
    PaymentFilled,
    Service,
    Setting,
    SettingFilled,
    Staff,
    StaffFilled,
    Store,
    Web,
    WebFilled,
    PDF,
    Logo,
    SearchInputIcon,
    ProfileImg,
    DashboardIcon,
    ArrowDown,
    ArrowUp,
    EyeIcon,
    EyeOffIcon,
    Plus,
    Exit,
    Cloud,
    NewLogo,
    LoaderIcon,
    LoaderDarkIcon,
    Tick,
    USFlag,
    AddImgIcon,
    Search,
    mapLocationPin,
    img,
    taxesLocation,
    groupLocation,
    image1Location,
    image2Location,
    image3Location,
    image4Location,
    image5Location,
    staffImg1,
    staffImg2,
    staffImg3,
    staffImg4,
    staffImg5,
    staffImg6,
    staffImg7,
    staffImg8,
    mapImg,
    IconChevronRight,
    locationImg1,
    IconDrag,
    BackArrow,
    personImg1,
    iconClientOutlinePrimary,
    iconMoneyOutlinePrimary,
    iconRentOutlinePrimary,
    iconWallClockOutlinePrimary,
    iconEditOutlinePrimary,
    iconDateCalendar,
    WebAppUploadLogo,
    PaymentPageLogo,
    IconMasterCard,
    IconReferral,
    IconLoyaltiy,
    IconCopy,
    ImgQrCode,
    IconPhone,
    IconEnvelop,
    IconUserOutline,
    IconBankNote,
    IconKiosk,
    IconCreditCard,
    IconGift,
    IconOther,
    IconInvoice,
    IconMoney,
    IconGrowth,
    IconCalendar,
    IconTime,
    IconRefreshUser,
    IconArrowUp,
    IconArrowDown,
    SendIcon,
    IconNewClient,
    IconProductivity,
    IconClientRotation,
    IconProfit,
};
