import React, { useEffect, useRef, useState } from 'react';
import { Calendar as ReactCalendar, SlotInfo, View, Views, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './Calendar.scss';
import 'react-datepicker/dist/react-datepicker.css';
import Toolbar from '../Calendar/Toolbar';
import ResourceHeader from './ResourceHeader';
import './Calendar.scss';
import { IResourceMap } from './Calendar.Interface';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { selectShopLocation, selectUserInfo } from '../Auth/Login/Login.slice';
import { addEvent, bookingList, initAction, selectCalendarData, setData, setGetBookingList, setOpenSidebar, updateAppointmentStatus } from './Calendar.slice';
import { formatShortName, getResourceOptions, getSelectBoxOptions } from 'src/utils/global-functions';
import { locationListAction } from '../Location/Location.slice';
import { staffList } from '../Staff/Staff.slice';
import Sidebar from './Sidebar/Sidebar';
import moment from 'moment-timezone';
import Button from 'src/components/Button';
import Popup from 'src/components/Popup';
import { axiosDelete, axiosGet } from 'src/utils/requestClient';
import { API } from 'src/constants/api';
import { errorCode } from 'src/constants/errorCode';
import { toast } from 'react-toastify';
import EmptyMsgWithBtn from 'src/components/EmptyMsgWithBtn';
import EventCard from './EventCard';
import LoadingBlock from 'src/components/LoadingBlock';
import BarLoader from 'src/components/BarLoader';
const Calendar = () => {
    // moment.tz.setDefault('America/Los_Angeles');
    const localizer = momentLocalizer(moment);
    const [newSlotInfo, setNewSlotInfo] = useState<SlotInfo | null>(null);
    const dispatch = useAppDispatch();
    const user: any = useAppSelector(selectUserInfo);
    const calendarData: any = useAppSelector(selectCalendarData);
    const shopId = user.shops[0].id;
    const [timeZone, setTimeZone] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [view, setView] = useState<View>(Views.DAY);
    const [calendarDate, setCalendarDate] = useState<Date>(moment().toDate());
    const [defaultDate, setdefaultDate] = useState<Date>(moment().toDate());
    const [getBookingCalendarDate, setGetBookingCalendarDate] = useState<Date>(moment().toDate());
    const [intervalId, setIntervalId] = useState<number | null>(null);
    const [isResourceListLoad, setIsResourceListLoad] = useState(true);
    const shopLocationList: any = useAppSelector(selectShopLocation);

    const handleClose = async () => {
        dispatch(setData({ action: initAction }));
        // dispatch(setOpenSidebar(false));
    };

    const getStaff = async (locationId: any) => {
        const data = {
            shop_id: shopId,
            location_id: locationId.id,
        };
        const result = await dispatch(staffList(data));
        if (result.type === staffList.fulfilled.toString()) {
            let staffOptionData = result.payload.data.map((item: any) => ({
                ...item,
                value: item.id,
                label: formatShortName(item.first_name, item.last_name),
            }));
            const allOption = { value: null, label: 'All Staff', id: null };

            if (view === 'day') {
                staffOptionData = [allOption].concat(staffOptionData);
            }
            console.log('view', view, staffOptionData, staffOptionData.length);

            const staffListResourceArray: any = await getResourceOptions(result.payload.data);
            const updateState = {
                resourceList: staffListResourceArray,
                staffList: staffOptionData,
                selectedStaff: staffOptionData.length > 1 ? (view === 'day' ? allOption : staffOptionData[0]) : null,
            };
            await dispatch(setData(updateState));
            setIsResourceListLoad(false);
        }
    };
    const getLocation = async () => {
        const locationListArray: any = getSelectBoxOptions(shopLocationList);
        const locationArray: any = shopLocationList;

        const updateState = {
            locationList: locationListArray,
            locationInfoList: locationArray,
            selectedLocation: locationListArray.length > 0 ? locationListArray[0] : null,
        };

        if (locationListArray.length > 0) {
            await getStaff(locationListArray[0]);
        }

        await dispatch(setData(updateState));
    };
    useEffect(() => {
        getBookingList();
    }, [calendarData.selectedStaff, calendarData.selectedLocation, view, getBookingCalendarDate]);
    useEffect(() => {
        if (calendarData.selectedLocation) {
            getStaff(calendarData.selectedLocation);
        }
    }, [calendarData.selectedLocation, view]);
    useEffect(() => {
        if (calendarData.getBookingList) {
            getBookingList();
            const updateState = {
                getBookingList: false,
                action: { ...initAction, bookedAppointment: true },

                // action: initAction,
                // bookingInfo: null,
            };
            dispatch(setData(updateState));
        }
    }, [calendarData.getBookingList]);

    useEffect(() => {
        if (calendarData.selectedLocation) {
            const filteredData = calendarData.locationInfoList.filter((item: any) => item.id === calendarData.selectedLocation.id);
            moment.tz.setDefault(filteredData[0].timezone);
            const currentDate = new Date();
            const formattedDate = currentDate.toLocaleString('en-US', { timeZone: filteredData[0].timezone });
            setdefaultDate(moment(formattedDate).toDate());
            setTimeZone(filteredData[0].timezone);
        }
    }, [calendarData.selectedLocation]);
    const getBookingList = async () => {
        if (calendarData.selectedLocation && calendarData.selectedStaff) {
            const payload: {
                location_id: number;
                booking_date: string;
                type: View;
                staff_id?: number;
            } = {
                location_id: calendarData.selectedLocation.id,
                booking_date: moment(getBookingCalendarDate).format('YYYY-MM-DD'),
                type: view,
            };

            if (view === 'week' && calendarData.selectedStaff.id) {
                payload.staff_id = calendarData.selectedStaff.id;
                const submitData = {
                    payload: payload,
                    shop_id: shopId,
                };
                await dispatch(bookingList(submitData));
            } else if (view === 'day') {
                const submitData = {
                    payload: payload,
                    shop_id: shopId,
                };
                await dispatch(bookingList(submitData));
            }
        } else {
            const updateState = {
                events: [],
                // action: initAction,
            };
            setView('day');
            // setIsLoading(false);
            await dispatch(setData(updateState));
        }
    };
    useEffect(() => {
        const fetchData = async () => {
            await getLocation();
        };
        fetchData();
        return () => {
            handleClose();
        };
    }, []);
    // calendarData.selectedLocation
    const isNewEventAvailable = () => calendarData.events.some((event: any) => event.status === 'new');

    const handleSelectSlot = async (slotInfo: SlotInfo) => {
        const formattedDate = timeZone && slotInfo.start.toLocaleString('en-US', { timeZone: timeZone });
        const updateState2 = {
            action: { ...initAction, newAppointment: true },
            selectedDate: moment(formattedDate).toDate(),
            selectedTime: moment(formattedDate).toDate(),
            bookingInfo: null,
        };
        await dispatch(setData(updateState2));
        // await handleAction('newAppointment');
        // await dispatch(setBookingInfo(null));
        setIsLoading(true);
        const isNewAppoinment = isNewEventAvailable();
        let updatedEvents = calendarData.events;
        if (isNewAppoinment) {
            updatedEvents = calendarData.events.filter((event: any) => event.status !== 'new');
            const updateState = {
                events: updatedEvents,
                action: initAction,
                // isSidebarOpen: true,
                selectedSlotStaffId: null,
            };
            // setIsLoading(false);
            await dispatch(setData(updateState));
            return;
        }
        // if (moment().toDate() > slotInfo.start) {
        //     const updateState = {
        //         isSlotAvailable: false,
        //         action: initAction,
        //         isSidebarOpen: true,
        //     };
        //     setIsLoading(false);
        //     await dispatch(setData(updateState));
        //     return;
        // }

        const isTimeslotOccupied = updatedEvents.some((event: any) => {
            // if (moment().toDate() > slotInfo.start) {
            //     return true;
            // }
            const eventStart = moment(`${event.booking_date} ${event.booking_start_time}`, 'YYYY-MM-DD HH:mm:ss').toDate();
            const eventEnd = moment(`${event.booking_date} ${event.booking_end_time}`, 'YYYY-MM-DD HH:mm:ss').toDate();

            return event.staff_id === slotInfo.resourceId && ((eventStart >= slotInfo.start && eventStart < slotInfo.end) || (eventEnd > slotInfo.start && eventEnd <= slotInfo.end));
        });

        if (!isTimeslotOccupied) {
            const updateState = {
                isSidebarOpen: true,
                selectedSlotStaffId: Number(slotInfo.resourceId ?? calendarData.selectedStaff.id),
            };
            await dispatch(setData(updateState));

            setNewSlotInfo(slotInfo);
            const addEventData = {
                status: 'new',
                booking_date: moment(slotInfo.start).format('YYYY-MM-DD'),
                booking_start_time: moment(slotInfo.start).format('HH:mm:ss'),
                booking_end_time: moment(slotInfo.end).format('HH:mm:ss'),
                staff_id: slotInfo.resourceId ?? calendarData.selectedStaff.id,
            };
            await dispatch(addEvent(addEventData));
        } else {
            const updateState = {
                isSlotAvailable: false,
                action: initAction,
                isSidebarOpen: true,
            };
            await dispatch(setData(updateState));
            // alert('Timeslot is already occupied. Choose a different time.');
        }
        setIsLoading(false);
    };
    const handleRemoveBlockTimeClose = async () => {
        const updateState = {
            removeBlockTime: {
                isRemoveBlockTime: false,
                blockTimeId: null,
            },
        };
        await dispatch(setData(updateState));
    };
    const handleCancelAppointmentClose = async () => {
        const updateState = {
            cancelAppointment: {
                type: null,
                status: false,
                message: '',
            },
        };
        await dispatch(setData(updateState));
    };
    const getBookingInfo = async (id: number) => {
        let payload = {
            shop_id: shopId,
            id: id,
        };

        try {
            const response = await axiosGet(API.BOOKING.GET, payload, {});
            if (response.data?.status === errorCode.success || response.data?.status === errorCode.updateSuccess) {
                return response.data.data;
                // setDefaultCard(response.data.data.user.card);
                return;
            }

            throw response.data;
        } catch (err: any) {
            toast.error(err?.message);
            return null;
        } finally {
        }
    };
    const updateBookingStatus = async (id: number, status: string) => {
        let payload;

        if (status === 'canceled') {
            if (calendarData.isCharge) {
                payload = {
                    charge: Number(calendarData.chargeableAmount.charge),
                    charge_type: calendarData.chargeableAmount.charge_type === '%' ? 'percentage' : 'value',
                    status,
                };
            } else {
                payload = { status };
            }
        } else if (status === 'completed') {
            payload = { payment_type: calendarData.paymentType, status };
            // 'stripe'
        } else {
            payload = { status };
        }
        const data = {
            shop_id: shopId,
            id: id,
            payload: payload,
        };
        const result = await dispatch(updateAppointmentStatus(data));
        if (result.type === updateAppointmentStatus.fulfilled.toString()) {
            await dispatch(setGetBookingList(true));
            const info = await getBookingInfo(result.payload.data.id);
            if (status === 'canceled') {
                const updateState = {
                    getBookingList: true,
                    action: { ...initAction, bookedAppointment: true },
                    bookingInfo: info,
                    cancelAppointment: {
                        type: 'success',
                        status: true,
                        message: `Successfully charged $${
                            calendarData.chargeableAmount.charge_type === '%' ? (calendarData.chargeableAmount.charge * calendarData.bookingInfo.total) / 100 : calendarData.chargeableAmount.charge
                        } by ${calendarData.bookingInfo.user.first_name} ${calendarData.bookingInfo.user.last_name}`,
                    },
                };
                await dispatch(setData(updateState));
            }
        }
        if (result.type === updateAppointmentStatus.rejected.toString()) {
            const response = result.payload.data;

            if (response.status === errorCode.unprocessable) {
                toast.error(response.message);
            }
            return false;
        }
    };

    const handleCancelAppointment = async () => {
        const updateState = {
            cancelAppointment: {
                type: 'process',
                status: true,
                message: `Charging ${calendarData.bookingInfo.user.first_name} ${calendarData.bookingInfo.user.last_name}...`,
            },
        };
        await dispatch(setData(updateState));

        const id = setTimeout(() => {
            handleCancelAppointmentUpdate();
        }, 5000) as unknown as number;
        setIntervalId(id);
    };
    const handleCancelClick = () => {
        if (intervalId !== null) {
            handleCancelAppointmentClose();
            clearTimeout(intervalId);
        }
    };
    const handleCancelAppointmentUpdate = async () => {
        await updateBookingStatus(calendarData.bookingInfo.id, 'canceled');
    };
    const handleRemoveBlockTime = async () => {
        setIsLoading(true);

        try {
            const response = await axiosDelete(API.STAFF_BLOCK_TIME.DELETE, undefined, {
                shop_id: shopId,
                id: calendarData.removeBlockTime.blockTimeId,
                staff_id: calendarData.removeBlockTime.staffId,
            });
            if (response.data?.status === errorCode.success || response.data?.status === errorCode.updateSuccess) {
                const updateState = {
                    action: { ...initAction, newAppointment: true },
                    bookingInfo: null,
                    removeBlockTime: {
                        isRemoveBlockTime: false,
                        blockTimeId: null,
                        staffId: null,
                    },
                    getBookingList: true,
                };
                await dispatch(setData(updateState));
                return;
            }

            throw response.data;
        } catch (err: any) {
            toast.error(err?.message);
        } finally {
            setIsLoading(false);
        }
    };

    const handleSelectEvent = async (handleSelectEventInto: any) => {
        if (handleSelectEventInto.status === 'new') {
            return;
        }
        setIsLoading(true);
        const isNewAppoinment = isNewEventAvailable();
        if (isNewAppoinment) {
            const updatedEvents = calendarData.events.filter((event: any) => event.status !== 'new');
            const updateState = {
                events: updatedEvents,
            };
            await dispatch(setData(updateState));
        }

        // await handleAction(
        //     handleSelectEventInto.status === 'block_time'
        //         ? 'blockTime'
        //         : 'bookedAppointment',
        // );

        const updateState = {
            isSidebarOpen: true,
            action: {
                ...initAction,
                [handleSelectEventInto.status === 'block_time' ? 'blockTime' : 'bookedAppointment']: true,
            },
            bookingInfo: handleSelectEventInto,
            selectedSlotStaffId: Number(handleSelectEventInto.staff_id),
        };
        await dispatch(setData(updateState));
    };
    const calendarRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const interval = setInterval(() => {
            const currentTimeIndicator = calendarRef.current?.querySelector('.rbc-current-time-indicator');
            if (currentTimeIndicator) {
                currentTimeIndicator.scrollIntoView({
                    behavior: 'auto',
                    block: 'center',
                });
                clearInterval(interval); // Stop polling once the element is found
            }
        }, 500); // Check every 100 milliseconds

        return () => clearInterval(interval); // Cleanup on component unmount
    }, [view]);
    const messages = {
        previous: 'Previous',
        today: 'Today',
        next: 'Next',
    };
    const handleNavigate = (date: Date) => {
        setCalendarDate(moment(date).toDate());
    };
    const handleRangeChange = (range: Date[] | { start: Date; end: Date }) => {
        if (Array.isArray(range)) {
            setGetBookingCalendarDate(moment(range[0]).toDate());
        } else {
            setGetBookingCalendarDate(moment(range.start).toDate());
        }
    };
    const deletePopupContent = (
        <div className="text-center">
            <h3 className="mb-5 text-xl font-bold text-mainTextColor -tracking-[0.6px]">Delete time block</h3>
            <p className="mb-8 text-sm font-normal text-secondaryTxtColor -tracking-[0.3px]">Are you sure you want to delete this time block?</p>
            <div className="w-full">
                <Button className="w-full fl-btn px-[10px] btn_primary text-base text-center justify-center font-bold mb-3" onClick={handleRemoveBlockTimeClose}>
                    Not now
                </Button>
                <Button className="w-full fl-btn text-base font-bold btn_outline_black px-[10px] justify-center" disabled={isLoading} onClick={handleRemoveBlockTime}>
                    {"Yes, I'm sure"}
                </Button>
            </div>
        </div>
    );
    const confirmCancelPopupContent = (
        <div className="text-center">
            <h3 className="mb-5 text-xl font-bold text-mainTextColor -tracking-[0.6px]">Cancel appointment</h3>
            <p className="mb-8 text-sm font-normal text-secondaryTxtColor -tracking-[0.3px]">Are you sure you want to cancel this appointment?</p>
            <div className="w-full">
                <Button className="w-full fl-btn px-[10px] btn_primary text-base text-center justify-center font-bold mb-3" onClick={handleCancelAppointmentClose}>
                    Not now
                </Button>
                <Button className="w-full fl-btn text-base font-bold btn_outline_black px-[10px] justify-center" disabled={isLoading} onClick={handleCancelAppointment}>
                    {"Yes, I'm sure"}
                </Button>
            </div>
        </div>
    );

    const processCancelPopupContent =
        calendarData.cancelAppointment.status && calendarData.cancelAppointment.type === 'process' ? (
            <div className="text-center">
                <h3 className="mb-5 text-xl font-bold text-mainTextColor -tracking-[0.6px]">{calendarData.cancelAppointment.message}</h3>
                <div className="w-full">
                    <button className="w-full fl-btn px-[10px] btn_primary text-base text-center justify-center font-bold mb-3" onClick={handleCancelClick}>
                        Cancel
                    </button>
                </div>
            </div>
        ) : (
            <></>
        );
    const successCancelPopupContent =
        calendarData.cancelAppointment.status && calendarData.cancelAppointment.type === 'success' ? (
            <div className="text-center">
                <h3 className="mb-5 text-xl font-bold text-mainTextColor -tracking-[0.6px]">Successfully charged</h3>
                <p className="mb-8 text-sm font-normal text-secondaryTxtColor -tracking-[0.3px]">{calendarData.cancelAppointment.message}</p>
                <div className="w-full">
                    <Button className="w-full fl-btn text-base font-bold btn_outline_black px-[10px] justify-center" disabled={isLoading} onClick={handleCancelAppointmentClose}>
                        {'Done'}
                    </Button>
                </div>
            </div>
        ) : (
            <></>
        );

    return (
        <div className="flex bg-white -m-7 px-7 pt-7">
            {/* <i className="icon " onClick={() => setOpenSidebar(true)}>
                <img
                    className={`mx-8 mt-[31px] cursor-pointer  transition delay-150 duration-300 ease-in-out  ${
                        openSidebar ? 'rotate-180 ml-[339px]' : ''
                    } `}
                    src={Images.BackArrow}
                    alt="homeIcon"
                />
            </i> */}
            <div ref={calendarRef} className={`calendar-block flex-1 ${calendarData.isSidebarOpen ? 'open_calendar' : 'close_calendar'}`}>
                <ReactCalendar
                    events={calendarData.events}
                    startAccessor={(event: any) => {
                        if (event.status === 'block_time') {
                            return moment(event.startTime).toDate();
                        } else {
                            return moment(`${event.booking_date} ${event.booking_start_time}`).toDate();
                        }
                    }} // Specify the correct accessor
                    endAccessor={(event: any) => {
                        if (event.status === 'block_time') {
                            return moment(event.endTime).toDate();
                        } else {
                            return moment(`${event.booking_date} ${event.booking_end_time}`).toDate();
                        }
                    }} // Specify the correct accessor
                    scrollToTime={moment().toDate()}
                    enableAutoScroll={true}
                    resources={view === 'day' ? calendarData.resourceList : undefined}
                    onNavigate={handleNavigate}
                    resourceIdAccessor="staff_id"
                    resourceAccessor={(resource: IResourceMap) => resource.staff_id}
                    showMultiDayTimes={false}
                    localizer={localizer}
                    view={view}
                    onView={() => {}}
                    messages={messages}
                    timeslots={4}
                    className="custom-calendar"
                    step={15}
                    onRangeChange={handleRangeChange}
                    defaultView={Views.DAY}
                    // showMultiDayTimes={true}
                    showAllEvents={false}
                    onSelectEvent={handleSelectEvent}
                    onDrillDown={(e) => console.log(e)}
                    onSelectSlot={handleSelectSlot}
                    selectable
                    date={calendarDate}
                    defaultDate={defaultDate}
                    components={{
                        resourceHeader: (eventInfo: any) => <ResourceHeader eventInfo={eventInfo} />,
                        timeGutterWrapper:
                            isResourceListLoad && calendarData.resourceList.length === 0 && view === 'day'
                                ? () => (
                                      <div className="flex h-full w-full justify-center items-center">
                                          <BarLoader />
                                      </div>
                                  )
                                : view === 'day' && calendarData.resourceList.length === 0
                                ? () => (
                                      <div className="flex h-full w-full justify-center items-center">
                                          <EmptyMsgWithBtn title="No Staff Data Available" description="Staff data will be available once Staff added." btnLabel="Create staff" onClick={() => {}} />
                                      </div>
                                  )
                                : undefined,

                        week: {
                            header: (e) => (
                                <>
                                    <div className="day-name ">{moment(e.date).format('ddd')}</div>
                                    <div className="day-date">{moment(e.date).format('D')}</div>
                                </>
                            ),
                        },
                        event: (props: any) => <EventCard eventInfo={props} />,
                        toolbar: (props) => (
                            <Toolbar
                                date={props.date}
                                view={props.view}
                                views={props.views}
                                label={props.label}
                                localizer={props.localizer}
                                onNavigate={props.onNavigate}
                                onView={props.onView}
                                setView={setView}
                            />
                        ),
                    }}
                />
            </div>
            <Sidebar slotInfo={newSlotInfo} isLoading={isLoading} setIsLoading={setIsLoading} handleClose={handleClose} />
            {calendarData.removeBlockTime.isRemoveBlockTime && <Popup size="max-w-[407px]" className="p-8 pb-10" handleClose={handleRemoveBlockTimeClose} content={deletePopupContent} />}
            {calendarData.cancelAppointment.status && calendarData.cancelAppointment.type === 'confirm' && (
                <Popup size="max-w-[407px]" className="p-8 pb-10" handleClose={handleCancelAppointmentClose} content={confirmCancelPopupContent} />
            )}

            {calendarData.cancelAppointment.status && calendarData.cancelAppointment.type === 'success' && (
                <Popup size="max-w-[407px]" className="p-8 pb-10" handleClose={handleCancelAppointmentClose} content={successCancelPopupContent} />
            )}

            {calendarData.cancelAppointment.status && calendarData.cancelAppointment.type === 'process' && (
                <Popup size="max-w-[407px]" className="p-8 pb-10" handleClose={handleCancelAppointmentClose} content={processCancelPopupContent} />
            )}
        </div>
    );
};

export default Calendar;
