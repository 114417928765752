// features/roleSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ADMIN_PERMISSIONS, IRole } from './RolePermission.interface';
import { RootState } from 'src/redux/store';

const initialState: { role: IRole } = {
    role: ADMIN_PERMISSIONS,
};

export const RolePermissionSlice = createSlice({
    name: 'role',
    initialState,
    reducers: {
        setRole: (state, action: PayloadAction<IRole>) => {
            state.role = action.payload;
        },
        // Add more reducers as needed
    },
});

export const { setRole } = RolePermissionSlice.actions;

export default RolePermissionSlice.reducer;
export const selectRoleInfo = (state: RootState) => state.RolePermission.role;
