import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Button from 'src/components/Button';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'src/redux/hooks';
import { shopDetail } from 'src/app/Auth/Login/Login.slice';
import { axiosPost } from 'src/utils/requestClient';
import { API } from 'src/constants/api';
import { errorCode } from 'src/constants/errorCode';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { IForgotPinProps, IPinForgotFormData } from './ReceptionistMode.interface';
import InputPasswordWithLabel from 'src/components/InputPasswordWithLabel';
type FieldKey = 'password';

const ForgotPin = ({ handleReceptionistMode }: IForgotPinProps) => {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    const schema = Yup.object({
        password: Yup.string().required(t('Password required')),
    }).required();
    const {
        handleSubmit,
        control,
        setError,
        formState: { errors },
    } = useForm<IPinForgotFormData>({
        resolver: yupResolver(schema),
        defaultValues: {
            password: '',
        },
    });
    const handleChange = async (data: any) => {
        setIsLoading(true);
        try {
            const response = await axiosPost(API.RECEPTIONIST_MODE.PIN_FORGOT, data, {});
            if (response.data?.status === errorCode.success || response.data?.status === errorCode.updateSuccess) {
                await dispatch(shopDetail());
                handleReceptionistMode('pinSetup');
                return;
            }

            throw response.data;
        } catch (err: any) {
            console.log('err', err);

            if (err.status === errorCode.unprocessable) {
                if (err.data) {
                    Object.keys(err.data).forEach((field) => {
                        setError(field as FieldKey, {
                            type: 'manual',
                            message: err.data[field][0],
                        });
                    });
                }
                return;
            }
            toast.error(err?.message);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <form onSubmit={handleSubmit(handleChange)}>
                <h2 className="font-bold text-[20px] leading-[35px] -tracking-[0.336px] text-center pb-5">{t('Forgot 4-digit code?')}</h2>
                <div className="flex flex-wrap">
                    <Controller
                        name="password"
                        control={control}
                        render={({ field: { onChange, value } }: any) => (
                            <div className="w-full">
                                <div className="flex flex-col gap-8 text-center">
                                    <p className="text-secondaryTxtColor leading-[19.6px] -tracking-[0.384px]">{t('Enter your password to reset the 4-digit code and setup a new one')}</p>
                                </div>
                                <div className="flex flex-col w-full my-3">
                                    <InputPasswordWithLabel label={t('Password')} placeholder={t('Password')} onChange={onChange} value={value.password} name="password" />
                                    {errors.password && <p className="text-error">{errors.password.message}</p>}
                                </div>
                            </div>
                        )}
                    />
                </div>
                <Button className={`fl-btn btn_primary mt-5`} disable={isLoading} isLoading={isLoading}>
                    Confirm
                </Button>
            </form>
        </>
    );
};

export default ForgotPin;
