import React from 'react';
import './Toggle.css';
import { IToggle } from './Toggle.interface';

const Toggle: React.FC<IToggle> = ({
    error,
    onChange,
    id,
    className,
    labelClassName,
    name,
    value,
    label,
    labelPosition,
    switchClassName,
    switchRoundClassName,
    isDisabled,
    isChecked,
}): JSX.Element => (
    <div className={`${labelClassName} toggle-switch`}>
        {labelPosition === 'left' ? <label className="fl-field-title-label mr-2 mb-0">{label}</label> : ''}

        <div className="switch-input-block">
            <input
                className={`${className} ${error && 'is-invalid'} toggle-checkbox`}
                type="checkbox"
                id={id}
                checked={isChecked}
                disabled={isDisabled}
                value={value}
                name={name}
                onChange={(e) => onChange(e)}
            />
            <div className={`toggle-switch-view ${switchClassName}`}>
                <span className={`toggle-swithc-glob ${switchRoundClassName}`}></span>
            </div>
        </div>
        {labelPosition === 'right' ? <label className="fl-field-title-label ml-2 mb-0">{label}</label> : ''}
    </div>
);

export default Toggle;
