import React, { useRef } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.module.css';
import { IDateSelectProps } from './dateSelect.interface';
import { format } from 'date-fns';

const DateSelect = React.forwardRef(
    (
        {
            inputName,
            placeholder,
            dateFormat,
            className,
            errorText,
            onKeyDown,
            required,
            label,
            labelClassName,
            isFormate = true,
            tabIndex,
            icon,
            onChangeValue,
            value,
            disabled = false,
            ...props
        }: IDateSelectProps,
        ref: React.ForwardedRef<any>,
    ) => {
        const datePickerRef = useRef<DatePicker | null>(null);

        const onChangeDate = (date: Date) => {
            if (isFormate) {
                const formattedDate = format(date, dateFormat);
                onChangeValue(formattedDate);
            } else {
                onChangeValue(date);
            }
        };
        React.useImperativeHandle(ref, () => ({
            setOpen: (isOpen: boolean) => {
                if (datePickerRef.current) {
                    datePickerRef.current.setOpen(isOpen);
                }
            },
        }));
        return (
            <>
                {label && <label className={`fl-field-title-label ${labelClassName}`}>{label}</label>}
                {required && <span className="text-red-600">*</span>}
                <div className="relative date-field-block w-full">
                    <DatePicker
                        ref={datePickerRef}
                        placeholderText={placeholder}
                        onChange={onChangeDate}
                        className={className}
                        tabIndex={tabIndex}
                        id={props.id}
                        onKeyDown={(e) => {
                            if (e?.keyCode === 9 || e?.which === 9) {
                                if (datePickerRef.current) {
                                    datePickerRef.current.setOpen(false);
                                }
                            }
                        }}
                        value={value}
                        autoComplete="off"
                        name={inputName}
                        dropdownMode="select"
                        popperPlacement="top-end"
                        {...props}
                        showYearDropdown
                        showMonthDropdown
                        disabled={disabled}
                    />
                    <div
                        onClick={() => {
                            if (datePickerRef.current) {
                                datePickerRef.current.setOpen(true);
                            }
                        }}
                        className="icon absolute right-4 top-[14px] text-lg text-grayText"
                    >
                        {icon}
                    </div>
                </div>
            </>
        );
    },
);

DateSelect.defaultProps = {
    inputName: '',
    placeholder: '',
    className: '',
    label: '',
    errorText: null,
    id: '',
};
export default DateSelect;
