import React, { useEffect, useMemo, useState } from 'react';

import SearchBar from 'src/components/SearchBar/SearchBar';
import WrapperHeading from 'src/components/WrapperHeading';
import SelectBox from 'src/components/SelectBox/SelectBox';
import Card from 'src/components/Card';
import { Images } from 'src/theme';
import Button from 'src/components/Button';
import { ICountStep, IStep } from 'src/components/Stepper/Stepper.interface';
import Add from './Staff.Add';
import WorkingLocation from './Staff.Workinglocation';
import PayStructure from './Staff.PayStructure';
import PopupCommissionPayout from './Staff.PopupCommissionPayout';
import PopupRentCollection from './Staff.PopupRentCollection';
import StaffPopup from './Staff.Popup';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { createStaff, deleteStaff, locationList, staffList } from './Staff.slice';
import { selectShopLocation, selectUserInfo } from '../Auth/Login/Login.slice';
import StaffCard from './Staff.Card';
import { getSelectBoxOptionsIdAsValue } from 'src/utils/global-functions';
import { IPayStructure, ISelectedLocation, IStaffDetails, IStaffFormData, IWorkingLocation } from './Staff.interface';
import * as Yup from 'yup';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { errorCode } from 'src/constants/errorCode';
import { toast } from 'react-toastify';
import StaffDetails from './StaffDetails/StaffDetails';
import BarLoader from 'src/components/BarLoader';
import { useTranslation } from 'react-i18next';
import Popup from 'src/components/Popup';

const Staff = () => {
    const { t } = useTranslation();
    const defaultFormData: IStaffFormData = {
        first_name: '',
        last_name: '',
        phone: '',
        phone_country_code: 'US',
        email: '',
        account_type: 'service_provider',
        shop_staff_role_id: null,
        shop_location_id: [],
        pay_structure_type: 'commission',
        commission: '',
        start_date: '',
        rent_money: '',
        effective_date: '',
    };
    const STEPS: IStep[] = useMemo(
        () => [
            {
                name: 'Staff details',
                step: 1,
            },
            {
                name: 'Working location',
                step: 2,
            },
            {
                name: 'Pay structure',
                step: 3,
            },
        ],
        [],
    );
    const [staffPopup, setStaffPopup] = useState(false);
    const [step, setStep] = useState<number | ICountStep>(1);
    const [formData, setFormData] = useState<IStaffFormData | null>(defaultFormData);

    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [selectedStaffId, setSelectedStaffId] = useState<number | null>(null);

    const [staffListArray, setStaffListArray] = useState([]);
    const [staffFilteredListArray, setStaffFilteredListArray] = useState([]);

    const shopLocationList: any = useAppSelector(selectShopLocation);

    const [locationOptions, seLocationOptions] = useState([]);
    const [locationDataDetails, seLocationDataDetails] = useState([]);
    const [searchString, setSearchString] = useState('');
    const [customError, setCustomError] = useState<string | undefined>(undefined);
    const [isLoading, setIsLoading] = useState(true);
    const [isSubmitLoading, setisSubmitLoading] = useState(false);
    const [isOpenRemovePopup, setIsOpenRemovePopup] = useState<boolean>(false);
    const [selectedLocation, setSelectedLocation] = useState<ISelectedLocation | null>(null);
    const user = useAppSelector(selectUserInfo);
    const shopId = user.shops[0].id;
    const shopPayment = {
        commission_payment_day: user.shops[0].shop_payment && user.shops[0].shop_payment.commission_day ? user.shops[0].shop_payment.commission_day : '',
        commission_frequency: user.shops[0].shop_payment && user.shops[0].shop_payment.commission_frequency ? user.shops[0].shop_payment.commission_frequency : '',
        rent_payment_day: user.shops[0].shop_payment && user.shops[0].shop_payment.rent_day ? user.shops[0].shop_payment.rent_day : '',
        rent_frequency: user.shops[0].shop_payment && user.shops[0].shop_payment.rent_frequency ? user.shops[0].shop_payment.rent_frequency : '',
    };
    const dispatch = useAppDispatch();
    type FieldKey =
        | 'first_name'
        | 'last_name'
        | 'phone'
        | 'phone_country_code'
        | 'shop_staff_role_id'
        | 'account_type'
        | 'shop_location_id'
        | 'pay_structure_type'
        | 'commission'
        | 'start_date'
        | 'rent_money'
        | 'effective_date';

    const validationSchema = [
        //validation for step1
        Yup.object({
            first_name: Yup.string().required(t('This field is required')),
            last_name: Yup.string().required(t('This field is required')),
            phone: Yup.string().required(t('This field is required')),
            phone_country_code: Yup.string().required(t('This field is required')),
            shop_staff_role_id: Yup.number().required(t('This field is required')),
            email: Yup.string().email(t('Please enter valid email address')).required(t('This field is required')),
            account_type: Yup.string().required(t('This field is required')),
        }).required(),
        //validation for step2
        Yup.object({
            shop_location_id: Yup.array().min(1, t('This field is required')),
        }),
        //validation for step3
        Yup.object({
            pay_structure_type: Yup.string().required(t('This field is required')),
            commission: Yup.string().when('pay_structure_type', {
                is: (val: string) => val === 'commission',
                then: (schema) => schema.required(t('This field is required')),
                otherwise: (schema) => schema.notRequired(),
            }),
            start_date: Yup.string().when('pay_structure_type', {
                is: (val: string) => val === 'commission',
                then: (schema) => schema.required(t('This field is required')),
                otherwise: (schema) => schema.notRequired(),
            }),
            rent_money: Yup.string().when('pay_structure_type', {
                is: (val: string) => val === 'rent',
                then: (schema) => schema.required(t('This field is required')),
                otherwise: (schema) => schema.notRequired(),
            }),
            effective_date: Yup.string().when('pay_structure_type', {
                is: (val: string) => val === 'rent',
                then: (schema) => schema.required(t('This field is required')),
                otherwise: (schema) => schema.notRequired(),
            }),
        }),
    ];
    const currentValidationSchema = validationSchema[step - 1] as Yup.ObjectSchema<IStaffDetails | IWorkingLocation | IPayStructure, Record<string, any>, any, ''>;

    const defaultValues = {
        first_name: '',
        last_name: '',
        phone: '',
        phone_country_code: 'US',
        email: '',
        account_type: 'service_provider',
        shop_staff_role_id: null,
        shop_location_id: [],
        pay_structure_type: 'commission',
        commission: '',
        start_date: null,
        rent_money: '',
        effective_date: null,
    };
    const methods = useForm({
        shouldUnregister: false,
        defaultValues,
        resolver: yupResolver(currentValidationSchema),
        mode: 'onBlur',
        reValidateMode: 'onSubmit',
    });

    const { handleSubmit, reset, trigger, setError } = methods;

    const handleDrawer =
        (open: boolean, staff_id: number | null = null) =>
        () => {
            setIsDrawerOpen(open);
            setSelectedStaffId(staff_id);
        };
    const handleCloseStaffPopup = (value: boolean) => {
        setStaffPopup(value);
        handleReset();
    };

    const handleNext = async () => {
        const isStepValid = await trigger();
        if (isStepValid) setStep((prevActiveStep) => prevActiveStep + 1);
    };

    /* const handleBack = () => {
        setStep((prevActiveStep) => prevActiveStep - 1);
    }; */

    const handleReset = () => {
        setStep(1);
        reset();
    };

    /* const handleEdit = () => {
        // Logic to handle edit mode
    }; */

    const getStaff = async () => {
        const data = {
            shop_id: shopId,
        };
        const result = await dispatch(staffList(data));
        if (result.type === staffList.fulfilled.toString()) {
            setStaffListArray(result.payload.data);
            setStaffFilteredListArray(result.payload.data);
        }
        setIsLoading(false);
    };

    // const getLocationOptions = async () => {
    //     const data = {
    //         shop_id: shopId,
    //     };
    //     const result = await dispatch(locationList(data));
    //     if (result.type === locationList.fulfilled.toString()) {
    //         const locationData: any = getSelectBoxOptionsIdAsValue(result.payload.data);
    //         setSelectedLocation(locationData[0]);
    //         seLocationOptions(locationData);
    //         seLocationDataDetails(result.payload.data);
    //     }
    //     setIsLoading(false);
    // };
    useEffect(() => {
        const locationData: any = getSelectBoxOptionsIdAsValue(shopLocationList);
        setSelectedLocation(locationData[0]);
        seLocationOptions(locationData);
        seLocationDataDetails(shopLocationList);
    }, []);
    const handleSearchFilter = () => {
        const filteredData = staffListArray.filter((item: any) => {
            const searchLowerCase = searchString.toLowerCase();
            const firstNameMatch = item.first_name.toLowerCase().includes(searchLowerCase);
            const lastNameMatch = item.last_name.toLowerCase().includes(searchLowerCase);
            const phoneMatch = item.phone.includes(searchString);

            return firstNameMatch || lastNameMatch || phoneMatch;
        });
        const filteredLocationData = filteredData.filter((item: any) => {
            const locationNameMatches = item.locations.some((location: any) => location.id === selectedLocation?.value);
            return locationNameMatches;
        });

        setStaffFilteredListArray(filteredLocationData);
    };

    useEffect(() => handleSearchFilter(), [searchString, selectedLocation, staffListArray]);
    useEffect(() => {
        getStaff();
    }, []);

    const onSubmit = async (data: any) => {
        setisSubmitLoading(true);
        const submitData = {
            payload: data,
            shop_id: shopId,
        };
        const result = await dispatch(createStaff(submitData));
        if (result.type === createStaff.fulfilled.toString()) {
            setIsLoading(true);
            setisSubmitLoading(false);
            handleCloseStaffPopup(false);
            getStaff();
        }

        if (result.type === createStaff.rejected.toString()) {
            const response = result.payload.data;
            if (response.status === errorCode.unprocessable) {
                if (response.data) {
                    Object.keys(response.data).forEach((field) => {
                        const conditions = [
                            ['first_name', 'last_name', 'email', 'phone', 'phone_country_code', 'shop_staff_role_id', 'account_type'],
                            ['shop_location_id'],
                            ['pay_structure_type', 'commission', 'start_date', 'rent_money', 'effective_date'],
                        ];

                        const pageNumber = conditions.findIndex((fields) => fields.every((field1) => Object.keys(response.data).includes(field1))) + 1;

                        setStep(pageNumber + 1);
                        setError(field as FieldKey, {
                            type: 'manual',
                            message: response.data[field][0],
                        });
                        if (field === 'phone') setCustomError(response.data[field][0]);
                    });
                }
                setIsLoading(false);
                setisSubmitLoading(false);
                return;
            }
            toast.error(response.message);
        }
    };

    const staffAddCardContent = (
        <div className=" h-full flex flex-col justify-center">
            <div className="text-center">
                <p className="mb-5 text-sm font-normal text-secondaryTxtColor leading-[140%] -tracking-[0.3px]">{t('Click to add new staff member')}</p>
                <Button className="btn_outline_primary max-w-[140px]" onClick={() => handleCloseStaffPopup(true)}>
                    + {t('Add Staff')}
                </Button>
            </div>
            {/* <div className="flex-1 py-[22px] items-center flex">
                <div className="h-[1px] bg-lineColor w-full"></div>
            </div>
            <div className="text-center ">
                <p className="mb-5 text-sm font-normal text-secondaryTxtColor max-w-[184px] mx-auto leading-[140%] -tracking-[0.12px]">
                    Register yourself as a service provider!
                </p>
                <Button className="btn_outline_primary max-w-[140px]">
                    + Register
                </Button>
            </div> */}
        </div>
    );

    const renderStepComponent = () => {
        if (step === 1) {
            return <Add setStep={setStep} formData={formData} setFormData={setFormData} customError={customError} setCustomError={setCustomError} onClick={handleNext} />;
        } else if (step === 2) {
            return <WorkingLocation setStep={setStep} locationDataDetails={locationDataDetails} onClick={handleNext} />;
        } else {
            return <PayStructure onClick={handleSubmit(onSubmit)} shop_payment={shopPayment} isLoading={isSubmitLoading} />;
        }
    };

    const deleteAccount = async () => {
        const data = {
            shop_id: shopId,
            id: selectedStaffId,
        };
        const result = await dispatch(deleteStaff(data));
        if (result.type === deleteStaff.fulfilled.toString()) {
            setIsOpenRemovePopup(false);
            setIsDrawerOpen(false);
            setSelectedStaffId(null);
            getStaff();
        }
    };

    const deletePopupContent = (
        <div className="text-center deletePopup">
            <h3 className="mb-5 text-xl font-bold text-mainTextColor -tracking-[0.4px]">{t('Delete Account')}</h3>
            <p className="mb-8 text-sm font-normal text-secondaryTxtColor -tracking-[0.3px]">{t('delete_confirmation_message')}</p>
            <div className="w-full">
                <Button
                    className="w-full fl-btn px-[10px] btn_primary text-base text-center justify-center font-bold mb-3"
                    onClick={() => {
                        setIsOpenRemovePopup(false);
                    }}
                >
                    {t('Not Now')}
                </Button>
                <Button className="w-full fl-btn text-base font-bold btn_outline_black px-[10px] justify-center" onClick={() => deleteAccount()}>
                    {t('Yes, Im sure')}
                </Button>
            </div>
        </div>
    );

    return (
        <>
            <div className="inner-page-wrape">
                <div className="flex items-center">
                    <WrapperHeading content={t('Staff')} />
                    <span className="ml-[18px] text-secondaryTxtColor text-base font-medium -tracking-[0.16px]">
                        &bull; ({staffFilteredListArray.length} {t('member')})
                    </span>
                </div>
                <div className="w-full flex-1 rounded-2xl bg-white p-6 mt-6 border-[1px] border-lineColor relative">
                    <div className="flex justify-between mb-6">
                        <SearchBar
                            placeholder={t('Search by name or mobile phone')}
                            className=""
                            onChange={(e: any) => {
                                setSearchString(e.target.value);
                            }}
                        />
                        <div className="w-[250px] ml-6">
                            <SelectBox
                                options={locationOptions}
                                classComp="outline-select-box"
                                value={selectedLocation}
                                onChangeFunc={(e: any) => {
                                    setSelectedLocation(e);
                                }}
                                openOnFocus={true}
                                autofocus={true}
                                allowIcon={<img src={Images.mapLocationPin} alt="map-pin" />}
                            />
                        </div>
                    </div>
                    <div className="staff-card-row -m-3  flex flex-wrap ">
                        <BarLoader isLoading={isLoading} />
                        {staffFilteredListArray.length > 0 && (
                            <>
                                {staffFilteredListArray.map((staffDetails: any) => (
                                    <div className="staff-card-col p-3 w-2/6 2xl:w-1/4" key={staffDetails.id}>
                                        <Card content={<StaffCard staffDetails={staffDetails} />} onClick={handleDrawer(true, staffDetails.id)} className="rounded-3xl bg-white py-5 cursor-pointer" />
                                    </div>
                                ))}
                            </>
                        )}
                        <div className={`staff-card-col p-3 w-2/6 2xl:w-1/4 min-h-[304px] ${isLoading ? 'hidden' : ''}`}>
                            <Card content={staffAddCardContent} className="rounded-3xl bg-white p-5 drop-shadow-none border-dashed border-primary h-full" />
                        </div>
                    </div>
                </div>
                {isOpenRemovePopup ? (
                    <Popup
                        size="max-w-[407px]"
                        className="p-8 pb-10 deletePopup"
                        parentClassName="z-50"
                        handleClose={() => {
                            setIsOpenRemovePopup(false);
                        }}
                        content={deletePopupContent}
                    />
                ) : (
                    ''
                )}
            </div>
            <StaffDetails
                handleDrawer={() => {
                    setIsDrawerOpen(false);
                    setSelectedStaffId(null);
                }}
                setIsOpenRemovePopup={setIsOpenRemovePopup}
                getStaff={getStaff}
                isDrawerOpen={isDrawerOpen}
                staffId={selectedStaffId}
            />

            {staffPopup && (
                <FormProvider {...methods}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <StaffPopup handleCloseStaffPopup={() => handleCloseStaffPopup(false)} renderStepComponent={renderStepComponent} step={step} STEPS={STEPS} />
                    </form>
                </FormProvider>
            )}
            {false && <PopupCommissionPayout />}
            {false && <PopupRentCollection />}
            {/* <LoadingCover show={isLoading} /> */}
        </>
    );
};

export default Staff;
