import React from 'react';
import { formatKpiContentLable } from 'src/utils/global-functions';

const NetIncome = ({ handleViewClick, netIncomeDetails }: any) => (
    <div className="bg-white rounded-3xl border border-lineColor flex px-4 h-full">
        <div className="w-1/2 py-4 pe-8 border-r flex flex-col justify-center">
            <div className="flex justify-between items-center mb-6">
                <p className="leading-[140%] -tracking-[0.3px] font-medium text-xs text-secondaryTxtColor">Net Sales Income:</p>
                <p className="font-bold -tracking-[0.3]">${formatKpiContentLable(netIncomeDetails.net_sales_income) ?? 0}</p>
            </div>

            <div className="flex justify-between items-center mb-6">
                <p className="leading-[140%] -tracking-[0.3px] font-medium text-xs text-secondaryTxtColor">Commission payments:</p>
                <p className="font-bold -tracking-[0.3]">${formatKpiContentLable(netIncomeDetails.commission_payments) ?? 0}</p>
            </div>

            <div className="flex justify-between items-center">
                <p className="leading-[140%] -tracking-[0.3px] font-medium text-xs text-secondaryTxtColor">Rent:</p>
                <p className="font-bold -tracking-[0.3]">${formatKpiContentLable(netIncomeDetails.rent) ?? 0}</p>
            </div>
        </div>

        <div className="w-1/2 py-4 ps-8">
            <div className="flex flex-col justify-center items-center h-full">
                <p className="-tracking-[0.3] font-medium text-xs text-center mb-5">Net Sales Income</p>
                <h3 className="text-[28px] font-bold leading-[140%] -tracking-[0.5px] text-center">${formatKpiContentLable(netIncomeDetails.net_income) ?? 0}</h3>
            </div>
        </div>
    </div>
);

export default NetIncome;
