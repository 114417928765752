/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useRef, useState } from 'react';
import { IoNotifications, IoSettingsOutline } from 'react-icons/io5';
import { toast } from 'react-toastify';
import { selectShopInfo, selectUserInfo } from 'src/app/Auth/Login/Login.slice';
import NotificationCard from 'src/app/Notification/NotificationCard';
import { notificationInfo, setNotificationToken } from 'src/app/Notification/Notification.Slice';
import Toggle from 'src/components/Toggle/Toggle';
import { API } from 'src/constants/api';
import { errorCode } from 'src/constants/errorCode';
import { ROUTES } from 'src/constants/routes';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { requestForFirebaseToken, onMessageListener } from 'src/services/API/firebase';
import { Images } from 'src/theme';
import { getShortName, logout } from 'src/utils/global-functions';
import { axiosPost } from 'src/utils/requestClient';
import DropDownWithIcon from 'src/components/DropDownWithIcon';
import { SlArrowRight, SlLogout } from 'react-icons/sl';
import { useTranslation } from 'react-i18next';
import { FaRegUser } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';
import { selectRoleInfo, setRole } from 'src/redux/RolePermission/RolePermission.slice';
import { ADMIN_PERMISSIONS, USER, USER_PERMISSIONS } from 'src/redux/RolePermission/RolePermission.interface';
import Popup from 'src/components/Popup';
import PinSetup from './ReceptionistMode/PinSetup';
import PinVerification from './ReceptionistMode/PinVerification';
import ForgotPin from './ReceptionistMode/ForgotPin';
import EnablePopup from './ReceptionistMode/EnablePopup';
// import {
//     onMessageListener,
//     requestForFirebaseToken,
// } from "../../services/API/firebase";
const initAction = {
    enablePopup: false,
    pinSetup: false,
    forgotPin: false,
    pinVerification: false,
};
const HeaderBar = () => {
    const role = useAppSelector(selectRoleInfo);
    const [isReceptionistMode, setIsReceptionistMode] = useState(role.name === USER);
    console.log('role.name', role.name);

    const navigate = useNavigate();
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const user = useAppSelector(selectUserInfo);
    const shop = useAppSelector(selectShopInfo);
    const [isNewNotification, setIsNewNotification] = useState<boolean>(false);
    const notification = useAppSelector(notificationInfo);
    const notificationButtonRef = useRef(null);
    const [isShowNotificationModal, setIsShowNotificationModal] = useState(false);
    const [action, setAction] = useState(initAction);

    const handleReceptionistMode = (type: string) => {
        setAction((old) => ({ ...initAction, [type]: true }));
    };
    const handleClose = () => {
        setAction(initAction);
    };

    const renderReceptionistMode = () => {
        if (action.pinSetup) {
            return <PinSetup handleClose={handleClose} />;
        } else if (action.pinVerification) {
            return <PinVerification handleReceptionistMode={handleReceptionistMode} handleClose={handleClose} />;
        } else if (action.forgotPin) {
            return <ForgotPin handleReceptionistMode={handleReceptionistMode} />;
        } else {
            return <EnablePopup handleClose={handleClose} handleReceptionistMode={handleReceptionistMode} />;
        }
    };
    const handleAction = (e: any) => {
        if (e.value === 'logout') {
            logout();
        } else {
            navigate(ROUTES.SETTINGS.GENERAL_SETTING);
        }
    };
    const handleToggle = () => {
        handleReceptionistMode(user.is_pin_set ? 'pinVerification' : 'enablePopup');
    };
    useEffect(() => {
        setIsReceptionistMode(role.name === USER);
    }, [role.name]);
    const actionBtnIcon = [
        {
            label: <span>{t('Profile')}</span>,
            value: 'profile',
            icon1: <FaRegUser className="text-primary" size={20} />,
            icon2: <SlArrowRight className="text-secondaryTxtColor" />,
            icon1Position: 'left',
            icon2Position: 'right',
        },
        // {
        //     label: <span>{t('Settings')}</span>,
        //     value: 'settings',
        //     icon1: <IoSettingsOutline className="text-primary" size={20} />,
        //     icon1Position: 'left',
        //     icon2: <SlArrowRight className="text-secondaryTxtColor" />,
        //     icon2Position: 'right',
        // },
        {
            label: <span>{t('Logout')}</span>,
            value: 'logout',
            icon1: <SlLogout className="text-dangerText" size={20} />,
            icon1Position: 'left',
            icon2: <SlArrowRight className="text-secondaryTxtColor" />,
            icon2Position: 'right',
        },
        // Add more objects as needed
    ];
    useEffect(() => {
        getOrUpdateFirebaseNotificationToken();

        if (user) {
            onMessageListener()
                .then((payload: any) => {
                    toast.success(payload?.notification?.body);
                    setIsNewNotification(true);
                })
                .catch((err) => console.log('failed: ', err));
        }
    }, [user]);

    const getOrUpdateFirebaseNotificationToken = async () => {
        let FCM_TOKEN = await requestForFirebaseToken();
        if (!FCM_TOKEN) {
            FCM_TOKEN = await requestForFirebaseToken();
        }

        if (FCM_TOKEN && FCM_TOKEN !== notification.notificationToken && user) {
            dispatch(setNotificationToken(FCM_TOKEN));

            try {
                const response = await axiosPost(API.NOTIFICATION.USER_NOTIFICATION_CREATE_UPDATE, {
                    token: FCM_TOKEN,
                });
                if (response.data?.status === errorCode.success || response.data?.status === errorCode.updateSuccess) {
                    return;
                }

                throw response.data;
            } catch (err: any) {
                toast.error(err?.message);
            }
        }
    };

    const [isImageError, setIsImageError] = useState<boolean>(false);

    return (
        <>
            <header className="header_Bar items-center z-20">
                <img src={Images.Logo} alt="" className="h-6" />
                <nav className="ml-auto flex items-center">
                    {/* <div className="mr-[156px] 2xl:mr-[260px] w-[200px] xl:w-[256px] relative">
                        <div className="absolute inset-y-0 left-3 flex items-center pointer-events-none">
                            <img src={Images.SearchInputIcon} alt="" className="w-3" />
                        </div>
                        <input
                            type="search"
                            placeholder="Search"
                            className="rounded-full bg-inputBg h-8 w-full pl-8 pr-3 placeholder:text-secondaryTxtColor text-xs font-medium tracking-[0.24px] border border-inputBg focus:border-primary focus:shadow-none focus:outline-none focus:ring-0"
                        />
                    </div> */}
                    <ul className="flex">
                        {/* <li className="me-4">
                            <button className="border border-primary rounded-full text-sm text-primary font-medium px-4 py-[3px]">Front Desk Displays</button>
                        </li> */}
                        <li className="flex items-center me-4">
                            <div className="pe-4 flex items-center border-r border-lineColor">
                                {/* <span className="text-sm font-medium pe-3">
                                Receptionist Mode
                            </span>
                            <label className="relative inline-flex items-center cursor-pointer">
                                <input
                                    type="checkbox"
                                    // value=""
                                    className="sr-only peer"
                                    // checked
                                />
                            </label> */}
                                <Toggle
                                    label="Receptionist Mode"
                                    labelPosition="left"
                                    id="recurring_appointment"
                                    isChecked={isReceptionistMode}
                                    onChange={handleToggle}
                                    className=""
                                    switchClassName="bg-secondaryTxtColor"
                                />
                                {/* <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div> */}
                            </div>
                        </li>
                        <li className="me-5">
                            <button
                                type="button"
                                onClick={() => {
                                    setIsShowNotificationModal(!isShowNotificationModal);
                                    // setIsShowProfileDropDown(false);
                                }}
                                className="border border-lineColor rounded-lg flex p-[6px] transition-all ease-in duration-100 text-secondaryTxtColor hover:border-primary hover:text-primary active:border-primary active:text-primary "
                            >
                                <IoNotifications className="text-sm" />
                            </button>
                        </li>
                        <li>
                            <DropDownWithIcon
                                className=""
                                labelIcon={
                                    <div className="rounded-full w-7 h-7 overflow-hidden block">
                                        {!isImageError && user.profile_image_url ? (
                                            <figure className="h-full w-full mx-auto rounded-full bg-primary relative">
                                                <img src={user.profile_image_url} alt="" className="h-full w-full" onError={() => setIsImageError(true)} />
                                            </figure>
                                        ) : (
                                            <figure className="w-7 h-7 basis-[38px] grow-0 shrink-0 rounded-full overflow-hidden mr-3 flex text-xs font-medium justify-center items-center bg-primary text-white">
                                                {getShortName(`${user.first_name ?? ''} ${user.last_name ?? ''}`)}
                                            </figure>
                                        )}

                                        {/* <img className="rounded-full h-full w-full" src={Images.ProfileImg} alt="" /> */}
                                    </div>
                                }
                                dropDownMenuClassName="w-[324px] top-[100%] py-6"
                                dropDownMenuItemClassName="border-b text-left py-5 last:border-0"
                                dropDownMenuLinkClassName="mx-3"
                                data={actionBtnIcon}
                                setselected={handleAction}
                            />

                            {/* <a
                            href="javasript:void(0);"
                            className="rounded-full w-7 h-7 overflow-hidden block"
                        >
                            <img
                                className="rounded-full h-full w-full"
                                src={Images.ProfileImg}
                                alt=""
                            />
                        </a> */}
                        </li>
                    </ul>
                </nav>
            </header>
            {isShowNotificationModal && (
                <NotificationCard
                    handleClose={() => setIsShowNotificationModal(false)}
                    notificationButtonRef={notificationButtonRef}
                    isNewNotification={isNewNotification}
                    setIsNewNotification={setIsNewNotification}
                    setIsShowNotificationModal={setIsShowNotificationModal}
                />
            )}
            {Object.values(action).some((value) => value === true) && <Popup size="max-w-[600px]" className="p-8 pb-10" handleClose={handleClose} content={renderReceptionistMode()} />}
        </>
    );
};

export default HeaderBar;
