import format from 'date-fns/format';
import React, { useEffect, useState } from 'react';
import { HiXMark } from 'react-icons/hi2';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { capitalizeFirstLetterSpace, getCardTypeImage, getShortName } from 'src/utils/global-functions';
import { selectShopInfo } from '../Auth/Login/Login.slice';
import { clientCardList } from '../Client/Client.slice';
import { usePhone } from 'src/hooks/usePhone';

const ClientDetail = ({ sidebarState, setSidebarState, isNewAppoinment = true }: any) => {
    const dispatch = useAppDispatch();
    const [defaultCard, setDefaultCard] = useState<any>(null);
    const shop = useAppSelector(selectShopInfo);
    const shopId = shop.id;
    const [isImageError, setIsImageError] = useState<boolean>(false);
    const getClientCard = async () => {
        const data = {
            shop_id: shopId,
            id: sidebarState?.selectedOption?.id,
        };
        const result = await dispatch(clientCardList(data));
        if (result.type === clientCardList.fulfilled.toString()) {
            let filterData = result.payload.data.cards.filter((items: any) => items.id === result.payload.data.default_card_id);
            setDefaultCard(filterData[0]);
        }
    };
    const fullName = sidebarState.selectedOption.first_name && sidebarState.selectedOption.last_name ? `${sidebarState.selectedOption.first_name} ${sidebarState.selectedOption.last_name}` : null;
    const { getCustomFormatPhone } = usePhone();
    const getphoneinfo = getCustomFormatPhone(sidebarState?.selectedOption?.phone, sidebarState.selectedOption?.phone_country_code);
    useEffect(() => {
        getClientCard();
    }, []);
    return (
        <div className="mb-5">
            <div className="flex w-full mb-3">
                <figure className="w-[38px] h-[38px] basis-[38px] grow-0 shrink-0 rounded-full overflow-hidden mr-3 flex text-xs font-medium justify-center items-center bg-primary text-white">
                    {sidebarState.selectedOption.profile_image_url && !isImageError ? (
                        <img src={sidebarState.selectedOption.profile_image_url} alt="client" className="w-full h-full object-cover" onError={() => setIsImageError(true)} />
                    ) : fullName ? (
                        getShortName(fullName)
                    ) : (
                        '+'
                    )}
                </figure>
                <div className="flex-1 px-3">
                    <p className="font-medium leading-[140%] -tracking-[0.14px] mb-[2px]">{fullName ? capitalizeFirstLetterSpace(fullName) : getphoneinfo}</p>
                    <p className="text-xs text-secondaryTxtColor leading-[150%] -tracking-[0.12px]">Client since {format(new Date(sidebarState.selectedOption.created_at), 'MMMM yyyy')}</p>
                </div>
                {isNewAppoinment && (
                    <HiXMark
                        size={20}
                        // onClick={() => setIsNewClient(false)}
                        onClick={() => {
                            setSidebarState((prevState: any) => ({
                                ...prevState,
                                selectedOption: null,
                            }));
                        }}
                        className="text-dangerText cursor-pointer "
                    />
                )}
            </div>
            <div className="">
                {getphoneinfo && (
                    <div className="text-sm leading-[140%] -tracking-[0.14px] flex items-center mb-2">
                        <span className="text-secondaryTxtColor w-[80px] basis-[80px] grow-0 shrink-0 mr-5">Phone :</span> <span className="font-medium flex-1 truncate">{getphoneinfo}</span>
                    </div>
                )}
                {sidebarState.selectedOption.email && (
                    <div className="text-sm leading-[140%] -tracking-[0.14px] flex items-center mb-2">
                        <span className="text-secondaryTxtColor w-[80px] basis-[80px] grow-0 shrink-0 mr-5">Email :</span>{' '}
                        <span className="font-medium flex-1 truncate">{sidebarState.selectedOption.email}</span>
                    </div>
                )}

                {defaultCard && (
                    <div className="text-sm leading-[140%] -tracking-[0.14px] flex items-center mb-2 last:mb-0">
                        <span className="text-secondaryTxtColor w-[80px] basis-[80px] grow-0 shrink-0 mr-5">Credit Card:</span>{' '}
                        <i className="icon w-[30px] mr-1">
                            <img src={getCardTypeImage(defaultCard?.card?.brand)} alt="" className="w-full " />
                        </i>
                        <span className="font-medium flex-1 truncate"> ⭑⭑⭑⭑ - ⭑⭑⭑⭑ - ⭑⭑⭑⭑ - {defaultCard.card.last4}</span>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ClientDetail;
