import React, { FC, useEffect, useState } from 'react';
import SelectBox from 'src/components/SelectBox/SelectBox';
import { Images } from 'src/theme';
import { IAppointmentDetails } from './Sidebar.Interface';
import { useFieldArray, useFormContext } from 'react-hook-form';
import ServiceDetail from '../ServiceDetail';
import ClientDetail from '../ClientDetail';
import { capitalizeFirstLetter, capitalizeFirstLetterSpace } from 'src/utils/global-functions';
import moment from 'moment';
import Button from 'src/components/Button';
import { FaPlus } from 'react-icons/fa6';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { getReceipt, selectCalendarData, setData } from '../Calendar.slice';
import { clientList } from 'src/app/Client/Client.slice';
import { selectShopInfo } from 'src/app/Auth/Login/Login.slice';
import AddClient from './AddClient';
import AppointmentHeader from './AppointmentHeader';
import CalulationDetails from './CalulationDetails';
import { errorCode } from 'src/constants/errorCode';
import { toast } from 'react-toastify';
import InputWithToggle from 'src/components/InputWithToggle';
import { usePhone } from 'src/hooks/usePhone';
import PaymentReceiptCalculation from './PaymentReceiptCalculation';

const AppointmentDetails: FC<IAppointmentDetails> = ({ bookedSlotInfo, control, errors, isBtnDisable, sidebarState, setSidebarState, appointmentCalulation, handleSubmitForm, cancelChargeError }) => {
    const { setValue, reset } = useFormContext();
    const dispatch = useAppDispatch();
    const shop = useAppSelector(selectShopInfo);
    const shopId = shop.id;
    const [isReceiptLoading, setIsReceiptLoading] = useState<boolean>(false);
    const [isReceiptDownloadLoading, setIsReceiptDownloadLoading] = useState<boolean>(false);
    const { fields, remove, append } = useFieldArray({
        control,
        keyName: 'uuid',
        name: 'services',
    });
    const [serviceOptionShow, setServiceOptionShow] = useState<boolean>(true);
    const [isNewClient, setIsNewClient] = useState(false);

    const updatedServicesList = sidebarState.serviceArrayList.map((service) => ({
        ...service,
        isDisabled: sidebarState.disabledServices.includes(service.value as number),
    }));
    const [clientArrayList, setClientArrayList] = useState([]);

    const calendarData: any = useAppSelector(selectCalendarData);
    const handleChangeService = (value: any) => {
        const selectedService = sidebarState.serviceArrayList.find((service) => service.value === value.value);
        if (selectedService) {
            setSidebarState((prevState: any) => ({
                ...prevState,
                disabledServices: [...prevState.disabledServices, value.value],
            }));
        }
        setServiceOptionShow(false);

        const changeServiceData = {
            name: selectedService?.name,
            price: selectedService?.price,
            id: selectedService?.id,
        };

        addServices(changeServiceData);
    };
    const addServices = (info: any) => {
        const duration = moment.duration(info.price.duration);
        const formattedDuration = moment.utc(duration.asMilliseconds()).format(duration.hours() > 0 ? 'H [hours] m [minutes]' : 'm [minutes]');

        append({
            name: info.name,
            price: info.price.price,
            staff_name: `${calendarData.staffInfo.first_name} ${calendarData.staffInfo.last_name}`,
            time_slot: moment(sidebarState.selectedDate).format('hh:mm A'),
            duration: formattedDuration,
            id: info.id,
            is_custom_price: false,
            qty: 1,
        });
    };
    const handleSelectChange = (selectedOptionInfo: any) => {
        setValue('user_id', selectedOptionInfo.id);
        setIsNewClient(false);
        setSidebarState((prevState) => ({
            ...prevState,
            selectedOption: selectedOptionInfo,
        }));
    };
    const { getCustomFormatPhone } = usePhone();

    const handleQtyChange = (newQuantity: number, index: number) => {
        const updatedFields: any = [...fields];
        updatedFields[index].qty = newQuantity;
        setValue('services', updatedFields);
    };
    const handleCustomPriceChange = (price: any, index: number) => {
        const updatedFields: any = [...fields];
        updatedFields[index].price = price;
        setValue('services', updatedFields);
    };
    const handleIsCustomPrice = (is_custom_price: boolean, index: number) => {
        const updatedFields: any = [...fields];
        updatedFields[index].is_custom_price = is_custom_price;

        setValue('services', updatedFields);
    };
    const handleRemoveService = async (index: number) => {
        const removedService: any = fields[index];
        setSidebarState((prevState: any) => ({
            ...prevState,
            disabledServices: [...prevState.disabledServices.filter((id: any) => id !== removedService?.id)],
        }));
        await remove(index);
        const updatedFields = fields.filter((_, i) => i !== index);

        setValue('services', updatedFields);
    };

    const getClientList = async () => {
        const payload: any = {
            shop_id: shopId,
            data: {
                filters: {
                    type: { value: 'all' },
                },
            },
        };

        const result = await dispatch(clientList(payload));
        if (result.type === clientList.fulfilled.toString()) {
            const clientOptionData = result.payload.data.data.map((item: any) => ({
                ...item,
                value: item.id,
                label: `${item.full_name ?? getCustomFormatPhone(item.phone, item.phone_country_code)}`,
            }));
            setClientArrayList(clientOptionData);
        }
    };

    const PaymentMethodJoined =
        bookedSlotInfo &&
        [...bookedSlotInfo.booking_transaction, ...bookedSlotInfo.tip_transaction]
            .map((transaction: { payment_method: any }) => capitalizeFirstLetter(transaction.payment_method))
            .reduce((unique: string[], item: string) => (unique.includes(item) ? unique : [...unique, item]), [] as string[])
            .join(', ');
    useEffect(() => {
        getClientList();
        return () => {
            reset();
        };
    }, []);

    const sendReceipt = (isMail: boolean) => async () => {
        isMail ? setIsReceiptLoading(true) : setIsReceiptDownloadLoading(true);
        const data = {
            params: {
                shop_id: shopId,
                id: bookedSlotInfo.id,
            },
            payload: {
                is_send_mail: isMail,
            },
        };
        const result = await dispatch(getReceipt(data));
        if (result.type === getReceipt.fulfilled.toString()) {
            if (isMail) {
                setIsReceiptLoading(false);
                return;
            }
            const response = result.payload.data.receipt;
            const byteCharacters = atob(response);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: 'application/pdf' });

            // Create download link
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = `${bookedSlotInfo.receipt_code}-appointment.pdf`;

            // Append the link to the body and trigger the click event
            document.body.appendChild(link);
            await link.click();

            // Clean up
            document.body.removeChild(link);
            await URL.revokeObjectURL(url);
            setIsReceiptDownloadLoading(false);
        }
        if (result.type === getReceipt.rejected.toString()) {
            const response = await result.payload.data;
            if (response.status === errorCode.unprocessable) {
                toast.error(response.message);
            }
            setIsReceiptDownloadLoading(false);
            setIsReceiptLoading(false);
        }
    };
    const handleCharge = async (e: any) => {
        const updateState = {
            isCharge: e.target.value === '1',
        };
        await dispatch(setData(updateState));
    };
    const handleChargeAmount = async (e: any) => {
        const updateState = {
            chargeableAmount: {
                ...calendarData.chargeableAmount,
                charge: e.target.value ? Number(e.target.value) : 0,
            },
        };
        await dispatch(setData(updateState));
    };
    const handleChargeType = async (e: any) => {
        const updateState = {
            chargeableAmount: {
                ...calendarData.chargeableAmount,
                charge_type: e,
            },
        };
        await dispatch(setData(updateState));
    };

    return (
        <>
            <AppointmentHeader sidebarState={sidebarState} setSidebarState={setSidebarState} bookedSlotInfo={bookedSlotInfo} />
            <div className="p-4 flex-1 overflow-y-auto">
                {sidebarState.selectedOption && !isNewClient && <ClientDetail sidebarState={sidebarState} setSidebarState={setSidebarState} isNewAppoinment={calendarData.action.newAppointment} />}
                {!sidebarState.selectedOption && !isNewClient && (
                    <>
                        <div className="mb-5">
                            <div className="flex justify-between">
                                <label htmlFor="newclient" className="fl-field-title-label">
                                    Client
                                </label>
                                <Button type="button" onClick={() => setIsNewClient(true)} className="text-xs font-bold leading-[140%] -tracking-[0.12px] ">
                                    Add new
                                </Button>
                            </div>
                            <SelectBox
                                isSearchable
                                name="newclient"
                                id="newclient"
                                errorText={!!errors?.user_id}
                                options={clientArrayList}
                                onChangeFunc={handleSelectChange}
                                className=""
                                placeholder="Search or add new client"
                            />
                            {errors.user_id && <p className="text-error">{errors.user_id.message}</p>}
                        </div>
                    </>
                )}
                {isNewClient && <AddClient getClientList={getClientList} handleSelectChange={handleSelectChange} setIsNewClient={setIsNewClient} />}
                {fields.length > 0 && (
                    <div className="">
                        {fields.map((item: any, index: any) => (
                            <ServiceDetail
                                key={index}
                                index={index}
                                serviceInfo={item}
                                handleRemoveService={() => handleRemoveService(index)}
                                handleQtyChange={handleQtyChange}
                                handleCustomPriceChange={handleCustomPriceChange}
                                handleIsCustomPrice={handleIsCustomPrice}
                            />
                        ))}
                    </div>
                )}
                {sidebarState.disabledServices.length !== sidebarState.serviceArrayList.length &&
                    serviceOptionShow &&
                    (calendarData.action.updateAppointment || calendarData.action.rescheduleAppointment || calendarData.action.newAppointment) && (
                        <div className="mb-5">
                            <label htmlFor="service" className="fl-field-title-label">
                                Service
                            </label>

                            <SelectBox
                                name="abc"
                                errorText={!!errors?.services}
                                id="service"
                                value={null}
                                options={updatedServicesList}
                                onChangeFunc={handleChangeService}
                                className=""
                                placeholder="Select a service"
                            />
                            {errors.services && <p className="text-error">{errors.services.message}</p>}
                        </div>
                    )}
                {sidebarState.disabledServices.length !== sidebarState.serviceArrayList.length &&
                    !serviceOptionShow &&
                    (calendarData.action.updateAppointment || calendarData.action.rescheduleAppointment || calendarData.action.newAppointment) && (
                        <Button className="text-primary flex items-center justify-center text-center w-full p-2" type="button" onClick={() => setServiceOptionShow(true)}>
                            <FaPlus size={12} className="mr-2" /> Add Service
                        </Button>
                    )}

                {bookedSlotInfo && bookedSlotInfo?.status === 'completed' && <PaymentReceiptCalculation bookedSlotInfo={bookedSlotInfo} />}

                {!calendarData.action.rescheduleAppointment && bookedSlotInfo && (
                    <div className="p-4 -mx-4">
                        <>
                            <div className="flex items-center mb-4">
                                <i className="mr-3">
                                    <img src={Images.iconWallClockOutlinePrimary} alt="" className="w-4" />
                                </i>
                                <p className="font-medium">
                                    Booked on
                                    {` ${moment(`${bookedSlotInfo.created_at}`, 'YYYY-MM-DD HH:mm:ss').format('ddd, MMM D [at] h:mm A')}`}
                                </p>
                            </div>
                            {bookedSlotInfo.created_by_name && (
                                <div className="flex items-center mb-4">
                                    <i className="mr-3">
                                        <img src={Images.IconUserOutline} alt="" className="w-4" />
                                    </i>
                                    <p className="font-medium">
                                        Booked by
                                        {` ${bookedSlotInfo.created_by_name && capitalizeFirstLetterSpace(`${bookedSlotInfo.created_by_name}`)}`}
                                    </p>
                                </div>
                            )}
                        </>
                        {bookedSlotInfo?.status === 'completed' && (
                            <>
                                <div className="flex items-center mb-4">
                                    <i className="mr-3">
                                        <img src={Images.PaymentBlue} alt="" className="w-4" />
                                    </i>
                                    <p className="font-medium">Payment method: {PaymentMethodJoined}</p>
                                </div>
                                <div className="flex items-center mb-4">
                                    <i className="mr-3">
                                        <img src={Images.iconWallClockOutlinePrimary} alt="" className="w-4" />
                                    </i>
                                    <p className="font-medium">{` Checked out on ${moment(`${bookedSlotInfo.updated_at}`, 'YYYY-MM-DD HH:mm:ss').format('ddd, MMM D [at] h:mm A')}`}</p>
                                </div>
                                <div className="flex items-center mb-4">
                                    <i className="mr-3">
                                        <img src={Images.IconUserOutline} alt="" className="w-4" />
                                    </i>
                                    <p className="font-medium">Checked out by {capitalizeFirstLetterSpace(bookedSlotInfo.checkout_by)}</p>
                                </div>
                            </>
                        )}

                        {bookedSlotInfo?.status === 'canceled' && (
                            <>
                                {' '}
                                <div className="flex items-center mb-4">
                                    <i className="mr-3">
                                        <img src={Images.iconWallClockOutlinePrimary} alt="" className="w-4" />
                                    </i>
                                    <p className="font-medium">{` Canceled on ${moment(`${bookedSlotInfo.canceled_at}`, 'YYYY-MM-DD HH:mm:ss').format('ddd, MMM D [at] h:mm A')}`}</p>
                                </div>
                                <div className="flex items-center mb-4">
                                    <i className="mr-3">
                                        <img src={Images.IconUserOutline} alt="" className="w-4" />
                                    </i>
                                    <p className="font-medium">Canceled by {capitalizeFirstLetterSpace(bookedSlotInfo.canceled_by_name)}</p>
                                </div>
                            </>
                        )}
                    </div>
                )}
                {calendarData.action.cancelAppointment && (
                    <>
                        {
                            <div className="border-t border-lineColor py-4 px-4 -mx-4 relative">
                                <h3 className="font-bold mb-[14px] leading-[140%] -tracking-[0.14px]">Please select the cancellation type</h3>
                                <div className="flex items-center mb-3">
                                    <input id="default-checkbox" type="radio" name="charge" value={1} className="fl-custom-checkbox-bg" onChange={handleCharge} checked={calendarData.isCharge} />
                                    <label htmlFor="default-checkbox" className="ml-3 text-sm font-normal pt-1">
                                        Charge client
                                    </label>
                                </div>
                                <div className="flex items-center mb-3">
                                    <input id="default-checkbox2" type="radio" name="charge" value={0} className="fl-custom-checkbox-bg" onChange={handleCharge} checked={!calendarData.isCharge} />
                                    <label htmlFor="default-checkbox2" className="ml-3 text-sm font-normal pt-1">
                                        Don&apos;t charge
                                    </label>
                                </div>
                                {calendarData.isCharge && (
                                    <div className="mb-4">
                                        <InputWithToggle
                                            label="Enter charge amount"
                                            type="number"
                                            name="discount"
                                            placeholder="Enter charge amount"
                                            toggleClassName="bg-white text-mainTextColor drop-shadow-cardShadow"
                                            value={calendarData.chargeableAmount.charge}
                                            toggleValue={calendarData.chargeableAmount.charge_type}
                                            setselected={handleChargeType}
                                            onChange={handleChargeAmount}
                                        />

                                        {cancelChargeError && <p className="text-error">{cancelChargeError}</p>}
                                    </div>
                                )}
                            </div>
                        }
                    </>
                )}
            </div>

            {bookedSlotInfo && bookedSlotInfo?.status === 'completed' ? <></> : appointmentCalulation && <CalulationDetails appointmentCalulation={appointmentCalulation.appointmentCalulation} />}
            {!calendarData.action.rescheduleAppointment && bookedSlotInfo && (bookedSlotInfo?.status === 'canceled' || bookedSlotInfo?.status === 'completed') ? (
                <div className="flex flex-col bg-white p-4">
                    <Button className="fl-btn btn_primary rounded-full mb-3" isLoading={isReceiptLoading} disabled={isReceiptLoading} type="button" onClick={sendReceipt(true)}>
                        Resend receipt
                    </Button>
                    <Button
                        className="fl-btn btn_outline_black justify-center text-base font-bold rounded-full"
                        isLoading={isReceiptDownloadLoading}
                        disabled={isReceiptDownloadLoading}
                        type="button"
                        onClick={sendReceipt(false)}
                    >
                        Download receipt
                    </Button>
                </div>
            ) : (
                <div className="">
                    {calendarData.action.bookedAppointment || calendarData.action.cancelAppointment ? (
                        (bookedSlotInfo && bookedSlotInfo?.status === 'pending') || calendarData.action.cancelAppointment ? (
                            <Button
                                type="button"
                                isLoading={isBtnDisable}
                                disabled={isBtnDisable}
                                onClick={(e: any) => {
                                    handleSubmitForm(e);
                                }}
                                className="fl-btn btn_primary rounded-none rounded-t-3xl"
                            >
                                {'Confirm'}
                            </Button>
                        ) : bookedSlotInfo?.status === 'confirmed' ? (
                            <Button
                                type="button"
                                isLoading={isBtnDisable}
                                disabled={isBtnDisable}
                                onClick={(e: any) => {
                                    handleSubmitForm(e);
                                }}
                                className="fl-btn btn_primary rounded-none rounded-t-3xl"
                            >
                                {'Checkout'}
                            </Button>
                        ) : null
                    ) : calendarData.action.updateAppointment && bookedSlotInfo ? (
                        <Button
                            type="button"
                            isLoading={isBtnDisable}
                            disabled={isBtnDisable}
                            onClick={(e: any) => {
                                handleSubmitForm(e);
                            }}
                            className="fl-btn btn_primary rounded-none rounded-t-3xl"
                        >
                            {'Save'}
                        </Button>
                    ) : (
                        <Button
                            type="button"
                            disabled={isBtnDisable}
                            isLoading={isBtnDisable}
                            onClick={(e: any) => {
                                handleSubmitForm(e);
                            }}
                            className="fl-btn btn_primary rounded-none rounded-t-3xl"
                        >
                            {'Book Appointment'}
                        </Button>
                    )}
                </div>
            )}
        </>
    );
};

export default AppointmentDetails;
