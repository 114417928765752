import React from 'react';
import InputWithLabel from 'src/components/InputWithLabel';
import { ReactComponent as Cloud } from 'src/assets/svgs/upload 1.svg';
import { Images } from 'src/theme';
import { CircularProgressWithLabel } from './Location.ImgCropper';
import { useFormContext } from 'react-hook-form';

const AddNewForm = ({ Controller, chooseFile, cropData, uploadProgress, showProgressBar }: any) => {
    const { control, formState } = useFormContext();
    const errors: any = formState.errors;

    return (
        <>
            <div className="mb-8 text-center">
                <div className={`upload-image-block  ${errors.location_image_name ? 'is-invalid' : ''}`}>
                    <Controller
                        name="location_image_name"
                        control={control}
                        render={({ field }: any) => <input type="file" id="uploadPic" className="upload-pic-input" name="location_image_name" onChange={chooseFile} />}
                    />

                    <label htmlFor="uploadPic" className="upload-pic-label h-full w-full rounded-full absolute flex justify-center items-center p-2 cursor-pointer">
                        {!showProgressBar ? (
                            <>
                                {!cropData ? (
                                    <div className="text-center">
                                        <img src={Images.AddImgIcon} alt="" className="mb-2 w-10 h-10 inline-block" />
                                        <p className="font-bold text-sm mb-1 -tracking-[0.14px] leading-[150%]">Location image</p>
                                        <p className="text-xs text-secondaryTxtColor -tracking-[0.12px] leading-[150%]">Supports: JPG, PNG. Upload a 3000 x 2000px image for best results.</p>
                                        {errors?.location_image_name && <p className="text-error">{errors?.location_image_name?.message}</p>}
                                    </div>
                                ) : (
                                    <div className="bg-inputBg p-2 w-14 h-14 rounded-full flex justify-center items-center ">
                                        <Cloud width={20} height={20} />
                                    </div>
                                )}
                            </>
                        ) : (
                            <CircularProgressWithLabel value={uploadProgress} />
                        )}
                    </label>
                    {!!cropData && <img id="imgEdit" src={cropData} alt="" className="object-cover w-full h-full" />}
                </div>
            </div>

            <div className="flex flex-wrap -mx-[10px]">
                <div className="px-[10px] mb-8 w-1/2">
                    <Controller
                        name="location_name"
                        control={control}
                        render={({ field: { onChange, value } }: any) => (
                            <div className="w-full">
                                <InputWithLabel
                                    label="Location Name"
                                    placeholder="Enter your location name"
                                    onChange={onChange}
                                    value={value}
                                    name="location_name"
                                    error={!!errors.location_name}
                                    isFocus={true}
                                />
                                {errors?.location_name && <p className="text-error">{errors?.location_name?.message}</p>}
                            </div>
                        )}
                    />
                </div>
                <div className="px-[10px] mb-8 w-1/2">
                    <Controller
                        name="city"
                        control={control}
                        render={({ field: { onChange, value } }: any) => (
                            <div className="w-full">
                                <InputWithLabel label="City" placeholder="Enter your city" onChange={onChange} value={value} name="city" error={!!errors.city} readOnly />
                                {errors?.city && <p className="text-error">{errors?.city?.message}</p>}
                            </div>
                        )}
                    />
                </div>
                <div className="px-[10px] mb-8 w-1/2">
                    <Controller
                        name="street"
                        control={control}
                        render={({ field: { onChange, value } }: any) => (
                            <div className="w-full">
                                <InputWithLabel label="Street" placeholder="Enter your street" onChange={onChange} value={value} name="street" error={!!errors.street} readOnly />
                                {errors?.street && <p className="text-error">{errors?.street?.message}</p>}
                            </div>
                        )}
                    />
                </div>
                <div className="px-[10px] mb-8 w-1/2">
                    <Controller
                        name="postal_code"
                        control={control}
                        render={({ field: { onChange, value } }: any) => (
                            <div className="w-full">
                                <InputWithLabel label="Postal Code" placeholder="Enter your postal code" onChange={onChange} value={value} name="postal_code" error={!!errors.postal_code} readOnly />
                                {errors?.postal_code && <p className="text-error">{errors?.postal_code?.message}</p>}
                            </div>
                        )}
                    />
                </div>
            </div>
        </>
    );
};

export default AddNewForm;
