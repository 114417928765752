import React, { FC, useEffect } from 'react';
import { IPaymentReceiptCalculationProps } from './Sidebar.Interface';
import { capitalizeFirstLetter } from 'src/utils/global-functions';
const PaymentReceiptCalculation: FC<IPaymentReceiptCalculationProps> = ({ bookedSlotInfo }) => {
    useEffect(() => {});
    const mergedTransactions = [...bookedSlotInfo.booking_transaction, ...bookedSlotInfo.tip_transaction];
    const summedByPaymentMethod = mergedTransactions.reduce((acc, { payment_method, total_amount }) => {
        if (!acc[payment_method]) {
            acc[payment_method] = 0;
        }
        acc[payment_method] += parseFloat(total_amount);
        return acc;
    }, {});
    const totalTips = bookedSlotInfo.tip_transaction.reduce((acc: number, transaction: { total_amount: string }) => acc + parseFloat(transaction.total_amount), 0).toFixed(2);
    return (
        <>
            <div className="bg-baseBg p-4 -mx-4">
                <div className="flex items-center mb-4">
                    <span className="text-xs text-secondaryTxtColor font-medium leading-[140%] -tracking-[0.12px] pr-3">Subtotal</span>
                    <span className="border-b border-dashed border-lineColor flex-1"></span>
                    <span className="text-sm leading-[140%] -tracking-[0.14px] pl-3">${bookedSlotInfo.subtotal ? Number(bookedSlotInfo.subtotal).toFixed(2) : ''}</span>
                </div>
                {bookedSlotInfo.tip_transaction.length > 0 && (
                    <div className="flex items-center mb-4">
                        <span className="text-xs text-secondaryTxtColor font-medium leading-[140%] -tracking-[0.12px] pr-3">Tips</span>
                        <span className="border-b border-dashed border-lineColor flex-1"></span>
                        <span className="text-sm leading-[140%] -tracking-[0.14px] pl-3">${totalTips}</span>
                    </div>
                )}
                <div className="flex items-center mb-4">
                    <span className="text-xs text-secondaryTxtColor font-medium leading-[140%] -tracking-[0.12px] pr-3">Taxes and fees</span>
                    <span className="border-b border-dashed border-lineColor flex-1"></span>
                    <span className="text-sm leading-[140%] -tracking-[0.14px] pl-3">${bookedSlotInfo.taxes_and_fees ? Number(bookedSlotInfo.taxes_and_fees).toFixed(2) : ''}</span>
                </div>
                {/* <div className="flex items-center mb-4"></div> */}

                <div className="flex items-center">
                    <span className="text-xs text-secondaryTxtColor font-medium leading-[140%] -tracking-[0.12px] pr-3">Total</span>
                    <span className="border-b border-dashed border-lineColor flex-1"></span>
                    <span className="text-sm leading-[140%] -tracking-[0.14px] pl-3">${bookedSlotInfo.total ? (Number(bookedSlotInfo.total) + Number(totalTips))?.toFixed(2) : ''}</span>
                </div>
            </div>

            <div className="border-t border-b bg-baseBg p-4 -mx-4">
                {Object.entries(summedByPaymentMethod).map(([method, amount]) => (
                    <div className="flex items-center mb-4 last:mb-0" key={method}>
                        <span className="text-base text-secondaryTxtColor font-normal leading-[140%] -tracking-[0.12px] pr-3">{capitalizeFirstLetter(method)}</span>
                        <span className="border-b border-dashed border-lineColor flex-1"></span>
                        <span className="text-base leading-[140%] font-bold -tracking-[0.14px] pl-3">${Number(amount).toFixed(2)}</span>
                    </div>
                ))}
            </div>
        </>
    );
};

export default PaymentReceiptCalculation;
