/* eslint-disable max-lines-per-function */
import React, { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import SearchBar from 'src/components/SearchBar/SearchBar';
import SelectBox from 'src/components/SelectBox/SelectBox';
import WrapperHeading from 'src/components/WrapperHeading';
import Popup from 'src/components/Popup';
import { IAddCategoryFormData, ISelectedLocation, IAddServicesFormData, IPopupType } from './Services.interface';
import Button from 'src/components/Button';
import { ICountStep, IStep } from 'src/components/Stepper/Stepper.interface';
import AddNewService from './Services.AddNewService';
import ChooseLocation from './Services.ChooseLocation';
import DropdownTick from 'src/components/DropdownTick';
import { Images } from 'src/theme';
import Detail from './Services.Detail';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { selectShopLocation, selectUserInfo } from 'src/app/Auth/Login/Login.slice';
import { toast } from 'react-toastify';
import { serviceList, addService, deleteCategory, deleteService } from './Services.slice';
import { locationListAction } from '../Location/Location.slice';
import { getSelectBoxOptions, getSelectBoxOptionsIdAsValue } from 'src/utils/global-functions';
import { errorCode } from 'src/constants/errorCode';
import ServicePopup from './Service.Popup';
import CategoryPopup from './Category.Popup';
import Listing from './Listing';
import BarLoader from 'src/components/BarLoader';

const Services = () => {
    const dispatch = useAppDispatch();
    const defaultFormData = {
        name: '',
        shop_category_id: undefined,
        // tags: [],
        description: '',
        shop_location_id: [],
    };

    const defaultCategoryData = {
        name: '',
        description: '',
    };
    const addNewOptions = ['Add New Service', 'Add New Category'];

    // const loading = useAppSelector(selectServiceLoading);
    const [serviceListArray, setServiceListArray] = useState([]);
    const [formData, setFormData] = useState<IAddServicesFormData | null>(defaultFormData);
    const [serviceFilteredList, setServiceFilteredList] = useState<any[]>([]);
    const [searchService, setSearchService] = useState('');
    const [categoryObj, setCategoryObj] = useState<IAddCategoryFormData>(defaultCategoryData);
    const [locationList, setLocationList] = useState([]);
    const shopLocationList: any = useAppSelector(selectShopLocation);
    const [selectedLocation, setSelectedLocation] = useState<ISelectedLocation>();
    const [step, setStep] = useState<number | ICountStep>(1);
    const [serviceEditData, setServiceEditData] = useState<IAddServicesFormData>(defaultFormData);
    const [serviceDetail, setServiceDetail] = useState(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [popupType, setPopupType] = useState<IPopupType>(null);
    const user = useAppSelector(selectUserInfo);
    const shopId = user.shops[0].id;

    useEffect(() => {
        handleSearchFilter();
    }, [searchService, selectedLocation, serviceListArray]);

    const getService = async () => {
        const data = {
            shop_id: shopId,
        };
        const result = await dispatch(serviceList(data));
        if (result.type === serviceList.fulfilled.toString()) {
            setServiceListArray(result.payload.data);
            setServiceFilteredList(result.payload.data);
            setLoading(false);
        }
    };

    // const getLocation = async () => {
    //     const data = {
    //         shop_id: shopId,
    //     };
    //     const result = await dispatch(locationListAction(data));
    //     if (result.type === locationListAction.fulfilled.toString()) {
    //         const locationListArray: any = getSelectBoxOptions(result.payload.data, 'id', 'name');

    //         setSelectedLocation(locationListArray[0]);
    //         setLocationList(locationListArray);
    //     }
    // };
    useEffect(() => {
        const locationData: any = getSelectBoxOptionsIdAsValue(shopLocationList);
        setSelectedLocation(locationData[0]);
        setLocationList(locationData);
    }, []);

    const handleSearchFilter = () => {
        const searchTermLower = searchService.toLowerCase();
        const matchedCategories: any[] = [];

        serviceListArray.forEach((category: any) => {
            if (category.name.toLowerCase().includes(searchTermLower)) {
                matchedCategories.push(category);
            } else {
                const matchedServices = category.services.filter((service: any) => service.name.toLowerCase().includes(searchTermLower));

                if (matchedServices.length > 0) {
                    const categoryWithMatchedServices = {
                        ...category,
                        services: matchedServices,
                    };
                    matchedCategories.push(categoryWithMatchedServices);
                }
            }
        });
        const result = matchedCategories
            .map((category) => ({
                ...category,
                services: category.services.filter((service: any) => service.locations.some((location: any) => location.id === selectedLocation?.id)),
            }))
            .filter((category) => category.services.length > 0);
        setServiceFilteredList(result);
    };

    useEffect(() => {
        getService();
    }, []);

    const validationSchema = [
        //validation for step1
        Yup.object({
            name: Yup.string().required('This field is required'),
            shop_category_id: Yup.number().required('This field is required'),
            // tags: Yup.array()
            //     .of(Yup.string().required('This field is required'))
            //     .min(1, 'This field is required')
            //     .required('This field is required'),
            description: Yup.string().required('This field is required'),
        }).required(),
        //validation for step2
        Yup.object({
            shop_location_id: Yup.array().min(1, 'At least one location is required'),
        }),
    ];

    const currentValidationSchema = validationSchema[step - 1] as Yup.ObjectSchema<
        | {
              name: string;
              shop_category_id: number | undefined;
              // tags: string[];
              description: string;
          }
        | { shop_location_id: [] },
        Record<string, any>,
        any,
        ''
    >;

    const methods = useForm({
        shouldUnregister: false,
        defaultValues: defaultFormData,
        resolver: yupResolver(currentValidationSchema),
        mode: 'onChange',
    });

    const { handleSubmit, reset, trigger, setValue, formState, control } = methods;

    const errors: any = formState.errors;
    const STEPS: IStep[] = useMemo(
        () => [
            {
                name: 'Service details',
                step: 1,
            },
            {
                name: 'Choose the location',
                step: 2,
            },
        ],
        [],
    );

    const handleNext = async () => {
        const isStepValid = await trigger();
        if (isStepValid) setStep((prevActiveStep) => prevActiveStep + 1);
    };

    // Insert Service
    const onSubmit = async (data: any) => {
        const submitData = {
            payload: data,
            shop_id: shopId,
        };

        const result = await dispatch(addService(submitData));
        if (result.type === addService.fulfilled.toString()) {
            reset();
            setStep(1);
            setPopupType(null);

            setServiceEditData(defaultFormData);
            getService();
        }
        if (result.type === addService.rejected.toString()) {
            const response = result.payload.data;
            if (response.status === errorCode.unprocessable) {
                return;
            }
            toast.error(response.message);
        }
    };

    const deleteCatgory = async () => {
        const result = await dispatch(deleteCategory({ shop_id: shopId, id: categoryObj.id }));
        if (result.type === deleteCategory.fulfilled.toString()) {
            getService();
            setPopupType(null);
        }
        if (result.type === deleteCategory.rejected.toString()) {
            const response = result.payload.data;
            toast.error(response.message);
        }
    };

    const deleteServiceDetail = async () => {
        const result = await dispatch(deleteService({ shop_id: shopId, id: serviceEditData.id }));
        if (result.type === deleteService.fulfilled.toString()) {
            getService();
            handleServiceInfo(false);
            setPopupType(null);
        }
        if (result.type === deleteService.rejected.toString()) {
            const response = result.payload.data;
            toast.error(response.message);
        }
    };

    const renderStepComponent = () => {
        if (step === 1) {
            return <AddNewService setStep={setStep} formData={formData} setFormData={setFormData} categoryList={serviceListArray} onClick={handleNext} />;
        } else if (step === 2) {
            return <ChooseLocation setStep={setStep} onClick={handleSubmit(onSubmit)} control={control} errors={errors} />;
        }
    };

    const handleServiceInfo = (isOpenServiceDetail: boolean, editData?: any) => {
        if (editData) {
            const shopLocationId = editData.locations.map((item: any) => item.id);

            setValue('name', editData.name);
            setValue('shop_category_id', editData.shop_category_id);
            // setValue('tags', editData.tags);
            setValue('shop_location_id', shopLocationId);
            setValue('description', editData.description);

            setServiceEditData({
                ...editData,
                shop_location_id: shopLocationId,
            });
        }
        if (!isOpenServiceDetail) {
            reset();
            setServiceEditData(defaultFormData);
            getService();
            setStep(1);
        }
        setServiceDetail(isOpenServiceDetail);
    };

    const deletePopupContent = (
        <div className="text-center">
            <h3 className="mb-5 text-xl font-bold text-mainTextColor -tracking-[0.6px]">Delete the {popupType === 'delete_category' ? 'category' : 'service'}</h3>
            <p className="mb-8 text-sm font-normal text-secondaryTxtColor -tracking-[0.3px]">
                Are you sure you want to delete this {popupType === 'delete_category' ? 'category' : 'service'}? This action cannot be undone.
            </p>
            <div className="w-full">
                <Button
                    className="w-full fl-btn px-[10px] btn_primary text-base text-center justify-center font-bold mb-3"
                    onClick={() => {
                        setPopupType(null);
                        setCategoryObj(defaultCategoryData);
                    }}
                >
                    Not now
                </Button>
                <Button
                    className="w-full fl-btn text-base font-bold btn_outline_black px-[10px] justify-center"
                    onClick={() => {
                        if (popupType === 'delete_category') {
                            deleteCatgory();
                        } else {
                            deleteServiceDetail();
                        }
                    }}
                >
                    {"Yes, I'm sure"}
                </Button>
            </div>
        </div>
    );

    return (
        <>
            {serviceDetail ? (
                <Detail serviceEditData={serviceEditData} categoryList={serviceListArray} handleBackBtn={handleServiceInfo} openDeletePopup={() => setPopupType('delete_service')} />
            ) : (
                <div className="inner-page-wrape">
                    <div className="flex justify-between items-center">
                        <div className="flex items-center">
                            <WrapperHeading content="Services" />
                            <span className="ml-[18px] text-secondaryTxtColor text-base font-medium -tracking-[0.16px]">&bull; ({serviceFilteredList.length})</span>
                        </div>
                        <div className="w-[193px]">
                            <DropdownTick
                                className="bg-primary  text-base font-bold h-10 px-2 py-2 w-full text-white  tracking-tight rounded-full text-center flex items-center justify-center cursor-pointer"
                                label="Add new"
                                dropDownMenuClassName="w-full top-[48px]"
                                data={addNewOptions}
                                setselected={(e: any) => {
                                    setPopupType(e === 'Add New Category' ? 'add_new_category' : 'add_new_service');
                                }}
                            />
                        </div>
                    </div>
                    <div className="w-full flex flex-col flex-1 rounded-2xl bg-white p-6 mt-6 border-[1px]  border-lineColor  ">
                        <div className="h-full flex flex-col flex-1">
                            <div className="flex justify-between  mb-6">
                                <SearchBar
                                    placeholder="Search services"
                                    className=""
                                    onChange={(e: any) => {
                                        setSearchService(e.target.value);
                                    }}
                                />
                                <div className="w-[250px] ml-6">
                                    <SelectBox
                                        name="territory"
                                        id="handlingUnit"
                                        options={locationList}
                                        classComp="outline-select-box"
                                        value={selectedLocation}
                                        onChangeFunc={(e: any) => {
                                            setSelectedLocation(e);
                                        }}
                                        openOnFocus={true}
                                        autofocus={true}
                                        allowIcon={<img src={Images.mapLocationPin} alt="map-pin" />}
                                    />
                                </div>
                            </div>
                            <BarLoader isLoading={loading} />
                            {serviceFilteredList.length > 0 ? (
                                <Listing
                                    filteredList={serviceFilteredList}
                                    serviceListArray={serviceListArray}
                                    handleServiceInfo={handleServiceInfo}
                                    setCategoryObj={setCategoryObj}
                                    setSelectedValue={(e: any) => {
                                        setPopupType(e === 'Edit Category' ? 'edit_category' : 'delete_category');
                                    }}
                                />
                            ) : !loading ? (
                                <div className="flex flex-col flex-1 justify-center items-center">
                                    <div className="max-w-[360px] text-center">
                                        <h2 className="text-base font-medium mb-2">You have no services</h2>
                                        <p>You do not have any services yet, create your first group to start filling up your services menu</p>
                                        {serviceListArray.length > 0 ? (
                                            <div className="flex w-full px-10 justify-center mt-10">
                                                <Button className="fl-btn btn_primary w-[420px] 2xl:w-[572px]" onClick={() => setPopupType('add_new_service')}>
                                                    Add service
                                                </Button>
                                            </div>
                                        ) : (
                                            <div className="flex w-full px-10 justify-center mt-10">
                                                <Button className="fl-btn btn_primary w-[420px] 2xl:w-[572px]" onClick={() => setPopupType('add_new_category')}>
                                                    Add category
                                                </Button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            ) : (
                                ''
                            )}
                        </div>
                    </div>
                </div>
            )}
            {(popupType === 'add_new_category' || popupType === 'edit_category') && (
                <CategoryPopup
                    isEdit={categoryObj.id}
                    handleClose={() => {
                        setPopupType(null);
                        setCategoryObj(defaultCategoryData);
                    }}
                    categoryObj={categoryObj}
                    getService={getService}
                />
            )}
            {popupType === 'add_new_service' && (
                <FormProvider {...methods}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <ServicePopup
                            handleClose={() => {
                                setPopupType(null);
                                setStep(1);
                                reset();
                            }}
                            renderStepComponent={renderStepComponent}
                            step={step}
                            STEPS={STEPS}
                        />
                    </form>
                </FormProvider>
            )}

            {(popupType === 'delete_category' || popupType === 'delete_service') && (
                <Popup
                    size="max-w-[407px]"
                    className="p-8 pb-10"
                    handleClose={() => {
                        setPopupType(null);
                        setCategoryObj(defaultCategoryData);
                    }}
                    content={deletePopupContent}
                />
            )}
        </>
    );
};

export default Services;
