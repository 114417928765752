import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'src/components/Button';

const Disable = ({ handleClose, handleStatusUpdate, value, isLoading }: any) => {
    const { t } = useTranslation();
    return (
        <div className="text-center">
            <h3 className="mb-5 text-xl font-bold text-mainTextColor -tracking-[0.2px]">
                {!value ? t('Enable') : t('Disable')} {t('Loyalty Program')}
            </h3>
            <p className="mb-8 text-sm font-normal text-secondaryTxtColor -tracking-[0.2px]">
                {!value ? t('Enabling') : t('Disabling')} {t('Loyalty_Program_popup_description')}
            </p>
            <div className="w-full">
                <Button className="fl-btn btn_primary px-[10px] w-full mb-3 " onClick={handleClose}>
                    {t('Not now')}
                </Button>
                <Button className="w-full fl-btn text-base font-bold btn_outline_black px-[10px] justify-center" isLoading={isLoading} disabled={isLoading} onClick={() => handleStatusUpdate(!value)}>
                    {t(' Yes, I am sure')}
                </Button>
            </div>
        </div>
    );
};

export default Disable;
