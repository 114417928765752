import React, { useState } from 'react';

import Button from 'src/components/Button';
import { IFormData, IPopsPaymentElement } from './PaymentMethod.interface';
import { FaArrowLeftLong } from 'react-icons/fa6';
import { useForm, Controller } from 'react-hook-form';
import InputWithLabel from 'src/components/InputWithLabel';
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useAppDispatch } from 'src/redux/hooks';
import { CreateStripeToken } from './PaymentMethod.slice';
import { shopDetail } from 'src/app/Auth/Login/Login.slice';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'src/constants/routes';

const PaymentElement: React.FC<IPopsPaymentElement> = ({ setStep, loading }) => {
    const { t } = useTranslation();
    const stripe: any = useStripe();
    const elements: any = useElements();
    const dispatch = useAppDispatch();

    const [cardError, setCardError] = useState<{
        cardNumber?: string;
        cardExpiry?: string;
        cardCvc?: string;
    }>({});

    const {
        handleSubmit,
        control,
        setError,
        formState: { errors },
    } = useForm<IFormData>({
        defaultValues: {
            cardnumber: '',
            date: '',
            cvv: '',
            holdername: '',
        },
    });
    const navigate = useNavigate();

    const onSubmit = async (data: any) => {
        const cardElement = elements.getElement(CardNumberElement);

        if (stripe) {
            const { error, token }: any = await stripe.createToken(cardElement);
            if (error) {
                if (!data.holdername) {
                    setError('holdername', {
                        type: 'manual',
                        message: t('This field is required'),
                    });
                }
                const errorCode = error.code;
                const parts = errorCode.split('_');

                if (parts[1] === 'number') {
                    await setCardError((prev) => ({
                        ...prev,
                        cardNumber: error?.message,
                    }));
                }
                if (parts[1] === 'expiry') {
                    await setCardError((prev) => ({
                        ...prev,
                        cardExpiry: error?.message,
                    }));
                }
                if (parts[1] === 'cvc') {
                    await setCardError((prev) => ({
                        ...prev,
                        cardCvc: error?.message,
                    }));
                }

                return;
            }

            // Process the paymentMethod.id or send it to your server for further processing
            const result = await dispatch(CreateStripeToken({ token: token?.id }));

            if (result.type === CreateStripeToken.fulfilled.toString()) {
                await dispatch(shopDetail());
                navigate(ROUTES.DASHBOARD);
            }
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="h-full">
            <div className="flex flex-col h-full justify-between">
                <div className="flex-1">
                    <div className="flex gap-6 flex-wrap max-w-[474px] 2xl:max-w-[572px] w-full mx-auto border border-line rounded-3xl p-[30px]">
                        <Controller
                            name="cardnumber"
                            control={control}
                            render={({ field: { onChange, value } }: any) => (
                                <div className="w-full">
                                    <label htmlFor="cardnumber" className="fl-field-title-label">
                                        {t('Card Number')}
                                        <CardNumberElement
                                            id="number-123"
                                            className={`form_input_control ${(errors.cardnumber || cardError.cardNumber) && 'is-invalid'}`}
                                            onChange={(e) => {
                                                onChange(e.error ? '' : e.complete);
                                                setCardError((prev) => ({
                                                    ...prev,
                                                    cardNumber: e.complete ? '' : e.error?.message,
                                                }));
                                            }}
                                            onReady={(e) => e.focus()}
                                        />
                                    </label>

                                    {(errors.cardnumber || cardError.cardNumber) && (
                                        <p className="text-error">{(errors?.cardnumber || cardError.cardNumber) && (errors?.cardnumber?.message || cardError.cardNumber)}</p>
                                    )}
                                </div>
                            )}
                        />

                        <div className="flex gap-6 w-full">
                            <div className="w-[50%]">
                                <Controller
                                    name="date"
                                    control={control}
                                    render={({ field: { onChange, value } }: any) => (
                                        <div className="w-full">
                                            <label htmlFor="date" className="fl-field-title-label">
                                                {t('Date')}
                                                <CardExpiryElement
                                                    className={`form_input_control ${(errors.date || cardError.cardExpiry) && 'is-invalid'}`}
                                                    onChange={(e) => {
                                                        onChange(e.error ? '' : e.complete);
                                                        setCardError((prev) => ({
                                                            ...prev,
                                                            cardExpiry: e.complete ? '' : e.error?.message,
                                                        }));
                                                    }}
                                                />
                                            </label>

                                            {(errors.date || cardError.cardExpiry) && (
                                                <p className="text-error">{(errors?.date || cardError.cardExpiry) && (errors?.date?.message || cardError.cardExpiry)}</p>
                                            )}
                                        </div>
                                    )}
                                />
                            </div>
                            <div className="w-[50%]">
                                <Controller
                                    name="cvv"
                                    control={control}
                                    render={({ field: { onChange, value } }: any) => (
                                        <div className="w-full">
                                            <label htmlFor="cvv" className="fl-field-title-label">
                                                {t('CVC')}
                                                <CardCvcElement
                                                    className={`form_input_control ${(errors.cvv || cardError.cardCvc) && 'is-invalid'}`}
                                                    onChange={(e) => {
                                                        onChange(e.error ? '' : e.complete);
                                                        setCardError((prev) => ({
                                                            ...prev,
                                                            cardCvc: e.complete ? '' : e.error?.message,
                                                        }));
                                                    }}
                                                />
                                            </label>
                                            {(errors.cvv || cardError.cardCvc) && <p className="text-error">{(errors?.cvv || cardError.cardCvc) && (errors?.cvv?.message || cardError.cardCvc)}</p>}
                                        </div>
                                    )}
                                />
                            </div>
                        </div>

                        <Controller
                            name="holdername"
                            control={control}
                            render={({ field: { onChange, value } }: any) => (
                                <div className="w-full">
                                    <InputWithLabel
                                        label={t('Cardholder Name')}
                                        placeholder={t('Enter Name As On Card')}
                                        onChange={onChange}
                                        value={value.holdername}
                                        name="holdername"
                                        error={errors.holdername && true}
                                    />
                                    {errors.holdername && <p className="text-error">{errors.holdername && errors.holdername.message}</p>}
                                </div>
                            )}
                        />
                    </div>
                </div>
                <div className="flex w-full px-10 justify-center my-10">
                    <div className="back-button absolute left-10">
                        <Button
                            type="button"
                            onClick={() => setStep(6)}
                            disable={loading}
                            className="rounded-full bg-transparent border-black border-2 text-black py-[14px] px-10 flex items-center gap-3 text-sm"
                        >
                            <FaArrowLeftLong size={18} className="mr-2" /> {t('Back')}
                        </Button>
                    </div>
                    <Button isLoading={loading} className="fl-btn btn_primary w-[420px] 2xl:w-[572px] ">
                        {t('Finish')}
                    </Button>
                </div>
            </div>
        </form>
    );
};

export default PaymentElement;
