import React, { useEffect, useState } from 'react';
import Button from 'src/components/Button';
import { Controller } from 'react-hook-form';
import { useAppSelector } from 'src/redux/hooks';
import { selectListing } from '../Location/Location.slice';
import Checkbox from 'src/components/Checkbox';

const ChooseLocation = ({ onClick, editForm, control, errors }: any) => {
    const [editableForm] = useState(false);
    const [shopLocationId, setShopLocationId] = useState<number[]>([]);
    const listing = useAppSelector(selectListing);

    useEffect(() => {
        const ids = listing.map((item: any) => item.id);
        setShopLocationId(ids);
    }, []);

    const handleLocationChange = (optionId: number, onChange: (value: any[]) => void, value: any[]) => (event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
        const isCurrentlyChecked = value.includes(optionId);
        if (isCurrentlyChecked) {
            onChange(value.filter((id: number) => id !== optionId));
        } else {
            onChange([...value, optionId]);
        }
    };

    return (
        <>
            {/* <h3 className="fwpo_heading_title font-bold leading-[140%] text-center mt-0 mb-4 ">Location</h3> */}
            <Controller
                name="shop_location_id"
                control={control}
                render={({ field: { onChange, value } }) => (
                    <>
                        <div className={`relative flex justify-center  mb-8  ${!editableForm ? 'pb-3  border-lineLightColor' : ''}`}>
                            {/* {!editableForm && <p className="leading-[140%] -tracking-[0.3px] opacity-70">Choose the location where the service will take place.</p>} */}
                            <div className="absolute right-0 flex">
                                <span className="font-medium">All locations</span>
                                <div className="custom-round-chekbox-block ml-2">
                                    <Checkbox
                                        id="All_location"
                                        checked={JSON.stringify(shopLocationId) === JSON.stringify(value)}
                                        onChange={(e: any) => {
                                            const isChecked = e.target.checked;
                                            if (isChecked) {
                                                onChange(shopLocationId);
                                            } else {
                                                onChange([]);
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        {listing?.map((obj: any) => (
                            <div className="flex mb-6 items-center border rounded-3xl p-2 border-gray-200 cursor-pointer" key={obj.id} onClick={handleLocationChange(obj.id, onChange, value)}>
                                <div className="h-[130px] w-[250px] basis-[200px] grow-0 shrink-0  rounded-2xl  mr-6 overflow-hidden">
                                    <img id="imgEdit" src={obj.location_image_url} alt={obj.name} className="h-full w-full object-cover" />
                                </div>
                                <div className="flex-auto flex items-center overflow-hidden ">
                                    <div className="flex-1">
                                        <h2 className="text-base font-bold leading-[140%] -tracking-[0.4px] cursor-pointer">{obj.name}</h2>

                                        <p className="truncate text-xs font-normal leading-[18px] -tracking-[0.3px] text-secondaryTxtColor mt-3">
                                            {obj.street}
                                            {obj.city} - {obj.postal_code},
                                            <br /> {obj.country}
                                        </p>
                                    </div>
                                    <div className="custom-round-chekbox-block ml-2">
                                        <Checkbox
                                            id={`location_${obj.id}`}
                                            name="shop_location_id"
                                            value={Number(obj.id)}
                                            checked={value && value.includes(obj.id)}
                                            onChange={(e: any) => {
                                                const isChecked = e.target.checked;
                                                if (isChecked) {
                                                    onChange([...value, Number(e.target.value)]);
                                                } else {
                                                    onChange(value.filter((value22: any) => value22 !== Number(e.target.value)));
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        ))}
                        {errors?.shop_location_id && <p className="text-error">{errors?.shop_location_id?.message}</p>}
                    </>
                )}
            />
            {!editForm && (
                <div className="mt-10 text-center">
                    <Button className="fl-btn btn_primary w-[420px]" onClick={onClick}>
                        Create service
                    </Button>
                </div>
            )}
        </>
    );
};

export default ChooseLocation;
