import { yupResolver } from '@hookform/resolvers/yup';
import React, { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { selectShopInfo } from 'src/app/Auth/Login/Login.slice';
import { IAddClientForm } from 'src/app/Client/Client.interface';
import { addClient } from 'src/app/Client/Client.slice';
import Button from 'src/components/Button';
import InputWithLabel from 'src/components/InputWithLabel';
import Number from 'src/components/Number';
import SelectBox from 'src/components/SelectBox/SelectBox';
import { errorCode } from 'src/constants/errorCode';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import * as Yup from 'yup';
import { CountryCode } from 'libphonenumber-js';
import { IAddClient } from './Sidebar.Interface';
import { useTranslation } from 'react-i18next';

type FieldKey = 'first_name' | 'last_name' | 'phone' | 'phone_country_code' | 'email' | 'gender';
const AddClient: FC<IAddClient> = ({ getClientList, handleSelectChange, setIsNewClient }) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const shop = useAppSelector(selectShopInfo);
    const shopId = shop.id;
    const schema = Yup.object({
        first_name: Yup.string().required('This field is required'),
        last_name: Yup.string().required('This field is required'),
        gender: Yup.string().required('This field is required'),
        email: Yup.string().required('This field is required'),
        phone: Yup.string().required('This field is required'),
        phone_country_code: Yup.string().required('This field is required'),
    }).required();

    const genderList = [
        { label: 'Male', value: 'male' },
        { label: 'Female', value: 'female' },
        { label: 'Non-binary', value: 'non-binary' },
        { label: 'Prefer not to respond', value: 'prefer not to respond' },
    ];

    const {
        handleSubmit,
        control,
        formState: { errors },
        setError,
        setValue,
        reset,
    } = useForm<IAddClientForm>({
        resolver: yupResolver(schema),
        defaultValues: {
            first_name: '',
            last_name: '',
            phone: '',
            gender: 'male',
            email: '',
            phone_country_code: 'US',
        },
    });
    const handleNumberChange = (
        country: CountryCode,
        phone: string,
        code: string,
        isValid: boolean,
        // country: CountryCode,
        // phone: string,
        // isValid: boolean,
    ) => {
        if (isValid) {
            setValue(`phone`, `+${code}${phone}`);
            setValue(`phone_country_code`, country);
        } else {
            setValue(`phone`, '');
        }
    };

    const onSubmit = async (data: any) => {
        const submitData = {
            payload: data,
            shop_id: shopId,
        };
        const result = await dispatch(addClient(submitData));
        if (result.type === addClient.fulfilled.toString()) {
            await getClientList(); // Properly invoke the function
            await handleSelectChange(result.payload.data);
            // handleClose();
            reset();
            // getClientList();
        }

        if (result.type === addClient.rejected.toString()) {
            const response = result.payload.data;
            if (response.status === errorCode.unprocessable) {
                if (response.data) {
                    Object.keys(response.data).forEach((field) => {
                        setError(field as FieldKey, {
                            type: 'manual',
                            message: response.data[field][0],
                        });
                    });
                }
                return;
            }
            toast.error(response.message);
        }
    };

    return (
        <div className="relative">
            <form>
                <div className="flex flex-col w-full mb-4">
                    <Controller
                        name="first_name"
                        control={control}
                        render={({ field: { onChange, value } }: any) => (
                            <>
                                <InputWithLabel label={t('First name')} placeholder="Enter Client’s First Name" onChange={onChange} value={value} name="first_name" error={!!errors.first_name} />
                                {errors?.first_name && <p className="text-error">{errors?.first_name?.message}</p>}
                            </>
                        )}
                    />
                </div>
                <div className="flex flex-col w-full mb-4">
                    <Controller
                        name="last_name"
                        control={control}
                        render={({ field: { onChange, value } }: any) => (
                            <>
                                <InputWithLabel label={t('Last Name')} placeholder="Enter Client’s Last Name" onChange={onChange} value={value} name="last_name" error={!!errors.last_name} />
                                {errors?.last_name && <p className="text-error">{errors?.last_name?.message}</p>}
                            </>
                        )}
                    />
                </div>
                <div className="flex flex-col w-full mb-4">
                    <Controller
                        name="email"
                        control={control}
                        render={({ field: { onChange, value } }: any) => (
                            <>
                                <InputWithLabel label="E-mail" placeholder="Enter member’s email" onChange={onChange} value={value} error={!!errors.email} name="email" />
                            </>
                        )}
                    />
                    {errors?.email && <p className="text-error">{errors?.email?.message}</p>}
                </div>
                <div className="flex flex-col w-full mb-4">
                    <label htmlFor="phone" className="fl-field-title-label">
                        Phone Number
                    </label>
                    <Controller
                        name="phone"
                        control={control}
                        render={({ field: { onChange } }: any) => (
                            <div className="flex flex-col ">
                                <Number getNumber={onChange} errors={errors} onNumberChange={handleNumberChange} />
                            </div>
                        )}
                    />
                </div>
                <div className="flex flex-col w-full mb-4">
                    <Controller
                        name="gender"
                        control={control}
                        render={({ field: { onChange, value } }: any) => (
                            <SelectBox
                                name="gender"
                                id="handlingUnit"
                                label="Gender"
                                options={genderList}
                                value={genderList.filter((item: any) => item.value === value)}
                                errorText={errors.gender && true}
                                onChangeFunc={(selectedOption: any) => onChange(selectedOption.value)}
                                placeholder="Select account type"
                            />
                        )}
                    />
                    {errors?.gender && <p className="text-error">{errors?.gender?.message}</p>}
                </div>
                <div className="mb-4 flex">
                    <Button type="button" onClick={() => setIsNewClient(false)} className="fl-btn btn_outline_black mr-1 w-1/2 justify-center font-bold">
                        Cancel
                    </Button>
                    <Button type="button" onClick={handleSubmit(onSubmit)} className="fl-btn btn_primary ml-1 w-1/2">
                        Submit
                    </Button>
                </div>
            </form>
        </div>
    );
};

export default AddClient;
