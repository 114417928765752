import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from 'src/components/Button';
import { ROUTES } from 'src/constants/routes';

const Forbidden = () => {
    const navigate = useNavigate();

    return (
        <>
            <div className=" h-[calc(100vh-122px)] bg-white border border-lineColor rounded-2xl flex justify-center items-center">
                <div className="w-full flex flex-col justify-center items-center p-4">
                    <h4 className="m-0 mb-1 text-[26px] text-mainTextColor">Access Denied</h4>
                    <p className="mb-8 text-sm font-normal text-secondaryTxtColor -tracking-[0.3px]">
                        We are sorry, but your account does not have the necessary permissions to view this content or perform this action.
                    </p>
                    <Button className="fl-btn bg-primary px-[10px] rounded-3xl w-[200px] text-base font-semibold text-white" onClick={() => navigate(ROUTES.HOME)}>
                        Home
                    </Button>
                </div>
            </div>
        </>
    );
};

export default Forbidden;
