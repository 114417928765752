import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { API } from 'src/constants/api';
import { axiosGet, axiosPost } from 'src/utils/requestClient';
import { errorCode } from 'src/constants/errorCode';

import { IInitialState } from './Login.interface';
import { RootState } from 'src/redux/store';
interface ErrorType {
    message: string;
    status?: string;
    data?: any;
}

const initialState: IInitialState = {
    user: '',
    location_list: [],
    loading: false,
    error: null,
};

export const userLogin = createAsyncThunk('shop/login', async (payload: any, { rejectWithValue }) => {
    try {
        const response = await axiosPost(API.USER.LOGIN_WITH_EMAIL, payload);
        if (response.data.status === errorCode.success) {
            return response.data;
        }

        return rejectWithValue(response as ErrorType);
    } catch (err: any) {
        if (!err.response) {
            throw err;
        }
        return rejectWithValue(err.response.data as ErrorType);
    }
});

export const shopLocationList = createAsyncThunk('location/list', async (payload: any, { rejectWithValue }) => {
    console.log('payload', payload);

    try {
        const response = await axiosGet(API.LOCATION.LIST, payload);
        if (response.data.status === errorCode.success) {
            return response.data;
        }
        return rejectWithValue(response as ErrorType);
    } catch (err: any) {
        if (!err.response) {
            throw err;
        }
        return rejectWithValue(err.response.data as ErrorType);
    }
});

export const shopDetail = createAsyncThunk('shop/get', async (payload, { dispatch, rejectWithValue }) => {
    try {
        const response = await axiosGet(API.USER.SHOP_DETAIL);
        if (response.data.status === 200) {
            const locationPayload = {
                shop_id: response.data.data.shops[0].id,
            };
            console.log('locationPayload', locationPayload);
            await dispatch(shopLocationList(locationPayload));

            await shopLocationList(locationPayload);
            return response.data.data;
        }
        return rejectWithValue(response as ErrorType);
    } catch (err: any) {
        if (!err.response) {
            throw err;
        }
        return rejectWithValue(err.response.data as ErrorType);
    }
});

export const loginSlice = createSlice({
    name: 'shop/details',
    initialState,
    reducers: {
        setUser: (state, { payload }) => {
            state.user = payload;
        },
        clearUser: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(shopLocationList.pending, (state) => {
                state.loading = true;
            })
            .addCase(shopLocationList.fulfilled, (state, { payload }: any) => {
                state.loading = false;
                state.location_list = payload.data;
            })
            .addCase(shopLocationList.rejected, (state, action: any) => {
                state.loading = false;
            })
            .addCase(userLogin.pending, (state) => {
                state.loading = true;
            })
            .addCase(userLogin.fulfilled, (state, { payload }: any) => {
                state.loading = false;
            })
            .addCase(userLogin.rejected, (state, action: any) => {
                state.loading = false;
            })
            .addCase(shopDetail.pending, (state) => {
                state.loading = true;
            })
            .addCase(shopDetail.fulfilled, (state, { payload }: any) => {
                state.loading = false;
                state.user = payload;
            })
            .addCase(shopDetail.rejected, (state) => {
                state.loading = false;
            });
    },
});

export const { setUser, clearUser } = loginSlice.actions;

export const selectUserInfo = (state: RootState) => state.User.user;
export const selectShopInfo = (state: RootState) => (state.User.user ? state.User.user.shops[0] : '');
export const selectShopLocation = (state: RootState) => (state.User.location_list ? state.User.location_list : []);
export const selectShopSetting = (state: RootState) =>
    state.User.user
        ? state.User.user.shops[0].shop_settings.reduce((acc: any, item: any) => {
              acc[item.type] = {
                  ...item,
              };
              return acc;
          }, {})
        : '';

export const selectLoading = (state: RootState) => state.User.loading;
export const selectError = (state: RootState) => state.User.error;
