import React, { useState } from 'react';
import { Images } from 'src/theme';
import { IInputPasswordWithLabel } from './InputPasswordWithLabel.interface';

const InputPasswordWithLabel = ({ error, onChange, value, name, placeholder, label, index, isFocus }: IInputPasswordWithLabel) => {
    const [isPasswordShow, setIsPasswordShow] = useState(false);

    const handlePasswordHideShow = () => {
        setIsPasswordShow(!isPasswordShow);
    };

    return (
        <div className={` flex flex-col  w-full h-full rounded-3xl`}>
            <label htmlFor={name} className="fl-field-title-label">
                {label}
            </label>
            <div className="relative">
                <input
                    className={`form_input_control form_password_control ${error && ' is-invalid'} `}
                    type={isPasswordShow ? 'text' : 'password'}
                    name={name}
                    value={value}
                    onChange={(e) => onChange(e, index)}
                    placeholder={placeholder}
                    autoFocus={isFocus}
                />
                <i className="absolute right-0 top-0 f-full px-4 py-[14px]" onClick={handlePasswordHideShow}>
                    <img src={isPasswordShow ? Images.EyeOffIcon : Images.EyeIcon} className="cursor-pointer" alt="Show password" title={isPasswordShow ? 'Hide password' : 'Show Password'} />
                </i>
            </div>
        </div>
    );
};

export default InputPasswordWithLabel;
