import React from 'react';
import { Images } from 'src/theme';
import { ISearchBar } from './SearchBar.interface';
import debounce from 'lodash/debounce';

const SearchBar = ({ placeholder, className, onChange }: ISearchBar) => {
    // Create a debounced version of the onChange handler
    const debouncedOnChange = debounce((value: any) => {
        onChange(value);
    }, 300); // Adjust the delay (in milliseconds) according to your needs

    return (
        <div className="flex-auto relative ">
            <input
                type="search"
                placeholder={placeholder || 'Search locations'}
                onChange={(e: any) => debouncedOnChange(e)} // Use the debounced function here
                className={`border-transparent rounded-[40px]  pr-11 bg-inputBg h-[50px] w-full px-4 py-2 placeholder:text-secondaryTxtColor text-sm font-normal -tracking-[0.14px] focus:border-primary  focus:border-primary focus:shadow-none focus:outline-none focus:ring-0 ${className}`}
            />
            <div className="absolute flex items-center pointer-events-none right-4 top-0 bottom-0">
                <img src={Images.Search} alt="" className="w-[20px] " />
            </div>
        </div>
    );
};

export default SearchBar;
