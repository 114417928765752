import React from 'react';

import Footer from '../Footer';
import Sidebar from './Sidebar';
import Header from './Header';

const NonLayout = ({ children }: any) => (
    <div className="auth_wrapper flex">
        <div className="flex flex-col h-full basis-[620px] grow-0 shrink-0">
            <div className="h-full pt-[120px] px-[100px]">
                <Header />
                {children}
            </div>
            <Footer />
        </div>
        <Sidebar />
    </div>
);

export default NonLayout;
