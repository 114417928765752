import * as React from 'react';
import { useAppDispatch } from 'src/redux/hooks';
import { initAction, setData, setOpenSidebar } from '../Calendar.slice';

const SlotNotAvailable = () => {
    const dispatch = useAppDispatch();
    const handleClose = async () => {
        dispatch(setData({ action: initAction }));
        dispatch(setOpenSidebar(false));
    };

    return (
        <div className="h-full p-4 flex justify-center items-center">
            <div className="text-center">
                <p className="text-sm text-secondaryTxtColor leading-[140%] -tracking-[0.14px] mb-6">
                    We regret to inform you that the chosen time slot is currently unavailable. Please consider selecting a different time that is still open for booking.
                </p>
                <button className="fl-btn btn_outline_black max-w-[200px] w-full justify-center border text-base font-medium mx-auto" onClick={() => handleClose()}>
                    Cancel
                </button>
            </div>
        </div>
    );
};

export default SlotNotAvailable;
