import React, { useLayoutEffect, useRef } from 'react';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import * as am5percent from '@amcharts/amcharts5/percent';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';

const NetSalesChartPie = (props: any) => {
    const { netSalesIncome, timeFileterSelected } = props;

    const chartRef = useRef<am5xy.XYChart | null>(null);

    useLayoutEffect(() => {
        let root = am5.Root.new('net-sales-chartpie-div');
        if (root._logo) {
            root._logo.dispose();
        }
        root.setThemes([am5themes_Animated.new(root)]);

        let chart = root.container.children.push(
            am5percent.PieChart.new(root, {
                innerRadius: am5.percent(80),
                layout: root.horizontalLayout,
                paddingLeft: 0,
                paddingRight: 0,
                paddingBottom: 0,
                paddingTop: 0,
                marginLeft: 0,
                marginRight: 0,
            }),
        );

        chart.onPrivate('width', function (width) {
            if (width) {
                var availableSpace = Math.max(width - chart.height() - 100, 100);

                legend.labels.template.setAll({
                    width: availableSpace,
                    maxWidth: availableSpace,
                });
            }
        });

        let series = chart.series.push(
            am5percent.PieSeries.new(root, {
                alignLabels: false,
                valueField: 'total_booking_service_amount',
                categoryField: 'name',
                legendLabelText: '{category}',
                legendValueText: '${value}',
            }),
        );

        series
            ?.get('colors')
            ?.set('colors', [
                am5.color('#D602EE'),
                am5.color('#1642C5'),
                am5.color('#1AD598'),
                am5.color('#6200EE'),
                am5.color('#F3654A'),
                am5.color('#FFB536'),
                am5.color('#860A35'),
                am5.color('#A3B763'),
                am5.color('#D04848'),
                am5.color('#6895D2'),
                am5.color('#A94438'),
                am5.color('#D24545'),
                am5.color('#867AE9'),
                am5.color('#C449C2'),
            ]);
        series.labels.template.set('visible', false);
        series.labels.template.set('disabled', true);
        series.ticks.template.set('disabled', false);

        series.slices.template.set('toggleKey', 'none');
        series.ticks.template.set('visible', false);
        netSalesIncome.sort((a: any, b: any) => b?.total_booking_service_amount - a?.total_booking_service_amount);
        series.data.setAll(netSalesIncome);

        series.appear(1000, 100);
        chart.seriesContainer.children.push(
            am5.Label.new(root, {
                textAlign: 'center',
                centerY: am5.p50,
                centerX: am5.p50,
                text: `[fontSize:25px]Total Sales[/]:\n[bold fontSize:30px]${
                    timeFileterSelected.label === 'All time' ? 'All Time' : timeFileterSelected.label === 'Custom' ? `Custom` : timeFileterSelected.label
                }[/]`,
            }),
        );
        let legend = chart.children.push(
            am5.Legend.new(root, {
                centerY: am5.percent(50),
                y: am5.percent(50),
                layout: root.verticalLayout,
                clickTarget: 'none',
            }),
        );
        legend.valueLabels.template.setAll({ textAlign: 'right' });
        legend.labels.template.setAll({
            maxWidth: 200,
            width: 200,
            oversizedBehavior: 'wrap',
        });

        legend.data.setAll(series.dataItems);
        am5.ready(function () {
            removeCredits();
        });

        return () => {
            root.dispose();
        };
    }, [netSalesIncome]);

    const removeCredits = () => {
        const titles = Array.from(document.querySelectorAll('title'));

        for (const ele of titles) {
            if (ele.textContent && ele.textContent.includes('Chart created using amCharts library')) {
                const id = ele.id;
                const elementWithAriaLabel = document.querySelector<HTMLElement>(`[aria-labelledby=${id}]`);
                if (elementWithAriaLabel) {
                    elementWithAriaLabel.style.display = 'none';
                }
            }
        }
    };

    return <div id="net-sales-chartpie-div" style={{ width: '95%', height: '300px' }}></div>;
};

export default NetSalesChartPie;
