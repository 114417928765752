import React, { memo, useEffect } from 'react';
import { HiXMark } from 'react-icons/hi2';
import { IProps } from './Popup.interface';

const escapeKey = 27;

const Popup = ({ size, handleClose, content, className, parentClassName }: IProps) => {
    useEffect(() => {
        const close = (e: KeyboardEvent) => e.keyCode === escapeKey && handleClose();

        window.addEventListener('keydown', close);
        return () => window.removeEventListener('keydown', close);
    }, []);
    /* return (
        <Modal
            dismissible
            className="modal_backdrop"
            show={true}
            theme={{ content: { inner: `bg-white rounded-3xl shadow dark:bg-gray-700 my-5 `, base: `${size}` } }}
            size={size}
            onClose={handleClose}
        >
            <div className={`relative p-10  ${className}`}>
                <button onClick={handleClose} type="button" className="absolute top-0 right-0 p-4">
                    <HiXMark size={20} className="text-black" />
                </button>
                {content}
            </div>
        </Modal>
    ); */
    return (
        <div className={`modal_backdrop ${parentClassName}`}>
            <div className="modal_content">
                <div className={`relative max-h-full w-full ${size} `}>
                    <div className={`bg-white rounded-3xl shadow dark:bg-gray-700 my-5 `}>
                        <div className={`relative p-10 ${className}`}>
                            <button onClick={handleClose} type="button" className="absolute top-0 right-0 p-4">
                                <HiXMark size={20} className="text-black" />
                            </button>
                            {content}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default memo(Popup);
