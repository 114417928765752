import React from 'react';
import QRCode from 'react-qr-code';
import Button from 'src/components/Button';
import InputWithLabel from 'src/components/InputWithLabel';
import { Images } from 'src/theme';
import * as Yup from 'yup';
import { IHyperlinks } from './Hyperlinks.interface';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { API } from 'src/constants/api';
import { axiosPost } from 'src/utils/requestClient';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { selectShopInfo, shopDetail } from 'src/app/Auth/Login/Login.slice';
import { errorCode } from 'src/constants/errorCode';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

const Hyperlinks = () => {
    const { t } = useTranslation();
    const shop: any = useAppSelector(selectShopInfo);
    const shopId = shop.id;
    const schema = Yup.object().shape({
        web_app_url: Yup.string().required(t('This field is required')),
        play_store_url: Yup.string().required(t('This field is required')),
        app_store_url: Yup.string().required(t('This field is required')),
    });
    const dispatch = useAppDispatch();

    const onImageDownload = (Id: string) => {
        const svg = document.getElementById(Id);

        if (svg) {
            const svgData = new XMLSerializer().serializeToString(svg);
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');

            if (ctx) {
                const img = new Image();

                img.onload = () => {
                    canvas.width = img.width;
                    canvas.height = img.height;
                    ctx.drawImage(img, 0, 0);
                    const pngFile = canvas.toDataURL('image/png');
                    const downloadLink = document.createElement('a');
                    downloadLink.download = Id;
                    downloadLink.href = `${pngFile}`;
                    downloadLink.click();
                };

                img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
            } else {
                console.error('2D context not available');
            }
        } else {
            console.error('SVG element not found');
        }
    };

    const onImagePrint = (Id: string) => {
        const svg = document.getElementById(Id);

        if (svg) {
            const svgData = new XMLSerializer().serializeToString(svg);
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');

            if (ctx) {
                const img = new Image();

                img.onload = () => {
                    canvas.width = img.width;
                    canvas.height = img.height;
                    ctx.drawImage(img, 0, 0);

                    // Open a new window and print the canvas content
                    const printWindow = window.open();
                    if (printWindow) {
                        printWindow.document.write('<html><head><title>Print</title></head><body style="margin: 0; text-align: center;">');
                        printWindow.document.write(`<img src="${canvas.toDataURL('image/png')}" style="max-width: 100%; max-height: 100vh; margin: auto; " />`);
                        printWindow.document.write('</body></html>');
                        printWindow.document.close();
                        printWindow.print();
                    } else {
                        console.error('Failed to open a new window for printing');
                    }
                };

                img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
            } else {
                console.error('2D context not available');
            }
        } else {
            console.error('SVG element not found');
        }
    };

    const defaultValues: IHyperlinks = {
        web_app_url: shop.web_app_url ?? '',
        play_store_url: shop.play_store_url ?? '',
        app_store_url: shop.app_store_url ?? '',
    };

    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm<IHyperlinks>({
        resolver: yupResolver(schema),
        defaultValues,
    });
    const handleChange = async (data: any) => {
        try {
            const response = await axiosPost(API.USER.HYPERLINKS, data, {
                shop_id: shopId,
            });
            if (response.data?.status === errorCode.success || response.data?.status === errorCode.updateSuccess) {
                await dispatch(shopDetail());
                return;
            }

            throw response.data;
        } catch (err: any) {
            toast.error(err?.message);
        } finally {
        }
    };
    const copyURL = (url: string) => () => {
        navigator.clipboard.writeText(url);
    };
    return (
        <form onSubmit={handleSubmit(handleChange)}>
            <div className="w-[full] rounded-2xl bg-white border-[1px] border-lineColor ">
                <div className="detail-page-title-block">
                    <h2 className="text-xl font-bold leading-[140%] -tracing-[0.2px]">{t('Hyperlinks')}</h2>
                </div>

                <div className="detail-page-data-block">
                    <div className="title-block">
                        <h3 className="title-h3">{t(`FLAIR's Web Application`)}</h3>
                        <p className="text-xs text-secondaryTxtColor leading-[150%] -tracking-[0.12px]">{t(`FLAIR's_Web_Application_description`)}</p>
                    </div>
                    <div className="data-wrap-block max-w-[512px] 2xl:max-w-[700px]">
                        <Controller
                            name="web_app_url"
                            control={control}
                            render={({ field: { onChange, value } }: any) => (
                                <>
                                    <div className="text-center mb-5">{value && <QRCode className="max-w-[208px] inline-block rounded-2xl" value={value} level="H" id="WebQRCode" />}</div>
                                    <div className="relative hyperlinks">
                                        <InputWithLabel name="web_app_url" label={t('URL')} placeholder={t('Enter URL')} value={value} error={!!errors.web_app_url} onChange={onChange} />
                                        <i className="absolute right-0 bottom-0 w-[48px] h-[48px] flex items-center justify-center">
                                            <img src={Images.IconCopy} alt="" className="w-5 h-5 cursor-pointer" onClick={copyURL(value)} />
                                        </i>
                                    </div>
                                    {errors.web_app_url && <p className="text-error">{errors.web_app_url.message}</p>}

                                    {value && (
                                        <div className="flex -mx-[10px] mt-5">
                                            <div className="w-1/2 px-[10px]">
                                                <Button className="fl-btn btn_outline_black border w-full justify-center text-base font-bold " type="button" onClick={() => onImagePrint('WebQRCode')}>
                                                    {t('Print')}
                                                </Button>
                                            </div>
                                            <div className="w-1/2 px-[10px]">
                                                <Button className="fl-btn btn_primary" onClick={() => onImageDownload('WebQRCode')} type="button">
                                                    {t('Download')}
                                                </Button>
                                            </div>
                                        </div>
                                    )}
                                </>
                            )}
                        />
                    </div>
                </div>
                <div className="detail-page-data-block border-0">
                    <div className="title-block pe-3">
                        <h3 className="title-h3">{t(`FLAIR's Mobile Application`)}</h3>
                        <p className="text-xs text-secondaryTxtColor leading-[150%] -tracking-[0.12px]">{t(`FLAIR's_Mobile_Application_description`)}</p>
                    </div>
                    <div className="data-wrap-block max-w-[512px] 2xl:max-w-[700px] pe-[10px]">
                        <Controller
                            name="play_store_url"
                            control={control}
                            render={({ field: { onChange, value } }: any) => (
                                <>
                                    <div className="text-center mb-5">{value && <QRCode className="max-w-[208px] inline-block rounded-2xl" value={value} level="H" id="PlaystoreQRCode" />}</div>
                                    <div className="relative hyperlinks">
                                        <InputWithLabel name="play_store_url" label={t('Play Store')} placeholder={t('Enter URL')} value={value} error={!!errors.play_store_url} onChange={onChange} />
                                        <i className="absolute right-0 bottom-0 w-[48px] h-[48px] flex items-center justify-center">
                                            <img src={Images.IconCopy} alt="" className="w-5 h-5 cursor-pointer" onClick={copyURL(value)} />
                                        </i>
                                    </div>
                                    {errors.play_store_url && <p className="text-error">{errors.play_store_url.message}</p>}
                                    {value && (
                                        <div className="flex -mx-[10px] mt-5">
                                            <div className="w-1/2 px-[10px]">
                                                <Button
                                                    className="fl-btn btn_outline_black border w-full justify-center text-base font-bold "
                                                    type="button"
                                                    onClick={() => onImagePrint('PlaystoreQRCode')}
                                                >
                                                    {t('Print')}
                                                </Button>
                                            </div>
                                            <div className="w-1/2 px-[10px]">
                                                <Button className="fl-btn btn_primary" onClick={() => onImageDownload('PlaystoreQRCode')} type="button">
                                                    {t('Download')}
                                                </Button>
                                            </div>
                                        </div>
                                    )}
                                </>
                            )}
                        />
                    </div>
                    <div className="data-wrap-block max-w-[512px] 2xl:max-w-[700px]">
                        <Controller
                            name="app_store_url"
                            control={control}
                            render={({ field: { onChange, value } }: any) => (
                                <>
                                    <div className="text-center mb-5">{value && <QRCode className="max-w-[208px] inline-block rounded-2xl" value={value} level="H" id="AppstoreQRCode" />}</div>
                                    <div className="relative hyperlinks">
                                        <InputWithLabel name="app_store_url" label={t('App Store')} placeholder={t('Enter URL')} value={value} error={!!errors.app_store_url} onChange={onChange} />
                                        <i className="absolute right-0 bottom-0 w-[48px] h-[48px] flex items-center justify-center">
                                            <img src={Images.IconCopy} alt="" className="w-5 h-5 cursor-pointer" onClick={copyURL(value)} />
                                        </i>
                                    </div>
                                    {errors.play_store_url && <p className="text-error">{errors.play_store_url.message}</p>}
                                    {value && (
                                        <div className="flex -mx-[10px] mt-5">
                                            <div className="w-1/2 px-[10px]">
                                                <Button
                                                    className="fl-btn btn_outline_black border w-full justify-center text-base font-bold "
                                                    type="button"
                                                    onClick={() => onImagePrint('AppstoreQRCode')}
                                                >
                                                    {t('Print')}
                                                </Button>
                                            </div>
                                            <div className="w-1/2 px-[10px]">
                                                <Button className="fl-btn btn_primary" onClick={() => onImageDownload('AppstoreQRCode')} type="button">
                                                    {t('Download')}
                                                </Button>
                                            </div>
                                        </div>
                                    )}
                                </>
                            )}
                        />
                    </div>
                </div>
                <div className="flex justify-end">
                    <div className="w-1/2 p-6 flex justify-end">
                        <Button className="fl-btn btn_primary p-[10px] w-[76%]">{t('Save changes')}</Button>
                    </div>
                </div>
            </div>
        </form>
    );
};

export default Hyperlinks;
