import React, { useEffect, useState } from 'react';
import { API } from 'src/constants/api';
import { axiosGet } from 'src/utils/requestClient';
import { errorCode } from 'src/constants/errorCode';
import { toast } from 'react-toastify';
import Drawer from 'src/components/Drawer';
import BarLoader from 'src/components/BarLoader';
import { DataTable } from 'primereact/datatable';
import { FilterMatchMode } from 'primereact/api';
import { Column } from 'primereact/column';
import NotificationCardComponent from '../NotificationCardComponent';
import { INotification } from '../Notification.interface';
interface IProps {
    isOpen: boolean;
    handleClose: () => void;
    type: 'general' | 'appointment' | 'whatsNew';
    handleCloseAll: () => void;
}
const NotificationDrawers: React.FC<IProps> = ({ isOpen, handleClose, type, handleCloseAll }) => {
    const [loading, setLoading] = useState(false);
    const [notificationsData, setNotificationsData] = useState<any[]>([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [lazyState, setlazyState] = useState<any>({
        first: 0,
        rows: 10,
        page: 1,
        sortField: null,
        sortOrder: null,
        filters: {
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            type: { value: type },
        },
    });
    const onPage = (event: any) => {
        setlazyState(event);
    };
    useEffect(() => {
        if (isOpen) {
            loadNotification();
        }
    }, [lazyState, isOpen]);

    const loadNotification = async () => {
        try {
            setLoading(true);

            const payloadData = {
                data: {
                    ...lazyState,
                    filters: {
                        ...lazyState.filters,
                        type: { value: type },
                    },
                },
            };
            const response = await axiosGet(API.NOTIFICATION.LIST, {}, payloadData.data);
            if (response.data?.status === errorCode.success || response.data?.status === errorCode.updateSuccess) {
                setTotalRecords(response.data.data.totalRecords);
                setNotificationsData(response.data.data.data);
                return;
            }

            throw response.data;
        } catch (err: any) {
            toast.error(err?.message);
        } finally {
            setLoading(false);
        }
    };

    const customPaginatorTemplate = {
        layout: 'CurrentPageReport PrevPageLink PageLinks NextPageLink',
        CurrentPageReport: ({ first, last, totalRecords: totalRecordsNum }: { first: number; last: number; totalRecords: number }) => (
            <div className="text-mainTextColor flex items-center font-[SF-Pro] text-sm mr-auto">
                <span>
                    {first} - {last} of {totalRecordsNum}
                </span>
            </div>
        ),
    };
    return (
        <Drawer
            isOpen={isOpen}
            handleClose={handleClose}
            title={type === 'general' ? 'General' : 'Appointments'}
            isEditable
            isEditClose
            handleClickOutside={handleClose}
            classDrawer="your-custom-styles"
            drawerHeight="your-height-value"
        >
            <div className="px-10 w-full notification_modal">
                {!loading ? (
                    <DataTable
                        scrollable
                        scrollHeight="flex"
                        paginatorClassName="flex relative justify-end"
                        value={notificationsData}
                        lazy
                        first={lazyState.first}
                        rows={lazyState.rows}
                        paginatorTemplate={customPaginatorTemplate}
                        totalRecords={totalRecords}
                        paginator
                        loading={loading}
                        onPage={onPage}
                        sortOrder={lazyState.sortOrder}
                        filters={lazyState.filters}
                        dataKey="id"
                        className="font-[SF-Pro] text-sm flex-1 text-mainTextColor rounded-3xl overflow-hidden fl-data-table"
                        loadingIcon={<BarLoader />}
                        emptyMessage="No records found."
                        showHeaders={false}
                    >
                        <Column
                            body={(rowData: INotification, index) => {
                                const isLastRowOnPage = (index.rowIndex + 1) % lazyState.rows === 0 || index.rowIndex + 1 === totalRecords;
                                return (
                                    <NotificationCardComponent
                                        key={`${rowData.name}_${rowData.id}`}
                                        className={`flex gap-4 sm:py-6 py-2 items-center ${!isLastRowOnPage && 'border-b border-line'}  px-[8px]`}
                                        isNegative={false}
                                        handleCloseAll={handleCloseAll}
                                        notificationRecord={rowData}
                                    />
                                );
                            }}
                        />
                    </DataTable>
                ) : (
                    <BarLoader />
                )}
            </div>
        </Drawer>
    );
};

export default NotificationDrawers;
