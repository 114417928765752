import React from 'react';
import Logo from 'src/assets/svgs/Logo.svg';
import { logout } from 'src/utils/global-functions';

const Header = () => {
    const removeUser = () => {
        logout();
    };
    return (
        <header className="py-5 px-10 border-b">
            <div className="">
                <img onClick={removeUser} src={Logo} className="h-[24px] cursor-pointer" alt="FLAIR" />
            </div>
        </header>
    );
};

export default Header;
