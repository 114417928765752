import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import Button from 'src/components/Button';
import { useTranslation } from 'react-i18next';
import OTPField from 'src/components/OTPField';
import { IPinSetup2Props } from '../ReceptionistMode.interface';

const PinSetupStep2 = ({ isLoading }: IPinSetup2Props) => {
    const { control, formState } = useFormContext();
    const errors: any = formState.errors;
    const { t } = useTranslation();

    return (
        <>
            <h2 className="font-bold text-[20px] leading-[35px] -tracking-[0.336px] text-center pb-5">{t('Confirm 4 digit PIN')}</h2>
            <div className="flex flex-wrap">
                <Controller
                    name="pin_confirmation"
                    control={control}
                    render={({ field: { onChange, value } }: any) => (
                        <div className="w-full">
                            <div className="flex flex-col gap-8 text-center">
                                <p className="text-secondaryTxtColor leading-[19.6px] -tracking-[0.384px]">{t('Repeat your 4-digit code to enable receptionist mode')}</p>
                                <OTPField otp={value} setOtp={onChange} errors={errors.pin_confirmation} />
                                {errors.pin_confirmation && <p className="text-error">{errors.pin_confirmation.message}</p>}
                            </div>
                        </div>
                    )}
                />
            </div>
            <Button disable={isLoading} isLoading={isLoading} className={`fl-btn btn_primary mt-10`}>
                Confirm Pin
            </Button>
        </>
    );
};

export default PinSetupStep2;
