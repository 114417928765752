import React from 'react';
import { UseFormSetValue, useFormContext } from 'react-hook-form';
import InputWithLabel from 'src/components/InputWithLabel';
import SelectBox from 'src/components/SelectBox/SelectBox';

const SalesTaxesForm = ({
    Controller,
    territories,
    saleTaxes,
    setValue,
    setSelectedSaleTax,
}: {
    Controller: any;
    territories: any;
    saleTaxes?: any;
    setValue?: UseFormSetValue<{ gst: any; pst: any; hst: any }>;
    setSelectedSaleTax?: any;
}) => {
    const { control, formState } = useFormContext();
    const errors: any = formState.errors;

    // const defaultData = saleTaxes?.find(
    //     (obj: { territory: string }) => obj.territory === territories[0].value,
    // );

    // setSelectedSaleTax?.(defaultData);
    // setValue<'pst'>?.('pst', defaultData.pst);
    // setValue<'gst'>?.('gst', defaultData.gst);
    // setValue<'hst'>?.('hst', defaultData.hst);

    return (
        <div className="flex flex-wrap -mx-[10px]">
            <div className="px-[10px] mb-8 w-full">
                <Controller
                    name="territory"
                    control={control}
                    render={({ field: { onChange, value } }: any) => (
                        <>
                            <SelectBox
                                name="territory"
                                id="handlingUnit"
                                label="Province or territory"
                                options={territories}
                                onChangeFunc={(selectedOption: any) => {
                                    onChange(selectedOption);
                                    const data = saleTaxes?.find((obj: { territory: string }) => obj.territory === selectedOption.value);
                                    setSelectedSaleTax?.(data);
                                    setValue<'pst'>?.('pst', data.pst);
                                    setValue<'gst'>?.('gst', data.gst);
                                    setValue<'hst'>?.('hst', data.hst);
                                }}
                                value={value}
                            />
                            {errors.territory && <p className="text-error">{errors.territory.message}</p>}
                        </>
                    )}
                />
            </div>
            <div className="px-[10px] w-1/3">
                <Controller
                    name="gst"
                    control={control}
                    render={({ field: { onChange, value } }: any) => (
                        <div className="w-full">
                            <InputWithLabel
                                label={
                                    <>
                                        GST <span className="text-primary">Read-only</span>
                                    </>
                                }
                                onChange={onChange}
                                value={`%${value}`}
                                name="gst"
                                readOnly
                            />
                        </div>
                    )}
                />
            </div>
            <div className="px-[10px] w-1/3">
                <Controller
                    name="pst"
                    control={control}
                    render={({ field: { onChange, value } }: any) => (
                        <div className="w-full">
                            <InputWithLabel
                                label={
                                    <>
                                        PST <span className="text-primary">Read-only</span>
                                    </>
                                }
                                onChange={onChange}
                                value={`%${value}`}
                                name="pst"
                                readOnly
                            />
                        </div>
                    )}
                />
            </div>
            <div className="px-[10px] w-1/3">
                <Controller
                    name="hst"
                    control={control}
                    render={({ field: { onChange, value } }: any) => (
                        <div className="w-full">
                            <InputWithLabel
                                label={
                                    <>
                                        HST <span className="text-primary">Read-only</span>
                                    </>
                                }
                                onChange={onChange}
                                value={`%${value}`}
                                name="hst"
                                readOnly
                            />
                        </div>
                    )}
                />
            </div>
        </div>
    );
};

export default SalesTaxesForm;
