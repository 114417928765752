import React, { FC, useEffect, useState } from 'react';
import { IListProp, WaitListEntry } from './WaitList.interface';
import SearchBar from 'src/components/SearchBar/SearchBar';
import Button from 'src/components/Button';
import { capitalizeFirstLetterSpace, getShortName } from 'src/utils/global-functions';
import { usePhone } from 'src/hooks/usePhone';
import { format } from 'date-fns';
import Info from './Info';
import { axiosGet } from 'src/utils/requestClient';
import { API } from 'src/constants/api';
import { errorCode } from 'src/constants/errorCode';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { selectShopInfo, shopDetail } from 'src/app/Auth/Login/Login.slice';
import { toast } from 'react-toastify';
import BarLoader from 'src/components/BarLoader';

const List: FC<IListProp> = ({ handleAction }) => {
    const handleActionFun = (type: string) => {
        handleAction(type);
    };
    const [waitList, setWaitList] = useState<WaitListEntry[]>([]);
    const [filterWaitList, setFilterWaitList] = useState<WaitListEntry[]>([]);
    const dispatch = useAppDispatch();
    const shop: any = useAppSelector(selectShopInfo);
    const shopId = shop.id;
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const { getCustomFormatPhone } = usePhone();
    const [searchValue, setSearchValue] = useState('');

    const [listDetail, setListDetail] = useState<any>(null);
    const getWaitlist = async () => {
        try {
            const response = await axiosGet(
                API.WAITLIST.LIST,
                {
                    shop_id: shopId,
                },
                {},
            );
            if (response.data?.status === errorCode.success || response.data?.status === errorCode.updateSuccess) {
                await dispatch(shopDetail());
                setWaitList(response.data.data);
                setFilterWaitList(response.data.data);
                return;
            }

            throw response.data;
        } catch (err: any) {
            toast.error(err?.message);
        } finally {
            setIsLoading(false);
        }
    };
    useEffect(() => {
        getWaitlist();
    }, []);
    const hanldeListDetail = (event: any) => {
        console.log('event', event);
        const payload = {
            selectedOption: {
                email: event.user.email,
                first_name: event.user.first_name,
                last_name: event.user.last_name,
                phone: event.user.phone,
                phone_country_code: event.user.phone_country_code,
                profile_image_url: event.user.profile_image_url,
                created_at: event.user.created_at,
                staff_name: event.staff.full_name,
                time_slot: event.wait_date,
            },
            waitListDetail: event,
        };
        setListDetail(payload);
        // setListDetail({
        //     selectedOption:event.
        // })
        // const initialSidebarState = {
        //     disabledServices: [],
        //     serviceArrayList: [],
        //     selectedDate: moment(calendarData.selectedDate).toDate(),
        //     selectedTime: moment(calendarData.selectedDate).toDate(),
        //     selectedOption: null,
        //     serviceOptionShow: true,
        // };
    };
    useEffect(() => {
        const filterList = waitList?.filter((obj: any) => {
            const name = obj.user.full_name ? obj.user.full_name.toLowerCase() : '';
            const phone = obj.user.phone ? obj.user.phone.toLowerCase() : '';

            const searchParam = searchValue ? searchValue.toLowerCase() : '';

            return name.includes(searchParam) || phone.includes(searchParam);
        });

        setFilterWaitList(filterList);
    }, [searchValue]);
    return listDetail === null ? (
        <>
            <div className="header px-4 flex justify-between items-center pt-4 pb-8">
                <h1 className="text-base font-bold leading-[22.4px] -tracking-[0.16px] text-mainTextColor">Waitlist</h1>
                <div className="flex">
                    {filterWaitList.length > 0 ? (
                        <button className="btn_primary py-[10px] px-4 w-[110px] justify-center border text-sm font-semibold " onClick={() => handleActionFun('create')}>
                            New Entry
                        </button>
                    ) : (
                        <button className="rounded-[40px] text-sm border border-solid border-mainTextColor py-[10px] px-8 w-[110px] " onClick={() => handleActionFun('default')}>
                            Cancel
                        </button>
                    )}
                </div>
            </div>
            <div className="px-4">
                <SearchBar placeholder="Search" onChange={(e: any) => setSearchValue(e.target.value)} />
            </div>
            {isLoading ? (
                <BarLoader />
            ) : (
                <>
                    <div className="pt-5 px-4">
                        <h3 className="font-bold text-sm leading-[140%] -tracking-[0.14px]">Entries ({filterWaitList.length})</h3>
                    </div>
                    {filterWaitList.length === 0 ? (
                        <div className="flex-1 px-4 flex justify-center items-center">
                            <div className="w-full text-center">
                                <h3 className="text-sm font-bold leading-[22.4px] -tracking-[0.16px] text-mainTextColor mb-2">No entries found</h3>
                                <p className="text-sm text-secondaryTxtColor leading-[140%] -tracking-[0.14px] mb-6">Add a new entry</p>
                                <Button className="text-base btn_primary w-full h-12" onClick={() => handleActionFun('create')}>
                                    New Entry
                                </Button>
                            </div>
                        </div>
                    ) : (
                        <>
                            <div className="overflow-y-auto">
                                {filterWaitList.map((entry) => {
                                    const getphoneinfo = getCustomFormatPhone(entry?.user?.phone, entry?.user?.phone_country_code);

                                    return (
                                        <div onClick={() => hanldeListDetail(entry)} key={entry.id}>
                                            <div className="pt-3 px-4 flex-1 overflow-y-auto">
                                                <div className="bg-white rounded-3xl border border-lineColor p-4 mb-3 cursor-pointer">
                                                    <div className="flex mb-3">
                                                        <figure className="w-[38px] h-[38px] basis-[38px] grow-0 shrink-0 rounded-full overflow-hidden mr-3 flex text-xs font-medium justify-center items-center bg-primary text-white">
                                                            {entry?.user.profile_image_url ? (
                                                                <img src={entry?.user.profile_image_url} alt="client" className="w-full h-full object-cover" />
                                                            ) : entry?.user.full_name ? (
                                                                getShortName(entry?.user.full_name)
                                                            ) : (
                                                                '+'
                                                            )}
                                                        </figure>
                                                        <div className="flex-1">
                                                            <p className="font-medium leading-[140%] -tracking-[0.14px] mb-[2px]">
                                                                {entry.user.full_name ? capitalizeFirstLetterSpace(entry.user.full_name) : getphoneinfo}
                                                            </p>
                                                            <p className="text-xs text-secondaryTxtColor leading-[150%] -tracking-[0.12px]">
                                                                Client since {format(new Date(entry.created_at), 'MMMM yyyy')}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    {entry.waitlist_services &&
                                                        entry.waitlist_services.map((service, index) => (
                                                            <div key={index}>
                                                                <div className="flex justify-between mb-3">
                                                                    <p className="font-bold leading-[140%] -tracking-[0.14px]">{service.staff_service.service.name}</p>
                                                                    <p className="font-bold leading-[140%] -tracking-[0.14px]">${service.price}</p>
                                                                </div>
                                                                <div>
                                                                    <div className="text-sm leading-[140%] -tracking-[0.14px] flex items-center mb-2">
                                                                        <span className="text-secondaryTxtColor w-[50px] basis-[50px] grow-0 shrink-0 mr-3">Staff:</span>
                                                                        <span className="font-medium flex-1 truncate">{entry.staff.full_name}</span> {/* Adjust according to actual data */}
                                                                    </div>
                                                                    <div className="text-sm leading-[140%] -tracking-[0.14px] flex items-center mb-2">
                                                                        <span className="text-secondaryTxtColor w-[50px] basis-[50px] grow-0 shrink-0 mr-3">Date:</span>
                                                                        <span className="font-medium flex-1 truncate">{entry.wait_date}</span> {/* Format date as needed */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </>
                    )}
                </>
            )}
        </>
    ) : (
        <Info handleAction={handleAction} listDetail={listDetail} setListDetail={setListDetail} getWaitlist={getWaitlist} />
    );
};

export default List;
