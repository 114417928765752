import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { API } from 'src/constants/api';
import { axiosGet, axiosPatch } from 'src/utils/requestClient';
import { RootState } from 'src/redux/store';
import { errorCode } from 'src/constants/errorCode';
import { ErrorType, IInitialState } from './FlairPayment.interface';

const initialState: IInitialState = {
    data: null,
    loading: false,
    error: null,
};

export const getStripeAccount = createAsyncThunk('/shop/stripe/account', async (payload, { rejectWithValue }) => {
    try {
        const response = await axiosGet(API.USER.GET_STRIPE_ACCOUNT);
        if (response.data.status === errorCode.success || response.data.status === errorCode.updateSuccess) {
            return response.data;
        }

        return rejectWithValue(response as ErrorType);
    } catch (err: any) {
        if (!err.response) {
            throw err;
        }
        return rejectWithValue(err.response.data as ErrorType);
    }
});
export const verifyStripeAccount = createAsyncThunk('/shop/stripe/account/verify', async (payload, { rejectWithValue }) => {
    try {
        const response = await axiosGet(API.USER.VERIFY_STRIPE_ACCOUNT);
        if (response.data.status === errorCode.success || response.data.status === errorCode.updateSuccess) {
            return response.data;
        }

        return rejectWithValue(response as ErrorType);
    } catch (err: any) {
        if (!err.response) {
            throw err;
        }
        return rejectWithValue(err.response.data as ErrorType);
    }
});
export const updateStripeAccountStatus = createAsyncThunk('/shop/stripe/account/status', async (payload, { rejectWithValue }) => {
    try {
        const response = await axiosPatch(API.USER.UPDATE_STRIPE_ACCOUNT_STATUS, {});
        if (response.data.status === errorCode.success || response.data.status === errorCode.updateSuccess) {
            return response.data;
        }

        return rejectWithValue(response as ErrorType);
    } catch (err: any) {
        if (!err.response) {
            throw err;
        }
        return rejectWithValue(err.response.data as ErrorType);
    }
});
export const FlairPaymentSlice = createSlice({
    name: 'shop/flair-payment',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getStripeAccount.pending, (state) => {
                state.loading = true;
            })
            .addCase(getStripeAccount.fulfilled, (state, { payload }: any) => {
                state.loading = false;
                state.data = payload;
            })
            .addCase(getStripeAccount.rejected, (state, action: any) => {
                state.loading = false;
            })
            .addCase(verifyStripeAccount.pending, (state) => {
                state.loading = true;
            })
            .addCase(verifyStripeAccount.fulfilled, (state, { payload }: any) => {
                state.loading = false;
                state.data = payload;
            })
            .addCase(verifyStripeAccount.rejected, (state, action: any) => {
                state.loading = false;
            })
            .addCase(updateStripeAccountStatus.pending, (state) => {
                state.loading = true;
            })
            .addCase(updateStripeAccountStatus.fulfilled, (state, { payload }: any) => {
                state.loading = false;
                state.data = payload;
            })
            .addCase(updateStripeAccountStatus.rejected, (state, action: any) => {
                state.loading = false;
            });
    },
});
export const { reducer: accountReducer } = FlairPaymentSlice;

export const selectFlairPaymentDetailsData = (state: RootState) => state.FlairPaymentDetails.data;
export const selectLoading = (state: RootState) => state.FlairPaymentDetails.loading;
export const selectError = (state: RootState) => state.FlairPaymentDetails.error;
