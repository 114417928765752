import { Label, Radio } from 'flowbite-react';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import Button from 'src/components/Button';
import DateSelect from 'src/components/DateSelect/DateSelect';
import InputWithLabel from 'src/components/InputWithLabel';
import { Images } from 'src/theme';
import { useTranslation } from 'react-i18next';
import { ROUTES } from 'src/constants/routes';
import OTPField from 'src/components/OTPField';
import { IPinSetupProps } from '../ReceptionistMode.interface';

const PinSetupStep1 = ({ onClick }: IPinSetupProps) => {
    const { control, formState, watch } = useFormContext();

    const errors: any = formState.errors;

    const { t } = useTranslation();

    return (
        <>
            <h2 className="font-bold text-[20px] leading-[35px] -tracking-[0.336px] text-center pb-5">{t('Setup 4 digit PIN')}</h2>
            <div className="flex flex-wrap">
                <Controller
                    name="pin"
                    control={control}
                    render={({ field: { onChange, value } }: any) => (
                        <div className="w-full">
                            <div className="flex flex-col gap-8 text-center">
                                <p className="text-secondaryTxtColor leading-[19.6px] -tracking-[0.384px]">{t('Enter 4-digit code to enable receptionist mode')}</p>
                                <OTPField otp={value} setOtp={onChange} errors={errors.pin} />
                                {errors.pin && <p className="text-error">{errors.pin.message}</p>}
                            </div>
                        </div>
                    )}
                />
            </div>
            <Button type="submit" onClick={onClick} className={`fl-btn btn_primary mt-10`}>
                Confirm
            </Button>
        </>
    );
};

export default PinSetupStep1;
