import React, { useState } from 'react';
import BrandingTheme from 'src/components/BrandingTheme';
import Button from 'src/components/Button';
import Popup from 'src/components/Popup';
import { IThemePopup } from './Appearance.interface';

const ThemeModal = (props: IThemePopup) => {
    const { activeTheme, handleThemeApply, handleClose, settings } = props;

    const [selectedTheme, setSelectedTheme] = useState<number>(activeTheme);

    const setTheme = (id: number) => () => {
        setSelectedTheme(id);
    };

    const content = (
        <div>
            <div className="mb-5">
                <h2 className="fwpo_heading_title w-[480px] mx-auto -tracking-[0.384px] text-center">Choose a starting theme</h2>
                <p className="mt-4 -tracking-[0.14px] leading-[140%] opacity-70 text-center">
                    Choose a starting theme for initial inspiration. You will be able to specify your exact brand and appearance next!
                </p>
            </div>
            <div className="mb-8">
                <h3 className="font-bold text-xl leading-[140%] -tracking-[0.2px] mb-2">Light themes:</h3>
                <BrandingTheme themeType="light" selectedTheme={selectedTheme} setSelectedTheme={setTheme} settings={settings} />
            </div>
            <div className="mb-10">
                <h3 className="font-bold text-xl leading-[140%] -tracking-[0.2px] mb-2">Dark themes:</h3>
                <BrandingTheme themeType="dark" selectedTheme={selectedTheme} setSelectedTheme={setTheme} settings={settings} />
            </div>
            <Button onClick={handleThemeApply(selectedTheme)} className="fl-btn btn_primary w-[586px] mx-auto block">
                Apply
            </Button>
        </div>
    );
    return <Popup size="max-w-[1214px]" className="p-8 pb-10" handleClose={handleClose} content={content} />;
};

export default ThemeModal;
