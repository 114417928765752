import React, { useState } from 'react';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import Button from 'src/components/Button';
import ToastBodyContainer from 'src/components/ToastContainer';
import InputPasswordWithLabel from 'src/components/InputPasswordWithLabel';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { errorCode } from 'src/constants/errorCode';

import { IFieldKey, IPasswordFiled } from './NewPassword.interface';
import { newPassword, selectLoading } from './NewPassword.slice';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'src/constants/routes';
import { useTranslation } from 'react-i18next';
import { forgotPasswordInfo } from '../VerifyOtp/VerifyOtp.slice';
import { shopDetail } from '../../Login/Login.slice';
import { logout } from 'src/utils/global-functions';

const NewPassword = () => {
    const schema = Yup.object({
        password: Yup.string()
            .required('Password is a required field')
            .min(8, 'Password must be at least 8 characters')
            .trim()
            .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*_|]).{8,}$/, 'Include at least one uppercase, lowercase, digit, and special character: !@#$%^&*_|'),
        password_confirmation: Yup.string()
            .required('Password confirmation is a required field')
            .oneOf([Yup.ref('password')], 'Passwords do not match'),
    }).required();
    const dispatch = useAppDispatch();
    const loading = useAppSelector(selectLoading);
    const navigate = useNavigate();
    const { t } = useTranslation();

    const isForgotPassword = useAppSelector(forgotPasswordInfo);
    const [forgotPasswordVerified, setForgotPasswordVerified] = useState<boolean>(false);

    const {
        handleSubmit,
        control,
        formState: { errors },
        setError,
    } = useForm<IPasswordFiled>({
        resolver: yupResolver(schema),
        defaultValues: {
            password: '',
            password_confirmation: '',
        },
    });

    const handleChange = async (data: IPasswordFiled) => {
        const result: any = await dispatch(newPassword(data));
        if (result.type === newPassword.fulfilled.toString()) {
            if (!isForgotPassword) {
                await dispatch(shopDetail());
                navigate(ROUTES.SIGNUP);
            } else {
                setForgotPasswordVerified(true);
            }
        }
        if (result.type === newPassword.rejected.toString()) {
            const response = result.payload.data.data;
            if (response.status === errorCode.unprocessable) {
                if (response.data) {
                    Object.keys(response.data).forEach((field) => {
                        setError(field as IFieldKey, {
                            type: 'manual',
                            message: response.data[field][0],
                        });
                    });
                }
                return;
            }
            toast.error(response.message);
        }
    };

    const redirectLogin = () => {
        logout();
    };

    return forgotPasswordVerified ? (
        <>
            <div className="content">
                <h1 className="mt-10 text-[32px] -tracking-[1.2px] leading-10 font-semibold">{t('Password changed')}</h1>
                <p className="mt-4 opacity-70 -tracking-[0.3px] leading-[140%]">{t('Your Password has been successfully changed')}</p>
                <button onClick={redirectLogin} className="text-base fl-btn btn_primary mt-10">
                    {t('Login')}
                </button>
            </div>
        </>
    ) : (
        <>
            <div className="content">
                <ToastBodyContainer />
                <h1 className="mt-10 text-[32px] -tracking-[0.384px] leading-10 font-semibold">{isForgotPassword ? t('Create a new password') : t('Login in to your account')}</h1>
                <p className="mt-4 opacity-70 -tracking-[0.14px] leading-[140%] ">
                    {isForgotPassword ? t('Create a new password to use for future logins') : t('Enter credits to manage your business')}
                </p>

                <div className="mt-10">
                    <form onSubmit={handleSubmit(handleChange)}>
                        <div className="flex flex-wrap">
                            <Controller
                                name="password"
                                control={control}
                                render={({ field: { onChange, value } }: any) => (
                                    <div className="flex flex-col w-full">
                                        <InputPasswordWithLabel
                                            label={isForgotPassword ? t('Password') : t('New Password')}
                                            placeholder={isForgotPassword ? t('Password') : t('New Password')}
                                            onChange={onChange}
                                            value={value.password}
                                            name="password"
                                            isFocus={true}
                                        />
                                        <p className="text-error">{errors.password && errors.password.message}</p>
                                    </div>
                                )}
                            />
                            {!isForgotPassword && (
                                <div className="mt-2 w-full">
                                    <p className="opacity-70 -tracking-[0.14px] leading-[140%]">{t('New password must contain')}:</p>
                                    <ul className="opacity-70 -tracking-[0.14px] leading-[140%] list-disc list-inside mt-1">
                                        <li className="">{t('At least 8 characters')}</li>
                                        <li className="">{t('At least 1 uppercase character')}</li>
                                        <li className="">{t('At least 1 lowercase character')}</li>
                                        <li className="">{t('At least 1 number or special character')}</li>
                                    </ul>
                                </div>
                            )}
                            <Controller
                                name="password_confirmation"
                                control={control}
                                render={({ field: { onChange, value } }: any) => (
                                    <div className="flex flex-col w-full mt-5">
                                        <InputPasswordWithLabel
                                            label={t('Confirm Password')}
                                            placeholder={t('Confirm Password')}
                                            onChange={onChange}
                                            value={value.confirm_password}
                                            name="confirm_password"
                                        />
                                        <p className="text-error">{errors.password_confirmation && errors.password_confirmation.message}</p>
                                    </div>
                                )}
                            />
                        </div>

                        <Button
                            disabled={loading}
                            isLoading={loading}
                            className={`fl-btn p-3 bg-primary text-white font-bold w-full mt-10 rounded-full hover:bg-blue-800 transition-all ease-in-out delay-75`}
                        >
                            {t('Confirm')}
                        </Button>
                    </form>
                </div>
            </div>
        </>
    );
};

export default NewPassword;
