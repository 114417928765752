import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { API } from 'src/constants/api';
import { errorCode } from 'src/constants/errorCode';
import { RootState } from 'src/redux/store';

import { axiosGet, axiosPost, axiosPatch, axiosDelete } from 'src/utils/requestClient';

interface ErrorType {
    message: string;
    status?: string;
    data?: any;
}

const initialState = {
    data: '',
    loading: false,
    error: null,
    serviceLength: 0,
};

export const addCategory = createAsyncThunk('/shop/category/add', async (payload: any, { rejectWithValue }) => {
    try {
        const response = await axiosPost(API.CATEGORY.CREATE, payload.payload, { shop_id: payload.shop_id });

        if (response.data.status === errorCode.success || response.data.status === errorCode.updateSuccess) {
            return response.data;
        }

        return rejectWithValue(response as ErrorType);
    } catch (err: any) {
        if (!err.response) {
            throw err;
        }
        return rejectWithValue(err.response.data as ErrorType);
    }
});

export const editCategory = createAsyncThunk('/shop/category/edit', async (payload: any, { rejectWithValue }) => {
    try {
        const response = await axiosPatch(API.CATEGORY.UPDATE, payload.payload, { shop_id: payload.shop_id, id: payload.id });

        if (response.data.status === errorCode.success || response.data.status === errorCode.updateSuccess) {
            return response.data;
        }

        return rejectWithValue(response as ErrorType);
    } catch (err: any) {
        if (!err.response) {
            throw err;
        }
        return rejectWithValue(err.response.data as ErrorType);
    }
});

export const categoryList = createAsyncThunk('/shop/category/list', async (payload: any, { rejectWithValue }) => {
    try {
        const response = await axiosGet(API.CATEGORY.LIST, payload);
        if (response.data.status === errorCode.success || response.data.status === errorCode.updateSuccess) {
            return response.data;
        }

        return rejectWithValue(response as ErrorType);
    } catch (err: any) {
        if (!err.response) {
            throw err;
        }
        return rejectWithValue(err.response.data as ErrorType);
    }
});

export const deleteCategory = createAsyncThunk('/shop/category/delete', async (payload: any, { rejectWithValue }) => {
    try {
        const response = await axiosDelete(API.CATEGORY.DELETE, payload.payload, { shop_id: payload.shop_id, id: payload.id });

        if (response.data.status === errorCode.success || response.data.status === errorCode.updateSuccess) {
            return response.data;
        }

        return rejectWithValue(response as ErrorType);
    } catch (err: any) {
        if (!err.response) {
            throw err;
        }
        return rejectWithValue(err.response.data as ErrorType);
    }
});

export const serviceList = createAsyncThunk('/shop/service/list', async (payload: any, { rejectWithValue }) => {
    try {
        const response = await axiosGet(API.CATEGORY.LIST, payload);
        if (response.data.status === errorCode.success || response.data.status === errorCode.updateSuccess) {
            return response.data;
        }

        return rejectWithValue(response as ErrorType);
    } catch (err: any) {
        if (!err.response) {
            throw err;
        }
        return rejectWithValue(err.response.data as ErrorType);
    }
});

export const addService = createAsyncThunk('/shop/service/add', async (payload: any, { rejectWithValue }) => {
    try {
        const response = await axiosPost(API.SERVICE.CREATE, payload.payload, { shop_id: payload.shop_id });

        if (response.data.status === errorCode.success || response.data.status === errorCode.updateSuccess) {
            return response.data;
        }

        return rejectWithValue(response as ErrorType);
    } catch (err: any) {
        if (!err.response) {
            throw err;
        }
        return rejectWithValue(err.response.data as ErrorType);
    }
});

export const editService = createAsyncThunk('/shop/service/edit', async (payload: any, { rejectWithValue }) => {
    try {
        const response = await axiosPatch(API.SERVICE.UPDATE, payload.payload, { shop_id: payload.shop_id, id: payload.id });

        if (response.data.status === errorCode.success || response.data.status === errorCode.updateSuccess) {
            return response.data;
        }

        return rejectWithValue(response as ErrorType);
    } catch (err: any) {
        if (!err.response) {
            throw err;
        }
        return rejectWithValue(err.response.data as ErrorType);
    }
});

export const deleteService = createAsyncThunk('/shop/service/delete', async (payload: any, { rejectWithValue }) => {
    try {
        const response = await axiosDelete(API.SERVICE.DELETE, payload.payload, { shop_id: payload.shop_id, id: payload.id });

        if (response.data.status === errorCode.success || response.data.status === errorCode.updateSuccess) {
            return response.data;
        }

        return rejectWithValue(response as ErrorType);
    } catch (err: any) {
        if (!err.response) {
            throw err;
        }
        return rejectWithValue(err.response.data as ErrorType);
    }
});

export const serviceSlice = createSlice({
    name: '/shop/service/detail',
    initialState,
    reducers: {
        setListing: (state, { payload }) => {
            state.data = payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(categoryList.pending, (state) => {
                state.loading = true;
            })
            .addCase(categoryList.fulfilled, (state, { payload }: any) => {
                state.loading = false;
                state.data = payload.data;
            })
            .addCase(categoryList.rejected, (state, { payload }: any) => {
                state.loading = false;
                state.data = payload.data;
            })
            .addCase(addCategory.pending, (state) => {
                state.loading = true;
            })
            .addCase(addCategory.fulfilled, (state, { payload }: any) => {
                state.loading = false;
                state.data = payload.data;
            })
            .addCase(addCategory.rejected, (state, { payload }: any) => {
                state.loading = false;
                state.data = payload.data;
            })
            .addCase(editCategory.pending, (state) => {
                state.loading = true;
            })
            .addCase(editCategory.fulfilled, (state, { payload }: any) => {
                state.loading = false;
                state.data = payload.data;
            })
            .addCase(editCategory.rejected, (state, { payload }: any) => {
                state.loading = false;
                state.data = payload.data;
            })
            .addCase(deleteCategory.pending, (state) => {
                state.loading = true;
            })
            .addCase(deleteCategory.fulfilled, (state, { payload }: any) => {
                state.loading = false;
                state.data = payload.data;
            })
            .addCase(deleteCategory.rejected, (state, { payload }: any) => {
                state.loading = false;
                state.data = payload.data;
            })
            .addCase(serviceList.pending, (state) => {
                state.loading = true;
            })
            .addCase(serviceList.fulfilled, (state, { payload }: any) => {
                state.loading = false;
                state.serviceLength = payload.data.length;
                state.data = payload.data;
            })
            .addCase(serviceList.rejected, (state, { payload }: any) => {
                state.loading = false;
                state.data = payload.data;
            })
            .addCase(addService.pending, (state) => {
                state.loading = true;
            })
            .addCase(addService.fulfilled, (state, { payload }: any) => {
                state.loading = false;
                state.data = payload.data;
            })
            .addCase(addService.rejected, (state, { payload }: any) => {
                state.loading = false;
                state.data = payload.data;
            })
            .addCase(editService.pending, (state) => {
                state.loading = true;
            })
            .addCase(editService.fulfilled, (state, { payload }: any) => {
                state.loading = false;
                state.data = payload.data;
            })
            .addCase(editService.rejected, (state, { payload }: any) => {
                state.loading = false;
                state.data = payload.data;
            })
            .addCase(deleteService.pending, (state) => {
                state.loading = true;
            })
            .addCase(deleteService.fulfilled, (state, { payload }: any) => {
                state.loading = false;
                state.data = payload.data;
            })
            .addCase(deleteService.rejected, (state, { payload }: any) => {
                state.loading = false;
                state.data = payload.data;
            });
    },
});
export const { reducer: accountReducer } = serviceSlice;
export const { setListing } = serviceSlice.actions;

export const selectServiceData = (state: RootState) => state.Service.data;
export const selectServiceLoading = (state: RootState) => state.Service.loading;
export const selectServiceLength = (state: RootState) => state.Service.serviceLength;
export const selectServiceError = (state: RootState) => state.Service.error;
