import React from 'react';
import { capitalizeFirstLetter } from 'src/utils/global-functions';
import { useTranslation } from 'react-i18next';
const PaymentCard = ({ cardData }: any) => {
    const { t } = useTranslation();
    return (
        <>
            <h2 className="items-center text-base font-bold mb-5">
                {capitalizeFirstLetter(cardData.card.brand)} {t('ending in')} {cardData.card.last4}
            </h2>

            <div className="flex text-xs">
                <div className="mr-10">
                    <p className="text-secondaryTxtColor mb-2">{t('Expires')}</p>
                    <p className="font-medium">
                        {cardData.card.exp_month}/{String(cardData.card.exp_year).slice(-2)}
                    </p>
                </div>
                <div className="mr-10">
                    <p className="text-secondaryTxtColor  mb-2">{t('Date added')}</p>
                    <p className="font-medium">
                        {new Date(cardData.created * 1000).toLocaleString('en-US', {
                            weekday: 'short',
                            month: 'short',
                            day: 'numeric',
                            hour: 'numeric',
                            minute: 'numeric',
                            hour12: true,
                        })}
                    </p>
                </div>
            </div>
        </>
    );
};

export default PaymentCard;
