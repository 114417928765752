export const APP_ROOT = '/';

export const ROUTES = {
    HOME: `${APP_ROOT}`,
    OTP: `${APP_ROOT}otp`,
    FORM: `${APP_ROOT}form`,
    TEST: `${APP_ROOT}test`,
    NEW_PASSWORD: `${APP_ROOT}password`,
    FORGOT_PASSWORD: `${APP_ROOT}forgot-password`,
    DASHBOARD: `${APP_ROOT}dashboard`,
    ANALYTICS: `${APP_ROOT}analytics`,
    SETTINGS: {
        STAFF: `${APP_ROOT}staff`,
        ADMIN: `${APP_ROOT}admin`,
        CLIENT: `${APP_ROOT}client`,
        LOCATION: `${APP_ROOT}locations`,
        SERVICES: `${APP_ROOT}services`,
        PAYMENT: `${APP_ROOT}payment`,
        GENERAL_SETTING: `${APP_ROOT}setting`,
    },
    SIGNUP: `${APP_ROOT}signup`,
    FLAIR_PAYMENT: `${APP_ROOT}flair-payment`,
    PAYMENT_METHOD: `${APP_ROOT}payment-method`,
    ACCOUNT_STEP: {
        PLAN: `${APP_ROOT}plan`,
        ACCOUNT: `${APP_ROOT}account`,
        COMPANY: `${APP_ROOT}company`,
        APP_LOGO: `${APP_ROOT}app-logo`,
        PAYMENT: `${APP_ROOT}payment`,
        BRANDING: `${APP_ROOT}branding`,
        CONTRACT: `${APP_ROOT}contract`,
        LOCATION: `${APP_ROOT}location`,
        WORKING_HOURS: `${APP_ROOT}working-hours`,
        STRIPE_ACCOUNT: `${APP_ROOT}stripe-account`,
        STRIPE_CONNECT_RETURN: `${APP_ROOT}stripe-connect-return`,
        BOOKING_PAYMENT: `${APP_ROOT}booking-payment`,
        COMPLETE_ACCOUNT: `${APP_ROOT}complete-account`,
    },
    CALENDAR: `${APP_ROOT}calendar`,
    CUSTOMIZATION: {
        APPEARANCE: `${APP_ROOT}appearance`,
    },
};
