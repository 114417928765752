import React, { useState } from 'react';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.min.css';
import AppointmentTable from './StaffDetail.AppointmentTable';
import BarLoader from 'src/components/BarLoader';
import { useTranslation } from 'react-i18next';

const Appointment = ({ staffInfo }: { staffInfo: any }) => {
    const { t } = useTranslation();
    const [isPastLoading, setIsPastLoading] = useState<boolean>(false);
    const [isUpcomingLoading, setIsUpcomingLoading] = useState<boolean>(false);
    return (
        <>
            <div className="drawer-content-block pb-10">
                <BarLoader isLoading={isPastLoading || isUpcomingLoading} />
                <div className="py-6 px-8">
                    <div className="rounded-3xl bg-white border border-lineColor drop-shadow-cardShadow mb-6">
                        <div className="px-6 mt-6 mb-5">
                            <h2 className="text-xl font-bold -tracking-[0.4px]">{t('Upcoming Appointments')}</h2>
                        </div>

                        <AppointmentTable type="upcoming" staffInfo={staffInfo} setIsPastLoading={setIsPastLoading} />
                    </div>
                    <div className="rounded-3xl bg-white border border-lineColor drop-shadow-cardShadow">
                        <div className="px-6 mt-6 mb-5">
                            <h2 className="text-xl font-bold -tracking-[0.4px]">{t('Latest Booking Activity')}</h2>
                        </div>
                        <AppointmentTable type="past" staffInfo={staffInfo} setIsUpcomingLoading={setIsUpcomingLoading} />
                    </div>
                </div>
            </div>
        </>
    );
};
export default Appointment;
