import { yupResolver } from '@hookform/resolvers/yup';
import React, { useMemo, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import Button from 'src/components/Button';
import InputWithLabel from 'src/components/InputWithLabel';
import Stepper from 'src/components/Stepper';
import * as Yup from 'yup';
import { ICountStep, ILoyalty1, ILoyalty2 } from './Loyalty.interface';
import { axiosPost } from 'src/utils/requestClient';
import { API } from 'src/constants/api';
import { selectShopInfo, selectShopSetting, shopDetail } from 'src/app/Auth/Login/Login.slice';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { errorCode } from 'src/constants/errorCode';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

const Loyalty = ({ handleClose }: any) => {
    const { t } = useTranslation();
    const [step, setStep] = useState<number | ICountStep>(1);
    const shop: any = useAppSelector(selectShopInfo);
    const shopSetting: any = useAppSelector(selectShopSetting);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const dispatch = useAppDispatch();

    const shopId = shop.id;

    const STEPS = useMemo(
        () => [
            {
                name: t('Receiving loyalty points'),
                step: 1,
            },
            {
                name: t('Spending loyalty points'),
                step: 2,
            },
        ],
        [],
    );
    const onSubmit = async (data: any) => {
        setIsLoading(true);
        if (step === 1) {
            handleNext();
            setIsLoading(false);
            return;
        }
        try {
            const response = await axiosPost(API.REFER.CREATE_OR_UPDATE, data, {
                shop_id: shopId,
            });
            if (response.data?.status === errorCode.success || response.data?.status === errorCode.updateSuccess) {
                handleClose();
                await dispatch(shopDetail());

                return;
            }

            throw response.data;
        } catch (err: any) {
            toast.error(err?.message);
        } finally {
            setIsLoading(false);
        }
    };
    const defaultValues: any = {
        spend_amount: shopSetting && shopSetting.loyalty_receive ? shopSetting.loyalty_receive.value.amount : 0,
        receive_point: shopSetting && shopSetting.loyalty_receive ? shopSetting.loyalty_receive.value.point : 0,
        spend_point: shopSetting && shopSetting.loyalty_spend ? shopSetting.loyalty_spend.value.point : 0,
        receive_amount: shopSetting && shopSetting.loyalty_spend ? shopSetting.loyalty_spend.value.amount : 0,
        spend_description: shopSetting && shopSetting.loyalty_spend ? shopSetting.loyalty_spend.description : '',
        receive_description: shopSetting && shopSetting.loyalty_receive ? shopSetting.loyalty_receive.description : '',
    };

    const validationSchema = [
        //validation for step1
        Yup.object({
            spend_amount: Yup.number()
                .typeError(t('This field not allowed null value'))
                .moreThan(0, t('Amount must be greater than zero'))
                .lessThan(100, t('Amount must be less than 100'))
                .required(t('This field is required')),
            receive_point: Yup.number()
                .typeError(t('This field not allowed null value'))
                .moreThan(0, t('Amount must be greater than zero'))
                .lessThan(100, t('Amount must be less than 100'))
                .required(t('This field is required')),
            spend_description: Yup.string().required(t('This field is required')),
        }).required(),
        //validation for step2
        Yup.object({
            spend_point: Yup.number()
                .typeError(t('This field not allowed null value'))
                .moreThan(0, t('Amount must be greater than zero'))
                .lessThan(100, t('Amount must be less than 100'))
                .required(t('This field is required')),
            receive_amount: Yup.number()
                .typeError(t('This field not allowed null value'))
                .moreThan(0, t('Amount must be greater than zero'))
                .lessThan(100, t('Amount must be less than 100'))
                .required(t('This field is required')),
            receive_description: Yup.string().required(t('This field is required')),
        }),
    ];

    const currentValidationSchema = validationSchema[step - 1] as unknown as Yup.ObjectSchema<ILoyalty1 | ILoyalty2, Record<string, any>, any, ''>;

    const methods = useForm({
        shouldUnregister: false,
        defaultValues,
        resolver: yupResolver(currentValidationSchema),
        mode: 'onChange',
    });
    const { handleSubmit, trigger, control, formState } = methods;
    const errors: any = formState.errors;

    const handleNext = async () => {
        const isStepValid = await trigger();
        if (isStepValid) {
            setStep((prevActiveStep) => prevActiveStep + 1);
        }
    };

    return (
        <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="mb-8 w-[325px] h-[46px] mx-auto">
                    <Stepper activeStep={step} steps={STEPS} />
                </div>
                <h2 className="font-bold text-[32px] leading-[124%] -tracking-[0.384px] text-center pb-8">
                    {step === 1 ? t('Receiving') : t('Spending')} {t('loyalty points')}
                </h2>
                {step === 1 && (
                    <>
                        <div className="flex -mx-[10px] mb-5">
                            <div className="w-1/2 px-[10px]">
                                <Controller
                                    name="spend_amount"
                                    control={control}
                                    render={({ field: { onChange, value } }: any) => (
                                        <div className="w-full">
                                            <InputWithLabel
                                                name="spend_amount"
                                                label={t('Client spend')}
                                                type="number"
                                                placeholder={t('Enter spend')}
                                                value={value}
                                                onChange={onChange}
                                                error={!!errors.spend_amount}
                                            />
                                            {errors.spend_amount && <p className="text-error">{errors.spend_amount.message}</p>}
                                        </div>
                                    )}
                                />
                            </div>
                            <div className="w-1/2 px-[10px]">
                                <Controller
                                    name="receive_point"
                                    control={control}
                                    render={({ field: { onChange, value } }: any) => (
                                        <div className="w-full">
                                            <InputWithLabel
                                                name="receive_point"
                                                label={t('Loyalty points')}
                                                placeholder={t('Enter points')}
                                                value={value}
                                                type="number"
                                                onChange={onChange}
                                                error={!!errors.receive_point}
                                            />
                                            {errors.receive_point && <p className="text-error">{errors.receive_point.message}</p>}
                                        </div>
                                    )}
                                />
                            </div>
                        </div>
                        <div className="w-full">
                            <Controller
                                name="spend_description"
                                control={control}
                                render={({ field: { onChange, value } }: any) => (
                                    <div className="w-full">
                                        <InputWithLabel
                                            name="spend_description"
                                            label={t('Loyalty description')}
                                            placeholder={t('Enter description')}
                                            value={value}
                                            onChange={onChange}
                                            error={!!errors.spend_description}
                                            textAearClassName="rounded-3xl"
                                            textArea
                                            rows={5}
                                        />
                                        {errors.spend_description && <p className="text-error">{errors.spend_description.message}</p>}
                                    </div>
                                )}
                            />
                        </div>
                    </>
                )}
                {step === 2 && (
                    <>
                        <div className="flex -mx-[10px] mb-5">
                            <div className="w-1/2 px-[10px]">
                                <Controller
                                    name="receive_amount"
                                    control={control}
                                    render={({ field: { onChange, value } }: any) => (
                                        <div className="w-full">
                                            <InputWithLabel
                                                name="receive_amount"
                                                type="number"
                                                label={t('Amount of points')}
                                                placeholder={t('Enter spend')}
                                                value={value}
                                                onChange={onChange}
                                                error={!!errors.receive_amount}
                                            />
                                            {errors.receive_amount && <p className="text-error">{errors.receive_amount.message}</p>}
                                        </div>
                                    )}
                                />
                            </div>
                            <div className="w-1/2 px-[10px]">
                                <Controller
                                    name="spend_point"
                                    control={control}
                                    render={({ field: { onChange, value } }: any) => (
                                        <div className="w-full">
                                            <InputWithLabel
                                                name="spend_point"
                                                type="number"
                                                label={t('Equals this worth')}
                                                placeholder={t('Enter points')}
                                                value={value}
                                                onChange={onChange}
                                                error={!!errors.spend_point}
                                            />
                                            {errors.spend_point && <p className="text-error">{errors.spend_point.message}</p>}
                                        </div>
                                    )}
                                />
                            </div>
                        </div>
                        <div className="w-full">
                            <Controller
                                name="receive_description"
                                control={control}
                                render={({ field: { onChange, value } }: any) => (
                                    <div className="w-full">
                                        <InputWithLabel
                                            name="receive_description"
                                            value={value}
                                            onChange={onChange}
                                            error={!!errors.receive_description}
                                            textArea
                                            rows={5}
                                            label={t('Loyalty description')}
                                            placeholder={t('Enter description')}
                                            textAearClassName="rounded-3xl"
                                        />
                                        {errors.receive_description && <p className="text-error">{errors.receive_description.message}</p>}
                                    </div>
                                )}
                            />
                        </div>
                    </>
                )}
                <div className="mt-10 text-center">
                    <Button className={`fl-btn btn_primary max-w-[420px]`} isLoading={isLoading} disabled={isLoading}>
                        {step === 1 ? t('Continue') : t('Save')}
                    </Button>
                </div>
            </form>
        </FormProvider>
    );
};

export default Loyalty;
