import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { IMenu, IMenuProps } from './StaffDetails.interface';
import { usePhone } from 'src/hooks/usePhone';
import { getShortName } from 'src/utils/global-functions';
import DropDownWithIcon from 'src/components/DropDownWithIcon';
import { SlArrowRight, SlCalender } from 'react-icons/sl';
import { TfiPencilAlt } from 'react-icons/tfi';
import { IoMdClose } from 'react-icons/io';
import { useTranslation } from 'react-i18next';

const Menu = ({ menuList, setActiveStep, staffInfo, setIsEditProfile, activeStep, setIsOpenRemovePopup }: IMenuProps) => {
    const { t } = useTranslation();
    useEffect(() => {}, []);
    const { getCustomFormatPhone } = usePhone();
    const handleAction = (e: any) => {
        if (e.value === 'edit_profile') {
            if (activeStep !== 1) setActiveStep(1);
            setIsEditProfile(true);
        }
        if (e.value === 'delete_account') {
            setIsOpenRemovePopup && setIsOpenRemovePopup(true);
        }
    };

    const actionBtnIcon = [
        // {
        //     label: <span>{t('Resend credentials')}</span>,
        //     value: 'resend_credential',
        //     icon1: <TfiEmail className="text-primary" size={20} />,
        //     icon1Position: 'left',
        //     icon2: <SlArrowRight className="text-secondaryTxtColor" />,
        //     icon2Position: 'right',
        // },
        {
            label: <span>{t('New appointment')}</span>,
            value: 'new_appointment',
            icon1: <SlCalender className="text-primary" size={20} />,
            icon1Position: 'left',
            icon2: <SlArrowRight className="text-secondaryTxtColor" />,
            icon2Position: 'right',
        },
        {
            label: <span>{t('Edit Profile')}</span>,
            value: 'edit_profile',
            icon1: <TfiPencilAlt className="text-primary" size={20} />,
            icon1Position: 'left',
            icon2: <SlArrowRight className="text-secondaryTxtColor" />,
            icon2Position: 'right',
        },
        {
            label: <span>{t('Delete Account')}</span>,
            value: 'delete_account',
            icon1: <IoMdClose className="text-dangerText" size={20} />,
            icon1Position: 'left',
            icon2: <SlArrowRight className="text-secondaryTxtColor" />,
            icon2Position: 'right',
        },
        // Add more objects as needed
    ];

    return (
        <div className="drawer-menu-block">
            {/* drawer user block */}
            <div className="pt-[43px] px-8 pb-6 border-b border-lineColor">
                {/* user img */}
                <div className="rounded-full bg-primary w-[164px] h-[164px] mx-auto">
                    <div className="w-full h-full overflow-hidden rounded-full flex justify-center items-center ">
                        {staffInfo.profile_image_url ? (
                            <img src={staffInfo.profile_image_url} alt="" className="w-full h-full object-cover" />
                        ) : (
                            <span className="text-[64px] font-medium text-white leading-[140%] -tracking-[0.64px]">{getShortName(`${staffInfo.first_name ?? ''} ${staffInfo.last_name ?? ''}`)}</span>
                        )}
                    </div>
                </div>
                {/* user info */}
                <div className="mt-6 text-center">
                    <h3 className="text-[28px] font-medium leading-[124%] -tracking-[1.2px] mb-[15px]">
                        {staffInfo.first_name && staffInfo.last_name && `${staffInfo.first_name} ${staffInfo.last_name}`}
                    </h3>
                    <h3 className="text-primary text-lg leading-[140%] -tracking-[0.4px]">{staffInfo.email}</h3>
                    <div className="mt-2 mb-5">
                        <h3 className="text-primary font-medium text-lg leading-[140%] -tracking-[0.4px]">{getCustomFormatPhone(staffInfo.phone, staffInfo.phone_country_code)}</h3>
                    </div>

                    <DropDownWithIcon
                        className="btn btn_outline_black text-base font-bold border flex w-full justify-center"
                        label=" Actions"
                        dropDownMenuClassName="w-full top-[100%] py-6"
                        dropDownMenuItemClassName="border-b text-left py-5 last:border-0"
                        dropDownMenuLinkClassName="mx-3"
                        data={actionBtnIcon}
                        setselected={(e: any) => {
                            handleAction(e);
                        }}
                    />
                </div>
            </div>
            {/* drawer user block end*/}
            {/* drawer menu block */}
            <ul className="list-none text-base py-6 px-8">
                {menuList.map((menu: IMenu) => (
                    <li className="mb-2" key={menu.step}>
                        <Link
                            className={`leading-[140%] -tracking-[0.4px] transition-all ease-in delay-100 hover:bg-inputBg rounded-3xl px-6 py-[14px] block ${
                                activeStep === menu.step ? 'bg-inputBg' : ''
                            }`}
                            to={'#'}
                            onClick={() => setActiveStep(menu.step)}
                        >
                            {menu.name}
                        </Link>
                    </li>
                ))}
            </ul>
            {/* drawer menu block end */}
        </div>
    );
};

export default Menu;
