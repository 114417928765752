import React, { FC } from 'react';
import { IDefaultProp } from './WaitList.interface';

const Default: FC<IDefaultProp> = ({ handleAction }) => {
    const handleWaitlist = () => {
        handleAction('list');
    };

    return (
        <div className="h-full p-4 flex justify-center items-center">
            <div className="text-center">
                <p className="text-sm text-secondaryTxtColor leading-[140%] -tracking-[0.14px] mb-6">Click anywhere on the calendar to create an appointment.</p>
                <button className="fl-btn btn_outline_black max-w-[200px] w-full justify-center border text-base font-medium mx-auto" onClick={handleWaitlist}>
                    Waitlist
                </button>
            </div>
        </div>
    );
};

export default Default;
