import React from 'react';
import ChangePassword from './ChangePassword';
import Profile from './Profile';
import { useTranslation } from 'react-i18next';

const AccountSettings = () => {
    const { t } = useTranslation();
    return (
        <div className="w-[full] rounded-2xl bg-white border-[1px] border-lineColor ">
            <div className="detail-page-title-block">
                <h2 className="text-xl font-bold leading-[140%] -tracing-[0.2px]">{t('Account settings')}</h2>
            </div>

            <div className="detail-page-data-block">
                <div className="title-block">
                    <h3 className="title-h3">{t('Personal details')}</h3>
                    <p className="text-xs text-secondaryTxtColor leading-[150%] -tracking-[0.12px] ">{t('personal_details_description')}</p>
                </div>
                <div className="data-wrap-block max-w-[512px] 2xl:max-w-[700px]">
                    <Profile />
                </div>
            </div>
            <div className="detail-page-data-block border-0">
                <div className="title-block">
                    <h3 className="font-bold text-[15px] leading-[35px] -tracking-[0.336px]">{t('Change password')}</h3>
                    <p className="text-xs text-secondaryTxtColor leading-[150%] -tracking-[0.12px] ">{t('change_password_description')}</p>
                </div>
                <div className="data-wrap-block max-w-[512px] 2xl:max-w-[700px]">
                    <ChangePassword />
                </div>
            </div>
        </div>
    );
};

export default AccountSettings;
