import React, { FC, useState } from 'react';
import * as Yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Button from 'src/components/Button';
import Card from 'src/components/Card';
import Checkbox from 'src/components/Checkbox';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { selectShopInfo, shopDetail } from 'src/app/Auth/Login/Login.slice';
import { addBookingPayReq } from './MyAccount.slice';
import { IBookingPayment, IFormData } from './MyAccount.interface';

const BookingPayment: FC<IBookingPayment> = ({ handleClose }) => {
    const dispatch = useAppDispatch();
    const shop: any = useAppSelector(selectShopInfo);
    const schema = Yup.object().shape({
        is_book_with_card: Yup.boolean().required('Status is required'),
    });
    const [isLoading, setIsLoading] = useState(false);

    const { handleSubmit, control } = useForm<IFormData>({
        resolver: yupResolver(schema),
        defaultValues: {
            is_book_with_card: shop.is_book_with_card ? true : false,
        },
    });

    const handleChange = async (data: any) => {
        setIsLoading(true);
        const result = await dispatch(addBookingPayReq({ is_book_with_card: data.is_book_with_card }));
        if (result.type === addBookingPayReq.fulfilled.toString()) {
            await dispatch(shopDetail());
            setIsLoading(false);
            handleClose();
        }
    };

    const handelCardSelect = (onChange: any, value: any) => (e: any) => {
        onChange(value);
    };

    return (
        <>
            <form onSubmit={handleSubmit(handleChange)}>
                <h2 className="text-[32px] text-center font-semibold mb-2 leading-[140%] -tracking-[1.2px]">Booking Payment Requirements</h2>
                <p className="text-center text-xs text-secondaryTxtColor leading-[150%] -tracking-[0.3px] mb-8 max-w-[590px] mx-auto">
                    Set the default booking payment requirements all clients will follow. You can change the booking payment requirements for a specific client through his client profile.
                </p>
                <Controller
                    name={`is_book_with_card`}
                    control={control}
                    render={({ field: { onChange, value } }: any) => (
                        <>
                            <Card className="rounded-3xl bg-white py-3 mb-6 cursor-pointer">
                                <div onClick={handelCardSelect(onChange, true)} className="flex items-center overflow-hidden ">
                                    <div className="flex-1">
                                        <h2 className="text-base font-bold leading-[140%] -tracking-[0.4px] cursor-pointer">Book with card</h2>

                                        <p className="leading-[140%] -tracking-[0.3px] opacity-70">
                                            Enable book with card to protect your staff and yourself from last minute cancelations and no shows. Your clients will need to confirm their appointment by
                                            entering their card details but won&apos;t be charged until the service is completed.
                                        </p>
                                    </div>
                                    <div className="custom-round-chekbox-block ml-7">
                                        <Checkbox id="location1" name="Location" onChange={handelCardSelect(onChange, true)} checked={value === true} />
                                    </div>
                                </div>
                            </Card>
                            <Card className="rounded-3xl bg-white py-3 cursor-pointer">
                                <div onClick={handelCardSelect(onChange, false)} className="flex items-center overflow-hidden ">
                                    <div className="flex-1">
                                        <h2 className="text-base font-bold leading-[140%] -tracking-[0.4px] cursor-pointer">Book without card</h2>

                                        <p className="leading-[140%] -tracking-[0.3px] opacity-70">
                                            Enable book without card in order to not require clients to have a card on file to reserve an appointment. This doesn&apos;t protect your team from
                                            cancelations and no shows.
                                        </p>
                                    </div>
                                    <div className="custom-round-chekbox-block ml-7">
                                        <Checkbox id="location1" name="Location" onChange={handelCardSelect(onChange, false)} checked={value === false} />
                                    </div>
                                </div>
                            </Card>
                        </>
                    )}
                />

                <div className="mt-[60px] text-center w-full">
                    <Button className="fl-btn btn_primary w-[420px] rounded-full" disabled={isLoading} isLoading={isLoading}>
                        Continue
                    </Button>
                </div>
            </form>
        </>
    );
};

export default BookingPayment;
