import React from 'react';
import Popup from 'src/components/Popup';
import { IStaffPopup } from './Staff.interface';
import Stepper from 'src/components/Stepper';

const StaffPopup = ({ handleCloseStaffPopup, renderStepComponent, step, STEPS }: IStaffPopup) => {
    const staffPopupContent = (
        <>
            <div className="mb-8 w-[325px] h-[46px] mx-auto">
                <Stepper activeStep={step} steps={STEPS} />
            </div>
            {renderStepComponent()}
        </>
    );

    return <Popup size="max-w-[896px]" className="p-8 pb-10" handleClose={() => handleCloseStaffPopup(false)} content={staffPopupContent} />;
};

export default StaffPopup;
