import React from 'react';

import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import PaymentElement from './PaymentMethod.Element';
import { IPops } from './PaymentMethod.interface';
import { useAppSelector } from 'src/redux/hooks';
import { selectLoading } from './PaymentMethod.slice';
import { useTranslation } from 'react-i18next';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY || '');

const PaymentMethod: React.FC<IPops> = ({ setStep }) => {
    const loading = useAppSelector(selectLoading);
    const { t } = useTranslation();

    return (
        <>
            <div className="w-[872px] 2xl:w-[1176px] flex-1 flex flex-col mt-[52px]">
                <div className="text-center mb-8">
                    <h2 className="fwpo_heading_title w-[480px] -tracking-[0.384px] leading-[124%] mb-4 mx-auto">{t('Add Card Details')}</h2>
                    <p className="text-sm -tracking-[0.14px] leading-[140%] max-w-[786px] text-secondaryTxtColor mx-auto">{t('payment_method_description')}</p>
                </div>

                <Elements stripe={stripePromise}>
                    <PaymentElement loading={loading} setStep={setStep} />
                </Elements>
            </div>
        </>
    );
};

export default PaymentMethod;
