import React from 'react';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import InputWithLabel from 'src/components/InputWithLabel';
import Button from 'src/components/Button';
import ToastBodyContainer from 'src/components/ToastContainer';
import { ROUTES } from 'src/constants/routes';
import InputPasswordWithLabel from 'src/components/InputPasswordWithLabel';
import { errorCode } from 'src/constants/errorCode';
import { LocalStorageKeys } from 'src/constants/keys';
import { selectLoading, shopDetail, userLogin } from './Login.slice';
import { ILoginProps } from './Login.interface';
import { useTranslation } from 'react-i18next';
import { locationListAction } from 'src/app/Location/Location.slice';

type FieldKey = 'email' | 'password';

const Login = () => {
    const { t } = useTranslation();

    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const loading = useAppSelector(selectLoading);

    const schema = Yup.object({
        email: Yup.string().email(t('Valid Gmail Address Needed')).required(t('Email required')),
        password: Yup.string().required(t('Password required')),
    }).required();

    const {
        handleSubmit,
        control,
        formState: { errors },
        setError,
    } = useForm<ILoginProps>({
        resolver: yupResolver(schema),
        defaultValues: {
            email: '',
            password: '',
        },
    });

    const HandleNavigate = (result: any) => {
        const isPasswordSet = result.payload.data.user.is_password_set;

        if (isPasswordSet === false) {
            navigate(ROUTES.NEW_PASSWORD);
        } else {
            navigate(ROUTES.SIGNUP);
        }
    };

    const handleChange = async (data: ILoginProps) => {
        const result: any = await dispatch(userLogin(data));
        if (result.type === userLogin.fulfilled.toString()) {
            localStorage.setItem(LocalStorageKeys.authToken, result.payload.data.token);
            const result2: any = await dispatch(shopDetail());
            if (result2.type === shopDetail.fulfilled.toString()) {
                HandleNavigate(result);
            }
        }

        if (result.type === userLogin.rejected.toString()) {
            const response = result.payload.data;
            if (response.status === errorCode.unprocessable) {
                if (response.data) {
                    Object.keys(response.data).forEach((field) => {
                        setError(field as FieldKey, {
                            type: 'manual',
                            message: response.data[field][0],
                        });
                    });
                } else if (response.message) {
                    setError('password', {
                        type: 'manual',
                        message: response.message,
                    });
                }
                return;
            }
            toast.error(response.message);
        }
    };

    const forgetPassFun = () => {
        navigate(ROUTES.FORGOT_PASSWORD);
    };

    return (
        <>
            <div className="content">
                <ToastBodyContainer />
                <h1 className="mt-10 text-[32px] -tracking-[1.2px] leading-10 font-semibold">{t('Login in to your account')}</h1>
                <p className="mt-4 opacity-70 -tracking-[0.3px] leading-[140%] ">{t('Enter your credentials to log in to FLAIR Manager')}</p>

                <div className="mt-10">
                    <form onSubmit={handleSubmit(handleChange)}>
                        <div className="flex flex-wrap">
                            <Controller
                                name="email"
                                control={control}
                                render={({ field: { onChange, value } }: any) => (
                                    <div className="flex flex-col w-full mb-5">
                                        <InputWithLabel label={t('Email')} placeholder={t('Email Address')} onChange={onChange} value={value.email} name="email" isFocus={true} />
                                        {errors.email && <p className="text-error">{errors.email.message}</p>}
                                    </div>
                                )}
                            />

                            <Controller
                                name="password"
                                control={control}
                                render={({ field: { onChange, value } }: any) => (
                                    <div className="flex flex-col w-full mb-4">
                                        <InputPasswordWithLabel label={t('Password')} placeholder={t('Password')} onChange={onChange} value={value.password} name="password" />
                                        {errors.password && <p className="text-error">{errors.password.message}</p>}
                                    </div>
                                )}
                            />

                            <Button type="button" onClick={forgetPassFun} disabled={loading} className={`ml-auto leading-[140%] text-sm font-bold text-primary -tracking-[0.14px]`}>
                                {t('Forgot Password?')}
                            </Button>
                        </div>
                        <Button isLoading={loading} disabled={loading} className={`fl-btn btn_primary mt-10`}>
                            {t('Continue')}
                        </Button>
                    </form>
                </div>
            </div>
        </>
    );
};

export default Login;
