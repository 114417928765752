import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Button from 'src/components/Button';
import { Images } from 'src/theme';
import { IDetail } from './Services.interface';
import AddNewServiceForm from './Services.AddNewService.Form';
import { getSelectBoxOptions, getShortName } from 'src/utils/global-functions';
import ChooseLocation from './Services.ChooseLocation';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { selectUserInfo } from '../Auth/Login/Login.slice';
import { toast } from 'react-toastify';
import { errorCode } from 'src/constants/errorCode';
import { editService, selectServiceLength, serviceList } from './Services.slice';
import Popup from 'src/components/Popup';
import WrapperHeading from 'src/components/WrapperHeading';
// import DropdownTick from 'src/components/DropdownTick';
import { usePhone } from 'src/hooks/usePhone';

const Detail = ({ handleBackBtn, openDeletePopup, serviceEditData, categoryList }: IDetail) => {
    const [saveDetails, setSaveDetails] = useState(false);

    const categoryOption = getSelectBoxOptions(categoryList);
    const serviceLength = useAppSelector(selectServiceLength);

    const user = useAppSelector(selectUserInfo);
    const dispatch = useAppDispatch();
    const shopId = user.shops[0].id;

    const openSavePopup = async () => {
        const isStepValid = await trigger();
        if (isStepValid) setSaveDetails(true);
    };
    const validationSchema =
        //validation for step1
        Yup.object({
            name: Yup.string().required('This field is required'),
            shop_category_id: Yup.number().required('This field is required'),
            // tags: Yup.array()
            // .of(Yup.string().required('This field is required'))
            // .min(1, 'This field is required')
            // .required('This field is required'),
            description: Yup.string().required('This field is required'),
            shop_location_id: Yup.array().min(1, 'This field is required'),
        }).required();

    const defaultValues = {
        name: serviceEditData.name,
        shop_category_id: serviceEditData.shop_category_id,
        // tags: serviceEditData.tags,
        description: serviceEditData.description,
        shop_location_id: serviceEditData.shop_location_id,
    };

    const {
        handleSubmit,
        control,
        trigger,
        formState: { errors },
    } = useForm<any>({
        resolver: yupResolver(validationSchema),
        defaultValues: defaultValues,
    });

    const onSubmit = async (data: any) => {
        const submitData = {
            payload: data,
            shop_id: shopId,
        };

        const result = await dispatch(editService({ ...submitData, id: serviceEditData.id }));
        if (result.type === editService.fulfilled.toString()) {
            handleBackBtn(false);
        }
        if (result.type === editService.rejected.toString()) {
            const response = result.payload.data;
            if (response.status === errorCode.unprocessable) {
                return;
            }
            toast.error(response.message);
        }
    };

    const saveContent = (
        <div className="text-center">
            <h3 className="mb-5 text-xl font-bold text-mainTextColor -tracking-[0.2px]">Save Changes</h3>
            <h3 className="mb-8 text-sm font-normal text-secondaryTxtColor -tracking-[0.2px]">Are you sure you want to save?</h3>
            <div className="w-full">
                <Button className="fl-btn btn_primary px-[10px] w-full mb-3" onClick={handleSubmit(onSubmit)}>
                    {"Yes, I'm sure"}
                </Button>
                <Button className="fl-btn px-[10px] btn_outline_black text-base w-full text-center justify-center font-bold" onClick={() => setSaveDetails(false)}>
                    No, cancel
                </Button>
            </div>
        </div>
    );

    // const addNewOptions = ['Add New Service', 'Add New Category'];

    const { getCustomFormatPhone } = usePhone();

    const formatPhoneNumber = (phone: any, countryCode: any) => {
        const getphoneinfo = getCustomFormatPhone(phone, countryCode);
        return getphoneinfo && getphoneinfo;
    };

    return (
        <>
            <div className="flex justify-between items-center">
                <div className="flex items-center">
                    <WrapperHeading content="Services" />
                    <span className="ml-[18px] text-secondaryTxtColor text-base font-medium -tracking-[0.16px]">&bull; ({serviceLength})</span>
                </div>
                {/* <div className="w-[193px]">
                    <DropdownTick
                        className="bg-primary text-base font-bold h-10 px-2 py-2 w-full text-white  tracking-tight rounded-full text-center flex items-center justify-center cursor-pointer"
                        label="Add new"
                        dropDownMenuClassName="w-full top-[48px]"
                        data={addNewOptions}
                        setselected={(e: any) => {
                            setPopupType(e === 'Add New Category' ? 'add_new_category' : 'add_new_service');
                        }}
                    />
                </div> */}
            </div>
            <div className="w-[full] rounded-2xl bg-white mt-6 border-[1px] border-lineColor ">
                <div className="detail-page-title-block">
                    <div className="flex items-center">
                        <Link className="border border-lineColor rounded-lg p-1 mr-5" to="" onClick={() => handleBackBtn(false)}>
                            <img src={Images.IconChevronRight} alt="" className="" />
                        </Link>
                        <h2 className="text-lg font-bold leading-[140%] -tracing-[0.2px]">{serviceEditData.name}</h2>
                    </div>
                    <div className="flex">
                        <Button className="fl-btn px-[50px] btn_outline_danger mr-5" onClick={openDeletePopup}>
                            Delete
                        </Button>

                        <Button
                            className="fl-btn btn_primary px-[60px]"
                            onClick={openSavePopup}
                            // disabled={!_.isEmpty(errors)}
                            // disabled={}
                        >
                            Save
                        </Button>
                    </div>
                </div>

                <form>
                    <div className="detail-page-data-block">
                        <div className="title-block">
                            <h3 className="title-h3">Service details</h3>
                        </div>
                        <div className="data-wrap-block max-w-[512px] 2xl:max-w-[700px]">
                            <div className="h-full flex flex-wrap">
                                <AddNewServiceForm optionCountry={categoryOption} control={control} errors={errors} />
                            </div>
                        </div>
                    </div>
                    <div className="detail-page-data-block">
                        <div className="title-block">
                            <h3 className="title-h3">Location</h3>
                            <p className="title-block-text ">Choose the location where the service will take place.</p>
                        </div>
                        <div className="data-wrap-block max-w-[512px] 2xl:max-w-[750px]">
                            <ChooseLocation editableForm={true} editForm={true} control={control} errors={errors} />
                        </div>
                    </div>
                </form>
                {serviceEditData.staff.length > 0 ? (
                    <div className="detail-page-data-block border-0">
                        <div className="title-block">
                            <h3 className="title-h3">Staff</h3>
                            <p className="title-block-text ">All staff who have connected this service</p>
                        </div>

                        <div className="data-wrap-block max-w-[512px] 2xl:max-w-[700px]">
                            <table className="staff-table ">
                                <tbody>
                                    {serviceEditData.staff.map((staff: any, index: number) => (
                                        <tr className="" key={index}>
                                            <td className="">
                                                <div className="staff-img-name flex items-center">
                                                    <figure className="staff-img w-[38px] h-[38px] basis-[38px] grow-0 shrink-0  overflow-hidden rounded-2xl">
                                                        {staff.profile_image_url ? (
                                                            <img src={staff.profile_image_url} alt="client" className="w-full h-full object-cover" />
                                                        ) : (
                                                            getShortName(`${staff.first_name} ${staff.last_name}`)
                                                        )}
                                                    </figure>

                                                    <div className="staff-info ml-3">
                                                        <h3 className="staff-name">
                                                            {staff.first_name ?? ''} {staff.last_name ? `${staff.last_name.charAt(0)}.` : ''}
                                                        </h3>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="">{formatPhoneNumber(staff.phone, staff.phone_country_code)}</td>
                                            <td className="text-right">{staff.email}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                ) : (
                    ''
                )}
            </div>
            {saveDetails && <Popup size="max-w-[407px]" className="p-8 pb-10" handleClose={() => setSaveDetails(false)} content={saveContent} />}
        </>
    );
};

export default Detail;
