import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import ColorPicker from 'src/components/ColorPicker';
import SelectBox from 'src/components/SelectBox/SelectBox';
import { ReactComponent as Cloud } from 'src/assets/svgs/upload 1.svg';
import { Images } from 'src/theme';
import { useAppSelector } from 'src/redux/hooks';
import { selectThemeResult } from './Appearance.slice';
import { ColorKeysToCheck, LightThemeCustomColorId, DarkThemeCustomColorId } from 'src/utils/global-variables';
import Toggle from 'src/components/Toggle/Toggle';

const FormSettings = ({ index, setting, logoImageUrl, themeOptions, handleAction, onChangeCrop }: any) => {
    const { control, setValue } = useFormContext();

    const allThemes = useAppSelector(selectThemeResult);

    const customBranding = async (key: any, value: string) => {
        if (allThemes) {
            const currentColorObj: any = allThemes.find((d: any) => d.id === setting?.shop_theme_id);
            if (currentColorObj) {
                let isThemeUpdatedToCustom = ColorKeysToCheck.some((keyTo: string) => {
                    const valueFromTypeObjects = setting?.[keyTo]?.toLowerCase();
                    const valueFromInitialState = currentColorObj.color?.[keyTo]?.toLowerCase();
                    return valueFromTypeObjects !== valueFromInitialState;
                });
                if (isThemeUpdatedToCustom) {
                    if (currentColorObj.type === 'light') {
                        setValue('settings[0].shop_theme_id', LightThemeCustomColorId);
                    } else if (currentColorObj.type === 'dark') {
                        setValue('settings[0].shop_theme_id', DarkThemeCustomColorId);
                    }
                }
            }
        }
    };

    return (
        <div className="basis-[340px] w-[340px] 2xl:basis-[580px] 2xl:w-[580px] grow-0 shrink-0 mr-5 2xl:mr-[118px]">
            <div className="mb-5">
                <Controller
                    name={`settings[${index}].shop_theme_id`}
                    control={control}
                    render={({ field: { value }, fieldState }: any) => (
                        <div onClick={handleAction('theme', value)}>
                            <SelectBox isDisabled label="Choose a system theme" placeholder="Choose your theme" options={themeOptions} value={themeOptions.find((theme: any) => theme.id === value)} />
                            {fieldState.error && <p className="text-error">{fieldState.error.message}</p>}
                        </div>
                    )}
                />
            </div>
            <div className="mb-5">
                <p className="mb-3 fl-field-title-label">Add your logo</p>
                <Controller
                    name="logo_image_name"
                    control={control}
                    render={({ fieldState }) => (
                        <>
                            <div className="bg-contentBackground w-full">
                                <div className="w-[full]">
                                    <div className={`h-[87px] overflow-hidden`}>
                                        {!logoImageUrl ? (
                                            <div className={`relative h-[87px] overflow-hidden rounded-md `}>
                                                <div className={`flex ${fieldState.error ? 'is-invalid' : ''} rounded-[3rem] gap-2 border-[1px] px-2 py-2  border-dashed items-center`}>
                                                    <>
                                                        <div
                                                            className={`drop-shadow-none shadow-none w-[63px] h-[63px] basis-[63px] shrink-0 grow-0 flex justify-center items-center ring-0 bg-inputBg rounded-full`}
                                                        >
                                                            <img src={Images.AddImgIcon} className="w-5" alt="" />
                                                        </div>

                                                        <h1 className="text-xs font-medium -tracking-[0.12px] text-secondaryTxtColor">Upload company logo in JPG or PNG. Max size of 800K</h1>
                                                    </>
                                                </div>
                                                <label htmlFor="fileInput" className="absolute left-0 top-0 z-20 cursor-pointer rounded-md px-8 py-2 overflow-hidden h-full w-full">
                                                    {''}
                                                    <input
                                                        type="file"
                                                        id="fileInput"
                                                        className="imgupload  w-full rounded-md text-mainTextColor px-8 py-2 mt-8 bg-btnclr "
                                                        style={{
                                                            visibility: 'hidden',
                                                        }}
                                                        onChange={onChangeCrop}
                                                    />
                                                </label>
                                            </div>
                                        ) : (
                                            <div
                                                className={`relative  h-[80px] overflow-hidden rounded-[40px] border border-lineLightColor todotest2 ${
                                                    fieldState.error ? 'is-invalid' : 'border-lineLightColor'
                                                }`}
                                            >
                                                <div className="w-auto h-full  flex items-center cursor-pointer justify-center overflow-hidden p-[5px]">
                                                    <img
                                                        style={{
                                                            height: '100%',
                                                            width: 'auto',
                                                            maxHeight: '55px',
                                                        }}
                                                        src={logoImageUrl}
                                                        className=""
                                                        alt="cropped"
                                                    />
                                                    <div className="  absolute top-0 left-0 w-full h-full  flex justify-end items-center pr-[8px] ">
                                                        <div className="bg-inputBg p-2 w-14 h-14 rounded-full flex justify-center items-center">
                                                            <Cloud width={20} height={20} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <label htmlFor="fileInput" className="absolute left-0 top-0 z-20 cursor-pointer rounded-md px-8 py-2 overflow-hidden h-full w-full">
                                                    {''}
                                                    <input
                                                        type="file"
                                                        id="fileInput"
                                                        className="imgupload  w-full rounded-md text-mainTextColor px-8 py-2 mt-8 bg-btnclr"
                                                        style={{
                                                            visibility: 'hidden',
                                                        }}
                                                        onChange={onChangeCrop}
                                                    />
                                                </label>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            {fieldState.error && <p className="text-error text-center">{fieldState.error.message}</p>}
                        </>
                    )}
                />
            </div>
            <div className="flex justify-between mb-6 items-center">
                <p className="text-base text-mainTextColor font-bold">Include business name next to logo display</p>
                <div>
                    <Controller
                        name={`settings[${index}].is_show_name`}
                        control={control}
                        render={({ field: { value, onChange }, fieldState }: any) => (
                            <Toggle
                                labelPosition="left"
                                id="recurring_appointment"
                                isChecked={value}
                                onChange={(event: any) => {
                                    onChange(event.target.checked);
                                }}
                                className=""
                                switchClassName="bg-secondaryTxtColor"
                            />
                        )}
                    />
                </div>
            </div>
            <div className="grid grid-cols-2 gap-5">
                <div className="clr-field">
                    <p className="fl-field-title-label">Content background</p>
                    <Controller
                        name={`settings[${index}].content_background`}
                        control={control}
                        render={({ field: { onChange, value }, fieldState }: any) => (
                            <>
                                <ColorPicker
                                    error={fieldState.error && true}
                                    setOneColor={value}
                                    customBranding={(colorValue: any) => {
                                        customBranding('content_background', colorValue.hex);
                                        onChange(colorValue.hex);
                                    }}
                                />
                                {fieldState.error && (
                                    <p className="text-error">
                                        {
                                            fieldState.error.message
                                            /*  (
                                                fieldState.error as FieldError
                                            ).message */
                                        }
                                    </p>
                                )}
                            </>
                        )}
                    />
                </div>
                <div className="clr-field">
                    <p className="fl-field-title-label">Cards color</p>
                    <Controller
                        name={`settings[${index}].cards_color`}
                        control={control}
                        render={({ field: { onChange, value }, fieldState }: any) => (
                            <>
                                <ColorPicker
                                    error={fieldState.error && true}
                                    setOneColor={value}
                                    customBranding={(colorValue: any) => {
                                        customBranding('cards_color', colorValue.hex);
                                        onChange(colorValue.hex);
                                    }}
                                />
                                {fieldState.error && <p className="text-error">{fieldState.error.message}</p>}
                            </>
                        )}
                    />
                </div>
                <div className="clr-field">
                    <p className="fl-field-title-label">Button color</p>
                    <Controller
                        name={`settings[${index}].button_color`}
                        control={control}
                        render={({ field: { onChange, value }, fieldState }: any) => (
                            <>
                                <ColorPicker
                                    error={fieldState.error && true}
                                    setOneColor={value}
                                    customBranding={(colorValue: any) => {
                                        customBranding('button_color', colorValue.hex);
                                        onChange(colorValue.hex);
                                    }}
                                />
                                {fieldState.error && <p className="text-error">{fieldState.error.message}</p>}
                            </>
                        )}
                    />
                </div>
                <div className="clr-field">
                    <p className="fl-field-title-label">Button text color</p>
                    <Controller
                        name={`settings[${index}].accent_color`}
                        control={control}
                        render={({ field: { onChange, value }, fieldState }: any) => (
                            <>
                                <ColorPicker
                                    error={fieldState.error && true}
                                    setOneColor={value}
                                    customBranding={(colorValue: any) => {
                                        customBranding('accent_color', colorValue.hex);
                                        onChange(colorValue.hex);
                                    }}
                                />
                                {fieldState.error && <p className="text-error">{fieldState.error.message}</p>}
                            </>
                        )}
                    />
                </div>
                <div className="clr-field">
                    <p className="fl-field-title-label">Main text color</p>
                    <Controller
                        name={`settings[${index}].main_text_color`}
                        control={control}
                        render={({ field: { onChange, value }, fieldState }: any) => (
                            <>
                                <ColorPicker
                                    error={fieldState.error && true}
                                    setOneColor={value}
                                    customBranding={(colorValue: any) => {
                                        customBranding('main_text_color', colorValue.hex);
                                        onChange(colorValue.hex);
                                    }}
                                />
                                {fieldState.error && <p className="text-error">{fieldState.error.message}</p>}
                            </>
                        )}
                    />
                </div>
                <div className="clr-field">
                    <p className="fl-field-title-label">Secondary text color</p>
                    <Controller
                        name={`settings[${index}].secondary_text_color`}
                        control={control}
                        render={({ field: { onChange, value }, fieldState }: any) => (
                            <>
                                <ColorPicker
                                    error={fieldState.error && true}
                                    setOneColor={value}
                                    customBranding={(colorValue: any) => {
                                        customBranding('secondary_text_color', colorValue.hex);
                                        onChange(colorValue.hex);
                                    }}
                                />
                                {fieldState.error && <p className="text-error">{fieldState.error.message}</p>}
                            </>
                        )}
                    />
                </div>
            </div>
            {/* <div className="mt-10">
                <Button
                    type="button"
                    onClick={handleAction('confirm_theme', 0, setting.type)}
                    className="btn_outline_black w-full justify-center font-bold border"
                >
                    Apply theme to all applications
                </Button>
            </div> */}
        </div>
    );
};

export default FormSettings;
