import React from 'react';
import Button from 'src/components/Button';
import { IProps } from './Location.interface';
import TipsDetailSelection from './Location.TipsDetailSelection';
import { useAppSelector } from 'src/redux/hooks';
import { selectLoading } from './Location.slice';

const TipsDetail = ({ handleClick, tipType, setTipType, isLoading = false }: IProps) => {
    const loading = useAppSelector(selectLoading);
    return (
        <div className="flex flex-col h-full justify-between">
            <div className="mb-10 text-center ">
                <h3 className="fwpo_heading_title w-[480px] mx-auto mb-4">Tips details</h3>
                <p className="text-secondaryTxtColor leading-[140%] -tracing-[0.3px] ">Configure the tipping options for this location. Clients will view these options at checkout.</p>
            </div>
            <TipsDetailSelection selectionContent="Configure the tipping options for (Location Name)" tipType={tipType} setTipType={setTipType} />
            <div className="flex w-full px-10 justify-center mt-10">
                <Button className="fl-btn btn_primary w-[420px] 2xl:w-[572px]" onClick={handleClick} type="button" disabled={isLoading} isLoading={isLoading}>
                    Create Location
                </Button>
            </div>
        </div>
    );
};

export default TipsDetail;
