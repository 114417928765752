import React, { FC } from 'react';
import { IEmptyMsgWithBtn } from './EmptyMsgWithBtn.interface';

const EmptyMsgWithBtn: FC<IEmptyMsgWithBtn> = ({ title, description, btnLabel, onClick }) => (
    <div className="flex h-80 w-full flex-col justify-center items-center">
        <h4 className="text-base font-bold mb-2">{title}</h4>
        <p className="-tracking-[0.3px] text-sm text-secondaryTxtColor font-normal mb-5 text-center">{description}</p>
        <button onClick={onClick} className="text-base font-bold btn_primary w-[200px] fl-btn">
            <div className="h-full flex items-center justify-center">{btnLabel}</div>
        </button>
    </div>
);

export default EmptyMsgWithBtn;
