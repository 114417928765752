import React from 'react';
import FormSettings from './FormSettings';
import WebApp from 'src/assets/customsvg/WebApp';

const WebSettings = ({ index, setting, logoImageUrl, themeOptions, handleAction, onChangeCrop, businessName }: any) => (
    <div className="flex ">
        <FormSettings index={index} setting={setting} logoImageUrl={logoImageUrl} themeOptions={themeOptions} handleAction={handleAction} onChangeCrop={onChangeCrop} />

        <div className="w-full max-w-[763px]">
            <p className="fl-field-title-label">Preview</p>
            <div className="rounded-2xl border-[2px] border-lineColor fl-web-app-tab-content oveflow-hidden p-2 2xl:py-5">
                <div
                    className=" p-4 max-w-[625px] w-full mx-auto rounded-2xl"
                    style={{
                        backgroundColor: setting.content_background,
                    }}
                >
                    <div className="fl-web-app-header flex items-center">
                        <img src={logoImageUrl} alt="" className="!max-h-[3rem] max-w-40" />
                        {setting.is_show_name && <span className="ml-3 text-base text-mainTextColor font-bold">{businessName}</span>}
                    </div>
                    <div className="w-full">
                        <WebApp selectedColors={setting} />
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export default WebSettings;
