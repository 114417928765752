import React, { useState } from 'react';

import ForgotPassword from './ForgotPassword/ForgotPassword';
import { IForgotPasswordStep } from './ForgotPassword/ForgotPassword.interface';
import VerifyOtp from './VerifyOtp/VerifyOtp';
import { CountryCode } from 'libphonenumber-js';

const Password = () => {
    const [forgotPasswordStep, setForgotPasswordStep] = useState<IForgotPasswordStep>('forgot_password');
    const [phone, setPhone] = useState<string>('');
    const [country, setCountry] = useState<CountryCode>('US');
    const [countryDigit, setCountryDigit] = useState<string>('1');
    const [formatedPhoneNumber, setFormatedPhoneNumber] = useState<string>('');

    return forgotPasswordStep === 'otp' ? (
        <VerifyOtp setForgotPasswordStep={setForgotPasswordStep} phone={phone} countryDigit={countryDigit} formatedPhoneNumber={formatedPhoneNumber} country={country} />
    ) : (
        <ForgotPassword setForgotPasswordStep={setForgotPasswordStep} setPhone={setPhone} setCountryDigit={setCountryDigit} setFormatedPhoneNumber={setFormatedPhoneNumber} setCountry={setCountry} />
    );
};

export default Password;
