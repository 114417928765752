import React from 'react';

const Card = ({ content, className, style, onClick, children }: any) => (
    <>
        <div
            className={`block p-6 w-full hover:border-primary
            border 
            border-gray-200 drop-shadow-cardShadow ${className}`}
            style={style}
            onClick={onClick}
        >
            {children}
            {content}
        </div>
    </>
);

export default Card;
