import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { selectShopInfo } from 'src/app/Auth/Login/Login.slice';
import BarLoader from 'src/components/BarLoader';
import Button from 'src/components/Button';
import InputWithLabel from 'src/components/InputWithLabel';
import { API } from 'src/constants/api';
import { errorCode } from 'src/constants/errorCode';
import { useAppSelector } from 'src/redux/hooks';
import { Images } from 'src/theme';
import { getShortName } from 'src/utils/global-functions';
import { axiosGet, axiosPost } from 'src/utils/requestClient';

const Notes = ({ clientInfo }: any) => {
    const shop: any = useAppSelector(selectShopInfo);
    const shopId = shop.id;

    const [errorMap, setErrorMap] = useState(new Map());

    const [clientNotesDetail, setClientNotesDetail] = useState<any>([]);

    const [loading, setLoading] = useState(false);
    const {
        handleSubmit,
        control,
        reset,
        formState: {},
    } = useForm<any>({
        defaultValues: {
            description: '',
        },
    });

    const noteList = async () => {
        setLoading(true);
        try {
            const response = await axiosGet(API.CLIENT.NOTE, {
                shop_id: shopId,
                client_id: clientInfo.id,
            });
            if (response.data?.status === errorCode.success || response.data?.status === errorCode.updateSuccess) {
                setLoading(false);
                setClientNotesDetail(response.data.data);
                return;
            }

            throw response.data;
        } catch (err: any) {
            toast.error(err?.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        noteList();
    }, []);

    const handleChange = async (data: any) => {
        setLoading(true);
        try {
            const payloadData = {
                data: {
                    description: data.description,
                },
                param: {
                    shop_id: shopId,
                    client_id: clientInfo.id,
                },
            };
            const response = await axiosPost(API.CLIENT.NOTE, payloadData.data, payloadData.param);

            if (response.data?.status === errorCode.success || response.data?.status === errorCode.updateSuccess) {
                setLoading(false);
                reset();
                noteList();
                return;
            }

            throw response.data;
        } catch (err: any) {
            toast.error(err?.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="w-full">
            <BarLoader isLoading={loading} />
            <div className="p-8">
                <form onSubmit={handleSubmit(handleChange)}>
                    <div className="flex gap-3 mb-8">
                        <div className="w-full">
                            <Controller
                                name="description"
                                control={control}
                                render={({ field: { onChange, value } }: any) => (
                                    <div className="flex flex-col w-full mb-5">
                                        <InputWithLabel placeholder="Write a comment" onChange={onChange} value={value} name="description" isFocus={true} />
                                    </div>
                                )}
                            />
                        </div>
                        <Button isLoading={loading} disabled={loading} className="w-12 min-w-12 h-12 notes_loader rounded-full bg-primary flex justify-center items-center">
                            <img src={Images.SendIcon} alt="send-icon" />
                        </Button>
                    </div>
                </form>

                {clientNotesDetail &&
                    clientNotesDetail.map((client: any, index: any) => (
                        <>
                            <div key={index} className="flex justify-between w-full border border-gray-200 drop-shadow-cardShadow rounded-3xl bg-white py-5 px-5 mb-3 last:mb-0">
                                <div className="flex items-center">
                                    <div className="w-11 h-11 me-3">
                                        <figure className="w-[38px] h-[38px] basis-[38px] grow-0 shrink-0 rounded-full overflow-hidden mr-3 flex text-xs font-medium justify-center items-center bg-primary text-white">
                                            {client.shop_admin_id ? (
                                                client.shop_admin.profile_image_url && !errorMap.get(client.shop_admin_id) ? (
                                                    <img
                                                        src={client.shop_admin.profile_image_url}
                                                        alt="client"
                                                        className="w-full h-full object-cover"
                                                        onError={() => {
                                                            setErrorMap((prevErrorMap) => new Map(prevErrorMap.set(client.shop_admin_id, true)));
                                                        }}
                                                    />
                                                ) : (
                                                    getShortName(`${client.shop_admin.first_name} ${client.shop_admin.last_name}`)
                                                )
                                            ) : client.staff.profile_image_url && !errorMap.get(client.staff_id) ? (
                                                <img
                                                    src={client.staff.profile_image_url}
                                                    alt="client"
                                                    className="w-full h-full object-cover"
                                                    onError={() => {
                                                        setErrorMap((prevErrorMap) => new Map(prevErrorMap.set(client.staff_id, true)));
                                                    }}
                                                />
                                            ) : (
                                                getShortName(`${client.staff.first_name} ${client.staff.last_name}`)
                                            )}
                                        </figure>
                                    </div>

                                    <div>
                                        <h2 className="text-base font-bold mb-1">
                                            {client.shop_admin_id ? `${client.shop_admin.first_name} ${client.shop_admin.last_name}` : `${client.staff.first_name} ${client.staff.last_name}`}
                                        </h2>
                                        <p className="text-secondaryTxtColor text-xs w-[360px]">{client.description}</p>
                                    </div>
                                </div>
                                <div>
                                    <p className="text-secondaryTxtColor text-xs">{moment(client.created_at).format('DD MMM YYYY • hh:mmA')}</p>
                                </div>
                            </div>
                        </>
                    ))}

                {/* <div className="flex justify-between w-full border border-gray-200 drop-shadow-cardShadow rounded-3xl bg-white py-5 px-5 mb-3 last:mb-0">
                    <div className="flex items-center">
                        <div className="w-11 h-11 me-3">
                            <img src={Images.ProfileImg} alt="person-image" className=" w-full h-full rounded-lg object-cover object-center" />
                        </div>

                        <div>
                            <h2 className="text-base font-bold mb-1">Wade Warren</h2>
                            <p className="text-secondaryTxtColor text-xs w-[410px]">Amet minim mollit non deserunt ullamco est sit do amet sint.</p>
                        </div>
                    </div>

                    <div>
                        <p className="text-secondaryTxtColor text-xs">10 Jan 2022 · 10:30AM</p>
                    </div>
                </div> */}
            </div>
        </div>
    );
};

export default Notes;
