import React, { useEffect, useMemo, useState } from 'react';
import WrapperHeading from 'src/components/WrapperHeading';
import Location from './Tabs/Location';
import Professional from './Tabs/Professional';
import Overview from './Tabs/Overview';
import SelectBox from 'src/components/SelectBox/SelectBox';
import { Images } from 'src/theme';
import CustomDropdownWithDate from 'src/components/CustomDropdownWithDate/CustomDropdownWithDate';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { selectShopInfo, selectShopLocation } from '../Auth/Login/Login.slice';
import { locationList } from '../Staff/Staff.slice';
import { getSelectBoxOptionsIdAsValue, getSelectBoxStaffOptionsIdAsValue } from 'src/utils/global-functions';
import { ISelectedLocation } from '../Staff/Staff.interface';
import { IActionType, IAnalyticsDetails } from './Analytics.interface';
import { toast } from 'react-toastify';
import { errorCode } from 'src/constants/errorCode';
import { axiosGet } from 'src/utils/requestClient';
import moment from 'moment';
import { API } from 'src/constants/api';
import StaffPerfomanceDrawer from '../Dashboard/Drawers/StaffPerfomanceDrawer';

const initAction: IActionType = {
    staffPerfomance: false,
};
interface IPayload {
    type: string;
    location_id?: number;
    end_date?: any;
    start_date?: any;
    staff_id?: number;
}
interface ITimeFileterOptions {
    label: string;
    value: string;
    endDate?: any;
    startDate?: any;
}

const timeFileterOptions: ITimeFileterOptions[] = [
    { label: 'All time', value: 'all' },
    { label: 'Today', value: 'today' },
    { label: 'Yesterday', value: 'yesterday' },
    { label: 'Weekly', value: 'weekly' },
    { label: 'Monthly', value: 'monthly' },
    { label: 'Quarterly', value: 'quarterly' },
    { label: 'Last Month', value: 'last_month' },
];
const selectAllOption: ISelectedLocation = {
    value: 0,
    label: 'All locations',
};
const Analytics = () => {
    const shop: any = useAppSelector(selectShopInfo);
    const [action, setAction] = useState(initAction);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    const shopId = shop.id;
    const shopLocationList: any = useAppSelector(selectShopLocation);

    const [locationOptions, setLocationOptions] = useState<any>(shopLocationList);
    const dispatch = useAppDispatch();
    const [AnalyticsDetails, setAnalyticsDetails] = useState<IAnalyticsDetails | null>(null);
    const [selectedLocation, setSelectedLocation] = useState<ISelectedLocation | null>(selectAllOption);
    const [timeFileterSelected, setTimeFileterSelected] = useState(timeFileterOptions[0]);
    const [staffOptionList, setStaffOptionList] = useState([]);
    const [selectedStaff, setSelectedStaff] = useState<any>();

    const tabs = useMemo(
        () => [
            {
                name: 'Overview',
                type: 'overview',
            },
            {
                name: 'By Professional',
                type: 'professional',
            },
            {
                name: 'By Location',
                type: 'location',
            },
        ],
        [],
    );
    const [activeTab, setActiveTab] = useState(tabs[0]);
    // const getLocationOptions = async () => {
    //     const data = {
    //         shop_id: shopId,
    //     };
    //     const result = await dispatch(locationList(data));
    //     if (result.type === locationList.fulfilled.toString()) {
    //         const locationData: any = getSelectBoxOptionsIdAsValue(result.payload.data);
    //         const updatedLocationData = [selectAllOption, ...locationData];
    //         setLocationOptions(updatedLocationData);
    //     }
    // };

    useEffect(() => {
        const locationData: any = getSelectBoxOptionsIdAsValue(locationOptions);
        const updatedLocationData = [selectAllOption, ...locationData];
        setSelectedLocation(updatedLocationData[0]);
        setLocationOptions(updatedLocationData);
    }, []);
    const handleViewClick = (drawerName: string) => async (event: { preventDefault: () => void }) => {
        setAction((prevAction) => ({
            ...initAction,
            [drawerName]: true,
        }));
        setIsDrawerOpen(true);
    };
    const getAnalytics = async () => {
        try {
            let payload: IPayload = {
                type: 'all',
            };
            if (activeTab.type === 'location' && selectedLocation && selectedLocation.value !== 0) {
                payload.location_id = selectedLocation.id;
            }
            if (activeTab.type === 'professional' && selectedStaff && selectedStaff.value !== 0) {
                payload.staff_id = selectedStaff.id;
            }
            if (timeFileterSelected) {
                if (timeFileterSelected.value === 'custom') {
                    payload.start_date = moment(timeFileterSelected.startDate).format('YYYY-MM-DD');
                    payload.end_date = moment(timeFileterSelected.endDate).format('YYYY-MM-DD');
                }
                payload.type = timeFileterSelected.value;
            }
            const response = await axiosGet(
                API.ANALYTICS.GET,
                {
                    shop_id: shopId,
                },
                payload,
            );
            if (response.data?.status === errorCode.success || response.data?.status === errorCode.updateSuccess) {
                // setActiveTab({
                //     name: 'Overview',
                //     component: (
                //         <Overview
                //             AnalyticsDetails={response.data.data}
                //             handleViewClick={() => {}}
                //         />
                //     ),
                // });

                setAnalyticsDetails(response.data.data);
                return;
            }

            throw response.data;
        } catch (err: any) {
            toast.error(err?.message);
        } finally {
        }
    };

    const getStaff = async () => {
        try {
            const response = await axiosGet(API.STAFF.LIST, {
                shop_id: shopId,
            });
            if (response.data?.status === errorCode.success || response.data?.status === errorCode.updateSuccess) {
                const staffListArray: any = getSelectBoxStaffOptionsIdAsValue(response.data.data, 'id', 'first_name', 'last_name');
                setStaffOptionList(staffListArray);
                if (staffListArray.length > 0) {
                    setSelectedStaff(staffListArray[0]);
                }
                return;
            }

            throw response.data;
        } catch (err: any) {
            toast.error(err?.message);
        } finally {
        }
    };

    useEffect(() => {
        getAnalytics();
    }, [selectedLocation, timeFileterSelected, selectedStaff, activeTab]);
    useEffect(() => {
        // getLocationOptions();
        getStaff();
    }, []);

    const handleTabClick = (tab: any) => async (event: { preventDefault: () => void }) => {
        event.preventDefault();
        setActiveTab(tab);
    };

    return (
        <div className="inner-page-wrape">
            <div className="flex items-center">
                <WrapperHeading content="Analytics" />
                <div className="ml-auto flex -mx-2">
                    {activeTab.type === 'professional' && (
                        <div className="px-2 ">
                            <div className="w-[290px]">
                                <SelectBox
                                    options={staffOptionList}
                                    classComp="outline-select-box bg-white"
                                    value={selectedStaff}
                                    onChangeFunc={(e: any) => {
                                        setSelectedStaff(e);
                                    }}
                                    openOnFocus={true}
                                    autofocus={true}
                                />
                            </div>
                        </div>
                    )}
                    {activeTab.type === 'location' && (
                        <div className="px-2 ">
                            <div className="w-[290px]">
                                <SelectBox
                                    options={locationOptions}
                                    classComp="outline-select-box bg-white"
                                    value={selectedLocation}
                                    onChangeFunc={(e: any) => {
                                        setSelectedLocation(e);
                                    }}
                                    openOnFocus={true}
                                    autofocus={true}
                                    allowIcon={<img src={Images.mapLocationPin} alt="map-pin" />}
                                />
                            </div>
                        </div>
                    )}
                    <div className="px-2 ">
                        <div className="w-[290px]">
                            <CustomDropdownWithDate
                                data={timeFileterOptions}
                                handleChangeValue={(e: any) => {
                                    setTimeFileterSelected(e);
                                }}
                                value={timeFileterSelected}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="fl-tab-underline w-full mt-8">
                {tabs.map((tab, index) => (
                    <button key={index} type="button" className={`fl-tab-link ${activeTab.name === tab.name ? 'active' : ''}`} onClick={handleTabClick(tab)}>
                        {tab.name}
                    </button>
                ))}
            </div>
            {/* <div className="mt-8">{activeTab.component}</div> */}
            <div className="mt-8">
                {activeTab.type === 'overview' ? (
                    <Overview AnalyticsDetails={AnalyticsDetails} handleViewClick={handleViewClick} timeFileterSelected={timeFileterSelected} />
                ) : activeTab.type === 'professional' ? (
                    <Professional AnalyticsDetails={AnalyticsDetails} selectedStaff={selectedStaff} timeFileterSelected={timeFileterSelected} />
                ) : (
                    <Location AnalyticsDetails={AnalyticsDetails} handleViewClick={handleViewClick} timeFileterSelected={timeFileterSelected} />
                )}
                {action.staffPerfomance && <StaffPerfomanceDrawer isOpen={isDrawerOpen} handleClose={() => setIsDrawerOpen(false)} />}
            </div>
        </div>
    );
};

export default Analytics;
