import React, { FC, useEffect, useRef, useState } from 'react';
import 'cropperjs/dist/cropper.css';
import Exit from 'src/assets/svgs/exit.svg';
import { ReactComponent as Newlogo } from 'src/assets/svgs/newlogo.svg';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { Cropper, ReactCropperElement } from 'react-cropper';
import { ICropType, IPops } from './CropperModal.interface';
import { useTranslation } from 'react-i18next';

export const CircularProgressWithLabel: React.FC<any> = (props) => (
    <div style={{ position: 'relative', display: 'inline-flex' }}>
        <CircularProgressbar
            variant="determinate"
            {...props}
            styles={buildStyles({
                textSize: '16px',
                pathColor: '#1642C5',
                textColor: '#1F293C',
                trailColor: '#E3E7EF',
                backgroundColor: '#f00000',
            })}
        />
        <div
            style={{
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                position: 'absolute',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <p>{`${Math.round(props.value)}%`}</p>
        </div>
    </div>
);
function getRoundedCanvas(sourceCanvas: any) {
    var canvas = document.createElement('canvas');
    var context = canvas.getContext('2d');
    var width = sourceCanvas.width;
    var height = sourceCanvas.height;

    canvas.width = width;
    canvas.height = height;
    if (context) {
        context.imageSmoothingEnabled = true;
        context.drawImage(sourceCanvas, 0, 0, width, height);
        context.globalCompositeOperation = 'destination-in';
        context.beginPath();
        context.arc(width / 2, height / 2, Math.min(width, height) / 2, 0, 2 * Math.PI, true);
        context.fill();
    }
    return canvas;
}
const CropperModal: FC<IPops> = ({ imageUpload, imageUrl, setUpload, setCropData, isCropType = true, defaultCropType = 'Rounded', isLocation = false }) => {
    const { t } = useTranslation();
    const cropTypeArray: ICropType[] = ['Rounded', 'Square', 'Rectangular'];
    const cropperRef = useRef<ReactCropperElement>(null);
    const previewCanvasRef = useRef<HTMLCanvasElement>(null);
    const [cropType, setCropType] = useState<ICropType>(defaultCropType);
    const zoomSliderRef = useRef<HTMLInputElement>(null);

    const getCropData = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        if (cropperRef.current?.cropper) {
            const croppedCanvas = cropperRef.current?.cropper.getCroppedCanvas({
                rounded: true,
                imageSmoothingEnabled: true,
            });
            const imageData = cropType === 'Rounded' ? getRoundedCanvas(croppedCanvas).toDataURL() : croppedCanvas.toDataURL();
            if (croppedCanvas) {
                setCropData(imageData);
                setUpload(false);
                imageUpload && imageUpload(imageData as any);
            } else {
                // console.error('Failed to get cropped canvas.');
            }
        } else {
            // console.error('Cropper instance not available.');
        }
    };

    const [zoomLevel, setZoomLevel] = useState<any>(0);

    useEffect(() => {
        if (cropperRef.current && typeof cropperRef.current?.cropper.zoomTo === 'number') {
            cropperRef.current?.cropper.zoomTo(Number(zoomSliderRef.current!.value));
        }
    }, [zoomLevel]);

    const handleZoomChange = (e: any) => {
        const newZoomLevel = parseFloat(e.target.value);
        setZoomLevel(newZoomLevel);
        if (cropperRef.current) {
            cropperRef.current?.cropper.zoomTo(Number(newZoomLevel));
        }
    };
    const [naturalAspectRatio, setNaturalAspectRatio] = useState<any>(null);

    const handleCropType = (type: ICropType) => {
        setCropType(type);
        cropperRef.current?.cropper.reset();
        if (type === 'Rectangular') {
            cropperRef.current?.cropper.setAspectRatio(naturalAspectRatio);
        } else {
            const aspectRatio: number = 1 / 1;
            cropperRef.current?.cropper.setAspectRatio(aspectRatio);
        }
        // const aspectRatio: number = type === 'Rectangular' ? 16 / 9 : 1 / 1;
        // cropperRef.current?.cropper.setAspectRatio(aspectRatio);

        {
            let imageData = cropperRef.current?.cropper.getImageData();
            let imageData2 = cropperRef.current?.cropper.getCropBoxData();

            if (imageData && imageData2) {
                /* const minSliderZoom = imageData?.width / imageData.naturalWidth;
                const mintestZoom = 1 - imageData2?.height / imageData.height;

                setScale(mintestZoom); */
            }
        }
    };

    const handleImageLoaded = (e: any) => {
        const naturalWidth = e.target.naturalWidth;
        const naturalHeight = e.target.naturalHeight;
        const aspectRatio = naturalWidth / naturalHeight;
        setNaturalAspectRatio(aspectRatio);
    };

    // const onCropperZoom = (e: CustomEvent) => {
    //     const newZoomLevel = e.detail.ratio;
    //     console.log('newZoomLevel', newZoomLevel);
    //     setZoomLevel(newZoomLevel);
    //     if (zoomSliderRef.current) {
    //         zoomSliderRef.current.value = String(newZoomLevel);
    //     }
    // };
    return (
        <div className="">
            <div className=" z-30 fixed w-[100vw] h-[100%] left-0 top-[0%] flex justify-center items-center bg-[#87888d8f]">
                <div className="bg-white rounded-3xl py-10 px-10 w-[600px] mx-auto my-0 relative">
                    <img
                        src={Exit}
                        alt=""
                        className="absolute right-[40px] top-[40px] z-10 cursor-pointer"
                        onClick={() => {
                            // setUploadProgress(0);
                            // setCropData('');
                            setUpload(false);
                        }}
                    />
                    <div className="relative">
                        <div className="flex justify-between items-start mb-8">
                            <div className="flex gap-4 items-center">
                                <Newlogo />
                                <div className="flex flex-col">
                                    <h2 className="-tracking-[0.18px] font-bold  text-[18px] leading-[140%] mb-[6px] text-mainTextColor">
                                        {isLocation ? t('Add your location image') : t('Add your logo')}
                                    </h2>
                                    <p className="text-sm font-medium -tracking-[0.14px] leading-[140%] text-secondaryTxtColor">{t('Upload a 512 x 512 logo for best results')}</p>
                                </div>
                            </div>
                        </div>
                        {
                            <>
                                {isCropType && (
                                    <div className="fl-tab mb-6">
                                        {cropTypeArray.map((type: ICropType) => (
                                            <button key={type} type="button" value={type} className={`fl-tab-link ${cropType === type && 'active'}`} onClick={() => handleCropType(type)}>
                                                {type}
                                            </button>
                                        ))}
                                    </div>
                                )}
                            </>
                        }
                        <div className=" ">
                            <div className="h-[320px] mb-6 overflow-hidden rounded-3xl app-logo-img-block bg-baseBg">
                                <Cropper
                                    ready={handleImageLoaded}
                                    aspectRatio={1 / 1}
                                    autoCrop={true}
                                    autoCropArea={1}
                                    preview=".img-preview"
                                    src={imageUrl}
                                    center={true}
                                    ref={cropperRef}
                                    viewMode={1}
                                    guides={false}
                                    minCropBoxHeight={3}
                                    minCropBoxWidth={3}
                                    background={false}
                                    responsive={false}
                                    checkOrientation={true}
                                    cropBoxMovable={false}
                                    cropBoxResizable={false}
                                    dragMode="move"
                                    disabled={true}
                                    highlight={true}
                                    className={`${cropType === 'Rounded' ? 'imageCropper' : ''}`}
                                    // zoom={onCropperZoom}
                                />

                                <canvas
                                    ref={previewCanvasRef}
                                    style={{
                                        border: '1px solid black',
                                        objectFit: 'contain',
                                        height: 0.5,
                                    }}
                                    className="hidden"
                                />
                            </div>

                            <div className="Crop-Controls w-full mb-8">
                                <div className="flex justify-between mb-4">
                                    <h1 className=" text-foreground text-[18px] font-bold leading-[140%] -tracking-[0.18px] ">{t('Zoom')}</h1>
                                    <h1 className=" text-[#1642C5] text-[18px] font-bold leading-[140%] -tracking-[0.18px] ">{Math.round((zoomLevel / 3) * 100)}%</h1>
                                </div>
                                <div className="flex justify-between items-center  custom-range-slider">
                                    <input ref={zoomSliderRef} id="zoomSlider" type="range" min="0" max="3" step="0.01" value={zoomLevel} onChange={handleZoomChange} />
                                </div>
                            </div>
                            <button className="fl-btn btn_primary w-full " onClick={getCropData}>
                                {t('Crop and Save')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default CropperModal;
