import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { IAddCategoryFormData } from './Services.interface';
import InputWithLabel from 'src/components/InputWithLabel';
import Popup from 'src/components/Popup';
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { selectUserInfo } from '../Auth/Login/Login.slice';
import Button from 'src/components/Button';
import { addCategory, editCategory } from './Services.slice';
import { errorCode } from 'src/constants/errorCode';

type FieldKey = 'name' | 'description';
const CategoryPopup = ({ isEdit, handleClose, categoryObj, getService }: any) => {
    const user = useAppSelector(selectUserInfo);
    const shopId = user.shops[0].id;

    const dispatch = useAppDispatch();

    const schema = Yup.object({
        name: Yup.string().required('This field is required'),
        description: Yup.string().required('This field is required'),
    }).required();

    const {
        handleSubmit: handleCategorySubmit,
        control,
        formState: { errors },
        setError,
    } = useForm<IAddCategoryFormData>({
        resolver: yupResolver(schema),
        defaultValues: {
            name: categoryObj.name ? categoryObj.name : '',
            description: categoryObj.description ? categoryObj.description : '',
        },
    });

    const onCategorySubmit = async (data: any) => {
        const submitData = {
            payload: data,
            shop_id: shopId,
            id: isEdit,
        };

        if (isEdit > 0) {
            const result = await dispatch(editCategory(submitData));
            if (result.type === editCategory.fulfilled.toString()) {
                getService();
                handleClose();
            }
            if (result.type === editCategory.rejected.toString()) {
                const response = result.payload.data;
                if (response.status === errorCode.unprocessable) {
                    if (response.data) {
                        Object.keys(response.data).forEach((field) => {
                            setError(field as FieldKey, {
                                type: 'manual',
                                message: response.data[field][0],
                            });
                        });
                    }
                    return;
                }
                toast.error(response.message);
            }
        } else {
            const result = await dispatch(addCategory(submitData));
            if (result.type === addCategory.fulfilled.toString()) {
                getService();
                handleClose();
            }
            if (result.type === addCategory.rejected.toString()) {
                const response = result.payload.data;
                if (response.status === errorCode.unprocessable) {
                    if (response.data) {
                        Object.keys(response.data).forEach((field) => {
                            setError(field as FieldKey, {
                                type: 'manual',
                                message: response.data[field][0],
                            });
                        });
                    }
                    return;
                }
                toast.error(response.message);
            }
        }
    };

    const addCategoryContent = (
        <>
            <h3 className="text-[28px] font-bold -tracking-[0.336px] leading-[124%] text-center mb-8 ">{isEdit > 0 ? 'Edit Category' : 'New Category'}</h3>

            <form onSubmit={handleCategorySubmit(onCategorySubmit)} className="h-full">
                <div className="mb-5 w-full">
                    <Controller
                        name="name"
                        control={control}
                        render={({ field: { onChange, value } }: any) => (
                            <div className="w-full">
                                <InputWithLabel label="Category name" placeholder="e.g. Hair Services" onChange={onChange} isFocus={true} value={value} name="name" error={!!errors.name} />
                                {errors?.name && <p className="text-error">{errors?.name?.message}</p>}
                            </div>
                        )}
                    />
                </div>
                <div className="mb-5 w-full">
                    <Controller
                        name="description"
                        control={control}
                        render={({ field: { onChange, value } }: any) => (
                            <div className="w-full">
                                <InputWithLabel
                                    label="Category description"
                                    placeholder="Enter description"
                                    onChange={onChange}
                                    value={value}
                                    name="description"
                                    error={!!errors.description}
                                    textArea
                                    textAearClassName="h-[120px] rounded-3xl resize-none"
                                />
                                {errors?.description && <p className="text-error">{errors?.description?.message}</p>}
                            </div>
                        )}
                    />
                </div>
                <div className="flex w-full justify-center mt-10">
                    <Button className="fl-btn btn_primary w-full">{isEdit > 0 ? 'Save changes' : 'Create'}</Button>
                </div>
            </form>
        </>
    );

    return (
        <div>
            <Popup size="max-w-[600px]" className="p-10" handleClose={handleClose} content={addCategoryContent} />
        </div>
    );
};

export default CategoryPopup;
