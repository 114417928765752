import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import Button from 'src/components/Button';
import { useAppDispatch } from 'src/redux/hooks';
import { errorCode } from 'src/constants/errorCode';
import { IUpdatePhoneField, IUpdatePhoneFieldKey, IUpdatePhonePayload, IUpdatePhoneVerifyPayload } from './Profile.interface';
import { toast } from 'react-toastify';
import { phoneUpdate, phoneVerify } from './Profile.slice';
import OTPField from 'src/components/OTPField';
import { shopDetail } from 'src/app/Auth/Login/Login.slice';
import Number from 'src/components/Number';
import { useTranslation } from 'react-i18next';

const UpdatePhone = ({ handleClose }: any) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const [isVerify, setIsVerify] = useState(false);
    const [counter, setCounter] = useState(59);

    const schema = Yup.object({
        phone_country_code: Yup.string().required('This field is required'),
        phone: Yup.string().required('This field is required'),
    }).required();

    const {
        handleSubmit,
        control,
        formState: { errors },
        setError,
        watch,
        setValue,
    } = useForm<IUpdatePhoneField>({
        resolver: yupResolver(schema),
        defaultValues: {
            phone: '',
            otp: new Array(4).fill(''),
        },
    });

    const phoneValue = watch('phone');
    const phoneCodeValue = watch('phone_country_code');

    useEffect(() => {
        const timer: any = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
        return () => clearInterval(timer);
    }, [counter]);

    const handleSave = async (data: IUpdatePhoneField) => {
        if (isVerify) {
            const payload: IUpdatePhoneVerifyPayload = {
                ...data,
                otp: data.otp ? data.otp.join('') : '',
            };
            const result: any = await dispatch(phoneVerify(payload));
            if (result.type === phoneVerify.fulfilled.toString()) {
                dispatch(shopDetail());
                handleClose();
            }
            if (result.type === phoneVerify.rejected.toString()) {
                const response = result.payload.data;
                if (response.status === errorCode.unprocessable) {
                    if (response.data) {
                        Object.keys(response.data).forEach((field) => {
                            setError(field as IUpdatePhoneFieldKey, {
                                type: 'manual',
                                message: response.data[field][0],
                            });
                        });
                    }
                    return;
                }
                toast.error(response.message);
            }
        } else {
            const asyncFunction = handlerSendCode(data);
            asyncFunction();
        }
    };

    const handlerSendCode = (data: IUpdatePhoneField) => async () => {
        const payload: IUpdatePhonePayload = {
            phone_country_code: data.phone_country_code,
            phone: data.phone,
        };
        const result: any = await dispatch(phoneUpdate(payload));
        if (result.type === phoneUpdate.fulfilled.toString()) {
            setIsVerify(true);
            setCounter(59);
        }
        if (result.type === phoneUpdate.rejected.toString()) {
            const response = result.payload.data;
            if (response.status === errorCode.unprocessable) {
                if (response.data) {
                    Object.keys(response.data).forEach((field) => {
                        setError(field as IUpdatePhoneFieldKey, {
                            type: 'manual',
                            message: response.data[field][0],
                        });
                    });
                }
                return;
            }
            toast.error(response.message);
        }
    };

    return (
        <form onSubmit={handleSubmit(handleSave)}>
            <h2 className="font-bold text-[20px] leading-[35px] -tracking-[0.336px] text-center pb-5">{'Phone number update'}</h2>
            <div className="flex flex-wrap">
                <Controller
                    name="otp"
                    control={control}
                    render={({ field: { onChange, value } }: any) => (
                        <div className="w-full">
                            {isVerify && (
                                <div className="flex flex-col gap-8 text-center">
                                    <p className="text-secondaryTxtColor leading-[19.6px] -tracking-[0.384px]">{t('Enter 4-digit code to change phon')}e</p>
                                    <OTPField otp={value} setOtp={onChange} errors={errors.otp} />
                                    {errors.otp && <p className="text-error">{errors.otp.message}</p>}
                                    <div>
                                        <p className="text-secondaryTxtColor leading-[19.6px] -tracking-[0.384px] mb-2">{t("Haven't received the code yet?")}</p>
                                        {counter ? (
                                            <span className="leading-[19.6px] text-sm font-bold text-mainTextColor">
                                                {t('Send again')}
                                                <span className="leading-5 text-primary text-sm font-bold">
                                                    ({counter}
                                                    {t('sec')})
                                                </span>
                                            </span>
                                        ) : (
                                            <span
                                                onClick={handlerSendCode({
                                                    phone_country_code: phoneCodeValue,
                                                    phone: phoneValue,
                                                })}
                                                className="text-primary cursor-pointer leading-[19.6px] text-sm font-bold text-mainTextColor"
                                            >
                                                {t('Send again')}
                                            </span>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                />
                <Controller
                    name="phone"
                    control={control}
                    render={({ field: { value } }: any) => (
                        <div className="w-full">
                            {!isVerify && (
                                <>
                                    <label htmlFor="phone" className="fl-field-title-label">
                                        New phone number
                                    </label>
                                    <Number
                                        errors={errors}
                                        value={value}
                                        onNumberChange={(country: any, phone: any, code: any, isValid: any) => {
                                            if (isValid) {
                                                setValue('phone', `+${code}${phone}`);
                                                setValue('phone_country_code', country);
                                            } else {
                                                setValue('phone', '');
                                            }
                                        }}
                                    />
                                </>
                            )}
                        </div>
                    )}
                />
                <Controller name="phone_country_code" control={control} render={({ field: { onChange } }: any) => <input type="hidden" onChange={onChange} />} />
            </div>
            <Button className={`fl-btn btn_primary mt-10`}>Continue</Button>
        </form>
    );
};

export default UpdatePhone;
