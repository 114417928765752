import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { API } from 'src/constants/api';
import { errorCode } from 'src/constants/errorCode';
import { RootState } from 'src/redux/store';
import { axiosGet, axiosPost } from 'src/utils/requestClient';
import { IInitialState } from './Appearance.interface';

const initialState: IInitialState = {
    loading: false,
    hasErrors: false,
    themeResult: null,
    themeLoading: false,
};

export const themeList = createAsyncThunk('/shop/theme/list', async (_, { rejectWithValue }) => {
    try {
        const response: any = await axiosGet(API.THEME.LIST);
        if (response.data.status === errorCode.success) {
            return response.data;
        }
        return rejectWithValue({
            data: response,
        } as any);
    } catch (err: any) {
        if (!err.response) {
            throw err;
        }

        return rejectWithValue({
            message: err.response.data,
        } as any);
    }
});

export const updateAppearance = createAsyncThunk('/shop/appearance', async (payload: any, { rejectWithValue }) => {
    try {
        const response: any = await axiosPost(API.THEME.UPDATE_APPEARANCE, payload, { shop_id: payload.shop_id });
        if (response.data.status === errorCode.success || response.data.status === errorCode.updateSuccess) {
            return response.data;
        }
        return rejectWithValue({
            data: response,
        } as any);
    } catch (err: any) {
        if (!err.response) {
            throw err;
        }

        return rejectWithValue({
            message: err.response.data,
        } as any);
    }
});

export const AppearanceSlice = createSlice({
    name: 'Appearance',
    initialState,
    reducers: {
        setAccountDeatil: (state, { payload }) => {
            // Modify payload to action
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(themeList.pending, (state) => {
                state.themeLoading = true;
                state.hasErrors = false;
            })
            .addCase(themeList.fulfilled, (state, { payload }) => {
                state.themeLoading = false;
                state.hasErrors = payload.status;
                state.themeResult = payload.data;
            })
            .addCase(themeList.rejected, (state) => {
                state.themeLoading = false;
                state.hasErrors = true;
            })
            .addCase(updateAppearance.pending, (state) => {
                state.loading = true;
                state.hasErrors = false;
            })
            .addCase(updateAppearance.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.hasErrors = payload.status;
            })
            .addCase(updateAppearance.rejected, (state) => {
                state.loading = false;
                state.hasErrors = true;
            });
    },
});

export const selectLoading = (state: RootState) => state.Appearance.loading;
export const selectThemeLoading = (state: RootState) => state.Appearance.themeLoading;
export const selectThemeResult = (state: RootState) => state.Appearance.themeResult;
