import React, { useEffect } from 'react';
import { Navigate, RouteProps } from 'react-router-dom';
import { selectUserInfo } from 'src/app/Auth/Login/Login.slice';
import NonLayout from 'src/app/Layout/NonLayout/NonGuard';
import { APP_FULL_NAME } from 'src/constants/common';
import { ROUTES } from 'src/constants/routes';
import { useAppSelector } from 'src/redux/hooks';

type NotGuardedRouteProps = {
    Component: React.ComponentType<any>;
    title?: string;
} & RouteProps;

const NotGuardedRoute: React.FC<NotGuardedRouteProps> = ({ Component, title }) => {
    const user = useAppSelector(selectUserInfo);

    useEffect(() => {
        document.title = `${title} - ${APP_FULL_NAME}`;
    }, [title]);

    return (
        <>
            {user ? (
                <Navigate to={ROUTES.DASHBOARD} />
            ) : (
                <NonLayout>
                    <Component />
                </NonLayout>
            )}
        </>
    );
};

export default NotGuardedRoute;
