import React, { useCallback, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import Popup from 'src/components/Popup';
import { IMyAccountMethods } from './MyAccount.interface';
import PaymentMethod from './PaymentMethod';
import AddPayMethodContent from './AddPayMethodContent';
import RemoveCard from './RemoveCard';

const initAction = {
    payment: false,
    removepayment: false,
};
const MyAccountMethods = ({ isOpen, handleClose, setDefaultCard, defaultCard, getCard, listCard }: IMyAccountMethods) => {
    const [action, setAction] = useState(initAction);
    const [isLoading, setIsLoading] = useState(false);
    const [paymentMethodId, setPaymentMethodId] = useState<string>();

    const handleAction = (type: string) => () => {
        setAction((old) => ({ ...old, [type]: true }));
    };
    const handleModalClose = useCallback(() => {
        setAction(initAction);
    }, []);

    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY || '');

    return (
        <div>
            {isOpen && (
                <>
                    <Popup
                        size="max-w-[705px]"
                        className="p-8 pb-10"
                        handleClose={handleClose}
                        content={
                            <>
                                <PaymentMethod
                                    isLoading={isLoading}
                                    getCard={getCard}
                                    listCard={listCard}
                                    handleAction={handleAction}
                                    handleClose={handleClose}
                                    setPaymentMethodId={setPaymentMethodId}
                                    setDefaultCard={setDefaultCard}
                                    defaultCard={defaultCard}
                                />
                            </>
                        }
                    />
                </>
            )}
            {action.payment && (
                <Popup
                    size="max-w-[484px]"
                    className="p-8 pb-10"
                    handleClose={handleModalClose}
                    content={<AddPayMethodContent stripePromise={stripePromise} getCard={getCard} handleModalClose={handleModalClose} />}
                />
            )}

            {action.removepayment && (
                <Popup
                    size="max-w-[407px]"
                    className="p-8 pb-10"
                    handleClose={handleModalClose}
                    content={
                        <>
                            <RemoveCard paymentMethodId={paymentMethodId} setPaymentMethodId={setPaymentMethodId} getCard={getCard} handleClose={handleClose} handleModalClose={handleModalClose} />
                        </>
                    }
                />
            )}
        </div>
    );
};

export default MyAccountMethods;
