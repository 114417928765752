import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { selectUserInfo, shopDetail } from '../../Auth/Login/Login.slice';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { IBusinessField, IFieldKey } from './BusinessDetails.interface';
import { company } from './BusinessDetails.slice';
import { errorCode } from 'src/constants/errorCode';
import { s3Upload } from 'src/utils/s3Operations';
import { toast } from 'react-toastify';
import BusinessInfo from './BusinessInfo';
import ContactInfo from './ContactInfo';
import OnlineLinks from './OnlineLinks';
import Button from 'src/components/Button';
import { useTranslation } from 'react-i18next';

const BusinessDetails = () => {
    const { t } = useTranslation();

    const dispatch = useAppDispatch();
    const user = useAppSelector(selectUserInfo);
    const shop = user.shops[0];

    const [file, setFile] = useState<any>({ convertedFile: '', filename: '' });
    const [isLoading, setIsLoading] = useState(false);

    const urlSchema = Yup.string()
        .url('Please enter a valid URL')
        .transform((value, originalValue) => {
            if (originalValue && originalValue.startsWith('www')) {
                return `http://${originalValue}`;
            }
            return value;
        })
        .nullable();

    const schema = Yup.object().shape({
        business_name: Yup.string().required(t('Business name required')),
        logo_image_name: Yup.string().required(t('Logo required')),
        country: Yup.string().required(t('Country selection required')),
        state: Yup.string().required(t('State required')),
        city: Yup.string().required(t('City required')),
        address: Yup.string().required(t('Address required')),
        staff_size: Yup.string().required(t('Staff size required')),
        website: urlSchema,
        google_review: urlSchema,
        facebook_page: urlSchema,
        instagram_page: urlSchema,
        contact_details: Yup.array()
            .of(
                Yup.object().shape({
                    id: Yup.number().required('This field is required'),
                    first_name: Yup.string().required(t('First name required')),
                    last_name: Yup.string().required(t('Last name required')),
                    phone: Yup.string().required(t('Phone number is invalid')),
                    email: Yup.string().email(t('Valid Gmail Address Needed')).required(t('Email required')),
                    phone_country_code: Yup.string().required(),
                }),
            )
            .nullable(),
    });
    const [defaultValues, setDefaultValues] = useState<IBusinessField>({
        business_name: shop.business_name,
        logo_image_name: shop.logo_image_name,
        country: shop.country,
        state: shop.state,
        city: shop.city,
        address: shop.address,
        staff_size: shop.staff_size,
        website: shop.website || '',
        google_review: shop.google_review || '',
        facebook_page: shop.facebook_page || '',
        instagram_page: shop.instagram_page || '',
        contact_details: shop.contacts || null,
    });
    const {
        handleSubmit,
        control,
        formState: { errors },
        setError,
        reset,
        setValue,
    } = useForm<IBusinessField>({
        resolver: yupResolver(schema),
        defaultValues,
    });

    useEffect(() => {
        setDefaultValues({
            business_name: shop.business_name,
            logo_image_name: shop.logo_image_name,
            country: shop.country,
            state: shop.state,
            city: shop.city,
            address: shop.address,
            staff_size: shop.staff_size,
            website: shop.website || '',
            google_review: shop.google_review || '',
            facebook_page: shop.facebook_page || '',
            instagram_page: shop.instagram_page || '',
            contact_details: shop.contacts || null,
        });
    }, [shop]);

    useEffect(() => {
        reset(defaultValues);
    }, [defaultValues]);
    console.log('errors', errors);

    const handleSave = async (data: IBusinessField) => {
        const result: any = await dispatch(company(data));
        if (result.type === company.fulfilled.toString()) {
            dispatch(shopDetail());
        } else if (result.type === company.rejected.toString()) {
            const response = result.payload.data.data;
            if (response.status === errorCode.unprocessable) {
                if (response.data) {
                    Object.keys(response.data).forEach((field) => {
                        setError(field as IFieldKey, {
                            type: 'manual',
                            message: response.data[field][0],
                        });
                    });
                }
                return;
            }
            toast.error(response.message);
        }
        setIsLoading(false);
    };

    const handleChange = async (data: IBusinessField) => {
        setIsLoading(true);
        if (file.convertedFile && file?.filename) {
            const imgUploadResult: any = await s3Upload(file?.convertedFile, `images/business_logos/${file?.filename}`);
            if (imgUploadResult.status === 201) {
                const updatedData = {
                    ...data,
                    logo_image_name: file.filename,
                };
                handleSave(updatedData);
            }
        } else {
            handleSave(data);
        }
    };

    const resetForm = (event: any) => {
        event.preventDefault();
        reset();
    };

    return (
        <div className="w-[full] rounded-2xl bg-white border-[1px] border-lineColor">
            <div className="detail-page-title-block">
                <h2 className="text-xl font-bold leading-[140%] -tracing-[0.2px]">{t('Business details')}</h2>
            </div>

            <form onSubmit={handleSubmit(handleChange)}>
                <div className="detail-page-data-block ">
                    <div className="title-block">
                        <h3 className="title-h3">{t('Business info')}</h3>
                        <p className="text-xs text-secondaryTxtColor leading-[150%] -tracking-[0.12px]">{t('Business_info_description')}</p>
                    </div>
                    <div className="data-wrap-block max-w-[512px] 2xl:max-w-[700px]">
                        <BusinessInfo errors={errors} control={control} setFile={setFile} setValue={setValue} />
                    </div>
                </div>
                <div className="detail-page-data-block">
                    <div className="title-block">
                        <h3 className="title-h3">{t('Contact info')}</h3>
                        <p className="text-xs text-secondaryTxtColor leading-[150%] -tracking-[0.12px] ">{t('Contact_infO_description')}</p>
                    </div>
                    <div className="data-wrap-block max-w-[512px] 2xl:max-w-[700px]">
                        <ContactInfo errors={errors} control={control} setValue={setValue} setError={setError} />
                    </div>
                </div>
                <div className="detail-page-data-block border-0">
                    <div className="title-block">
                        <h3 className="title-h3">{t('Online links')}</h3>
                        <p className="text-xs text-secondaryTxtColor leading-[150%] -tracking-[0.12px] ">{t('Online_links_description')}</p>
                    </div>
                    <div className="data-wrap-block max-w-[512px] 2xl:max-w-[700px]">
                        <OnlineLinks errors={errors} control={control} />
                        <div className="flex -mx-3 mt-5">
                            <div className="px-3 w-1/2">
                                <Button className="btn_outline_black w-full justify-center" onClick={resetForm}>
                                    {t('Cancel')}
                                </Button>
                            </div>
                            <div className="px-3 w-1/2">
                                <Button isLoading={isLoading} disabled={isLoading} className="fl-btn btn_primary w-full">
                                    {t('Save changes')}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default BusinessDetails;
