import React, { useEffect, useRef, useState } from 'react';
import InputColor from 'react-input-color';
import { IColor, IColorPicker } from './ColorPicker.interface';
import { SketchPicker } from 'react-color';
import reactCSS from 'reactcss';

const ColorPicker: React.FC<IColorPicker> = ({ setOneColor, customBranding, error }) => {
    const [color, setColor] = useState<IColor>({ hex: setOneColor || '#5e72e4' });
    const colorPickerRef = useRef<HTMLDivElement>(null);
    const [showPicker, setShowPicker] = useState(false);

    const handleClickOutside = (event: MouseEvent) => {
        if (showPicker && colorPickerRef.current && !colorPickerRef.current.contains(event.target as Node)) {
            setShowPicker(false);
        }
    };
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showPicker]);

    const handleColorChange = (Color: IColor) => {
        setColor({ hex: Color.hex });
        customBranding(Color);
    };

    const onClick = () => {
        setShowPicker(!showPicker);
    };

    const styles = reactCSS({
        default: {
            color: {
                padding: 0,
                height: '24px',
                width: '24px',
                borderRadius: '24px',
                overflow: 'hidden',
                borderColor: '#F0F0F0',
                background: setOneColor || color.hex,
            },
            popover: {
                position: 'absolute',
                zIndex: '3',
            },
            cover: {
                position: 'fixed',
                top: '0px',
                right: '0px',
                bottom: '0px',
                left: '0px',
            },
            swatch: {
                padding: '6px',
                background: '#ffffff',
                borderRadius: '2px',
                cursor: 'pointer',
                display: 'inline-block',
                boxShadow: '0 0 0 1px rgba(0,0,0,.2)',
            },
        },
    });
    return (
        <>
            <div className={`px-4 py-[7px] border border-lineColor rounded-xl flex gap-3 items-center bg-white box-border ${error && 'is-invalid'}`}>
                <>
                    <div className="flex items-center gap-3 custom-color-picker" onClick={onClick}>
                        <div style={styles.color} />
                        {setOneColor || color.hex}
                    </div>
                </>
            </div>
            <div ref={colorPickerRef}>{showPicker && <SketchPicker color={setOneColor || color.hex} presetColors={[]} disableAlpha={true} onChange={handleColorChange} />}</div>
        </>
    );
};

export default ColorPicker;
