import React, { FC, useRef, useState } from 'react';
import 'cropperjs/dist/cropper.css';
import { Cropper, ReactCropperElement } from 'react-cropper';
import { ReactComponent as Newlogo } from 'src/assets/svgs/newlogo.svg';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import Popup from 'src/components/Popup';

export const CircularProgressWithLabel: React.FC<any> = (props) => (
    <div style={{ position: 'relative', display: 'inline-flex' }}>
        <CircularProgressbar
            variant="determinate"
            {...props}
            styles={buildStyles({
                textSize: '16px',
                pathColor: '#1642C5',
                textColor: '#1F293C',
                trailColor: '#E3E7EF',
                backgroundColor: '#f00000',
            })}
        />
        <div
            style={{
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                position: 'absolute',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <p>{`${Math.round(props.value)}%`}</p>
        </div>
    </div>
);
function getRoundedCanvas(sourceCanvas: any) {
    var canvas = document.createElement('canvas');
    var context = canvas.getContext('2d');
    var width = sourceCanvas.width;
    var height = sourceCanvas.height;

    canvas.width = width;
    canvas.height = height;
    if (context) {
        context.imageSmoothingEnabled = true;
        context.drawImage(sourceCanvas, 0, 0, width, height);
        context.globalCompositeOperation = 'destination-in';
        context.beginPath();
        context.arc(width / 2, height / 2, Math.min(width, height) / 2, 0, 2 * Math.PI, true);
        context.fill();
    }
    return canvas;
}
type ICropType = 'Rounded' | 'Square' | 'Rectangular';
const cropTypeArray: ICropType[] = ['Rounded', 'Square', 'Rectangular'];
const ImgCropper: FC<any> = ({ imageUpload, setUpload, image, setCropData, handleClose }) => {
    const previewCanvasRef = useRef<HTMLCanvasElement>(null);
    const [scale, setScale] = useState<any>(0);
    const cropperRef = useRef<ReactCropperElement>(null);
    const [cropType, setCropType] = useState<ICropType>('Rounded');

    const getCropData = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        if (cropperRef.current?.cropper) {
            const croppedCanvas = cropperRef.current?.cropper.getCroppedCanvas({
                rounded: true,
                imageSmoothingEnabled: true,
            });
            const imageData = cropType === 'Rounded' ? getRoundedCanvas(croppedCanvas).toDataURL() : croppedCanvas.toDataURL();
            if (croppedCanvas) {
                setCropData(imageData);
                setUpload(false);
                imageUpload && imageUpload(imageData as any);
            } else {
                // console.error('Failed to get cropped canvas.');
            }
        } else {
            // console.error('Cropper instance not available.');
        }
    };

    const handleCropType = (type: ICropType) => {
        setCropType(type);
        cropperRef.current?.cropper.reset();
        const aspectRatio: number = type === 'Rectangular' ? 16 / 9 : 1 / 1;
        cropperRef.current?.cropper.setAspectRatio(aspectRatio);
        let imageData = cropperRef.current?.cropper.getImageData();
        let imageData2 = cropperRef.current?.cropper.getCropBoxData();

        if (imageData && imageData2) {
            const mintestZoom = 1 - imageData2?.height / imageData.height;

            setScale(mintestZoom);
        }
    };

    const content = (
        <div className="rounded-3xl py-10 px-10">
            <div className="flex justify-between items-start mb-8">
                <div className="flex gap-4 items-center">
                    <Newlogo />
                    <div className="flex flex-col">
                        <h2 className="-tracking-[0.18px] font-bold  text-[18px] leading-[140%] mb-[6px] text-mainTextColor">Add your logo</h2>
                        <p className="text-sm font-medium -tracking-[0.14px] leading-[140%] text-secondaryTxtColor">Upload a 512 x 512 logo for best results.</p>
                    </div>
                </div>
            </div>
            <div className="fl-tab mb-6">
                {cropTypeArray.map((type: ICropType) => (
                    <button key={type} type="button" value={type} className={`fl-tab-link ${cropType === type && 'active'}`} onClick={() => handleCropType(type)}>
                        {type}
                    </button>
                ))}
            </div>
            <div className="flex bg-contentBackground justify-center items-center flex-col ">
                {!!image && (
                    <>
                        <Cropper
                            aspectRatio={1 / 1}
                            preview=".img-preview"
                            src={image}
                            center={true}
                            ref={cropperRef}
                            viewMode={1}
                            guides={false}
                            minCropBoxHeight={10}
                            minCropBoxWidth={10}
                            background={false}
                            responsive={true}
                            checkOrientation={true}
                            cropBoxMovable={false}
                            cropBoxResizable={false}
                            dragMode="move"
                            disabled={true}
                            highlight={true}
                            className={`${cropType === 'Rounded' ? 'imageCropper' : ''}`}
                        />
                    </>
                )}
                <canvas
                    ref={previewCanvasRef}
                    style={{
                        border: '1px solid black',
                        objectFit: 'contain',
                    }}
                    className="hidden"
                />

                <div className="Crop-Controls w-full mb-8">
                    <div className="flex justify-between mb-4">
                        <h1 className=" text-foreground text-[18px] font-bold leading-[140%] -tracking-[0.18px] ">Zoom</h1>
                        <h1 className=" text-[#1642C5] text-[18px] font-bold leading-[140%] -tracking-[0.18px] ">{Math.floor(scale * 100)}%</h1>
                    </div>
                    <div className="flex justify-between items-center  custom-range-slider">
                        <input
                            type="range"
                            id="default-range"
                            value={scale}
                            min={0}
                            step={0.0001}
                            max={1.0}
                            className="w-full "
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                const value = event.target.value;
                                cropperRef.current?.cropper.zoomTo(Number(value));
                                setScale(Number(value));
                            }}
                        />
                    </div>
                </div>
                <button className="fl-btn btn_primary w-full " onClick={getCropData}>
                    Crop and Save
                </button>
            </div>
        </div>
    );

    return <Popup size="max-w-[600px]" className="p-8 pb-10" handleClose={handleClose} content={content} />;
};
export default ImgCropper;
