import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { selectShopInfo, selectShopSetting, shopDetail } from 'src/app/Auth/Login/Login.slice';
import Button from 'src/components/Button';
import InputWithLabel from 'src/components/InputWithLabel';
import InputWithToggle from 'src/components/InputWithToggle';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import * as Yup from 'yup';
import { IReferral } from './Loyalty.interface';
import { yupResolver } from '@hookform/resolvers/yup';
import { axiosPost } from 'src/utils/requestClient';
import { API } from 'src/constants/api';
import { errorCode } from 'src/constants/errorCode';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

const Referral = ({ handleClose }: any) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const shop: any = useAppSelector(selectShopInfo);
    const shopSetting: any = useAppSelector(selectShopSetting);
    const shopId = shop.id;
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [toggle, setToggle] = useState({
        refferer_type: shopSetting && shopSetting.referral_config && shopSetting.referral_config.value.refferer_type === 'value' ? '$' : '%',
        referee_type: shopSetting && shopSetting.referral_config && shopSetting.referral_config.value.referee_type === 'value' ? '$' : '%',
    });
    const handleInputChange = (fieldName: string, value: string) => {
        setToggle((old) => ({ ...old, ...{ [fieldName]: value } }));
    };
    const schema = Yup.object().shape({
        refferer: Yup.number()
            .typeError(t('This field not allowed null value'))
            .moreThan(0, t('Amount must be greater than zero'))
            .lessThan(100, t('Amount must be less than 100'))
            .required(t('This field is required')),
        referee: Yup.number()
            .typeError(t('This field not allowed null value'))
            .moreThan(0, t('Amount must be greater than zero'))
            .lessThan(100, t('Amount must be less than 100'))
            .required(t('This field is required')),
        description: Yup.string().required('This field is required'),
    });

    const defaultValues: IReferral = {
        refferer: shopSetting && shopSetting.referral_config ? shopSetting.referral_config.value.refferer : 0, // You can set default values here
        referee: shopSetting && shopSetting.referral_config ? shopSetting.referral_config.value.referee : 0,
        description: shopSetting && shopSetting.referral_config ? shopSetting.referral_config.description : '',
    };

    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm<IReferral>({
        resolver: yupResolver(schema),
        defaultValues,
    });
    const handleChange = async (data: any) => {
        setIsLoading(true);
        let payload = {
            ...data,
            refferer_type: toggle.refferer_type === '%' ? 'percentage' : 'value',
            referee_type: toggle.referee_type === '%' ? 'percentage' : 'value',
        };

        try {
            const response = await axiosPost(API.REFER.CONFIG_CREATE_OR_UPDATE, payload, {
                shop_id: shopId,
            });
            if (response.data?.status === errorCode.success || response.data?.status === errorCode.updateSuccess) {
                await dispatch(shopDetail());
                handleClose();
                return;
            }

            throw response.data;
        } catch (err: any) {
            toast.error(err?.message);
        } finally {
            setIsLoading(false);
        }
    };
    return (
        <form onSubmit={handleSubmit(handleChange)}>
            <h2 className="font-bold text-[32px] leading-[124%] -tracking-[0.384px] text-center pb-8">Configure referral program</h2>
            <div className="flex -mx-[10px] mb-5">
                <div className="w-1/2 px-[10px]">
                    <Controller
                        name="refferer"
                        control={control}
                        render={({ field: { onChange, value } }: any) => (
                            <div className="w-full">
                                <InputWithToggle
                                    label={t('Refferer_discount_label')}
                                    type="number"
                                    name="refferer"
                                    placeholder={t('Enter spend')}
                                    toggleClassName="bg-white text-mainTextColor drop-shadow-cardShadow"
                                    value={value}
                                    toggleValue={toggle.refferer_type}
                                    setselected={(e: any) => {
                                        handleInputChange('refferer_type', e);
                                    }}
                                    onChange={onChange}
                                    error={!!errors.refferer}
                                />
                                {errors.refferer && <p className="text-error">{errors.refferer.message}</p>}
                            </div>
                        )}
                    />
                </div>
                <div className="w-1/2 px-[10px]">
                    <Controller
                        name="referee"
                        control={control}
                        render={({ field: { onChange, value } }: any) => (
                            <div className="w-full">
                                <InputWithToggle
                                    label={t('Referees_appointment_label')}
                                    type="number"
                                    name="referee"
                                    placeholder={t('Enter points')}
                                    toggleClassName="bg-white text-mainTextColor drop-shadow-cardShadow"
                                    value={value}
                                    toggleValue={toggle.referee_type}
                                    setselected={(e: any) => {
                                        handleInputChange('referee_type', e);
                                    }}
                                    onChange={onChange}
                                    error={!!errors.referee}
                                />
                                {errors.referee && <p className="text-error">{errors.referee.message}</p>}
                            </div>
                        )}
                    />
                </div>
            </div>
            <div className="w-full">
                <Controller
                    name="description"
                    control={control}
                    render={({ field: { onChange, value } }: any) => (
                        <div className="w-full">
                            <InputWithLabel
                                name="description"
                                type="number"
                                value={value}
                                onChange={onChange}
                                error={!!errors.description}
                                textArea
                                rows={5}
                                label={t('Referral description')}
                                placeholder={t('Enter description')}
                                textAearClassName="rounded-3xl"
                            />
                            {errors.description && <p className="text-error">{errors.description.message}</p>}
                        </div>
                    )}
                />
            </div>

            <div className="mt-10 text-center">
                <Button className={`fl-btn btn_primary max-w-[420px]`} isLoading={isLoading} disabled={isLoading}>
                    {t('Save')}
                </Button>
            </div>
        </form>
    );
};

export default Referral;
