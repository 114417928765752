import React from 'react';
import EmptyMsgWithBtn from 'src/components/EmptyMsgWithBtn';
import KpiCard from 'src/components/KpiCard';
import { Images } from 'src/theme';
import AmChart from '../AmChart';
import StaffPerfomance from 'src/app/Dashboard/Tables/StaffPerfomance';
import NetSalesChartPie from '../NetSalesChartPie';
import PaymentChartPie from '../PaymentChartPie';
import Discount from '../Tables/Discount';
import NetSalesIncome from '../Tables/NetSalesIncome';
import NetIncome from '../Tables/NetIncome';
import BarLoader from 'src/components/BarLoader';
import { checkFieldIsZero, formatKpiContentLable } from 'src/utils/global-functions';

const Overview = ({ handleViewClick, AnalyticsDetails, timeFileterSelected }: any) => (
    <>
        {AnalyticsDetails ? (
            <>
                <div className="mb-3 flex -mx-[6px]">
                    <div className="px-[6px] w-[20%]">
                        <div className="bg-white rounded-3xl border border-lineColor p-4">
                            <KpiCard
                                headerIcon={Images.IconMoney}
                                headerTitle="Total Sales"
                                contentLable={`$${formatKpiContentLable(AnalyticsDetails?.total?.amount)}`}
                                growth={AnalyticsDetails?.total?.amount_growth}
                                fromDate={AnalyticsDetails?.from_date}
                            />
                        </div>
                    </div>
                    <div className="px-[6px] w-[20%]">
                        <div className="bg-white rounded-3xl border border-lineColor p-4">
                            <KpiCard
                                headerIcon={Images.IconNewClient}
                                headerTitle="New Clients"
                                contentLable={`$${formatKpiContentLable(AnalyticsDetails?.total?.client)}`}
                                growth={AnalyticsDetails?.total?.client_growth}
                                fromDate={AnalyticsDetails?.from_date}
                            />
                        </div>
                    </div>
                    <div className="px-[6px] w-[20%]">
                        <div className="bg-white rounded-3xl border border-lineColor p-4">
                            <KpiCard
                                headerIcon={Images.IconClientRotation}
                                headerTitle="Client Retenetion"
                                contentLable={`${AnalyticsDetails?.total?.client_retention_rate}%`}
                                contentDescription="new"
                                growth={AnalyticsDetails?.total?.client_retention_rate_growth}
                                secondaryContentLabel={`${AnalyticsDetails?.total?.client_repeat_rate}%`}
                                secondaryContentDescription="repeat"
                                fromDate={AnalyticsDetails?.from_date}
                            />
                        </div>
                    </div>
                    <div className="px-[6px] w-[20%]">
                        <div className="bg-white rounded-3xl border border-lineColor p-4">
                            <KpiCard
                                headerIcon={Images.IconCalendar}
                                headerTitle="Av. Booking Frequency"
                                contentLable={`${AnalyticsDetails?.total?.avg_bookings_frequency}%`}
                                growth={`${AnalyticsDetails?.total?.avg_bookings_frequency_growth} `}
                                fromDate={`${AnalyticsDetails?.from_date}`}
                            />
                        </div>
                    </div>
                    <div className="px-[6px] w-[20%]">
                        <div className="bg-white rounded-3xl border border-lineColor p-4">
                            <KpiCard
                                headerIcon={Images.IconProductivity}
                                headerTitle="Productivity"
                                contentLable={`${AnalyticsDetails?.total?.productivity}%`}
                                growth={AnalyticsDetails?.total?.productivity_growth}
                                fromDate={AnalyticsDetails?.from_date}
                            />
                        </div>
                    </div>
                </div>

                <div className="flex mb-3">
                    <div className="pe-[6px] w-3/5">
                        <div className="bg-white rounded-3xl border border-lineColor p-4 h-full">
                            <h3 className="text-xl font-bold -tracking-[0.4px]">Sales Analytics</h3>

                            {AnalyticsDetails.sales_line_graph.length > 0 ? (
                                <AmChart salesLineGraph={AnalyticsDetails.sales_line_graph} />
                            ) : (
                                <EmptyMsgWithBtn
                                    title="No Sales Data"
                                    description="Sales Analytics will be available once clients start
                 booking appointments."
                                    btnLabel="Create booking"
                                    onClick={() => {}}
                                />
                            )}
                        </div>
                    </div>

                    <div className="ps-[6px] w-2/5">
                        <StaffPerfomance staffData={AnalyticsDetails.staff} handleViewClick={handleViewClick} />
                    </div>
                </div>

                <div className="flex mb-3">
                    <div className="pe-[6px] w-1/2">
                        <div className="bg-white rounded-3xl border border-lineColor p-4">
                            <h3 className="text-xl font-bold -tracking-[0.4px]">Net Sales by Service</h3>
                            {checkFieldIsZero(AnalyticsDetails.sales_by_services, 'total_booking_service_amount') ? (
                                <EmptyMsgWithBtn
                                    title="No Net Sales by Service"
                                    description="Net Sales by Service will be available once clients start booking appointments.                                    "
                                    btnLabel="Create booking"
                                    onClick={() => {}}
                                />
                            ) : (
                                <NetSalesChartPie netSalesIncome={AnalyticsDetails.sales_by_services} timeFileterSelected={timeFileterSelected} />
                            )}
                        </div>
                    </div>

                    <div className="ps-[6px] w-1/2">
                        <div className="bg-white rounded-3xl border border-lineColor p-4">
                            <h3 className="text-xl font-bold -tracking-[0.4px]">Payment method split</h3>
                            {checkFieldIsZero(AnalyticsDetails.payment_method_split, 'total') ? (
                                <EmptyMsgWithBtn title="You have no sales" description="Create an appointment to track sales" btnLabel="Create booking" onClick={() => {}} />
                            ) : (
                                <PaymentChartPie paymentMethod={AnalyticsDetails.payment_method_split} timeFileterSelected={timeFileterSelected} />
                            )}
                        </div>
                    </div>
                </div>

                <div className="flex mb-3">
                    <Discount discountInfo={AnalyticsDetails.gross_revenue} />
                </div>

                <div className="flex mb-3">
                    <div className="pe-[6px] w-1/2">
                        <NetSalesIncome netSalesIncomeDetails={AnalyticsDetails.gross_revenue} />
                    </div>

                    <div className="ps-[6px] w-1/2">
                        <NetIncome netIncomeDetails={AnalyticsDetails.gross_revenue} />
                    </div>
                </div>

                {/* <div className="bg-white rounded-3xl border border-lineColor py-4">
                    <AdvancedAnalytics />
                </div> */}
            </>
        ) : (
            <BarLoader />
        )}
    </>
);
export default Overview;
