import { Dispatch, SetStateAction } from 'react';
import { ICountStep } from 'src/components/Stepper/Stepper.interface';

export interface IPops {
    setStep: Dispatch<SetStateAction<ICountStep>>;
}
export interface IMapAddress {
    fullAddress: string;
    address1: string;
    city: string;
    state: string;
    country: string;
    postal: string;
    latitude: number;
    longitude: number;
    stateCode: string;
    countryCode: string;
}
export const initConsigneeAddress: IMapAddress = {
    fullAddress: '',
    address1: '',
    city: '',
    state: '',
    country: '',
    postal: '',
    latitude: 0,
    longitude: 0,
    stateCode: '',
    countryCode: '',
};

export interface IFormData {
    locationAddress: IMapAddress;
}
export interface IFormData2 {
    name: string;
    country: string;
    city: string;
    street: string;
    postal_code: string;
    latitude: number;
    longitude: number;
    location_image_name: string;
    gst?: string;
    pst?: string;
    hst?: string;
    territory?: {
        label: string;
        value: string;
    };
}

export interface ILocation {
    latitude: number | null;
    longitude: number | null;
    error: string | null;
    permissionDenied: boolean;
}
export const initFormData: IFormData = {
    locationAddress: initConsigneeAddress,
};
export interface IInitialState {
    data: any;
    loading: boolean;
    error:
        | {
              message: string | undefined;
              status?: string | undefined;
          }
        | undefined
        | null;
}
