import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

const FIREBASE_API_KEY = process.env.REACT_APP_FIREBASE_API_KEY;
const FIREBASE_AUTH_DOMAIN = process.env.REACT_APP_FIREBASE_AUTH_DOMAIN;
const FIREBASE_PROJECT_ID = process.env.REACT_APP_FIREBASE_PROJECT_ID;
const FIREBASE_STORAGE_BUCKET = process.env.REACT_APP_FIREBASE_STORAGE_BUCKET;
const FIREBASE_MESSAGING_SENDER_ID = process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID;
const FIREBASE_APP_ID = process.env.REACT_APP_FIREBASE_APP_ID;
const FIREBASE_MEASUREMENT_ID = process.env.REACT_APP_FIREBASE_MEASUREMENT_ID;
const FIREBASE_WEB_PUSH_CERTIFICATE_VAPID = process.env.REACT_APP_FIREBASE_WEB_PUSH_CERTIFICATE_VAPID;

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: FIREBASE_API_KEY,
    authDomain: FIREBASE_AUTH_DOMAIN,
    projectId: FIREBASE_PROJECT_ID,
    storageBucket: FIREBASE_STORAGE_BUCKET,
    messagingSenderId: FIREBASE_MESSAGING_SENDER_ID,
    appId: FIREBASE_APP_ID,
    measurementId: FIREBASE_MEASUREMENT_ID,
};

let app: any = undefined;
let messaging: any = undefined;

if (!!navigator?.serviceWorker) {
    // Initialize Firebase
    app = initializeApp(firebaseConfig);

    messaging = getMessaging(app);
}

export const requestForFirebaseToken = async () => {
    const swRegistration = await navigator?.serviceWorker?.register('/firebase-notification-sw.js');

    return getToken(messaging, {
        vapidKey: FIREBASE_WEB_PUSH_CERTIFICATE_VAPID,
        serviceWorkerRegistration: swRegistration,
    })
        .then((currentToken) => {
            if (currentToken) {
                return currentToken;
            } else {
                // Show permission request UI
                console.log('No registration token available. Request permission to generate one.');
            }
        })
        .catch((err: any) => {
            console.log('An error occurred while retrieving token. ', err);
            return;
        });
};

export const onMessageListener = () =>
    new Promise((resolve) => {
        onMessage(messaging, (payload) => {
            resolve(payload);
        });
    });
