import React, { Dispatch, SetStateAction } from 'react';
import { Checkbox, Label, Radio } from 'flowbite-react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';

const TipsDetailSelection = ({
    selectionContent,
    className,
    tipType,
    setTipType,
}: {
    selectionContent: string;
    className?: string;
    tipType?: string;
    setTipType?: Dispatch<SetStateAction<string>>;
}) => {
    const { control, formState, setValue, getValues } = useFormContext();
    const { fields } = useFieldArray({
        control,
        keyName: 'uuid',
        name: 'tip',
    });
    const error: any = formState.errors;

    const handleRadio = (data: string) => (e: any) => {
        setTipType?.(data === 'percentage' ? 'percentage' : 'value');

        const customTip = data === 'percentage' ? `${getValues().custom_tip.slice(1)}%` : `$${getValues().custom_tip.slice(0, -1)}`;
        setValue<'custom_tip'>('custom_tip', customTip);
    };

    const handleInputChange = (value: string) => {
        const regex = /^\d*\.?\d*$/;
        if (regex.test(value)) {
            return value;
        }
        return '';
    };

    return (
        <div className={`${className} w-full flex flex-1  flex-col gap-5 p-8 border border-lineLightColor rounded-[20px]`}>
            <div className="flex justify-between items-center">
                <p className="text-base font-bold leading-[140%] -tracing-[0.4px]">{selectionContent}</p>
                <div className="custom-square-radio-block ">
                    <Radio className="hidden custom-radio radio-am" id="tip_percentage" name="tip_type" value="%" checked={tipType === 'percentage'} onChange={handleRadio('percentage')} />
                    <Label className="custome-label" htmlFor="tip_percentage">
                        %
                    </Label>
                    <Radio className="hidden custom-radio radio-pm" id="tip_dollar" name="tip_type" value="$" checked={tipType === 'value'} onChange={handleRadio('value')} />
                    <Label className="custome-label" htmlFor="tip_dollar">
                        $
                    </Label>
                    <span className="switch-base"></span>
                </div>
            </div>
            <div className="flex flex-wrap -mx-[10px]">
                {fields.map((item: any, index) => (
                    <div className="w-1/2 px-[10px] mb-5" key={item.uuid}>
                        <div className="custom-tip-checkbox-block ">
                            <Controller
                                key={`tip.[${index}]`}
                                name={`tip.[${index}]`}
                                control={control}
                                render={({ field: { onChange, value } }: any) => (
                                    <Checkbox
                                        className="custom-tip-checkbox"
                                        name={`tip.[${index}]`}
                                        id={`tip.[${index}]`}
                                        checked={value.checked}
                                        onChange={(e: any) => {
                                            onChange({
                                                checked: e.target.checked,
                                                value: value.value,
                                            });
                                        }}
                                    />
                                )}
                            />

                            <Label className="custom-tip-label" htmlFor="tip_10">
                                {tipType !== 'percentage' && '$'}
                                {item.value}
                                {tipType === 'percentage' && '%'}
                            </Label>
                        </div>
                    </div>
                ))}
                <div className="custom-tip-checkbox-block w-1/2 px-[10px]">
                    <Controller
                        name="custom_tip"
                        control={control}
                        render={({ field: { onChange, value } }: any) => (
                            <input
                                name="custom_tip"
                                type="text"
                                className="w-full custom-tip-label"
                                placeholder={`${tipType === 'percentage' ? '%' : '$'} Enter tip value`}
                                value={value}
                                onChange={(e) => onChange(handleInputChange(e.target.value))}
                                onFocus={(e) => {
                                    const sign = tipType === 'percentage' ? '%' : '$';
                                    const values = e.target.value;
                                    if (values.includes(sign)) {
                                        onChange(tipType === 'percentage' ? values.slice(0, -1) : values.slice(1));
                                    }
                                }}
                                onBlur={(e) => {
                                    const sign = tipType === 'percentage' ? '%' : '$';
                                    const values = e.target.value;
                                    if (values && !values.includes(sign)) {
                                        onChange(tipType === 'percentage' ? values + sign : sign + values);
                                    }
                                }}
                            />
                        )}
                    />
                </div>
                {error?.tip && <p className="text-error">{error?.tip?.root?.message}</p>}
            </div>
        </div>
    );
};

export default TipsDetailSelection;
