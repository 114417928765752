import React, { useEffect, useMemo, useState } from 'react';
import Drawer from 'src/components/Drawer';
import { IProps, IMenu } from './StaffDetails.interface';
import Menu from './StaffDetails.Menu';
import Info from './StaffDetails.Info';
import Appointment from './StaffDetails.Appointment';
import WorkingHours from './StaffDetails.WorkingHours';
import Services from './StaffDetails.Services';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { selectUserInfo } from 'src/app/Auth/Login/Login.slice';
import { bookingDetails, getStaffDetails } from '../Staff.slice';
import Compensation from './StaffDetail.Compensation';
import { useTranslation } from 'react-i18next';
import BarLoader from 'src/components/BarLoader';
const StaffDetails = ({ isDrawerOpen, handleDrawer, staffId, getStaff, setIsOpenRemovePopup }: IProps) => {
    const { t } = useTranslation();
    const user = useAppSelector(selectUserInfo);
    const shopId = user.shops[0].id;
    const dispatch = useAppDispatch();
    const [staffInfo, setStaffInfo] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [activeStep, setActiveStep] = useState(1);
    const [isEditProfile, setIsEditProfile] = useState(false);
    const [bookingData, setBookingData] = useState<any>();

    const getBookingDetails = async () => {
        setIsLoading(true);
        const result = await dispatch(bookingDetails({ shop_id: shopId, id: staffId }));
        if (result.type === bookingDetails.fulfilled.toString()) {
            setBookingData(result.payload.data);
        }
        setIsLoading(false);
    };

    const getStaffDetailFun = async () => {
        setIsLoading(true);
        const data = {
            shop_id: shopId,
            id: staffId,
        };
        const result = await dispatch(getStaffDetails(data));
        if (result.type === getStaffDetails.fulfilled.toString()) {
            setStaffInfo(result.payload.data);
        }
        setIsLoading(false);
    };
    useEffect(() => {
        if (isDrawerOpen) {
            setIsLoading(true);
            setActiveStep(1);
            staffId && getStaffDetailFun();
            staffId && getBookingDetails();
        } else {
            setTimeout(() => {
                setStaffInfo({});
            }, 500);
        }
    }, [staffId]);

    const MENU: IMenu[] = useMemo(
        () => [
            {
                name: t('Staff Details'),
                step: 1,
            },
            {
                name: t('Appointments'),
                step: 2,
            },
            {
                name: t('Working Hours'),
                step: 3,
            },
            {
                name: t('Services'),
                step: 4,
            },
            {
                name: t('Compensation'),
                step: 5,
            },
        ],
        [],
    );

    const renderStepComponent = () => {
        if (isLoading) {
            return <BarLoader isLoading={isLoading} />;
        }
        if (activeStep === 1 && Object.keys(staffInfo).length > 0 && bookingData) {
            return (
                <Info
                    bookingData={bookingData}
                    loading={isLoading}
                    staffInfo={staffInfo}
                    shopId={shopId}
                    setIsEditProfile={setIsEditProfile}
                    getStaffDetailFun={getStaffDetailFun}
                    getStaff={getStaff}
                    isEditProfile={isEditProfile}
                />
            );
        } else if (activeStep === 2) {
            return <Appointment staffInfo={staffInfo} />;
        } else if (activeStep === 3) {
            return <WorkingHours staffInfo={staffInfo} />;
        } else if (activeStep === 4) {
            return <Services staffInfo={staffInfo} />;
        } else if (activeStep === 5) {
            return <Compensation staffInfo={staffInfo} />;
        } else {
            return null;
        }
    };
    return (
        <Drawer
            isOpen={isDrawerOpen && Object.keys(staffInfo).length > 0}
            handleClose={() => {
                handleDrawer(false);
                setIsEditProfile(false);
            }}
            title={t('Staff Details')}
            isEditable
            isEditClose
            handleClickOutside={() => {
                handleDrawer(false);
                setIsEditProfile(false);
            }}
            classDrawer="your-custom-styles"
            drawerHeight="your-height-value"
        >
            {!isLoading && (
                <>
                    <Menu menuList={MENU} setActiveStep={setActiveStep} staffInfo={staffInfo} activeStep={activeStep} setIsEditProfile={setIsEditProfile} setIsOpenRemovePopup={setIsOpenRemovePopup} />
                </>
            )}
            {renderStepComponent()}
        </Drawer>
    );
};

export default StaffDetails;
