import React, { FC, useEffect } from 'react';
import RentFrequency from './RentFrequency';
import { useForm } from 'react-hook-form';
import { IAddCommissionFormData, IFrequency } from './MyAccount.interface';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { addPaymentAccount } from './MyAccount.slice';
import { selectShopInfo, shopDetail } from 'src/app/Auth/Login/Login.slice';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { errorCode } from 'src/constants/errorCode';
import { toast } from 'react-toastify';
import CommissionFrequency from './CommissionFrequency';
import Button from 'src/components/Button';

const Frequency: FC<IFrequency> = ({ paymentType, setPaymentType, handleClose }) => {
    const dispatch = useAppDispatch();
    type FieldKey = 'frequency' | 'commission_payment_day';
    const shop: any = useAppSelector(selectShopInfo);
    const shopId = shop.id;

    let validationSchema;
    if (paymentType === 'commission') {
        validationSchema = Yup.object({
            frequency: Yup.string().required('Please select frequency'),
            commission_payment_day: Yup.string().when('frequency', {
                is: (val: string) => val === 'weekly' || val === 'bi-Weekly' || val === 'tri-Weekly',
                then: (schema) => schema.required('commission_payment_day is required when commission frequency is weekly or bi-Weekly'),
                otherwise: (schema) => schema.notRequired(),
            }),
        }).required();
    } else if (paymentType === 'rent') {
        validationSchema = Yup.object({
            frequency: Yup.string().required('Please select frequency'),
            rent_day: Yup.string().when('frequency', {
                is: (val: string) => val === 'weekly' || val === 'bi-Weekly' || val === 'tri-Weekly',
                then: (schema) => schema.required('rent_day is required when rent frequency is weekly or bi-Weekly'),
                otherwise: (schema) => schema.notRequired(),
            }),
        }).required();
    } else {
        validationSchema = Yup.object({
            frequency: Yup.string().required('Please select frequency'),
        }).required();
    }

    const {
        handleSubmit,
        control,
        formState: { errors },
        watch,
        setValue,
        setError,
    } = useForm<IAddCommissionFormData>({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            frequency: '',
            commission_payment_day: '',
            rent_day: '',
        },
    });
    useEffect(() => {
        if (shop.shop_payment !== null) {
            if (paymentType === 'commission') {
                setValue('frequency', shop?.shop_payment?.commission_frequency);
                setValue('commission_payment_day', shop?.shop_payment?.commission_day);
            } else {
                setValue('frequency', shop?.shop_payment?.rent_frequency);
                setValue('rent_day', shop?.shop_payment?.rent_day);
            }
        }
    }, [shop]);
    const frequencyVal = watch('frequency');
    const onSubmit = async (data: any) => {
        let updatedData;
        if (paymentType === 'commission') {
            updatedData = {
                commission_frequency: data.frequency,
                commission_day: data.commission_payment_day,
                pay_structure_type: paymentType,
            };
        } else {
            updatedData = {
                rent_frequency: data.frequency,
                rent_day: data.rent_day,
                pay_structure_type: paymentType,
            };
        }
        const submitData = {
            payload: updatedData,
            shop_id: shopId,
        };
        const result = await dispatch(addPaymentAccount(submitData));
        if (result.type === addPaymentAccount.fulfilled.toString()) {
            await dispatch(shopDetail());
            if (paymentType === 'commission') {
                handleClose();
            } else {
                handleClose();
            }
            setPaymentType('');
        }
        if (result.type === addPaymentAccount.rejected.toString()) {
            const response = result.payload.data;
            if (response.status === errorCode.unprocessable) {
                if (response.data) {
                    Object.keys(response.data).forEach((field) => {
                        setError(field as FieldKey, {
                            type: 'manual',
                            message: response.data[field][0],
                        });
                    });
                }
                return;
            }
            toast.error(response.message);
        }
    };

    return (
        <>
            {paymentType === 'commission' ? (
                <>
                    <h2 className="text-xl text-center font-bold mb-2 leading-[140%] -tracking-[0.4px]">Commission payout frequency</h2>
                    <p className="text-center text-xs text-secondaryTxtColor leading-[150%] -tracking-[0.3px] mb-8">
                        Set the commission payout frequency that all staff members on the commission payment structure will follow by default. This enables a consistent payout window.
                    </p>
                </>
            ) : (
                <>
                    <h2 className="text-xl text-center font-bold mb-2 leading-[140%] -tracking-[0.4px]">Rent collection frequency</h2>
                    <p className="text-center text-xs text-secondaryTxtColor leading-[150%] -tracking-[0.3px] mb-8">
                        Set the rent collection frequency that all staff members on the rent payment structure will follow by default. This enables a consistent collection window.
                    </p>
                </>
            )}

            <form onSubmit={handleSubmit(onSubmit)}>
                {paymentType === 'commission' ? (
                    <CommissionFrequency control={control} errors={errors} frequencyVal={frequencyVal} />
                ) : (
                    <RentFrequency control={control} errors={errors} frequencyVal={frequencyVal} />
                )}

                <div className="flex w-full justify-center mt-8">
                    <Button className="fl-btn btn_primary w-full rounded-full">Save</Button>
                </div>
            </form>
        </>
    );
};

export default Frequency;
