import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { API } from 'src/constants/api';
import { axiosPost } from 'src/utils/requestClient';
import { RootState } from 'src/redux/store';
import { errorCode } from 'src/constants/errorCode';
import { ErrorType, IInitialState } from './BookingPayment.interface';

const initialState: IInitialState = {
    data: null,
    loading: false,
    error: null,
};

export const bookingPayment = createAsyncThunk('/shop/booking_payment', async (payload: any, { rejectWithValue }) => {
    try {
        const response = await axiosPost(API.USER.BOOKING_PAYMENT, payload);
        if (response.data.status === errorCode.success || response.data.status === errorCode.updateSuccess) {
            return response.data;
        }

        return rejectWithValue(response as ErrorType);
    } catch (err: any) {
        if (!err.response) {
            throw err;
        }
        return rejectWithValue(err.response.data as ErrorType);
    }
});

export const BookingPaymentSlice = createSlice({
    name: 'shop/booking_payment',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(bookingPayment.pending, (state) => {
                state.loading = true;
            })
            .addCase(bookingPayment.fulfilled, (state, { payload }: any) => {
                state.loading = false;
                state.data = payload.data;
            })
            .addCase(bookingPayment.rejected, (state, action: any) => {
                state.loading = false;
            });
    },
});
export const { reducer: accountReducer } = BookingPaymentSlice;

export const selectBookingPaymentData = (state: RootState) => state.BookingPaymentDetails.data;
export const selectLoading = (state: RootState) => state.BookingPaymentDetails.loading;
export const selectError = (state: RootState) => state.BookingPaymentDetails.error;
