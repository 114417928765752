import React, { FC, useEffect } from 'react';
import { ICalulationDetails } from './Sidebar.Interface';
const CalulationDetails: FC<ICalulationDetails> = ({ appointmentCalulation }) => {
    useEffect(() => {});
    return (
        <div className="border-t border-borderGray bg-baseBg p-4">
            <div className="flex items-center mb-4">
                <span className="text-xs text-secondaryTxtColor font-medium leading-[140%] -tracking-[0.12px] pr-3">Services</span>
                <span className="border-b border-dashed border-lineColor flex-1"></span>
                <span className="text-sm leading-[140%] -tracking-[0.14px] pl-3">${appointmentCalulation.subtotal ? appointmentCalulation.subtotal.toFixed(2) : ''}</span>
            </div>
            {appointmentCalulation.tips !== 0 && (
                <div className="flex items-center  mb-5">
                    <span className="text-xs text-secondaryTxtColor font-medium leading-[140%] -tracking-[0.12px] pr-3">Tips</span>
                    <span className="border-b border-dashed border-lineColor flex-1"></span>
                    <span className="text-sm leading-[140%] -tracking-[0.14px] pl-3">${appointmentCalulation.tips ? appointmentCalulation.tips.toFixed(2) : ''}</span>
                </div>
            )}
            <div className="flex items-center  mb-5">
                <span className="text-xs text-secondaryTxtColor font-medium leading-[140%] -tracking-[0.12px] pr-3">Taxes and fees</span>
                <span className="border-b border-dashed border-lineColor flex-1"></span>
                <span className="text-sm leading-[140%] -tracking-[0.14px] pl-3">${appointmentCalulation.taxes_and_fees ? appointmentCalulation.taxes_and_fees.toFixed(2) : ''}</span>
            </div>

            <div className="flex items-center text-xl font-bold ">
                <span className="leading-[140%] -tracking-[0.2px] pr-3">Total</span>
                <span className="leading-[140%] -tracking-[0.2px] ml-auto">${appointmentCalulation.total ? appointmentCalulation.total?.toFixed(2) : ''}</span>
            </div>
        </div>
    );
};

export default CalulationDetails;
