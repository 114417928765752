import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { API } from 'src/constants/api';
import { errorCode } from 'src/constants/errorCode';
import { axiosPost } from 'src/utils/requestClient';

interface ErrorType {
    message: string;
    status?: string;
    data?: any;
}

const initialState = {
    data: '',
    loading: false,
    error: null,
};

export const addPaymentAccount = createAsyncThunk('/shop/category/add', async (payload: any, { rejectWithValue }) => {
    try {
        const response = await axiosPost(API.PAYMENT.UPDATE, payload.payload, { shop_id: payload.shop_id });

        if (response.data.status === errorCode.success || response.data.status === errorCode.updateSuccess) {
            return response.data;
        }

        return rejectWithValue(response as ErrorType);
    } catch (err: any) {
        if (!err.response) {
            throw err;
        }
        return rejectWithValue(err.response.data as ErrorType);
    }
});

export const addPaymentAccountSlice = createSlice({
    name: 'shop/category/detail',
    initialState,
    reducers: {
        addService: (state, { payload }) => {
            state.data = payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(addPaymentAccount.pending, (state) => {
                state.loading = true;
            })
            .addCase(addPaymentAccount.fulfilled, (state, { payload }: any) => {
                state.loading = false;
                state.data = payload.data;
            })
            .addCase(addPaymentAccount.rejected, (state, { payload }: any) => {
                state.loading = false;
                state.data = payload.data;
            });
    },
});

export const addBookingPayReq = createAsyncThunk('/shop/booking-payment/add', async (payload: any, { rejectWithValue }) => {
    try {
        const response = await axiosPost(API.USER.BOOKING_PAYMENT, payload);

        if (response.data.status === errorCode.success || response.data.status === errorCode.updateSuccess) {
            return response.data;
        }

        return rejectWithValue(response as ErrorType);
    } catch (err: any) {
        if (!err.response) {
            throw err;
        }
        return rejectWithValue(err.response.data as ErrorType);
    }
});

export const addBookingPayReqSlice = createSlice({
    name: '/shop/booking-payment/add',
    initialState,
    reducers: {
        addBooking: (state, { payload }) => {
            state.data = payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(addBookingPayReq.pending, (state) => {
                state.loading = true;
            })
            .addCase(addBookingPayReq.fulfilled, (state, { payload }: any) => {
                state.loading = false;
                state.data = payload.data;
            })
            .addCase(addBookingPayReq.rejected, (state, { payload }: any) => {
                state.loading = false;
                state.data = payload.data;
            });
    },
});

export const listStripeCard = createAsyncThunk('/shop/stripe/add', async () => {
    try {
        const response = await axiosPost(API.STRIPE.LIST, {});

        if (response.data.status === errorCode.success || response.data.status === errorCode.updateSuccess) {
            return response.data;
        }
    } catch (err: any) {
        if (!err.response) {
            throw err;
        }
    }
});

export const listStripeCardSlice = createSlice({
    name: '/shop/booking-payment/add',
    initialState,
    reducers: {
        addBooking: (state, { payload }) => {
            state.data = payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(listStripeCard.pending, (state) => {
                state.loading = true;
            })
            .addCase(listStripeCard.fulfilled, (state, { payload }: any) => {
                state.loading = false;
                state.data = payload.data;
            })
            .addCase(listStripeCard.rejected, (state, { payload }: any) => {
                state.loading = false;
                state.data = payload.data;
            });
    },
});

export const CreateStripeToken = createAsyncThunk('/shop/token', async (payload: any, { rejectWithValue }) => {
    try {
        const response: any = await axiosPost(API.STRIPE.CREATE, payload);
        if (response.data.status === 200) {
            return response.data;
        }

        return rejectWithValue({
            data: response,
        } as any);
    } catch (err: any) {
        if (!err.response) {
            throw err;
        }

        return rejectWithValue({
            message: err.response.data,
        } as any);
    }
});

export const CreateStripeTokenSlice = createSlice({
    name: '/shop/token',
    initialState,
    reducers: {
        addBooking: (state, { payload }) => {
            state.data = payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(CreateStripeToken.pending, (state) => {
                state.loading = true;
            })
            .addCase(CreateStripeToken.fulfilled, (state, { payload }: any) => {
                state.loading = false;
                state.data = payload.data;
            })
            .addCase(CreateStripeToken.rejected, (state, { payload }: any) => {
                state.loading = false;
                state.data = payload.data;
            });
    },
});

export const deleteStripeCard = createAsyncThunk('/shop/stripe/delete', async (payload: any, { rejectWithValue }) => {
    try {
        const response = await axiosPost(API.STRIPE.DELETE, payload);
        if (response.data.status === errorCode.success || response.data.status === errorCode.updateSuccess) {
            return response.data;
        }
    } catch (err: any) {
        if (!err.response) {
            throw err;
        }
    }
});

export const deleteStriperCardSlice = createSlice({
    name: '/shop/stripr/delete',
    initialState,
    reducers: {
        addBooking: (state, { payload }) => {
            state.data = payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(deleteStripeCard.pending, (state) => {
                state.loading = true;
            })
            .addCase(deleteStripeCard.fulfilled, (state, { payload }: any) => {
                state.loading = false;
                state.data = payload.data;
            })
            .addCase(deleteStripeCard.rejected, (state, { payload }: any) => {
                state.loading = false;
                state.data = payload.data;
            });
    },
});
