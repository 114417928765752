import React from 'react';
import { FaCheck, FaCircle } from 'react-icons/fa6';
import { IProps } from './Stepper.interface';

const Stepper: React.FC<IProps> = ({ steps, activeStep }) => (
    <ol className="fwpo__steper ">
        {steps.map(({ name, step }, index) => (
            <li
                key={index}
                className={`flex ${step !== steps?.length ? 'w-full' : ''} items-center ${
                    activeStep > step ? 'step_completed' : activeStep === 7 ? 'last_step step_inprogress' : activeStep === step ? 'step_inprogress' : ''
                }`}
            >
                {activeStep === step ? (
                    <>
                        <div className="relative flex flex-col items-center">
                            <span className="step__round">
                                <FaCircle />
                            </span>
                            <div className="steper_text">{name}</div>
                        </div>

                        {activeStep !== 7 && <div className="border_start"></div>}
                    </>
                ) : activeStep > step ? (
                    <>
                        <div className="relative flex flex-col items-center">
                            <span className="step__round">
                                <FaCheck />
                            </span>
                            <div className="steper_text">{name}</div>
                        </div>
                        <div className="border_start"></div>
                    </>
                ) : (
                    <>
                        <div className="relative flex flex-col items-center">
                            <span className="step__round">{<FaCircle />}</span>
                            <div className="steper_text">{name}</div>
                        </div>
                        {step !== steps?.length && <div className="border_start"></div>}
                    </>
                )}
            </li>
        ))}
    </ol>
);

export default Stepper;
