import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { API } from 'src/constants/api';
import { errorCode } from 'src/constants/errorCode';
import { RootState } from 'src/redux/store';

import { axiosDelete, axiosGet, axiosPatch, axiosPost } from 'src/utils/requestClient';

interface ErrorType {
    message: string;
    status?: string;
    data?: any;
}

const initialState = {
    data: '',
    loading: false,
    error: null,
};

export const clientList = createAsyncThunk('/shop/client/list', async (payload: any, { rejectWithValue }) => {
    try {
        const response = await axiosGet(
            API.CLIENT.LIST,
            {
                shop_id: payload.shop_id,
            },
            payload.data,
        );
        if (response.data.status === errorCode.success || response.data.status === errorCode.updateSuccess) {
            return response.data;
        }

        return rejectWithValue(response as ErrorType);
    } catch (err: any) {
        if (!err.response) {
            throw err;
        }
        return rejectWithValue(err.response.data as ErrorType);
    }
});

export const addClient = createAsyncThunk('/shop/client/add', async (payload: any, { rejectWithValue }) => {
    try {
        const response = await axiosPost(API.CLIENT.CREATE, payload.payload, { shop_id: payload.shop_id });

        if (response.data.status === errorCode.success || response.data.status === errorCode.updateSuccess) {
            return response.data;
        }

        return rejectWithValue(response as ErrorType);
    } catch (err: any) {
        if (!err.response) {
            throw err;
        }
        return rejectWithValue(err.response.data as ErrorType);
    }
});

export const updateClient = createAsyncThunk('/shop/client/update', async (payload: any, { rejectWithValue }) => {
    try {
        const response = await axiosPatch(API.CLIENT.UPDATE, payload.payload, payload.param);

        if (response.data.status === errorCode.success || response.data.status === errorCode.updateSuccess) {
            return response.data;
        }

        return rejectWithValue(response as ErrorType);
    } catch (err: any) {
        if (!err.response) {
            throw err;
        }
        return rejectWithValue(err.response.data as ErrorType);
    }
});

export const getClientDetails = createAsyncThunk('shop/client/details', async (payload: any, { rejectWithValue }) => {
    try {
        const response = await axiosGet(API.CLIENT.GET, {
            shop_id: payload.shop_id,
            id: payload.id,
        });
        if (response.data.status === errorCode.success || response.data.status === errorCode.updateSuccess) {
            return response.data;
        }
        return rejectWithValue(response as ErrorType);
    } catch (err: any) {
        if (!err.response) {
            throw err;
        }
        return rejectWithValue(err.response.data as ErrorType);
    }
});

export const serviceList = createAsyncThunk('/shop/client/service/list', async (payload: any, { rejectWithValue }) => {
    try {
        const response = await axiosGet(API.SERVICE.LIST, payload);
        if (response.data.status === errorCode.success || response.data.status === errorCode.updateSuccess) {
            return response.data;
        }

        return rejectWithValue(response as ErrorType);
    } catch (err: any) {
        if (!err.response) {
            throw err;
        }
        return rejectWithValue(err.response.data as ErrorType);
    }
});

export const bookingDetails = createAsyncThunk('/shop/client/booking/details', async (payload: any, { rejectWithValue }) => {
    try {
        const response = await axiosGet(API.CLIENT.BOOKING, payload);
        if (response.data.status === errorCode.success || response.data.status === errorCode.updateSuccess) {
            return response.data;
        }

        return rejectWithValue(response as ErrorType);
    } catch (err: any) {
        if (!err.response) {
            throw err;
        }
        return rejectWithValue(err.response.data as ErrorType);
    }
});

export const deleteClient = createAsyncThunk('/shop/client/delete', async (payload: any, { rejectWithValue }) => {
    try {
        const response = await axiosDelete(API.CLIENT.DELETE, {}, payload);
        if (response.data.status === errorCode.success || response.data.status === errorCode.updateSuccess) {
            return response.data;
        }

        return rejectWithValue(response as ErrorType);
    } catch (err: any) {
        if (!err.response) {
            throw err;
        }
        return rejectWithValue(err.response.data as ErrorType);
    }
});

export const downloadExcelOrCsv = createAsyncThunk('/shop/client/download', async (payload: any, { rejectWithValue }) => {
    try {
        const response = await axiosGet(
            API.CLIENT.EXPORT,
            {
                shop_id: payload.shop_id,
            },
            {
                type: payload.type,
            },
        );
        if (response.data.status === errorCode.success || response.data.status === errorCode.updateSuccess) {
            return response.data;
        }

        return rejectWithValue(response as ErrorType);
    } catch (err: any) {
        if (!err.response) {
            throw err;
        }
        return rejectWithValue(err.response.data as ErrorType);
    }
});

export const downloadImportClientsExcelOrCsv = createAsyncThunk('shop/client/sample', async (payload: any, { rejectWithValue }) => {
    try {
        const response = await axiosGet(
            API.CLIENT.SAMPLE,
            {
                shop_id: payload.shop_id,
            },
            {
                type: payload.type,
            },
        );
        if (response.data.status === errorCode.success || response.data.status === errorCode.updateSuccess) {
            return response.data;
        }

        return rejectWithValue(response as ErrorType);
    } catch (err: any) {
        if (!err.response) {
            throw err;
        }
        return rejectWithValue(err.response.data as ErrorType);
    }
});

export const importExcel = createAsyncThunk('/shop/client/import', async (payload: any, { rejectWithValue }) => {
    try {
        const response = await axiosPost(
            API.CLIENT.IMPORT,
            payload.file,
            {
                shop_id: payload.shop_id,
            },
            true,
        );
        if (response.data.status === errorCode.success || response.data.status === errorCode.updateSuccess) {
            return response.data;
        }

        return rejectWithValue(response as ErrorType);
    } catch (err: any) {
        if (!err.response) {
            throw err;
        }
        return rejectWithValue(err.response.data as ErrorType);
    }
});

export const clientCardList = createAsyncThunk('/shop/client/card/list', async (payload: any, { rejectWithValue }) => {
    try {
        const response = await axiosGet(API.CLIENT.CARD_LIST, payload);
        if (response.data.status === errorCode.success || response.data.status === errorCode.updateSuccess) {
            return response.data;
        }

        return rejectWithValue(response as ErrorType);
    } catch (err: any) {
        if (!err.response) {
            throw err;
        }
        return rejectWithValue(err.response.data as ErrorType);
    }
});

export const appointmentList = createAsyncThunk('/shop/client/upcoming/appointment', async (payload: any, { rejectWithValue }) => {
    try {
        const response = await axiosGet(API.CLIENT.APPOINTMENT, payload.param, payload.data);
        if (response.data.status === errorCode.success || response.data.status === errorCode.updateSuccess) {
            return response.data;
        }

        return rejectWithValue(response as ErrorType);
    } catch (err: any) {
        if (!err.response) {
            throw err;
        }
        return rejectWithValue(err.response.data as ErrorType);
    }
});

export const clientSlice = createSlice({
    name: '/shop/client/detail',
    initialState,
    reducers: {
        setListing: (state, { payload }) => {
            state.data = payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(clientList.pending, (state) => {
                state.loading = true;
            })
            .addCase(clientList.fulfilled, (state, { payload }: any) => {
                state.loading = false;
            })
            .addCase(clientList.rejected, (state, action: any) => {
                state.loading = false;
            })
            .addCase(addClient.pending, (state) => {
                state.loading = true;
            })
            .addCase(addClient.fulfilled, (state, { payload }: any) => {
                state.loading = false;
                state.data = payload.data;
            })
            .addCase(addClient.rejected, (state, { payload }: any) => {
                state.loading = false;
                state.data = payload.data;
            })
            .addCase(getClientDetails.pending, (state) => {
                state.loading = true;
            })
            .addCase(getClientDetails.fulfilled, (state, { payload }: any) => {
                state.loading = false;
            })
            .addCase(getClientDetails.rejected, (state, action: any) => {
                state.loading = false;
            })
            .addCase(serviceList.pending, (state) => {
                state.loading = true;
            })
            .addCase(serviceList.fulfilled, (state, { payload }: any) => {
                state.loading = false;
            })
            .addCase(serviceList.rejected, (state, action: any) => {
                state.loading = false;
            })
            .addCase(appointmentList.pending, (state) => {
                state.loading = true;
            })
            .addCase(appointmentList.fulfilled, (state, { payload }: any) => {
                state.loading = false;
            })
            .addCase(appointmentList.rejected, (state, action: any) => {
                state.loading = false;
            })
            .addCase(bookingDetails.pending, (state) => {
                state.loading = true;
            })
            .addCase(bookingDetails.fulfilled, (state, { payload }: any) => {
                state.loading = false;
            })
            .addCase(bookingDetails.rejected, (state, action: any) => {
                state.loading = false;
            })
            .addCase(clientCardList.pending, (state) => {
                state.loading = true;
            })
            .addCase(clientCardList.fulfilled, (state, { payload }: any) => {
                state.loading = false;
            })
            .addCase(clientCardList.rejected, (state, action: any) => {
                state.loading = false;
            })
            .addCase(deleteClient.pending, (state) => {
                state.loading = true;
            })
            .addCase(deleteClient.fulfilled, (state, { payload }: any) => {
                state.loading = false;
            })
            .addCase(deleteClient.rejected, (state, action: any) => {
                state.loading = false;
            })
            .addCase(downloadExcelOrCsv.pending, (state) => {
                state.loading = true;
            })
            .addCase(downloadExcelOrCsv.fulfilled, (state, { payload }: any) => {
                state.loading = false;
            })
            .addCase(downloadExcelOrCsv.rejected, (state, action: any) => {
                state.loading = false;
            })
            .addCase(importExcel.pending, (state) => {
                state.loading = true;
            })
            .addCase(importExcel.fulfilled, (state, { payload }: any) => {
                state.loading = false;
            })
            .addCase(importExcel.rejected, (state, action: any) => {
                state.loading = false;
            })
            .addCase(downloadImportClientsExcelOrCsv.pending, (state) => {
                state.loading = true;
            })
            .addCase(downloadImportClientsExcelOrCsv.fulfilled, (state, { payload }: any) => {
                state.loading = false;
            })
            .addCase(downloadImportClientsExcelOrCsv.rejected, (state, action: any) => {
                state.loading = false;
            });
    },
});

export const { reducer: accountReducer } = clientSlice;
export const { setListing } = clientSlice.actions;

export const selectClientData = (state: RootState) => state.Client.data;
export const selectClientLoading = (state: RootState) => state.Client.loading;
export const selectClientError = (state: RootState) => state.Client.error;
