import moment from 'moment';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import EmptyMsgWithBtn from 'src/components/EmptyMsgWithBtn';
import { usePhone } from 'src/hooks/usePhone';
import { Images } from 'src/theme';
import { capitalizeFirstLetterAndUnderscore, formatName, getShortName, getStatusClass } from 'src/utils/global-functions';

const LatestBookingActivity = ({ handleViewClick, latestData }: any) => {
    const CreateBooking = () => {};
    const { t } = useTranslation();
    const { getCustomFormatPhone } = usePhone();
    const [isImageError, setIsImageError] = useState<boolean>(false);

    return (
        <div className="bg-white rounded-3xl border border-lineColor  p-4 h-full">
            <div className="flex items-center mb-5">
                <h3 className="text-xl font-bold -tracking-[0.4px]">{t('Latest Booking Activity')}</h3>
                <button type="button" onClick={handleViewClick('latestBookingActivity')} className="text-primary ml-auto -tracking-[0.3] font-medium">
                    {t('View all')}
                </button>
            </div>
            {latestData.length > 0 ? (
                <div className="-mx-4">
                    <table className="fl-table w-full">
                        <thead>
                            <tr>
                                <th>{t('Client')}</th>
                                <th>{t('Service')}</th>
                                <th>{t('Location')}</th>
                                <th>{t('Price')}</th>
                                <th>{t('Professional')}</th>
                                <th>{t('Status')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {latestData.map((item: any) => (
                                <tr key={item.id}>
                                    <td>
                                        <div className="flex items-center">
                                            <figure className="w-[38px] h-[38px] basis-[38px] grow-0 shrink-0 rounded-full overflow-hidden mr-3 flex text-xs font-medium justify-center items-center bg-primary text-white">
                                                {item.user.profile_image_url && !isImageError ? (
                                                    <img src={item.user.profile_image_url} alt="client" className="w-full h-full object-cover" onError={() => setIsImageError(true)} />
                                                ) : item.user.full_name ? (
                                                    getShortName(`${item.user.full_name}`)
                                                ) : (
                                                    '+'
                                                )}
                                            </figure>
                                            <div className="">
                                                <p className="text-sm font-medium mb-[2px] leading-[140%] -tracking-[0.12px]">
                                                    {item.user.full_name ? `${formatName(item.user.full_name)}` : `${getCustomFormatPhone(item.user.phone, item.user.phone_country_code)}`}
                                                </p>
                                                <p className="text-xs font-medium leading-[150%] -tracking-[0.12px] text-secondaryTxtColor">
                                                    {moment(`${item.booking_date}' ' ${item.booking_start_time}`, 'YYYY-MM-DD HH:mm:ss').format('DD MMM YYYY • hh:mmA')}{' '}
                                                </p>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="truncate max-w-[128px] -tracking-[0.3px]">
                                            {item.booking_services[0].staff_service.service.name}
                                            {item.booking_services.length > 1 && `+ ${item.booking_services.length - 1}`}
                                        </div>
                                    </td>
                                    <td>
                                        <div className="text-xs font-normal leading-[140%] -tracking-[0.12px] flex items-center">
                                            <div className="text-xs text-primary font-medium leading-[140%] -tracking-[0.12px] flex items-center">
                                                <i className="mr-1 w-4 h-4">
                                                    <img src={Images.mapLocationPin} alt="map-pin" className="w-4 h-4" />
                                                </i>
                                                {item.location.name}
                                            </div>
                                            {/* <Tooltip
className="bg-mainTextColor text-xs"
// style="dark"
content={}
>
<RxInfoCircled className="text-sm" />
</Tooltip> */}
                                        </div>
                                    </td>
                                    <td>${item.total}</td>
                                    <td>{formatName(item.staff.full_name)}</td>
                                    <td>
                                        <span className={`-tracking-[0.3px] abc ${getStatusClass(item.status)}`}>{capitalizeFirstLetterAndUnderscore(item.status)}</span>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            ) : (
                <EmptyMsgWithBtn title={t('No latest booking activity')} description={t('No_latest_booking_activity_description')} btnLabel={t('Create booking')} onClick={CreateBooking} />
            )}
        </div>
    );
};

export default LatestBookingActivity;
