import React, { FC, memo, useEffect, useState } from 'react';
import { IBrandingTheme } from './BrandingTheme.interface';
import { useAppSelector } from 'src/redux/hooks';
import { selectThemeResult } from 'src/app/Appearance/Appearance.slice';

const BrandingTheme: FC<IBrandingTheme> = ({ themeType, selectedTheme, setSelectedTheme, settings }) => {
    const themeResult = useAppSelector(selectThemeResult);
    const [themes, setThemes] = useState<any>(themeResult);

    useEffect(() => {
        if (themeResult?.length) {
            const newThemes = themeResult.map((theme) => {
                if ((6 === theme.id && theme.id === settings[0].shop_theme_id) || (12 === theme.id && theme.id === settings[0].shop_theme_id)) {
                    const updatedTheme = { ...theme };
                    updatedTheme.color = settings[0];
                    updatedTheme.id = settings[0].shop_theme_id;
                    return updatedTheme;
                }
                return theme;
            });
            setThemes(newThemes);
        }
    }, []);

    return (
        <div className="flex gap-5">
            {themes &&
                themes?.length > 0 &&
                themes?.map(
                    (theme: any, index: any) =>
                        theme.type === themeType && (
                            <div className={`theme-box ${selectedTheme === theme.id ? 'active' : ''}`} key={index} onClick={setSelectedTheme(theme.id)}>
                                <div
                                    className="flex px-[10px] py-5 rounded-[6px] gap-[10px] theme-block-thumb mb-3"
                                    style={{
                                        backgroundColor: theme.color.content_background,
                                    }}
                                >
                                    <div className="flex flex-col">
                                        <div
                                            className="h-[10px] mb-[5px] w-[25px] rounded-sm"
                                            style={{
                                                backgroundColor: theme.color.main_text_color,
                                            }}
                                        ></div>
                                        <div
                                            className="h-[10px] mb-[5px] w-[42px] rounded-sm"
                                            style={{
                                                backgroundColor: theme.color.secondary_text_color,
                                            }}
                                        ></div>
                                        <div
                                            className="h-[10px] mb-[10px] w-[36px] rounded-sm"
                                            style={{
                                                backgroundColor: theme.color.secondary_text_color,
                                            }}
                                        ></div>
                                        <div
                                            className="h-[10px] mb-[5px] w-[25px] rounded-sm"
                                            style={{
                                                backgroundColor: theme.color.button_color,
                                            }}
                                        ></div>
                                        <div className="flex">
                                            <div
                                                className="w-[10px] h-[10px] mr-1 rounded-full"
                                                style={{
                                                    backgroundColor: theme.color.main_text_color,
                                                }}
                                            ></div>
                                            <div
                                                className="w-[10px] h-[10px] mr-1 rounded-full"
                                                style={{
                                                    backgroundColor: theme.color.accent_color,
                                                }}
                                            ></div>
                                            <div
                                                className="w-[10px] h-[10px] mr-1 rounded-full"
                                                style={{
                                                    backgroundColor: theme.color.main_text_color,
                                                }}
                                            ></div>
                                        </div>
                                    </div>
                                    <div
                                        className="flex flex-col px-4 py-3 rounded-[6px] "
                                        style={{
                                            backgroundColor: theme.color.cards_color,
                                        }}
                                    >
                                        <div
                                            className="h-[12px] mb-[7px] w-[25px] rounded-sm"
                                            style={{
                                                backgroundColor: theme.color.main_text_color,
                                            }}
                                        ></div>
                                        <div
                                            className="h-[12px] mb-[7px] w-[45px] rounded-sm"
                                            style={{
                                                backgroundColor: theme.color.secondary_text_color,
                                            }}
                                        ></div>
                                        <div
                                            className="h-[12px] mb-0 w-[25px] rounded-sm"
                                            style={{
                                                backgroundColor: theme.color.button_color,
                                            }}
                                        ></div>
                                    </div>
                                </div>
                                <p className="theme-box-title">{theme.name}</p>
                            </div>
                        ),
                )}
        </div>
    );
};

export default memo(BrandingTheme);
