/* eslint-disable max-lines-per-function */
import React, { FC, useEffect, useState } from 'react';
import InputWithLabel from 'src/components/InputWithLabel';
import Button from 'src/components/Button';
import { useForm, Controller } from 'react-hook-form';
import { FaArrowLeftLong } from 'react-icons/fa6';
import SelectBox from '../../../components/SelectBox/SelectBox';
import { IFormData, IPops, ICountryResponse } from './Company.interface';
import AddForm from './AddForm';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { company } from './Company.slice';
import { errorCode } from 'src/constants/errorCode';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { selectUserInfo, shopDetail } from 'src/app/Auth/Login/Login.slice';
import { getCountry } from 'src/redux/services/country/Country.slice';
import { getState } from 'src/redux/services/state/State.slice';
import { checkFileTypeValidation, convertBase64ToFile, getSelectBoxOptions } from 'src/utils/global-functions';
import { s3Upload } from 'src/utils/s3Operations';
import { ReactComponent as Cloud } from 'src/assets/svgs/upload 1.svg';
import { Images } from 'src/theme';
import CropperModal from 'src/components/CropperModal/CropperModal';
import { GLOBALVARIABLE } from 'src/utils/global-variables';
import AutoCompleteGoogle from 'src/components/AutoCompleteGoogle/AutoCompleteGoogle';
import { useTranslation } from 'react-i18next';

const Company: FC<IPops> = ({ setStep }) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const user = useAppSelector(selectUserInfo);
    const firstName = user?.first_name;
    const companyStep = user.shops[0].account_step.company;
    const [optionCountry, setOptionContry] = useState<ICountryResponse[]>([]);
    const [optionState, setOptionState] = useState<ICountryResponse[]>([]);
    const imageUrl = companyStep ? user.shops[0].logo_image_url : null;
    const [isLoading, setIsLoading] = useState(true);
    const [file, setFile] = useState<any>({ convertedFile: '', filename: '' });
    const [cropData, setCropData] = useState<any>(imageUrl || '');
    const [image, setImage] = useState<any>(imageUrl || '');
    const [upload, setUpload] = useState(false);
    const getCountryFun = async () => {
        const result = await dispatch(getCountry());
        if (result.type === getCountry.fulfilled.toString()) {
            const contryData: ICountryResponse[] | [] = getSelectBoxOptions(result.payload.data);
            const countriesWithImages = contryData.map((country) => ({
                ...country,
                image: require(`src/assets/flags/${country.shortName}.svg`),
            }));
            const priorityCountries = ['Canada', 'United States'];

            const sortedCountries = countriesWithImages.sort((a, b) => {
                const aIndex = priorityCountries.indexOf(a.label);
                const bIndex = priorityCountries.indexOf(b.label);
                if ((aIndex !== -1 && bIndex !== -1) || (aIndex === -1 && bIndex === -1)) {
                    return a.label.localeCompare(b.label);
                }
                return aIndex !== -1 ? -1 : 1;
            });
            const countyId = companyStep && user.shops[0].country ? contryData.find((option) => option?.value === user.shops[0].country) : null;
            setOptionContry(sortedCountries);
            if (countyId) {
                setValue('country', countyId.value);
                getStateFun(countyId.id);
            }
            setIsLoading(false);
        }
    };

    const getStateFun = async (country_id: number) => {
        const data = {
            country_id: country_id,
        };
        const result = await dispatch(getState(data));
        if (result.type === getState.fulfilled.toString()) {
            const stateData: ICountryResponse[] | [] = getSelectBoxOptions(result.payload.data);
            setOptionState(stateData);

            const stateDataId = companyStep && user.shops[0].state ? stateData.find((option) => option?.value === user.shops[0].state) : null;

            if (stateDataId) {
                setValue('state', stateDataId.value);
            } else {
                setValue('state', '');
            }
        }
    };

    const onChangeCrop = (e: any) => {
        setUpload(false);
        e.preventDefault();
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        const { result, message } = checkFileTypeValidation(files, GLOBALVARIABLE.locationImageSize);
        if (result) {
            clearErrors('logo_image_name');
            const reader = new FileReader();
            reader.onloadstart = () => {
                setImage('');
                setUpload(false);
            };
            reader.onloadend = () => {
                setImage(reader.result as any);
                setUpload(true);
            };
            reader.readAsDataURL(files[0]);
            e.target.value = null;
            // setUploadProgress(0);
        } else {
            setError('logo_image_name' as 'logo_image_name', {
                type: 'manual',
                message: message,
            });
        }
        e.target.value = null;
    };

    const handleAutocompleteChange = (name: string) => (value: any) => {
        setValue('address', value.fullAddress);
    };
    useEffect(() => {
        getCountryFun();
    }, []);
    const urlSchema = Yup.string()
        .url(t('Please enter a valid URL'))
        .transform((value, originalValue) => {
            if (originalValue && originalValue.startsWith('www')) {
                return `http://${originalValue}`;
            }
            return value;
        })
        .nullable();
    const schema = Yup.object().shape({
        business_name: Yup.string().required(t('Business name required')),
        logo_image_name: Yup.string().required(t('Logo required')),
        country: Yup.string().required(t('Country selection required')),
        state: Yup.string().required(t('State required')),
        city: Yup.string().required(t('City required')),
        address: Yup.string().required(t('Address required')),
        staff_size: Yup.string().required(t('Staff size required')),
        website: urlSchema,
        google_review: urlSchema,
        facebook_page: urlSchema,
        instagram_page: urlSchema,
        contact_details: Yup.array()
            .of(
                Yup.object().shape({
                    id: Yup.number().required('ID is required'),
                    first_name: Yup.string().required(t('First name required')),
                    last_name: Yup.string().required(t('Last name required')),
                    phone: Yup.string().required(t('Phone number required')),
                    email: Yup.string().email(t('Valid Gmail Address Needed')).required(t('Email required')),
                    phone_country_code: Yup.string().required(),
                }),
            )
            .nullable(),
    });
    type FieldKey =
        | 'business_name'
        | 'logo_image_name'
        | 'country'
        | 'state'
        | 'city'
        | 'address'
        | 'staff_size'
        | 'website'
        | 'google_review'
        | 'facebook_page'
        | 'instagram_page'
        | 'contact_details';

    const {
        handleSubmit,
        control,
        formState: { errors },
        setValue,
        setError,
        clearErrors,
    } = useForm<IFormData>({
        resolver: yupResolver(schema),
        defaultValues: {
            business_name: companyStep ? user.shops[0].business_name : '',
            logo_image_name: companyStep ? user.shops[0].logo_image_name : '',
            country: companyStep ? user.shops[0].country : '',
            state: companyStep ? user.shops[0].state : '',
            city: companyStep ? user.shops[0].city : '',
            address: companyStep ? user.shops[0].address : '',
            staff_size: companyStep ? user.shops[0].staff_size : '',
            website: companyStep ? user.shops[0].website : '',
            google_review: companyStep ? user.shops[0].google_review : '',
            facebook_page: companyStep ? user.shops[0].facebook_page : '',
            instagram_page: companyStep ? user.shops[0].instagram_page : '',
            contact_details: companyStep ? user.shops[0].contacts : null,
        },
    });

    const handleChange = async (data: IFormData) => {
        setIsLoading(true);

        if (file.convertedFile && file?.filename) {
            const imgUploadResult: any = await s3Upload(file?.convertedFile, `images/business_logos/${file?.filename}`);
            if (imgUploadResult.status === 201) {
                /* const url = imgUploadResult.data.Location.replace(
                    process.env.REACT_APP_AWS_UPLOAD_URL,
                    process.env.REACT_APP_PUBLIC_URL,
                ); */
                const updatedData = {
                    ...data,
                    logo_image_name: file.filename,
                };
                submitForm(updatedData);
            }
        } else {
            submitForm(data);
        }
    };
    const submitForm = async (data: IFormData) => {
        const result = await dispatch(company(data));
        if (result.type === company.fulfilled.toString()) {
            dispatch(shopDetail());
            setStep(3);
            setIsLoading(false);
        }
        if (result.type === company.rejected.toString()) {
            const response = result.payload.data;
            if (response.status === errorCode.unprocessable) {
                if (response.data) {
                    Object.keys(response.data).forEach((field) => {
                        setError(field as FieldKey, {
                            type: 'manual',
                            message: response.data[field][0].replace(/[\d.]+/g, ' '),
                        });
                    });
                }
                setIsLoading(false);
                return;
            }
        }
    };
    const imageUpload = (data: any) => {
        const appLogoImage = data;
        const fileInfo = convertBase64ToFile(appLogoImage);
        setFile(fileInfo);
        if (fileInfo && fileInfo.filename) {
            setValue('logo_image_name', fileInfo.filename);
        }
    };
    return (
        <>
            <form onSubmit={handleSubmit(handleChange)}>
                <div className="w-[872px] 2xl:w-[1176px] flex-1 flex flex-col mt-[52px]">
                    <div className="text-center">
                        <h3 className="fwpo_heading_title w-[480px] mx-auto">
                            {t('Welcome')} {firstName}. {t('Tell us a bit about your business!')}
                        </h3>
                    </div>

                    <div className="mt-10">
                        <div className="flex -mx-4 flex-wrap">
                            <div className="w-[260px] px-4">
                                <Controller
                                    name="logo_image_name"
                                    control={control}
                                    render={({ field: { onChange } }) => (
                                        <>
                                            <div className="bg-contentBackground w-full">
                                                <div className="w-[full]">
                                                    <div className={`h-[87px] overflow-hidden`}>
                                                        {!cropData ? (
                                                            <div className={`relative h-[87px] overflow-hidden rounded-md `}>
                                                                <div
                                                                    className={`flex  ${
                                                                        errors.logo_image_name ? 'is-invalid' : ''
                                                                    } rounded-[3rem] gap-2 border-[1px] px-2 py-2  border-dashed items-center`}
                                                                >
                                                                    <>
                                                                        <div
                                                                            className={`drop-shadow-none shadow-none w-[63px] h-[63px] basis-[63px] shrink-0 grow-0 flex justify-center items-center ring-0 bg-inputBg rounded-full`}
                                                                        >
                                                                            <img src={Images.AddImgIcon} className="w-5" alt="" />
                                                                        </div>

                                                                        <h1 className="text-xs font-medium -tracking-[0.12px] text-secondaryTxtColor">
                                                                            {t('Upload company logo in JPG or PNG.Max size of 800K')}
                                                                        </h1>
                                                                    </>
                                                                </div>
                                                                <label htmlFor="fileInput" className="absolute left-0 top-0 z-20 cursor-pointer rounded-md px-8 py-2 overflow-hidden h-full w-full">
                                                                    {''}
                                                                    <input
                                                                        type="file"
                                                                        id="fileInput"
                                                                        className="imgupload  w-full rounded-md text-mainTextColor px-8 py-2 mt-8 bg-btnclr "
                                                                        style={{
                                                                            visibility: 'hidden',
                                                                        }}
                                                                        onChange={onChangeCrop}
                                                                    />
                                                                </label>
                                                            </div>
                                                        ) : (
                                                            <div
                                                                className={`relative h-[80px] overflow-hidden rounded-[40px] border border-lineLightColor todotest2 ${
                                                                    errors.logo_image_name ? 'is-invalid' : 'border-lineLightColor'
                                                                }`}
                                                            >
                                                                <div className="w-auto h-full  flex items-center cursor-pointer justify-center overflow-hidden p-[5px]">
                                                                    {/* <div className="flex rounded-[2rem] gap-2 border-2 px-2 py-2 border-lightgray h-full  items-center"> */}
                                                                    <img
                                                                        style={{
                                                                            height: '100%',
                                                                            width: 'auto',
                                                                        }}
                                                                        src={cropData}
                                                                        className=""
                                                                        alt="cropped"
                                                                    />
                                                                    <div className="  absolute top-0 left-0 w-full h-full  flex justify-end items-center pr-[8px] ">
                                                                        <div className="bg-inputBg p-2 w-14 h-14 rounded-full flex justify-center items-center">
                                                                            <Cloud width={20} height={20} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <label htmlFor="fileInput" className="absolute left-0 top-0 z-20 cursor-pointer rounded-md px-8 py-2 overflow-hidden h-full w-full">
                                                                    {''}
                                                                    <input
                                                                        type="file"
                                                                        id="fileInput"
                                                                        className="imgupload  w-full rounded-md text-mainTextColor px-8 py-2 mt-8 bg-btnclr"
                                                                        style={{
                                                                            visibility: 'hidden',
                                                                        }}
                                                                        onChange={onChangeCrop}
                                                                    />
                                                                </label>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                />
                                <p className="text-error ">{errors.logo_image_name && errors.logo_image_name.message}</p>
                            </div>
                            <div className="px-4 flex-1 mb-8">
                                <Controller
                                    name="business_name"
                                    control={control}
                                    render={({ field: { onChange, value } }: any) => (
                                        <div className="">
                                            <InputWithLabel
                                                label={t('Business Name')}
                                                placeholder={t('Company Name')}
                                                onChange={onChange}
                                                value={value}
                                                name="Businessname"
                                                disabled={isLoading}
                                                error={errors.business_name && true}
                                                isFocus={true}
                                            />
                                            <p className="text-error ">{errors.business_name && errors.business_name.message}</p>
                                        </div>
                                    )}
                                />
                            </div>
                            <div className="px-4 mb-8 w-[344px]">
                                <Controller
                                    name="country"
                                    control={control}
                                    render={({ field: { onChange, value } }: any) => (
                                        <>
                                            <SelectBox
                                                name="country"
                                                id="handlingUnit"
                                                label={t('Select Country')}
                                                placeholder={t('Select Country')}
                                                options={optionCountry}
                                                onChangeFunc={(selectedOption: any) => {
                                                    getStateFun(selectedOption.id);
                                                    onChange(selectedOption.value);
                                                }}
                                                value={optionCountry.find((option) => option?.value === value)}
                                                errorText={errors.country && true}
                                                isDisabled={isLoading}
                                            />
                                            <p className="text-error">{errors.country && errors.country.message}</p>
                                        </>
                                    )}
                                />
                            </div>
                            <div className="w-1/2 px-4 mb-8">
                                <Controller
                                    name="state"
                                    control={control}
                                    render={({ field: { onChange, value } }: any) => (
                                        <>
                                            <SelectBox
                                                name="state"
                                                id="handlingUnit"
                                                label={t('Province/State')}
                                                placeholder={t('Select Province/State')}
                                                options={optionState}
                                                onChangeFunc={(selectedOption: any) => {
                                                    onChange(selectedOption.value);
                                                }}
                                                value={value ? optionState.find((option) => option.value === value) : null}
                                                errorText={errors.state && true}
                                                isDisabled={isLoading}
                                            />
                                            <p className="text-error">{errors.state && errors.state.message}</p>
                                        </>
                                    )}
                                />
                            </div>
                            <div className="w-1/2 px-4 mb-8">
                                <Controller
                                    name="city"
                                    control={control}
                                    render={({ field: { onChange, value } }: any) => (
                                        <>
                                            <InputWithLabel
                                                label={t('City')}
                                                placeholder={t('Select City')}
                                                onChange={onChange}
                                                value={value}
                                                name="city"
                                                disabled={isLoading}
                                                error={errors.city && true}
                                            />
                                            <p className="text-error">{errors.city && errors.city.message}</p>
                                        </>
                                    )}
                                />
                            </div>
                            <Controller
                                name="address"
                                control={control}
                                render={({ field: { onChange, value } }: any) => (
                                    <div className="w-3/5 2xl:w-2/3 px-4 mb-8">
                                        <AutoCompleteGoogle
                                            name="address"
                                            inputName="locationAddress"
                                            placeholder={t('Enter Address')}
                                            className="form_control"
                                            onChangeFunc={handleAutocompleteChange('address')}
                                            value={value}
                                            onChange={onChange}
                                            label={t('Business Address')}
                                            error={errors.address && true}
                                            disabled={isLoading}
                                        />

                                        <p className="text-error ">{errors.address && errors.address.message}</p>
                                    </div>
                                )}
                            />
                            <div className="w-[327px] px-4">
                                <span className="fl-field-title-label">{t('Team Size')}</span>
                                <Controller
                                    name="staff_size"
                                    control={control}
                                    render={({ field: { onChange, value } }) => (
                                        <ul className="list-none inline-flex gap-3">
                                            <li>
                                                <input
                                                    type="radio"
                                                    id="independent"
                                                    value="independent"
                                                    className="hidden peer"
                                                    onChange={() => onChange('independent')}
                                                    checked={value === 'independent'}
                                                    disabled={isLoading}
                                                />
                                                <label
                                                    htmlFor="independent"
                                                    className={`inline-flex items-center justify-between p-3 px-5 text-secondaryTxtColor bg-inputBg border border-transparent rounded-full cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-primary peer-checked:border-primary peer-checked:text-primary ${
                                                        errors.staff_size ? 'is-invalid' : ''
                                                    }`}
                                                >
                                                    <div className="block text-sm">{t('Independent')}</div>
                                                </label>
                                            </li>
                                            {/* <li>
                                                <input
                                                    type="radio"
                                                    id="block01"
                                                    name="staff_size"
                                                    value="block01"
                                                    onChange={() =>
                                                        onChange('block01')
                                                    }
                                                    className="hidden peer"
                                                    checked={
                                                        value === 'block01'
                                                    }
                                                />
                                                <label
                                                    htmlFor="block01"
                                                    className="inline-flex items-center justify-between  p-3 px-5 text-secondaryTxtColor bg-inputBg border border-transparent rounded-full cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-primary peer-checked:border-primary peer-checked:text-primary "
                                                >
                                                    <div className="block text-sm">
                                                        1
                                                    </div>
                                                </label>
                                            </li> */}
                                            <li>
                                                <input
                                                    type="radio"
                                                    id="block2to5"
                                                    name="staff_size"
                                                    value="block2to5"
                                                    className="hidden peer"
                                                    onChange={() => onChange('block2to5')}
                                                    checked={value === 'block2to5'}
                                                    disabled={isLoading}
                                                />
                                                <label
                                                    htmlFor="block2to5"
                                                    className={`inline-flex items-center justify-between  p-3 px-5 text-secondaryTxtColor bg-inputBg border border-transparent rounded-full cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-primary peer-checked:border-primary peer-checked:text-primary ${
                                                        errors.staff_size ? 'is-invalid' : ''
                                                    } `}
                                                >
                                                    <div className="block text-sm whitespace-nowrap">{t('2-5')}</div>
                                                </label>
                                            </li>
                                            <li>
                                                <input
                                                    type="radio"
                                                    id="block6plus"
                                                    name="staff_size"
                                                    value="block6plus"
                                                    className="hidden peer"
                                                    onChange={() => onChange('block6plus')}
                                                    checked={value === 'block6plus'}
                                                    disabled={isLoading}
                                                />
                                                <label
                                                    htmlFor="block6plus"
                                                    className={`inline-flex items-center justify-between  p-3 px-5 text-secondaryTxtColor bg-inputBg border border-transparent rounded-full cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-primary peer-checked:border-primary peer-checked:text-primary ${
                                                        errors.staff_size ? 'is-invalid' : ''
                                                    }`}
                                                >
                                                    <div className="block text-sm">{t('6+')}</div>
                                                </label>
                                            </li>
                                        </ul>
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="mt-10">
                        <h2 className="text-mainTextColor text-base font-bold leading-[140%] -tracking-[0.16px]">{t('Online Links')}</h2>
                        <p className="text-secondaryTxtColor font-normal leading-[150%] -tracking-[0.12px] mt-2 text-xs">{t('online_links_description')}</p>

                        <div className="flex -mx-4 flex-wrap mt-5">
                            <Controller
                                name="website"
                                control={control}
                                render={({ field: { onChange, value } }: any) => (
                                    <div className="px-4 mb-8 w-1/2">
                                        <InputWithLabel
                                            label={t('Website URL')}
                                            placeholder={t('www.yoursite.com')}
                                            onChange={onChange}
                                            value={value}
                                            name="Businessname"
                                            disabled={isLoading}
                                            error={errors.website && true}
                                        />
                                        <p className="text-error ">{errors.website && errors.website.message}</p>
                                    </div>
                                )}
                            />
                            <Controller
                                name="google_review"
                                control={control}
                                render={({ field: { onChange, value } }: any) => (
                                    <div className="px-4 mb-8 w-1/2">
                                        <InputWithLabel
                                            label={t('Google Reviews')}
                                            placeholder={t('www.google.com/search?q=your_business_name')}
                                            onChange={onChange}
                                            value={value}
                                            name="Businessname"
                                            disabled={isLoading}
                                            error={errors.google_review && true}
                                        />
                                        <p className="text-error ">{errors.google_review && errors.google_review.message}</p>
                                    </div>
                                )}
                            />
                            <Controller
                                name="facebook_page"
                                control={control}
                                render={({ field: { onChange, value } }: any) => (
                                    <div className="px-4 mb-8 w-1/2">
                                        <InputWithLabel
                                            label={t('Facebook Page')}
                                            placeholder={t('www.facebook.com/yoursite')}
                                            onChange={onChange}
                                            value={value}
                                            name="Businessname"
                                            disabled={isLoading}
                                            error={errors.facebook_page && true}
                                        />
                                        <p className="text-error ">{errors.facebook_page && errors.facebook_page.message}</p>
                                    </div>
                                )}
                            />
                            <Controller
                                name="instagram_page"
                                control={control}
                                render={({ field: { onChange, value } }: any) => (
                                    <div className="px-4 mb-8 w-1/2">
                                        <InputWithLabel
                                            label={t('Instagram Account')}
                                            placeholder={t('www.instagram.com/yourprofile')}
                                            onChange={onChange}
                                            value={value}
                                            name="Businessname"
                                            disabled={isLoading}
                                            error={errors.instagram_page && true}
                                        />
                                        <p className="text-error ">{errors.instagram_page && errors.instagram_page.message}</p>
                                    </div>
                                )}
                            />
                        </div>
                    </div>
                    <div className="mt-7">
                        <div className="text-center">
                            <h3 className="fwpo_heading_title  mx-auto">{t('Business Contacts')}</h3>
                            <p className="text-secondaryTxtColor font-normal leading-[22.4px] w-[586px] mx-auto mt-4 text-sm">{t('business_contacts_description')}</p>
                        </div>

                        <AddForm control={control} errors={errors} setValue={setValue} isLoading={isLoading} />
                    </div>
                </div>
                <div className="flex w-full px-10 justify-center my-[60px]">
                    <div className="back-button absolute left-10">
                        <Button className="btn_outline_black text-sm" disable type="button" onClick={() => setStep(1)} disabled={isLoading}>
                            <FaArrowLeftLong className="mr-2" size={18} /> {t('Back')}
                        </Button>
                    </div>
                    <Button isLoading={isLoading} className="fl-btn btn_primary w-[420px] 2xl:w-[572px]">
                        {t('Continue')}
                    </Button>
                </div>
            </form>
            {upload && <CropperModal defaultCropType="Rounded" imageUrl={image} imageUpload={imageUpload} setUpload={setUpload} setCropData={setCropData} />}
        </>
    );
};

export default Company;
