import React from 'react';
import { IFileInputWithBorder } from './FileInputWithBorder.interface';

const FileInputWithBorder: React.FC<IFileInputWithBorder> = ({ error, onChange, value, name, fileName, label, labelClassName, readOnly, disabled = false }): JSX.Element => (
    <div className={` flex flex-col  w-full`}>
        {label && (
            <label htmlFor={name} className={`fl-field-title-label ${labelClassName}`}>
                {label}
            </label>
        )}
        <div className="relative flex">
            {/* <div
                className={`form_input_control rounded-none rounded-l-full flex items-center ' ${
                    error && 'is-invalid'
                }`}
            >
                {fileName ? (
                    <span className="text-mainTextColor">{fileName}</span>
                ) : (
                    <span className="">No file chosen</span>
                )}
            </div>
            <Button className="fl-btn bg-mainTextColor font-bold text-white px-4 rounded-none rounded-r-full whitespace-nowrap cursor-pointer">
                Choose File
            </Button> */}

            <div className={`form_input_control bg-white border-black border-dashed rounded-full flex justify-center items-center mb-3 ' ${error && 'is-invalid'}`}>
                {fileName ? <span className="text-mainTextColor">{fileName}</span> : <span className="text-mainTextColor font-normal">+ Upload contract document</span>}
            </div>

            <input className={`opacity-0 absolute left-0 top-0 bottom-0 w-full h-full `} type="file" name={name} value={value} disabled={disabled} onChange={onChange} readOnly={readOnly} />
        </div>
    </div>
);

export default FileInputWithBorder;
