/* eslint-disable @typescript-eslint/no-shadow */
import React from 'react';
import ReactSelect, { components } from 'react-select';
import makeAnimated from 'react-select/animated';

import { ISelectBoxProps } from './SelectBox.interface';
import { FaCheck } from 'react-icons/fa6';

const colourStyles = (error: any) => ({
    control: (defaultStyles: any, state: any) => ({
        ...defaultStyles,
        border: `1px solid ${state.isFocused && error ? '#E33B3B' : state.isFocused ? '#1642C5' : error ? '#E33B3B' : '#EFF1F5'}`,

        boxShadow: state.isFocused ? 0 : 0,
        '&:hover': {
            border: `1px solid  ${state.isFocused && error ? '#E33B3B' : state.isFocused ? '#1642C5' : error ? '#E33B3B' : '#EFF1F5'}`,
        },
        backgroundColor: error ? '#f9e0e0' : '#EFF1F5',
        padding: '0.375rem 0.5rem',
        color: '#1F293C',
        borderRadius: '40px',
    }),
    menuList: (styles: any) => ({
        ...styles,
        borderRadius: '24px',
    }),
    menu: (styles: any) => ({
        ...styles,
        borderRadius: '24px',
        border: 'none',
        boxShadow: '0px 5.33333px 16px 0px rgba(146, 140, 151, 0.25);',
    }),
    option: (styles: any, state: any) => ({
        ...styles,
        '&:hover': {
            backgroundColor: !state.isSelected && '#EFF1F5',
        },
    }),
    clearIndicator: (styles: any) => ({
        ...styles,
        borderRadius: '24px',
        padding: '1px',
        marginRight: '5px',
        color: 'white',
        backgroundColor: '#98A7C3',
        '&:hover': {
            color: 'white',
            backgroundColor: '#66738c',
        },
    }),
});

const CustomOption = ({ children, ...props }: any) => {
    const { onMouseMove, onMouseOver, ...rest } = props.innerProps;
    const newProps = { ...props, innerProps: rest };
    return (
        <components.Option {...newProps}>
            <div className="flex justify-between items-center font-medium">
                {/* {children} {props.isSelected && <FaCheck size={16} />} */}
                <div className="dropdown-detail text-sm font-normal">
                    {props.data.image && <img src={props.data.image} alt={props.data.label} className="w-6 h-auto max-h-6 rounded-md me-4" />}
                    {children}
                </div>
                {props.isSelected && <FaCheck size={16} />}
            </div>
        </components.Option>
    );
};
const CustomControl = ({ children, ...props }: any) => (
    <components.Control {...props}>
        {props?.allowIcon || ''}
        {children}
    </components.Control>
);

const SelectBox = ({
    name,
    errorText,
    label,
    id,
    options,
    className,
    classNamePrefix,
    tabIndex,
    isSearchable = false,
    labelClassname,
    outerClassName,
    isDisabled,
    tooltip,
    value,
    onChangeFunc,
    isClearable,
    isLoading,
    placeholder,
    classComp,
    isMulti,
    allowIcon,
    defaultValue,
    menuIsOpen = false,
    ...props
}: ISelectBoxProps) => (
    <>
        <div className={`w-full ${outerClassName}`}>
            {label && <label className={`fl-field-title-label ${labelClassname}`}>{label}</label>}
            <ReactSelect
                value={value}
                defaultValue={defaultValue}
                // defaultMenuIsOpen
                onChange={onChangeFunc}
                options={options}
                tabIndex={tabIndex}
                classNamePrefix={classNamePrefix ? classNamePrefix : 'fwpo_select'}
                isSearchable={isSearchable}
                isClearable={isClearable}
                isDisabled={isDisabled}
                isLoading={isLoading}
                // menuIsOpen={menuIsOpen}
                styles={colourStyles(errorText)}
                placeholder={placeholder}
                className={`${classComp}`}
                // menuIsOpen
                components={
                    !isMulti
                        ? {
                              Option: (props: any) => <CustomOption {...props} />,
                              Control: (props: any) => <CustomControl {...props} allowIcon={allowIcon} />,
                          }
                        : makeAnimated()
                }
                isMulti={isMulti}
            />
        </div>
    </>
);

SelectBox.defaultProps = {
    name: '',
    errorText: '',
    label: '',
    tooltip: '',
    className: '',
    options: '',
    id: '',
    labelClassname: '',
    classComp: '',
};

export default SelectBox;
