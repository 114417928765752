/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable max-lines-per-function */
import React, { useEffect, useMemo, useState } from 'react';
import * as Yup from 'yup';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import Button from 'src/components/Button';
import SearchBar from 'src/components/SearchBar/SearchBar';
import WrapperHeading from 'src/components/WrapperHeading';
import Popup from 'src/components/Popup';

import LocationInfo from './Location.Info';
import AddNew from './Location.AddNew';
import SalesTaxes from './Location.SalesTaxes';
import OpeningHours from './Location.OpeningHours';
import TipsDetail from './Location.TipsDetail';
import { ICountStep, IStep } from 'src/components/Stepper/Stepper.interface';
import Stepper from 'src/components/Stepper';
import Detail from './Location.Detail';
import { IAddNewFormData, ISalesTaxesFormList, StepperName } from './Location.interface';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { selectShopInfo } from '../Auth/Login/Login.slice';
import { createLocationAction, getSalesTaxAction, locationListAction, selectListing, selectLoading, selectTaxes } from './Location.slice';

import { checkFileTypeValidation, convertBase64ToFile } from 'src/utils/global-functions';
import CropperModal from 'src/components/CropperModal/CropperModal';
import { IFormData, initFormData } from '../Signup/Location/Location.interface';
import moment from 'moment';
import { IWorkingHoursValue, workingHoursData } from '../Signup/WorkingHours/WorkingHours.interface';
import { s3Upload } from 'src/utils/s3Operations';
import { errorCode } from 'src/constants/errorCode';
import { GLOBALVARIABLE } from 'src/utils/global-variables';
import BarLoader from 'src/components/BarLoader';

// let intervalProgress: any;
const scriptId = 'google-maps-api';
const defaultTipType = 'percentage';

const Location = () => {
    const dispatch = useAppDispatch();
    const loading = useAppSelector(selectLoading);
    const listing = useAppSelector(selectListing);
    const shopInfo = useAppSelector(selectShopInfo);
    const saleTaxes = useAppSelector(selectTaxes);
    const [formData, setFormData] = useState<IFormData>(initFormData);
    const [filterListing, setFilterListing] = useState<any>([]);
    const [searchValue, setSearchValue] = useState('');
    const [newLocationModal, setNewLocationModal] = useState(false);
    const [step, setStep] = useState<number | ICountStep>(1);
    const [seeDetails, setSeeDetails] = useState<any>({
        open: false,
        shopId: null,
        viewSource: null,
    });
    const [upload, setUpload] = useState(false);
    const [image, setImage] = useState('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [cropData, setCropData] = useState<any>('');
    const [uploadProgress, setUploadProgress] = useState(0);
    const [statusOfScript, setStatus] = useState(!!document.getElementById(scriptId));
    const [isValidAddress, setIsValidAddress] = useState({
        locationAddress: true,
    });
    const [locationAddress, setLocationAddress] = useState('');
    const [validForm, setValidForm] = useState(isValidAddress);
    const [tipType, setTipType] = useState(defaultTipType);
    const [file, setFile] = useState<any>({ convertedFile: '', filename: '' });
    const [territories, setTerritories] = useState([]);
    const [selectedSaleTax, setSelectedSaleTax] = useState<{
        id: number;
    } | null>(null);

    const validationSchema = [
        //validation for step1
        Yup.object({
            location_image_name: Yup.string().required('This field is required'),
            location_name: Yup.string().required('This field is required'),
            city: Yup.string().required('This field is required'),
            street: Yup.string().required('This field is required'),
            postal_code: Yup.string().required('This field is required'),
        }).required(),

        //validation for step2
        Yup.object({
            territory: Yup.object().shape({
                label: Yup.string().required('This field is required'),
            }),
        }),

        //validation for step3
        Yup.object().shape({
            hours: Yup.array()
                .of(
                    Yup.object().shape({
                        day: Yup.string().required('Day is required').oneOf(['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'], 'Invalid day'),
                        status: Yup.boolean().required('Status is required'),

                        from_time_hours: Yup.string()
                            .required('From time hours are required')
                            .test({
                                name: 'from_valid_hours_1',
                                message: 'From hours must be between 01 to 12',
                                test: function (value) {
                                    const { status } = this.parent;
                                    if (status) {
                                        const numericValue = parseInt(value, 10);
                                        return !isNaN(numericValue) && numericValue >= 1 && numericValue <= 12;
                                    }
                                    return true;
                                },
                            })
                            .test({
                                name: 'from_valid_hours_2',
                                message: '"From" time must be less than "To" time',
                                test: function (value) {
                                    const {
                                        from_time_minute: fromTimeMinute,
                                        from_time_type: fromTimeType,
                                        to_time_hours: toTimeHours,
                                        to_time_minute: toTimeMinute,
                                        to_time_type: toTimeType,
                                        status,
                                    } = this.parent;

                                    if (status) {
                                        const fromMoment = moment(`${value}:${fromTimeMinute} ${fromTimeType}`, 'hh:mm a');
                                        const toMoment = moment(`${toTimeHours}:${toTimeMinute} ${toTimeType}`, 'hh:mm a');

                                        if (fromMoment.isSameOrAfter(toMoment)) {
                                            return false;
                                        } else {
                                            return true;
                                        }
                                    }
                                    return true;
                                },
                            }),

                        from_time_minute: Yup.string().required('From time minutes are required'),

                        to_time_hours: Yup.string()
                            .required('To time hours are required')
                            .test({
                                name: 'to_valid_hours_1',
                                message: 'To hours must be between 01 to 12',
                                test: function (value) {
                                    const { status } = this.parent;
                                    if (status) {
                                        const numericValue = parseInt(value, 10);
                                        return !isNaN(numericValue) && numericValue >= 1 && numericValue <= 12;
                                    }
                                    return true;
                                },
                            })
                            .test({
                                name: 'to_valid_hours_2',
                                message: '"To" time must be greater than "From" time',
                                test: function (value) {
                                    const {
                                        from_time_hours: fromTimeHours,
                                        from_time_minute: fromTimeMinute,
                                        from_time_type: fromTimeType,
                                        to_time_minute: toTimeMinute,
                                        to_time_type: toTimeType,
                                        status,
                                    } = this.parent;

                                    if (status) {
                                        const fromMoment = moment(`${fromTimeHours}:${fromTimeMinute} ${fromTimeType}`, 'hh:mm a');
                                        const toMoment = moment(`${value}:${toTimeMinute} ${toTimeType}`, 'hh:mm a');

                                        if (toMoment.isSameOrBefore(fromMoment)) {
                                            return false;
                                        } else {
                                            return true;
                                        }
                                    }
                                    return true;
                                },
                            }),

                        to_time_minute: Yup.string().required('To time minutes are required'),

                        from_time_type: Yup.string().required('From time type is required').oneOf(['am', 'pm'], 'Invalid from time type'),

                        to_time_type: Yup.string().required('To time type is required').oneOf(['am', 'pm'], 'Invalid to time type'),
                    }),
                )
                .required('Working hours are required'),
        }),

        //validation for step4
        Yup.object({
            tip: Yup.array()
                .test({
                    message: 'Please select at least one',
                    test: function (value) {
                        const { custom_tip } = this.parent;
                        if (!custom_tip && value && !value.some((obj) => obj?.checked === true)) {
                            return false;
                        }
                        return true;
                    },
                })
                .test({
                    message: 'Please choose a unique tip',
                    test: function (value) {
                        const { custom_tip } = this.parent;
                        if (custom_tip && value && value.some((obj) => obj?.checked && obj?.value === custom_tip)) {
                            return false;
                        }
                        return true;
                    },
                })
                .required(),
            custom_tip: Yup.string(),
        }).required(),
    ];

    const currentValidationSchema = validationSchema[step - 1] as Yup.ObjectSchema<IAddNewFormData | ISalesTaxesFormList, Record<string, any>, any, ''>;

    const schema: any = Yup.object().shape({
        location_image_name: Yup.string().required('This field is required'),
        location_name: Yup.string().required('This field is required'),
        city: Yup.string().required('This field is required'),
        street: Yup.string().required('This field is required'),
        postal_code: Yup.string().required('This field is required'),
        territory: Yup.object().shape({
            label: Yup.string().required('This field is required'),
        }),
        hours: Yup.array()
            .of(
                Yup.object().shape({
                    day: Yup.string().required('Day is required').oneOf(['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'], 'Invalid day'),
                    status: Yup.boolean().required('Status is required'),

                    from_time_hours: Yup.string()
                        .required('From time hours are required')
                        .test({
                            name: 'from_valid_hours_1',
                            message: 'From hours must be between 01 to 12',
                            test: function (value) {
                                const { status } = this.parent;
                                if (status) {
                                    const numericValue = parseInt(value, 10);
                                    return !isNaN(numericValue) && numericValue >= 1 && numericValue <= 12;
                                }
                                return true;
                            },
                        })
                        .test({
                            name: 'from_valid_hours_2',
                            message: '"From" time must be less than "To" time',
                            test: function (value) {
                                const {
                                    from_time_minute: fromTimeMinute,
                                    from_time_type: fromTimeType,
                                    to_time_hours: toTimeHours,
                                    to_time_minute: toTimeMinute,
                                    to_time_type: toTimeType,
                                    status,
                                } = this.parent;

                                if (status) {
                                    const fromMoment = moment(`${value}:${fromTimeMinute} ${fromTimeType}`, 'hh:mm a');
                                    const toMoment = moment(`${toTimeHours}:${toTimeMinute} ${toTimeType}`, 'hh:mm a');

                                    if (fromMoment.isSameOrAfter(toMoment)) {
                                        return false;
                                    } else {
                                        return true;
                                    }
                                }
                                return true;
                            },
                        }),

                    from_time_minute: Yup.string().required('From time minutes are required'),

                    to_time_hours: Yup.string()
                        .required('To time hours are required')
                        .test({
                            name: 'to_valid_hours_1',
                            message: 'To hours must be between 01 to 12',
                            test: function (value) {
                                const { status } = this.parent;
                                if (status) {
                                    const numericValue = parseInt(value, 10);
                                    return !isNaN(numericValue) && numericValue >= 1 && numericValue <= 12;
                                }
                                return true;
                            },
                        })
                        .test({
                            name: 'to_valid_hours_2',
                            message: '"To" time must be greater than "From" time',
                            test: function (value) {
                                const {
                                    from_time_hours: fromTimeHours,
                                    from_time_minute: fromTimeMinute,
                                    from_time_type: fromTimeType,
                                    to_time_minute: toTimeMinute,
                                    to_time_type: toTimeType,
                                    status,
                                } = this.parent;

                                if (status) {
                                    const fromMoment = moment(`${fromTimeHours}:${fromTimeMinute} ${fromTimeType}`, 'hh:mm a');
                                    const toMoment = moment(`${value}:${toTimeMinute} ${toTimeType}`, 'hh:mm a');

                                    if (toMoment.isSameOrBefore(fromMoment)) {
                                        return false;
                                    } else {
                                        return true;
                                    }
                                }
                                return true;
                            },
                        }),

                    to_time_minute: Yup.string().required('To time minutes are required'),

                    from_time_type: Yup.string().required('From time type is required').oneOf(['am', 'pm'], 'Invalid from time type'),

                    to_time_type: Yup.string().required('To time type is required').oneOf(['am', 'pm'], 'Invalid to time type'),
                }),
            )
            .required('Working hours are required'),
        tip: Yup.array()
            .test({
                message: 'Please select at least one',
                test: function (value) {
                    const { custom_tip } = this.parent;
                    if (!custom_tip && value && !value.some((obj) => obj?.checked === true)) {
                        return false;
                    }
                    return true;
                },
            })
            .test({
                message: 'Please choose a unique tip',
                test: function (value) {
                    const { custom_tip } = this.parent;
                    if (custom_tip && value && value.some((obj) => obj?.checked && obj?.value === custom_tip)) {
                        return false;
                    }
                    return true;
                },
            })
            .required(),
        custom_tip: Yup.string(),
    });

    const defaultValues = {
        location_image_name: '',
        location_name: '',
        city: '',
        street: '',
        postal_code: '',
        territory: {
            label: saleTaxes?.[0]?.territory,
            value: saleTaxes?.[0]?.territory,
        },
        gst: saleTaxes?.[0]?.gst || '',
        pst: saleTaxes?.[0]?.pst || '',
        hst: saleTaxes?.[0]?.hst || '',
        hours: workingHoursData,
        tip: [
            { value: '10', checked: false },
            { value: '15', checked: false },
            { value: '18', checked: false },
        ],
        custom_tip: '',
    };

    const methods = useForm({
        shouldUnregister: false,
        defaultValues,
        resolver: yupResolver(!seeDetails.open ? currentValidationSchema : schema),
        mode: 'onBlur',
        reValidateMode: 'onSubmit',
    });
    const { handleSubmit, trigger, setValue, setError, clearErrors, reset, getValues } = methods;

    const valid = () => {
        let isValid = true;
        const validFormData = {
            locationAddress: true,
        };

        if (formData.locationAddress.fullAddress === '' || formData.locationAddress.fullAddress === null) {
            validFormData.locationAddress = false;
            isValid = false;
        }

        setValidForm({ ...validForm, ...validFormData });
        return isValid;
    };

    const handleGeolocation = (open?: boolean) => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const latitude = position.coords.latitude;
                    const longitude = position.coords.longitude;
                    open
                        ? setFormData((prevFormData) => ({
                              ...prevFormData,
                              locationAddress: {
                                  ...prevFormData.locationAddress,
                                  address1: '',
                                  city: '',
                                  state: '',
                                  country: '',
                                  postal: '',
                                  fullAddress: '',
                                  latitude: latitude,
                                  longitude: longitude,
                              },
                          }))
                        : setFormData((prevFormData) => ({
                              ...prevFormData,
                              locationAddress: {
                                  ...prevFormData.locationAddress,
                                  latitude: latitude,
                                  longitude: longitude,
                              },
                          }));
                },
                (error) => {
                    if (error.code === error.PERMISSION_DENIED) {
                        // User denied geolocation permission.
                    }
                    // console.error(`Error getting location: ${error.message}`);
                },
            );
        } else {
            // console.error('Geolocation is not supported by this browser.');
        }
    };

    const clearState = async () => {
        await reset(defaultValues);
        await handleGeolocation(true);
        // setTerritories([]);
        setFile({ convertedFile: '', filename: '' });
        setLocationAddress('');
        setCropData('');
        setImage('');
        setUpload(false);
        setStep(1);
    };

    useEffect(() => {
        dispatch(locationListAction({ shop_id: shopInfo?.id }));
        dispatch(getSalesTaxAction());

        // intervalProgress = setInterval(() => {
        //     setUploadProgress((prev) => (prev + 1 < 100 ? prev + 1 : 100));
        // }, 10);

        // return () => {
        //     clearInterval(intervalProgress);
        // };
    }, []);

    useEffect(() => {
        handleGeolocation();
        valid();
    }, []);

    useEffect(() => {
        if (!document.getElementById(scriptId)) {
            const script = document.createElement('script');
            script.id = scriptId;
            script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places&callback=initMap`;
            script.async = true;
            script.defer = true;
            document.head.appendChild(script);
        }
    }, []);

    useEffect(() => {
        const filterList = listing?.filter((obj: any) => {
            const name = obj.name.toLowerCase();
            const searchParam = searchValue.toLowerCase();
            return name.includes(searchParam);
        });

        setFilterListing(filterList);
    }, [searchValue, listing]);

    useEffect(() => {
        setValue<'city'>('city', formData.locationAddress.city);
        setValue<'street'>('street', formData.locationAddress.address1);
        setValue<'postal_code'>('postal_code', formData.locationAddress.postal);
        clearErrors(['city', 'street', 'postal_code']);
    }, [formData]);

    useEffect(() => {
        if (saleTaxes?.length > 0) {
            setValue<'gst'>('gst', saleTaxes?.[0]?.gst);
            setValue<'pst'>('pst', saleTaxes?.[0]?.pst);
            setValue<'hst'>('hst', saleTaxes?.[0]?.hst);
            setValue<'territory'>('territory', {
                label: saleTaxes?.[0]?.territory,
                value: saleTaxes?.[0]?.territory,
            });
            const territory = saleTaxes?.map((obj: { territory: string }) => ({
                label: obj?.territory,
                value: obj?.territory,
            }));
            setTerritories(territory);
        }
    }, [saleTaxes]);

    useEffect(() => {
        setSelectedSaleTax(saleTaxes?.[0]);
    }, [filterListing]);

    useEffect(() => {
        if (seeDetails.open) {
            const filterList = filterListing?.find((obj: any) => obj?.id === seeDetails.shopId);
            setSeeDetails((prev: any) => ({ ...prev, viewSource: filterList }));

            const tips = getValues().tip?.map((obj1: any) => {
                const match = filterList?.tips?.find((obj2: any) => obj2 === obj1.value);
                return match ? { value: obj1.value, checked: true } : { value: obj1.value, checked: false };
            });

            const filterTips = filterList?.tips?.map((obj: { value: number; checked: boolean }) => ({
                value: obj,
            }));

            const customTip = filterTips?.filter((item1: any) => !getValues().tip.some((item2) => item2.value === item1.value));
            console.log('customTip', customTip);

            const custom_tip = customTip.length > 0 ? (filterList?.tips_type === 'percentage' ? `${customTip?.[0]?.value}%` : `$${customTip?.[0]?.value}`) : '';
            setTipType(filterList?.tips_type ?? defaultTipType);
            setValue<'custom_tip'>('custom_tip', custom_tip);
            setValue<'tip'>('tip', tips);
            setValue<'city'>('city', filterList.city);
            setValue<'street'>('street', filterList.street);
            setValue<'postal_code'>('postal_code', filterList.postal_code);
            setValue<'gst'>('gst', filterList?.sales_tax?.gst ?? '0.000');
            setValue<'pst'>('pst', filterList?.sales_tax?.pst ?? '0.000');
            setValue<'hst'>('hst', filterList?.sales_tax?.hst ?? '0.000');
            setValue<'territory'>('territory', {
                label: filterList?.sales_tax?.territory,
                value: filterList?.sales_tax?.territory,
            });
            setValue<'location_image_name'>('location_image_name', filterList.location_image_name);
            setValue<'location_name'>('location_name', filterList.name);
            setCropData(filterList.location_image_url);
            if (seeDetails.open) {
                setFormData((prevFormData) => ({
                    ...prevFormData,
                    locationAddress: {
                        ...prevFormData.locationAddress,
                        latitude: filterList.latitude,
                        longitude: filterList.longitude,
                        city: filterList.city,
                        address1: filterList.street,
                        postal: filterList.postal_code,
                        fullAddress: filterList.street,
                        country: filterList.country,
                    },
                }));
            }
            if (filterList.working_hours.length) {
                const existingWorkingHours: IWorkingHoursValue[] = filterList.working_hours.map((workingHour: any) => {
                    const momentFromTime = moment(workingHour.from, 'HH:mm:ss');
                    const momentToTime = moment(workingHour.to, 'HH:mm:ss');

                    const fromTimeHours = momentFromTime.format('hh');
                    const fromTimeMinute = momentFromTime.format('mm');
                    const fromTimeType = momentFromTime.format('a');

                    const toTimeHours = momentToTime.format('hh');
                    const toTimeMinute = momentToTime.format('mm');
                    const toTimeType = momentToTime.format('a');

                    return {
                        day: workingHour.day,
                        status: workingHour.status,
                        from_time_hours: fromTimeHours,
                        from_time_minute: fromTimeMinute,
                        to_time_hours: toTimeHours,
                        to_time_minute: toTimeMinute,
                        from_time_type: fromTimeType,
                        to_time_type: toTimeType,
                    };
                });
                setValue<'hours'>('hours', existingWorkingHours);
            }
        } else {
            clearState();
            setFormData(initFormData);
        }
    }, [seeDetails.open]);

    window.initMap = () => {
        setStatus(true);
    };

    const handleNext = async () => {
        const isStepValid = await trigger();

        if (isStepValid) setStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleLocation = (open: boolean) => {
        setNewLocationModal(open);
        if (!open) clearState();
    };

    const modifySubmitData = async (data: any) => {
        delete data.gst;
        delete data.pst;
        delete data.hst;
        const outputData = data.hours.map(
            (day: { from_time_hours: any; from_time_minute: any; from_time_type: any; to_time_hours: any; to_time_minute: any; to_time_type: any; day: any; status: any }) => {
                const fromtime12hour = `${day.from_time_hours}:${day.from_time_minute} ${day.from_time_type}`;
                const totime12hour = `${day.to_time_hours}:${day.to_time_minute} ${day.to_time_type}`;

                const fromtime24hour = moment(fromtime12hour, 'hh:mm a').format('HH:mm:ss');
                const totime24hour = moment(totime12hour, 'hh:mm a').format('HH:mm:ss');

                return {
                    day: day.day,
                    status: day.status,
                    from: fromtime24hour,
                    to: totime24hour,
                };
            },
        );
        const { tip, custom_tip, location_name, ...rest } = data;
        const filterTip = tip?.filter((obj: { checked: boolean }) => obj.checked).map((item: { value: number | string }) => item.value.toString());

        const tips = custom_tip ? [...filterTip, custom_tip.replace(/[%$]/g, '')] : [...filterTip];

        const payload = {
            ...rest,
            name: location_name,
            hours: outputData,
            latitude: formData?.locationAddress.latitude,
            longitude: formData?.locationAddress.longitude,
            country: formData?.locationAddress?.country,
            shop_id: shopInfo?.id,
            tips_type: tipType,
            tips,
            sales_tax_id: selectedSaleTax?.id,
        };
        if (file.convertedFile && file?.filename) {
            const imgUploadResult: any = await s3Upload(file?.convertedFile, `images/location_logos/${file?.filename}`);
            return { status: imgUploadResult.status, payload };
        } else {
            return { status: 201, payload };
        }
    };

    const onSubmit = async (data: any) => {
        setIsLoading(true);
        const { status, payload }: any = await modifySubmitData(data);
        if (status === errorCode.updateSuccess) {
            const result: any = await dispatch(createLocationAction(payload));
            if (result?.type === createLocationAction.fulfilled.toString()) {
                await dispatch(locationListAction({ shop_id: shopInfo?.id }));
                handleLocation(false);
            }
        }
        setIsLoading(false);
    };

    const gotoListing = (open: boolean, shopId: null | number) => () => {
        setSeeDetails((prev: any) => ({ ...prev, open, shopId }));
    };

    const chooseFile = (e: any) => {
        setUpload(false);
        e.preventDefault();
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        const { result, message } = checkFileTypeValidation(files, GLOBALVARIABLE.forNoValidation);
        if (result) {
            clearErrors('location_image_name');
            const reader = new FileReader();
            reader.onloadstart = () => {
                setImage('');
                setUpload(false);
            };
            reader.onloadend = () => {
                setImage(reader.result as any);
                setUpload(true);
            };
            reader.readAsDataURL(files[0]);
            e.target.value = null;
            setUploadProgress(0);
        } else {
            setError('location_image_name' as 'location_image_name', {
                type: 'manual',
                message: message,
            });
        }
    };

    const imageUpload = (data: any) => {
        const appLogoImage = data;
        const fileInfo = convertBase64ToFile(appLogoImage);
        setFile(fileInfo);
        if (fileInfo && fileInfo.filename) {
            setValue<'location_image_name'>('location_image_name', fileInfo.filename);
        }
    };

    const handleAutocompleteChange = (name: string) => (value: any) => {
        if (value.type === 'blur') {
            if (!formData.locationAddress.fullAddress && value.target.value) {
                setIsValidAddress((old) => ({ ...old, [name]: false }));
            }
            return;
        }
        if (name === 'locationAddress') {
            setLocationAddress(value);
            setIsValidAddress((old) => ({ ...old, [name]: true }));
        }
        setFormData((old) => ({ ...old, [name]: value }));
    };

    const renderStepComponent = () => {
        if (step === 1) {
            return (
                <AddNew
                    setStep={setStep}
                    chooseFile={chooseFile}
                    cropData={cropData}
                    uploadProgress={uploadProgress}
                    showProgressBar={upload}
                    handleClick={handleNext}
                    status={statusOfScript}
                    handleAutocompleteChange={handleAutocompleteChange}
                    validForm={validForm}
                    formData={formData}
                    locationAddress={locationAddress}
                    isValidAddress={isValidAddress}
                />
            );
        } else if (step === 2) {
            return <SalesTaxes setStep={setStep} handleClick={handleNext} territories={territories} saleTaxes={saleTaxes} setValue={setValue} setSelectedSaleTax={setSelectedSaleTax} />;
        } else if (step === 3) {
            return <OpeningHours setStep={setStep} handleClick={handleNext} />;
        } else if (step === 4) {
            return <TipsDetail setStep={setStep} handleClick={handleSubmit(onSubmit)} tipType={tipType} setTipType={setTipType} isLoading={isLoading} />;
        } else {
            return null;
        }
    };

    const STEPS: IStep[] = useMemo(
        () => [
            {
                name: StepperName.LocationDetails,
                step: 1,
            },
            {
                name: StepperName.SalesTax,
                step: 2,
            },
            {
                name: StepperName.OpeningHours,
                step: 3,
            },
            {
                name: StepperName.TipsDetails,
                step: 4,
            },
        ],
        [],
    );

    const stepperContent = (
        <FormProvider {...methods}>
            <form>
                <div className="mb-8 w-[480px] h-[46px] mx-auto">
                    <Stepper activeStep={step} steps={STEPS} />
                </div>
                {renderStepComponent()}
            </form>
        </FormProvider>
    );

    return (
        <div className="inner-page-wrape ">
            <WrapperHeading content="locations" />
            {!seeDetails.open ? (
                <div className="w-full flex-1 rounded-2xl bg-white p-6 mt-6 border-[1px] border-lineColor overflow-y-auto relative">
                    <div className="flex justify-between gap-6 mb-6">
                        <SearchBar onChange={(e: any) => setSearchValue(e.target.value)} />
                        <div className="">
                            <Button className={`w-[193px] h-[50px] px-6 py-2  bg-primary text-white font-bold rounded-[40px] hover:bg-blue-800`} onClick={() => handleLocation(true)}>
                                Add new location
                            </Button>
                        </div>
                    </div>
                    {!loading ? (
                        <div className="flex -mx-3 2xl:-mx-5 flex-wrap overflow-hidden overflow-x-hidden cursor-pointer">
                            {filterListing?.map((obj: any) => (
                                <LocationInfo onClick={gotoListing(true, obj?.id)} list={obj} key={obj?.id} />
                            ))}
                        </div>
                    ) : (
                        <BarLoader />
                    )}
                </div>
            ) : (
                <div className="w-[full] rounded-2xl bg-white mt-6 border-[1px] border-lineColor ">
                    <FormProvider {...methods}>
                        <Detail
                            handleClick={gotoListing}
                            chooseFile={chooseFile}
                            cropData={cropData}
                            uploadProgress={uploadProgress}
                            showProgressBar={upload}
                            scriptStatus={statusOfScript}
                            handleAutocompleteChange={handleAutocompleteChange}
                            validForm={validForm}
                            formData={formData}
                            locationAddress={locationAddress}
                            isValidAddress={isValidAddress}
                            tipType={tipType}
                            setTipType={setTipType}
                            trigger={trigger}
                            territories={territories}
                            saleTaxes={saleTaxes}
                            setValue={setValue}
                            setSelectedSaleTax={setSelectedSaleTax}
                            handleSubmit={handleSubmit}
                            gotoListing={gotoListing}
                            seeDetails={seeDetails}
                            modifySubmitData={modifySubmitData}
                        />
                    </FormProvider>
                </div>
            )}

            {upload ? (
                <CropperModal imageUpload={imageUpload} imageUrl={image} setUpload={setUpload} setCropData={setCropData} isCropType={false} defaultCropType="Rectangular" isLocation={true} />
            ) : (
                newLocationModal && <Popup size="max-w-[896px]" className="p-8 pb-10" handleClose={() => handleLocation(false)} content={stepperContent} />
            )}
        </div>
    );
};

export default Location;
