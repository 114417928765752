import React, { useEffect } from 'react';
import { RouteProps, Navigate } from 'react-router-dom';
import { selectUserInfo } from 'src/app/Auth/Login/Login.slice';
import DashboardLayout from 'src/app/Layout/Dashboard/DashboardLayout';
import NonLayout from 'src/app/Layout/NonLayout/NonGuard';
import SimpleLayout from 'src/app/Layout/Simple/SimpleLayout';
import Forbidden from 'src/components/Error/403';
import { APP_FULL_NAME, LayoutAction } from 'src/constants/common';
import { ROUTES } from 'src/constants/routes';
import { selectRoleInfo } from 'src/redux/RolePermission/RolePermission.slice';
import { useAppSelector } from 'src/redux/hooks';
import { handleNavigate } from 'src/utils/global-functions';

type GuardedRouteProps = {
    Component: React.ComponentType<any>;
    title?: string;
    layout?: LayoutAction;
    view?: string;
    permission?: string;
} & RouteProps;

const GuardedRoute: React.FC<GuardedRouteProps> = ({ Component, title, layout, view, permission, ...routeProps }): JSX.Element => {
    useEffect(() => {
        document.title = `${title} - ${APP_FULL_NAME}`;
    }, [title]);

    const user = useAppSelector(selectUserInfo);
    const navigateRouteName = user ? handleNavigate(user) : ROUTES.DASHBOARD;
    const role = useAppSelector(selectRoleInfo);

    const hasPermission = (permissionName: string) => role.permissions.includes(permissionName);
    console.log('permission', permission);

    return user && permission ? (
        hasPermission(permission) ? (
            layout === LayoutAction.NonLayout ? (
                <NonLayout>
                    <Component />
                </NonLayout>
            ) : layout === LayoutAction.Simple ? (
                <SimpleLayout>
                    <Component />
                </SimpleLayout>
            ) : navigateRouteName === ROUTES.NEW_PASSWORD || navigateRouteName === ROUTES.SIGNUP ? (
                <Navigate to={navigateRouteName} />
            ) : (
                <DashboardLayout>
                    <Component />
                </DashboardLayout>
            )
        ) : (
            <DashboardLayout>
                <Forbidden />
            </DashboardLayout>
        )
    ) : (
        <Navigate to={ROUTES.HOME} />
    );
};

export default GuardedRoute;
