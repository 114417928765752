import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { INotification } from './Notification.interface';
import NotificationCardComponent from './NotificationCardComponent';
import { API } from 'src/constants/api';
import { axiosGet } from 'src/utils/requestClient';
import { errorCode } from 'src/constants/errorCode';
import BarLoader from 'src/components/BarLoader';
import NotificationDrawers from './Drawers/NotificationDrawer';

interface IProps {
    handleClose: () => void;
    notificationButtonRef: any;
    isNewNotification: boolean;
    setIsNewNotification: Dispatch<SetStateAction<boolean>>;
    setIsShowNotificationModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const NotificationCard = ({ handleClose, notificationButtonRef, isNewNotification, setIsNewNotification, setIsShowNotificationModal }: IProps) => {
    const drawerRef = useRef(null);

    const [currentTab, setCurrentTab] = useState<'general' | 'appointment' | 'whatsNew'>('general');
    const [isNotificationsLoading, setIsNotificationsLoading] = useState(false);
    const [notificationsData, setNotificationsData] = useState<any[]>([]);

    useEffect(() => {
        if (isNewNotification) {
            loadNotification(currentTab);
            setIsNewNotification(false);
        }
    }, [isNewNotification]);
    useEffect(() => {
        if (currentTab === 'general' || currentTab === 'appointment') {
            loadNotification(currentTab);
        }
    }, [currentTab]);
    const [isShowViewAllNotificationDrawer, setIsShowViewAllNotificationDrawer] = useState(false);

    const [isShowViewWhatsNewAllDrawer, setIsShowViewWhatsNewAllDrawer] = useState(false);

    const loadNotification = async (type: string) => {
        try {
            setIsNotificationsLoading(true);

            const response = await axiosGet(
                API.NOTIFICATION.LIST,
                {},
                {
                    rows: 3,
                    filters: {
                        type: { value: type },
                    },
                },
            );
            if (response.data?.status === errorCode.success || response.data?.status === errorCode.updateSuccess) {
                setNotificationsData(response.data.data.data);
                return;
            }

            throw response.data;
        } catch (err: any) {
            console.log('err : ', err);
            toast.error(err?.message);
        } finally {
            setIsNotificationsLoading(false);
        }
    };

    useEffect(() => {
        loadNotification(currentTab);

        const handleClickOutside = (event: any) => {
            if (drawerRef.current && !((drawerRef.current as any).contains(event.target) || notificationButtonRef.current?.contains(event.target))) {
                handleClose();
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleCloseAll = () => {
        setIsShowNotificationModal(false);
        handleClose();
    };
    return (
        <div className="fixed right-5 md:right-[86px] z-20 top-16 w-[90%] md:w-[609px]" ref={drawerRef}>
            {!(isShowViewAllNotificationDrawer || isShowViewWhatsNewAllDrawer) && (
                <div className="bg-white shadow-[0px_5.3px_15.9px_0px_#928C9740] rounded-3xl ">
                    <div className="px-6 pt-6 rounded-md">
                        <div className="flex items-center gap-4 mb-4">
                            <div className="flex flex-wrap sm:flex-nowrap gap-1 w-full bg-inputBg rounded-full justify-between">
                                <div className="m-1 text-center xl:w-[100%] lg:w-[100%] w-[46%] sm:w-[48%]">
                                    <span
                                        onClick={() => setCurrentTab('general')}
                                        className={`tarcking-[-0.2px] block cursor-pointer py-3 h-full font-medium text-sm rounded-full  ${
                                            currentTab === 'general' ? 'bg-white text-black' : 'text-gray'
                                        }  `}
                                    >
                                        General
                                    </span>
                                </div>
                                <div className="m-1 text-center xl:w-[100%] lg:w-[100%] w-[46%] sm:w-[48%]">
                                    <span
                                        onClick={() => setCurrentTab('appointment')}
                                        className={`tarcking-[-0.2px] block cursor-pointer py-3 h-full font-medium text-sm rounded-full  ${
                                            currentTab === 'appointment' ? 'bg-white text-black' : 'text-gray'
                                        }  `}
                                    >
                                        Appointments
                                    </span>
                                </div>
                                {/* <div className="m-1 text-center xl:w-[100%] lg:w-[100%] w-[46%] sm:w-[48%]">
                                    <span
                                        onClick={() => setCurrentTab('whatsNew')}
                                        className={`tarcking-[-0.2px] block cursor-pointer py-3 h-full  font-medium text-sm rounded-3xl   ${
                                            currentTab === 'whatsNew' ? 'bg-white text-black' : 'text-gray'
                                        }`}
                                    >
                                        Whats New
                                    </span>
                                </div> */}
                            </div>
                        </div>

                        <div className="flex mt-4 md:my-4 justify-end md:justify-between items-center">
                            <h1 className={`hidden md:block h-full tracking-[-0.5px] font-bold text-xl text-black`}>
                                {currentTab === 'appointment' ? 'Appointments' : currentTab === 'general' ? 'General' : 'Whats New'}
                            </h1>

                            {notificationsData.length ? (
                                <p
                                    onClick={() => (currentTab === 'whatsNew' ? setIsShowViewWhatsNewAllDrawer(true) : setIsShowViewAllNotificationDrawer(true))}
                                    className="cursor-pointer text-sm md:text-base font-medium text-primary -tracking-[0.16px]"
                                >
                                    View All
                                </p>
                            ) : null}
                        </div>
                    </div>
                    {currentTab === 'general' && (
                        <div className="p-3 md:p-6 !pt-3 min-h-[200px] max-h-[480px] overflow-x-auto scrollbar-hide">
                            {isNotificationsLoading ? (
                                <div className="min-h-[248px]">
                                    <BarLoader isLoading={true} />
                                </div>
                            ) : notificationsData.length ? (
                                notificationsData.map((notificationRecord: INotification, index) => (
                                    <NotificationCardComponent
                                        key={`${notificationRecord.name}_${notificationRecord.id}`}
                                        className={`gap-3 rounded-xl mb-3 last:mb-0 flex h-full md:gap-6 p-[15px] md:p-6 items-center w-full border border-lineColor ${
                                            notificationsData.length === index + 1 ? 'index' : index + 1
                                        } `}
                                        isNegative={false}
                                        notificationRecord={notificationRecord}
                                        handleCloseAll={handleCloseAll}
                                    />
                                ))
                            ) : (
                                <div className="min-h-[248px] justify-center items-center flex">No notification</div>
                            )}
                        </div>
                    )}
                    {currentTab === 'appointment' && (
                        <div className="p-3 md:p-6 !pt-3 min-h-[200px] max-h-[480px] overflow-x-auto scrollbar-hide">
                            {isNotificationsLoading ? (
                                <div className="min-h-[248px]">
                                    <BarLoader isLoading={true} />
                                </div>
                            ) : notificationsData.length ? (
                                notificationsData.map((notificationRecord: INotification, index) => (
                                    <NotificationCardComponent
                                        key={`${notificationRecord.name}_${notificationRecord.id}`}
                                        className={`gap-3 rounded-xl flex h-full md:gap-6 p-[15px] md:p-6 items-center w-full border border-lineColor mb-3 ${
                                            notificationsData.length === index + 1 ? '' : 'border-b border-line'
                                        } `}
                                        isNegative={false}
                                        notificationRecord={notificationRecord}
                                        handleCloseAll={handleCloseAll}
                                    />
                                ))
                            ) : (
                                <div className="min-h-[248px] justify-center items-center flex">No notification</div>
                            )}
                        </div>
                    )}
                    {currentTab === 'whatsNew' && (
                        <div className="p-3 md:p-6 pt-0 min-h-[200px] max-h-[480px] overflow-x-auto scrollbar-hide">
                            <div className="min-h-[248px] justify-center items-center flex">No whatsNew</div>
                        </div>
                    )}
                </div>
            )}
            {/*     
                {isShowManageNotificationModal && (
                    <ManageNotificationModal
                        handleClose={() => setIsShowManageNotificationModal(false)}
                    />
                )} */}

            <NotificationDrawers isOpen={isShowViewAllNotificationDrawer} handleCloseAll={handleCloseAll} handleClose={() => setIsShowViewAllNotificationDrawer(false)} type={currentTab} />

            {/* <ViewWhatsNewAllDrawer
                    isOpen={isShowViewWhatsNewAllDrawer}
                    handleClose={() => setIsShowViewWhatsNewAllDrawer(false)}
                /> */}
        </div>
    );
};

export default NotificationCard;
