import React from 'react';
import { Images } from 'src/theme';
import { getShortName } from 'src/utils/global-functions';

const LocationInfo = ({ onClick, list }: any) => {
    const staffList = list?.shop_staff || [];
    const visibleStaff = staffList.slice(0, 4);
    const remainingCount = staffList.length - visibleStaff.length;

    return (
        <div className="w-1/2 px-3 2xl:px-5" onClick={onClick}>
            <div className="location-card p-3 mb-6">
                <div className="h-[130px] w-[130px] basis-[130px] grow-0 shrink-0  rounded-2xl  mr-3 overflow-hidden">
                    <img src={list?.location_image_url} alt={list?.location_image_name} className="h-full w-full object-cover" />
                </div>
                <div className="flex-auto overflow-hidden ">
                    <div className="mt-1 w-full">
                        <h2 className="text-base font-bold leading-[140%] -tracking-[0.4px] cursor-pointer">{list?.name}</h2>

                        <p className="truncate text-xs font-normal leading-[18px] -tracking-[0.3px] text-primary mt-2">
                            <img src={Images.mapLocationPin} alt="" className="w-[20px] inline basis-4 shrink-0 grow-0 mr-2" />
                            <span>
                                {list?.street}, {list?.city}, {list?.country}
                            </span>
                        </p>
                    </div>
                    <div className="flex mt-6 justify-between">
                        <div className="w-1/3">
                            <h3 className="text-xs font-normal leading-[18px] -tracking-[0.12px] text-secondaryTxtColor mb-2">Clients</h3>
                            <div className="flex items-center  text-xs font-[510] leading-[16.8px] -tracking-[0.12px] text-mainTextColor">
                                <img src={Images.groupLocation} alt="" className="w-4 inline mr-2 " />
                                <div className="text-xs">{list.total_users}</div>
                            </div>
                        </div>

                        <div className="w-1/3">
                            <h3 className="text-xs font-normal leading-[18px] -tracking-[0.12px] text-secondaryTxtColor mb-2">Tax District</h3>
                            <div className="flex items-center text-xs font-[510] leading-[16.8px] -tracking-[0.12px] text-mainTextColor">
                                <img src={Images.taxesLocation} alt="" className="w-4 inline mr-2 " />
                                <div className="text-xs">{list?.sales_tax?.territory ?? '-'}</div>
                            </div>
                        </div>

                        <div className="w-1/3 flex flex-col items-end">
                            <div className="">
                                <h3 className="text-xs font-normal leading-[18px] -tracking-[0.12px] text-secondaryTxtColor mb-[2px]">Staff</h3>
                                <div className="flex items-center ">
                                    <div className="flex justify-end sm:justify-start lg:justify-end xl:justify-start -space-x-2 -gap-[10px] ">
                                        {visibleStaff?.map((staff: any) =>
                                            staff?.profile_image_url ? (
                                                <img
                                                    key={staff?.id}
                                                    src={staff?.profile_image_url}
                                                    alt={staff?.profile_image}
                                                    width="48"
                                                    height="48"
                                                    className="w-7 h-7 rounded-full bg-gray-100 border-2 border-white"
                                                />
                                            ) : (
                                                <span className="w-7 h-7 rounded-full border-2 border-white flex justify-center items-center bg-primary text-white text-[10px] " key={staff?.id}>
                                                    {getShortName(`${staff.first_name ?? ''} ${staff.last_name ?? ''}`)}
                                                </span>
                                            ),
                                        )}
                                        <div className="w-7 h-7 rounded-full  bg-primary border-2 border-white text-white text-[10px] font-[510px] leading-[14px] -tracking-[0.1px] flex items-center justify-center">
                                            {remainingCount > 0 ? `+${remainingCount}` : remainingCount}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LocationInfo;
