import React from 'react';
import { Images } from 'src/theme';

const Sidebar = () => (
    <div className="bg-inputBg rounded-tl-3xl rounded-bl-3xl flex flex-col flex-auto justify-between overflow-hidden">
        <div className="content text-center mx-auto w-[580px] xl:w-[600px]  mt-[100px]">
            <h5 className="text-[24px] xl:text-[28px] text-mainTextColor font-semibold leading-[124%] -tracking-[0.9px] xl:-tracking-[1.7px]">An All-in-one platform to power your Barbershop</h5>
            <p className="text-secondaryTxtColor text-base xl:px-8 mt-5 leading-[140%] -tracking-[1px]">
                Take control of operations, increase revenue, and enhance your brand experience with a single, easy-to-use platform.
            </p>
        </div>

        <div className="mt-[40px] ml-10 xl:ml-16 flex-1">
            <img src={Images.DashboardIcon} alt="" className="max-w-none w-full h-full object-cover object-left-top" />
        </div>
    </div>
);

export default Sidebar;
