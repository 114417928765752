import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { API } from 'src/constants/api';
import { axiosPost } from 'src/utils/requestClient';
import { RootState } from 'src/redux/store';
import { errorCode } from 'src/constants/errorCode';
import { ErrorType, IInitialState } from './WorkingHours.interface';

const initialState: IInitialState = {
    data: null,
    loading: false,
    error: null,
};

export const locationWorkingHour = createAsyncThunk('/shop/working-hours', async (payload: any, { rejectWithValue }) => {
    try {
        const response = await axiosPost(API.USER.ADD_LOCATION_WORKING_HOURS, { hours: payload.hours }, { id: payload.id });
        if (response.data.status === errorCode.success || response.data.status === errorCode.updateSuccess) {
            return response.data;
        }

        return rejectWithValue(response as ErrorType);
    } catch (err: any) {
        if (!err.response) {
            throw err;
        }
        return rejectWithValue(err.response.data as ErrorType);
    }
});

export const WorkingHoursSlice = createSlice({
    name: 'shop/location/workingHours',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(locationWorkingHour.pending, (state) => {
                state.loading = true;
            })
            .addCase(locationWorkingHour.fulfilled, (state, { payload }: any) => {
                state.loading = false;
                state.data = payload;
            })
            .addCase(locationWorkingHour.rejected, (state, action: any) => {
                state.loading = false;
            });
    },
});
export const { reducer: locationWorkingHourReducer } = WorkingHoursSlice;

export const selectLocationWorkingHourData = (state: RootState) => state.LocationWorkingHours.data;
export const selectLoading = (state: RootState) => state.LocationWorkingHours.loading;
export const selectError = (state: RootState) => state.LocationWorkingHours.error;
