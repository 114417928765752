/* eslint-disable */
export const GLOBALVARIABLE = {
    accountImageSize: 4096,
    locationImageSize: 800,
    appearanceImageSize: 800,
    forNoValidation: 800000000,
};

export const ColorKeysToCheck = ['accent_color', 'button_color', 'cards_color', 'content_background', 'main_text_color', 'secondary_text_color'];

export const LightThemeCustomColorId = 6;

export const DarkThemeCustomColorId = 12;
