import { Dispatch, SetStateAction } from 'react';
import { ICountStep } from 'src/components/Stepper/Stepper.interface';
import { IFormData } from '../Signup/Location/Location.interface';

export interface IProps {
    setStep: Dispatch<SetStateAction<ICountStep | number>>;
    handleAddData?: (data: any) => void;
    handleLocation?: (open: boolean) => () => void;
    chooseFile?: any;
    cropData?: string;
    uploadProgress?: number;
    showProgressBar?: boolean;
    handleClick?: () => Promise<void>;
    status?: boolean;
    handleAutocompleteChange?: (name: string) => (value: any) => void;
    validForm?: {
        locationAddress: boolean;
    };
    formData?: IFormData;
    locationAddress?: string;
    isValidAddress?: {
        locationAddress: boolean;
    };
    values?: any;
    tipType?: string;
    setTipType?: Dispatch<SetStateAction<string>>;
    territories?: any;
    saleTaxes?: any;
    setValue?: any;
    setSelectedSaleTax?: any;
    isLoading?: boolean;
}

export interface IAddNewFormData {
    location_image_name: string;
    location_name: string;
    city: string;
    street: string;
    postal_code: string;
}
export interface ISalesTaxesFormList {
    territory: string;
    gst?: string;
    pst?: string;
    hst?: string;
}
export interface IDetail {
    handleClick: (open: boolean, shopId: null | number) => () => void;
    handleSubmit: any;
    handleAddData?: (data: any) => void;
    handleLocation?: (open: boolean) => () => void;
    chooseFile?: any;
    cropData?: string;
    uploadProgress?: number;
    showProgressBar?: boolean;
    scriptStatus?: boolean;
    handleAutocompleteChange?: (name: string) => (value: any) => void;
    validForm?: {
        locationAddress: boolean;
    };
    formData?: IFormData;
    locationAddress?: string;
    seeDetails: any;
    modifySubmitData: any;
    isValidAddress?: {
        locationAddress: boolean;
    };
    values?: any;
    tipType?: string;
    setTipType?: Dispatch<SetStateAction<string>>;
    trigger: any;
    territories?: any;
    saleTaxes?: any;
    setValue?: any;
    setSelectedSaleTax?: any;
    gotoListing: any;
}

export enum StepperName {
    LocationDetails = 'Location details',
    SalesTax = 'Sales Tax',
    OpeningHours = 'Opening hours',
    TipsDetails = 'Tips details',
}
