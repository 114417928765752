import { Label, Radio } from 'flowbite-react';
import React from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Button from 'src/components/Button';
import DateSelect from 'src/components/DateSelect/DateSelect';
import InputWithLabel from 'src/components/InputWithLabel';
import Toggle from 'src/components/Toggle/Toggle';
import { Images } from 'src/theme';
import { capitalizeFirstLetter } from 'src/utils/global-functions';

const PayStructure = ({ control, errors, watch, shop_payment, isLoading, setIsChangePayStructure, setSaveDetails, staffInfo }: any) => {
    const { t } = useTranslation();
    const payStructureTypeWatch = ['pay_structure_type'];
    const payStructureType = watch(payStructureTypeWatch);

    return (
        <div>
            <div className="mb-10 text-center ">
                <h3 className="fwpo_heading_title w-[480px] mx-auto">{t('Select pay structure')}</h3>
            </div>
            <Controller
                name={`pay_structure_type`}
                control={control}
                render={({ field: { onChange, value } }: any) => (
                    <>
                        <div className="flex flex-wrap -m-[10px]">
                            <div className="flex px-[10px] w-1/2">
                                <div className="flex w-full justify-between items-center px-5 py-6 border border-lineColor bg-white drop-shadow-cardShadow rounded-2xl custom-round-radio-block">
                                    <div className="flex items-center">
                                        <i className="w-[50px] h-[50px] bg-inputBg rounded-2xl flex items-center justify-center">
                                            <img src={Images.iconMoneyOutlinePrimary} alt="" className="w-[25px]" />
                                        </i>
                                        <span className="text-base font-bold leading-[140%] -tracking-[0.16px] ml-4">{t('Commission')}</span>
                                    </div>
                                    {''}
                                    <Radio className="custom-round-radio" id="commission" name="pay_structure_type" value={'commission'} onChange={onChange} checked={value === 'commission'} />
                                    <Label className="custom-round-label" htmlFor="commission"></Label>
                                </div>
                            </div>

                            <div className="flex px-[10px] w-1/2">
                                <div className="flex w-full justify-between items-center px-5 py-6 border border-lineColor bg-white drop-shadow-cardShadow rounded-2xl custom-round-radio-block">
                                    <div className="flex items-center">
                                        <i className="w-[50px] h-[50px] bg-inputBg rounded-2xl flex items-center justify-center">
                                            <img src={Images.iconRentOutlinePrimary} alt="" className="w-[25px]" />
                                        </i>
                                        <span className="text-base font-bold leading-[140%] -tracking-[0.16px] ml-4">{t('Rent')}</span>
                                    </div>
                                    {''}
                                    <Radio className="custom-round-radio" id="Rent" name="pay_structure_type" value={'rent'} onChange={onChange} checked={value === 'rent'} />
                                    <Label className="custom-round-label" htmlFor="Rent"></Label>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            />

            {payStructureType[0] === 'commission' ? (
                <div className="flex mt-8 -mx-[10px] mb-5">
                    <Controller
                        name="commission"
                        control={control}
                        render={({ field: { onChange, value } }: any) => (
                            <>
                                <div className="w-1/2 px-[10px] ">
                                    <InputWithLabel
                                        label={t('Enter staff comission')}
                                        placeholder={t('Please enter staff commission')}
                                        onChange={onChange}
                                        value={value}
                                        name="commission"
                                        type="number"
                                        error={!!errors.commission}
                                    />
                                    {errors && errors?.commission && <p className="text-error">{errors?.commission?.message}</p>}
                                </div>
                            </>
                        )}
                    />
                    <Controller
                        name="start_date"
                        control={control}
                        render={({ field: { onChange, value } }: any) => (
                            <>
                                <div className="w-1/2 px-[10px]">
                                    <DateSelect
                                        label={t('Employee start date')}
                                        inputName={t('Employee start date')}
                                        className={`form_control_date w-full form_input_control ${errors?.start_date && 'is-invalid'}`}
                                        placeholder="yyyy-mm-dd"
                                        dateFormat="yyyy-MM-dd"
                                        errorText={''}
                                        onChangeValue={onChange}
                                        icon={<img src={Images.iconDateCalendar} alt="" className="w-5 " />}
                                        value={value}
                                        minDate={new Date()}
                                        onChangeRaw={() => onchange}
                                    />
                                    {errors && errors?.start_date && <p className="text-error">{errors?.start_date?.message}</p>}
                                </div>
                            </>
                        )}
                    />
                </div>
            ) : (
                <>
                    <div className="px-[10px] flex justify-between mt-6 mb-5 w-full grow-0 shrink-0 basis-[50%]">
                        <Controller
                            name="auto_rent_payment"
                            control={control}
                            render={({ field: { onChange, value } }: any) => (
                                <>
                                    <div className="mr-[10px] w-[579px]">
                                        <label htmlFor="is_annette_rent" className=" font-bold text-base leading-[140%] -tracking-[0.14px] mb-1">
                                            {t('Automate rent payments')}
                                        </label>
                                        <p className="text-xs font-medium text-secondaryTxtColor leading-[140%] -tracking-[0.3px] mb-1">
                                            {t('automate_rent_payments_description', {
                                                first_name: staffInfo.first_name,
                                            })}
                                        </p>
                                    </div>
                                    <Toggle name="auto_rent_payment" id="auto_rent_payment" isChecked={value} onChange={onChange} labelClassName="mt-[3px]" switchClassName="bg-secondaryTxtColor" />
                                </>
                            )}
                        />
                    </div>
                    <div className="flex mt-8 -mx-[10px] mb-5">
                        <Controller
                            name="rent_money"
                            control={control}
                            render={({ field: { onChange, value } }: any) => (
                                <>
                                    <div className="w-1/2 px-[10px] ">
                                        <InputWithLabel
                                            label={t('Enter rent money')}
                                            placeholder={t('Please enter rent money')}
                                            onChange={onChange}
                                            value={value}
                                            name="rent_money"
                                            type="number"
                                            error={!!errors.rent_money}
                                        />

                                        {errors && errors?.rent_money && <p className="text-error">{errors?.rent_money?.message}</p>}
                                    </div>
                                </>
                            )}
                        />
                        <Controller
                            name="effective_date"
                            control={control}
                            render={({ field: { onChange, value } }: any) => (
                                <>
                                    <div className="w-1/2 px-[10px]">
                                        <DateSelect
                                            label={t('Rent Plan Start Date')}
                                            inputName="effective_date"
                                            className={`form_control_date w-full form_input_control ${errors?.effective_date && 'is-invalid'}`}
                                            placeholder="yyyy-mm-dd"
                                            dateFormat="yyyy-MM-dd"
                                            errorText={''}
                                            onChangeValue={onChange}
                                            icon={<img src={Images.iconDateCalendar} alt="" className="w-5 " />}
                                            value={value}
                                            minDate={new Date()}
                                            onChangeRaw={() => onchange}
                                        />
                                        {errors && errors?.effective_date && <p className="text-error">{errors?.effective_date?.message}</p>}
                                    </div>
                                </>
                            )}
                        />
                    </div>
                </>
            )}

            {payStructureType[0] === 'commission' ? (
                <>
                    <div>
                        <h3 className="text-base -tracking-[0.16px] leading-[140%] mb-1 flex items-center">
                            {t('Commission payout frequency')}: <span className="font-bold ml-2">{capitalizeFirstLetter(shop_payment?.commission_frequency)}</span>
                            <Link to="#" className="ml-2">
                                <img src={Images.iconEditOutlinePrimary} alt="" className="w-4 h-4" />
                            </Link>
                        </h3>
                        <p className="text-xs text-secondaryTxtColor leading-[140%] -tracking-[0.12px]">{t('commission_frequency_description')}</p>
                    </div>
                    <div className="flex mt-5 -mx-[10px] mb-5">
                        <div className="w-1/2 px-[10px] ">
                            <InputWithLabel
                                label={t('Commission payout frequency')}
                                placeholder={t('Commission payout frequency')}
                                value={shop_payment?.commission_frequency ?? ''}
                                disabled
                                onChange={() => {}}
                                name={''}
                            />
                        </div>
                        {(shop_payment?.commission_frequency === 'weekly' || shop_payment?.commission_frequency === 'bi-Weekly' || shop_payment?.commission_frequency === 'tri-Weekly') && (
                            <div className="px-[10px] w-1/2">
                                <InputWithLabel label={t('Payment day')} placeholder={t('Payment day')} value={shop_payment?.commission_payment_day ?? ''} disabled name={''} onChange={() => {}} />
                            </div>
                        )}
                    </div>
                    <div className="flex text-sm width-[832px] py-3 px-3 border rounded-3xl gap-2">
                        {t('staffdetail_pay_structure_commission_description', {
                            first_name: staffInfo.first_name,
                        })}
                    </div>
                </>
            ) : (
                <>
                    <div>
                        <h3 className="text-base -tracking-[0.16px] leading-[140%] mb-1 flex items-center">
                            {t('Rent collection frequency')}: <span className="font-bold ml-2">{capitalizeFirstLetter(shop_payment?.rent_frequency)}</span>
                            <Link to="#" className="ml-2">
                                <img src={Images.iconEditOutlinePrimary} alt="" className="w-4 h-4" />
                            </Link>
                        </h3>
                        <p className="text-xs text-secondaryTxtColor leading-[140%] -tracking-[0.12px]">{t('rent_frequency_description')}</p>
                    </div>
                    <div className="flex mt-5 -mx-[10px] mb-5">
                        <div className="w-1/2 px-[10px] ">
                            <InputWithLabel
                                label={t('Rent collection frequency')}
                                placeholder={t('Please enter rent collection frequency')}
                                value={shop_payment?.rent_frequency ?? ''}
                                disabled
                                onChange={() => {}}
                                name={''}
                            />
                        </div>{' '}
                        {(shop_payment?.rent_frequency === 'weekly' || shop_payment?.rent_frequency === 'bi-Weekly' || shop_payment?.rent_frequency === 'tri-Weekly') && (
                            <div className="px-[10px] w-1/2">
                                <InputWithLabel label={t('Payment day')} placeholder={t('Payment day')} value={shop_payment?.rent_day ?? ''} disabled name={''} onChange={() => {}} />
                                <p className="text-xs text-secondaryTxtColor leading-[140%] -tracking-[0.12px]">{t('The default payment day is the first day of the month.')}</p>
                            </div>
                        )}
                    </div>
                    <div className="flex text-sm width-[832px] py-3 px-3 border rounded-3xl gap-2">
                        {t('staffdetail_pay_structure_rent_description', {
                            first_name: staffInfo.first_name,
                        })}
                    </div>
                </>
            )}

            <div className="flex w-full px-10 justify-center mt-14">
                <Button
                    // className="fl-btn btn_primary w-[420px] mt-5"
                    className="fl-btn btn_primary w-[420px] absolute bottom-6 p-4"
                    type="button"
                    onClick={() => {
                        setIsChangePayStructure(false);
                        setSaveDetails(true);
                    }}
                    isLoading={isLoading}
                >
                    {t('Save Changes')}
                </Button>
            </div>
        </div>
    );
};

export default PayStructure;
