import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import EmptyMsgWithBtn from 'src/components/EmptyMsgWithBtn';
import { Images } from 'src/theme';
import { capitalizeFirstLetterSpace, formatName, getShortName } from 'src/utils/global-functions';

const StaffPerfomance = ({ handleViewClick, staffData }: any) => {
    const [errorMap, setErrorMap] = useState(new Map());
    const addStaff = () => {};
    const { t } = useTranslation();

    return (
        <div className="bg-white rounded-3xl border border-lineColor h-full p-4">
            <div className="flex items-center mb-5">
                <h3 className="text-xl font-bold -tracking-[0.4px]">{t('Staff Perfomance')}</h3>
                <button type="button" onClick={handleViewClick('staffPerfomance')} className="text-primary ml-auto -tracking-[0.3] font-medium">
                    {t('View all')}
                </button>
            </div>

            {staffData.length > 0 ? (
                <div className="-mx-4">
                    <table className="fl-table w-full">
                        <thead>
                            <tr>
                                <th>{t('Professional')}</th>
                                <th>{t('Bookings')}</th>
                                <th>{t('Earnings')}</th>
                                <th>{t('Productivity')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {staffData.map((item: any) => (
                                <tr key={item.id}>
                                    <td>
                                        <div className="flex items-center">
                                            <figure className="w-[38px] h-[38px] basis-[38px] grow-0 shrink-0 rounded-full overflow-hidden mr-3 flex text-xs font-medium justify-center items-center bg-primary text-white">
                                                {item.profile_image_url && !errorMap.get(item.id) ? (
                                                    <img
                                                        src={item.profile_image_url}
                                                        alt="client"
                                                        className="w-full h-full object-cover"
                                                        onError={() => {
                                                            setErrorMap((prevErrorMap) => new Map(prevErrorMap.set(item.id, true)));
                                                        }}
                                                    />
                                                ) : (
                                                    getShortName(`${item.full_name}`)
                                                )}
                                            </figure>

                                            <div className="">
                                                <p className="text-sm font-medium mb-[2px] leading-[140%] -tracking-[0.12px]">{capitalizeFirstLetterSpace(`${item.full_name}`)}</p>
                                                <p className="text-xs font-medium leading-[150%] -tracking-[0.12px] text-secondaryTxtColor">{item.role.name} </p>
                                            </div>
                                        </div>
                                    </td>
                                    <td>{item.bookings_count}</td>
                                    <td>${item.bookings_sum_total ?? 0}</td>
                                    <td>
                                        <div className="flex items-center">
                                            {item.productivity}%{' '}
                                            <i className="icon ml-1">
                                                <img src={Images.IconArrowUp} alt="" className="w-[18px]" />
                                            </i>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            ) : (
                <EmptyMsgWithBtn title={t('No Staff Performance Data')} description={t('No_Staff_Performance_Data_description')} btnLabel={t('Add staff')} onClick={addStaff} />
            )}
        </div>
    );
};
export default StaffPerfomance;
