import React, { FC, useState } from 'react';

import Number from 'src/components/Number';
import Button from 'src/components/Button';

import { useForm, Controller } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { CountryCode } from 'libphonenumber-js';
import { usePhone } from 'src/hooks/usePhone';
import { IForgotPassword, IForgotPasswordCredential, IForgotPasswordProps } from './ForgotPassword.interface';
import { forgotPassword, selectForgotPasswordLoading } from './ForgotPassword.slice';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { useTranslation } from 'react-i18next';
import { errorCode } from 'src/constants/errorCode';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'src/constants/routes';

const ForgotPassword: FC<IForgotPasswordProps> = ({ setForgotPasswordStep, setPhone, setCountryDigit, setFormatedPhoneNumber, setCountry }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const loading = useAppSelector(selectForgotPasswordLoading);

    const [countryCode, setcountryCode] = useState<CountryCode>('US');
    const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);
    const dispatch = useAppDispatch();

    const { isPhoneValid, setFormatPhone } = usePhone();
    const schema = Yup.object().shape({
        phone: Yup.string()
            .test('phone-validation', 'Invalid phone number', (value) => {
                try {
                    if (!value || value.trim() === '') return true;
                    return isPhoneValid(value, countryCode);
                } catch (error) {
                    return false;
                }
            })
            .required('Phone number is a required field'),
    });

    const handleNumberChange = (country: CountryCode, phone: string, code: string, isValid: boolean) => {
        setcountryCode(country);
        setCountryDigit(code);
        const formatedPhoneNumber = setFormatPhone(code, phone, country);
        setFormatedPhoneNumber(formatedPhoneNumber);
        if (isValid) {
            setIsButtonDisabled(!isValid);
        }
    };

    const {
        handleSubmit,
        control,
        formState: { errors },
        setError,
    } = useForm<IForgotPassword>({
        resolver: yupResolver(schema),
        defaultValues: {
            phone: '',
        },
    });

    const handleChange = async (data: IForgotPassword) => {
        const cread: IForgotPasswordCredential = {
            phone: data.phone,
            phone_country_code: countryCode,
        };
        const result = await dispatch(forgotPassword(cread));

        if (result.type === forgotPassword.fulfilled.toString()) {
            setForgotPasswordStep('otp');
            setPhone(data.phone);
            setCountry(countryCode);
        }
        if (result.type === forgotPassword.rejected.toString()) {
            const response = result.payload.data.data;
            if (response.status === errorCode.unprocessable) {
                if (response.data) {
                    Object.keys(response.data).forEach((field) => {
                        setError('phone', {
                            type: 'manual',
                            message: t('Invalid phone number.'),
                        });
                    });
                } else {
                    Object.keys(response).forEach((field) => {
                        setError('phone', {
                            type: 'manual',
                            message: response.message,
                        });
                    });
                }
            }
        }
    };

    const [isButtonClicked, setIsButtonClicked] = useState(false);

    const handleButtonClick = () => {
        setIsButtonClicked(true);
    };

    return (
        <>
            <div className="content">
                <h5 className="mt-10 text-[32px] -tracking-[1.2px] leading-10 font-semibold">{t('Forgot Password?')}</h5>
                <p className="mt-4 opacity-70 -tracking-[0.4px] leading-[140%] ">{t('forgot_password_description')}</p>
                <div className="mt-10">
                    <form onSubmit={handleSubmit(handleChange)}>
                        <label htmlFor="phone" className="font-medium text-darkblue hover:shadow-none text-sm mb-2">
                            {t('Phone Number')}
                        </label>
                        <Controller
                            name="phone"
                            control={control}
                            render={({ field: { onChange } }: any) => (
                                <div className="flex flex-col mt-2">
                                    <Number getNumber={onChange} errors={errors} onNumberChange={handleNumberChange} isFocus={true} />
                                </div>
                            )}
                        />
                        <Button
                            disabled={isButtonDisabled || loading}
                            isLoading={loading}
                            className={`p-3 bg-primary text-white font-bold w-full mt-10 rounded-full hover:bg-blue-800 transition-all ease-in-out delay-75  ${
                                isButtonDisabled || loading ? 'opacity-50' : 'opacity-100'
                            }`}
                            onClick={handleButtonClick}
                            isButtonClicked={isButtonClicked}
                        >
                            {t('Reset Password')}
                        </Button>
                        {/* <Button className="p-3 text-mainTextColor text-sm font-normal w-full mt-5 border-2 border-mainTextColor rounded-full">
                        {t('Don’t have an account?')}
                        <span className="ms-1 text-primary font-bold">
                            {' '}
                            {t('Sign Up')}{' '}
                        </span>
                    </Button> */}

                        <Button className="mt-3 text-primary text-sm font-bold text-center w-full" onClick={() => navigate(ROUTES.HOME)} disabled={loading}>
                            {t('Back to login')}
                        </Button>
                    </form>
                </div>
            </div>
        </>
    );
};

export default ForgotPassword;
