import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Complete from 'src/assets/svgs/complete.svg';
import Button from 'src/components/Button';
import { ICountStep } from 'src/components/Stepper/Stepper.interface';

export interface IStripeConnectProps {
    setStep: (step: ICountStep) => void;
}

const StripeConnect: FC<IStripeConnectProps> = ({ setStep }) => {
    const cmpltredirect = () => {
        setStep(7);
    };
    const { t } = useTranslation();

    return (
        <>
            <div className="text-center">
                <img src={Complete} alt="" className="inline-block" />

                <h3 className=" text-[28px] font-bold -tracking-[0.336px] leading-[124%] mt-3 mb-[14px]">{t('Stripe Connected')}</h3>
                <p className="text-sm text-secondaryTxtColor -tracking-[0.14px] leading-[140%] mb-[30px]">{t('stripe_connected_description')}</p>
            </div>
            <Button onClick={cmpltredirect} className="fl-btn btn_primary">
                {t('Complete')}
            </Button>
        </>
    );
};

export default StripeConnect;
