import React from 'react';
import { formatKpiContentLable } from 'src/utils/global-functions';

const NetSalesIncome = ({ handleViewClick, netSalesIncomeDetails }: any) => (
    <>
        <div className="bg-white rounded-3xl border border-lineColor flex px-4">
            <div className="w-1/2 py-4 pe-8 border-r">
                <div className="flex justify-between items-center mb-6">
                    <p className="leading-[140%] -tracking-[0.3px] font-medium text-xs text-secondaryTxtColor">Total Gross Revenue:</p>
                    <p className="font-bold -tracking-[0.3]">${formatKpiContentLable(netSalesIncomeDetails.total) ?? 0}</p>
                </div>

                <div className="flex justify-between items-center mb-6">
                    <p className="leading-[140%] -tracking-[0.3px] font-medium text-xs text-secondaryTxtColor">Taxes:</p>
                    <p className="font-bold -tracking-[0.3]"> ${formatKpiContentLable(netSalesIncomeDetails.taxes) ?? 0}</p>
                </div>

                <div className="flex justify-between items-center mb-6">
                    <p className="leading-[140%] -tracking-[0.3px] font-medium text-xs text-secondaryTxtColor">Tips:</p>
                    <p className="font-bold -tracking-[0.3]">${formatKpiContentLable(netSalesIncomeDetails.tips) ?? 0}</p>
                </div>

                <div className="flex justify-between items-center">
                    <p className="leading-[140%] -tracking-[0.3px] font-medium text-xs text-secondaryTxtColor">Discounts:</p>
                    <p className="font-bold -tracking-[0.3]">$0.00</p>
                </div>
            </div>

            <div className="w-1/2 py-4 ps-8">
                <div className="flex flex-col justify-center items-center h-full">
                    <p className="-tracking-[0.3] font-medium text-xs text-center mb-5">Net Sales Income</p>
                    <h3 className="text-[28px] font-bold leading-[140%] -tracking-[0.5px] text-center">${formatKpiContentLable(netSalesIncomeDetails.net_sales_income) ?? 0}</h3>
                </div>
            </div>
        </div>
    </>
);

export default NetSalesIncome;
