import React, { useEffect, useState } from 'react';
import { selectUserInfo } from 'src/app/Auth/Login/Login.slice';
import InputWithLabel from 'src/components/InputWithLabel';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { Images } from 'src/theme';
import { initAction, selectCalendarData, setData } from '../Calendar.slice';
import Button from 'src/components/Button';
import { Link } from 'react-router-dom';
import { Label } from 'flowbite-react';
import { IPaymentMethod, IPaymentState, IPaymentValidation } from '../Calendar.Interface';
import { HiXMark } from 'react-icons/hi2';
import { useTranslation } from 'react-i18next';
import { axiosGet } from 'src/utils/requestClient';
import { API } from 'src/constants/api';
import { errorCode } from 'src/constants/errorCode';
import { toast } from 'react-toastify';
import { capitalizeFirstLetterSpace } from 'src/utils/global-functions';

const PaymentData = ({ className }: { className?: string }) => {
    const user: any = useAppSelector(selectUserInfo);
    const calendarData: any = useAppSelector(selectCalendarData);
    const bookedSlotInfo = calendarData.bookingInfo;
    const [tip, setTip] = useState<number>(0);
    const [paymentState, setPaymentState] = useState<IPaymentState>({ payments: [] });
    const [chargeAmountInput, setChargeAmountInput] = useState<number>(bookedSlotInfo.total);
    const [totalPayable, setTotalPayable] = useState<number>(bookedSlotInfo.total);
    const [remainingCharge, setRemainingCharge] = useState<number>(bookedSlotInfo.total);
    const [returnAmount, setReturnAmount] = useState<number>(0);
    const [isTipCollapse, setIsTipCollapse] = useState<boolean>(true);
    const [otherPaymentNote, setOtherPaymentNote] = useState<string>('');
    const [isOtherPayment, setIsOtherPayment] = useState<boolean>(false);
    const [clientInfo, setClientInfo] = useState<any>(null);
    const dispatch = useAppDispatch();
    const shopId = user.shops[0].id;
    const { t } = useTranslation();

    const initValidation: IPaymentValidation = {
        paymentMethod: {
            status: false,
            message: '',
        },
        tip: {
            status: false,
            message: '',
        },
        returnAmount: {
            status: false,
            message: '',
        },
        chargeAmount: {
            status: false,
            message: '',
        },
        otherPaymentMethod: {
            status: false,
            message: '',
        },
    };
    const [errors, setErrors] = useState<IPaymentValidation>(initValidation);
    useEffect(() => {
        if (bookedSlotInfo && bookedSlotInfo.created_by === 'user') {
            addPayment({
                type: bookedSlotInfo.payment_type === 'stripe' ? 'card' : bookedSlotInfo.payment_type,
                amount: chargeAmountInput,
            });
        }
    }, bookedSlotInfo);
    useEffect(() => {}, [returnAmount]);
    const addPayment = (newPayment: IPaymentMethod) => {
        setPaymentState((prevState) => {
            const paymentIndex = prevState.payments.findIndex((payment) => payment.type === newPayment.type);
            if (paymentIndex !== -1) {
                if (!newPayment.amount) {
                    return {
                        ...prevState,
                        payments: prevState.payments.filter((_, i) => i !== paymentIndex),
                    };
                }
                return {
                    ...prevState,
                    payments: prevState.payments.map((payment, index) => {
                        if (index === paymentIndex) {
                            const updatedAmount = (Number(payment.amount) || 0) + Number(newPayment.amount);
                            return { ...payment, amount: updatedAmount };
                        }
                        return payment;
                    }),
                };
            } else {
                if (newPayment.amount) {
                    return {
                        ...prevState,
                        payments: [...prevState.payments, newPayment],
                    };
                }
                return prevState;
            }
        });
    };
    const updateError = (type: any, status: boolean, message: string = '') => {
        setErrors((currentErrors) => ({
            ...currentErrors,
            [type]: {
                status: status,
                message: message,
            },
        }));
    };
    const isPaymentMethodAvailable = (type: string) => paymentState.payments.some((payment) => payment.type === type);
    const handleCalculation = async () => {
        const collectedAmount = paymentState.payments.reduce((acc, payment) => Number(acc) + Number(payment.amount), 0);
        const remainingAmount = Number(Number(totalPayable - collectedAmount).toFixed(2));

        await setChargeAmountInput(remainingAmount >= 0 ? remainingAmount : 0);
        setRemainingCharge(remainingAmount);
    };

    const handleReturnAmountCalculation = async (event: any) => {
        const value = Number(event.target.value);
        const collectedAmount = paymentState.payments.reduce((acc, payment) => Number(acc) + Number(payment.amount), 0);
        const remainingAmount = Number(calendarData.bookingInfo.total) - Number(collectedAmount);
        if (remainingAmount <= 0) {
            let maxReturnAmount = Number(Number(Math.abs(remainingAmount)).toFixed(2));
            const cashAmount = Number(paymentState.payments.filter((payment) => payment.type === 'cash')[0].amount);
            console.log('maxReturnAmount < value || cashAmount < value', maxReturnAmount < value, cashAmount < value);
            console.log('maxReturnAmount , value , cashAmount', maxReturnAmount, value, cashAmount);

            if (maxReturnAmount < value || cashAmount < value) {
                const updateMaxReturnAmount = maxReturnAmount > cashAmount ? cashAmount : maxReturnAmount;
                updateError('returnAmount', true, `Please do not exceed the maximum return amount of $${updateMaxReturnAmount}.`);
            } else {
                updateError('returnAmount', false);
            }
            const updatedTipAmount = Number(Number(maxReturnAmount - value).toFixed(2));
            setTip(updatedTipAmount >= 0 ? updatedTipAmount : 0);
            setReturnAmount(value);
        }
    };

    const handleTipWithoutCalculation = (value: any, isFixed: boolean = false) => {
        let tipAmount = value;
        if (isFixed && bookedSlotInfo.location.tips_type !== 'value') {
            tipAmount = Number(((Number(calendarData.bookingInfo.total) * Number(tipAmount)) / 100).toFixed(2));
        }
        if (tipAmount >= 0) {
            const collectedAmount = paymentState.payments.reduce((acc, payment) => Number(acc) + Number(payment.amount), 0);
            const remainingAmount = Number(calendarData.bookingInfo.total) - collectedAmount;

            if (!isPaymentMethodAvailable('cash')) {
                if (remainingAmount < 0 && Math.abs(remainingAmount) > tipAmount) {
                    updateError('tip', true, `Please enter a minimum tip of $${Math.abs(remainingAmount)}`);
                } else {
                    updateError('tip', false);
                }
            }
            if (isPaymentMethodAvailable('cash')) {
                if (remainingAmount < 0) {
                    let maxReturnAmount = Math.abs(remainingAmount);
                    const cashAmount = paymentState.payments.filter((payment) => payment.type === 'cash')[0].amount;
                    const updatedMaxReturnAmount = maxReturnAmount - cashAmount;
                    if (updatedMaxReturnAmount > tipAmount) {
                        updateError('tip', true, `Please enter a minimum tip of $${Math.abs(updatedMaxReturnAmount)}`);
                    } else {
                        updateError('tip', false);
                    }
                }
            }

            const updatedTipAmount = Number(Number(Math.abs(remainingAmount) - tipAmount).toFixed(2));

            setReturnAmount(updatedTipAmount >= 0 ? updatedTipAmount : 0);
            setTip(tipAmount);
        }
    };
    const handleTipCalculation = async (isvalidate = true) => {
        updateError('tip', false);
        updateError('returnAmount', false);
        const collectedAmount = paymentState.payments.reduce((acc, payment) => Number(acc) + Number(payment.amount), 0);
        const remainingAmount = Number(Number(totalPayable - collectedAmount).toFixed(2));

        if (!isPaymentMethodAvailable('cash')) {
            if (remainingAmount < 0) {
                setTip(Number(tip) + Number(Math.abs(remainingAmount)));
            }
            setReturnAmount(0);
        }
        if (isPaymentMethodAvailable('cash')) {
            if (remainingAmount < 0) {
                const remaiinngAmount = Math.abs(remainingAmount);
                const cashAmount = paymentState.payments.filter((payment) => payment.type === 'cash')[0].amount;
                if (remaiinngAmount > cashAmount) {
                    setReturnAmount(cashAmount);
                    setTip(remaiinngAmount - cashAmount);
                } else {
                    setReturnAmount(remaiinngAmount);
                }
            }
        }
    };
    useEffect(() => {
        handleCalculation();
        handleTipCalculation();
    }, [paymentState.payments]);
    useEffect(() => {
        handleCalculation();
    }, [totalPayable]);
    const handleBlurInput = (event: any) => {
        const value = event.target.value;
        const name = event.target.name;
        if (!value) {
            name === 'tip' && setTip(0);
            name === 'return_amount' && setReturnAmount(0);
            name === 'charge_amount' && setChargeAmountInput(0);
        }
    };
    useEffect(() => {
        if (tip >= 0) {
            setTotalPayable(Number((Number(calendarData.bookingInfo.total) + Number(tip)).toFixed(2)));
            setPaymentState((prevState) => ({
                ...prevState,
                tip: Number(tip),
            }));
        } else {
            updateError('tip', true, 'error message');
        }
    }, [tip]);

    const removePayment = (index: number) => {
        setPaymentState((prevState) => ({
            ...prevState,
            payments: prevState.payments.filter((_, i) => i !== index),
        }));
    };
    const handlePaymenttype = (event: any) => {
        if (event.target.value) {
            addPayment({
                type: event.target.value,
                amount: chargeAmountInput,
            });
        }
    };
    const handleOtherPaymentPopup = (status: boolean) => () => setIsOtherPayment(status);

    useEffect(() => {
        const getClientCard = async () => {
            let payload = {
                shop_id: shopId,
                id: bookedSlotInfo.id,
            };

            try {
                const response = await axiosGet(API.BOOKING.GET, payload, {});
                if (response.data?.status === errorCode.success || response.data?.status === errorCode.updateSuccess) {
                    console.log('response', response.data.data.user.card);
                    setClientInfo(response.data.data.user);
                    // setDefaultCard(response.data.data.user.card);
                    return;
                }

                throw response.data;
            } catch (err: any) {
                toast.error(err?.message);
            } finally {
            }
        };
        if (bookedSlotInfo && shopId) {
            getClientCard();
        }
    }, [bookedSlotInfo]);
    const handleBackButton = async () => {
        const updateState = {
            action: { ...initAction, bookedAppointment: true },
        };
        await dispatch(setData(updateState));
    };

    const isDisable = (types: string[], isInclude: boolean = true) => {
        const typeCondition = paymentState.payments.some((payment) => (isInclude ? types.includes(payment.type) : !types.includes(payment.type)));
        const amountCondition = chargeAmountInput > 0 ? false : true;
        return typeCondition || amountCondition ? true : false;
    };

    const handleOtherPaymentNote = (event: any) => {
        if (event.target.value) {
            updateError('otherPaymentMethod', false);
            // setErrors((prev) => ({ ...prev, otherPaymentMethod: false }));
        }
        setOtherPaymentNote(event.target.value);
    };
    const handleSubmitForm = async () => {
        const paymentInfo: any = paymentState.payments
            .map((payment: { amount: number; type: string }) => {
                if (payment.type === 'card') {
                    return { ...payment, type: 'stripe' };
                } else if (payment.type === 'cash' && payment.amount - returnAmount > 0) {
                    return { ...payment, amount: payment.amount - returnAmount };
                } else if (payment.type === 'cash') {
                    return null; // Mark for exclusion
                } else {
                    return payment;
                }
            })
            .filter((payment) => payment !== null);
        const updateState = {
            payments: { ...paymentState, payments: paymentInfo, extra_payment_method: otherPaymentNote },
            action: { ...initAction, completedPaymentAppointment: true },
        };
        await dispatch(setData(updateState));
    };

    const handleChargeAmount = (event: any) => {
        const newValue = event.target.value;
        if (newValue >= 0) {
            setChargeAmountInput(newValue);
            updateError('chargeAmount', false);

            // setErrors((prev) => ({ ...prev, chargeAmount: false }));
        }
    };

    const handleTipCollapse = (value: boolean) => {
        setIsTipCollapse(value);
    };

    const handleOthterPayment = () => {
        if (isOtherPayment && otherPaymentNote && chargeAmountInput) {
            addPayment({
                type: 'other',
                amount: chargeAmountInput,
            });
            setIsOtherPayment(false);
        } else {
            updateError('otherPaymentMethod', true, 'otherPaymentMethodotherPaymentMethod');
        }
    };

    return (
        <>
            <div className="p-4 flex items-center">
                <Link className="border border-lineColor rounded-lg p-1 mr-3" to="" onClick={handleBackButton}>
                    <img src={Images.IconChevronRight} alt="" className="" />
                </Link>

                <h4 className="text-base font-bold leading-[140%] -tracking-[0.16px]">Payment</h4>
            </div>

            <div className={`p-4 flex-1 overflow-y-auto ${className}`}>
                <div className="flex font-bold leading-[140%] -tracking-[0.14px] mb-3 pb-3 border-b border-lineColor border-dashed">
                    <p className="">Subtotal</p>
                    <p className="ml-auto">${calendarData.bookingInfo.subtotal}</p>
                </div>
                <div className="flex font-medium leading-[140%] -tracking-[0.14px] mb-4 pb-3 border-b border-lineColor ">
                    <p className="">Tax and Fees</p>
                    <p className="ml-auto">${calendarData.bookingInfo.taxes_and_fees}</p>
                </div>
                {tip > 0 ? (
                    <div className="flex font-medium leading-[140%] -tracking-[0.14px] mb-4 pb-3 border-b border-lineColor ">
                        <p className="">Tip</p>
                        <p className="ml-auto">${tip}</p>
                    </div>
                ) : (
                    ''
                )}
                <div className="flex text-xl font-bold leading-[140%] -tracking-[0.2px] mb-3">
                    <p className="">Total</p>
                    <p className="ml-auto">${totalPayable}</p>
                </div>

                {paymentState.payments.map((item: IPaymentMethod, index: number) => (
                    <div key={index} className="flex text-base font-bold leading-[140%] -tracking-[0.4px] mb-5">
                        <p className="text-secondaryTxtColor font-normal">{capitalizeFirstLetterSpace(item.type)}</p>
                        <div className="ml-auto flex items-center">
                            <HiXMark className="w-5 h-5 cursor-pointer text-dangerText" onClick={() => removePayment(index)} />
                            <p className="ml-3">${item.amount}</p>
                        </div>
                    </div>
                ))}
                <div className="flex text-base font-bold leading-[140%] -tracking-[0.4px] mb-5">
                    <h3 className="font-bold ">Amount to charge</h3>
                    <div className="ml-auto flex items-center">
                        <p className="ml-3">${remainingCharge >= 0 ? remainingCharge : 0}</p>
                    </div>
                </div>

                <div className="mb-4">
                    <InputWithLabel
                        label="Enter the amount to charge"
                        onBlur={handleBlurInput}
                        labelClassName="text-xs"
                        placeholder="$0.00"
                        onChange={handleChargeAmount}
                        value={chargeAmountInput}
                        name="charge_amount"
                    />
                    {errors?.chargeAmount.status && <p className="text-error">{errors?.chargeAmount.message}</p>}
                </div>
                {!isOtherPayment && (
                    <>
                        <div className="mb-3 bg-inputBg p-1 flex rounded-full">
                            <button className={`w-1/2  rounded-full p-3 cursor-pointer ${isTipCollapse && 'bg-white'}`} onClick={() => handleTipCollapse(true)}>
                                <p className={`text-center font-medium ${isTipCollapse ? 'text-mainTextColor' : 'text-secondaryTxtColor'}`}>Tip Amount</p>
                            </button>
                            <button
                                disabled={!isPaymentMethodAvailable('cash')}
                                className={`w-1/2 rounded-full p-3 ${isPaymentMethodAvailable('cash') ? 'cursor-pointer' : 'cursor-not-allowed'} ${!isTipCollapse && 'bg-white'}`}
                                onClick={() => handleTipCollapse(false)}
                            >
                                <p className={`text-center font-medium ${!isTipCollapse ? 'text-mainTextColor' : 'text-secondaryTxtColor'}`}>Return Amount</p>
                            </button>
                        </div>
                        {isTipCollapse && (
                            <div className="mb-8">
                                <InputWithLabel
                                    label="Tip amount"
                                    labelClassName="text-xs"
                                    type="text"
                                    placeholder="$0.00"
                                    onChange={(e: any) => handleTipWithoutCalculation(e.target.value)}
                                    value={tip}
                                    name="tip"
                                    error={errors?.tip.status}
                                    onBlur={handleBlurInput}
                                />
                                {errors?.tip.status && <p className="text-error">{errors?.tip.message}</p>}

                                <div className="flex my-4 -mx-[4px]">
                                    {bookedSlotInfo.location.tips.length > 0 &&
                                        bookedSlotInfo.location.tips.map((item: any) => (
                                            <span
                                                key={item}
                                                className="flex text-xs bg-inputBg leading-[150%] -tracking-[0.12px] rounded-full py-[3px] px-[10px] cursor-pointer mx-1"
                                                onClick={() => handleTipWithoutCalculation(item, true)}
                                            >
                                                {bookedSlotInfo.location.tips_type === 'value' && '$'}
                                                {item}
                                                {bookedSlotInfo.location.tips_type !== 'value' && '%'}
                                            </span>
                                        ))}
                                </div>
                                {remainingCharge < 0 && (
                                    <p className=" text-mainTextColor">
                                        Please return <span className=" font-extrabold">${Math.abs(returnAmount)}</span> to {bookedSlotInfo.user.first_name}
                                    </p>
                                )}
                            </div>
                        )}

                        {!isTipCollapse && (
                            <div className="mb-8">
                                <InputWithLabel
                                    label="Return amount"
                                    labelClassName="text-xs"
                                    type="text"
                                    placeholder="$0.00"
                                    onChange={handleReturnAmountCalculation}
                                    value={returnAmount}
                                    error={errors?.returnAmount.status}
                                    name="return_amount"
                                    onBlur={handleBlurInput}
                                />
                                {errors?.returnAmount.status && <p className="text-error">{errors?.returnAmount.message}</p>}

                                {tip > 0 && (
                                    <p className=" text-mainTextColor mt-1">
                                        {bookedSlotInfo.user.first_name} has tipped you <span className=" font-extrabold">${tip}</span>
                                    </p>
                                )}
                            </div>
                        )}
                        <p className="flex font-bold leading-[140%] -tracking-[0.14px] mb-4">Please select the payment method</p>
                        <div className="flex -mx-1 flex-wrap">
                            <Label
                                htmlFor="cashPayment"
                                className={`px-1 w-1/2 mb-1 payment-radio-block ${Number(chargeAmountInput) > 0 ? 'cursor-pointer' : 'cursor-not-allowed'} ${
                                    isPaymentMethodAvailable('cash') && 'active'
                                }`}
                            >
                                <input
                                    type="button"
                                    id="cashPayment"
                                    disabled={!(Number(chargeAmountInput) > 0)}
                                    name="paymentMethod"
                                    className="payment-radio"
                                    value={'cash'}
                                    onClick={handlePaymenttype}
                                />
                                <div className="payment-radio-content">
                                    <div className={`text-center ${!(Number(chargeAmountInput) > 0) && 'opacity-50'}`}>
                                        <img src={Images.IconBankNote} alt="" className="w-6 inline-block" />
                                        <p className="font-medium leading-[140%] -tracking-[0.16px] mt-[6px]">Cash</p>
                                    </div>
                                </div>
                            </Label>
                            <Label
                                htmlFor="frontDeskPayment"
                                className={`px-1 w-1/2 mb-1 payment-radio-block  ${isDisable(['card']) ? 'cursor-not-allowed' : 'cursor-pointer'} ${isPaymentMethodAvailable('pos') && 'active'}`}
                            >
                                <input type="button" id="frontDeskPayment" name="paymentMethod" className="payment-radio" disabled={isDisable(['card'])} value={'pos'} onClick={handlePaymenttype} />
                                <div className="payment-radio-content">
                                    <div className={`text-center ${isDisable(['card']) && 'opacity-50'}`}>
                                        <img src={Images.IconKiosk} alt="" className="w-6 inline-block" />
                                        <p className="font-medium leading-[140%] -tracking-[0.16px] mt-[6px]">Front Desk</p>
                                    </div>
                                </div>
                            </Label>
                            {clientInfo?.card && (
                                <Label
                                    htmlFor="creditCardPayment"
                                    className={`px-1 w-1/2 mb-1 payment-radio-block ${isDisable(['pos']) ? 'cursor-not-allowed' : 'cursor-pointer'} ${isPaymentMethodAvailable('card') && 'active'}`}
                                >
                                    <input
                                        type="button"
                                        id="creditCardPayment"
                                        name="paymentMethod"
                                        disabled={isDisable(['pos'])}
                                        className="payment-radio"
                                        value={'card'}
                                        onClick={handlePaymenttype}
                                    />
                                    <div className="payment-radio-content">
                                        <div className={`text-center ${isDisable(['pos']) && 'opacity-50'}`}>
                                            <img src={Images.IconCreditCard} alt="" className="w-6 inline-block" />
                                            <p className="font-medium leading-[140%] -tracking-[0.16px] mt-[6px]">Credit Card</p>
                                            <p className="text-[10px] text-secondaryTxtColor font-medium leading-[12px] -tracking-[0.01px] mt-[2px]">⭑⭑⭑⭑ - ⭑⭑⭑⭑ - ⭑⭑⭑⭑ -{clientInfo?.card?.last4}</p>
                                        </div>
                                    </div>
                                </Label>
                            )}
                            {clientInfo?.total_loyalty_amount ? (
                                clientInfo?.total_loyalty_amount >= totalPayable ? (
                                    <Label
                                        htmlFor="loyalPointPayment"
                                        className={`px-1 w-1/2 mb-1 cursor-pointer payment-radio-block ${isDisable(['cash', 'pos', 'other', 'card']) ? 'cursor-not-allowed' : 'cursor-pointer'} ${
                                            isPaymentMethodAvailable('loyalty') && 'active'
                                        }`}
                                    >
                                        <input
                                            type="button"
                                            id="loyalPointPayment"
                                            disabled={bookedSlotInfo.user.total_loyalty_points === 0 || isDisable(['cash', 'pos', 'other', 'card'])}
                                            name="paymentMethod"
                                            className="payment-radio"
                                            value={'loyalty'}
                                            onClick={handlePaymenttype}
                                        />
                                        <div className="payment-radio-content">
                                            <div className={`text-center ${bookedSlotInfo.user.total_loyalty_points === 0 || isDisable(['cash', 'pos', 'other', 'card']) ? 'opacity-50' : ''}`}>
                                                <img src={Images.IconGift} alt="" className="w-6 inline-block" />
                                                <p className="font-medium leading-[140%] -tracking-[0.16px] mt-[6px]">Loyalty points</p>
                                                <p className="text-[10px] text-secondaryTxtColor font-medium leading-[12px] -tracking-[0.01px] mt-[2px]">
                                                    Available: {bookedSlotInfo.user.total_loyalty_points} points
                                                </p>
                                            </div>
                                        </div>
                                    </Label>
                                ) : (
                                    ''
                                )
                            ) : (
                                ''
                            )}

                            <Label htmlFor="otherPayment" className="px-1 w-full mb-1 payment-radio-block cursor-pointer">
                                <input
                                    type="button"
                                    id="otherPayment"
                                    disabled={isDisable(['loyalty'])}
                                    name="paymentMethod"
                                    className="payment-radio"
                                    value={'other'}
                                    onClick={handleOtherPaymentPopup(true)}
                                />
                                <div className="payment-radio-content">
                                    <div className={`text-center ${isDisable(['loyalty']) && 'opacity-50'}`}>
                                        <img src={Images.IconOther} alt="" className="w-6 inline-block" />
                                        <p className="font-medium leading-[140%] -tracking-[0.16px] mt-[6px]">Other</p>
                                    </div>
                                </div>
                            </Label>
                        </div>
                    </>
                )}

                {isOtherPayment && (
                    <>
                        {' '}
                        <h3 className="font-bold w-full">Other payment method</h3>
                        <div className="flex text-base font-bold leading-[140%] -tracking-[0.4px] mb-5">
                            <InputWithLabel
                                label={`Type in an optional reference for $${chargeAmountInput}:`}
                                labelClassName="text-xs"
                                placeholder="e.g. Interact, Venmo"
                                onChange={handleOtherPaymentNote}
                                value={otherPaymentNote}
                                error={errors?.otherPaymentMethod.status}
                                name="otherPayment"
                            />
                        </div>
                        {errors?.otherPaymentMethod.status && <p className="text-error">{errors?.otherPaymentMethod.message}</p>}
                        <div className="data-wrap-block max-w-[512px] 2xl:max-w-[700px]">
                            <div className="flex -mx-3 mt-5">
                                <div className="px-3 w-1/2">
                                    <Button className="btn_outline_black w-full justify-center" onClick={handleOtherPaymentPopup(false)}>
                                        {'Cancel'}
                                    </Button>
                                </div>
                                <div className="px-3 w-1/2">
                                    <Button onClick={handleOthterPayment} className="fl-btn btn_primary w-full">
                                        Add
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
            <div className="">
                <Button
                    type="button"
                    onClick={handleSubmitForm}
                    disabled={remainingCharge > 0 || Object.values(errors).some((option) => option.status)}
                    className="fl-btn btn_primary rounded-none rounded-t-3xl"
                >
                    Complete Payment
                </Button>
            </div>
        </>
    );
};
export default PaymentData;
