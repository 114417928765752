import React, { useEffect, useState } from 'react';
import { HiOutlineMinusSm, HiOutlinePlusSm } from 'react-icons/hi';
import { HiXMark } from 'react-icons/hi2';
import { TfiPencilAlt } from 'react-icons/tfi';
import InputWithLabel from 'src/components/InputWithLabel';
import Toggle from 'src/components/Toggle/Toggle';
import { useAppSelector } from 'src/redux/hooks';
import { selectCalendarData } from './Calendar.slice';
import { format } from 'date-fns';
const ServiceDetail = ({ handleRemoveService, index, serviceInfo, handleQtyChange, handleCustomPriceChange, handleIsCustomPrice, isUpdate = false, isWaitList = false }: any) => {
    const [customPrice, setCustomPrice] = useState(serviceInfo.is_custom_price);
    const calendarData: any = useAppSelector(selectCalendarData);

    const handleToggle = (e: any) => {
        handleIsCustomPrice(!customPrice, index);
        setCustomPrice(!customPrice);
    };

    const [quantity, setQuantity] = useState(Number(serviceInfo.qty));
    const [customPriceValue, setCustomPriceValue] = useState(serviceInfo.price);
    const handleQtyIncrement = () => {
        setQuantity((prevQuantity) => prevQuantity + 1);
    };
    useEffect(() => {
        setCustomPriceValue(serviceInfo.price);
    }, [serviceInfo]);
    const handleQtyDecrement = () => {
        if (quantity > 1) {
            setQuantity((prevQuantity) => prevQuantity - 1);
        }
    };
    const handleCustomPriceValue = (event: any) => {
        setCustomPriceValue(event.target.value);
        handleCustomPriceChange(event.target.value, index);
    };
    const handleQtyChangeInternal = (newQuantity: number) => {
        setQuantity(newQuantity);

        if (handleQtyChange) {
            handleQtyChange(newQuantity, index);
        }
    };
    useEffect(() => {
        if (handleQtyChange) {
            handleQtyChange(quantity, index);
        }
    }, [quantity]);

    // useEffect(() => {
    //     if (handleCustomPriceChange) {
    //         handleCustomPriceChange(customPrice, customPriceValue, index);
    //     }
    // }, [customPrice, customPriceValue]);

    return (
        <div className={`border-b ${index === 0 && 'border-t'} py-4 px-4 -mx-4 relative`}>
            <div className="flex items-center relative">
                <h4 className="font-bold justify-center ">{serviceInfo.name}</h4>

                {calendarData.action.newAppointment || calendarData.action.updateAppointment || calendarData.action.rescheduleAppointment || isUpdate ? (
                    <HiXMark size={20} onClick={handleRemoveService} className="text-dangerText cursor-pointer ml-auto" />
                ) : (
                    <span className="ml-auto text font-bold">${Number(serviceInfo.qty) * Number(serviceInfo.price)}</span>
                )}
            </div>

            {calendarData.action.newAppointment || calendarData.action.updateAppointment || calendarData.action.rescheduleAppointment || isUpdate ? (
                <>
                    <div className="w-full flex h-[32px] my-2 ">
                        <button
                            className="w-[32px] h-full basis-[32px] grow-0 shrink-0 bg-secondaryTxtColor flex justify-center items-center text-xl leading-6 rounded-l-md text-white cursor-pointer"
                            onClick={handleQtyDecrement}
                            type="button"
                        >
                            <HiOutlineMinusSm />
                        </button>
                        <div className="w-full h-full flex-1 max-w-[38px]">
                            <input type="text" className="w-full border-0 h-full text-center bg-inputBg" value={quantity} onChange={(e) => handleQtyChangeInternal(Number(e.target.value))} />
                        </div>
                        <button
                            className="w-[32px] h-full basis-[32px] grow-0 shrink-0 bg-secondaryTxtColor flex justify-center items-center text-xl leading-6 rounded-r-md text-white cursor-pointer"
                            onClick={handleQtyIncrement}
                            type="button"
                        >
                            <HiOutlinePlusSm />
                        </button>
                    </div>

                    <div className="flex mb-3 items-center">
                        <div className="flex-1 mr-2">
                            <Toggle label="Custom price" labelPosition="left" id="custom_price" isChecked={customPrice} onChange={handleToggle} className="" switchClassName="bg-secondaryTxtColor" />
                        </div>
                        <div className="w-[150px] basis-[150px] grow-0 shrink-0">
                            <InputWithLabel type="number" placeholder="Price" onChange={handleCustomPriceValue} value={customPriceValue} name="custom_price" disabled={!customPrice} />
                        </div>
                    </div>
                </>
            ) : (
                <div className="text-xs text-secondaryTxtColor  mb-2">
                    {' '}
                    Qty {serviceInfo.qty} X ${serviceInfo.price}
                </div>
            )}

            <div className="font-medium mb-2 flex items-center -tracking-[0.14px]">
                <span className="text-secondaryTxtColor leading-[140%]  inline-block font-normal w-[120px]">Barber:</span>
                <span className="">{serviceInfo.staff_name}</span>
            </div>
            {isWaitList ? (
                <>
                    <div className="font-medium mb-2 flex items-center -tracking-[0.14px]">
                        <span className="text-secondaryTxtColor leading-[140%]  inline-block font-normal w-[120px]">Requested date:</span>
                        <span className="">{format(new Date(serviceInfo.time_slot), 'eee ,MMM d')}</span>
                    </div>
                    <div className="font-medium mb-2 flex items-center -tracking-[0.14px]">
                        <span className="text-secondaryTxtColor leading-[140%]  inline-block font-normal w-[120px]">Requested time:</span>
                        <span className="">Anytime</span>
                    </div>
                </>
            ) : (
                <>
                    <div className="font-medium mb-2 flex items-center -tracking-[0.14px]">
                        <span className="text-secondaryTxtColor leading-[140%]  inline-block font-normal w-[120px]">At:</span>
                        <span className="">{serviceInfo.time_slot}</span>
                    </div>
                    <div className="font-medium flex items-center -tracking-[0.14px]">
                        <span className="text-secondaryTxtColor leading-[140%]  inline-block font-normal w-[120px]">For:</span>
                        <span className="flex">
                            {serviceInfo.duration}
                            {calendarData.action.updateAppointment && <TfiPencilAlt className="ml-2 text-primary cursor-pointer" size={16} />}
                        </span>
                    </div>
                </>
            )}
        </div>
    );
};
export default ServiceDetail;
