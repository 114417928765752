import React, { useEffect, useState } from 'react';
import SelectBox from 'src/components/SelectBox/SelectBox';
import Toggle from 'src/components/Toggle/Toggle';
import * as Yup from 'yup';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { selectShopInfo, shopDetail } from 'src/app/Auth/Login/Login.slice';
import { INotifications } from './Notifications.interface';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import { API } from 'src/constants/api';
import { axiosPatch } from 'src/utils/requestClient';
import { errorCode } from 'src/constants/errorCode';
import { useTranslation } from 'react-i18next';
type FieldKey = 'first_reminder' | 'second_reminder';

const Notifications = () => {
    const { t } = useTranslation();
    const [hours] = useState([
        { label: '1 hours', value: 1 },
        { label: '2 hours', value: 2 },
        { label: '3 hours', value: 3 },
        { label: '6 hours', value: 6 },
        { label: '8 hours', value: 8 },
        { label: '12 hours', value: 12 },
        { label: '16 hours', value: 16 },
        { label: '24 hours', value: 24 },
        { label: '48 hours', value: 48 },
        { label: '72 hours', value: 72 },
    ]);
    const shop: any = useAppSelector(selectShopInfo);
    const shopId = shop.id;
    const [defaultValues, setDefaultValues] = useState<INotifications>({
        first_reminder: hours[0],
        second_reminder: hours[0],
        is_first_reminder: false,
        is_second_reminder: false,
    });
    const schema = Yup.object().shape({
        first_reminder: Yup.object({
            label: Yup.string().required(),
            value: Yup.number().required(),
        }).required(),
        second_reminder: Yup.object({
            label: Yup.string().required(),
            value: Yup.number().required(),
        }).required(),
        is_first_reminder: Yup.boolean().required(),
        is_second_reminder: Yup.boolean().required(),
    });

    useEffect(() => {
        // Update defaultValues when shop state changes
        setDefaultValues({
            first_reminder: shop?.first_reminder ? hours.find((item: any) => item.value === shop.first_reminder) || hours[0] : hours[0],
            second_reminder: shop?.second_reminder ? hours.find((item: any) => item.value === shop.second_reminder) || hours[0] : hours[0],
            is_first_reminder: shop?.is_first_reminder ?? false,
            is_second_reminder: shop?.is_second_reminder ?? false,
        });
    }, [shop]); // useEffect dependency on shop

    const {
        handleSubmit,
        control,
        formState: { errors },
        setError,
        reset,
    } = useForm<INotifications>({
        resolver: yupResolver(schema),
        defaultValues,
    });
    const dispatch = useAppDispatch();
    useEffect(() => {
        reset(defaultValues);
    }, [defaultValues]);

    const handleChange =
        (type: any = null) =>
        async (data: any) => {
            let isFirstReminder = data.is_first_reminder;
            let isSecondReminder = data.is_second_reminder;
            if (type === 'is_first_reminder') {
                if (!isFirstReminder) {
                    isSecondReminder = false;
                }
            } else if (type === 'is_second_reminder') {
                if (isSecondReminder) {
                    isFirstReminder = true;
                }
            }
            const payload = {
                ...data,
                first_reminder: data.first_reminder.value,
                second_reminder: data.second_reminder.value,
                is_first_reminder: isFirstReminder,
                is_second_reminder: isSecondReminder,
            };

            try {
                const response = await axiosPatch(API.NOTIFICATION.REMINDER, payload, {
                    shop_id: shopId,
                });
                if (response.data?.status === errorCode.success || response.data?.status === errorCode.updateSuccess) {
                    await dispatch(shopDetail());
                    return;
                }

                throw response.data;
            } catch (err: any) {
                if (err.status === errorCode.unprocessable) {
                    if (err.data) {
                        Object.keys(err.data).forEach((field) => {
                            setError(field as FieldKey, {
                                type: 'manual',
                                message: err.data[field][0],
                            });
                        });
                    }
                    return;
                }
                toast.error(err?.message);
            } finally {
            }
        };
    return (
        <div className="w-[full] rounded-2xl bg-white border-[1px] border-lineColor">
            <div className="detail-page-title-block">
                <h2 className="text-xl font-bold leading-[140%] -tracing-[0.2px]">{t('Notifications')}</h2>
            </div>

            <form onSubmit={handleSubmit(handleChange)}>
                <div className="detail-page-data-block border-0">
                    <div className="title-block">
                        <h3 className="title-h3">{t('Reminder timeframe')}</h3>
                        <p className="text-xs text-secondaryTxtColor leading-[150%] -tracking-[0.12px]">{t('Reminder_timeframe_description')}</p>
                    </div>
                    <div className="data-wrap-block max-w-[512px] 2xl:max-w-[700px]">
                        <div className="flex flex-wrap mb-5">
                            <div className="flex-1">
                                <Controller
                                    name="first_reminder"
                                    control={control}
                                    render={({ field: { onChange, value } }: any) => (
                                        <div className="w-full">
                                            <SelectBox
                                                name="first_reminder"
                                                label={t('First reminder')}
                                                options={hours}
                                                onChangeFunc={(e: any) => {
                                                    onChange(e);
                                                    handleSubmit(handleChange(null))();
                                                }}
                                                value={value}
                                            />
                                            {errors.first_reminder && <p className="text-error">{errors.first_reminder.message}</p>}
                                        </div>
                                    )}
                                />
                            </div>
                            <div className="ml-8 ">
                                <Controller
                                    name="is_first_reminder"
                                    control={control}
                                    render={({ field: { onChange, value } }: any) => (
                                        <div className="w-full">
                                            <Toggle
                                                id="is_first_reminder"
                                                labelPosition="left"
                                                isChecked={value}
                                                onChange={(event: any) => {
                                                    onChange(event.target.checked);
                                                    handleSubmit(handleChange('is_first_reminder'))();
                                                }}
                                                switchClassName="bg-secondaryTxtColor"
                                                labelClassName="mt-10"
                                            />
                                            {errors.is_first_reminder && <p className="text-error">{errors.is_first_reminder.message}</p>}
                                        </div>
                                    )}
                                />
                            </div>
                        </div>
                        <div className="flex flex-wrap">
                            <div className="flex-1">
                                <Controller
                                    name="second_reminder"
                                    control={control}
                                    render={({ field: { onChange, value } }: any) => (
                                        <div className="w-full">
                                            <SelectBox
                                                name="second_reminder"
                                                label={t('Second reminder')}
                                                options={hours}
                                                onChangeFunc={(e: any) => {
                                                    onChange(e);
                                                    handleSubmit(handleChange(null))();
                                                }}
                                                value={value}
                                            />
                                            {errors.second_reminder && <p className="text-error">{errors.second_reminder.message}</p>}
                                        </div>
                                    )}
                                />
                            </div>
                            <div className="ml-8 ">
                                <Controller
                                    name="is_second_reminder"
                                    control={control}
                                    render={({ field: { onChange, value } }: any) => (
                                        <div className="w-full">
                                            <Toggle
                                                id="is_second_reminder"
                                                labelPosition="left"
                                                isChecked={value}
                                                onChange={(event: any) => {
                                                    onChange(event.target.checked);
                                                    handleSubmit(handleChange('is_second_reminder'))();
                                                }}
                                                switchClassName="bg-secondaryTxtColor"
                                                labelClassName="mt-10"
                                            />
                                            {errors.is_second_reminder && <p className="text-error">{errors.is_second_reminder.message}</p>}
                                        </div>
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default Notifications;
