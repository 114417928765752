import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import ThemeProvider from './hooks/useTheme';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import MainRoutes from './routes/MainRoutes';
import './assets/css/app.css';
import 'react-toastify/dist/ReactToastify.css';
import ToastBodyContainer from './components/ToastContainer';

const App = () => (
    <div className="h-full w-full bg-background relative">
        <Provider store={store}>
            <ThemeProvider>
                <ToastBodyContainer />
                <BrowserRouter>
                    <MainRoutes />
                </BrowserRouter>
            </ThemeProvider>
        </Provider>
    </div>
);

export default App;
