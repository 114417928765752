/* eslint-disable @typescript-eslint/no-shadow */
import React, { useEffect, useRef, useState } from 'react';

interface MapProps {
    address?: any;
    id?: string;
    onReverseAddressFunc?: any;
    draggable?: boolean;
    height?: number;
    isCurrentLocation?: boolean;
}

const initialMapAddressVal = {
    fullAddress: '',
    address1: '',
    city: '',
    state: '',
    country: '',
    postal: '',
    latitude: null,
    longitude: null,
};

const Map: React.FC<MapProps> = ({ address, id, onReverseAddressFunc, draggable, height, isCurrentLocation = false }) => {
    const mapRef = useRef(null);
    const [initialize, setInitaize] = useState(isCurrentLocation);
    let latitude = parseFloat(address.latitude);
    let longitude = parseFloat(address.longitude);
    const initializeMap = () => {
        if (!Number.isNaN(latitude) && !Number.isNaN(longitude)) {
            const mapOptions = {
                center: { lat: latitude, lng: longitude },
                zoom: 15,
                gestureHandling: 'cooperative',
            };

            const map = new window.google.maps.Map(mapRef.current, mapOptions);

            const marker = new window.google.maps.Marker({
                position: { lat: latitude, lng: longitude },
                map: map,
                draggable: draggable,
            });
            if (initialize) {
                const position = marker.getPosition();
                const lat = position.lat();
                const lng = position.lng();
                getAddress(lat, lng);
                setInitaize(false);
            }
            window.google.maps.event.addListener(marker, 'dragend', () => {
                const position = marker.getPosition();
                const lat = position.lat();
                const lng = position.lng();
                getAddress(lat, lng);
            });
        }
    };

    const getAddress = (lat: number, lng: number) => {
        const geocoder = new window.google.maps.Geocoder();

        geocoder.geocode({ location: { lat, lng } }, (results: any, status: any) => {
            if (status === 'OK') {
                if (results[0]) {
                    const addressObject = results[0];
                    latitude = addressObject.geometry.location.lat();
                    longitude = addressObject.geometry.location.lng();
                    let city = '';
                    let state = '';
                    let postcode = '';
                    let address = '';
                    let country = '';
                    let stateCode = '';
                    let countryCode = '';

                    addressObject.address_components.forEach((component: any) => {
                        const componentType = component.types;

                        if (componentType.includes('premise')) {
                            address += `${component.long_name} `;
                        } else if (componentType.includes('street_number')) {
                            address += `${component.long_name} `;
                        } else if (componentType.includes('route')) {
                            address += `${component.short_name} `;
                        } else if (componentType.includes('sublocality')) {
                            address += `${component.long_name} `;
                        } else if (componentType.includes('postal_code')) {
                            postcode = `${component.long_name}${postcode}`;
                        } else if (componentType.includes('postal_code_suffix')) {
                            postcode = `${postcode}-${component.long_name}`;
                        } else if (componentType.includes('locality')) {
                            city = component.long_name;
                        } else if (componentType.includes('administrative_area_level_1')) {
                            state = component.long_name;
                            stateCode = component.short_name;
                        } else if (componentType.includes('country')) {
                            country = component.long_name;
                            countryCode = component.short_name;
                        }
                    });

                    const query = addressObject.formatted_address;

                    let addressObj = {
                        fullAddress: query,
                        address1: address,
                        city: city,
                        state: state,
                        country: country,
                        postal: postcode,
                        latitude: latitude,
                        longitude: longitude,
                        stateCode: stateCode,
                        countryCode: countryCode,
                    };
                    onReverseAddressFunc(addressObj);
                } else {
                    onReverseAddressFunc(initialMapAddressVal);
                }
            } else {
                onReverseAddressFunc(initialMapAddressVal);
            }
        });
    };

    useEffect(() => {
        if (window.google && window.google.maps) {
            initializeMap();
        }
    }, [address]);

    return <div id={id} ref={mapRef} className="rounded-3xl h-[300px] bg-secondaryTxtColor overflow-hidden" style={{ width: '100%', height: `${height}px` }} />;
};

Map.defaultProps = {
    address: { latitude: 21.2496398, longitude: 72.8934363 },
    id: '',
    draggable: true,
    height: 300,
};

export default Map;
