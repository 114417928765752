import React, { useEffect, useState } from 'react';
import { Accordion } from 'flowbite-react';
import Checkbox from 'src/components/Checkbox';
import SearchBar from 'src/components/SearchBar/SearchBar';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
type GroupedService = {
    id: number;
    name: string;
    services: { id: number; name: string; price: string; time: string }[];
};
const Services = ({ staffInfo }: { staffInfo: any }) => {
    const { t } = useTranslation();
    const [serviceList, setServiceList] = useState<GroupedService[]>([]);
    const [serviceFilterList, setServiceFilterList] = useState<GroupedService[]>([]);
    const [searchString, setSearchString] = useState('');
    const handleSearchFilter = () => {
        const filteredCategories = serviceList.filter((category) => {
            const categoryMatch = category.name.toLowerCase().includes(searchString.toLowerCase());
            const serviceMatch = category.services.some((service) => service.name.toLowerCase().includes(searchString.toLowerCase()));
            return categoryMatch || serviceMatch;
        });

        setServiceFilterList(filteredCategories);
    };

    useEffect(() => handleSearchFilter(), [searchString]);

    const getServices = () => {
        const groupedServices: { [key: string]: GroupedService } = {};
        staffInfo.services.forEach((service: any) => {
            const { shop_category_id: shopCategoryId, category, price } = service;

            // If the category ID doesn't exist in the groupedServices object, create it
            if (!groupedServices[shopCategoryId]) {
                groupedServices[shopCategoryId] = {
                    id: category?.id,
                    name: category?.name,
                    services: [],
                };
            }
            const duration = moment.duration(price.duration);
            const hours = duration.hours();
            const minutes = duration.minutes();

            let time;
            if (hours === 0) {
                time = `${minutes} mins`;
            } else if (minutes === 0) {
                time = `${hours} hour`;
            } else {
                time = `${hours} hour ${minutes} mins`;
            }

            // Add the service to the corresponding category
            groupedServices[shopCategoryId].services.push({
                id: service.id,
                name: service.name,
                price: price.price,
                time: time,
            });
        });

        const groupedServicesArray: GroupedService[] = Object.values(groupedServices);
        return groupedServicesArray;
    };
    useEffect(() => {
        const serviceListData = getServices();
        setServiceList(serviceListData);
        setServiceFilterList(serviceListData);
    }, []);

    return (
        <div className="drawer-content-block">
            <div className="py-6 px-8">
                <h2 className="text-xl font-bold -tracking-[0.4px] mb-5">{t('Services')}</h2>
                <SearchBar
                    placeholder={t('Search services')}
                    className=""
                    onChange={(e: any) => {
                        setSearchString(e.target.value);
                    }}
                />
                <div className="mt-6 -ml-8">
                    <div className="text-base font-bold text-mainTextColor flex items-center px-8 border-b border-lineColor pb-4">
                        <div className="custom-round-chekbox-block mr-3">
                            <Checkbox id="All_location" name="location" className="" checked />
                        </div>

                        {t('All services')}
                        <span className="number-of-servic text-secondaryTxtColor ml-2">({serviceFilterList.reduce((total, category) => total + category.services.length, 0)})</span>
                    </div>
                    {serviceFilterList.map((category: any, index: number) => (
                        <Accordion collapseAll={true} className="flush-accordian" key={index}>
                            <Accordion.Panel>
                                <Accordion.Title className="flush-accordian-title">
                                    {' '}
                                    <div className="text-base font-bold text-mainTextColor flex items-center">
                                        <div className="custom-round-chekbox-block mr-3">
                                            <Checkbox id="All_location" name="location" className="" checked />
                                        </div>
                                        {category.name}
                                        <span className="number-of-servic text-secondaryTxtColor ml-2">({category.services.length})</span>
                                    </div>
                                </Accordion.Title>
                                <Accordion.Content className="flush-accordian-content border-0">
                                    {category.services.map((service: any, serIndex: number) => (
                                        <div className="flex items-center mb-4" key={serIndex}>
                                            <div className="custom-round-chekbox-block mr-3">
                                                <Checkbox id="All_location" name="location" className="" checked />
                                            </div>
                                            <div className="text-sm font-normal text-mainTextColor flex flex-col">
                                                {service.name}
                                                <span className="text-xs number-of-servic text-secondaryTxtColor">{service.time}</span>
                                            </div>
                                            <div className="ml-auto text-xl font-bold">{service.price}</div>
                                        </div>
                                    ))}
                                </Accordion.Content>
                            </Accordion.Panel>
                        </Accordion>
                    ))}
                    {/* <Accordion collapseAll className="flush-accordian">
                        <Accordion.Panel>
                            <Accordion.Title className="flush-accordian-title">
                                {' '}
                                <div className="text-base font-bold text-mainTextColor flex items-center">
                                    <div className="custom-round-chekbox-block mr-3">
                                        <Checkbox
                                            id="All_location"
                                            name="location"
                                            className=""
                                            checked
                                        />
                                    </div>
                                    Hair{' '}
                                    <span className="number-of-servic text-secondaryTxtColor ml-2">
                                        (5)
                                    </span>
                                </div>
                            </Accordion.Title>
                            <Accordion.Content className="flush-accordian-content border-0">
                                <div className="flex items-center mb-4">
                                    <div className="custom-round-chekbox-block mr-3">
                                        <Checkbox
                                            id="All_location"
                                            name="location"
                                            className=""
                                            checked
                                        />
                                    </div>
                                    <div className="text-sm font-normal text-mainTextColor flex flex-col">
                                        Haircut (Shampoo & Grooming)
                                        <span className="text-xs number-of-servic text-secondaryTxtColor">
                                            1 hour
                                        </span>
                                    </div>
                                    <div className="ml-auto text-xl font-bold">
                                        $208
                                    </div>
                                </div>
                                <div className="flex items-center mb-4">
                                    <div className="custom-round-chekbox-block mr-3">
                                        <Checkbox
                                            id="All_location"
                                            name="location"
                                            className=""
                                            checked
                                        />
                                    </div>
                                    <div className="text-sm font-normal text-mainTextColor flex flex-col">
                                        Haircut ( No Shampoo)
                                        <span className="text-xs number-of-servic text-secondaryTxtColor">
                                            1 hour
                                        </span>
                                    </div>
                                    <div className="ml-auto text-xl font-bold">
                                        $150
                                    </div>
                                </div>
                                <div className="flex items-center mb-4">
                                    <div className="custom-round-chekbox-block mr-3">
                                        <Checkbox
                                            id="All_location"
                                            name="location"
                                            className=""
                                            checked
                                        />
                                    </div>
                                    <div className="text-sm font-normal text-mainTextColor flex flex-col">
                                        Children&apos;s haircut
                                        <span className="text-xs number-of-servic text-secondaryTxtColor">
                                            1 hour
                                        </span>
                                    </div>
                                    <div className="ml-auto text-xl font-bold">
                                        $100
                                    </div>
                                </div>
                                <div className="flex items-center mb-4">
                                    <div className="custom-round-chekbox-block mr-3">
                                        <Checkbox
                                            id="All_location"
                                            name="location"
                                            className=""
                                            checked
                                        />
                                    </div>
                                    <div className="text-sm font-normal text-mainTextColor flex flex-col">
                                        Hair Coloring
                                        <span className="text-xs number-of-servic text-secondaryTxtColor">
                                            1 hour 30 min
                                        </span>
                                    </div>
                                    <div className="ml-auto text-xl font-bold">
                                        $100
                                    </div>
                                </div>
                                <div className="flex items-center">
                                    <div className="custom-round-chekbox-block mr-3">
                                        <Checkbox
                                            id="All_location"
                                            name="location"
                                            className=""
                                            checked
                                        />
                                    </div>
                                    <div className="text-sm font-normal text-mainTextColor flex flex-col">
                                        Razor Cut
                                        <span className="text-xs number-of-servic text-secondaryTxtColor">
                                            30 min
                                        </span>
                                    </div>
                                    <div className="ml-auto text-xl font-bold">
                                        $70
                                    </div>
                                </div>
                            </Accordion.Content>
                        </Accordion.Panel>

                        <Accordion.Panel>
                            <Accordion.Title className="flush-accordian-title">
                                {' '}
                                <div className="text-base font-bold text-mainTextColor flex items-center">
                                    <div className="custom-round-chekbox-block mr-3">
                                        <Checkbox
                                            id="All_location"
                                            name="location"
                                            className=""
                                            checked
                                        />
                                    </div>
                                    Beard
                                    <span className="number-of-servic text-secondaryTxtColor ml-2">
                                        (4)
                                    </span>
                                </div>
                            </Accordion.Title>
                            <Accordion.Content className="flush-accordian-content border-0">
                                <div className="flex items-center mb-4">
                                    <div className="custom-round-chekbox-block mr-3">
                                        <Checkbox
                                            id="All_location"
                                            name="location"
                                            className=""
                                            checked
                                        />
                                    </div>
                                    <div className="text-sm font-normal text-mainTextColor flex flex-col">
                                        Haircut (Shampoo & Grooming)
                                        <span className="text-xs number-of-servic text-secondaryTxtColor">
                                            1 hour
                                        </span>
                                    </div>
                                    <div className="ml-auto text-xl font-bold">
                                        $208
                                    </div>
                                </div>
                                <div className="flex items-center mb-4">
                                    <div className="custom-round-chekbox-block mr-3">
                                        <Checkbox
                                            id="All_location"
                                            name="location"
                                            className=""
                                            checked
                                        />
                                    </div>
                                    <div className="text-sm font-normal text-mainTextColor flex flex-col">
                                        Haircut ( No Shampoo)
                                        <span className="text-xs number-of-servic text-secondaryTxtColor">
                                            1 hour
                                        </span>
                                    </div>
                                    <div className="ml-auto text-xl font-bold">
                                        $150
                                    </div>
                                </div>
                                <div className="flex items-center mb-4">
                                    <div className="custom-round-chekbox-block mr-3">
                                        <Checkbox
                                            id="All_location"
                                            name="location"
                                            className=""
                                            checked
                                        />
                                    </div>
                                    <div className="text-sm font-normal text-mainTextColor flex flex-col">
                                        Children&apos;s haircut
                                        <span className="text-xs number-of-servic text-secondaryTxtColor">
                                            1 hour
                                        </span>
                                    </div>
                                    <div className="ml-auto text-xl font-bold">
                                        $100
                                    </div>
                                </div>
                                <div className="flex items-center mb-4">
                                    <div className="custom-round-chekbox-block mr-3">
                                        <Checkbox
                                            id="All_location"
                                            name="location"
                                            className=""
                                            checked
                                        />
                                    </div>
                                    <div className="text-sm font-normal text-mainTextColor flex flex-col">
                                        Hair Coloring
                                        <span className="text-xs number-of-servic text-secondaryTxtColor">
                                            1 hour 30 min
                                        </span>
                                    </div>
                                    <div className="ml-auto text-xl font-bold">
                                        $100
                                    </div>
                                </div>
                                <div className="flex items-center">
                                    <div className="custom-round-chekbox-block mr-3">
                                        <Checkbox
                                            id="All_location"
                                            name="location"
                                            className=""
                                            checked
                                        />
                                    </div>
                                    <div className="text-sm font-normal text-mainTextColor flex flex-col">
                                        Razor Cut
                                        <span className="text-xs number-of-servic text-secondaryTxtColor">
                                            30 min
                                        </span>
                                    </div>
                                    <div className="ml-auto text-xl font-bold">
                                        $70
                                    </div>
                                </div>
                            </Accordion.Content>
                        </Accordion.Panel>

                        <Accordion.Panel>
                            <Accordion.Title className="flush-accordian-title">
                                {' '}
                                <div className="text-base font-bold text-mainTextColor flex items-center">
                                    <div className="custom-round-chekbox-block mr-3">
                                        <Checkbox
                                            id="All_location"
                                            name="location"
                                            className=""
                                            checked
                                        />
                                    </div>
                                    Styling
                                    <span className="number-of-servic text-secondaryTxtColor ml-2">
                                        (11)
                                    </span>
                                </div>
                            </Accordion.Title>
                            <Accordion.Content className="flush-accordian-content ">
                                <div className="flex items-center mb-4">
                                    <div className="custom-round-chekbox-block mr-3">
                                        <Checkbox
                                            id="All_location"
                                            name="location"
                                            className=""
                                            checked
                                        />
                                    </div>
                                    <div className="text-sm font-normal text-mainTextColor flex flex-col">
                                        Haircut (Shampoo & Grooming)
                                        <span className="text-xs number-of-servic text-secondaryTxtColor">
                                            1 hour
                                        </span>
                                    </div>
                                    <div className="ml-auto text-xl font-bold">
                                        $208
                                    </div>
                                </div>
                                <div className="flex items-center mb-4">
                                    <div className="custom-round-chekbox-block mr-3">
                                        <Checkbox
                                            id="All_location"
                                            name="location"
                                            className=""
                                            checked
                                        />
                                    </div>
                                    <div className="text-sm font-normal text-mainTextColor flex flex-col">
                                        Haircut ( No Shampoo)
                                        <span className="text-xs number-of-servic text-secondaryTxtColor">
                                            1 hour
                                        </span>
                                    </div>
                                    <div className="ml-auto text-xl font-bold">
                                        $150
                                    </div>
                                </div>
                                <div className="flex items-center mb-4">
                                    <div className="custom-round-chekbox-block mr-3">
                                        <Checkbox
                                            id="All_location"
                                            name="location"
                                            className=""
                                            checked
                                        />
                                    </div>
                                    <div className="text-sm font-normal text-mainTextColor flex flex-col">
                                        Children&apos;s haircut
                                        <span className="text-xs number-of-servic text-secondaryTxtColor">
                                            1 hour
                                        </span>
                                    </div>
                                    <div className="ml-auto text-xl font-bold">
                                        $100
                                    </div>
                                </div>
                                <div className="flex items-center mb-4">
                                    <div className="custom-round-chekbox-block mr-3">
                                        <Checkbox
                                            id="All_location"
                                            name="location"
                                            className=""
                                            checked
                                        />
                                    </div>
                                    <div className="text-sm font-normal text-mainTextColor flex flex-col">
                                        Hair Coloring
                                        <span className="text-xs number-of-servic text-secondaryTxtColor">
                                            1 hour 30 min
                                        </span>
                                    </div>
                                    <div className="ml-auto text-xl font-bold">
                                        $100
                                    </div>
                                </div>
                                <div className="flex items-center">
                                    <div className="custom-round-chekbox-block mr-3">
                                        <Checkbox
                                            id="All_location"
                                            name="location"
                                            className=""
                                            checked
                                        />
                                    </div>
                                    <div className="text-sm font-normal text-mainTextColor flex flex-col">
                                        Razor Cut
                                        <span className="text-xs number-of-servic text-secondaryTxtColor">
                                            30 min
                                        </span>
                                    </div>
                                    <div className="ml-auto text-xl font-bold">
                                        $70
                                    </div>
                                </div>
                            </Accordion.Content>
                        </Accordion.Panel>
                    </Accordion> */}
                </div>
            </div>
        </div>
    );
};

export default Services;
