import React, { FC, Ref, forwardRef, useEffect, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { HiChevronDown, HiChevronLeft, HiChevronRight } from 'react-icons/hi';
import SelectBox from 'src/components/SelectBox/SelectBox';
import { Images } from 'src/theme';
import { IOptions, IToolbar } from './Calendar.Interface';
import moment from 'moment-timezone';
import { capitalizeFirstLetter, formatName, formatShortName, getResourceOptions } from 'src/utils/global-functions';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { selectCalendarData, setData } from './Calendar.slice';
import { staffList } from '../Staff/Staff.slice';
import { selectShopInfo } from '../Auth/Login/Login.slice';
import { update } from 'lodash';

const Toolbar: FC<IToolbar> = ({
    onNavigate,
    date,
    setView,
    onView,
    view,
    // staffListArray,
    // locationList,
}) => {
    const addServiceslast = [
        { label: 'Day', value: 'day' },
        { label: 'Week', value: 'week' },
    ];
    const [timeZone, setTimeZone] = useState(null);

    const dispatch = useAppDispatch();
    const calendarData: any = useAppSelector(selectCalendarData);
    const shop: any = useAppSelector(selectShopInfo);
    const shopId = shop.id;
    // const filteredData = calendarData.locationInfoList.filter((item: any) => item.id === calendarData.selectedLocation.id);
    // const timeZone = filteredData[0].timezone;
    // moment.tz.setDefault(timeZone);
    useEffect(() => {
        if (calendarData.selectedLocation) {
            const filteredData = calendarData.locationInfoList.filter((item: any) => item.id === calendarData.selectedLocation.id);
            moment.tz.setDefault(filteredData[0].timezone);
            setTimeZone(filteredData[0].timezone);
        }
    }, [calendarData.selectedLocation]);

    const handleLocation = async (e: any) => {
        const updateState = {
            selectedLocation: e,
        };
        await dispatch(setData(updateState));
    };

    const handleStaffChange = async (e: IOptions) => {
        let updateState: any = {
            selectedStaff: e,
        };
        console.log(e, 'eee');

        if (e.id !== null) {
            const selectedStaff = calendarData.staffList.filter((item: any) => item.id === e.id);
            updateState.resourceList = getResourceOptions(selectedStaff);
        } else {
            const staffList2 = calendarData.staffList.filter((item: any) => item.id !== null);
            console.log('calendarData.staffList', staffList2);
            updateState.resourceList = getResourceOptions(staffList2);
        }
        await dispatch(setData(updateState));
    };

    const CustomInput = forwardRef((props: any, ref: Ref<HTMLInputElement>) => {
        const timeZone2 = props.timeZone; // Assuming timeZone is passed through props

        // Create moment objects for both the current date and props.value in the specified time zone
        const currentDateInTimeZone = moment.tz(moment(), timeZone2);
        const propsDateInTimeZone = moment.tz(props.value, timeZone2);

        return (
            <div className="flex px-3 items-center max-w-[180px]">
                {propsDateInTimeZone.isSame(currentDateInTimeZone, 'day') ? (
                    <span role="button" onClick={props.onClick}>
                        TODAY
                    </span>
                ) : (
                    <input
                        ref={ref}
                        type="text"
                        className="text-sm bg-transparent border-0 font-bold leading-[140%] -tracking-[0.14px] focus:border-0 focus:ring-0 w-full flex-1 p-0"
                        onClick={props.onClick}
                        value={props.value}
                        onChange={props.onChange}
                    />
                )}

                <span className="ml-1 flex justify-center items-center w-5 h-5 hover:text-primary" role="button" onClick={props.onClick} tabIndex={0}>
                    <HiChevronDown size={20} />
                </span>
                {!propsDateInTimeZone.isSame(currentDateInTimeZone, 'day') && (
                    <span role="button" onClick={() => onNavigate('TODAY')}>
                        TODAY
                    </span>
                )}
            </div>
        );
    });

    return (
        <div className="flex items-center mb-8 relative z-10">
            <div className="flex items-center">
                <div
                    className="flex justify-center items-center w-[44px] h-10 rounded-full border border-lineColor text-secondaryTxtColor hover:text-primary"
                    role="button"
                    tabIndex={0}
                    onClick={() => onNavigate('PREV')}
                >
                    <HiChevronLeft size={20} />
                </div>

                <ReactDatePicker
                    selected={moment(date).toDate()}
                    customInput={<CustomInput />}
                    onChange={(date2: Date) => {
                        onNavigate('DATE', date2);
                    }}
                />

                <div
                    className="flex justify-center items-center w-[44px] h-10 rounded-full border border-lineColor text-secondaryTxtColor hover:text-primary"
                    role="button"
                    tabIndex={0}
                    onClick={() => onNavigate('NEXT')}
                >
                    <HiChevronRight size={20} />
                </div>
            </div>
            <div className="flex gap-3 ml-auto">
                <div className="w-[170px] xl:w-[240px] ">
                    <SelectBox
                        isSearchablel
                        name="territory"
                        id="handlingUnit"
                        options={calendarData.locationList}
                        classComp="outline-select-box h-10"
                        value={calendarData.selectedLocation}
                        onChangeFunc={handleLocation}
                        // menuIsOpen
                        openOnFocus={true}
                        autofocus={true}
                        allowIcon={<img src={Images.mapLocationPin} alt="map-pin" />}
                    />
                </div>

                <div className="w-[180px] px-3">
                    <SelectBox
                        name="territory"
                        id="handlingUnit"
                        options={calendarData.staffList}
                        value={calendarData.selectedStaff}
                        onChangeFunc={handleStaffChange}
                        classComp="outline-select-box h-10"
                    />
                </div>

                <div className="w-[111px] 2xl:w-[170px] pe-6">
                    <SelectBox
                        name="territory"
                        id="handlingUnit"
                        value={{
                            label: capitalizeFirstLetter(view),
                            value: view,
                        }}
                        options={addServiceslast}
                        onChangeFunc={(e: any) => {
                            onView(e.value);
                            setView(e.value);
                        }}
                        classComp="outline-select-box h-10"
                    />
                </div>
            </div>
            {/* <div role="button" tabIndex={0}>
                <button>{'day'}</button>
            </div>
            <div role="button" tabIndex={0}>
                <button>{'WEEK'}</button>
            </div> */}
        </div>
    );
};
export default Toolbar;
