import React, { useEffect, useState } from 'react';
import './index.scss';

interface IProps {
    isLoading?: boolean;
    className?: string;
}

const BarLoader = ({ isLoading = true, className = '' }: IProps) => {
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        let timeoutId: any;

        if (isLoading === false) {
            // Calculate the next multiple of 3 seconds
            const currentTime = new Date().getTime();
            const nextMultipleOfThree = Math.ceil(currentTime / 4500) * 4500;
            const delay = nextMultipleOfThree - currentTime;

            // Set the visibility after the calculated delay
            timeoutId = setTimeout(() => {
                setVisible(false);
            }, delay);
        } else {
            setVisible(true);
        }

        return () => clearTimeout(timeoutId);
    }, [isLoading]);

    return (
        <>
            {visible ? (
                <div className={`w-full flex justify-center relative ${className}`}>
                    <div className="loader"></div>
                </div>
            ) : null}
        </>
    );
};

export default BarLoader;
