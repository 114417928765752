import React, { FC, useEffect } from 'react';
import { MdOutlineMoreVert } from 'src/assets/customsvg';
import DropdownTick from 'src/components/DropdownTick';
import { Images } from 'src/theme';
interface IListProps {
    filteredList: any;
    serviceListArray: any;
    handleServiceInfo: (isOpenServiceDetail: boolean, editData: any) => void;
    setCategoryObj: (categoryInfo: any) => void;
    setSelectedValue: any;
}

const Listing: FC<IListProps> = ({ filteredList, serviceListArray, handleServiceInfo, setCategoryObj, setSelectedValue }) => {
    useEffect(() => {}, []);

    return (
        <div>
            {filteredList.length > 0 ? (
                filteredList.map((category: any, index: number) => (
                    <div className="flex flex-col -mx-6  flex-1 justify-start items-start " key={index}>
                        <div className="flex flex-col px-6 w-full pb-5 mb-6 border-b border-lineLightColor ">
                            <div className="flex justify-between mb-5">
                                <h2 className="flex items-center text-base font-bold mr-2">
                                    <i className="icon mr-3 text-primary text-xl">
                                        <img src={Images.IconDrag} alt="" className="" />
                                    </i>
                                    {category.name}
                                    <span className="text-secondaryTxtColor ml-1">({category?.services.length})</span>
                                </h2>
                                <div className="cursor-pointer">
                                    <DropdownTick
                                        dropDownMenuClassName="w-[164px] top-[32px]"
                                        dropDownMenuLinkClassName="font-normal"
                                        labelIcon={<MdOutlineMoreVert stroke="#1642C5" currentColor="#1642C5" />}
                                        labelActiveIcon={<MdOutlineMoreVert stroke="#E3E7EF" currentColor="#1F293C" />}
                                        data={['Edit Category', 'Delete Category']}
                                        setselected={setSelectedValue}
                                        handleItemClick={() => setCategoryObj(category)}
                                    />
                                </div>
                            </div>
                            {/* sub services block */}

                            {category?.services.length > 0 &&
                                category?.services.map((serviceInfo: any, serviceIndex: number) => (
                                    <div className="pl-6 flex flex-col" key={serviceIndex}>
                                        <div className="flex items-center text-sm mb-3 ">
                                            <h3
                                                className="flex items-center text-sm mr-2 font-medium -tracking-[0.14px] w-full max-w-[240px] cursor-pointer "
                                                onClick={() => handleServiceInfo(true, serviceInfo)}
                                            >
                                                <i className="icon mr-3 text-primary text-xl">
                                                    <img src={Images.IconDrag} alt="" className="" />
                                                </i>
                                                {serviceInfo.name}
                                            </h3>
                                            <div className="max-w-[458px] w-full overflow-hidden whitespace-nowrap text-secondaryTxtColor -tracking-[0.14px] text-ellipsis">
                                                {serviceInfo.description}
                                            </div>
                                            <div className="flex items-center ml-auto">
                                                <div className="flex justify-end sm:justify-start lg:justify-end xl:justify-start -space-x-2 -gap-[10px] ">
                                                    {serviceInfo.staff.map((staff: any, staffIndex: number) =>
                                                        staffIndex < 5 ? (
                                                            <img
                                                                key={staffIndex}
                                                                src={staff.profile_image_url}
                                                                alt=""
                                                                width="48"
                                                                height="48"
                                                                className="w-7 h-7 rounded-full bg-gray-100 border-2 border-white"
                                                            />
                                                        ) : (
                                                            ''
                                                        ),
                                                    )}
                                                    {serviceInfo.staff.lenth > 5 ? (
                                                        <div className="w-7 h-7 rounded-full  bg-primary border-2 border-white text-white text-[10px] font-medium leading-[14px] -tracking-[0.1px] flex items-center justify-center">
                                                            {serviceInfo.staff.lenth - 5}
                                                        </div>
                                                    ) : (
                                                        ''
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </div>
                ))
            ) : (
                <p>No items to display</p>
            )}
        </div>
    );
};

export default Listing;
