export interface IThemePopup {
    activeTheme: number;
    handleThemeApply: (id: number) => () => void;
    handleClose: () => void;
    settings: ISettings[];
}

export interface ISettings {
    shop_theme_id: number;
    type: string;
    content_background: string;
    cards_color: string;
    button_color: string;
    accent_color: string;
    main_text_color: string;
    secondary_text_color: string;
}

export interface IFormData {
    logo_image_name: string;
    settings: ISettings[];
}

export interface Color {
    id?: number;
    shop_theme_id?: number;
    content_background: string;
    cards_color: string;
    button_color: string;
    accent_color: string;
    main_text_color: string;
    secondary_text_color: string;
    created_at?: string;
    updated_at?: string;
}

export interface ColorTheme {
    id: number;
    name: string;
    type: 'light' | 'dark';
    deleted_at: string | null;
    created_at: string;
    updated_at: string;
    color: Color;
}

export interface IInitialState {
    loading: boolean;
    hasErrors: boolean;
    themeResult: ColorTheme[] | null;
    themeLoading: boolean;
}

export enum AppearanceScreen {
    Web = 'Web',
    Mobile = 'Mobile',
    POS = 'POS',
}

export type IFieldKey = 'logo_image_name' | 'settings';
