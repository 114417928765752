import React from 'react';
import FormSettings from './FormSettings';
import PosImg from 'src/assets/customsvg/PosImg';

const POSSettings = ({ index, setting, logoImageUrl, themeOptions, handleAction, onChangeCrop }: any) => (
    <div className="flex ">
        <FormSettings index={index} setting={setting} logoImageUrl={logoImageUrl} themeOptions={themeOptions} handleAction={handleAction} onChangeCrop={onChangeCrop} />

        <div className="w-full max-w-[763px]">
            <p className="fl-field-title-label">Preview</p>
            <div className="rounded-2xl border-[2px] border-lineColor fl-web-app-tab-content oveflow-hidden p-2 2xl:py-5">
                <div className="p-4 max-w-[625px] w-full mx-auto rounded-2xl">
                    <PosImg selectedColors={setting} />
                </div>
            </div>
        </div>
    </div>
);

export default POSSettings;
