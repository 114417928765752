import React, { useEffect, useState } from 'react';
import { IProps } from './Staff.interface';
import Button from 'src/components/Button';
import Checkbox from 'src/components/Checkbox';

import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const WorkingLocation = ({ setStep, locationDataDetails, onClick }: IProps) => {
    const { t } = useTranslation();
    const [editableForm] = useState(false);
    const [idArray, setIdArray] = useState([]);
    const { control, formState } = useFormContext();
    const errors: any = formState.errors;
    useEffect(() => {
        const idArray2 = locationDataDetails.map((item: any) => item.id);
        setIdArray(idArray2);
    }, []);

    const { clearErrors } = useFormContext();

    const handleLocationChange = (optionId: number, onChange: (value: any[]) => void, value: any[]) => (event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
        const isCurrentlyChecked = value.includes(optionId);
        if (isCurrentlyChecked) {
            onChange(value.filter((id: number) => id !== optionId));
        } else {
            onChange([...value, optionId]);
        }
        clearErrors('shop_location_id');
    };

    return (
        <div>
            <div className="mb-4 text-center ">
                <h3 className="fwpo_heading_title w-[480px] mx-auto">{t('Working location')}</h3>
            </div>
            <Controller
                name="shop_location_id"
                control={control}
                render={({ field: { onChange, value } }) => (
                    <>
                        <div className={`relative flex justify-center  mb-8  ${!editableForm ? 'pb-3 border-b border-lineLightColor' : ''}`}>
                            {!editableForm && <p className="leading-[140%] -tracking-[0.3px] opacity-70">{t('Choose the location where the service will take place')}</p>}
                            <div className="absolute right-0 flex">
                                <span className="font-medium">{t('All locations')}</span>
                                <div className="custom-round-chekbox-block ml-2">
                                    <Checkbox
                                        id={`location_all`}
                                        checked={JSON.stringify(idArray) === JSON.stringify(value)}
                                        onChange={(e: any) => {
                                            const isChecked = e.target.checked;
                                            if (isChecked) {
                                                onChange(idArray);
                                            } else {
                                                onChange([]);
                                            }
                                            clearErrors('shop_location_id');
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        {locationDataDetails.map((option: any) => (
                            <div className="flex mb-6 items-center border rounded-3xl p-2 border-gray-200" key={option.id} onClick={handleLocationChange(option.id, onChange, value)}>
                                <div className="h-[130px] w-[200px] basis-[200px] grow-0 shrink-0  rounded-2xl  mr-6 overflow-hidden">
                                    <img id="imgEdit" src={option.location_image_url} alt="Location Img" className="h-full w-full object-cover" />
                                </div>
                                <div className="flex-auto flex items-center overflow-hidden ">
                                    <div className="flex-1">
                                        <h2 className="text-base font-bold leading-[140%] -tracking-[0.4px]">{option.name}</h2>

                                        <p className="truncate text-xs font-normal leading-[18px] -tracking-[0.3px] text-secondaryTxtColor mt-3">
                                            {`${option.street},${option.city},${option.country}`}
                                        </p>
                                    </div>
                                    <div className="custom-round-chekbox-block ml-2">
                                        <Checkbox
                                            id={`location_${option.id}`}
                                            name="shop_location_id"
                                            value={Number(option.id)}
                                            checked={value.includes(option.id)}
                                            onChange={(e: any) => {
                                                const isChecked = e.target.checked;
                                                if (isChecked) {
                                                    onChange([...value, Number(e.target.value)]);
                                                } else {
                                                    onChange(value.filter((value22: any) => value22 !== Number(e.target.value)));
                                                }
                                                clearErrors('shop_location_id');
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        ))}
                        {errors?.shop_location_id && <p className="text-error">{errors?.shop_location_id?.message}</p>}
                    </>
                )}
            />
            <div className="flex w-full px-10 justify-center mt-10">
                <Button className="fl-btn btn_primary w-[420px] " onClick={onClick} type="button">
                    {t('Continue')}
                </Button>
            </div>
        </div>
    );
};

export default WorkingLocation;
