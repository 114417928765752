import React, { memo, useEffect, useRef, useState } from 'react';
import { HiChevronDown, HiChevronUp } from 'react-icons/hi';
import { HiCheck } from 'react-icons/hi';

import { ICustomSelectDropdown } from './CustomSelectDropdown.interface';
import Button from '../Button';
import { useTranslation } from 'react-i18next';

const CustomSelectDropdown: React.FC<ICustomSelectDropdown> = ({
    label,
    data,
    setselected,
    className,
    subSwitch,
    handleItemClick,
    setSubSwitch,
    dropDownMenuClassName,
    handleAddClick,
    handleRemoveClick,
    handleCloseDropdown,
    createRoleError,
    dropDownMenuItemClassName,
    dropDownMenuLinkClassName,
    onKeyPress,
    isDisabled = false,
}) => {
    const { t } = useTranslation();
    const [Switch, setSwitch] = useState(false);

    const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
    const [hicheckIndex, setHicheckIndex] = useState<number | null>(null);
    const [newRole, setNewRole] = useState<string>('');

    const showHicheck = (event: any, index: any) => {
        event.stopPropagation();
        setHicheckIndex(index);
    };
    const handleRemoveClick2 = (event: any, item: any, index: any) => {
        event.stopPropagation();
        setselected(t('select Role'));
        handleRemoveClick(item.value);
    };
    const handleAddButtonClick = () => {
        handleAddClick(newRole);
        setNewRole('');
    };
    const closeHandler = () => {
        setSwitch(false);
        setSubSwitch?.(false);
        setHicheckIndex(null);
        setHoveredIndex(null);
    };

    useEffect(() => {
        if (handleCloseDropdown) {
            closeHandler();
        }
    }, [handleCloseDropdown]);
    useEffect(() => {
        const handler = () => {
            setSwitch(false);
            setSubSwitch?.(false);
        };
        // window.addEventListener('click', handler);
        return () => {
            window.removeEventListener('click', handler);
        };
    }, []);

    const handlerInputClick = (e: any) => {
        e.stopPropagation();

        if (!isDisabled) {
            setSwitch(!Switch);
            setSubSwitch?.(!subSwitch || false);
        }
    };

    const onItemClick = (e: any) => {
        setselected(e.label);
        handleItemClick && handleItemClick(e);
        setHicheckIndex(null);
        closeHandler();
    };

    const divRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        // Function to handle click outside the div
        const handleClickOutside = (event: MouseEvent) => {
            if (divRef.current && !divRef.current.contains(event.target as Node)) {
                closeHandler();
            }
        };

        // Add event listener when the component mounts
        document.addEventListener('mousedown', handleClickOutside);

        // Remove the event listener when the component unmounts
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [divRef]);
    return (
        <div className="relative" ref={divRef}>
            <label htmlFor="" className="fl-field-title-label">
                Role{''}
            </label>
            <div className="flex items-center" onClick={handlerInputClick}>
                <div className={className}>
                    <span className="text">{label}</span>
                    {typeof label === 'string' && <i className="icon ml-auto text-xl">{Switch || subSwitch ? <HiChevronUp /> : <HiChevronDown />}</i>}
                </div>
            </div>
            {Switch && (
                <div id="dropdownDelay" className={`z-10 absolute mt-2  shadow-DropDown  bg-white rounded-3xl  py-5  right-0 top-[100%]  ${dropDownMenuClassName}`}>
                    <ul className="text-sm w-full max-h-[160px] overflow-y-auto" aria-labelledby="dropdownDelayButton">
                        {data.map((items: any, index: number) => (
                            <li className={`${dropDownMenuItemClassName}`} key={index} value={items.value} onMouseEnter={() => setHoveredIndex(index)} onMouseLeave={() => setHoveredIndex(null)}>
                                {/* The main content */}
                                <div
                                    className={`cursor-pointer flex items-center px-5 py-[10px] hover:text-primary hover:font-medium  ${dropDownMenuLinkClassName}`}
                                    onClick={() => onItemClick(items)}
                                >
                                    {items.label && <span>{items.label}</span>}

                                    {/* The section that becomes visible on hover */}
                                    {(hoveredIndex === index || hicheckIndex === index) && (
                                        <div className="ml-auto flex items-center">
                                            <span
                                                className="text-dangerText font-normal text-xs"
                                                onClick={(e: any) => {
                                                    showHicheck(e, index);
                                                }}
                                            >
                                                Remove
                                            </span>
                                            {hicheckIndex === index && (
                                                <i
                                                    className="icon ml-5 text-xl text-primary"
                                                    onClick={(e: any) => {
                                                        handleRemoveClick2(e, items, index);
                                                    }}
                                                >
                                                    <HiCheck />
                                                </i>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </li>
                        ))}

                        {/* <li className={`${dropDownMenuItemClassName}`}>
                                <div
                                    className={`cursor-pointer flex items-center px-5 py-[10px] hover:text-primary hover:font-medium  ${dropDownMenuLinkClassName}`}
                                    onClick={onItemClick}
                                >
                                    <span>Makeup Artist</span>
                                    <div className="ml-auto flex items-center">
                                        <span className="text-dangerText font-normal text-xs">
                                            Remove
                                        </span>
                                        <i className="icon ml-5  text-xl text-primary">
                                            <HiCheck />
                                        </i>
                                    </div>
                                </div>
                            </li>
                            <li className={`${dropDownMenuItemClassName}`}>
                                <div
                                    className={`cursor-pointer flex items-center px-5 py-[10px] hover:text-primary hover:font-medium  ${dropDownMenuLinkClassName}`}
                                    onClick={onItemClick}
                                >
                                    <span>Barber</span>
                                    <div className="ml-auto flex items-center">
                                        <span className="text-dangerText font-normal text-xs">
                                            Remove
                                        </span>
                                        <i className="icon ml-5  text-xl text-primary">
                                            <HiCheck />
                                        </i>
                                    </div>
                                </div>
                            </li> */}
                    </ul>
                    <div className="px-5 border-0 pt-[10px] w-full">
                        <div className="bg-inputBg h-[48px] flex items-center rounded-full w-full">
                            <input
                                type="text"
                                placeholder="Add new role"
                                className="bg-transparent border-0 py-2 px-4 flex-1 w-full text-sm focus:border-0 focus:shadow-none focus:ring-0 "
                                autoFocus
                                onKeyDown={(e: any) => {
                                    if (e.keyCode === 13) {
                                        e.preventDefault();
                                        handleAddButtonClick();
                                    }
                                }}
                                onChange={(e: any) => setNewRole(e.target.value)}
                                value={newRole}
                            />
                            <Button type="button" className="border-0 px-4 py-2  bg-transparent text-primary font-medium" onClick={handleAddButtonClick}>
                                ADD
                            </Button>
                        </div>
                        {createRoleError && <p className="text-error">This role has already been created</p>}
                    </div>
                </div>
            )}
        </div>
    );
};

export default memo(CustomSelectDropdown);
