import React, { useState } from 'react';
import Button from 'src/components/Button';

import { Controller, useForm } from 'react-hook-form';
import { IAddPaymentMethodData, IPaymentElement } from './MyAccount.interface';
import InputWithLabel from 'src/components/InputWithLabel';

import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { CreateStripeToken } from './MyAccount.slice';
import { useAppDispatch } from 'src/redux/hooks';

const MyAccountElement = ({ closePopup, cardList }: IPaymentElement) => {
    const stripe: any = useStripe();
    const elements: any = useElements();
    const dispatch = useAppDispatch();
    const [cardError, setCardError] = useState<{
        cardNumber?: string;
        cardExpiry?: string;
        cardCvc?: string;
    }>({});

    const [isLoading, setIsLoading] = useState(false);

    const {
        handleSubmit,
        control,
        setError,
        formState: { errors },
    } = useForm<IAddPaymentMethodData>({
        defaultValues: {
            cardnumber: '',
            date: '',
            cvv: '',
            holdername: '',
        },
    });

    const onSubmit = async (data: any) => {
        setIsLoading(true);
        const cardElement = elements.getElement(CardNumberElement);
        if (stripe) {
            const { error, token }: any = await stripe.createToken(cardElement);
            if (error) {
                setIsLoading(false);
                if (!data.holdername) {
                    setError('holdername', {
                        type: 'manual',
                        message: 'This field is required',
                    });
                }
                const errorCode = error.code;
                const parts = errorCode.split('_');

                if (parts[1] === 'number') {
                    await setCardError((prev) => ({
                        ...prev,
                        cardNumber: error?.message,
                    }));
                }
                if (parts[1] === 'expiry') {
                    await setCardError((prev) => ({
                        ...prev,
                        cardExpiry: error?.message,
                    }));
                }
                if (parts[1] === 'cvc') {
                    await setCardError((prev) => ({
                        ...prev,
                        cardCvc: error?.message,
                    }));
                }
                return;
            }

            // Process the paymentMethod.id or send it to your server for further processing
            const result = await dispatch(CreateStripeToken({ token: token?.id }));

            if (result.type === CreateStripeToken.fulfilled.toString()) {
                setIsLoading(false);
                closePopup();
                cardList();
            }
        }
    };

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)} className="h-full">
                <div className="flex flex-col h-full justify-between">
                    <div className="flex-1">
                        <div className="flex gap-6 flex-wrap w-full mx-auto ">
                            <Controller
                                name="cardnumber"
                                control={control}
                                render={({ field: { onChange, value } }: any) => (
                                    <div className="w-full">
                                        <label htmlFor="cardnumber" className="fl-field-title-label">
                                            Card number
                                        </label>
                                        <CardNumberElement
                                            id="number-123"
                                            className={`form_input_control h-[48px] py-[14px] ${(errors.cardnumber || cardError.cardNumber) && 'is-invalid'}`}
                                            onChange={(e) => {
                                                onChange(e.error ? '' : e.complete);
                                                setCardError((prev) => ({
                                                    ...prev,
                                                    cardNumber: e.complete ? '' : e.error?.message,
                                                }));
                                            }}
                                        />
                                        {(errors.cardnumber || cardError.cardNumber) && (
                                            <p className="text-error">{(errors?.cardnumber || cardError.cardNumber) && (errors?.cardnumber?.message || cardError.cardNumber)}</p>
                                        )}
                                    </div>
                                )}
                            />

                            <div className="flex gap-6 w-full">
                                <div className="w-[50%]">
                                    <Controller
                                        name="date"
                                        control={control}
                                        render={({ field: { onChange, value } }: any) => (
                                            <div className="w-full">
                                                <label htmlFor="date" className="fl-field-title-label">
                                                    Date
                                                </label>
                                                <CardExpiryElement
                                                    className={`form_input_control h-[48px] py-[14px] ${(errors.date || cardError.cardExpiry) && 'is-invalid'}`}
                                                    onChange={(e) => {
                                                        onChange(e.error ? '' : e.complete);
                                                        setCardError((prev) => ({
                                                            ...prev,
                                                            cardExpiry: e.complete ? '' : e.error?.message,
                                                        }));
                                                    }}
                                                />
                                                {(errors.date || cardError.cardExpiry) && (
                                                    <p className="text-error">{(errors?.date || cardError.cardExpiry) && (errors?.date?.message || cardError.cardExpiry)}</p>
                                                )}
                                            </div>
                                        )}
                                    />
                                </div>
                                <div className="w-[50%]">
                                    <Controller
                                        name="cvv"
                                        control={control}
                                        render={({ field: { onChange, value } }: any) => (
                                            <div className="w-full">
                                                <label htmlFor="cvv" className="fl-field-title-label">
                                                    CVC
                                                </label>
                                                <CardCvcElement
                                                    className={`form_input_control h-[48px] py-[14px] ${(errors.cvv || cardError.cardCvc) && 'is-invalid'}`}
                                                    onChange={(e) => {
                                                        onChange(e.error ? '' : e.complete);
                                                        setCardError((prev) => ({
                                                            ...prev,
                                                            cardCvc: e.complete ? '' : e.error?.message,
                                                        }));
                                                    }}
                                                />
                                                {(errors.cvv || cardError.cardCvc) && <p className="text-error">{(errors?.cvv || cardError.cardCvc) && (errors?.cvv?.message || cardError.cardCvc)}</p>}
                                            </div>
                                        )}
                                    />
                                </div>
                            </div>

                            <Controller
                                name="holdername"
                                control={control}
                                render={({ field: { onChange, value } }: any) => (
                                    <div className="w-full">
                                        <InputWithLabel
                                            label="Cardholder name"
                                            placeholder="Enter card holder name"
                                            onChange={onChange}
                                            value={value.holdername}
                                            name="holdername"
                                            error={errors.holdername && true}
                                        />
                                        {errors.holdername && <p className="text-error">{errors.holdername && errors.holdername.message}</p>}
                                    </div>
                                )}
                            />
                        </div>
                    </div>
                    <div className="flex w-full justify-center mt-10">
                        <Button isLoading={isLoading} disabled={isLoading} type="submit" className="fl-btn btn_primary w-full ">
                            Continue
                        </Button>
                    </div>
                </div>
            </form>
        </>
    );
};

export default MyAccountElement;
