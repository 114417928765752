import React, { FC, useEffect } from 'react';
import Button from 'src/components/Button';
import { FaArrowLeftLong } from 'react-icons/fa6';
import OpningHour from './OpningHour';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { IPops, workingHoursData } from './WorkingHours.interface';
import moment from 'moment';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { selectShopInfo, shopDetail } from 'src/app/Auth/Login/Login.slice';
import { errorCode } from 'src/constants/errorCode';
import { toast } from 'react-toastify';
import { locationWorkingHour, selectLoading } from './WorkingHours.slice';
import { useTranslation } from 'react-i18next';

const WorkingHours: FC<IPops> = ({ setStep }) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const loading = useAppSelector(selectLoading);
    const shop = useAppSelector(selectShopInfo);
    const locationName = shop?.locations[0]?.name;
    const isWorkingHoursCompleted = shop?.account_step?.working_hours;

    const schema = Yup.object().shape({
        id: Yup.number().required(t('ID required')),
        hours: Yup.array()
            .of(
                Yup.object().shape({
                    day: Yup.string().required(t('Day required')).oneOf(['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'], t('Invalid day')),
                    status: Yup.boolean().required(t('Status required')),

                    from_time_hours: Yup.string()
                        .required(t('From time hours required'))
                        .test({
                            name: 'from_valid_hours_1',
                            message: t('From hours must be between 01 to 12'),
                            test: function (value) {
                                const { status } = this.parent;
                                if (status) {
                                    const numericValue = parseInt(value, 10);
                                    return !isNaN(numericValue) && numericValue >= 1 && numericValue <= 12;
                                }
                                return true;
                            },
                        })
                        .test({
                            name: 'from_valid_hours_2',
                            message: t("'From' time must be less than 'To' time"),
                            test: function (value) {
                                const {
                                    from_time_minute: fromTimeMinute,
                                    from_time_type: fromTimeType,
                                    to_time_hours: toTimeHours,
                                    to_time_minute: toTimeMinute,
                                    to_time_type: toTimeType,
                                    status,
                                } = this.parent;

                                if (status) {
                                    const fromMoment = moment(`${value}:${fromTimeMinute} ${fromTimeType}`, 'hh:mm a');
                                    const toMoment = moment(`${toTimeHours}:${toTimeMinute} ${toTimeType}`, 'hh:mm a');

                                    if (fromMoment.isSameOrAfter(toMoment)) {
                                        return false;
                                    } else {
                                        return true;
                                    }
                                }
                                return true;
                            },
                        }),

                    from_time_minute: Yup.string().required(t('From time minutes required')),

                    to_time_hours: Yup.string()
                        .required(t('To time hours required'))
                        .test({
                            name: 'to_valid_hours_1',
                            message: t('To hours must be between 01 to 12'),
                            test: function (value) {
                                const { status } = this.parent;
                                if (status) {
                                    const numericValue = parseInt(value, 10);
                                    return !isNaN(numericValue) && numericValue >= 1 && numericValue <= 12;
                                }
                                return true;
                            },
                        })
                        .test({
                            name: 'to_valid_hours_2',
                            message: t("'To' time must be greater than 'From' time"),
                            test: function (value) {
                                const {
                                    from_time_hours: fromTimeHours,
                                    from_time_minute: fromTimeMinute,
                                    from_time_type: fromTimeType,
                                    to_time_minute: toTimeMinute,
                                    to_time_type: toTimeType,
                                    status,
                                } = this.parent;

                                if (status) {
                                    const fromMoment = moment(`${fromTimeHours}:${fromTimeMinute} ${fromTimeType}`, 'hh:mm a');
                                    const toMoment = moment(`${value}:${toTimeMinute} ${toTimeType}`, 'hh:mm a');

                                    if (toMoment.isSameOrBefore(fromMoment)) {
                                        return false;
                                    } else {
                                        return true;
                                    }
                                }
                                return true;
                            },
                        }),

                    to_time_minute: Yup.string().required(t('To time minutes required')),

                    from_time_type: Yup.string().required(t('From time type required')).oneOf(['am', 'pm'], t('Invalid from time type')),

                    to_time_type: Yup.string().required(t('To time type required')).oneOf(['am', 'pm'], t('Invalid from time type')),
                }),
            )
            .required(t('Working hours required')),
    });

    const {
        handleSubmit,
        control,
        formState: { errors },
        setError,
        setValue,
        resetField,
        // } = useForm<IWorkingHoursValue>({
    } = useForm<any>({
        resolver: yupResolver(schema),
        defaultValues: {
            id: shop.locations[0]?.id,
            hours: workingHoursData,
        },
    });

    useEffect(() => {
        const workingHours = shop?.locations[0]?.working_hours;
        if (isWorkingHoursCompleted && workingHours.length) {
            const existingWorkingHours = workingHours.map((workingHour: any) => {
                const momentFromTime = moment(workingHour.from, 'HH:mm:ss');
                const momentToTime = moment(workingHour.to, 'HH:mm:ss');

                const fromTimeHours = momentFromTime.format('hh');
                const fromTimeMinute = momentFromTime.format('mm');
                const fromTimeType = momentFromTime.format('a');

                const toTimeHours = momentToTime.format('hh');
                const toTimeMinute = momentToTime.format('mm');
                const toTimeType = momentToTime.format('a');

                return {
                    day: workingHour.day,
                    status: workingHour.status,
                    from_time_hours: fromTimeHours,
                    from_time_minute: fromTimeMinute,
                    to_time_hours: toTimeHours,
                    to_time_minute: toTimeMinute,
                    from_time_type: fromTimeType,
                    to_time_type: toTimeType,
                };
            });
            setValue('hours', existingWorkingHours);
        }
    }, []);

    const handleChange = async (data: any) => {
        const outputData = data.hours.map(
            (day: { from_time_hours: any; from_time_minute: any; from_time_type: any; to_time_hours: any; to_time_minute: any; to_time_type: any; day: any; status: any }) => {
                const fromtime12hour = `${day.from_time_hours}:${day.from_time_minute} ${day.from_time_type}`;
                const totime12hour = `${day.to_time_hours}:${day.to_time_minute} ${day.to_time_type}`;

                const fromtime24hour = moment(fromtime12hour, 'hh:mm a').format('HH:mm:ss');
                const totime24hour = moment(totime12hour, 'hh:mm a').format('HH:mm:ss');

                return {
                    day: day.day,
                    status: day.status,
                    from: fromtime24hour,
                    to: totime24hour,
                };
            },
        );

        const finalOutput = { id: data.id, hours: outputData };

        const result = await dispatch(locationWorkingHour(finalOutput));
        if (result.type === locationWorkingHour.fulfilled.toString()) {
            dispatch(shopDetail());
            setStep(5);
        }
        if (result.type === locationWorkingHour.rejected.toString()) {
            const response = result.payload.data;
            if (response.status === errorCode.unprocessable) {
                if (response.data) {
                    Object.keys(response.data).forEach((field) => {
                        // setError(field as FieldKey, {
                        //     type: 'manual',
                        //     message: response.data[field][0],
                        // });
                    });
                }
                return;
            }
            toast.error(response.message);
        }
    };

    return (
        <>
            <div className="w-[872px] 2xl:w-[1176px] flex-1 flex flex-col mt-[52px] items-center">
                <div className="text-center">
                    <h3 className="fwpo_heading_title w-[500px] mx-auto">
                        {t('Add working hours for')}
                        {locationName}
                    </h3>
                    <p className="text-secondaryTxtColor leading-[140%] -tracing-[0.14px] mt-4">{t('working_hours_description')}</p>
                </div>
                <form onSubmit={handleSubmit(handleChange)}>
                    <div className="w-[800px] 2xl:w-[1056px] flex flex-1  flex-col gap-5 mt-10">
                        <OpningHour control={control} errors={errors} setValue={setValue} setError={setError} resetField={resetField} />
                    </div>
                    <div className="flex w-full px-10 justify-center my-[60px]">
                        <div className="back-button absolute left-10">
                            <Button className="btn_outline_black text-sm" onClick={() => setStep(3)} type="button" disabled={loading}>
                                <FaArrowLeftLong size={18} className="mr-2" /> {t('Back')}
                            </Button>
                        </div>
                        <Button isLoading={loading} className="fl-btn btn_primary w-[420px] 2xl:w-[572px]">
                            {t('Continue')}
                        </Button>
                    </div>
                </form>
            </div>
        </>
    );
};

export default WorkingHours;
