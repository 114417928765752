import React, { FC, Ref, forwardRef, useEffect, useState } from 'react';
import { IDefaultProp, IFormProp } from './WaitList.interface';
import { Link } from 'react-router-dom';
import { Images } from 'src/theme';
import ReactDatePicker from 'react-datepicker';
import moment from 'moment';
import { format } from 'date-fns';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { selectCalendarData } from '../../Calendar.slice';
import { API } from 'src/constants/api';
import { axiosGet, axiosPost } from 'src/utils/requestClient';
import { selectShopInfo, shopDetail } from 'src/app/Auth/Login/Login.slice';
import { errorCode } from 'src/constants/errorCode';
import { toast } from 'react-toastify';
import Button from 'src/components/Button';
import SelectBox from 'src/components/SelectBox/SelectBox';
import { formatShortName } from 'src/utils/global-functions';
import { clientList } from 'src/app/Client/Client.slice';
import AddClient from '../AddClient';
import ClientDetail from '../../ClientDetail';
import { ISidebarSchema, ISidebarState } from '../Sidebar.Interface';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { usePhone } from 'src/hooks/usePhone';
import { FaPlus } from 'react-icons/fa';
import ServiceDetail from '../../ServiceDetail';
import { getStaffDetails } from 'src/app/Staff/Staff.slice';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import InputWithLabel from 'src/components/InputWithLabel';
const initialSidebarState = {
    disabledServices: [],
    serviceArrayList: [],
    selectedDate: moment().toDate(),
    selectedTime: moment().toDate(),
    selectedOption: null,
    serviceOptionShow: true,
};
const Form: FC<IFormProp> = ({ handleAction }) => {
    const calendarData: any = useAppSelector(selectCalendarData);
    // calendarData.selectedLocation
    const shop: any = useAppSelector(selectShopInfo);
    const shopId = shop.id;
    const dispatch = useAppDispatch();
    const [staffList, setStaffList] = useState([]);
    const [selectedStaff, setSelectedStaff] = useState<any>(undefined);
    const [clientArrayList, setClientArrayList] = useState([]);
    const [isNewClient, setIsNewClient] = useState(false);
    const [isBtnDisable, setIsBtnDisable] = useState(false);

    const [sidebarState, setSidebarState] = useState<ISidebarState>(initialSidebarState);

    const handleSelectChange = (selectedOptionInfo: any) => {
        setIsNewClient(false);
        setValue('user_id', selectedOptionInfo.id);
        setSidebarState((prevState) => ({
            ...prevState,
            selectedOption: selectedOptionInfo,
        }));
    };
    // useEffect(() => {
    //     setValue('staff_id', selectedStaff.id);
    // }, [sidebarState]);
    const formSchema = Yup.object({
        staff_id: Yup.number().required('Staff ID is required'),
        user_id: Yup.number().required('User ID is required'),
        services: Yup.array()
            .of(
                Yup.object().shape({
                    id: Yup.number().required('Service ID is required'),
                    qty: Yup.number().required('Quantity is required'),
                }),
            )
            .min(1, 'At least one service must be selected'),
        booking_date: Yup.date().required('Booking date is required'),
    }).required();
    const {
        control,
        handleSubmit,
        formState: { errors },
        setValue,
    } = useForm<ISidebarSchema>({
        resolver: yupResolver(formSchema) as any,
        defaultValues: {
            services: null,
            note: '',
        },
        mode: 'onChange',
    });
    const { fields, remove, append } = useFieldArray({
        control,
        keyName: 'uuid',
        name: 'services',
    });

    // const methods = useForm<ISidebarSchema>({
    //     resolver: yupResolver(schema) as any,
    //     defaultValues: {
    //         services: null,
    //         payment_method: 'pay_online',
    //     },
    // });
    // useEffect(() => {
    //     if (sidebarState.selectedOption) {
    //         setValue('user_id', sidebarState.selectedOption.id);
    //     }
    // }, [sidebarState.selectedOption]);

    const [serviceOptionShow, setServiceOptionShow] = useState<boolean>(true);

    const updatedServicesList = sidebarState.serviceArrayList.map((service) => ({
        ...service,
        isDisabled: sidebarState.disabledServices.includes(service.value as number),
    }));

    const handleChangeService = (value: any) => {
        const selectedService = sidebarState.serviceArrayList.find((service) => service.value === value.value);
        if (selectedService) {
            setSidebarState((prevState: any) => ({
                ...prevState,
                disabledServices: [...prevState.disabledServices, value.value],
            }));
        }
        setServiceOptionShow(false);

        const changeServiceData = {
            name: selectedService?.name,
            price: selectedService?.price,
            id: selectedService?.id,
        };

        addServices(changeServiceData);
    };
    const addServices = (info: any) => {
        const duration = moment.duration(info.price.duration);
        const formattedDuration = moment.utc(duration.asMilliseconds()).format(duration.hours() > 0 ? 'H [hours] m [minutes]' : 'm [minutes]');

        append({
            name: info.name,
            price: info.price.price,
            staff_name: `${selectedStaff.first_name} ${selectedStaff.last_name}`,
            time_slot: moment(selectedDate).format('YYYY-MM-DD'),
            duration: formattedDuration,
            id: info.id,
            is_custom_price: false,
            qty: 1,
        });
    };
    const onSubmit = async (data: any) => {
        // Process your form data
        setIsBtnDisable(true);
        const services = data.services.map((item: any) => ({
            id: item.id,
            quantity: item.qty,
            is_custom_price: item.is_custom_price,
            price: item.price,
        }));

        const payload = {
            shop_location_id: calendarData.selectedLocation.id,
            staff_id: data.staff_id,
            user_id: data.user_id,
            wait_date: moment(data.booking_date).format('yyyy-MM-DD'),
            services: services,
            note: data.note,
        };
        try {
            const response = await axiosPost(API.WAITLIST.CREATE, payload, {
                shop_id: shopId,
            });
            if (response.data?.status === errorCode.success || response.data?.status === errorCode.updateSuccess) {
                await handleAction('list');
                return;
            }
            throw response.data;
        } catch (err: any) {
            toast.error(err?.message);
        } finally {
            // setIsLoading(false);
            setIsBtnDisable(false);
        }
        console.log('data', data);
    };

    const onError = (errors2: any) => {
        console.error(errors2);
    };
    const { getCustomFormatPhone } = usePhone();

    const handleQtyChange = (newQuantity: number, index: number) => {
        const updatedFields: any = [...fields];
        updatedFields[index].qty = newQuantity;
        setValue('services', updatedFields);
    };
    const handleCustomPriceChange = (price: any, index: number) => {
        const updatedFields: any = [...fields];
        updatedFields[index].price = price;
        setValue('services', updatedFields);
    };
    const handleIsCustomPrice = (is_custom_price: boolean, index: number) => {
        const updatedFields: any = [...fields];
        updatedFields[index].is_custom_price = is_custom_price;

        setValue('services', updatedFields);
    };
    const handleRemoveService = (index: number) => {
        const removedService: any = fields[index];
        setSidebarState((prevState: any) => ({
            ...prevState,
            disabledServices: [...prevState.disabledServices.filter((id: any) => id !== removedService?.id)],
        }));

        remove(index);
    };
    const getStaffList = async () => {
        try {
            const response = await axiosGet(
                API.STAFF.LIST,
                {
                    shop_id: shopId,
                },
                { location_id: calendarData.selectedLocation.id },
            );
            if (response.data?.status === errorCode.success || response.data?.status === errorCode.updateSuccess) {
                const staffOptionData = response.data.data.map((item: any) => ({
                    ...item,
                    value: item.id,
                    label: formatShortName(item.first_name, item.last_name),
                }));
                setStaffList(staffOptionData);
                return;
            }
            throw response.data;
        } catch (err: any) {
            toast.error(err?.message);
        } finally {
            // setIsLoading(false);
        }
    };

    console.log('selectedStaff', typeof selectedStaff, selectedStaff !== null);

    console.log('');
    useEffect(() => {
        const fetchData = async () => {
            if (typeof selectedStaff === 'object') {
                await getServiceList(); // Assuming this also returns a promise
            }
        };
        fetchData();
    }, [selectedStaff]);
    const getClientList = async () => {
        const payload: any = {
            shop_id: shopId,
            data: {
                filters: {
                    type: { value: 'all' },
                },
            },
        };

        const result = await dispatch(clientList(payload));
        if (result.type === clientList.fulfilled.toString()) {
            const clientOptionData = result.payload.data.data.map((item: any) => ({
                ...item,
                value: item.id,
                label: `${item.full_name ?? getCustomFormatPhone(item.phone, item.phone_country_code)}`,
            }));
            setClientArrayList(clientOptionData);
        }
    };
    console.log('selectedStaff', selectedStaff);
    const [selectedDate, setSelectedDate] = useState<Date>(moment().toDate());

    const getServiceList = async () => {
        const data = {
            shop_id: shopId,
            id: selectedStaff && selectedStaff?.id,
        };
        const result = await dispatch(getStaffDetails(data));
        if (result.type === getStaffDetails.fulfilled.toString()) {
            const staffOptionData = result.payload.data.services.map((item: any) => ({
                ...item,
                value: item.id,
                label: item.name,
            }));
            await setSidebarState((prevState) => ({
                ...prevState,
                serviceArrayList: staffOptionData,
            }));
            // setIsLoading(false);
            // await handleBookedSlot();
        }
    };

    useEffect(() => {
        getStaffList();
        getClientList(); // Assuming this returns a promise
        console.log('hello', calendarData.selectedLocation);
    }, []);
    const handleSelectedStaff = (selectedOptionInfo: any) => {
        setSelectedStaff(selectedOptionInfo);
        setValue('staff_id', selectedOptionInfo.id);
    };
    useEffect(() => {
        const isValidDate = moment(selectedDate).isValid();
        console.log('isvalide', isValidDate);

        if (isValidDate) {
            setValue('booking_date', format(selectedDate, 'yyyy-MM-dd'));
        }
    }, [selectedDate]);
    console.log('selectedOptionInfo', selectedStaff);
    const handleWaitlist = () => {
        handleAction('list');
    };

    const CustomDatepickerInput = forwardRef((props: any, ref: Ref<HTMLInputElement>) => (
        <div onClick={props.onClick} className="text-primary font-medium">
            {props.value ? format(new Date(props.value), 'eee, MMM d') : 'Select date'}
        </div>
    ));

    return (
        <>
            <div className="h-full">
                <div className="h-full flex flex-col">
                    <div className="header flex justify-between items-center px-4 pt-6">
                        <div className="">
                            <h1 className="text-base font-bold leading-[22.4px] -tracking-[0.16px] text-mainTextColor mb-1">New Waitlist Entry</h1>
                        </div>
                        <div>
                            <button className="rounded-[40px] border border-solid border-mainTextColor py-[10px] pr-8 pl-[33px] w-[110px] " type="button" onClick={() => handleAction('list')}>
                                Cancel
                            </button>
                        </div>
                    </div>
                    <div className="p-4 flex items-center">
                        <div className="date and time flex flex-auto border-y border-lineColor justify-evenly items-center">
                            <div className="w-[50%] py-5 px-4 border-r-[1px] border-solid border-lineColor ">
                                <div className="leading-[19.6px] -tracking-[0.14px] text-secondaryTxtColor">
                                    On{' '}
                                    <Controller
                                        name="booking_date"
                                        control={control}
                                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                                            <>
                                                <ReactDatePicker
                                                    customInput={<CustomDatepickerInput />}
                                                    selected={selectedDate}
                                                    onChange={(date) => {
                                                        onChange(moment(date).toDate());
                                                        setSelectedDate(moment(date).toDate());
                                                    }}
                                                />
                                                {errors.booking_date && <p className="text-error">{errors.booking_date.message}</p>}
                                            </>
                                        )}
                                    />
                                </div>
                            </div>
                            <div className="w-[50%] py-5 px-4">
                                <div className="leading-[19.6px] -tracking-[0.14px] text-secondaryTxtColor">At Anytime</div>
                            </div>
                        </div>
                    </div>

                    <div className="p-4 flex-1 overflow-y-auto">
                        {sidebarState.selectedOption && !isNewClient && <ClientDetail sidebarState={sidebarState} setSidebarState={setSidebarState} />}

                        {!isNewClient && !sidebarState.selectedOption && (
                            <>
                                <div className="mb-5">
                                    <div className="flex justify-between">
                                        <label htmlFor="newclient" className="fl-field-title-label">
                                            Search client
                                        </label>
                                        <Button type="button" onClick={() => setIsNewClient(true)} className="text-xs font-bold leading-[140%] -tracking-[0.12px] ">
                                            + Add new client
                                        </Button>
                                    </div>
                                    <Controller
                                        name="user_id"
                                        control={control}
                                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                                            <>
                                                <SelectBox
                                                    isSearchable
                                                    name="user_id"
                                                    id="newclient"
                                                    options={clientArrayList}
                                                    className=""
                                                    onChangeFunc={(event: any) => {
                                                        handleSelectChange(event);
                                                        onChange(event.id);
                                                    }}
                                                    placeholder="Search or add new client"
                                                />

                                                {errors.user_id && <p className="text-error">{errors.user_id.message}</p>}
                                            </>
                                        )}
                                    />
                                </div>
                            </>
                        )}
                        {isNewClient && !sidebarState.selectedOption && <AddClient getClientList={getClientList} handleSelectChange={handleSelectChange} setIsNewClient={setIsNewClient} />}

                        <div className="mb-5">
                            <div className="flex justify-between">
                                <label htmlFor="newclient" className="fl-field-title-label">
                                    Staff
                                </label>
                            </div>
                            <Controller
                                name="staff_id"
                                control={control}
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                    <>
                                        <SelectBox
                                            name="staff_id"
                                            id="staff_id"
                                            options={staffList}
                                            value={selectedStaff}
                                            defaultValue={selectedStaff}
                                            onChangeFunc={(event: any) => {
                                                handleSelectedStaff(event);
                                                onChange(event.id);
                                            }}
                                            className=""
                                            placeholder="Search or staff"
                                        />
                                        {errors.staff_id && <p className="text-error">{errors.staff_id.message}</p>}
                                    </>
                                )}
                            />
                        </div>

                        {fields.length > 0 && (
                            <div className="">
                                {fields.map((item: any, index: any) => (
                                    <ServiceDetail
                                        key={index}
                                        index={index}
                                        serviceInfo={item}
                                        handleRemoveService={() => handleRemoveService(index)}
                                        handleQtyChange={handleQtyChange}
                                        handleCustomPriceChange={handleCustomPriceChange}
                                        handleIsCustomPrice={handleIsCustomPrice}
                                        isUpdate={true}
                                        isWaitList={true}
                                    />
                                ))}
                            </div>
                        )}
                        {sidebarState.disabledServices.length !== sidebarState.serviceArrayList.length && serviceOptionShow && (
                            <div className="mb-5">
                                <label htmlFor="service" className="fl-field-title-label">
                                    Service
                                </label>

                                <SelectBox
                                    name="abc"
                                    // errorText={!!errors?.services}
                                    id="service"
                                    value={null}
                                    options={updatedServicesList}
                                    onChangeFunc={handleChangeService}
                                    className=""
                                    placeholder="Select a service"
                                />
                                {errors.services && <p className="text-error">{errors.services.message}</p>}
                            </div>
                        )}
                        {sidebarState.disabledServices.length !== sidebarState.serviceArrayList.length && !serviceOptionShow && (
                            <Button className="text-primary flex items-center justify-center text-center w-full p-2" type="button" onClick={() => setServiceOptionShow(true)}>
                                <FaPlus size={12} className="mr-2" /> Add Service
                            </Button>
                        )}
                        <div className="my-5">
                            <Controller
                                name="note"
                                control={control}
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                    <InputWithLabel
                                        type="text"
                                        label="Appointment note"
                                        placeholder="Enter Appointment note"
                                        onChange={onChange}
                                        value={value}
                                        name="amount_payin"
                                        textArea
                                        textAearClassName="rounded-3xl h-[220px]"
                                        rows={5}
                                        error={!!errors.note}
                                    />
                                )}
                            />
                        </div>
                    </div>

                    <div className="">
                        <Button type="button" isLoading={isBtnDisable} disabled={isBtnDisable} onClick={handleSubmit(onSubmit, onError)} className="fl-btn btn_primary rounded-none rounded-t-3xl">
                            {'Add to Waitlist'}
                        </Button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Form;
