import { CountryCode } from 'libphonenumber-js';

export type IActionType = {
    info: boolean;
    create: boolean;
    update: boolean;
    remove: boolean;
    list: boolean;
    default: boolean;
};
export const initAction: IActionType = {
    info: false,
    create: false,
    update: false,
    remove: false,
    list: false,
    default: false,
};

export type IDefaultProp = {
    handleAction: (type: string) => void;
};

export type IFormProp = {
    handleAction: (type: string) => void;
};

export type IInfoProp = {
    handleAction: (type: string) => void;
    listDetail: any;
    setListDetail: (type: string | null) => void;
    getWaitlist: () => void;
};

interface Shop {
    id: number;
    created_by: number;
    slug: string;
    business_name: string;
    address: string;
    city: string;
    state: string;
    country: string;
    staff_size: string;
    logo_image_name: string;
    app_logo_image_name: string | null;
    contract_pdf_name: string;
    contract_name: string;
    contract_business_name: string;
    contract_business_address: string;
    website: string | null;
    google_review: string | null;
    facebook_page: string | null;
    instagram_page: string | null;
    web_app_url: string | null;
    play_store_url: string | null;
    app_store_url: string | null;
    first_reminder: number;
    second_reminder: number;
    is_first_reminder: boolean;
    is_second_reminder: boolean;
    is_book_with_card: boolean;
    account_step: any;
    contract_accepted_at: string;
    deleted_at: string | null;
    created_at: string;
    updated_at: string;
    logo_image_url: string;
    app_logo_image_url: string | null;
    contract_pdf_url: string;
}

interface Location {
    id: number;
    shop_id: number;
    sales_tax_id: number;
    name: string;
    country: string;
    city: string;
    street: string;
    postal_code: string;
    latitude: string;
    longitude: string;
    location_image_name: string;
    tips_type: string;
    tips: string[];
    timezone: string;
    deleted_at: string | null;
    created_at: string;
    updated_at: string;
    location_image_url: string;
    total_users: number;
}

interface Staff {
    id: number;
    created_by: number;
    stripe_customer_id: string;
    stripe_account_id: string;
    first_name: string;
    last_name: string;
    username: string;
    full_name: string;
    phone_country_code: string;
    phone: string;
    email: string;
    birth_date: string;
    gender: string;
    about: string;
    profile_image: string;
    instagram_link: string;
    is_notification: boolean;
    is_verified_stripe_account: boolean;
    notification: any;
    login_at: string;
    email_verified_at: string;
    phone_verified_at: string | null;
    deleted_at: string | null;
    created_at: string;
    updated_at: string;
    profile_image_url: string;
}
interface User {
    id: number;
    created_by_admin_id: number | null;
    created_by_staff_id: number | null;
    location_id: number | null;
    referrer_id: number | null;
    stripe_customer_id: string;
    phone_country_code: CountryCode;

    phone: string;
    email: string;
    first_name: string;
    last_name: string;
    full_name: string;
    birthdate: string; // or Date if you prefer to work with Date objects
    profile_image_name: string;
    referral_code: string;
    referral_offer: string | null;
    login_type: 'web' | string; // Specify other types if available
    gender: 'male' | 'female' | 'other'; // Extend this based on your application's supported genders
    is_user_verified: boolean;
    is_allowed_notification: boolean;
    remember_me: number; // Could also be boolean based on its usage
    notification: any;
    is_out_of_town: boolean;
    is_completed_account: boolean;
    return_at: string | null; // or Date
    login_at: string; // or Date
    email_verified_at: string; // or Date
    created_at: string; // or Date
    updated_at: string; // or Date
    profile_image_url: string;
    total_loyalty_points: number;
}
interface WaitlistService {
    id: number;
    waitlist_id: number;
    staff_service_id: number;
    is_custom_price: boolean;
    price: string;
    quantity: number;
    duration: string;
    created_at: string;
    updated_at: string;
    staff_service: StaffService;
}

interface StaffService {
    id: number;
    shop_id: number;
    staff_id: number;
    shop_service_id: number;
    duration: string;
    price: string;
    starting_price: boolean;
    status: boolean;
    description: string;
    created_at: string;
    updated_at: string;
    service: ServiceDetail;
}

interface ServiceDetail {
    id: number;
    shop_id: number;
    shop_category_id: number;
    name: string;
    tags: null | string[]; // If tags are expected to be an array of strings, otherwise keep it as null if unused
    description: string;
    is_active: boolean;
    deleted_at: null | string; // Assuming this can be null or a string date, adjust based on actual use
    created_at: string;
    updated_at: string;
}
interface WaitlistService {
    id: number;
    waitlist_id: number;
    staff_service_id: number;
    is_custom_price: boolean;
    price: string;
    quantity: number;
    duration: string;
    created_at: string;
    updated_at: string;
    staff_service: StaffService;
}

export interface WaitListEntry {
    id: number;
    shop_id: number;
    shop_location_id: number;
    staff_id: number;
    user_id: number;
    wait_date: string;
    created_by: string;
    note: string | null;
    deleted_at: string | null;
    created_at: string;
    updated_at: string;
    shop: Shop;
    location: Location;
    staff: Staff;
    user: User;
    waitlist_services: WaitlistService[];
}

export type IListProp = {
    handleAction: (type: string) => void;
};

export interface IEntryOption {
    label: JSX.Element;
    value: string;
    icon1: JSX.Element;
    icon1Position: string;
    icon2: JSX.Element;
    icon2Position: string;
}
