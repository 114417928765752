import { yupResolver } from '@hookform/resolvers/yup';
import { format } from 'date-fns';
import React, { Ref, forwardRef, useEffect, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { Controller, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { selectShopInfo } from 'src/app/Auth/Login/Login.slice';
import { MdOutlineMoreVert } from 'src/assets/customsvg';
import Button from 'src/components/Button';
import DropDownWithIcon from 'src/components/DropDownWithIcon';
import InputWithLabel from 'src/components/InputWithLabel';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { Images } from 'src/theme';
import * as Yup from 'yup';
import { IBlockTime } from './Sidebar.Interface';
import { blockTimeCreate, blockTimeUpdate, selectCalendarData, setData } from '../Calendar.slice';
import moment from 'moment-timezone';
import { errorCode } from 'src/constants/errorCode';
import { toast } from 'react-toastify';
import { IoMdClose } from 'react-icons/io';
import { SlArrowRight } from 'react-icons/sl';
import { TfiPencilAlt } from 'react-icons/tfi';
import Toggle from 'src/components/Toggle/Toggle';
type FieldKey = 'name';
type ActionType = 'new' | 'edit' | 'delete' | 'info' | null;
const BlockTime = () => {
    const dispatch = useAppDispatch();
    const shop = useAppSelector(selectShopInfo);
    const calendarData: any = useAppSelector(selectCalendarData);
    const [fromDate, setFromDate] = useState(moment(calendarData.selectedDate).toDate());
    const [fromTime, setFromTime] = useState(moment(calendarData.selectedTime).toDate());
    const [toDate, setToDate] = useState(moment(calendarData.selectedDate).toDate());
    const [toTime, setToTime] = useState(moment(calendarData.selectedTime).toDate());
    const [staffId, setStaffId] = useState(null);
    const [action, setAction] = useState<ActionType>(null);
    const shopId = shop.id;
    const schema = Yup.object().shape({
        name: Yup.string().required('Title is required'),
        // Add other fields as needed
    });
    const [timeZone, setTimeZone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone);
    useEffect(() => {
        if (calendarData.selectedLocation) {
            const filteredData = calendarData.locationInfoList.filter((item: any) => item.id === calendarData.selectedLocation.id);
            moment.tz.setDefault(filteredData[0].timezone);
            setTimeZone(filteredData[0].timezone);
        }
    }, [calendarData.selectedLocation]);
    console.log('fromDate', fromDate);
    console.log('fromTime', fromTime);
    console.log('toDate', toDate);
    console.log('toTime', toTime);

    const {
        handleSubmit,
        control,
        formState: { errors },
        setError,
        setValue,
        reset,
    } = useForm<IBlockTime>({
        resolver: yupResolver(schema),
        defaultValues: {
            name: '',
        },
    });
    const [switch2, setSwitch2] = useState(true);
    const handleToggle = () => {
        setSwitch2(!switch2);
    };
    useEffect(() => {
        if (calendarData.bookingInfo !== null) {
            setFromDate(new Date(calendarData.bookingInfo.from));
            setFromTime(new Date(calendarData.bookingInfo.from));
            setToTime(new Date(calendarData.bookingInfo.to));
            setToDate(new Date(calendarData.bookingInfo.to));
            setAction('info');
            setStaffId(calendarData.bookingInfo.staff_id);
            setValue('name', calendarData.bookingInfo.name);
        } else {
            setAction('new');
        }
    }, [calendarData.bookingInfo, timeZone]);

    const onSubmit = async (data: any) => {
        const formattedFromDate = format(fromDate, 'yyyy-MM-dd');
        const formattedFromTime = format(fromTime, 'HH:mm:ss');
        const fromDateTime = `${formattedFromDate} ${formattedFromTime}`;
        const formattedToDate = format(toDate, 'yyyy-MM-dd');
        const formattedToTime = format(toTime, 'HH:mm:ss');
        const toDateTime = `${formattedToDate} ${formattedToTime}`;

        const payload = {
            is_full_day: false,
            name: data.name,
            from: fromDateTime,
            to: toDateTime,
        };

        const submitData = {
            payload: payload,
            params: {
                shop_id: shopId,
                staff_id: calendarData.staffInfo.id,
            },
        };
        if (action === 'edit') {
            const submitData2 = {
                payload: payload,
                params: {
                    shop_id: shopId,
                    staff_id: staffId,
                    id: calendarData.bookingInfo.id,
                },
            };
            const result = await dispatch(blockTimeUpdate(submitData2));
            if (result.type === blockTimeUpdate.fulfilled.toString()) {
                // await getClientList(); // Properly invoke the function
                // await handleSelectChange(result.payload.data);
                // handleClose();
                reset();
                const updateState = {
                    getBookingList: true,
                };
                await dispatch(setData(updateState));
                // getClientList();
            }

            if (result.type === blockTimeUpdate.rejected.toString()) {
                const response = result.payload.data;
                if (response.status === errorCode.unprocessable) {
                    if (response.data) {
                        Object.keys(response.data).forEach((field) =>
                            setError(field as FieldKey, {
                                type: 'manual',
                                message: response.data[field][0],
                            }),
                        );
                    }
                    return;
                }
                toast.error(response.message);
            }
        } else {
            const result = await dispatch(blockTimeCreate(submitData));
            if (result.type === blockTimeCreate.fulfilled.toString()) {
                // await getClientList(); // Properly invoke the function
                // await handleSelectChange(result.payload.data);
                // handleClose();
                reset();
                const updateState = {
                    getBookingList: true,
                };
                await dispatch(setData(updateState));
                // getClientList();
            }

            if (result.type === blockTimeCreate.rejected.toString()) {
                const response = result.payload.data;
                if (response.status === errorCode.unprocessable) {
                    if (response.data) {
                        Object.keys(response.data).forEach((field) =>
                            setError(field as FieldKey, {
                                type: 'manual',
                                message: response.data[field][0],
                            }),
                        );
                    }
                    return;
                }
                toast.error(response.message);
            }
        }
    };
    useEffect(() => {}, []);
    const TimePickerInput = forwardRef((props: any, ref: Ref<HTMLInputElement>) => (
        <span className="text-primary font-medium" onClick={props.onClick}>
            {props.value}
        </span>
    ));
    const CustomDatepickerInput = forwardRef((props: any, ref: Ref<HTMLInputElement>) => (
        <div onClick={props.onClick} className="text-primary font-medium">
            {props.value ? format(new Date(props.value), 'eee, MMM d') : 'Select date'}
        </div>
    ));
    const actionBtnIcon = [
        {
            label: <span>Delete</span>,
            value: 'delete_blocktime',
            icon1: <IoMdClose className="text-dangerText" size={20} />,
            icon1Position: 'left',
            icon2: <SlArrowRight className="text-secondaryTxtColor" />,
            icon2Position: 'right',
        },

        {
            label: <span>Edit </span>,
            value: 'edit_blocktime',
            icon1: <TfiPencilAlt className="text-primary" size={20} />,
            icon1Position: 'left',
            icon2: <SlArrowRight className="text-secondaryTxtColor" />,
            icon2Position: 'right',
        },
    ];
    const handleOption = async (e: any) => {
        if (e.value === 'edit_blocktime') {
            setAction('edit');
        } else if (e.value === 'delete_blocktime') {
            const updateState = {
                removeBlockTime: {
                    isRemoveBlockTime: true,
                    blockTimeId: calendarData.bookingInfo.id,
                    staffId: calendarData.bookingInfo.staff_id,
                },
            };
            await dispatch(setData(updateState));
        }
    };
    return (
        <div className="h-full">
            <form className="h-full flex flex-col">
                <div className="p-4 flex items-center">
                    {action === 'edit' && (
                        <Link className="border border-lineColor rounded-lg p-1 mr-3" to="" onClick={() => setAction('info')}>
                            <img src={Images.IconChevronRight} alt="" className="" />
                        </Link>
                    )}

                    <h4 className="text-base font-bold leading-[140%] -tracking-[0.16px]">New Time Block</h4>
                    {action === 'info' && (
                        <DropDownWithIcon
                            dropwDownContainerClass="ml-auto"
                            className=""
                            labelIcon={<MdOutlineMoreVert stroke="#1642C5" currentColor="#1642C5" />}
                            labelActiveIcon={<MdOutlineMoreVert stroke="#E3E7EF" currentColor="#1F293C" />}
                            dropDownMenuClassName="w-[324px] top-[100%] py-6"
                            dropDownMenuItemClassName="border-b text-left py-5 last:border-0"
                            dropDownMenuLinkClassName="mx-3"
                            data={actionBtnIcon}
                            setselected={handleOption}
                        />
                    )}
                </div>
                <div className="flex-1 overflow-y-auto">
                    {action === 'info' && (
                        <div className="">
                            <div className="p-4 border-y border-lineColor">
                                <h3 className="font-bold mb-3">{calendarData.bookingInfo.name}</h3>
                                <div className="flex items-center">
                                    <div className="text-secondaryTxtColor w-full max-w-[88px] grow-0 shrink-0 basis-[88px] pr-3">From:</div>
                                    <p className="font-medium">{`${moment(calendarData.bookingInfo.from, 'YYYY-MM-DD HH:mm:ss').format('ddd, MMM D [at] h:mm A')}`}</p>
                                </div>
                                <div className="flex items-center mt-3">
                                    <div className="text-secondaryTxtColor w-full max-w-[88px] grow-0 shrink-0 basis-[88px] pr-3">To:</div>
                                    <p className="font-medium">{`${moment(calendarData.bookingInfo.to, 'YYYY-MM-DD HH:mm:ss').format('ddd, MMM D [at] h:mm A')}`}</p>
                                </div>
                            </div>
                            <div className="p-4">
                                <div className="flex items-center mb-4">
                                    <i className="mr-3">
                                        <img src={Images.iconWallClockOutlinePrimary} alt="" className="w-4" />
                                    </i>
                                    <div className="font-medium">
                                        <span className="text-secondaryTxtColor mr-1">Created on:</span>
                                        {` ${moment(calendarData.bookingInfo.created_at, 'YYYY-MM-DD HH:mm:ss').format('ddd, MMM D [at] h:mm A')}`}
                                    </div>
                                </div>
                                <div className="flex items-center mb-4">
                                    <i className="mr-3">
                                        <img src={Images.IconUserOutline} alt="" className="w-4" />
                                    </i>
                                    <div className="font-medium">
                                        <span className="text-secondaryTxtColor  mr-1">Created by:</span>
                                        <span className="">Static Name</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    {(action === 'edit' || action === 'new') && (
                        <>
                            <div className="header flex justify-between mb-4">
                                <div className="date and time flex flex-auto border-y border-lineColor justify-evenly items-center">
                                    <div className="w-[50%] py-5 px-4 border-r-[1px] border-solid border-lineColor ">
                                        <div className="leading-[19.6px] -tracking-[0.14px] text-secondaryTxtColor">
                                            From{' '}
                                            <ReactDatePicker
                                                customInput={<CustomDatepickerInput />}
                                                selected={fromDate}
                                                onChange={(date) => {
                                                    setFromDate(moment(date).toDate());
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="w-[50%] py-5 px-4">
                                        <div className="leading-[19.6px] -tracking-[0.14px] text-secondaryTxtColor">
                                            At{' '}
                                            <ReactDatePicker
                                                customInput={<TimePickerInput />}
                                                showTimeSelect
                                                showTimeSelectOnly
                                                timeIntervals={15}
                                                timeCaption="Time"
                                                dateFormat="h:mm aa"
                                                selected={fromTime}
                                                onChange={(date) => {
                                                    setFromTime(moment(date).toDate());
                                                }}
                                                // onChange={() => {}}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="px-4">
                                <Controller
                                    name="name"
                                    control={control}
                                    render={({ field: { onChange, value } }: any) => (
                                        <div className="mb-6">
                                            <InputWithLabel label="Title" placeholder="Enter Title" onChange={onChange} value={value} name="name" error={!!errors.name} />
                                            {errors?.name && <p className="text-error">{errors?.name?.message}</p>}
                                        </div>
                                    )}
                                />
                                <div className="mb-6 flex items-center">
                                    <p className="font-medium leading-[140%] -tracking-[0.3px]">All Day</p>

                                    <Toggle
                                        // label="Recurring Appointment"
                                        labelClassName="ml-auto"
                                        labelPosition="left"
                                        id="recurring_appointment"
                                        isChecked={switch2}
                                        onChange={handleToggle}
                                        className=""
                                        switchClassName="bg-secondaryTxtColor"
                                    />
                                </div>
                            </div>

                            <div className="header flex justify-between mb-4">
                                <div className="date and time flex flex-auto border-y border-lineColor justify-evenly items-center">
                                    <div className="w-[50%] py-5 px-4 border-r-[1px] border-solid border-lineColor ">
                                        <div className="leading-[19.6px] -tracking-[0.14px] text-secondaryTxtColor">
                                            To{' '}
                                            <ReactDatePicker
                                                customInput={<CustomDatepickerInput />}
                                                selected={toDate}
                                                onChange={(date) => {
                                                    setToDate(moment(date).toDate());
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="w-[50%] py-5 px-4">
                                        <div className="leading-[19.6px] -tracking-[0.14px] text-secondaryTxtColor">
                                            At{' '}
                                            <ReactDatePicker
                                                customInput={<TimePickerInput />}
                                                showTimeSelect
                                                showTimeSelectOnly
                                                timeIntervals={15}
                                                timeCaption="Time"
                                                dateFormat="h:mm aa"
                                                selected={toTime}
                                                onChange={(date) => {
                                                    setToTime(moment(date).toDate());
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </div>
                {(action === 'edit' || action === 'new') && (
                    <div className="">
                        <Button type="button" onClick={handleSubmit(onSubmit)} className="fl-btn btn_primary rounded-none rounded-t-3xl">
                            {action === 'edit' ? 'Edit Time Block' : 'Create Time Block'}
                        </Button>
                    </div>
                )}
            </form>
        </div>
    );
};

export default BlockTime;
