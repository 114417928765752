import React from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import DateSelect from 'src/components/DateSelect/DateSelect';
import InputWithLabel from 'src/components/InputWithLabel';
import Toggle from 'src/components/Toggle/Toggle';
import { Images } from 'src/theme';

const Rent = ({ shop_payment, control, errors, isEdit, staffInfo }: any) => {
    const { t } = useTranslation();
    return (
        <div>
            <p className="text-xs text-secondaryTxtColor leading-[150%] -tracking-[0.12px] mb-5 max-w-[590px] ">
                {t('Set rent structure', {
                    first_name: staffInfo.first_name,
                })}
            </p>

            <div className="flex -mx-[10px] flex-wrap mt-6">
                <div className="px-[10px] mb-5 w-full grow-0 shrink-0 basis-[50%] flex">
                    <Controller
                        name="auto_rent_payment"
                        control={control}
                        render={({ field: { onChange, value } }: any) => (
                            <>
                                <div className="mr-[10px] w-[579px]">
                                    <label htmlFor="is_annette_rent" className=" font-medium leading-[140%] -tracking-[0.14px] mb-1">
                                        {t('Automate rent payments')}
                                    </label>

                                    <p className="text-xs font-medium text-secondaryTxtColor leading-[140%] -tracking-[0.3px] mb-1">
                                        {shop_payment?.status === 'pending'
                                            ? t('rent_pending_status_description', {
                                                  first_name: staffInfo.first_name,
                                              })
                                            : shop_payment?.status === 'successfully_entered'
                                            ? ''
                                            : t('rent_status_description', {
                                                  first_name: staffInfo.first_name,
                                              })}
                                    </p>
                                    {shop_payment?.status === 'pending' ||
                                        (shop_payment?.status === 'successfully_entered' && (
                                            <label className=" font-medium leading-[140%] -tracking-[0.14px] mb-1">
                                                {t('Status')}: <span className={`${shop_payment?.status === 'pending' ? 'text-yellow-400' : 'text-green-400'}`}></span>
                                            </label>
                                        ))}
                                </div>
                                <Toggle
                                    name="auto_rent_payment"
                                    id="auto_rent_payment"
                                    isChecked={value}
                                    isDisabled={!isEdit}
                                    onChange={onChange}
                                    labelClassName="mt-[3px]"
                                    switchClassName="bg-secondaryTxtColor"
                                />
                            </>
                        )}
                    />
                </div>
                <div className="px-[10px] mb-5 w-1/2 grow-0 shrink-0 basis-[50%]">
                    <Controller
                        name="rent_money"
                        control={control}
                        render={({ field: { onChange, value } }: any) => (
                            <InputWithLabel
                                label={t('Enter rent money')}
                                value={value}
                                onChange={onChange}
                                disabled={!isEdit}
                                placeholder={t('Enter rent money')}
                                name="rent_money"
                                error={!!errors.rent_money}
                            />
                        )}
                    />
                </div>
                <div className="px-[10px] mb-5 w-1/2">
                    <Controller
                        name="effective_date"
                        control={control}
                        render={({ field: { onChange, value } }: any) => (
                            <DateSelect
                                label={t('Effective date')}
                                inputName="effective_date"
                                className={`form_control_date w-full form_input_control ${errors?.effective_date && 'is-invalid'}`}
                                placeholder="yyyy-mm-dd"
                                dateFormat="yyyy-MM-dd"
                                disabled={!isEdit}
                                errorText={''}
                                onChangeValue={onChange}
                                icon={<img src={Images.iconDateCalendar} alt="" className="w-5 " />}
                                value={value}
                                minDate={new Date()}
                                onChangeRaw={() => onchange}
                            />
                        )}
                    />
                </div>
                <div className="px-[10px] w-full">
                    <InputWithLabel
                        label={t('Rent collection frequency')}
                        placeholder={t('Please enter rent collection frequency')}
                        value={shop_payment?.rent_frequency ?? ''}
                        disabled
                        onChange={() => {}}
                        name={''}
                    />
                    <p className="p-2 text-xs text-secondaryTxtColor leading-[150%] -tracking-[0.12px] max-w-[590px] ">{t('The default payment day is the first day of the month.')}</p>
                </div>
                {(shop_payment?.rent_frequency === 'weekly' || shop_payment?.rent_frequency === 'bi-Weekly' || shop_payment?.rent_frequency === 'tri-Weekly') && (
                    <div className="px-[10px] w-1/2">
                        <InputWithLabel label={t('Payment day')} placeholder={t('Payment day')} value={shop_payment?.rent_day ?? ''} disabled name={''} onChange={() => {}} />
                    </div>
                )}
            </div>
        </div>
    );
};

export default Rent;
