import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Controller, useFormContext } from 'react-hook-form';

import Button from 'src/components/Button';
import { Images } from 'src/theme';
import AutoCompleteGoogle from 'src/components/AutoCompleteGoogle/AutoCompleteGoogle';

import { IDetail } from './Location.interface';
import AddNewForm from './Location.AddNewForm';
import SalesTaxesForm from './Location.SalesTaxesForm';
import Map from 'src/components/Map/Map';
import TipsDetailSelection from './Location.TipsDetailSelection';
import OpeningHour from '../Signup/WorkingHours/OpningHour';
import { errorCode } from 'src/constants/errorCode';
import { deleteLocationAction, locationListAction, updateLocationAction } from './Location.slice';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { selectShopInfo } from '../Auth/Login/Login.slice';
import Popup from 'src/components/Popup';

const Detail = ({
    handleClick,
    chooseFile,
    cropData,
    uploadProgress,
    showProgressBar,
    scriptStatus,
    handleAutocompleteChange,
    validForm,
    formData,
    locationAddress,
    isValidAddress,
    tipType,
    setTipType,
    trigger,
    territories,
    saleTaxes,
    setValue,
    setSelectedSaleTax,
    gotoListing,
    handleSubmit,
    seeDetails,
    modifySubmitData,
}: IDetail) => {
    useEffect(() => {
        // Scroll to top
        window.scrollTo(0, 0);
    }, []);
    const { getValues } = useFormContext();
    const locationFormdata = getValues();
    const shopInfo = useAppSelector(selectShopInfo);

    const dispatch = useAppDispatch();
    const [deleteDetails, setDeleteDetails] = useState(false);
    const [saveDetails, setSaveDetails] = useState(false);
    const [loading, setLoading] = useState(false);

    const onSaveContent = async (data: any) => {
        setLoading(true);
        const { status, payload }: any = await modifySubmitData(data);

        const editDetail = {
            payload: payload,
            id: seeDetails?.shopId,
        };

        if (status === errorCode.updateSuccess) {
            const result: any = await dispatch(updateLocationAction(editDetail));
            if (result?.type === updateLocationAction.fulfilled.toString()) {
                await dispatch(locationListAction({ shop_id: shopInfo?.id }));
                await setSaveDetails(false);
                gotoListing(false, null)();
            }
        }
        setLoading(false);
    };

    const handleDeleteLocation = async () => {
        setLoading(true);
        const payload = {
            shop_id: shopInfo?.id,
            id: seeDetails?.shopId,
        };
        const result: any = await dispatch(deleteLocationAction(payload));

        if (result?.type === deleteLocationAction.fulfilled.toString()) {
            await dispatch(locationListAction({ shop_id: shopInfo?.id }));
            setDeleteDetails(false);
            gotoListing(false, null)();
        }
        setLoading(false);
    };

    const deleteContent = (
        <div className="text-center">
            <h3 className="mb-5 text-xl font-bold text-mainTextColor -tracking-[0.4px]">Delete Location</h3>
            <p className="mb-8 text-sm font-normal text-secondaryTxtColor -tracking-[0.4px]">Are you sure you want to delete?</p>
            <div className="w-full">
                <Button className="w-full fl-btn px-[10px] btn_primary text-base text-center justify-center font-bold mb-3" onClick={() => setDeleteDetails(false)}>
                    No, cancel
                </Button>
                <Button className="w-full fl-btn text-base font-bold btn_outline_black px-[10px] justify-center " onClick={handleDeleteLocation} isLoading={loading} disabled={loading}>
                    {"Yes, I'm sure"}
                </Button>
            </div>
        </div>
    );
    const saveContent = (
        <div className="text-center">
            <h3 className="mb-5 text-xl font-bold text-mainTextColor -tracking-[0.4px]">Save Changes</h3>
            <p className="mb-8 text-sm font-normal text-secondaryTxtColor -tracking-[0.4px]">Are you sure you want to save?</p>
            <div className="w-full">
                <Button className="fl-btn btn_primary px-[10px] w-full mb-3 " onClick={handleSubmit(onSaveContent)} isLoading={loading} disabled={loading}>
                    {"Yes, I'm sure"}
                </Button>
                <Button className="w-full fl-btn text-base font-bold btn_outline_black px-[10px] justify-center" onClick={() => setSaveDetails(false)}>
                    No, cancel
                </Button>
            </div>
        </div>
    );

    return (
        <>
            <div className="detail-page-title-block">
                <div className="flex items-center">
                    <Link className="border border-lineColor rounded-lg p-1 mr-5" to="" onClick={handleClick(false, null)}>
                        <img src={Images.IconChevronRight} alt="" className="" />
                    </Link>
                    <h2 className="text-lg font-bold leading-[140%] -tracing-[0.4px]">{locationFormdata.location_name}</h2>
                </div>
                <div className="flex">
                    <Button className="fl-btn px-[50px] btn_outline_danger mr-5" onClick={() => setDeleteDetails(true)} type="button">
                        Delete
                    </Button>
                    <Button
                        className="fl-btn btn_primary px-[60px]"
                        onClick={async () => {
                            const validateTrue = await trigger();
                            if (validateTrue) {
                                setSaveDetails(true);
                            }
                        }}
                        type="button"
                    >
                        Save
                    </Button>
                </div>
            </div>
            <form>
                <div className="detail-page-data-block">
                    <div className="title-block">
                        <h3 className="title-h3">Location details</h3>
                    </div>
                    <div className="data-wrap-block max-w-[512px] 2xl:max-w-[700px]">
                        <AddNewForm
                            Controller={Controller}
                            chooseFile={chooseFile}
                            cropData={cropData}
                            uploadProgress={uploadProgress}
                            showProgressBar={showProgressBar}
                            status={scriptStatus}
                            handleAutocompleteChange={handleAutocompleteChange}
                            validForm={validForm}
                            formData={formData}
                            locationAddress={locationAddress}
                            isValidAddress={isValidAddress}
                        />
                    </div>
                </div>
                <div className="detail-page-data-block">
                    <div className="title-block">
                        <h3 className="title-h3">Map location</h3>
                        <p className="title-block-text ">525 East 68th Street, New York City, New York, United States, NY 10065</p>
                    </div>
                    <div className="data-wrap-block max-w-[512px] 2xl:max-w-[700px]">
                        {scriptStatus && (
                            <>
                                <h3 className="font-semibold text-xl leading-[124%] -tracking-[0.5px] mb-3">Map location</h3>
                                <p className="text-secondaryTxtColor leading-[140%] -tracing-[0.3px] mb-5">Drag the map so the pin matches the exact location of your business location.</p>
                                <AutoCompleteGoogle
                                    inputName="locationAddress"
                                    placeholder="Enter address"
                                    className="form_control"
                                    onChangeFunc={handleAutocompleteChange?.('locationAddress')}
                                    onBlur={handleAutocompleteChange?.('locationAddress')}
                                    errorText={!validForm?.locationAddress ? 'Address is required' : null}
                                    value={formData?.locationAddress.fullAddress || locationAddress}
                                />
                                {!isValidAddress?.locationAddress && 'Please select valid address'}
                                {isValidAddress?.locationAddress &&
                                    scriptStatus &&
                                    formData?.locationAddress &&
                                    formData?.locationAddress &&
                                    (formData?.locationAddress?.latitude ?? 0) !== 0 &&
                                    (formData?.locationAddress?.longitude ?? 0) !== 0 && (
                                        <div className="rounded-3xl h-[240px] bg-secondaryTxtColor overflow-hidden">
                                            <Map address={formData?.locationAddress} id={'consigneeMap'} onReverseAddressFunc={handleAutocompleteChange?.('locationAddress')} />
                                        </div>
                                    )}
                            </>
                        )}
                    </div>
                </div>
                <div className="detail-page-data-block relative z-10">
                    <div className="title-block">
                        <h3 className="title-h3">Sales taxes</h3>
                        <p className="title-block-text ">Set the sales tax bracket to add to transactions at checkout for this location.</p>
                    </div>
                    <div className="data-wrap-block max-w-[512px] 2xl:max-w-[700px]">
                        <SalesTaxesForm Controller={Controller} territories={territories} saleTaxes={saleTaxes} setValue={setValue} setSelectedSaleTax={setSelectedSaleTax} />
                    </div>
                </div>
                <div className="detail-page-data-block relative z-1">
                    <div className="title-block">
                        <h3 className="title-h3">Opening hours</h3>
                        <p className="title-block-text ">Opening hours for these locations are default working hours for your team and will be visible to your clients.</p>
                    </div>
                    <div className="data-wrap-block max-w-[512px] 2xl:max-w-[700px]">
                        <OpeningHour extraClassName="opening-hours-small opening-hours-xsmall" />
                    </div>
                </div>
                <div className="detail-page-data-block border-0">
                    <div className="title-block">
                        <h3 className="title-h3">Tips details</h3>
                        <p className="title-block-text ">Configure the tipping options for this location. Clients will view these options at checkout.</p>
                    </div>
                    <div className="data-wrap-block max-w-[512px] 2xl:max-w-[700px]">
                        <TipsDetailSelection className="location-detail-tips-detail-selection" selectionContent="Set up tips for this location" tipType={tipType} setTipType={setTipType} />
                    </div>
                </div>
            </form>
            {deleteDetails && <Popup size="max-w-[407px]" className="p-8 pb-10" handleClose={() => setDeleteDetails(false)} content={deleteContent} />}
            {saveDetails && <Popup size="max-w-[407px]" className="p-8 pb-10" handleClose={() => setSaveDetails(false)} content={saveContent} />}
        </>
    );
};

export default Detail;
