import React from 'react';
import Popup from 'src/components/Popup';
import { IAdminPopup } from './Admin.interface';
import Stepper from 'src/components/Stepper';

const AdminPopup = ({ handleCloseAdminPopup, renderStepComponent, step, STEPS }: IAdminPopup) => {
    const adminPopupContent = (
        <>
            <div className="mb-8 w-[325px] h-[46px] mx-auto">
                <Stepper activeStep={step} steps={STEPS} />
            </div>
            {renderStepComponent()}
        </>
    );

    return <Popup size="max-w-[896px]" className="p-8 pb-10" handleClose={() => handleCloseAdminPopup(false)} content={adminPopupContent} />;
};

export default AdminPopup;
