import React, { FC, useState } from 'react';
import { IResourceHeader } from './Calendar.Interface';
import { capitalizeFirstLetterSpace, getShortName } from 'src/utils/global-functions';

const ResourceHeader: FC<IResourceHeader> = ({ eventInfo }) => {
    const [isImageError, setIsImageError] = useState<boolean>(false);
    const handleImageError = () => {
        setIsImageError(true);
    };
    return (
        <div className="flex px-2 pb-[18px] pt-[10px]">
            <figure className="w-6 h-6 overflow-hidden rounded-full overflow-hidden flex text-xs font-medium justify-center items-center bg-primary text-white">
                {eventInfo.resource.profile_image_url && !isImageError ? (
                    <img src={eventInfo.resource.profile_image_url} alt="client" className="w-full h-full object-cover" onError={handleImageError} />
                ) : (
                    getShortName(`${eventInfo.resource.name}`)
                )}
            </figure>
            <div className="flex-1 ml-2 text-left">
                <h3 className="text-xs font-medium leading-[16px] -tracking-[0.12px]">{capitalizeFirstLetterSpace(eventInfo.resource.name)}</h3>
                <p className="font-normal text-secondaryTxtColor text-[10px] leading-[12px] -tracking-[0.1px]">{eventInfo.resource.role}</p>
            </div>
        </div>
    );
};
export default ResourceHeader;
