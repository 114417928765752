import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { CountryCode, parse } from 'libphonenumber-js';

import Button from 'src/components/Button';
import InputWithLabel from 'src/components/InputWithLabel';
import Number from 'src/components/Number';
import SelectBox from 'src/components/SelectBox/SelectBox';
import { IProps } from './Admin.interface';

const optionAccType = [
    { label: 'Admin', value: 'admin' },
    // { label: 'Reseptionist', value: 'receptionist' },
];
const AdminForm = ({ onClick, customError, setCustomError }: IProps) => {
    const { control, formState, setValue, getValues } = useFormContext();

    const errors: any = formState.errors;
    const handleNumberChange = (country: CountryCode, phone: string, code: string, isValid: boolean) => {
        if (isValid) {
            setValue(`phone`, `+${code}${phone}`);
            setValue(`phone_country_code`, country);
        } else {
            setValue(`phone`, '');
            setCustomError && setCustomError('');
        }
    };

    return (
        <>
            <div className="mb-10 text-center ">
                <h3 className="fwpo_heading_title w-[480px] mx-auto">Add new admin</h3>
            </div>
            <div className="h-full flex flex-wrap -mx-[10px]">
                <div className="px-[10px] mb-5 w-1/2">
                    <Controller
                        name="first_name"
                        control={control}
                        render={({ field: { onChange, value } }: any) => (
                            <div className="w-full">
                                <InputWithLabel
                                    label="First Name"
                                    placeholder="Enter Member’s First Name"
                                    onChange={onChange}
                                    value={value}
                                    name="first_name"
                                    isFocus={true}
                                    error={!!errors.first_name}
                                />
                                {errors && errors?.first_name && <p className="text-error">{errors?.first_name?.message}</p>}
                            </div>
                        )}
                    />
                </div>
                <div className="px-[10px] mb-5 w-1/2">
                    <Controller
                        name="last_name"
                        control={control}
                        render={({ field: { onChange, value } }: any) => (
                            <div className="w-full">
                                <InputWithLabel label="Last Name" placeholder="Enter Member’s Last Name" onChange={onChange} value={value} name="last_name" error={!!errors.last_name} />
                                {errors?.last_name && <p className="text-error">{errors?.last_name?.message}</p>}
                            </div>
                        )}
                    />
                </div>
                <div className="px-[10px] mb-5 w-1/2">
                    <label htmlFor="phone" className="fl-field-title-label">
                        Phone Number
                    </label>
                    <Controller
                        name="phone"
                        control={control}
                        render={({ field: { onChange, value } }: any) => {
                            const parsedPhoneNumber = parse(value, getValues().phone_country_code);
                            const defaultValues = {
                                phone: parsedPhoneNumber.phone,
                                phone_country_code: parsedPhoneNumber.country,
                            };

                            return (
                                <>
                                    <div className="flex flex-col ">
                                        <Number getNumber={onChange} errors={errors} value={value} onNumberChange={handleNumberChange} defaultValues={defaultValues} />
                                    </div>

                                    {!errors.phone && !errors.phone_country_code && customError && <p className="text-error">{customError}</p>}
                                </>
                            );
                        }}
                    />
                    {/* {errors && errors?.phone && (
                        <p className="text-error">{errors?.phone?.message}</p>
                    )} */}
                </div>
                <div className="px-[10px] w-1/2">
                    <Controller
                        name="email"
                        control={control}
                        render={({ field: { onChange, value } }: any) => (
                            <div className="w-full">
                                <InputWithLabel label="E-mail" placeholder="Enter member’s email" onChange={onChange} value={value} name="email" error={!!errors.email} />
                                {errors?.email && <p className="text-error">{errors?.email?.message}</p>}
                            </div>
                        )}
                    />
                </div>
                <div className="px-[10px] mb-5 w-1/2">
                    <Controller
                        name="role_type"
                        control={control}
                        render={({ field: { onChange, value } }: any) => (
                            <div className="w-full">
                                <SelectBox
                                    name="role_type"
                                    id="handlingUnit"
                                    label="Role type"
                                    options={optionAccType}
                                    value={optionAccType.filter((item: any) => item.value === value)}
                                    errorText={errors.role_type && true}
                                    onChangeFunc={(selectedOption: any) => onChange(selectedOption.value)}
                                    placeholder="Select account type"
                                />
                                {errors.role_type && <p className="text-error">{errors.role_type.message}</p>}
                            </div>
                        )}
                    />
                </div>

                <div className="flex w-full px-10 justify-center mt-10">
                    <Button className="fl-btn btn_primary w-[420px] " type="submit" onClick={onClick}>
                        Continue
                    </Button>
                </div>
            </div>
        </>
    );
};

export default AdminForm;
