import React from 'react';
import InputWithLabel from '../../../components/InputWithLabel';
import { MdOutlineClose } from 'react-icons/md';
import { Controller, useFieldArray } from 'react-hook-form';
import Number from 'src/components/Number';
import { FaPlus } from 'react-icons/fa6';
import { useTranslation } from 'react-i18next';

interface IPops {
    control: any;
    errors: any;
    setValue: any;
    isLoading: boolean;
}
const AddForm: React.FC<IPops> = ({ errors, control, setValue, isLoading }) => {
    const { t } = useTranslation();

    const { fields, remove, append } = useFieldArray({
        control,
        keyName: 'uuid',
        name: 'contact_details',
    });
    const addContact = () => {
        append({ id: 0, first_name: '', last_name: '', email: '', phone: '' });
    };

    return (
        <div className="bg-contentBackground">
            {fields.map((item: any, index) => (
                <div className="mt-8" key={item.uuid}>
                    <div className="flex justify-between">
                        <h2 className="text-mainTextColor text-base font-bold leading-[140%] -tracking-[0.16px]">{t('Contact')}</h2>
                        <div className="flex justify-center">
                            <span className="text-red-500 text-sm font-medium -tracking-[0.14px] leading-[140%] cursor-pointer flex items-center" onClick={() => remove(index)}>
                                <MdOutlineClose className="mr-2" />
                                <span>{t('Remove Contact')}</span>
                            </span>
                        </div>
                    </div>
                    <div className="mt-5">
                        <div className="flex -mx-4 flex-wrap">
                            <Controller
                                key={`contact_details.[${index}].id`}
                                name={`contact_details.[${index}].id`}
                                control={control}
                                render={({ field: { onChange, value } }: any) => (
                                    <InputWithLabel
                                        label="User Id"
                                        placeholder="Enter first name"
                                        onChange={(e: any) => {
                                            onChange(e);
                                        }}
                                        value={value}
                                        name="id"
                                        type="hidden"
                                        index={index}
                                        disabled={isLoading}
                                    />
                                )}
                            />

                            <Controller
                                key={`contact_details.[${index}].first_name`}
                                name={`contact_details.[${index}].first_name`}
                                control={control}
                                render={({ field: { onChange, value = '' } }: any) => (
                                    <div className="w-1/2 px-4 mb-8">
                                        <InputWithLabel
                                            label={t('First Name')}
                                            placeholder={t('First Name')}
                                            onChange={(e: any) => {
                                                onChange(e);
                                            }}
                                            value={value}
                                            name="first_name"
                                            index={index}
                                            error={!!errors?.contact_details?.[index]?.first_name}
                                            disabled={isLoading}
                                        />
                                        <p className="text-error">
                                            {errors.contact_details && errors.contact_details[index] && errors.contact_details[index].first_name && errors.contact_details[index].first_name.message}
                                        </p>
                                    </div>
                                )}
                            />

                            <Controller
                                key={`contact_details.[${index}].last_name`}
                                name={`contact_details.[${index}].last_name`}
                                control={control}
                                render={({ field: { onChange, value = '' } }: any) => (
                                    <div className="w-1/2 px-4 mb-8">
                                        <InputWithLabel
                                            label={t('Last Name')}
                                            placeholder={t('Last Name')}
                                            onChange={(e: any) => {
                                                onChange(e);
                                            }}
                                            index={index}
                                            value={value}
                                            name="last_name"
                                            error={!!errors?.contact_details?.[index]?.last_name}
                                            disabled={isLoading}
                                        />
                                        <p className="text-error">
                                            {errors.contact_details && errors.contact_details[index] && errors.contact_details[index].last_name && errors.contact_details[index].last_name.message}
                                        </p>
                                    </div>
                                )}
                            />

                            <Controller
                                key={`contact_details.[${index}].email`}
                                name={`contact_details.[${index}].email`}
                                control={control}
                                render={({ field: { onChange, value = '' } }: any) => (
                                    <div className="w-1/2 px-4 mb-8">
                                        <InputWithLabel
                                            label={t('Email')}
                                            placeholder={t('Email Address')}
                                            onChange={(e: any) => {
                                                onChange(e);
                                            }}
                                            value={value}
                                            name="email"
                                            index={index}
                                            error={!!errors?.contact_details?.[index]?.email}
                                            disabled={isLoading}
                                        />
                                        <p className="text-error">
                                            {errors.contact_details && errors.contact_details[index] && errors.contact_details[index].email && errors.contact_details[index].email.message}
                                        </p>
                                    </div>
                                )}
                            />
                            <div className="w-1/2 px-4 mb-8">
                                <label htmlFor="phone" className="fl-field-title-label">
                                    {t('Phone Number')}
                                </label>
                                <Controller
                                    key={`contact_details.[${index}].phone`}
                                    name={`contact_details.[${index}].phone`}
                                    control={control}
                                    render={({ field: { onChange, value } }: any) => (
                                        <Number
                                            index={index}
                                            // errors={errors}
                                            errors={errors?.contact_details?.[index]}
                                            value={value}
                                            defaultValues={item}
                                            onNumberChange={(country: any, phone: any, code: any, isValid: any) => {
                                                if (isValid) {
                                                    setValue(`contact_details.[${index}].phone`, `+${code}${phone}`);
                                                    setValue(`contact_details.[${index}].phone_country_code`, country);
                                                } else {
                                                    setValue(`contact_details.[${index}].phone`, '');
                                                }
                                            }}
                                        />
                                    )}
                                />
                                <Controller
                                    name={`contact_details[${index}].phone_country_code`}
                                    control={control}
                                    render={({ field: { onChange } }: any) => <input type="hidden" onChange={onChange} key={`contact_details.[${index}].phone_country_code`} />}
                                />
                                {/* <p className="text-error">
                                    {errors.contact_details &&
                                        errors.contact_details[index] &&
                                        errors.contact_details[index].phone &&
                                        errors.contact_details[index].phone
                                            .message}
                                </p> */}
                            </div>
                        </div>
                    </div>
                </div>
            ))}
            <div className="flex justify-center">
                <button className="text-primary text-[18px] font-medium -tracking-[0.18px] leading-[140%] flex items-center" onClick={addContact} type="button">
                    <FaPlus className="mr-2" />
                    <span>{t('Add Contact')}</span>
                </button>
            </div>
        </div>
    );
};
export default AddForm;
