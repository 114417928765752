/* eslint-disable max-lines-per-function */
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Button from 'src/components/Button';
import DateSelect from 'src/components/DateSelect/DateSelect';
import InputWithLabel from 'src/components/InputWithLabel';
import Number from 'src/components/Number';
import { Images } from 'src/theme';
import { changeDateformat, dateFormatTZtoDate } from 'src/utils/global-functions';
import { updateClient } from '../Client.slice';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { selectUserInfo } from 'src/app/Auth/Login/Login.slice';
import { errorCode } from 'src/constants/errorCode';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import BarLoader from 'src/components/BarLoader';
import SelectBox from 'src/components/SelectBox/SelectBox';
type FieldKey = 'first_name' | 'last_name' | 'birthdate' | 'gender' | 'is_out_of_town' | 'return_at';
function Info({ clientInfo, isEditProfile, setIsEditProfile, getClientList, getClientDetailFun, bookingData, loading }: any) {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const user = useAppSelector(selectUserInfo);
    const shopId = user.shops[0].id;
    const [isShowMore, setIsShowMore] = useState(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const genderList = [
        { label: 'Male', value: 'male' },
        { label: 'Female', value: 'female' },
        { label: 'Non-binary', value: 'non-binary' },
        { label: 'Prefer not to respond', value: 'prefer not to respond' },
    ];

    const schema = Yup.object({
        first_name: Yup.string().required(`${t('This field is required')}`),
        last_name: Yup.string().required(`${t('This field is required')}`),
        birthdate: Yup.mixed().required(`${t('This field is required')}`),
        gender: Yup.string().required(`${t('This field is required')}`),
        is_out_of_town: Yup.boolean().required(`${t('This field is required')}`),
        return_at: Yup.mixed().when('is_out_of_town', {
            is: (val: boolean) => val === true,
            then: (schm) => schm.required(`${t('This field is required')}`),
            otherwise: (schm) => schm.notRequired(),
        }),
    }).required();

    const {
        handleSubmit,
        control,
        formState: { errors },
        setError,
        watch,
    } = useForm<any>({
        resolver: yupResolver(schema),
        defaultValues: {
            first_name: clientInfo.first_name,
            last_name: clientInfo.last_name,
            email: clientInfo.email,
            gender: clientInfo.gender,
            is_out_of_town: clientInfo.is_out_of_town ?? false,
            birthdate: clientInfo.birthdate ? changeDateformat(clientInfo.birthdate, 'yyyy-MM-DD', 'DD/MM/YYYY') : null,
            return_at: clientInfo.return_at ? changeDateformat(clientInfo.return_at, 'yyyy-MM-DD', 'DD/MM/YYYY') : null,

            last_activity: clientInfo.login_at ? dateFormatTZtoDate(clientInfo.login_at) : null,
            date_of_creation: clientInfo.updated_at ? dateFormatTZtoDate(clientInfo.updated_at) : null,

            created_at: clientInfo.created_at,
        },
    });
    const isOutOfTown = watch('is_out_of_town');
    const formateDate = (date: any) => {
        const parts = date ? date.split('/') : [];
        if (parts.length > 1) return `${parts[2]}-${parts[1]}-${parts[0]}`;
        else return date;
    };
    const onSubmit = async (data: any) => {
        setIsLoading(true);
        data.birthdate = formateDate(data.birthdate);
        if (data.is_out_of_town) {
            data.return_at = formateDate(data.return_at);
        } else {
            delete data.return_at;
        }
        const submitData = {
            payload: data,
            param: { shop_id: shopId, id: clientInfo?.id },
        };
        const result = await dispatch(updateClient(submitData));
        if (result.type === updateClient.fulfilled.toString()) {
            setIsEditProfile(false);
            getClientList();
            getClientDetailFun();
            setIsLoading(false);
            // handleClose();
            // reset();
        }

        if (result.type === updateClient.rejected.toString()) {
            const response = result.payload.data;
            if (response.status === errorCode.unprocessable) {
                if (response.data) {
                    Object.keys(response.data).forEach((field) => {
                        setError(field as FieldKey, {
                            type: 'manual',
                            message: response.data[field][0],
                        });
                    });
                }
                setIsLoading(false);
                return;
            }
            toast.error(response.message);
        }
    };

    return (
        <>
            <div className="drawer-content-block">
                <BarLoader isLoading={loading} />
                <form className="mb-12" onSubmit={handleSubmit(onSubmit)}>
                    <div className="px-8 py-6 border-b border-lineColor">
                        {isEditProfile && (
                            <div className="w-full relative">
                                <Button className={`btn_primary w-[200px] h-10 fixed right-[75px] top-3`} isLoading={isLoading}>
                                    {t('Save changes')}
                                </Button>
                            </div>
                        )}
                        {bookingData?.expected_amount ? (
                            <>
                                <div className="flex w-full justify-between flex-wrap">
                                    <div className="grow-0 shrink-0 basis-[172px] mb-3">
                                        <p className="text-xs font-medium text-secondaryTxtColor leading-[140%] -tracking-[0.3px] mb-1">{t('Expected income')}</p>
                                        <p className="font-bold text-xl -tracking-[0.36px] ">$ {bookingData.expected_amount}</p>
                                    </div>
                                    <div className="grow-0 shrink-0 basis-[172px] mb-3">
                                        <p className="text-xs font-medium text-secondaryTxtColor leading-[140%] -tracking-[0.3px] mb-1">{t('Total sales')}</p>
                                        <p className="font-bold text-xl -tracking-[0.36px] ">$ {bookingData.total_amount}</p>
                                    </div>
                                    <div className="grow-0 shrink-0 basis-[172px] mb-3">
                                        <p className="text-xs font-medium text-secondaryTxtColor leading-[140%] -tracking-[0.3px] mb-1">{t('Average bill')}</p>
                                        <p className="font-bold text-xl -tracking-[0.36px] ">$ {bookingData.average_amount}</p>
                                    </div>
                                    <div className="grow-0 shrink-0 basis-[172px]">
                                        <p className="text-xs font-medium text-secondaryTxtColor leading-[140%] -tracking-[0.3px] mb-1">{t('Average booking time')}</p>
                                        <p className="font-bold text-xl -tracking-[0.36px] ">{bookingData.average_days}</p>
                                    </div>
                                    <div className="grow-0 shrink-0 basis-[172px]">
                                        <p className="text-xs font-medium text-secondaryTxtColor leading-[140%] -tracking-[0.3px] mb-1">{t('Average tip')}</p>
                                        <p className="font-bold text-xl -tracking-[0.36px] ">$ {bookingData.average_tips}</p>
                                    </div>
                                    <div className="grow-0 shrink-0 basis-[172px]">
                                        <p className="text-xs font-medium text-secondaryTxtColor leading-[140%] -tracking-[0.3px] mb-1">{t('Total bookings')}</p>
                                        <p className="font-bold text-xl -tracking-[0.36px] ">{bookingData.total_bookings}</p>
                                    </div>
                                </div>
                                {!isEditProfile ? (
                                    isShowMore ? (
                                        <>
                                            <div className="flex justify-between flex-wrap mt-3">
                                                <div className="grow-0 shrink-0 basis-[172px] mb-3">
                                                    <p className="text-xs font-medium text-secondaryTxtColor leading-[140%] -tracking-[0.3px] mb-1">{t('Days since last booking')}</p>
                                                    <p className="font-bold text-xl -tracking-[0.36px] ">
                                                        {bookingData.days_since_last_booking} {t('days')}
                                                    </p>
                                                </div>{' '}
                                                <div className="grow-0 shrink-0 basis-[172px] mb-3">
                                                    <p className="text-xs font-medium text-secondaryTxtColor leading-[140%] -tracking-[0.3px] mb-1">{t('Total tips')}</p>
                                                    <p className="font-bold text-xl -tracking-[0.36px] ">$ {bookingData.total_tips}</p>
                                                </div>
                                                <div className="grow-0 shrink-0 basis-[172px] mb-3">
                                                    <p className="text-xs font-medium text-secondaryTxtColor leading-[140%] -tracking-[0.3px] mb-1">{t('No show count')}</p>
                                                    <p className="font-bold text-xl -tracking-[0.36px] ">{bookingData.total_no_show}</p>
                                                </div>
                                                <div className="grow-0 shrink-0 basis-[172px]">
                                                    <p className="text-xs font-medium text-secondaryTxtColor leading-[140%] -tracking-[0.3px] mb-1">{t('Canceled bookings')}</p>
                                                    <p className="font-bold text-xl -tracking-[0.36px] "> {bookingData.total_canceled}</p>
                                                </div>
                                            </div>
                                            <div className="mt-[6px] text-center">
                                                <Button className="text-primary text-xs leading-[150%] -tracking-[0.3px]" onClick={() => setIsShowMore(false)}>
                                                    {t('Show Less')}
                                                </Button>
                                            </div>
                                        </>
                                    ) : (
                                        <div className="mt-[6px] text-center">
                                            <Button className="text-primary text-xs leading-[150%] -tracking-[0.3px]" onClick={() => setIsShowMore(true)}>
                                                {t(' Show more')}
                                            </Button>
                                        </div>
                                    )
                                ) : (
                                    ''
                                )}
                            </>
                        ) : (
                            ''
                        )}
                    </div>
                    <div className="py-6 px-8">
                        <div className="flex -mx-[10px] flex-wrap">
                            <div className="px-[10px] w-1/2 mb-5 grow-0 shrink-0 basis-[50%]">
                                <Controller
                                    name="first_name"
                                    control={control}
                                    render={({ field: { onChange, value } }: any) => (
                                        <InputWithLabel
                                            label={t('First Name')}
                                            placeholder={t('Enter Client First Name')}
                                            onChange={onChange}
                                            disabled={!isEditProfile}
                                            value={value}
                                            name="first_name"
                                        />
                                    )}
                                />
                                {errors?.first_name && <p className="text-error">{errors?.first_name?.message as React.ReactNode}</p>}
                            </div>
                            <div className="px-[10px] w-1/2 mb-5 grow-0 shrink-0 basis-[50%]">
                                <Controller
                                    name="last_name"
                                    control={control}
                                    render={({ field: { onChange, value } }: any) => (
                                        <InputWithLabel label={t('Last Name')} placeholder={t('Enter Client Last Name')} onChange={onChange} value={value} disabled={!isEditProfile} name="last_name" />
                                    )}
                                />
                                {errors?.last_name && <p className="text-error">{errors?.last_name?.message as React.ReactNode}</p>}
                            </div>

                            <div className="px-[10px] mb-5 w-1/2 grow-0 shrink-0 basis-[50%]">
                                <label htmlFor="phone" className="fl-field-title-label">
                                    {t('Phone Number')}
                                </label>
                                <Controller
                                    name="phone"
                                    control={control}
                                    render={({ field: { onChange } }: any) => (
                                        <div className="flex flex-col ">
                                            <Number
                                                getNumber={onChange}
                                                errors={null}
                                                onNumberChange={() => {}}
                                                disabled={true}
                                                defaultValues={{
                                                    phone: clientInfo.phone,
                                                    phone_country_code: clientInfo.phone_country_code,
                                                }}
                                            />
                                        </div>
                                    )}
                                />
                                {errors?.phone && <p className="text-error">{errors?.phone?.message as React.ReactNode}</p>}
                            </div>
                            <div className="px-[10px] w-1/2 mb-5 grow-0 shrink-0 basis-[50%]">
                                <Controller
                                    name="email"
                                    control={control}
                                    render={({ field: { onChange, value } }: any) => (
                                        <InputWithLabel label={t('Email')} placeholder={t('Enter Client email')} onChange={onChange} value={value} disabled={true} name="email" />
                                    )}
                                />
                                {errors?.email && <p className="text-error">{errors?.email?.message as React.ReactNode}</p>}
                            </div>
                            <div className="px-[10px] w-1/2 mb-5 grow-0 shrink-0 basis-[50%]">
                                <Controller
                                    name="gender"
                                    control={control}
                                    render={({ field: { onChange, value } }: any) => (
                                        <SelectBox
                                            name="gender"
                                            label={t('Gender')}
                                            options={genderList}
                                            value={genderList.filter((item: any) => item.value === value)}
                                            errorText={errors.gender && true}
                                            onChangeFunc={(selectedOption: any) => onChange(selectedOption.value)}
                                            placeholder={t('Select Gender')}
                                            isDisabled={!isEditProfile}
                                        />
                                    )}
                                />
                                {errors?.gender && <p className="text-error">{errors?.gender?.message as React.ReactNode}</p>}
                            </div>
                            <div className="px-[10px] w-1/2 mb-5 grow-0 shrink-0 basis-[50%]">
                                <Controller
                                    name={`birthdate`}
                                    control={control}
                                    render={({ field: { onChange, value } }: any) => (
                                        <>
                                            <DateSelect
                                                inputName="birthdate"
                                                className="form_control_date w-full form_input_control"
                                                label={t('Date of birth')}
                                                placeholder="dd/mm/YYYY"
                                                value={value}
                                                dateFormat="dd/MM/Y"
                                                disabled={!isEditProfile}
                                                onChangeValue={onChange}
                                                icon={<img src={Images.iconDateCalendar} alt="" className="w-5 " />}
                                            />
                                        </>
                                    )}
                                />
                                {errors?.birthdate && <p className="text-error">{errors?.birthdate?.message as React.ReactNode}</p>}
                            </div>
                            <div className="px-[10px] mb-5 w-1/2 grow-0 shrink-0 basis-[50%]">
                                <label className="fl-field-title-label">{t('Invited by')}</label>
                                <div className="px-4 py-2 bg-inputBg rounded-full h-[48px] flex items-center">
                                    {clientInfo.created_by_admin && (
                                        <img src={clientInfo.created_by_admin?.profile_image_url ?? Images.ProfileImg} alt="" className="w-8 h-8 grow-0 shrink-0 rounded-full " />
                                    )}
                                    <div className="ml-2 leading-[140%] -tracking-[0.14px]">{`${clientInfo.created_by_admin?.first_name ?? ''} ${clientInfo.created_by_admin?.last_name ?? ''}`}</div>
                                </div>
                            </div>
                            <Controller
                                name="last_activity"
                                control={control}
                                render={({ field: { onChange, value } }: any) => (
                                    <>
                                        <div className="px-[10px] mb-5 w-1/2 grow-0 shrink-0 basis-[50%]">
                                            <DateSelect
                                                inputName="picktime"
                                                showTimeSelect
                                                className="form_control_date w-full form_input_control"
                                                label={t('Last Activity')}
                                                placeholder="dd/mm/yyyy"
                                                dateFormat="dd/MM/Y HH:mm:ss"
                                                disabled={true}
                                                value={value}
                                                onChangeValue={onChange}
                                                icon={<img src={Images.iconDateCalendar} alt="" className="w-5 " />}
                                            />
                                        </div>
                                    </>
                                )}
                            />
                            <Controller
                                name="date_of_creation"
                                control={control}
                                render={({ field: { onChange, value } }: any) => (
                                    <>
                                        <div className="px-[10px] mb-5 w-1/2 grow-0 shrink-0 basis-[50%]">
                                            <DateSelect
                                                inputName="date_of_creation"
                                                className="form_control_date w-full form_input_control"
                                                label={t('Date of creation')}
                                                placeholder="dd/mm/yyyy"
                                                disabled={true}
                                                dateFormat="dd/mm/YYYY"
                                                value={value}
                                                onChangeValue={onChange}
                                                icon={<img src={Images.iconDateCalendar} alt="" className="w-5 " />}
                                            />
                                        </div>
                                    </>
                                )}
                            />
                        </div>
                        {/* <div className="flex items-start">
                            <Controller
                                name="is_out_of_town"
                                control={control}
                                render={({
                                    field: { onChange, value },
                                }: any) => (
                                    <Toggle
                                        name="is_out_of_town"
                                        // label="Out of town"
                                        labelPosition="right"
                                        id="out_of_town"
                                        isDisabled={!isEditProfile}
                                        value={value}
                                        isChecked={value}
                                        onChange={onChange}
                                        labelClassName="mt-[3px]"
                                        switchClassName="bg-secondaryTxtColor"
                                    />
                                )}
                            />

                            <div className="ml-[10px]">
                                <label
                                    htmlFor="out_of_town"
                                    className=" font-medium leading-[140%] -tracking-[0.14px] mb-1"
                                >
                                    {t('Out of town')}
                                </label>
                                <p className="text-xs font-medium text-secondaryTxtColor leading-[140%] -tracking-[0.3px] mb-1">
                                    {t('out_of_town_description')}
                                </p>
                            </div>
                            {errors?.is_out_of_town && (
                                <p className="text-error">
                                    {
                                        errors?.is_out_of_town
                                            ?.message as React.ReactNode
                                    }
                                </p>
                            )}
                        </div> */}
                        {isOutOfTown && (
                            <div className="px-[10px] mb-5 w-1/2 grow-0 shrink-0 basis-[50%] mt-3">
                                <Controller
                                    name="return_at"
                                    control={control}
                                    render={({ field: { onChange, value } }: any) => (
                                        <DateSelect
                                            inputName="return_at"
                                            className="form_control_date w-full form_input_control"
                                            label={t('Return Date')}
                                            placeholder="dd/mm/yyyy"
                                            dateFormat="dd/MM/Y"
                                            value={value}
                                            disabled={!isEditProfile}
                                            onChangeValue={onChange}
                                            icon={<img src={Images.iconDateCalendar} alt="" className="w-5 " />}
                                        />
                                    )}
                                />
                                {errors?.return_at && <p className="text-error">{errors?.return_at?.message as React.ReactNode}</p>}
                            </div>
                        )}
                    </div>
                </form>
            </div>
        </>
    );
}

export default Info;
