import React from 'react';
import { IInputWithLabel } from './InputWithLabel.interface';
import Button from '../Button';

const InputWithLabel: React.FC<IInputWithLabel> = ({
    error,
    onChange,
    value,
    name,
    inputWrapClassName,
    fileName,
    placeholder,
    label,
    labelClassName,
    type,
    index,
    readOnly,
    textArea,
    disabled = false,
    textAearClassName,
    isFocus,
    rows,
    max,
    min,
    onBlur,
    onFocus,
}): JSX.Element => (
    <div className={` flex flex-col  w-full ${inputWrapClassName}`}>
        {type !== 'hidden' && label && (
            <label htmlFor={name} className={`fl-field-title-label ${labelClassName}`}>
                {label}
            </label>
        )}

        {!textArea ? (
            type === 'file' ? (
                <div className="relative flex">
                    <div className={`form_input_control rounded-none rounded-l-full flex items-center ' ${error && 'is-invalid'}`}>
                        {fileName ? <span className="text-mainTextColor">{fileName}</span> : <span className="">No file chosen</span>}
                    </div>
                    <Button className="fl-btn bg-mainTextColor font-bold text-white px-4 rounded-none rounded-r-full whitespace-nowrap cursor-pointer">Choose File</Button>
                    <input
                        className={`opacity-0 absolute left-0 top-0 bottom-0 w-full h-full `}
                        type="file"
                        name={name}
                        value={value}
                        disabled={disabled}
                        onChange={(e) => onChange(e, index)}
                        placeholder={placeholder}
                        readOnly={readOnly}
                    />
                </div>
            ) : (
                <input
                    className={`form_input_control ${error && 'is-invalid'}`}
                    type={type || 'text'}
                    name={name}
                    autoFocus={isFocus}
                    value={value}
                    max={max}
                    min={min}
                    disabled={disabled}
                    onChange={(e) => onChange(e, index)}
                    onFocus={(e) => onFocus && onFocus(e, index)}
                    placeholder={placeholder}
                    readOnly={readOnly}
                    onBlur={(e) => onBlur && onBlur(e, index)}
                />
            )
        ) : (
            <textarea
                className={`form_input_control ${error && 'is-invalid'} ${textAearClassName}`}
                name={name}
                autoFocus={isFocus}
                value={value}
                onChange={(e) => onChange(e, index)}
                placeholder={placeholder}
                readOnly={readOnly}
                rows={rows}
            />
        )}
    </div>
);

export default InputWithLabel;
