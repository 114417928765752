import React, { FC } from 'react';
import { IKpiCard } from './KpiCard.interface';
import { Images } from 'src/theme';

const KpiCard: FC<IKpiCard> = ({ headerIcon, headerTitle, contentLable, contentDescription, growth, fromDate, secondaryContentLabel, secondaryContentDescription }) => (
    <>
        <div className="flex items-center mb-4">
            <i className="rounded-xl border border-lineColor flex items-center justify-center mr-1 w-10 h-10">
                <img src={headerIcon} alt="" className="w-5" />
            </i>
            <h4 className="font-bold -tracking-[0.3]">{headerTitle}</h4>
        </div>
        <h3 className="text-[24px] font-bold leading-[140%] -tracking-[0.5px] mb-4">
            {contentLable} {contentDescription && <span className="text-xs text-secondaryTxtColor font-normal -tracking-[0.3px]">{contentDescription}</span>}
            {secondaryContentLabel && <span className="ml-1 text-xs font-bold -tracking-[0.5px]">{secondaryContentLabel}</span>}
            {secondaryContentDescription && <span className="ml-1 text-xs text-secondaryTxtColor font-normal -tracking-[0.3px]">{secondaryContentDescription}</span>}
        </h3>

        <div className="flex items-center text-xs">
            <span className={`mr-[6px] ${growth >= 0 ? 'bg-lightGreen' : 'bg-dangerBgLight'}  rounded-lg px-[6px] py-[1px] ${growth >= 0 ? 'text-green' : 'text-dangerText'} flex items-center`}>
                <i className="icon mr-[2px]">
                    <img src={growth >= 0 ? Images.IconArrowUp : Images.IconArrowDown} alt="" className="w-[11px]" />
                </i>
                <span className="font-medium">{`${Math.abs(growth)}%`}</span>
            </span>
            <span className="leading-[140%] -tracking-[0.3px] font-medium text-secondaryTxtColor">{`Since ${fromDate}`} </span>
        </div>
    </>
);

export default KpiCard;
