/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
import React, { FC, useEffect, useState } from 'react';
import InputWithLabel from 'src/components/InputWithLabel';
import Button from 'src/components/Button';
import { FaArrowLeftLong } from 'react-icons/fa6';
import AutoCompleteGoogle from 'src/components/AutoCompleteGoogle/AutoCompleteGoogle';
import Map from 'src/components/Map/Map';
import { IFormData, IFormData2, IPops, initFormData } from './Location.interface';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { selectUserInfo, shopDetail } from 'src/app/Auth/Login/Login.slice';
import { accountLocation } from './Location.slice';
import { errorCode } from 'src/constants/errorCode';
import { toast } from 'react-toastify';
import { ReactComponent as Cloud } from 'src/assets/svgs/upload 1.svg';
import { useForm, Controller } from 'react-hook-form';
import { checkFileTypeValidation, convertBase64ToFile } from 'src/utils/global-functions';
import { s3Upload } from 'src/utils/s3Operations';
import { Images } from 'src/theme';
import CropperModal from 'src/components/CropperModal/CropperModal';
import { GLOBALVARIABLE } from 'src/utils/global-variables';
import { useTranslation } from 'react-i18next';
import { getSalesTaxAction, selectTaxes } from 'src/app/Location/Location.slice';
import SelectBox from 'src/components/SelectBox/SelectBox';

const Location: FC<IPops> = ({ setStep }) => {
    const scriptId = 'google-maps-api';
    const { t } = useTranslation();
    const [status, setStatus] = useState(document.getElementById(scriptId) ? true : false);
    const [formData, setFormData] = useState<IFormData>(initFormData);
    const [locationAddress, setLocationAddress] = useState('');
    const [file, setFile] = useState<any>({ convertedFile: '', filename: '' });
    const [isValidAddress, setIsValidAddress] = useState({
        locationAddress: true,
    });
    const [isLoading, setIsLoading] = useState(false);

    const user = useAppSelector(selectUserInfo);
    const locationStep = user.shops[0].account_step?.location;
    const imageUrl = locationStep && user.shops[0].locations[0] ? user.shops[0].locations[0].location_image_url : null;
    const [cropData, setCropData] = useState<any>(imageUrl || '');
    const [image, setImage] = useState<any>(imageUrl || '');
    const [upload, setUpload] = useState(false);
    const initValidForm = {
        locationAddress: true,
    };
    const [validForm, setValidForm] = useState(initValidForm);
    window.initMap = () => {
        setStatus(true);
    };
    const dispatch = useAppDispatch();

    const valid = () => {
        let isValid = true;
        const validFormData = {
            locationAddress: true,
        };

        if (formData.locationAddress.fullAddress === '' || formData.locationAddress.fullAddress === null) {
            validFormData.locationAddress = false;
            isValid = false;
        }

        setValidForm({ ...validForm, ...validFormData });
        return isValid;
    };
    const onChangeCrop = (e: any) => {
        setUpload(false);
        e.preventDefault();
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        const { result, message } = checkFileTypeValidation(files, GLOBALVARIABLE.locationImageSize);
        if (result) {
            clearErrors('location_image_name');
            const reader = new FileReader();
            reader.onloadstart = () => {
                setImage('');
                setUpload(false);
            };
            reader.onloadend = () => {
                setImage(reader.result as any);
                setUpload(true);
            };
            reader.readAsDataURL(files[0]);
            e.target.value = null;
            // setUploadProgress(0);
        } else {
            setError('location_image_name' as 'location_image_name', {
                type: 'manual',
                message: message,
            });
        }
        e.target.value = null;
    };
    useEffect(() => {
        valid();
        dispatch(getSalesTaxAction());
    }, []);

    useEffect(() => {
        if (locationStep) {
            setFormData((prevFormData) => ({
                ...prevFormData,
                locationAddress: {
                    ...prevFormData.locationAddress,
                    city: user.shops[0].locations[0].city,
                    country: user.shops[0].locations[0].country,
                    address1: user.shops[0].locations[0].street,
                    postal: user.shops[0].locations[0].postal_code,
                    latitude: user.shops[0].locations[0].latitude,
                    longitude: user.shops[0].locations[0].longitude,
                },
            }));
        } else if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const latitude = position.coords.latitude;
                    const longitude = position.coords.longitude;
                    setFormData((prevFormData) => ({
                        ...prevFormData,
                        locationAddress: {
                            ...prevFormData.locationAddress,
                            latitude: latitude,
                            longitude: longitude,
                        },
                    }));
                },
                (error) => {
                    if (error.code === error.PERMISSION_DENIED) {
                        // User denied geolocation permission.
                    }
                    // console.error(`Error getting location: ${error.message}`);
                },
            );
        } else {
            // console.error('Geolocation is not supported by this browser.');
        }
    }, []);

    useEffect(() => {
        if (!document.getElementById(scriptId)) {
            const script = document.createElement('script');
            script.id = scriptId;
            script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places&callback=initMap`;
            script.async = true;
            script.defer = true;
            document.head.appendChild(script);
        }
    }, []);

    const handleAutocompleteChange = (name: string) => (value: any) => {
        if (value.type === 'blur') {
            if (!formData.locationAddress.fullAddress && value.target.value) {
                setIsValidAddress((old) => ({ ...old, [name]: false }));
            }
            return;
        }
        if (name === 'locationAddress') {
            setLocationAddress(value);
            setIsValidAddress((old) => ({ ...old, [name]: true }));
        }

        setFormData((old) => ({ ...old, [name]: value }));
    };
    useEffect(() => {
        setValue('country', formData.locationAddress.country);
        setValue('city', formData.locationAddress.city);
        setValue('street', formData.locationAddress.address1);
        setValue('postal_code', formData.locationAddress.postal);
        setValue('latitude', formData?.locationAddress?.latitude);
        setValue('longitude', formData.locationAddress.longitude);
    }, [formData]);
    const [territories, setTerritories] = useState([]);

    const schema = Yup.object().shape({
        name: Yup.string().required(t('Business name required')),
        location_image_name: Yup.string().required(t('Logo required')),
        country: Yup.string().required(t('Country required')),
        city: Yup.string().required(t('City required')),
        street: Yup.string().required(t('Street required')),
        postal_code: Yup.string().required(t('Postal Code required')),
        latitude: Yup.number().required(t('Latitude required')),
        longitude: Yup.number().required(t('Longitude required')),
    });
    const saleTaxes = useAppSelector(selectTaxes);
    const [selectedSaleTax, setSelectedSaleTax] = useState<{
        id: number;
    } | null>(null);
    useEffect(() => {
        if (saleTaxes?.length > 0) {
            const territory = saleTaxes?.map((obj: { territory: string }) => ({
                label: obj?.territory,
                value: obj?.territory,
            }));
            setTerritories(territory);
            if (locationStep) {
                const previousSaleTax = saleTaxes?.find((tax: any) => tax.id === user.shops[0]?.locations[0]?.sales_tax_id);
                if (previousSaleTax) {
                    setSelectedSaleTax(previousSaleTax);
                    setValue<'gst'>('gst', previousSaleTax.gst);
                    setValue<'pst'>('pst', previousSaleTax.pst);
                    setValue<'hst'>('hst', previousSaleTax.hst);
                    setValue<'territory'>('territory', {
                        label: previousSaleTax.territory,
                        value: previousSaleTax.territory,
                    });
                }
            } else {
                setSelectedSaleTax(saleTaxes[0]);
                setValue<'gst'>('gst', saleTaxes?.[0]?.gst);
                setValue<'pst'>('pst', saleTaxes?.[0]?.pst);
                setValue<'hst'>('hst', saleTaxes?.[0]?.hst);
                setValue<'territory'>('territory', {
                    label: saleTaxes?.[0]?.territory,
                    value: saleTaxes?.[0]?.territory,
                });
            }
        }
    }, [saleTaxes]);

    type FieldKey = 'name' | 'location_image_name' | 'name' | 'country' | 'city' | 'street' | 'postal_code' | 'latitude' | 'longitude' | 'territory' | 'gst' | 'pst' | 'hst';
    const {
        handleSubmit,
        control,
        formState: { errors },
        setValue,
        setError,
        clearErrors,
    } = useForm<IFormData2>({
        resolver: yupResolver(schema),
        defaultValues: {
            name: locationStep ? user.shops[0].locations[0].name : '',
            location_image_name: locationStep ? user.shops[0].locations[0].location_image_name : '',
            country: locationStep ? user.shops[0].locations[0].country : 'India',
            city: locationStep ? user.shops[0].locations[0].city : '',
            street: locationStep ? user.shops[0].locations[0].street : '',
            postal_code: locationStep ? user.shops[0].locations[0].postal_code : null,
            latitude: locationStep ? user.shops[0].locations[0].latitude : null,
            longitude: locationStep ? user.shops[0].locations[0].longitude : null,
            territory: {
                label: saleTaxes?.[0]?.territory,
                value: saleTaxes?.[0]?.territory,
            },
            gst: saleTaxes?.[0]?.gst,
            pst: saleTaxes?.[0]?.pst,
            hst: saleTaxes?.[0]?.hst,
        },
    });

    const handleChange = async (data: IFormData2) => {
        setIsLoading(true);

        if (file.convertedFile && file?.filename) {
            const imgUploadResult: any = await s3Upload(file?.convertedFile, `images/location_logos/${file?.filename}`);
            if (imgUploadResult.status === 201) {
                const updatedData = {
                    ...data,
                    location_image_name: file.filename,
                };
                submitForm(updatedData);
            }
        } else {
            submitForm(data);
        }
    };
    const submitForm = async (data: IFormData2) => {
        const updatedData = { ...data, sales_tax_id: selectedSaleTax?.id };
        const result = await dispatch(accountLocation(updatedData));
        if (result.type === accountLocation.fulfilled.toString()) {
            await dispatch(shopDetail());
            setStep(4);
            setIsLoading(false);
        }
        if (result.type === accountLocation.rejected.toString()) {
            const response = result.payload.data;
            if (response.status === errorCode.unprocessable) {
                if (response.data) {
                    Object.keys(response.data).forEach((field) => {
                        setError(field as FieldKey, {
                            type: 'manual',
                            message: response.data[field][0],
                        });
                    });
                }
                setIsLoading(false);

                return;
            }
            toast.error(response.message);
        }
    };

    const imageUpload = (data: any) => {
        const appLogoImage = data;
        const fileInfo = convertBase64ToFile(appLogoImage);
        setFile(fileInfo);
        if (fileInfo && fileInfo.filename) {
            setValue('location_image_name', fileInfo.filename);
        }
    };
    return (
        <form onSubmit={handleSubmit(handleChange)}>
            <div className="w-[872px] 2xl:w-[1176px] flex-1 flex flex-col mt-[52px]">
                <div className="text-center">
                    <h3 className="fwpo_heading_title w-[480px] mx-auto">{t('Add your first location')}</h3>
                    <p className="text-secondaryTxtColor leading-[140%] -tracing-[0.14px] mt-4">{t('location_description')}</p>
                </div>
                <div className="mt-10">
                    <div className="flex -mx-4 flex-wrap">
                        <div className="w-[260px] px-4">
                            <Controller
                                name="location_image_name"
                                control={control}
                                render={({ field: { onChange } }) => (
                                    <>
                                        <div className="bg-contentBackground w-full">
                                            <div className="w-[full]">
                                                <div className={`h-[87px] overflow-hidden`}>
                                                    {!cropData ? (
                                                        <div className={`relative h-[87px] overflow-hidden rounded-md `}>
                                                            <div
                                                                className={`flex  ${
                                                                    errors.location_image_name ? 'is-invalid' : ''
                                                                } rounded-[3rem] gap-2 border-[1px] px-2 py-2  border-dashed items-center`}
                                                            >
                                                                <>
                                                                    <div
                                                                        className={`drop-shadow-none shadow-none w-[63px] h-[63px] basis-[63px] shrink-0 grow-0 flex justify-center items-center ring-0 bg-inputBg rounded-full`}
                                                                    >
                                                                        <img src={Images.AddImgIcon} className="w-5" alt="" />
                                                                    </div>

                                                                    <h1 className="text-xs font-medium -tracking-[0.12px] text-secondaryTxtColor">
                                                                        {t('Upload location image in JPG or PNG.Max size of 800K')}
                                                                    </h1>
                                                                </>
                                                            </div>
                                                            <label htmlFor="fileInput" className="absolute left-0 top-0 z-20 cursor-pointer rounded-md px-8 py-2 overflow-hidden h-full w-full">
                                                                {''}
                                                                <input
                                                                    type="file"
                                                                    id="fileInput"
                                                                    className="imgupload  w-full rounded-md text-mainTextColor px-8 py-2 mt-8 bg-btnclr "
                                                                    style={{
                                                                        visibility: 'hidden',
                                                                    }}
                                                                    onChange={onChangeCrop}
                                                                />
                                                            </label>
                                                        </div>
                                                    ) : (
                                                        <div
                                                            className={`relative h-[80px] overflow-hidden rounded-[40px] border border-lineLightColor todotest2 ${
                                                                errors.location_image_name ? 'is-invalid' : 'border-lineLightColor'
                                                            }`}
                                                        >
                                                            <div className="w-auto h-full  flex items-center cursor-pointer justify-center overflow-hidden p-[5px]">
                                                                {/* <div className="flex rounded-[2rem] gap-2 border-2 px-2 py-2 border-lightgray h-full  items-center"> */}
                                                                <img
                                                                    style={{
                                                                        height: '100%',
                                                                        width: 'auto',
                                                                    }}
                                                                    src={cropData}
                                                                    className=""
                                                                    alt="cropped"
                                                                />
                                                                <div className="  absolute top-0 left-0 w-full h-full  flex justify-end items-center pr-[8px] ">
                                                                    <div className="bg-inputBg p-2 w-14 h-14 rounded-full flex justify-center items-center">
                                                                        <Cloud width={20} height={20} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <label htmlFor="fileInput" className="absolute left-0 top-0 z-20 cursor-pointer rounded-md px-8 py-2 overflow-hidden h-full w-full">
                                                                {''}
                                                                <input
                                                                    type="file"
                                                                    id="fileInput"
                                                                    className="imgupload  w-full rounded-md text-mainTextColor px-8 py-2 mt-8 bg-btnclr"
                                                                    style={{
                                                                        visibility: 'hidden',
                                                                    }}
                                                                    onChange={onChangeCrop}
                                                                />
                                                            </label>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <p className="text-error">{errors.location_image_name && errors.location_image_name.message}</p>
                                    </>
                                )}
                            />
                        </div>
                        <div className="px-4 flex-1 mb-8">
                            <Controller
                                name="name"
                                control={control}
                                render={({ field: { onChange, value } }: any) => (
                                    <div className="">
                                        <InputWithLabel
                                            label={t('Location Name')}
                                            placeholder={t('Location Name')}
                                            onChange={onChange}
                                            value={value}
                                            name="name"
                                            disabled={isLoading}
                                            error={!!errors.name}
                                            isFocus={true}
                                        />
                                        <p className="text-error ">{errors.name && errors.name.message}</p>
                                    </div>
                                )}
                            />
                        </div>
                        <div className="px-4 mb-8 w-[272px]">
                            <Controller
                                name="country"
                                control={control}
                                render={({ field: { onChange, value } }: any) => (
                                    <div className="">
                                        <InputWithLabel
                                            label={t('Country')}
                                            placeholder={t('Country')}
                                            onChange={onChange}
                                            value={value}
                                            name="country"
                                            readOnly
                                            // error={!!errors.country}
                                        />
                                        <p className="text-error ">{errors.country && errors.country.message}</p>
                                    </div>
                                )}
                            />
                        </div>
                    </div>
                    <div className="flex -mx-4 flex-wrap">
                        <div className="w-[228px] px-4">
                            <Controller
                                name="city"
                                control={control}
                                render={({ field: { onChange, value } }: any) => (
                                    <div className="">
                                        <InputWithLabel
                                            label={t('City')}
                                            placeholder={t('City')}
                                            onChange={onChange}
                                            value={value}
                                            name="city"
                                            readOnly
                                            // error={!!errors.city}
                                        />
                                        <p className="text-error ">{errors.city && errors.city.message}</p>
                                    </div>
                                )}
                            />
                        </div>
                        <div className="px-4 flex-1 mb-8">
                            <Controller
                                name="street"
                                control={control}
                                render={({ field: { onChange, value } }: any) => (
                                    <div className="">
                                        <InputWithLabel
                                            label={t('Street')}
                                            placeholder={t('Street')}
                                            onChange={onChange}
                                            value={value}
                                            name="street"
                                            readOnly
                                            // error={!!errors.street}
                                        />
                                        <p className="text-error ">{errors.street && errors.street.message}</p>
                                    </div>
                                )}
                            />
                        </div>
                        <div className="px-4 mb-8 w-[224px]">
                            <Controller
                                name="postal_code"
                                control={control}
                                render={({ field: { onChange, value } }: any) => (
                                    <div className="">
                                        <InputWithLabel
                                            label={t('Postal Code')}
                                            placeholder={t('Postal Code')}
                                            onChange={onChange}
                                            value={value}
                                            name="postal_code"
                                            readOnly
                                            // error={errors.postal_code && true}
                                        />
                                        <p className="text-error ">{errors.postal_code && errors.postal_code.message}</p>
                                    </div>
                                )}
                            />
                        </div>
                        <div className="flex w-full">
                            <div className="px-[10px] mb-8 w-1/4">
                                <Controller
                                    name="territory"
                                    control={control}
                                    render={({ field: { onChange, value } }: any) => (
                                        <>
                                            <SelectBox
                                                name="territory"
                                                id="handlingUnit"
                                                label="Province or territory"
                                                options={territories}
                                                onChangeFunc={(selectedOption: any) => {
                                                    onChange(selectedOption);
                                                    const data = saleTaxes?.find((obj: { territory: string }) => obj.territory === selectedOption.value);
                                                    setSelectedSaleTax?.(data);
                                                    setValue<'pst'>?.('pst', data.pst);
                                                    setValue<'gst'>?.('gst', data.gst);
                                                    setValue<'hst'>?.('hst', data.hst);
                                                }}
                                                value={value}
                                            />
                                        </>
                                    )}
                                />
                            </div>
                            <div className="px-[10px] w-1/4">
                                <Controller
                                    name="gst"
                                    control={control}
                                    render={({ field: { onChange, value } }: any) => (
                                        <div className="w-full">
                                            <InputWithLabel
                                                label={
                                                    <>
                                                        GST <span className="text-primary">Read-only</span>
                                                    </>
                                                }
                                                onChange={onChange}
                                                value={`%${value}`}
                                                name="gst"
                                                readOnly
                                            />
                                        </div>
                                    )}
                                />
                            </div>
                            <div className="px-[10px] w-1/4">
                                <Controller
                                    name="pst"
                                    control={control}
                                    render={({ field: { onChange, value } }: any) => (
                                        <div className="w-full">
                                            <InputWithLabel
                                                label={
                                                    <>
                                                        PST <span className="text-primary">Read-only</span>
                                                    </>
                                                }
                                                onChange={onChange}
                                                value={`%${value}`}
                                                name="pst"
                                                readOnly
                                            />
                                        </div>
                                    )}
                                />
                            </div>
                            <div className="px-[10px] w-1/4">
                                <Controller
                                    name="hst"
                                    control={control}
                                    render={({ field: { onChange, value } }: any) => (
                                        <div className="w-full">
                                            <InputWithLabel
                                                label={
                                                    <>
                                                        HST <span className="text-primary">Read-only</span>
                                                    </>
                                                }
                                                onChange={onChange}
                                                value={`%${value}`}
                                                name="hst"
                                                readOnly
                                            />
                                        </div>
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                    <h3 className="font-semibold text-xl leading-[124%] -tracking-[0.24px] mb-3">{t('Select Address')}</h3>
                    <p className="text-secondaryTxtColor leading-[140%] -tracing-[0.14px] mb-5">{t('map_description')}</p>
                    {/* <div className="rounded-3xl h-[300px] bg-secondaryTxtColor overflow-hidden"> */}
                    {status && (
                        <AutoCompleteGoogle
                            inputName="locationAddress"
                            placeholder={t('Enter Address')}
                            className="form_control"
                            onChangeFunc={handleAutocompleteChange('locationAddress')}
                            onBlur={handleAutocompleteChange('locationAddress')}
                            errorText={!validForm.locationAddress ? 'Address is required' : null}
                            value={formData.locationAddress.fullAddress || locationAddress}
                        />
                    )}
                    {!isValidAddress.locationAddress && 'Please select valid address'}
                    <div>
                        {isValidAddress?.locationAddress &&
                            status &&
                            formData?.locationAddress &&
                            formData?.locationAddress &&
                            (formData?.locationAddress?.latitude ?? 0) !== 0 &&
                            (formData?.locationAddress?.longitude ?? 0) !== 0 && (
                                <Map address={formData.locationAddress} id={'consigneeMap'} onReverseAddressFunc={handleAutocompleteChange('locationAddress')} isCurrentLocation={true} />
                            )}
                    </div>
                    {/* </div> */}
                </div>
                <div className="flex w-full px-10 justify-center my-[60px]">
                    <div className="back-button absolute left-10">
                        <Button className="btn_outline_black text-sm" onClick={() => setStep(2)} type="button" disabled={isLoading}>
                            <FaArrowLeftLong size={18} className="mr-2" /> {t('Back')}
                        </Button>
                    </div>
                    <Button isLoading={isLoading} className="fl-btn btn_primary w-[420px] 2xl:w-[572px]">
                        {t('Continue')}
                    </Button>
                </div>
            </div>
            {upload && <CropperModal imageUrl={image} imageUpload={imageUpload} setUpload={setUpload} setCropData={setCropData} isCropType={false} defaultCropType="Square" isLocation={true} />}
        </form>
    );
};

export default Location;
