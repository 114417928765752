import React from 'react';
import { Controller } from 'react-hook-form';

import Button from 'src/components/Button';

import { IProps } from './Location.interface';
import SalesTaxesForm from './Location.SalesTaxesForm';

export const optionCountry = [
    { label: 'Afghanistan', value: 'pgi' },
    { label: 'Albania', value: 'pgii' },
    { label: 'Algeria', value: 'pgiii2' },
    { label: 'Andorra', value: 'pgiii3' },
    { label: 'Angola', value: 'pgiii4' },
    { label: 'Antigua and Barbuda', value: 'pgiii6' },
    { label: 'Argentina', value: 'pgiii52' },
];

const SalesTaxes = ({ setStep, handleClick, territories, saleTaxes, setValue, setSelectedSaleTax }: IProps) => (
    <div>
        <div className="flex flex-col h-full justify-between">
            <div className="">
                <div className="mb-10 text-center ">
                    <h3 className="fwpo_heading_title w-[480px] mx-auto mb-4">Sales taxes</h3>
                    <p className="text-secondaryTxtColor leading-[140%] -tracing-[0.3px] ">Set the sales tax bracket to add to transactions at checkout for this location.</p>
                </div>
                <SalesTaxesForm Controller={Controller} territories={territories} saleTaxes={saleTaxes} setValue={setValue} setSelectedSaleTax={setSelectedSaleTax} />
            </div>
            <div className="flex w-full px-10 justify-center mt-10">
                <Button
                    className="fl-btn btn_primary w-[420px] 2xl:w-[572px]"
                    onClick={handleClick}
                    type="button"
                    // disabled={loading}
                >
                    Continue
                </Button>
            </div>
        </div>
    </div>
);

export default SalesTaxes;
