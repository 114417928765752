import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import Button from 'src/components/Button';
import { useAppDispatch } from 'src/redux/hooks';
import { errorCode } from 'src/constants/errorCode';
import InputWithLabel from 'src/components/InputWithLabel';
import { IUpdateEmailField, IUpdateEmailFieldKey, IUpdateEmailPayload, IUpdateEmailVerifyPayload } from './Profile.interface';
import { toast } from 'react-toastify';
import { emailUpdate, emailVerify } from './Profile.slice';
import OTPField from 'src/components/OTPField';
import { shopDetail } from 'src/app/Auth/Login/Login.slice';
import { useTranslation } from 'react-i18next';

const UpdateEmail = ({ handleClose }: any) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const [isVerify, setIsVerify] = useState(false);
    const [counter, setCounter] = useState(59);

    const schema = Yup.object({
        email: Yup.string().email(t('Valid Gmail Address Needed')).required(t('Email required')),
    }).required();

    const {
        handleSubmit,
        control,
        formState: { errors },
        setError,
        watch,
    } = useForm<IUpdateEmailField>({
        resolver: yupResolver(schema),
        defaultValues: {
            email: '',
            otp: new Array(4).fill(''),
        },
    });

    const emailValue = watch('email');

    useEffect(() => {
        const timer: any = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
        return () => clearInterval(timer);
    }, [counter]);

    const handleSave = async (data: IUpdateEmailField) => {
        if (isVerify) {
            const payload: IUpdateEmailVerifyPayload = {
                ...data,
                otp: data.otp ? data.otp.join('') : '',
            };
            const result: any = await dispatch(emailVerify(payload));
            if (result.type === emailVerify.fulfilled.toString()) {
                dispatch(shopDetail());
                handleClose();
            }
            if (result.type === emailVerify.rejected.toString()) {
                const response = result.payload.data;
                if (response.status === errorCode.unprocessable) {
                    if (response.data) {
                        Object.keys(response.data).forEach((field) => {
                            setError(field as IUpdateEmailFieldKey, {
                                type: 'manual',
                                message: response.data[field][0],
                            });
                        });
                    }
                    return;
                }
                toast.error(response.message);
            }
        } else {
            const asyncFunction = handlerSendCode(data);
            asyncFunction();
        }
    };

    const handlerSendCode = (data: IUpdateEmailField) => async () => {
        const payload: IUpdateEmailPayload = {
            email: data.email,
        };
        const result: any = await dispatch(emailUpdate(payload));
        if (result.type === emailUpdate.fulfilled.toString()) {
            setIsVerify(true);
            setCounter(59);
        }
        if (result.type === emailUpdate.rejected.toString()) {
            const response = result.payload.data;
            if (response.status === errorCode.unprocessable) {
                if (response.data) {
                    Object.keys(response.data).forEach((field) => {
                        setError(field as IUpdateEmailFieldKey, {
                            type: 'manual',
                            message: response.data[field][0],
                        });
                    });
                }
                return;
            }
            toast.error(response.message);
        }
    };

    return (
        <form onSubmit={handleSubmit(handleSave)}>
            <h2 className="font-bold text-[20px] leading-[35px] -tracking-[0.336px] text-center pb-5">{t('Email update')}</h2>
            <div className="flex flex-wrap">
                <Controller
                    name="otp"
                    control={control}
                    render={({ field: { onChange, value } }: any) => (
                        <div className="w-full">
                            {isVerify && (
                                <div className="flex flex-col gap-8 text-center">
                                    <p className="text-secondaryTxtColor leading-[19.6px] -tracking-[0.384px]">{t('Enter 4-digit code to change email')}</p>
                                    <OTPField otp={value} setOtp={onChange} errors={errors.otp} />
                                    {errors.otp && <p className="text-error">{errors.otp.message}</p>}
                                    <div>
                                        <p className="text-secondaryTxtColor leading-[19.6px] -tracking-[0.384px] mb-2">{t("Haven't received the code yet?")}</p>
                                        {counter ? (
                                            <span className="leading-[19.6px] text-sm font-bold text-mainTextColor">
                                                {t('Send again')}
                                                <span className="leading-5 text-primary text-sm font-bold">({counter}sec)</span>
                                            </span>
                                        ) : (
                                            <span
                                                onClick={handlerSendCode({
                                                    email: emailValue,
                                                })}
                                                className="text-primary cursor-pointer leading-[19.6px] text-sm font-bold text-mainTextColor"
                                            >
                                                {t('Send again')}
                                            </span>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                />
                <Controller
                    name="email"
                    control={control}
                    render={({ field: { onChange, value } }: any) => (
                        <div className="w-full">
                            {!isVerify && (
                                <>
                                    <InputWithLabel name="email" label={t('Email')} placeholder={t('Email')} value={value} onChange={onChange} error={!!errors.email} />
                                    {errors.email && <p className="text-error">{errors.email.message}</p>}
                                </>
                            )}
                        </div>
                    )}
                />
            </div>
            <Button className={`fl-btn btn_primary mt-10`}>Continue</Button>
        </form>
    );
};

export default UpdateEmail;
