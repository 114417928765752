import React from 'react';
import { Controller } from 'react-hook-form';
import InputWithLabel from 'src/components/InputWithLabel';
import SelectBox from 'src/components/SelectBox/SelectBox';

const AddNewServiceForm = ({ optionCountry, control, errors }: any) => (
    <>
        <div className="px-[10px] mb-5 w-1/2">
            <Controller
                name="name"
                control={control}
                render={({ field: { onChange, value } }: any) => (
                    <div className="w-full">
                        <InputWithLabel label="Service name" isFocus={true} placeholder="Enter service name" onChange={onChange} value={value} name="name" error={!!errors.name} />
                        {errors?.name && <p className="text-error">{errors?.name?.message}</p>}
                    </div>
                )}
            />
        </div>
        <div className="px-[10px] mb-5 w-1/2">
            <Controller
                name="shop_category_id"
                control={control}
                render={({ field: { onChange, value } }: any) => (
                    <div className="w-full">
                        <SelectBox
                            name="shop_category_id"
                            id="handlingUnit"
                            label="Service category"
                            errorText={errors.shop_category_id && true}
                            options={optionCountry}
                            value={optionCountry.find((item: any) => item.id === value)}
                            onChangeFunc={(selectedOption: any) => onChange(selectedOption.id)}
                            placeholder="No service category selected"
                        />
                        {errors.shop_category_id && <p className="text-error">{errors.shop_category_id.message}</p>}
                    </div>
                )}
            />
        </div>
        {/* <div className="px-[10px] mb-5 w-full working_location">
            <Controller
                name="tags"
                control={control}
                render={({ field: { onChange, value } }: any) => (
                    <div className="w-full">
                        <SelectBox
                            isMulti
                            name="tags"
                            id="handlingUnit"
                            label="Add tags to the service"
                            options={optionCountry}
                            errorText={errors.tags?.message}
                            // value={optionCountry.includes(value)}
                            value={optionCountry.filter((item: any) =>
                                value.includes(item.value),
                            )}
                            onChangeFunc={(selectedOption: any) => {
                                onChange(
                                    Array.isArray(selectedOption)
                                        ? selectedOption.map(
                                              (x: any) => x.value,
                                          )
                                        : [],
                                );
                            }}
                            placeholder="Select tags"
                        />
                        {errors.tags && (
                            <p className="text-error">{errors.tags.message}</p>
                        )}
                    </div>
                )}
            />
        </div> */}
        <div className="px-[10px] w-full">
            <Controller
                name="description"
                control={control}
                render={({ field: { onChange, value } }: any) => (
                    <div className="w-full">
                        <InputWithLabel
                            label="Service description"
                            placeholder="Enter description"
                            onChange={onChange}
                            value={value}
                            name="description"
                            textArea
                            textAearClassName="h-[120px] rounded-3xl resize-none"
                            error={!!errors.description}
                        />
                        {errors?.description && <p className="text-error">{errors?.description?.message}</p>}
                    </div>
                )}
            />
        </div>
    </>
);
export default AddNewServiceForm;
