import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import EmptyMsgWithBtn from 'src/components/EmptyMsgWithBtn';

const TopServices = ({ services, timeFileterSelected }: any) => {
    const addServices = () => {};
    const { t } = useTranslation();
    return (
        <div className="bg-white rounded-3xl border border-lineColor  p-4 h-full">
            <div className="flex items-center mb-5">
                <h3 className="text-xl font-bold -tracking-[0.4px]">Top Services</h3>
                {/* <button type='button'
                className="text-primary ml-auto -tracking-[0.3] font-medium"
            >
                View all
            </button> */}
            </div>

            {services.length > 0 ? (
                <div className="-mx-4">
                    <table className="fl-table w-full">
                        <thead>
                            <tr>
                                <th>{t('Service')}</th>
                                <th>{t('Sales')}</th>
                                <th>{timeFileterSelected.label === 'All time' ? 'All Time' : timeFileterSelected.label === 'Custom' ? `Custom` : timeFileterSelected.label}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {services.map((item: any) => (
                                <tr key={item.id}>
                                    <td>{item.name}</td>
                                    <td>${item.total_booking_service_amount ?? 0}</td>
                                    <td>{item.total_booking_service_count ?? 0}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            ) : (
                <EmptyMsgWithBtn title={t('No top services')} description={t('No_top_services_description')} btnLabel={t('Add services')} onClick={addServices} />
            )}
        </div>
    );
};

export default TopServices;
