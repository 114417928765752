import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'src/components/Button';
import { IEnablePopup } from './ReceptionistMode.interface';

const EnablePopup = ({ handleReceptionistMode, handleClose }: IEnablePopup) => {
    const { t } = useTranslation();
    return (
        <>
            <h2 className="font-bold text-[20px] leading-[35px] -tracking-[0.336px] text-center pb-5">{t('Receptionist Mode')}</h2>
            <div className="flex flex-wrap">
                <div className="w-full">
                    <div className="flex flex-col gap-8 text-center">
                        <p className="text-secondaryTxtColor leading-[19.6px] -tracking-[0.384px]">{t('Are you sure you want to enable receptionist mode?')}</p>
                    </div>
                </div>
            </div>
            <Button type="submit" onClick={() => handleReceptionistMode('pinSetup')} className={`fl-btn btn_primary mt-10`}>
                Set up receptionist mode
            </Button>
            <Button type="submit" className={`fl-btn btn_outline_black mt-5 w-full justify-center`} onClick={handleClose}>
                Not now
            </Button>
        </>
    );
};

export default EnablePopup;
