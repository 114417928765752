import React, { useCallback, useEffect, useState } from 'react';
import WrapperHeading from 'src/components/WrapperHeading';
import CropperModal from 'src/components/CropperModal/CropperModal';
import { FormProvider, useForm } from 'react-hook-form';
import { checkFileTypeValidation, convertBase64ToFile, getSelectBoxOptions } from 'src/utils/global-functions';
import Button from 'src/components/Button';
import { selectThemeLoading, selectThemeResult, themeList, updateAppearance } from './Appearance.slice';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { AppearanceScreen, IFieldKey, IFormData, ISettings } from './Appearance.interface';
import WebSettings from './WebSettings';
import MobileSettings from './MobileSettings';
import POSSettings from './POSSettings';
import ThemeModal from './ThemeModal';
import { selectUserInfo, shopDetail } from '../Auth/Login/Login.slice';
import { toast } from 'react-toastify';
import { errorCode } from 'src/constants/errorCode';
import { s3Upload } from 'src/utils/s3Operations';
import ThemeApplyConfirm from './ThemeApplyConfirm';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { GLOBALVARIABLE } from 'src/utils/global-variables';
import BarLoader from 'src/components/BarLoader';

const initAction = {
    id: 0,
    app: '',
    theme: false,
    confirm_theme: false,
};

const Appearance = () => {
    const themeLoading = useAppSelector(selectThemeLoading);

    const dispatch = useAppDispatch();
    const allThemes = useAppSelector(selectThemeResult);
    const user = useAppSelector(selectUserInfo);
    const shop = user.shops[0];

    const businessName = shop.business_name;

    const [image, setImage] = useState('');
    const [logoImageUrl, setLogoImageURL] = useState(null);
    const [upload, setUpload] = useState(false);
    const [activeTab, setActiveTab] = useState(AppearanceScreen.Web);
    const [activeTabType, setActiveTabType] = useState('web');
    const [action, setAction] = useState(initAction);
    const [themeOptions, setThemeOptions] = useState([]);
    const [file, setFile] = useState<any>({ convertedFile: '', filename: '' });
    const [timestamp, setTimestamp] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    const settingsSchema = Yup.object().shape({
        shop_theme_id: Yup.number().required('This field is required'),
        type: Yup.string().required('This field is required'),
        content_background: Yup.string().required('This field is required'),
        cards_color: Yup.string().required('This field is required'),
        button_color: Yup.string().required('This field is required'),
        accent_color: Yup.string().required('This field is required'),
        main_text_color: Yup.string().required('This field is required'),
        secondary_text_color: Yup.string().required('This field is required'),
    });

    const schema = Yup.object().shape({
        logo_image_name: Yup.string().required('This field is required'),
        settings: Yup.array().of(settingsSchema).required('This field is required'),
    });

    const methods = useForm<IFormData>({
        resolver: yupResolver(schema),
        defaultValues: {
            logo_image_name: shop.logo_image_name,
            settings: [],
        },
    });

    useEffect(() => {
        const subscription = methods.watch((value, { name, type }) => setTimestamp(new Date().getTime()));
        return () => subscription.unsubscribe();
    }, [methods.watch]);

    const fetchData = async () => {
        await dispatch(themeList());
    };

    useEffect(() => {
        fetchData();
        handleExistingData();
    }, []);

    useEffect(() => {
        if (allThemes?.length) {
            const themes: any = getSelectBoxOptions(allThemes);
            setThemeOptions(themes);
        }
    }, [allThemes]);

    const handleExistingData = () => {
        const existingLogoImageUrl = shop.logo_image_name ? shop.logo_image_url : null;
        setLogoImageURL(existingLogoImageUrl);

        if (shop.settings.length) {
            const settings = shop.settings.map(({ id, shop_id, created_at, updated_at, ...rest }: any) => rest);
            methods.setValue('settings', settings);
            methods.reset({
                logo_image_name: shop.logo_image_name,
                settings: methods.getValues('settings'),
            });
        }
    };

    const imageUpload = (data: any) => {
        const appLogoImage = data;
        const fileInfo = convertBase64ToFile(appLogoImage);
        setFile(fileInfo);
        if (fileInfo && fileInfo.filename) {
            methods.setValue('logo_image_name', fileInfo.filename);
        }
    };

    const onChangeCrop = (e: any) => {
        setUpload(false);
        e.preventDefault();
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        const { result, message } = checkFileTypeValidation(files, GLOBALVARIABLE.appearanceImageSize);
        if (result) {
            methods.clearErrors('logo_image_name');
            const reader = new FileReader();
            reader.onloadstart = () => {
                setImage('');
                setUpload(false);
            };
            reader.onloadend = () => {
                setImage(reader.result as any);
                setUpload(true);
            };
            reader.readAsDataURL(files[0]);
            e.target.value = null;
            // setUploadProgress(0);
        } else {
            methods.setError('logo_image_name' as 'logo_image_name', {
                type: 'manual',
                message: message,
            });
        }
    };

    const onSubmit = async (data: IFormData) => {
        const result: any = await dispatch(updateAppearance({ ...data, ...{ shop_id: shop.id } }));
        if (result.type === updateAppearance.fulfilled.toString()) {
            await dispatch(shopDetail());
        } else if (result.type === updateAppearance.rejected.toString()) {
            const response = result.payload.data.data;
            if (response.status === errorCode.unprocessable) {
                if (response.data) {
                    Object.keys(response.data).forEach((field) => {
                        methods.setError(field as IFieldKey, {
                            type: 'manual',
                            message: response.data[field][0],
                        });
                    });
                }
                return;
            }
            toast.error(response.message);
        }
        setIsLoading(false);
    };

    const handleUpload = async (data: IFormData) => {
        setIsLoading(true);
        if (file.convertedFile && file?.filename) {
            const imgUploadResult: any = await s3Upload(file?.convertedFile, `images/business_logos/${file?.filename}`);
            if (imgUploadResult.status === 201) {
                const updatedData = {
                    ...data,
                    logo_image_name: file.filename,
                };
                onSubmit(updatedData);
            }
        } else {
            onSubmit(data);
        }
    };

    const handleThemeApply =
        (id: number = 0) =>
        () => {
            if (id === 0) {
                const currentSetting = methods.getValues('settings').find((setting) => setting.type === action.app);

                if (currentSetting) {
                    let newSettings: any = [];

                    methods.getValues('settings').forEach((setting) => {
                        newSettings.push({
                            ...currentSetting,
                            ...{ type: setting.type },
                        });
                    });

                    methods.setValue('settings', newSettings);
                }
            } else {
                if (allThemes?.length) {
                    const selectedTheme: any = allThemes.find((theme: any) => theme.id === id);
                    const index = methods.getValues('settings').findIndex((setting) => setting.type === activeTabType);

                    const newSetting: ISettings = {
                        type: activeTabType,
                        shop_theme_id: selectedTheme.id,
                        content_background: selectedTheme.color.content_background,
                        cards_color: selectedTheme.color.cards_color,
                        button_color: selectedTheme.color.button_color,
                        accent_color: selectedTheme.color.accent_color,
                        main_text_color: selectedTheme.color.main_text_color,
                        secondary_text_color: selectedTheme.color.secondary_text_color,
                    };
                    methods.setValue(`settings.${index}`, newSetting);
                    methods.reset({ settings: methods.getValues('settings') });
                }
            }
            setAction(initAction);
        };

    const handleAction =
        (type: string, id: number = 0, app: string = '') =>
        () => {
            setAction((old) => ({ ...old, [type]: true, id, app }));
        };

    const handleModalClose = useCallback(() => {
        setAction(initAction);
    }, []);

    return (
        <div className="inner-page-wrape">
            <div className="flex items-center">
                <WrapperHeading content="Application Appearance" />
            </div>
            {/* <div className="fl-tab-underline w-full mt-8 ">
                {tabs.map((tab, index) => (
                    <button
                        key={index}
                        type="button"
                        className={`fl-tab-link ${
                            activeTab === tab.name ? 'active' : ''
                        }`}
                        onClick={handleTabClick(tab)}
                    >
                        {tab.name}
                    </button>
                ))}
            </div> */}
            <div className="mt-8">
                <div className={`w-full h-full rounded-2xl bg-white border-[1px] border-lineColor ${timestamp}`}>
                    <BarLoader isLoading={isLoading || themeLoading} />
                    {!themeLoading && (
                        <>
                            <FormProvider {...methods}>
                                <form onSubmit={methods.handleSubmit(handleUpload)}>
                                    <div className="p-6">
                                        <div className="flex items-center mb-5">
                                            <h2 className="font-bold text-xl leading-[140%] -tracking-[0.2px]">{activeTab}</h2>
                                            <div className="flex ml-auto">
                                                <Button type="reset" className="h-10 text-base font-bold btn_outline_black px-[10px] justify-center   w-[180px]" onClick={handleExistingData}>
                                                    Cancel
                                                </Button>
                                                <Button className="h-10 btn_primary w-[180px] ml-5">Save changes</Button>
                                            </div>
                                        </div>
                                        {methods.getValues('settings').map((setting, index) => (
                                            <div key={index}>
                                                {setting.type === 'web' && activeTabType === 'web' && (
                                                    <WebSettings
                                                        key={index}
                                                        index={index}
                                                        setting={setting}
                                                        logoImageUrl={logoImageUrl}
                                                        themeOptions={themeOptions}
                                                        handleAction={handleAction}
                                                        onChangeCrop={onChangeCrop}
                                                        businessName={businessName}
                                                    />
                                                )}
                                                {setting.type === 'mobile' && activeTabType === 'mobile' && (
                                                    <MobileSettings
                                                        key={index}
                                                        index={index}
                                                        setting={setting}
                                                        logoImageUrl={logoImageUrl}
                                                        themeOptions={themeOptions}
                                                        handleAction={handleAction}
                                                        onChangeCrop={onChangeCrop}
                                                    />
                                                )}
                                                {setting.type === 'pos' && activeTabType === 'pos' && (
                                                    <POSSettings
                                                        key={index}
                                                        index={index}
                                                        setting={setting}
                                                        logoImageUrl={logoImageUrl}
                                                        themeOptions={themeOptions}
                                                        handleAction={handleAction}
                                                        onChangeCrop={onChangeCrop}
                                                    />
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                </form>
                            </FormProvider>
                        </>
                    )}
                </div>
            </div>
            {upload && <CropperModal defaultCropType="Rounded" imageUrl={image} imageUpload={imageUpload} setUpload={setUpload} setCropData={setLogoImageURL} />}
            {action.theme && <ThemeModal activeTheme={action.id} handleClose={handleModalClose} handleThemeApply={handleThemeApply} settings={methods.watch('settings')} />}
            {action.confirm_theme && <ThemeApplyConfirm handleModalClose={handleModalClose} handleThemeApply={handleThemeApply} />}
        </div>
    );
};

export default Appearance;
