import React, { useEffect, useRef, useState } from 'react';
import SelectBox from 'src/components/SelectBox/SelectBox';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { selectUserInfo } from '../Auth/Login/Login.slice';
import './../../utils/Datatable.scss';
import { capitalizeFirstLetter, getShortName, formateAmount, formatName } from 'src/utils/global-functions';
import WrapperHeading from 'src/components/WrapperHeading';
import SearchBar from 'src/components/SearchBar/SearchBar';
import Button from 'src/components/Button';
import Add from './Client.add';
import ClientDetails from './ClientDetails/ClientDetails';
import { clientList, deleteClient, downloadExcelOrCsv, downloadImportClientsExcelOrCsv } from './Client.slice';
import './../../utils/prime-react-datatable.scss';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import Import from './Client.Import';
import { Tooltip } from 'flowbite-react';
import { RxInfoCircled } from 'react-icons/rx';
import { usePhone } from 'src/hooks/usePhone';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.min.css';
import { PiCaretUpDownBold } from 'react-icons/pi';
import BarLoader from 'src/components/BarLoader';
import { useTranslation } from 'react-i18next';
import Popup from 'src/components/Popup';
const ClientList = [
    { label: 'All clients', value: 'all' },
    { label: 'New clients', value: 'new' },
    { label: 'Recent clients', value: 'recent' },
    { label: 'Loyal clients', value: 'loyal' },
    { label: 'Lapsed Clients', value: 'lapsed' },
    { label: 'At Risk Clients', value: 'risk' },
    { label: 'Former Clients', value: 'former' },
    { label: 'Top clients', value: 'top' },
];
const Client = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { getFormatPhone } = usePhone();
    const user = useAppSelector(selectUserInfo);
    const shopId = user.shops[0].id;
    // const loading = useAppSelector(selectClientLoading);
    const clientIdRef = useRef<number | null>(null);

    const [clientInfo, setClientInfo] = useState<any>(null);
    const [clientArrayList, setClientArrayList] = useState<any>([]);

    const [isOpenAddClient, setIsOpenAddClient] = useState<boolean>();
    const [loading, setLoading] = useState<boolean>(true);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [isOpenImportPopup, setIsOpenImportPopup] = useState<boolean>(false);
    const [isFileUpload, setIsFileUpload] = useState<boolean>(false);

    const [selectedAction, setSelectedAction] = useState<any>();
    const [totalClients, setTotalClients] = useState<number>(0);
    const [searchInput, setSearchInput] = useState<any>('');
    const [isOpenRemovePopup, setIsOpenRemovePopup] = useState<boolean>(false);
    const [lazyState, setlazyState] = useState<any>({
        first: 0,
        rows: 10,
        page: 1,
        sortField: null,
        sortOrder: null,
        filters: {
            global: { value: null },
            type: { value: 'all' },
            // location: { value: null, matchMode: FilterMatchMode.EQUALS },
        },
    });

    const [selectedFilter, setSelectedFilter] = useState<any>(ClientList[0]);

    const optionsList = [
        { label: 'Export as Excel', value: 'export_as_excel' },
        { label: 'Export as CSV', value: 'export_as_csv' },
        { label: 'Import clients', value: 'import_clients' },
    ];

    const handleClientChange = (e: any) => {
        setSelectedFilter(e);
        setlazyState({
            ...lazyState,
            filters: {
                ...lazyState.filters,
                type: { value: e.value },
            },
        });
        clientIdRef.current = e.value;
    };

    useEffect(() => {
        loadLazyData();
    }, [lazyState]);

    let networkTimeout: any = null;
    const loadLazyData = async () => {
        setLoading(true);
        if (networkTimeout) {
            clearTimeout(networkTimeout);
        }
        //imitate delay of a backend call
        networkTimeout = setTimeout(async () => {
            const payloadData = {
                data: lazyState,
                shop_id: shopId,
            };

            const result = await dispatch(clientList(payloadData));

            if (result.type === clientList.fulfilled.toString()) {
                setClientArrayList(result.payload.data.data);
                setTotalClients(result.payload.data.totalRecords);
                setLoading(false);
            }
        }, Math.random() * 1000 + 250);
    };
    useEffect(() => {
        if (searchInput === '') {
            setlazyState({
                ...lazyState,
                filters: {
                    ...lazyState.filters,
                    global: { value: searchInput },
                },
            });
        }
    }, [searchInput]);

    const onPage = (event: any) => {
        setlazyState(event);
    };

    const onSort = (event: any) => {
        setlazyState(event);
    };

    const onFilter = (event: any) => {
        event.first = 0;
        setlazyState(event);
    };

    const downloadExcel = async (type: string) => {
        setLoading(true);
        if (type === 'import_xlsx') {
            const data = {
                type: 'xlsx',
                shop_id: shopId,
            };

            const result = await dispatch(downloadImportClientsExcelOrCsv(data));
            if (result.type === downloadImportClientsExcelOrCsv.fulfilled.toString()) {
                const response = result.payload.data;
                const fileName = `client.xlsx`;
                const blob = new Blob(
                    [
                        new Uint8Array(
                            atob(response)
                                .split('')
                                .map((char) => char.charCodeAt(0)),
                        ),
                    ],
                    {
                        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    },
                );

                const url = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.download = fileName;
                document.body.appendChild(link);
                link.click();
                setLoading(false);
            }

            if (isOpenImportPopup) {
                setIsFileUpload(true);
            }
        } else {
            const data = {
                type: type,
                shop_id: shopId,
            };
            const result = await dispatch(downloadExcelOrCsv(data));
            if (result.type === downloadExcelOrCsv.fulfilled.toString()) {
                const response = result.payload.data;
                const res = response?.[type];
                const fileName = `client.${type}`;
                const blob = new Blob(
                    [
                        new Uint8Array(
                            atob(res)
                                .split('')
                                .map((char) => char.charCodeAt(0)),
                        ),
                    ],
                    {
                        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    },
                );

                const url = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.download = fileName;
                document.body.appendChild(link);
                link.click();
                setLoading(false);
            }
        }
        setSelectedAction('');
    };

    useEffect(() => {
        if (selectedAction?.value === 'export_as_excel') {
            downloadExcel('xlsx');
        }
        if (selectedAction?.value === 'export_as_csv') {
            downloadExcel('csv');
        }
        if (selectedAction?.value === 'import_clients') {
            setIsOpenImportPopup(true);
        }
    }, [selectedAction]);

    const handleRowClick = (rowData?: any) => {
        if (rowData) setClientInfo(rowData);
        setIsDrawerOpen(true);
    };

    const customPaginatorTemplate = {
        layout: 'CurrentPageReport PrevPageLink PageLinks NextPageLink',
        CurrentPageReport: ({ first, last, totalRecords }: { first: number; last: number; totalRecords: number }) => (
            <div className="text-mainTextColor flex items-center font-[SF-Pro] text-base mr-auto">
                <span>
                    {first} - {last} of {totalRecords}
                </span>
            </div>
        ),
    };

    const GetFullName = (row: any) => {
        const [isImageError, setIsImageError] = useState<boolean>(false);

        return (
            <div className="flex items-center">
                <figure className="w-[38px] h-[38px] basis-[38px] grow-0 shrink-0 rounded-full overflow-hidden mr-3 flex text-xs font-medium justify-center items-center bg-primary text-white">
                    {row.profile_image_url && !isImageError ? (
                        <img src={row.profile_image_url} alt="client" className="w-full h-full object-cover" onError={() => setIsImageError(true)} />
                    ) : row.full_name ? (
                        getShortName(`${row.full_name}`)
                    ) : (
                        '+'
                    )}
                </figure>
                <div className="">
                    <p className="text-sm font-medium mb-[2px] leading-[140%] -tracking-[0.12px]">{row.full_name ? formatName(row.full_name) : ''}</p>
                </div>
            </div>
        );
    };

    const getEmail = (row: any) => (
        <div className="font-normal leading-[140%] -tracking-[0.12px] flex items-center">
            <p className="text-sm font-medium leading-[140%] -tracking-[0.12px] flex items-center">{row.email && row.email.length > 15 ? `${row.email.slice(0, 15)} ...` : row.email}</p>
            {row.email && row.email.length > 10 ? (
                <Tooltip className="bg-mainTextColor text-xs" content={row.email}>
                    <RxInfoCircled className="text-sm" />
                </Tooltip>
            ) : (
                ''
            )}
        </div>
    );

    const deleteAccount = async () => {
        const data = {
            shop_id: shopId,
            id: clientInfo?.id,
        };
        const result = await dispatch(deleteClient(data));
        if (result.type === deleteClient.fulfilled.toString()) {
            setIsOpenRemovePopup(false);
            setIsDrawerOpen(false);
            loadLazyData();
        }
    };

    const deletePopupContent = (
        <div className="text-center deletePopup">
            <h3 className="mb-5 text-xl font-bold text-mainTextColor -tracking-[0.4px]">{t('Delete account')}</h3>
            <p className="mb-8 text-sm font-normal text-secondaryTxtColor -tracking-[0.3px]">{t('delete_confirmation_message')}</p>
            <div className="w-full">
                <Button
                    className="w-full fl-btn px-[10px] btn_primary text-base text-center justify-center font-bold mb-3"
                    onClick={() => {
                        setIsOpenRemovePopup(false);
                    }}
                >
                    {t('Not Now')}
                </Button>
                <Button className="w-full fl-btn text-base font-bold btn_outline_black px-[10px] justify-center" onClick={() => deleteAccount()}>
                    {t('Yes, Im sure')}
                </Button>
            </div>
        </div>
    );

    return (
        <>
            <div className="inner-page-wrape">
                <div className="flex justify-between items-center">
                    <div className="flex items-center">
                        <WrapperHeading content={t('Clients')} />
                        <span className="ml-[18px] text-secondaryTxtColor text-base font-medium -tracking-[0.4px]">
                            &bull; ({totalClients} {t('Clients')})
                        </span>
                    </div>
                    <div className="ml-auto flex items-center">
                        <div className="w-[200px] mx-4">
                            <SelectBox
                                placeholder={`${t('Options')}`}
                                defaultMenuIsOpen
                                options={optionsList}
                                value={selectedAction}
                                onChangeFunc={(e: any) => setSelectedAction(e)}
                                classComp="outline-select-box bg-white h-10"
                            />
                        </div>
                        <Button className="btn_primary w-[200px] h-10" onClick={() => setIsOpenAddClient(true)}>
                            {t('Add Client')}
                        </Button>
                    </div>
                </div>
                <div className="w-full flex flex-col flex-1 rounded-2xl bg-white mt-6 border-[1px]  border-lineColor  ">
                    <div className="flex w-full p-6">
                        <div className="flex-auto ">
                            <SearchBar placeholder={t('Search by name or mobile phone')} className="" onChange={(e: any) => setSearchInput(e.target.value)} />
                        </div>
                        <div className="w-[250px] ml-6">
                            <div className="w-full">
                                <SelectBox name="clientId" id="clientId" value={selectedFilter} options={ClientList} onChangeFunc={handleClientChange} classComp="outline-select-box " />
                            </div>
                        </div>
                    </div>
                    {/* {!loading ? ( */}
                    <DataTable
                        paginatorTemplate={customPaginatorTemplate}
                        paginatorClassName="flex relative justify-end"
                        value={clientArrayList}
                        lazy
                        paginator={totalClients > lazyState.rows}
                        first={lazyState.first}
                        rows={lazyState.rows}
                        totalRecords={totalClients}
                        onPage={onPage}
                        onSort={onSort}
                        sortOrder={lazyState.sortOrder}
                        sortField={lazyState.sortField}
                        onFilter={onFilter}
                        filters={lazyState.filters}
                        loading={loading}
                        dataKey="id"
                        sortIcon={<PiCaretUpDownBold />}
                        className="font-[SF-Pro] text-sm flex-1 text-mainTextColor rounded-3xl overflow-hidden fl-data-table"
                        loadingIcon={<BarLoader isLoading={loading} />}
                        globalFilter={searchInput}
                        globalFilterFields={['first_name', 'last_name', 'email', 'phone', 'gender', 'total_booking', 'bookings_count', 'average_days', 'average_amount']}
                        selectionMode="single"
                        selection={clientInfo}
                        onSelectionChange={(e) => setClientInfo(e.value)}
                        onRowClick={handleRowClick}
                        emptyMessage={t('No clients found.')}
                    >
                        <Column field="first_name" header={t('Name')} body={GetFullName} className=" font-normal text-mainTextColor" sortable></Column>
                        <Column field="phone" header={t('Phone')} className=" font-medium text-mainTextColor" body={(row: any) => getFormatPhone(row.phone, row.phone_country_code)}></Column>
                        <Column field="email" header={t('Email')} className="text-mainTextColor" body={getEmail}></Column>
                        <Column
                            field="gender"
                            header={t('Gender')}
                            className=" font-medium text-mainTextColor"
                            body={(row: any) => (row.gender ? capitalizeFirstLetter(row?.gender) : '')}
                            sortable
                        ></Column>
                        <Column field="bookings_count" header={t('Total bookings')} className="font-medium text-mainTextColor"></Column>
                        <Column
                            field="average_days"
                            header={t('Av. booking time')}
                            className="font-medium text-mainTextColor"
                            body={(row: any) => (row.average_days ? `${row.average_days} days` : '')}
                            sortable
                        ></Column>
                        <Column
                            field="average_amount"
                            header={t('Average spend')}
                            className="font-medium text-mainTextColor"
                            body={(row: any) => (row.average_amount ? `${formateAmount(row.average_amount)}` : '')}
                            sortable
                        ></Column>
                    </DataTable>
                </div>

                {isOpenRemovePopup ? (
                    <Popup
                        size="max-w-[407px]"
                        className="p-8 pb-10 deletePopup"
                        parentClassName="z-50"
                        handleClose={() => {
                            setIsOpenRemovePopup(false);
                        }}
                        content={deletePopupContent}
                    />
                ) : (
                    ''
                )}
            </div>

            <Add
                isOpenPopup={isOpenAddClient}
                handleClose={() => {
                    setIsOpenAddClient(false);
                }}
                getClientList={loadLazyData}
            />

            <ClientDetails
                isDrawerOpen={isDrawerOpen}
                handleDrawer={() => {
                    setIsDrawerOpen(false);
                    setClientInfo(null);
                }}
                setIsOpenRemovePopup={setIsOpenRemovePopup}
                isOpenRemovePopup={isOpenRemovePopup}
                getClientList={loadLazyData}
                clientId={clientInfo?.id}
            />

            {isOpenImportPopup && (
                <Import
                    isOpenImportPopup={isOpenImportPopup}
                    handleClose={() => {
                        setIsOpenImportPopup(false);
                        setIsFileUpload(false);
                        setSelectedAction('');
                    }}
                    setIsFileUpload={setIsFileUpload}
                    isFileUpload={isFileUpload}
                    downloadExcel={downloadExcel}
                    loadLazyData={loadLazyData}
                    loading={loading}
                />
            )}
        </>
    );
};

export default Client;
