import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { API } from 'src/constants/api';
import { errorCode } from 'src/constants/errorCode';
import { axiosGet } from 'src/utils/requestClient';

interface ErrorType {
    message: string;
    status?: string;
    data?: any;
}

const initialState = {
    data: '',
    loading: false,
    error: null,
};

export const transactionHistoryList = createAsyncThunk('/shop/transactionHistory/list', async (payload: any, { rejectWithValue }) => {
    try {
        const response = await axiosGet(
            API.TRANSACTION_HISOTRY.LIST,
            {
                shop_id: payload.shop_id,
            },
            payload.data,
        );
        if (response.data.status === errorCode.success || response.data.status === errorCode.updateSuccess) {
            return response.data;
        }

        return rejectWithValue(response as ErrorType);
    } catch (err: any) {
        if (!err.response) {
            throw err;
        }
        return rejectWithValue(err.response.data as ErrorType);
    }
});

export const transactionHistorySlice = createSlice({
    name: '/shop/transactionHistory/detail',
    initialState,
    reducers: {
        setListing: (state, { payload }) => {
            state.data = payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(transactionHistoryList.pending, (state) => {
                state.loading = true;
            })
            .addCase(transactionHistoryList.fulfilled, (state, { payload }: any) => {
                state.loading = false;
            })
            .addCase(transactionHistoryList.rejected, (state, action: any) => {
                state.loading = false;
            });
    },
});
