import React, { FC } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import MyAccountElement from './MyAccountElement';
import { IAddPayMethodContent } from './MyAccount.interface';

const AddPayMethodContent: FC<IAddPayMethodContent> = ({ stripePromise, getCard, handleModalClose }) => (
    <>
        <h2 className="text-[32px] text-center font-semibold mb-8 leading-[124%] -tracking-[1.2px]">Add your payment method</h2>
        <Elements stripe={stripePromise}>
            <MyAccountElement closePopup={handleModalClose} cardList={getCard} />
        </Elements>
    </>
);

export default AddPayMethodContent;
