import React, { useEffect, useState } from 'react';
import { IProps } from './Services.interface';
import Button from 'src/components/Button';
import AddNewServiceForm from './Services.AddNewService.Form';
import { getSelectBoxOptions } from 'src/utils/global-functions';
import { useFormContext } from 'react-hook-form';

const AddNewService = ({ onClick, categoryList }: IProps) => {
    const [categoryOption, setCategoryOption] = useState([]);
    const { control, formState } = useFormContext();
    const errors: any = formState.errors;
    const getCategoryList = async () => {
        const categoryArray: any = getSelectBoxOptions(categoryList);
        setCategoryOption(categoryArray);
    };
    useEffect(() => {
        getCategoryList();
    }, []);

    return (
        <>
            <h3 className="fwpo_heading_title font-bold leading-[140%] text-center mb-8 ">Add new service</h3>
            <div className="h-full flex flex-wrap">
                <AddNewServiceForm optionCountry={categoryOption} control={control} errors={errors} />
                <div className="flex w-full px-10 justify-center mt-10">
                    <Button type="submit" className="fl-btn btn_primary w-[420px] " onClick={onClick}>
                        Continue
                    </Button>
                </div>
            </div>
        </>
    );
};

export default AddNewService;
