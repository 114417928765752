import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { selectUserInfo } from '../../Auth/Login/Login.slice';
import { Controller } from 'react-hook-form';
import InputWithLabel from 'src/components/InputWithLabel';
import { ICountryResponse } from './BusinessDetails.interface';
import { convertBase64ToFile, getSelectBoxOptions } from 'src/utils/global-functions';
import { ReactComponent as Cloud } from 'src/assets/svgs/upload 1.svg';
import { Images } from 'src/theme';
import CropperModal from 'src/components/CropperModal/CropperModal';
import SelectBox from 'src/components/SelectBox/SelectBox';
import { getCountry } from 'src/redux/services/country/Country.slice';
import { getState } from 'src/redux/services/state/State.slice';
import { getCity } from 'src/redux/services/city/City.slice';
import { useTranslation } from 'react-i18next';

const BusinessInfo = ({ errors, control, setValue, setFile }: any) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const user = useAppSelector(selectUserInfo);
    const shop = user.shops[0];

    const logoImageUrl = shop.logo_image_url;

    const [cropData, setCropData] = useState<any>(logoImageUrl || '');
    const [image, setImage] = useState<any>(logoImageUrl || '');
    const [upload, setUpload] = useState(false);

    const [optionCountry, setOptionContry] = useState<ICountryResponse[]>([]);
    const [optionState, setOptionState] = useState<ICountryResponse[]>([]);
    const [optionCity, setOptionCity] = useState<ICountryResponse[]>([]);

    useEffect(() => {
        getCountryFun();
    }, []);

    const getCountryFun = async () => {
        const result = await dispatch(getCountry());
        if (result.type === getCountry.fulfilled.toString()) {
            const contryData: ICountryResponse[] | [] = getSelectBoxOptions(result.payload.data);
            const countyId = shop.country ? contryData.find((option) => option?.value === shop.country) : null;
            setOptionContry(contryData);
            if (countyId) {
                setValue('country', countyId.value);
                getStateFun(countyId.id);
            }
        }
    };

    const getStateFun = async (country_id: number) => {
        const data = {
            country_id: country_id,
        };
        const result = await dispatch(getState(data));
        if (result.type === getState.fulfilled.toString()) {
            const stateData: ICountryResponse[] | [] = getSelectBoxOptions(result.payload.data);
            setOptionState(stateData);

            const stateDataId = shop.state ? stateData.find((option) => option?.value === shop.state) : null;

            if (stateDataId) {
                getCityFun(stateDataId.id);
                setValue('state', stateDataId.value);
            } else {
                setValue('state', '');
                setValue('city', '');
                setOptionCity([]);
            }
        }
    };

    const getCityFun = async (state_id: number) => {
        const data = {
            state_id: state_id,
        };
        const result = await dispatch(getCity(data));
        if (result.type === getCity.fulfilled.toString()) {
            if (result.type === getCity.fulfilled.toString()) {
                const cityData: ICountryResponse[] | [] = getSelectBoxOptions(result.payload.data);
                setOptionCity(cityData);
                const cityDataId = shop.city ? cityData.find((option) => option?.value === shop.city) : null;

                if (cityDataId) {
                    setValue('city', cityDataId.value);
                } else {
                    setValue('city', '');
                }
            }
        }
    };

    const imageUpload = (data: any) => {
        const appLogoImage = data;
        const fileInfo = convertBase64ToFile(appLogoImage);
        setFile(fileInfo);
        if (fileInfo && fileInfo.filename) {
            setValue('logo_image_name', fileInfo.filename);
        }
    };

    const onChangeCrop = (e: any) => {
        e.preventDefault();
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        const reader = new FileReader();
        reader.onloadstart = () => {
            setImage('');
            setUpload(false);
        };
        reader.onloadend = () => {
            setImage(reader.result as any);
            setUpload(true);
        };
        reader.readAsDataURL(files[0]);
        e.target.value = null;
    };

    return (
        <>
            <h4 className="font-medium leading-[140%] -tracking-[0.14px] mb-2">{t('Business logo')}</h4>
            <p className="text-xs text-secondaryTxtColor leading-[150%] -tracking-[0.12px] mb-5">{t('Business_logo_description')}</p>
            <div className={`h-[180px] w-[180px] rounded-full bg-inputBg mx-auto broder border-inputBg flex justify-center items-center relative ${errors.logo_image_name && 'is-invalid'}`}>
                <div className="bg-contentBackground w-full">
                    <div className="w-[full]">
                        <div className="w-auto">
                            {!cropData ? (
                                <div className="relative overflow-hidden">
                                    <input type="file" id="logo_image_name" className="upload-pic-input" onChange={onChangeCrop} accept="image/png, image/jpg" />
                                    <label htmlFor="logo_image_name" className="upload-pic-label h-full w-full rounded-full relative flex justify-center items-center">
                                        {''}
                                        <div className="text-center">
                                            <img src={Images.AddImgIcon} alt="" className="mb-2 w-10 h-10 inline-block" />
                                            <p className="font-bold text-sm mb-1">Your logo</p>
                                            <p className="text-secondaryTxtColor ">
                                                JPG or PNG.
                                                <br /> Max size of 4MB
                                            </p>
                                        </div>
                                    </label>
                                </div>
                            ) : (
                                <>
                                    <div className="w-full h-full rounded-full relative overflow-hidden">
                                        <img src={cropData} alt="" className="w-full h-full" />
                                    </div>
                                    <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center pr-[8px]">
                                        <div className="bg-inputBg p-2 w-14 h-14 rounded-full flex justify-center items-center">
                                            <Cloud width={20} height={20} />
                                        </div>
                                    </div>
                                    <label htmlFor="logo_image_name" className="absolute left-0 top-0 z-20 cursor-pointer rounded-md px-8 py-2 overflow-hidden h-full w-full">
                                        {''}
                                        <input
                                            type="file"
                                            id="logo_image_name"
                                            className="imgupload  w-full rounded-md text-mainTextColor px-8 py-2 mt-8 bg-btnclr"
                                            style={{
                                                visibility: 'hidden',
                                            }}
                                            onChange={onChangeCrop}
                                            accept="image/png, image/jpg"
                                        />
                                    </label>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex flex-col mt-8">
                <Controller
                    name="business_name"
                    control={control}
                    render={({ field: { onChange, value } }: any) => (
                        <div className="mb-5">
                            <InputWithLabel name="business_name" label={t('Business name')} placeholder={t('Business name')} value={value} onChange={onChange} error={!!errors.business_name} />
                            {errors.business_name && <p className="text-error">{errors.business_name.message}</p>}
                        </div>
                    )}
                />
                <Controller
                    name="country"
                    control={control}
                    render={({ field: { onChange, value } }: any) => (
                        <div className="mb-5">
                            <SelectBox
                                name="country"
                                label={t('Country')}
                                options={optionCountry}
                                onChangeFunc={(selectedOption: any) => {
                                    getStateFun(selectedOption.id);
                                    onChange(selectedOption.value);
                                }}
                                value={optionCountry.find((option) => option?.value === value)}
                            />
                            {errors.country && <p className="text-error">{errors.country.message}</p>}
                        </div>
                    )}
                />
                <div className="flex -mx-[10px]">
                    <Controller
                        name="state"
                        control={control}
                        render={({ field: { onChange, value } }: any) => (
                            <div className="w-1/2 mb-5 px-[10px]">
                                <SelectBox
                                    name="state"
                                    label={t('Province/State')}
                                    options={optionState}
                                    onChangeFunc={(selectedOption: any) => {
                                        getCityFun(selectedOption.id);
                                        onChange(selectedOption.value);
                                    }}
                                    value={value ? optionState.find((option) => option.value === value) : null}
                                />
                                {errors.state && <p className="text-error">{errors.state.message}</p>}
                            </div>
                        )}
                    />
                    <Controller
                        name="city"
                        control={control}
                        render={({ field: { onChange, value } }: any) => (
                            <div className="w-1/2 mb-5 px-[10px]">
                                <SelectBox
                                    name="city"
                                    label={t('City')}
                                    options={optionCity}
                                    onChangeFunc={(selectedOption: any) => onChange(selectedOption.value)}
                                    value={value ? optionCity.find((option) => option.value === value) : null}
                                />
                                {errors.city && <p className="text-error">{errors.city.message}</p>}
                            </div>
                        )}
                    />
                </div>
                <Controller
                    name="address"
                    control={control}
                    render={({ field: { onChange, value } }: any) => (
                        <div className="mb-5">
                            <InputWithLabel label={t('Business Address')} placeholder={t('Enter Address')} onChange={onChange} value={value} name="address" error={!!errors.address} />
                            {errors.address && <p className="text-error">{errors.address.message}</p>}
                        </div>
                    )}
                />
                <Controller
                    name="staff_size"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                        <div>
                            <div className="fl-field-title-label">{t('Team Size')}</div>
                            <ul className="list-none inline-flex ">
                                <li className="mr-3">
                                    <input type="radio" id="independent" value="independent" className="hidden peer" onChange={() => onChange('independent')} checked={value === 'independent'} />
                                    <label
                                        htmlFor="independent"
                                        className="inline-flex items-center justify-between p-3 px-5 text-secondaryTxtColor bg-inputBg border border-transparent rounded-full cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-primary peer-checked:border-primary peer-checked:text-primary"
                                    >
                                        <div className="block text-sm">{t('Independent')}</div>
                                    </label>
                                </li>
                                <li className="mr-3">
                                    <input
                                        type="radio"
                                        id="block2to5"
                                        name="staff_size"
                                        value="block2to5"
                                        className="hidden peer"
                                        onChange={() => onChange('block2to5')}
                                        checked={value === 'block2to5'}
                                    />
                                    <label
                                        htmlFor="block2to5"
                                        className="inline-flex items-center justify-between  p-3 px-5 text-secondaryTxtColor bg-inputBg border border-transparent rounded-full cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-primary peer-checked:border-primary peer-checked:text-primary "
                                    >
                                        <div className="block text-sm whitespace-nowrap">{t('2-5')}</div>
                                    </label>
                                </li>
                                <li>
                                    <input
                                        type="radio"
                                        id="block6plus"
                                        name="staff_size"
                                        value="block6plus"
                                        className="hidden peer"
                                        onChange={() => onChange('block6plus')}
                                        checked={value === 'block6plus'}
                                    />
                                    <label
                                        htmlFor="block6plus"
                                        className="inline-flex items-center justify-between  p-3 px-5 text-secondaryTxtColor bg-inputBg border border-transparent rounded-full cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-primary peer-checked:border-primary peer-checked:text-primary "
                                    >
                                        <div className="block text-sm">{t('6+')}</div>
                                    </label>
                                </li>
                            </ul>
                        </div>
                    )}
                />
            </div>
            {upload && <CropperModal defaultCropType="Rounded" imageUrl={image} imageUpload={imageUpload} setUpload={setUpload} setCropData={setCropData} />}
        </>
    );
};

export default BusinessInfo;
