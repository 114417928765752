import React, { FC, useEffect, useState } from 'react';

import { useForm, Controller } from 'react-hook-form';
import { useAppDispatch } from 'src/redux/hooks';
import { IOtp, IOtpApiVerify, IVerifyOtpProps } from './VerifyOtp.interface';
import { verifyOtp } from './VerifyOtp.slice';
import OTPField from './OTPField/OTPField';
import { useTranslation } from 'react-i18next';
import { LocalStorageKeys } from 'src/constants/keys';
import { errorCode } from 'src/constants/errorCode';
import { forgotPassword } from '../ForgotPassword/ForgotPassword.slice';
import { IForgotPasswordCredential } from '../ForgotPassword/ForgotPassword.interface';
import { shopDetail } from '../../Login/Login.slice';

const VerifyOtp: FC<IVerifyOtpProps> = ({ setForgotPasswordStep, phone, countryDigit, formatedPhoneNumber, country }) => {
    const mainNumber: string = `(+${countryDigit}) ${formatedPhoneNumber}`;
    const [activeOTPIndex, setactiveOTPIndex] = useState<number>(0);
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const [counter, setCounter] = React.useState(60);
    React.useEffect(() => {
        const timer: any = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
        return () => clearInterval(timer);
    }, [counter]);
    const handlerAgainSend = async () => {
        setCounter(60);
        reset();
        const cread: IForgotPasswordCredential = {
            phone: phone,
            phone_country_code: country,
        };
        const result = await dispatch(forgotPassword(cread));

        if (result.type === forgotPassword.fulfilled.toString()) {
            setForgotPasswordStep('otp');
        }
    };

    const {
        handleSubmit,
        control,
        setError,
        setValue,
        formState: { errors },
        reset,
    } = useForm<IOtp>({
        defaultValues: {
            number: new Array(4).fill(''),
        },
    });
    useEffect(() => {
        if (activeOTPIndex === 4) {
            handleSubmit(handleChange)();
        }
    }, [activeOTPIndex]);

    const handleChange = async (data: any) => {
        const cread: IOtpApiVerify = {
            phone: phone,
            otp: data.number.join(''),
        };
        const result = await dispatch(verifyOtp(cread));

        if (result.type === verifyOtp.fulfilled.toString()) {
            localStorage.setItem(LocalStorageKeys.authToken, result.payload.data.token);
            // setForgotPasswordStep('verified');
            await dispatch(shopDetail());
        }
        if (result.type === verifyOtp.rejected.toString()) {
            setCounter(0);
            const response = result.payload.data.data;

            if (response.status === errorCode.unprocessable) {
                setError('number', {
                    type: 'manual',
                    message: 'Invalid OTP. Please retry.',
                });
                setValue('number', new Array(4).fill(''));
                setactiveOTPIndex(0);
            }
        }
    };

    return (
        <div className="content mt-10 ">
            <h2 className="text-[32px] font-semibold m-0 leading-[124%] -tracking-[0.384px]">{t('Enter the 4-digit code')}</h2>
            <div className="flex mt-4 text-mainTextColor text-sm leading-[140%] -tracking-[0.14px]">
                <span className="opacity-70"> {t('A 4-digit code has been sent to')}</span>
                <span className="ml-3 font-bold text-mainTextColor text-sm leading-[140%] -tracking-[0.14px]">{mainNumber}</span>
            </div>

            <div className="mx-auto">
                <form onSubmit={handleSubmit(handleChange)}>
                    <Controller
                        name="number"
                        control={control}
                        render={({ field: { onChange, value } }: any) => (
                            <div className="flex flex-col mt-8">
                                <OTPField otp={value} setOtp={onChange} activeOTPIndex={activeOTPIndex} setactiveOTPIndex={setactiveOTPIndex} errors={errors.number} />
                                {errors.number && <p className="text-error text-center">{errors.number.message}</p>}
                            </div>
                        )}
                    />

                    <div className="mt-10 flex flex-col items-center">
                        <p className="text-secondaryTxtColor leading-[19.6px] -tracking-[0.384px]">{t("Haven't received the code yet?")}</p>
                        <span className="mt-2">
                            {counter === 0 && (
                                <span onClick={handlerAgainSend} className={`${counter === 0 && 'cursor-pointer'} leading-[19.6px] text-sm font-bold text-mainTextColor`}>
                                    {t('Send again')}
                                </span>
                            )}
                            <span className="text-sm font-bold text-primary">
                                {counter === 0 ? (
                                    ''
                                ) : (
                                    <span className="leading-5 text-sm font-bold">
                                        ({counter}
                                        {t('sec')})
                                    </span>
                                )}
                            </span>
                        </span>
                    </div>
                    {/* <Button className="fl-btn text-base bg-primary text-white -tracking-[0.16px] font-bold w-full mt-10 rounded-full hover:bg-blue-800 transition-all ease-in-out delay-75">
                        {t('Confirm')}
                    </Button> */}
                </form>
            </div>
        </div>
    );
};

export default VerifyOtp;
