import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Button from 'src/components/Button';
import { IMenu, IMenuProps } from './AdminDetails.interface';
import { usePhone } from 'src/hooks/usePhone';
import { getShortName } from 'src/utils/global-functions';
import { SlArrowRight } from 'react-icons/sl';
import { TfiPencilAlt } from 'react-icons/tfi';
import { IoMdClose } from 'react-icons/io';
import DropDownWithIcon from 'src/components/DropDownWithIcon';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { selectUserInfo } from 'src/app/Auth/Login/Login.slice';
import { deleteAdmin } from '../Admin.slice';
import Popup from 'src/components/Popup';

const Menu = ({ menuList, setActiveStep, adminInfo, activeStep, handleClose, setIsEditProfile, getAdminList }: IMenuProps) => {
    const dispatch = useAppDispatch();
    const user = useAppSelector(selectUserInfo);
    const shopId = user.shops[0].id;

    const { getCustomFormatPhone } = usePhone();
    const [isOpenRemovePopup, setIsOpenRemovePopup] = useState<boolean>(false);

    const actionBtnIcon = [
        {
            label: <span>Edit Profile</span>,
            value: 'edit_profile',
            icon1: <TfiPencilAlt className="text-primary" size={20} />,
            icon1Position: 'left',
            icon2: <SlArrowRight className="text-secondaryTxtColor" />,
            icon2Position: 'right',
        },
        {
            label: <span>Delete Account</span>,
            value: 'delete_account',
            icon1: <IoMdClose className="text-dangerText" size={20} />,
            icon1Position: 'left',
            icon2: <SlArrowRight className="text-secondaryTxtColor" />,
            icon2Position: 'right',
        },
    ];

    const handleAction = (e: any) => {
        if (e.value === 'edit_profile') {
            if (activeStep !== 1) setActiveStep(1);
            setIsEditProfile(true);
        }
        if (e.value === 'delete_account') {
            setIsOpenRemovePopup(true);
        }
    };

    const deleteAccount = async () => {
        const data = {
            shop_id: shopId,
            id: adminInfo?.id,
        };
        const result = await dispatch(deleteAdmin(data));
        if (result.type === deleteAdmin.fulfilled.toString()) {
            handleClose(false);
            getAdminList();
        }
    };

    const deletePopupContent = (
        <div className="text-center">
            <h3 className="mb-5 text-xl font-bold text-mainTextColor -tracking-[0.4px]">Delete account</h3>
            <p className="mb-8 text-sm font-normal text-secondaryTxtColor -tracking-[0.3px]">Are you sure you want to delete your account?</p>
            <div className="w-full">
                <Button
                    className="w-full fl-btn px-[10px] btn_primary text-base text-center justify-center font-bold mb-3"
                    onClick={() => {
                        setIsOpenRemovePopup(false);
                    }}
                >
                    Not now
                </Button>
                <Button className="w-full fl-btn text-base font-bold btn_outline_black px-[10px] justify-center" onClick={() => deleteAccount()}>
                    {"Yes, I'm sure"}
                </Button>
            </div>
        </div>
    );

    return (
        <div className="drawer-menu-block">
            {/* drawer user block */}
            <div className="pt-[43px] px-8 pb-6 border-b border-lineColor">
                {/* user img */}
                <div className="rounded-full bg-primary w-[164px] h-[164px] mx-auto">
                    <div className="w-full h-full overflow-hidden rounded-full flex justify-center items-center ">
                        {adminInfo.profile_image_url ? (
                            <img src={adminInfo.profile_image_url} alt="" className="w-full h-full object-cover" />
                        ) : (
                            <span className="text-[64px] font-medium text-white leading-[140%] -tracking-[0.64px]">{getShortName(`${adminInfo.first_name ?? ''} ${adminInfo.last_name ?? ''}`)}</span>
                        )}
                    </div>
                </div>
                {/* user info */}
                <div className="mt-6 text-center">
                    <h3 className="text-[28px] font-medium leading-[124%] -tracking-[1.2px] mb-[15px]">{`${adminInfo.first_name} ${adminInfo.last_name}`}</h3>

                    <h3 className="text-primary text-lg leading-[140%] -tracking-[0.4px]">{adminInfo.email}</h3>
                    <div className="mt-2 mb-5">
                        <h3 className="text-primary font-medium text-lg leading-[140%] -tracking-[0.4px]">{getCustomFormatPhone(adminInfo.phone, adminInfo.phone_country_code)}</h3>
                    </div>
                    <DropDownWithIcon
                        className="btn btn_outline_black text-base font-bold border flex w-full justify-center"
                        label=" Actions"
                        dropDownMenuClassName="w-full top-[100%] py-6"
                        dropDownMenuItemClassName="border-b text-left py-5 last:border-0"
                        dropDownMenuLinkClassName="mx-3"
                        data={actionBtnIcon}
                        setselected={(e: any) => {
                            handleAction(e);
                        }}
                    />
                </div>
            </div>
            {/* drawer user block end*/}
            {/* drawer menu block */}
            <ul className="list-none text-base py-6 px-8">
                {menuList.map((menu: IMenu) => (
                    <li className="mb-2" key={menu.step}>
                        <Link
                            className={`leading-[140%] -tracking-[0.4px] transition-all ease-in delay-100 hover:bg-inputBg rounded-3xl px-6 py-[14px] block ${
                                activeStep === menu.step ? 'bg-inputBg' : ''
                            }`}
                            to={'#'}
                            onClick={() => setActiveStep(menu.step)}
                        >
                            {menu.name}
                        </Link>
                    </li>
                ))}
            </ul>

            {isOpenRemovePopup ? (
                <Popup
                    size="max-w-[407px]"
                    className="p-8 pb-10"
                    handleClose={() => {
                        setIsOpenRemovePopup(false);
                    }}
                    content={deletePopupContent}
                />
            ) : (
                ''
            )}

            {/* drawer menu block end */}
        </div>
    );
};

export default Menu;
