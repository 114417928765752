import React from 'react';
import MyAccount from './MyAccount/MyAccount';
import CommissionPayout from './CommissionPayout/CommissionPayout';
import RentCollection from './RentCollection/RentCollection';
import TransactionHistory from './TransactionHistory/TransactionHistory';
import MyPlan from './MyPlan/Myplan';

const Tabs = ({
    activeTab,
}: // selectedColors,
{
    activeTab: {
        name?: string;
        data?: any;
    };

    // selectedColors: any;
}) => (
    <>
        {activeTab.name === 'My Account' && <MyAccount activeTab={activeTab} />}
        {activeTab.name === 'Commision Payouts' && <CommissionPayout activeTab={activeTab} />}
        {activeTab.name === 'Rent Collection' && <RentCollection activeTab={activeTab} />}
        {/* {activeTab.name === 'Cash Register' && (
            <CashRegister activeTab={activeTab} />
        )} */}
        {activeTab.name === 'Transactions History' && <TransactionHistory activeTab={activeTab} />}
        {activeTab.name === 'My Plan' && <MyPlan activeTab={activeTab} />}
    </>
);

export default Tabs;
