import React from 'react';
import FormSettings from './FormSettings';
import MobHomeAppointment from 'src/assets/customsvg/MobHomeAppointment';
import MobBookNow from 'src/assets/customsvg/MobBookNow';

const MobileSettings = ({ index, setting, logoImageUrl, themeOptions, handleAction, onChangeCrop }: any) => (
    <div className="flex ">
        <FormSettings index={index} setting={setting} logoImageUrl={logoImageUrl} themeOptions={themeOptions} handleAction={handleAction} onChangeCrop={onChangeCrop} />

        <div className="w-full max-w-[763px]">
            <p className="fl-field-title-label">Preview</p>
            <div className="flex justify-center fl-mob-app-tab-content">
                <div className="mob-screen-wrap rounded-2xl border-[2px] border-lineColor p-4 mr-6">
                    <MobHomeAppointment selectedColors={setting} />
                </div>
                <div className="mob-screen-wrap rounded-2xl border-[2px] border-lineColor p-4">
                    <MobBookNow selectedColors={setting} />
                </div>
            </div>
        </div>
    </div>
);

export default MobileSettings;
