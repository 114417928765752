import React, { Dispatch, useEffect, useState } from 'react';
import { selectUserInfo } from 'src/app/Auth/Login/Login.slice';
import { RxInfoCircled } from 'react-icons/rx';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import './../../../utils/Datatable.scss';
import { Images } from 'src/theme';
import moment from 'moment';
import { Tooltip } from 'flowbite-react';
import './../../../utils/prime-react-datatable.scss';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.min.css';
import { capitalizeFirstLetter, capitalizeFirstLetterAndUnderscore, capitalizeFirstLetterSpace, getShortName } from 'src/utils/global-functions';
import { appointmentList } from '../Staff.slice';
import { useTranslation } from 'react-i18next';

const AppointmentTable = ({
    staffInfo,
    type,
    setIsUpcomingLoading,
    setIsPastLoading,
}: {
    staffInfo: any;
    type: string;
    setIsPastLoading?: Dispatch<React.SetStateAction<boolean>>;
    setIsUpcomingLoading?: Dispatch<React.SetStateAction<boolean>>;
}) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const user = useAppSelector(selectUserInfo);
    const shopId = user.shops[0].id;
    const [appointmenList, setAppointmentList] = useState<any>([]);
    const [totalAppointment, setTotalAppointment] = useState<any>();
    const [lazyState, setlazyState] = useState({
        first: 0,
        rows: type === 'past' ? 10 : 5,
        page: 1,
        sortField: null,
        sortOrder: null,
    });

    useEffect(() => {
        getClientAppointment();
    }, [lazyState]);

    let networkTimeout: any = null;

    const getClientAppointment = async () => {
        setIsPastLoading && setIsPastLoading(true);
        setIsUpcomingLoading && setIsUpcomingLoading(true);
        if (networkTimeout) {
            clearTimeout(networkTimeout);
        }
        //imitate delay of a backend call
        networkTimeout = setTimeout(async () => {
            const payloadData = {
                data: { ...lazyState, ...{ type: type } },
                param: {
                    shop_id: shopId,
                    id: staffInfo?.id,
                },
            };
            const result = await dispatch(appointmentList(payloadData));
            if (result.type === appointmentList.fulfilled.toString()) {
                setAppointmentList(result.payload.data.data);
                setTotalAppointment(result.payload.data.totalRecords);
                setIsPastLoading && setIsPastLoading(false);
                setIsUpcomingLoading && setIsUpcomingLoading(false);
            }
        }, Math.random() * 1000 + 250);
    };

    const GetFullName = (row: any) => {
        const [isImageError, setIsImageError] = useState<boolean>(false);
        return (
            <div className="flex items-center">
                <figure className="w-[38px] h-[38px] basis-[38px] grow-0 shrink-0 rounded-full overflow-hidden mr-3 flex text-xs font-medium justify-center items-center bg-primary text-white">
                    {row.user.profile_image_url && !isImageError ? (
                        <img src={row.user.profile_image_url} alt="staff" className="w-full h-full" onError={() => setIsImageError(true)} />
                    ) : (
                        getShortName(`${row.user.full_name}`)
                    )}
                </figure>
                <div className="">
                    <p className="text-xs font-normal mb-[2px] leading-[140%] -tracking-[0.12px]">{row.user.full_name ? capitalizeFirstLetterSpace(`${row.user.full_name}`) : ''}</p>
                    <p className="text-xs font-normal leading-[150%] -tracking-[0.12px] text-secondaryTxtColor">
                        {moment(`${row.booking_date}' ' ${row.booking_start_time}`, 'YYYY-MM-DD HH:mm:ss').format('DD MMM YYYY • hh:mmA')}
                    </p>
                </div>
            </div>
        );
    };
    const getService = (row: any) => {
        const serviceData = row.booking_services;
        const serviceNames = serviceData.map((entry: any) => entry.service.name);
        const servicesString = serviceNames.join(', ');

        return (
            <div className="text-xs font-normal leading-[140%] -tracking-[0.12px] flex items-center">
                <div className="mr-1">{serviceNames[0].length > 10 ? `${serviceNames[0].slice(0, 10)} ...` : serviceNames[0]}</div>
                {serviceNames.length > 1 || serviceNames[0].length > 10 ? (
                    <Tooltip className="bg-mainTextColor text-xs" content={servicesString}>
                        <RxInfoCircled className="text-sm" />
                    </Tooltip>
                ) : (
                    ''
                )}
            </div>
        );
    };
    const getLocation = (row: any) => (
        <div className="text-xs font-normal leading-[140%] -tracking-[0.12px] flex items-center">
            <p className="text-xs text-primary font-medium leading-[140%] -tracking-[0.12px] flex items-center">
                <i className="mr-1 w-4 h-4">
                    <img src={Images.mapLocationPin} alt="map-pin" className="w-4 h-4" />
                </i>
                {row.location && row.location.name.length > 10 ? `${row.location.name.slice(0, 10)} ...` : row.location.name}
            </p>
            {row.location && row.location.name.length > 10 ? (
                <Tooltip
                    className="bg-mainTextColor text-xs"
                    // style="dark"
                    content={row.location.name}
                >
                    <RxInfoCircled className="text-sm" />
                </Tooltip>
            ) : (
                ''
            )}
        </div>
    );
    const getStatus = (row: any) => {
        if (row.status === 'confirmed') return <span className="text-xs font-normal leading-[140%] -tracking-[0.12px] text-yellow-400">{capitalizeFirstLetter(row.status)}</span>;
        else if (row.status === 'no_show' || row.status === 'request_canceled')
            return <span className="text-xs font-normal leading-[140%] -tracking-[0.12px] text-yellow-400">{capitalizeFirstLetterAndUnderscore(row.status)}</span>;
        else if (row.status === 'canceled' || row.status === 'declined' || row.status === 'expired')
            return <span className="text-xs font-normal leading-[140%] -tracking-[0.12px] text-dangerText">{capitalizeFirstLetter(row.status)}</span>;
        else return <span className="text-xs font-normal leading-[140%] -tracking-[0.12px] text-green">{capitalizeFirstLetter(row.status)}</span>;
    };

    const onPage = (event: any) => {
        event.page += 1;
        setlazyState(event);
    };

    return (
        <>
            {/* {!loading ? ( */}
            <DataTable
                paginatorTemplate="PrevPageLink PageLinks NextPageLink"
                paginatorClassName="flex relative justify-end"
                value={appointmenList}
                lazy
                paginator={totalAppointment > lazyState.rows}
                first={lazyState.first}
                rows={lazyState.rows}
                totalRecords={totalAppointment}
                onPage={onPage}
                sortOrder={lazyState.sortOrder}
                className="font-[SF-Pro] text-sm flex-1 text-mainTextColor rounded-3xl overflow-hidden fl-data-table"
                dataKey="id"
                emptyMessage={t(type === 'past' ? 'No latest booking activity found' : 'No upcoming appointment found')}
            >
                <Column field="name" header={t('Client')} body={GetFullName} className="text-xs font-normal"></Column>
                <Column field="service" header={t('Services')} body={getService} className="text-xs font-normal"></Column>
                <Column field="total" header={t('Price')} className="text-xs font-normal"></Column>
                <Column field="location" header={t('Location')} body={getLocation} className="text-xs font-normal"></Column>
                <Column field="status" header={t('Status')} body={getStatus} className="text-xs font-normal"></Column>
            </DataTable>
        </>
    );
};

export default AppointmentTable;
