import React, { FC, useState } from 'react';
import InputWithLabel from 'src/components/InputWithLabel';
import Button from 'src/components/Button';
import { useForm, Controller } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { IFormData, IFormDataRequest, IPops } from './Account.interface';
import { FaArrowLeftLong } from 'react-icons/fa6';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { account, selectAccountLoading } from './Account.slice';
import { Images } from 'src/theme';
import { errorCode } from 'src/constants/errorCode';
import { toast } from 'react-toastify';
import { selectUserInfo, shopDetail } from 'src/app/Auth/Login/Login.slice';
import { checkFileTypeValidation, convertBase64ToFile, logout } from 'src/utils/global-functions';
import { s3Upload } from 'src/utils/s3Operations';
import { ReactComponent as Cloud } from 'src/assets/svgs/upload 1.svg';
import CropperModal from 'src/components/CropperModal/CropperModal';
import { GLOBALVARIABLE } from 'src/utils/global-variables';
import { useTranslation } from 'react-i18next';
type FieldKey = 'first_name' | 'last_name' | 'profile_image_name';

const Account: FC<IPops> = ({ setStep }) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const loading = useAppSelector(selectAccountLoading);
    const user = useAppSelector(selectUserInfo);
    const [file, setFile] = useState<any>({ convertedFile: '', filename: '' });
    const [isLoading, setIsLoading] = useState(false);
    const [upload, setUpload] = useState(false);

    const completeAccountStep = user.shops[0].account_step.complete_account;
    const imageUrl = completeAccountStep && user.profile_image_url ? user.profile_image_url : null;
    const [cropData, setCropData] = useState<any>(imageUrl || '');
    const [image, setImage] = useState<any>(imageUrl || '');

    const schema = Yup.object({
        first_name: Yup.string().required(t('First name required')),

        last_name: Yup.string().required(t('Last name required')),

        profile_image_name: Yup.string().required(t('Profile required')),

        is_accept_terms: Yup.boolean().oneOf([true], t('Terms agreement required')).required(t('Terms agreement required')),
    }).required();
    const onChangeCrop = (e: any) => {
        setUpload(false);
        e.preventDefault();
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        const { result, message } = checkFileTypeValidation(files, GLOBALVARIABLE.accountImageSize);
        if (result) {
            clearErrors('profile_image_name');
            const reader = new FileReader();
            reader.onloadstart = () => {
                setImage('');
                setUpload(false);
            };
            reader.onloadend = () => {
                setImage(reader.result as any);
                setUpload(true);
            };
            reader.readAsDataURL(files[0]);
            e.target.value = null;
            // setUploadProgress(0);
        } else {
            setError('profile_image_name' as 'profile_image_name', {
                type: 'manual',
                message: message,
            });
        }
    };

    const {
        handleSubmit,
        control,
        formState: { errors },
        setError,
        setValue,
        clearErrors,
    } = useForm<IFormData>({
        resolver: yupResolver(schema),
        defaultValues: {
            first_name: completeAccountStep ? user?.first_name : '',
            last_name: completeAccountStep ? user?.last_name : '',
            profile_image_name: completeAccountStep ? user.profile_image_name : '',
            is_accept_terms: completeAccountStep ? true : false,
        },
    });
    const handleChange = async (data: IFormData) => {
        setIsLoading(true);

        if (file.convertedFile && file?.filename) {
            const imgUploadResult: any = await s3Upload(file?.convertedFile, `images/admin_profile/${file?.filename}`);
            if (imgUploadResult.status === 201) {
                /* const url = imgUploadResult.data.Location.replace(
                    process.env.REACT_APP_AWS_UPLOAD_URL,
                    process.env.REACT_APP_PUBLIC_URL,
                ); */
                const updatedData = {
                    ...data,
                    logo_image_name: file.filename,
                };
                submitForm(updatedData);
            }
        } else {
            submitForm(data);
        }
    };
    const submitForm = async (data: IFormData) => {
        const cread: IFormDataRequest = {
            first_name: data.first_name,
            last_name: data.last_name,
            profile_image_name: data.profile_image_name,
        };
        const result = await dispatch(account(cread));
        if (result.type === account.fulfilled.toString()) {
            dispatch(shopDetail());
            setStep(2);
            setIsLoading(false);
        }

        if (result.type === account.rejected.toString()) {
            const response = result.payload.data;
            if (response.status === errorCode.unprocessable) {
                if (response.data) {
                    Object.keys(response.data).forEach((field) => {
                        setError(field as FieldKey, {
                            type: 'manual',
                            message: response.data[field][0],
                        });
                    });
                }
                setIsLoading(false);

                return;
            }
            toast.error(response.message);
        }
    };

    const imageUpload = (data: any) => {
        const appLogoImage = data;
        const fileInfo = convertBase64ToFile(appLogoImage);
        setFile(fileInfo);
        if (fileInfo && fileInfo.filename) {
            setValue('profile_image_name', fileInfo.filename);
        }
    };
    const removeUser = () => {
        logout();
    };

    return (
        <>
            <div className="w-[872px] 2xl:w-[1176px] flex-1 flex flex-col mt-[52px]">
                <div className="text-center">
                    <h3 className="fwpo_heading_title">{t('Create a business account')}</h3>
                    <p className="font-normal leading-[22.4px] mt-4 text-sm opacity-70">{t('account_description')}</p>
                </div>
                <div className="mt-10 h-full">
                    <form onSubmit={handleSubmit(handleChange)} className="h-full">
                        <div className="flex flex-col h-full justify-between">
                            <div className="">
                                <div className="mb-8 text-center">
                                    <div
                                        className={`h-[200px] w-[200px] rounded-full bg-inputBg mx-auto broder border-inputBg flex justify-center items-center relative   ${
                                            errors.profile_image_name && 'is-invalid'
                                        }`}
                                    >
                                        {upload && <CropperModal imageUpload={imageUpload} imageUrl={image} setUpload={setUpload} setCropData={setCropData} isCropType={false} />}
                                        <div className="bg-contentBackground w-full">
                                            <div className="w-[full]">
                                                <div className="w-auto">
                                                    {!cropData ? (
                                                        <div className="relative overflow-hidden">
                                                            <input type="file" id="fileInput" className="upload-pic-input" onChange={onChangeCrop} />
                                                            <label htmlFor="fileInput" className="upload-pic-label h-full w-full rounded-full relative flex justify-center items-center">
                                                                {''}
                                                                <div className="text-center">
                                                                    <img src={Images.AddImgIcon} alt="" className="mb-2 w-10 h-10 inline-block" />
                                                                    <p className="font-bold text-sm mb-1">{t('Your image')}</p>
                                                                    <p className="text-secondaryTxtColor ">
                                                                        {t('JPG or PNG')}
                                                                        <br /> {t('Max size of 4MB')}
                                                                    </p>
                                                                </div>
                                                            </label>
                                                        </div>
                                                    ) : (
                                                        <div className={``}>
                                                            <div className="w-full h-full rounded-full relative overflow-hidden">
                                                                <img src={cropData} alt="" className="w-full h-full" />
                                                            </div>
                                                            <div className="  absolute top-0 left-0 w-full h-full  flex justify-center items-center pr-[8px] ">
                                                                <div className="bg-inputBg p-2 w-14 h-14 rounded-full flex justify-center items-center">
                                                                    <Cloud width={20} height={20} />
                                                                </div>
                                                            </div>
                                                            <label htmlFor="fileInput" className="absolute left-0 top-0 z-20 cursor-pointer rounded-md px-8 py-2 overflow-hidden h-full w-full">
                                                                {''}
                                                                <input
                                                                    type="file"
                                                                    id="fileInput"
                                                                    className="imgupload  w-full rounded-md text-mainTextColor px-8 py-2 mt-8 bg-btnclr"
                                                                    style={{
                                                                        visibility: 'hidden',
                                                                    }}
                                                                    onChange={onChangeCrop}
                                                                />
                                                            </label>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <p className="text-error">{errors.profile_image_name && errors.profile_image_name.message}</p>
                                </div>
                                <div className="flex gap-6  flex-wrap">
                                    <Controller
                                        name="first_name"
                                        control={control}
                                        render={({ field: { onChange, value } }: any) => (
                                            <div className="w-[48%]">
                                                <InputWithLabel
                                                    label={t('First Name')}
                                                    placeholder={t('First Name')}
                                                    onChange={onChange}
                                                    value={value}
                                                    name="first_name"
                                                    error={errors.first_name && true}
                                                    disabled={isLoading}
                                                    isFocus={true}
                                                />
                                                <p className="text-error">{errors.first_name && errors.first_name.message}</p>
                                            </div>
                                        )}
                                    />

                                    <Controller
                                        name="last_name"
                                        control={control}
                                        render={({ field: { onChange, value } }: any) => (
                                            <div className="w-[48%]">
                                                <InputWithLabel
                                                    label={t('Last Name')}
                                                    placeholder={t('Last Name')}
                                                    onChange={onChange}
                                                    value={value}
                                                    name="last_name"
                                                    error={errors.last_name && true}
                                                    disabled={isLoading}
                                                />
                                                <p className="text-error">{errors.last_name && errors.last_name.message}</p>
                                            </div>
                                        )}
                                    />
                                </div>
                                <div className="flex items-start mt-5">
                                    <Controller
                                        name="is_accept_terms"
                                        control={control}
                                        render={({ field: { onChange, value } }: any) => (
                                            <>
                                                <input id="default-checkbox" type="checkbox" value={value} className="fl-custom-checkbox" onChange={onChange} checked={value} disabled={isLoading} />
                                                <label htmlFor="default-checkbox" className={`ml-3 text-sm font-normal pt-1`}>
                                                    {
                                                        <span className="text-sm -tracking-[0.3px]">
                                                            {t('I have read and accept')}{' '}
                                                            <a href="https://www.getflair.ca/terms-of-service" target="_blank" className="font-normal text-primary -tracking-[0.12px]">
                                                                {t('Terms & Conditions')}
                                                            </a>
                                                            &nbsp;{t('and')}{' '}
                                                            <a href="https://www.getflair.ca/privacy-policy" target="_blank" className="font-normal text-primary -tracking-[0.12px]">
                                                                {t('Privacy Policy')}
                                                            </a>
                                                        </span>
                                                    }
                                                </label>
                                                {errors.is_accept_terms && errors.is_accept_terms.message && <p className="text-error">{errors.is_accept_terms.message}</p>}
                                            </>
                                        )}
                                    />
                                </div>
                            </div>
                            <div className="flex w-full px-10 justify-center my-10">
                                <div className="back-button absolute left-10">
                                    <Button type="button" disabled={isLoading} className="btn_outline_black text-sm" onClick={removeUser}>
                                        <FaArrowLeftLong size={18} className="mr-2" /> {t('Back')}
                                    </Button>
                                </div>
                                <Button isLoading={isLoading} className="fl-btn btn_primary w-[420px] 2xl:w-[572px]" disabled={loading}>
                                    {t('Continue')}
                                </Button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};

export default Account;
