import React, { useEffect, useState } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';

import { Screens } from './Screens';
import { ERouteType, RouteItem } from 'src/constants/common';
import GuardedRoute from './GuardedRoute';
import NotGuardedRoute from './NotGuardedRoute';
import { useAppDispatch } from 'src/redux/hooks';
import { shopDetail } from 'src/app/Auth/Login/Login.slice';
import { LocalStorageKeys } from 'src/constants/keys';
import BarLoader from 'src/components/BarLoader';

const MainRoutes = (): JSX.Element => {
    const [loading, setLoading] = useState(true);
    const getRoute = (route: RouteItem): JSX.Element => {
        switch (route.type) {
            case ERouteType.GUARDED:
                return (
                    <Route
                        key={route.path}
                        path={route.path}
                        element={<GuardedRoute view={route.view} permission={route.permission} Component={route.component} title={route.title} layout={route.layout} />}
                    />
                );
            case ERouteType.NOT_GUARDED:
                return <Route key={route.path} path={route.path} element={<NotGuardedRoute Component={route.component} title={route.title} />} />;
            default:
                return <Route key={route.path} path={route.path} element={<NotGuardedRoute Component={route.component} title={route.title} />} />;
        }
    };
    const token = localStorage.getItem(LocalStorageKeys.authToken);
    const dispatch = useAppDispatch();
    useEffect(() => {
        const fetchData = async () => {
            await dispatch(shopDetail());
            setLoading(false);
        };
        token ? fetchData() : setLoading(false);
    }, []);

    return loading ? (
        <BarLoader />
    ) : (
        <Routes>
            {Screens.map((item): JSX.Element => getRoute(item))}
            <Route path="*" element={<Navigate to="/not-found" />} />
        </Routes>
    );
};

export default MainRoutes;
