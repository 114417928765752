import React, { useState } from 'react';
import { Controller, useForm, useFormContext } from 'react-hook-form';
import Button from 'src/components/Button';
import { useTranslation } from 'react-i18next';
import OTPField from 'src/components/OTPField';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { selectShopInfo, shopDetail } from 'src/app/Auth/Login/Login.slice';
import { axiosPost } from 'src/utils/requestClient';
import { API } from 'src/constants/api';
import { errorCode } from 'src/constants/errorCode';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { IPinVerificationFormData, IPinVerificationProps } from './ReceptionistMode.interface';
import { selectRoleInfo, setRole } from 'src/redux/RolePermission/RolePermission.slice';
import { ADMIN_PERMISSIONS, USER, USER_PERMISSIONS } from 'src/redux/RolePermission/RolePermission.interface';
type FieldKey = 'pin';

const PinVerification = ({ handleReceptionistMode, handleClose }: IPinVerificationProps) => {
    const shop = useAppSelector(selectShopInfo);
    const shopId = shop.id;
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const role = useAppSelector(selectRoleInfo);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleChange = async (data: any) => {
        setIsLoading(true);
        const payload = {
            ...data,
            pin: data.pin ? data.pin.join('') : '',
        };
        try {
            const response = await axiosPost(API.RECEPTIONIST_MODE.PIN_VERIFY, payload, {
                shop_id: shopId,
            });
            if (response.data?.status === errorCode.success || response.data?.status === errorCode.updateSuccess) {
                await dispatch(shopDetail());
                dispatch(setRole(role.name === USER ? ADMIN_PERMISSIONS : USER_PERMISSIONS));
                handleClose();
                return;
            }

            throw response.data;
        } catch (err: any) {
            if (err.status === errorCode.unprocessable) {
                if (err.data) {
                    Object.keys(err.data).forEach((field) => {
                        setError(field as FieldKey, {
                            type: 'manual',
                            message: err.data[field][0],
                        });
                    });
                }
                return;
            }
            toast.error(err?.message);
        } finally {
            setIsLoading(false);
        }
    };
    const schema = Yup.object({
        pin: Yup.array()
            .of(Yup.string().required('PIN digit is required').matches(/^\d$/, 'Each PIN digit must be a number'))
            .test('is-valid-pin', 'PIN is required and must be 4 digits', (pinArray) => {
                // Ensure pinArray is defined and not empty
                if (!pinArray || pinArray.length === 0) {
                    return false; // Return false directly if the condition is not met
                }

                const pinString = pinArray.join('');
                // Use a regular expression to check if the pinString is exactly 4 digits
                const isValid = /^\d{4}$/.test(pinString);

                // Instead of returning an empty string or undefined, directly return the boolean result of the validation
                return isValid;
            })
            .required('PIN is required'),
    }).required();
    const {
        handleSubmit,
        control,
        setError,
        formState: { errors },
    } = useForm<IPinVerificationFormData>({
        resolver: yupResolver(schema),
        defaultValues: {
            pin: new Array(4).fill(''),
        },
    });

    return (
        <>
            <form onSubmit={handleSubmit(handleChange)}>
                <h2 className="font-bold text-[20px] leading-[35px] -tracking-[0.336px] text-center pb-5">{role.name === USER ? t('Disable Receptionist Mode') : t('Enable Receptionist Mode')}</h2>
                <div className="flex flex-wrap">
                    <Controller
                        name="pin"
                        control={control}
                        render={({ field: { onChange, value } }: any) => (
                            <>
                                <div className="w-full">
                                    <div className="flex flex-col gap-8 text-center">
                                        <p className="text-secondaryTxtColor leading-[19.6px] -tracking-[0.384px]">
                                            {role.name === USER ? t('Enter 4-digit code to disable receptionist mode') : t('Enter 4-digit code to enable receptionist mode')}
                                        </p>
                                        <OTPField otp={value} setOtp={onChange} errors={errors.pin} />
                                    </div>
                                    {errors.pin && <p className="text-error">{errors.pin.message}</p>}
                                </div>
                                <Button type="button" onClick={() => handleReceptionistMode('forgotPin')} className={`ml-auto mt-3 leading-[140%] text-sm font-bold text-primary -tracking-[0.14px]`}>
                                    {t('Forgot PIN?')}
                                </Button>
                            </>
                        )}
                    />
                </div>
                <Button className={`fl-btn btn_primary mt-5`} disable={isLoading} isLoading={isLoading}>
                    Confirm
                </Button>
            </form>
        </>
    );
};

export default PinVerification;
