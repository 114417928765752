import React, { FC } from 'react';
import { Label, Radio } from 'flowbite-react';
import Button from 'src/components/Button';
import { FaArrowLeftLong } from 'react-icons/fa6';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import * as Yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import { IFormData, IPops } from './BookingPayment.interface';
import { bookingPayment, selectLoading } from './BookingPayment.slice';
import { errorCode } from 'src/constants/errorCode';
import { selectUserInfo, shopDetail } from 'src/app/Auth/Login/Login.slice';
import { useTranslation } from 'react-i18next';

const BookingPayment: FC<IPops> = ({ setStep }) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const schema = Yup.object().shape({
        is_book_with_card: Yup.boolean().required(t('Status required')),
    });
    const loading = useAppSelector(selectLoading);
    const user = useAppSelector(selectUserInfo);
    const BookingPaymentStep = user.shops[0].account_step.booking_payment;

    const { handleSubmit, control } = useForm<IFormData>({
        resolver: yupResolver(schema),
        defaultValues: {
            is_book_with_card: BookingPaymentStep ? user.shops[0].is_book_with_card : true,
        },
    });

    const handleChange = async (data: any) => {
        const result = await dispatch(bookingPayment(data));
        if (result.type === bookingPayment.fulfilled.toString()) {
            dispatch(shopDetail());
            setStep(6);
        }
        if (result.type === bookingPayment.rejected.toString()) {
            const response = result.payload.data;
            if (response.status === errorCode.unprocessable) {
                if (response.data) {
                    Object.keys(response.data).forEach((field) => {
                        // setError(field as FieldKey, {
                        //     type: 'manual',
                        //     message: response.data[field][0],
                        // });
                    });
                }
                return;
            }
            toast.error(response.message);
        }
    };
    const handelCardSelect = (onChange: any, value: any) => (e: any) => {
        onChange(value);
    };
    return (
        <>
            <form onSubmit={handleSubmit(handleChange)} className="h-full flex">
                <div className="w-[872px] 2xl:w-[1176px] flex-1 flex flex-col mt-[52px] items-center">
                    <div className="text-center">
                        <h3 className="fwpo_heading_title w-[500px] mx-auto">{t('Booking Payment Requirements')}</h3>
                        <p className="text-secondaryTxtColor leading-[140%] -tracing-[0.14px] mt-4 max-w-[736px] mx-auto">{t('booking_payment_description')}</p>
                    </div>
                    <Controller
                        name={`is_book_with_card`}
                        control={control}
                        render={({ field: { onChange, value } }: any) => (
                            <>
                                <div className="flex flex-col flex-1 gap-7 mt-8">
                                    <div onClick={handelCardSelect(onChange, true)} className="booking-card ">
                                        <div className="">
                                            <h4 className="mb-[2px] text-base leading-[140%] font-bold">{t('Book with card')}</h4>
                                            <p className="text-secondaryTxtColor leading-[140%] -tracing-[0.14px]">{t('book_with_card_description')}</p>
                                        </div>

                                        <div className="custom-round-radio-block">
                                            <Radio
                                                className="custom-round-radio"
                                                id="booking_1"
                                                name="is_book_with_card"
                                                value={true.toString()}
                                                onChange={handelCardSelect(onChange, true)}
                                                checked={value === true}
                                            />
                                            <Label className="custom-round-label" htmlFor="booking_1"></Label>
                                        </div>
                                    </div>
                                    <div onClick={handelCardSelect(onChange, false)} className="booking-card ">
                                        <div className="">
                                            <h4 className="mb-[2px] text-base leading-[140%] font-bold">{'Book without card'}</h4>
                                            <p className="text-secondaryTxtColor leading-[140%] -tracing-[0.14px]">{t('book_without_card_description')}</p>
                                        </div>

                                        <div className="custom-round-radio-block">
                                            <Radio
                                                className="custom-round-radio"
                                                id="booking_2"
                                                name="is_book_with_card"
                                                value={false.toString()}
                                                onChange={handelCardSelect(onChange, false)}
                                                checked={value === false}
                                            />
                                            <Label className="custom-round-label" htmlFor="booking_2"></Label>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                    />
                    <div className="flex w-full px-10 justify-center my-[60px]">
                        <div className="back-button absolute left-10">
                            <Button className="btn_outline_black text-sm" type="button" onClick={() => setStep(4)} disabled={loading}>
                                <FaArrowLeftLong size={18} className="mr-2" /> {t('Back')}
                            </Button>
                        </div>
                        <Button isLoading={loading} className="fl-btn btn_primary w-[420px] 2xl:w-[572px]">
                            {t('Continue')}
                        </Button>
                    </div>
                </div>
            </form>
        </>
    );
};

export default BookingPayment;
