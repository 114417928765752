import React, { useState } from 'react';
import Drawer from 'src/components/Drawer';
import { IProps } from '../CommissionPayout/ComissionPayout.interface';
import { capitalizeFirstLetterSpace, getShortName } from 'src/utils/global-functions';
import StaffDetails from 'src/app/Staff/StaffDetails/StaffDetails';
import Button from 'src/components/Button';
import { BiSolidChevronRight } from 'react-icons/bi';
import { Images } from 'src/theme';
import { usePhone } from 'src/hooks/usePhone';
import { axiosGet, axiosPatch } from 'src/utils/requestClient';
import { API } from 'src/constants/api';
import { useAppSelector } from 'src/redux/hooks';
import { selectShopInfo } from 'src/app/Auth/Login/Login.slice';
import { errorCode } from 'src/constants/errorCode';
import { toast } from 'react-toastify';
import BarLoader from 'src/components/BarLoader';
const PayoutDetail = ({ isDrawerOpen, handleDrawer, staffInfo, setIsDrawerOpen = () => {}, getRentList }: IProps) => {
    const [isOpenStaffDetail, setIsOpenStaffDetail] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState(false);

    const [isImageError, setIsImageError] = useState<boolean>(false);
    const { phoneInfo } = usePhone();
    const getphoneinfo = phoneInfo(staffInfo?.staff?.phone, staffInfo?.staff?.phone_country_code);
    const shop: any = useAppSelector(selectShopInfo);
    const shopId = shop.id;
    const handlePaid = async () => {
        setIsLoading(true);
        try {
            const response = await axiosPatch(
                API.PAYMENT.RENT_RETRY,
                {},
                {
                    shop_id: shopId,
                    id: staffInfo.id,
                },
            );
            if (response.data?.status === errorCode.success || response.data?.status === errorCode.updateSuccess) {
                getRentList && getRentList();
                setIsDrawerOpen(false);
                return;
            }

            throw response.data;
        } catch (err: any) {
            toast.error(err?.message);
        } finally {
            setIsLoading(false);
        }
    };

    const handleReceiptDownload = async () => {
        setIsLoading(true);
        try {
            const response = await axiosGet(API.PAYMENT.RECEIPT, {
                shop_id: shopId,
                id: staffInfo.id,
            });
            if (response.data?.status === errorCode.success || response.data?.status === errorCode.updateSuccess) {
                const receiptLink = response.data.data.receipt;
                const byteCharacters = atob(receiptLink);
                const byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                const blob = new Blob([byteArray], { type: 'application/pdf' });

                const url = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.download = `${staffInfo.staff.first_name}-receipt.pdf`;
                document.body.appendChild(link);
                link.click();

                document.body.removeChild(link);
                URL.revokeObjectURL(url);
                return;
            }
        } catch (err: any) {
            toast.error(err?.message);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <Drawer
                isOpen={isDrawerOpen}
                handleClose={handleDrawer(false)}
                title="Payout Details"
                isEditable
                isEditClose
                classDrawer="w-[391px]"
                isDrawerWidth={true}
                handleClickOutside={handleDrawer(false)}
                drawerHeight="your-height-value"
                classDrawerHeader="px-4"
            >
                <div className="w-full flex flex-col h-[calc[100vh-69px]] ">
                    <BarLoader isLoading={isLoading} />
                    <div className="px-4 py-6 border-b border-lineColor">
                        <div className="w-full flex items-center mb-5">
                            <figure className="w-[38px] h-[38px] basis-[38px] grow-0 shrink-0 rounded-full overflow-hidden mr-3 flex text-xs font-medium justify-center items-center bg-primary text-white">
                                {staffInfo.staff.profile_image_url && !isImageError ? (
                                    <img src={staffInfo.staff.profile_image_url} alt="client" className="w-full h-full object-cover" onError={() => setIsImageError(true)} />
                                ) : (
                                    getShortName(`${staffInfo.staff.first_name} ${staffInfo.staff.last_name}`)
                                )}
                            </figure>

                            <div className="flex-1 px-3">
                                <p className="font-medium leading-[140%] -tracking-[0.14px] mb-[2px]">{capitalizeFirstLetterSpace(`${staffInfo.staff.first_name} ${staffInfo.staff.last_name}`)}</p>
                                <p className="text-xs text-secondaryTxtColor leading-[150%] -tracking-[0.12px]">{staffInfo.staff.role.name}</p>
                            </div>
                            <BiSolidChevronRight size={20} className="text-secondaryTxtColor items-center cursor-pointer" onClick={() => setIsOpenStaffDetail(true)} />
                        </div>

                        <div className="">
                            <div className="text-sm leading-[140%] -tracking-[0.14px] flex items-center mb-2">
                                <span className="text-secondaryTxtColor w-[80px] basis-[80px] grow-0 shrink-0 mr-5">Phone:</span>
                                <span className="font-medium flex-1 truncate">{getphoneinfo && getphoneinfo.formatInternational()}</span>
                            </div>
                            <div className="text-sm leading-[140%] -tracking-[0.14px] flex items-center">
                                <span className="text-secondaryTxtColor w-[80px] basis-[80px] grow-0 shrink-0 mr-5">Email :</span>{' '}
                                <span className="font-medium flex-1 truncate">{staffInfo.staff.email ?? '-'}</span>
                            </div>
                        </div>
                    </div>

                    <div className=" p-4 flex-1">
                        <p className="flex items-center mb-6">
                            <i className="mr-3 w-4 h-4">
                                <img src={Images.iconWallClockOutlinePrimary} alt="" className="w-full" />
                            </i>
                            Showing data for the <span className="font-bold ml-[2px]">All time</span>
                        </p>
                        {/* <div className="flex justify-between mb-5 leading-[140%] -tracking-[0.14px]">
                            <p className="text-secondaryTxtColor">Total services</p>
                            <p className="ml-auto font-bold ">-</p>
                        </div> */}
                        <div className="flex justify-between mb-5 leading-[140%] -tracking-[0.14px]">
                            <p className="text-secondaryTxtColor">Payout period</p>
                            <p className="ml-auto font-bold ">{capitalizeFirstLetterSpace(staffInfo.frequency)}</p>
                        </div>
                        <p className="mb-2">Payment model:</p>
                        <div className="flex justify-between mb-5 leading-[140%] -tracking-[0.14px]">
                            <p className="text-secondaryTxtColor">Rent Amount</p>
                            <p className="ml-auto font-bold">${staffInfo.staff.pay_structure.rent_money}</p>
                        </div>
                        {!staffInfo.is_paid ? (
                            <Button
                                isLoading={isLoading}
                                disabled={isLoading}
                                className="fl-btn btn_outline_primary border border-primary rounded-fulltext-primary font-medium mt-5"
                                onClick={handlePaid}
                            >
                                Mark as paid
                            </Button>
                        ) : (
                            <>
                                <Button className="fl-btn text-base bg-primary text-white -tracking-[0.16px] font-bold w-full rounded-full mt-5 cursor-default" type="button">
                                    Paid
                                </Button>
                                <div className="text-center mt-10">
                                    <div onClick={handleReceiptDownload} className="inline-block cursor-pointer">
                                        <i className="w-6 h-6 inline-block">
                                            <img src={Images.IconInvoice} alt="" className="w-full" />
                                        </i>
                                        <div className="mt-2">
                                            {' '}
                                            <div className="text-primary font-medium leading-[140%] -tracking-[0.14px] cursor-pointer">Receipt</div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>

                    <div className="px-4 py-8 border-t border-lineColor bg-[#FBFBFB]">
                        <p className="font-medium leading-[140%] -tracking-[0.14px] mb-3">Total received during this period</p>
                        <div className="text-4xl font-bold leading-tight -tracking-[0.432px] ">${staffInfo.amount}</div>
                    </div>
                </div>
            </Drawer>
            <StaffDetails handleDrawer={(e: any) => setIsOpenStaffDetail(false)} isDrawerOpen={isOpenStaffDetail} staffId={staffInfo.staff.id} />
        </>
    );
};

export default PayoutDetail;
