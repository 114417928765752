import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { API } from 'src/constants/api';
import { axiosDelete, axiosGet, axiosPatch, axiosPost } from 'src/utils/requestClient';
// import { ErrorType, IInitialState } from './Account.interface';
import { RootState } from 'src/redux/store';
import { errorCode } from 'src/constants/errorCode';
import { IInitialState } from './Admin.interface';

const initialState: IInitialState = {
    data: null,
    loading: false,
    error: null,
};

export interface ErrorType {
    message: string;
    status?: string;
    data?: any;
}

export const adminList = createAsyncThunk('/shop/admin', async (payload: { shop_id: number }, { rejectWithValue }) => {
    try {
        const response = await axiosGet(API.ADMINS.LIST, payload);
        if (response.data.status === errorCode.success || response.data.status === errorCode.updateSuccess) {
            return response.data;
        }

        return rejectWithValue(response as ErrorType);
    } catch (err: any) {
        if (!err.response) {
            throw err;
        }
        return rejectWithValue(err.response.data as ErrorType);
    }
});

export const createAdmin = createAsyncThunk('/shop/location', async (payload: any, { rejectWithValue }) => {
    try {
        const response = await axiosPost(API.ADMINS.CREATE, payload.payload, { shop_id: payload.shop_id });
        if (response.data.status === errorCode.success || response.data.status === errorCode.updateSuccess) {
            return response.data;
        }

        return rejectWithValue(response as ErrorType);
    } catch (err: any) {
        if (!err.response) {
            throw err;
        }
        return rejectWithValue(err.response.data as ErrorType);
    }
});

export const getAdminDetails = createAsyncThunk('/shop/admin/details', async (payload: any, { rejectWithValue }) => {
    try {
        const response = await axiosGet(API.ADMINS.GET, {
            shop_id: payload.shop_id,
            id: payload.id,
        });
        if (response.data.status === errorCode.success || response.data.status === errorCode.updateSuccess) {
            return response.data;
        }

        return rejectWithValue(response as ErrorType);
    } catch (err: any) {
        if (!err.response) {
            throw err;
        }
        return rejectWithValue(err.response.data as ErrorType);
    }
});

export const updateAdmin = createAsyncThunk('/shop/admin/update', async (payload: any, { rejectWithValue }) => {
    try {
        const response = await axiosPatch(API.ADMINS.UPDATE, payload.payload, payload.param);

        if (response.data.status === errorCode.success || response.data.status === errorCode.updateSuccess) {
            return response.data;
        }

        return rejectWithValue(response as ErrorType);
    } catch (err: any) {
        if (!err.response) {
            throw err;
        }
        return rejectWithValue(err.response.data as ErrorType);
    }
});

export const deleteAdmin = createAsyncThunk('/shop/admin/delete', async (payload: any, { rejectWithValue }) => {
    try {
        const response = await axiosDelete(API.ADMINS.DELETE, {}, payload);
        if (response.data.status === errorCode.success || response.data.status === errorCode.updateSuccess) {
            return response.data;
        }

        return rejectWithValue(response as ErrorType);
    } catch (err: any) {
        if (!err.response) {
            throw err;
        }
        return rejectWithValue(err.response.data as ErrorType);
    }
});

export const adminSlice = createSlice({
    name: 'shop/admin/details',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(adminList.pending, (state) => {
                state.loading = true;
            })
            .addCase(adminList.fulfilled, (state, { payload }: any) => {
                state.loading = false;
            })
            .addCase(adminList.rejected, (state, action: any) => {
                state.loading = false;
            })
            .addCase(createAdmin.pending, (state) => {
                state.loading = true;
            })
            .addCase(createAdmin.fulfilled, (state, { payload }: any) => {
                state.loading = false;
                state.data = payload.data;
            })
            .addCase(createAdmin.rejected, (state, { payload }: any) => {
                state.loading = false;
                state.data = payload.data;
            })
            .addCase(updateAdmin.pending, (state) => {
                state.loading = true;
            })
            .addCase(updateAdmin.fulfilled, (state, { payload }: any) => {
                state.loading = false;
                state.data = payload.data;
            })
            .addCase(updateAdmin.rejected, (state, { payload }: any) => {
                state.loading = false;
                state.data = payload.data;
            })
            .addCase(getAdminDetails.pending, (state) => {
                state.loading = true;
            })
            .addCase(getAdminDetails.fulfilled, (state, { payload }: any) => {
                state.loading = false;
            })
            .addCase(getAdminDetails.rejected, (state, action: any) => {
                state.loading = false;
            })
            .addCase(deleteAdmin.pending, (state) => {
                state.loading = true;
            })
            .addCase(deleteAdmin.fulfilled, (state, { payload }: any) => {
                state.loading = false;
            })
            .addCase(deleteAdmin.rejected, (state, action: any) => {
                state.loading = false;
            });
    },
});

export const { reducer: accountReducer } = adminSlice;
export const selectAdmniData = (state: RootState) => state.Admin.data;
export const selectLoading = (state: RootState) => state.Admin.loading;
export const selectError = (state: RootState) => state.Admin.error;
