import React from 'react';
import Admin from 'src/app/Admin/Admin';
import Appearance from 'src/app/Appearance/Appearance';
import Login from 'src/app/Auth/Login/Login';
import Password from 'src/app/Auth/Password/Password';
import NewPassword from 'src/app/Auth/Password/NewPassword/NewPassword';
import Calendar from 'src/app/Calendar/Calendar';
import Client from 'src/app/Client/Client';
import Dashboard from 'src/app/Dashboard';
import Location from 'src/app/Location';
import BookingPayment from 'src/app/Payment/MyAccount/BookingPayment';
import Payment from 'src/app/Payment/Payment';
import Services from 'src/app/Services/Services';
import Setting from 'src/app/Setting';
import AppLogo from 'src/app/Signup/AppLogo';
import Branding from 'src/app/Signup/Branding';
import CompleteAccount from 'src/app/Signup/CompleteAccount';
import Contract from 'src/app/Signup/Contract';
import FlairPaymentReturn from 'src/app/Signup/FlairPayment/FlairPaymentReturn';
import Plan from 'src/app/Signup/Plan';
import Signup from 'src/app/Signup/Signup';
import StripeAccount from 'src/app/Signup/StripeAccount';
import Staff from 'src/app/Staff';
import Test from 'src/app/Test/Test';
import { ERouteType, LayoutAction, RouteItem } from 'src/constants/common';
import { ROUTES } from 'src/constants/routes';
import Analytics from 'src/app/Analytics/Analytics';

export const Screens: RouteItem[] = [
    {
        type: ERouteType.NOT_GUARDED,
        path: ROUTES.HOME,
        component: Login,
        title: 'Login',
        layout: LayoutAction.NonLayout,
    },
    {
        type: ERouteType.GUARDED,
        view: 'views.newPassword',
        permission: 'newPassword.read',
        path: ROUTES.NEW_PASSWORD,
        component: NewPassword,
        title: 'New Password',
        layout: LayoutAction.NonLayout,
    },
    {
        type: ERouteType.GUARDED,
        view: 'views.accountStepPlan',
        permission: 'accountStepPlan.read',
        path: ROUTES.ACCOUNT_STEP.PLAN,
        component: Plan,
        title: 'Plan',
        layout: LayoutAction.Simple,
    },
    {
        type: ERouteType.GUARDED,
        view: 'views.accountStepAppLogo',
        permission: 'accountStepAppLogo.read',
        path: ROUTES.ACCOUNT_STEP.APP_LOGO,
        component: AppLogo,
        title: 'AppLogo',
        layout: LayoutAction.Simple,
    },
    {
        type: ERouteType.GUARDED,
        view: 'views.accountStepBookingPayment',
        permission: 'accountStepBookingPayment.read',
        path: ROUTES.ACCOUNT_STEP.BOOKING_PAYMENT,
        component: BookingPayment,
        title: 'BookingPayment',
        layout: LayoutAction.Simple,
    },
    {
        type: ERouteType.GUARDED,
        view: 'views.accountStepBranding',
        permission: 'accountStepBranding.read',
        path: ROUTES.ACCOUNT_STEP.BRANDING,
        component: Branding,
        title: 'Branding',
        layout: LayoutAction.Simple,
    },
    {
        type: ERouteType.GUARDED,
        view: 'views.accountStepCompleteAccount',
        permission: 'accountStepCompleteAccount.read',
        path: ROUTES.ACCOUNT_STEP.COMPLETE_ACCOUNT,
        component: CompleteAccount,
        title: 'CompleteAccount',
        layout: LayoutAction.Simple,
    },
    {
        type: ERouteType.GUARDED,
        view: 'views.accountStepContract',
        permission: 'accountStepContract.read',
        path: ROUTES.ACCOUNT_STEP.CONTRACT,
        component: Contract,
        title: 'Contract',
        layout: LayoutAction.Simple,
    },
    {
        type: ERouteType.GUARDED,
        view: 'views.accountStepLocation',
        permission: 'accountStepLocation.read',
        path: ROUTES.ACCOUNT_STEP.LOCATION,
        component: Location,
        title: 'Location',
        layout: LayoutAction.Simple,
    },
    {
        type: ERouteType.GUARDED,
        view: 'views.accountStepStripeAccount',
        permission: 'accountStepStripeAccount.read',
        path: ROUTES.ACCOUNT_STEP.STRIPE_ACCOUNT,
        component: StripeAccount,
        title: 'StripeAccount',
        layout: LayoutAction.Simple,
    },
    {
        type: ERouteType.GUARDED,
        view: 'views.signup',
        permission: 'signup.read',
        path: ROUTES.SIGNUP,
        component: Signup,
        title: 'Sign Up',
        layout: LayoutAction.Simple,
    },
    {
        type: ERouteType.GUARDED,
        view: 'views.accountStepFlairPaymentReturn',
        permission: 'accountStepFlairPaymentReturn.read',
        path: ROUTES.ACCOUNT_STEP.STRIPE_CONNECT_RETURN,
        component: FlairPaymentReturn,
        title: 'Stripe Connect',
        layout: LayoutAction.Simple,
    },
    {
        type: ERouteType.NOT_GUARDED,
        path: ROUTES.FORGOT_PASSWORD,
        component: Password,
        title: 'Forgot Password',
        layout: LayoutAction.Simple,
    },
    {
        type: ERouteType.GUARDED,
        view: 'views.dashboard',
        permission: 'dashboard.read',
        path: ROUTES.DASHBOARD,
        component: Dashboard,
        title: 'Dashboard',
        layout: LayoutAction.Dashboard,
    },

    {
        type: ERouteType.GUARDED,
        view: 'views.admin',
        permission: 'admin.read',
        path: ROUTES.SETTINGS.ADMIN,
        component: Admin,
        title: 'Admin',
        layout: LayoutAction.Dashboard,
    },
    {
        type: ERouteType.GUARDED,
        view: 'views.client',
        permission: 'client.read',
        path: ROUTES.SETTINGS.CLIENT,
        component: Client,
        title: 'Client',
        layout: LayoutAction.Dashboard,
    },

    {
        type: ERouteType.GUARDED,
        view: 'views.payment',
        permission: 'payment.read',
        path: ROUTES.SETTINGS.PAYMENT,
        component: Payment,
        title: 'Payment',
        layout: LayoutAction.Dashboard,
    },

    {
        type: ERouteType.GUARDED,
        view: 'views.setting',
        permission: 'setting.read',
        path: ROUTES.SETTINGS.GENERAL_SETTING,
        component: Setting,
        title: 'Setting',
        layout: LayoutAction.Dashboard,
    },
    {
        type: ERouteType.GUARDED,
        view: 'views.test',
        permission: 'test.read',
        path: ROUTES.TEST,
        component: Test,
        title: 'Test',
        layout: LayoutAction.Dashboard,
    },

    // Problem component
    {
        type: ERouteType.GUARDED,
        view: 'views.location',
        permission: 'location.read',
        path: ROUTES.SETTINGS.LOCATION,
        component: Location,
        title: 'Location',
        layout: LayoutAction.Dashboard,
    },

    {
        type: ERouteType.GUARDED,
        view: 'views.staff',
        permission: 'staff.read',
        path: ROUTES.SETTINGS.STAFF,
        component: Staff,
        title: 'Staff',
        layout: LayoutAction.Dashboard,
    },
    {
        type: ERouteType.GUARDED,
        view: 'views.services',
        permission: 'services.read',
        path: ROUTES.SETTINGS.SERVICES,
        component: Services,
        title: 'Service',
        layout: LayoutAction.Dashboard,
    },
    {
        type: ERouteType.GUARDED,
        view: 'views.appearance',
        permission: 'appearance.read',
        path: ROUTES.CUSTOMIZATION.APPEARANCE,
        component: Appearance,
        title: 'Appearance',
        layout: LayoutAction.Dashboard,
    },
    {
        type: ERouteType.GUARDED,
        view: 'views.calendar',
        permission: 'calendar.read',
        path: ROUTES.CALENDAR,
        component: Calendar,
        title: 'Calendar',
        layout: LayoutAction.Dashboard,
    },
    {
        type: ERouteType.GUARDED,
        view: 'views.analytics',
        permission: 'analytics.read',
        path: ROUTES.ANALYTICS,
        component: Analytics,
        title: 'Analytics',
        layout: LayoutAction.Dashboard,
    },
];
