import React, { FC, Ref, forwardRef, useEffect, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { Link } from 'react-router-dom';
import format from 'date-fns/format';
import { Images } from 'src/theme';
import DropDownWithIcon from 'src/components/DropDownWithIcon';
import { MdOutlineMoreVert } from 'src/assets/customsvg';
import { actionBtnIcon } from './Sidebar.const';
import { IAppointmentOption } from './Sidebar.Interface';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { selectShopInfo } from 'src/app/Auth/Login/Login.slice';
import { initAction, selectCalendarData, setData, setGetBookingList, setOpenSidebar, updateAppointmentStatus } from '../Calendar.slice';
import { capitalizeFirstLetterAndUnderscore } from 'src/utils/global-functions';
import moment from 'moment-timezone';
import { axiosGet } from 'src/utils/requestClient';
import { API } from 'src/constants/api';
import { errorCode } from 'src/constants/errorCode';
import { toast } from 'react-toastify';

const AppointmentHeader: FC<any> = ({ sidebarState, setSidebarState, bookedSlotInfo }) => {
    const dispatch = useAppDispatch();
    const shop: any = useAppSelector(selectShopInfo);
    const shopId = shop.id;
    const [appointmentOptions, setAppointmentOptions] = useState<IAppointmentOption[]>([]);
    const calendarData: any = useAppSelector(selectCalendarData);
    const getButtonsForStatus = () => {
        if (bookedSlotInfo.status === 'confirmed') {
            return ['cancel_appointment', 'edit_appointment'];
        } else if (bookedSlotInfo.status === 'canceled' || bookedSlotInfo.status === 'declined' || bookedSlotInfo.status === 'completed') {
            return ['reschedule_appointment'];
        } else {
            return ['declined_appointment', 'edit_appointment'];
        }
    };
    const [timeZone, setTimeZone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone);

    useEffect(() => {
        if (calendarData.selectedLocation) {
            const filteredData = calendarData.locationInfoList.filter((item: any) => item.id === calendarData.selectedLocation.id);
            moment.tz.setDefault(filteredData[0].timezone);
            setTimeZone(filteredData[0].timezone);
        }
    }, [calendarData.selectedLocation]);

    const filteredOptions = () => actionBtnIcon.filter((btn) => getButtonsForStatus().includes(btn.value));
    useEffect(() => {
        if (bookedSlotInfo) {
            const options = filteredOptions();
            setAppointmentOptions(options);
        }
    }, [bookedSlotInfo]);

    const TimePickerInput = forwardRef((props: any, ref: Ref<HTMLInputElement>) => (
        <span className="text-primary font-medium" onClick={props.onClick}>
            {props.value}
        </span>
    ));
    const CustomDatepickerInput = forwardRef((props: any, ref: Ref<HTMLInputElement>) => (
        <div onClick={props.onClick} className="text-primary font-medium">
            {props.value ? format(new Date(props.value), 'eee, MMM d') : 'Select date'}
        </div>
    ));

    const handleTimeBlock = async () => {
        const updateState = {
            action: { ...initAction, blockTime: true },
        };
        await dispatch(setData(updateState));
    };
    const handleAppointmentEvent = async (e: any) => {
        if (e.value === 'edit_appointment') {
            setSidebarState((prevState: any) => ({
                ...prevState,
                isEditable: true,
            }));
            const updateState = {
                action: { ...initAction, updateAppointment: true },
            };
            await dispatch(setData(updateState));
        } else if (e.value === 'cancel_appointment') {
            const updateState = {
                action: { ...initAction, cancelAppointment: true },
            };
            await dispatch(setData(updateState));
        } else if (e.value === 'declined_appointment') {
            await updateBookingStatus(bookedSlotInfo.id, 'declined');
            // await dispatch(setGetBookingList(true));
        } else if (e.value === 'reschedule_appointment') {
            setSidebarState((prevState: any) => ({
                ...prevState,
                isEditable: true,
            }));
            const updateState = {
                action: { ...initAction, rescheduleAppointment: true },
            };
            await dispatch(setData(updateState));
        }
    };
    const getBookingInfo = async (id: number) => {
        let payload = {
            shop_id: shopId,
            id: id,
        };

        try {
            const response = await axiosGet(API.BOOKING.GET, payload, {});
            if (response.data?.status === errorCode.success || response.data?.status === errorCode.updateSuccess) {
                console.log('response', response.data.data.user.card);
                return response.data.data;
                // setDefaultCard(response.data.data.user.card);
                return;
            }

            throw response.data;
        } catch (err: any) {
            toast.error(err?.message);
            return null;
        } finally {
        }
    };
    const updateBookingStatus = async (id: number, status: string) => {
        const payload = { status: status };
        const data = {
            shop_id: shopId,
            id: id,
            payload: payload,
        };
        const result = await dispatch(updateAppointmentStatus(data));
        if (result.type === updateAppointmentStatus.fulfilled.toString()) {
            const info = await getBookingInfo(result.payload.data.id);
            if (status === 'canceled' || status === 'declined') {
                const updateState = {
                    bookingInfo: info,
                    getBookingList: true,
                    action: { ...initAction, bookedAppointment: true },
                };
                await dispatch(setData(updateState));
            }
        }
    };
    const handleBackButton = async () => {
        const updateState = {
            action: { ...initAction, bookedAppointment: true },
        };
        await dispatch(setData(updateState));
    };
    const handleClose = async () => {
        dispatch(setData({ action: initAction, getBookingList: true }));
        dispatch(setOpenSidebar(false));
    };

    return (
        <>
            {bookedSlotInfo && !calendarData.action.updateAppointment && !calendarData.action.rescheduleAppointment ? (
                <div className="p-4 flex items-center">
                    {(calendarData.action.cancelAppointment || calendarData.action.payemntAppointment) && (
                        <Link className="border border-lineColor rounded-lg p-1 mr-3" to="" onClick={handleBackButton}>
                            <img src={Images.IconChevronRight} alt="" className="" />
                        </Link>
                    )}

                    <h4 className="text-base font-bold leading-[140%] -tracking-[0.16px]">
                        {calendarData.action.cancelAppointment
                            ? 'Cancel / Delete appointment'
                            : `Appointment #${bookedSlotInfo.receipt_code} (${capitalizeFirstLetterAndUnderscore(bookedSlotInfo.status)})`}
                    </h4>

                    <DropDownWithIcon
                        dropwDownContainerClass="ml-auto"
                        className=""
                        labelIcon={<MdOutlineMoreVert stroke="#1642C5" currentColor="#1642C5" />}
                        labelActiveIcon={<MdOutlineMoreVert stroke="#E3E7EF" currentColor="#1F293C" />}
                        dropDownMenuClassName="w-[324px] top-[100%] py-6"
                        dropDownMenuItemClassName="border-b text-left py-5 last:border-0"
                        dropDownMenuLinkClassName="mx-3"
                        data={appointmentOptions}
                        setselected={handleAppointmentEvent}
                    />
                </div>
            ) : (
                <div className="">
                    <div className="header flex justify-between px-4 py-6">
                        <div className="">
                            <h1 className="text-base font-bold leading-[22.4px] -tracking-[0.16px] text-mainTextColor mb-1">
                                {bookedSlotInfo && calendarData.action.updateAppointment
                                    ? 'Edit Appoitment'
                                    : bookedSlotInfo && calendarData.action.rescheduleAppointment
                                    ? 'RescheduleAppintment'
                                    : 'New Appointment'}
                            </h1>
                            {!calendarData.action.updateAppointment && !calendarData.action.rescheduleAppointment && (
                                <div className="text-xs font-normal leading-[18px] -tracking-[0.12px] text-mainTextColor flex items-center ">
                                    <div className="text-xs font-normal leading-[18px] -tracking-[0.12px] text-mainTextColor flex items-center ">
                                        <span className="mr-1">or</span>
                                        <div onClick={handleTimeBlock} className="text-primary cursor-pointer font-medium">
                                            CREATE TIME BLOCK
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div>
                            <button className="rounded-[40px] border border-solid border-mainTextColor py-[10px] pr-8 pl-[33px] w-[110px] " type="button" onClick={() => handleClose()}>
                                Cancel
                            </button>
                        </div>
                    </div>
                    <div className="date and time flex flex-auto border-y border-lineColor justify-evenly items-center">
                        <div className="w-[50%] py-5 px-4 border-r-[1px] border-solid border-lineColor ">
                            <div className="leading-[19.6px] -tracking-[0.14px] text-secondaryTxtColor">
                                On{' '}
                                <ReactDatePicker
                                    customInput={<CustomDatepickerInput />}
                                    selected={moment(sidebarState.selectedDate, timeZone).toDate()}
                                    onChange={(date) => {
                                        setSidebarState((prevState: any) => ({
                                            ...prevState,
                                            selectedDate: moment(date, timeZone).toDate() as Date,
                                        }));
                                    }}
                                />
                            </div>
                        </div>
                        <div className="w-[50%] py-5 px-4">
                            <div className="leading-[19.6px] -tracking-[0.14px] text-secondaryTxtColor">
                                At{' '}
                                <ReactDatePicker
                                    customInput={<TimePickerInput />}
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={15}
                                    timeCaption="Time"
                                    dateFormat="h:mm aa"
                                    selected={moment(sidebarState.selectedTime).toDate()}
                                    onChange={(date) => {
                                        setSidebarState((prevState: any) => ({
                                            ...prevState,
                                            selectedTime: moment(date, timeZone).toDate() as Date,
                                        }));
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default AppointmentHeader;
