import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { API } from 'src/constants/api';
import { axiosDelete, axiosGet, axiosPatch, axiosPost } from 'src/utils/requestClient';
// import { ErrorType, IInitialState } from './Account.interface';
import { RootState } from 'src/redux/store';
import { errorCode } from 'src/constants/errorCode';
import { IInitialState, ErrorType } from './Staff.interface';

const initialState: IInitialState = {
    data: null,
    loading: false,
    error: null,
};

export const staffList = createAsyncThunk('/shop/staff', async (payload: any, { rejectWithValue }) => {
    try {
        const response = await axiosGet(API.STAFF.LIST, { shop_id: payload.shop_id }, { location_id: payload.location_id });
        if (response.data.status === errorCode.success || response.data.status === errorCode.updateSuccess) {
            return response.data;
        }

        return rejectWithValue(response as ErrorType);
    } catch (err: any) {
        if (!err.response) {
            throw err;
        }
        return rejectWithValue(err.response.data as ErrorType);
    }
});
export const roleList = createAsyncThunk('/shop/staff/role', async (payload: { shop_id: number }, { rejectWithValue }) => {
    try {
        const response = await axiosGet(API.STAFF_ROLE.LIST, payload);
        if (response.data.status === errorCode.success || response.data.status === errorCode.updateSuccess) {
            return response.data;
        }

        return rejectWithValue(response as ErrorType);
    } catch (err: any) {
        if (!err.response) {
            throw err;
        }
        return rejectWithValue(err.response.data as ErrorType);
    }
});

export const createRole = createAsyncThunk('/shop/staff/role/create', async (payload: any, { rejectWithValue }) => {
    try {
        const response = await axiosPost(API.STAFF_ROLE.CREATE, payload.payload, { shop_id: payload.shop_id });
        if (response.data.status === errorCode.success || response.data.status === errorCode.updateSuccess) {
            return response.data;
        }

        return rejectWithValue(response as ErrorType);
    } catch (err: any) {
        if (!err.response) {
            throw err;
        }
        return rejectWithValue(err.response.data as ErrorType);
    }
});
export const removeRole = createAsyncThunk('/shop/staff/role/delete', async (payload: any, { rejectWithValue }) => {
    try {
        const response: any = await axiosDelete(API.STAFF_ROLE.DELETE, undefined, { shop_id: payload?.shop_id, id: payload?.id });
        if (response.data.status === errorCode.success) {
            return response.data;
        }

        return rejectWithValue(response as ErrorType);
    } catch (err: any) {
        if (!err.response) {
            throw err;
        }
        return rejectWithValue(err.response.data as ErrorType);
    }
});
export const locationList = createAsyncThunk('/shop/location/list', async (payload: { shop_id: number }, { rejectWithValue }) => {
    try {
        const response = await axiosGet(API.LOCATION.LIST, payload);
        if (response.data.status === errorCode.success || response.data.status === errorCode.updateSuccess) {
            return response.data;
        }

        return rejectWithValue(response as ErrorType);
    } catch (err: any) {
        if (!err.response) {
            throw err;
        }
        return rejectWithValue(err.response.data as ErrorType);
    }
});
export const createStaff = createAsyncThunk('/shop/location', async (payload: any, { rejectWithValue }) => {
    try {
        const response = await axiosPost(API.STAFF.CREATE, payload.payload, { shop_id: payload.shop_id });
        if (response.data.status === errorCode.success || response.data.status === errorCode.updateSuccess) {
            return response.data;
        }

        return rejectWithValue(response as ErrorType);
    } catch (err: any) {
        if (!err.response) {
            throw err;
        }
        return rejectWithValue(err.response.data as ErrorType);
    }
});

export const updateStaff = createAsyncThunk('/shop/staf/update', async (payload: any, { rejectWithValue }) => {
    try {
        const response = await axiosPatch(API.STAFF.UPDATE, payload.payload, payload.param);

        if (response.data.status === errorCode.success || response.data.status === errorCode.updateSuccess) {
            return response.data;
        }

        return rejectWithValue(response as ErrorType);
    } catch (err: any) {
        if (!err.response) {
            throw err;
        }
        return rejectWithValue(err.response.data as ErrorType);
    }
});

export const deleteStaff = createAsyncThunk('/shop/staff/delete', async (payload: any, { rejectWithValue }) => {
    try {
        const response = await axiosDelete(API.STAFF.DELETE, {}, payload);

        if (response.data.status === errorCode.success || response.data.status === errorCode.updateSuccess) {
            return response.data;
        }

        return rejectWithValue(response as ErrorType);
    } catch (err: any) {
        if (!err.response) {
            throw err;
        }
        return rejectWithValue(err.response.data as ErrorType);
    }
});

export const getStaffDetails = createAsyncThunk('/shop/staff/details', async (payload: any, { rejectWithValue }) => {
    try {
        const response = await axiosGet(API.STAFF.GET, {
            shop_id: payload.shop_id,
            id: payload.id,
        });
        if (response.data.status === errorCode.success || response.data.status === errorCode.updateSuccess) {
            return response.data;
        }

        return rejectWithValue(response as ErrorType);
    } catch (err: any) {
        if (!err.response) {
            throw err;
        }
        return rejectWithValue(err.response.data as ErrorType);
    }
});

export const appointmentList = createAsyncThunk('/shop/staff/appintment', async (payload: any, { rejectWithValue }) => {
    try {
        const response = await axiosGet(API.STAFF.APPOINTMENT, payload.param, payload.data);
        if (response.data.status === errorCode.success || response.data.status === errorCode.updateSuccess) {
            return response.data;
        }

        return rejectWithValue(response as ErrorType);
    } catch (err: any) {
        if (!err.response) {
            throw err;
        }
        return rejectWithValue(err.response.data as ErrorType);
    }
});
export const bookingDetails = createAsyncThunk('/shop/staff/booking/details', async (payload: any, { rejectWithValue }) => {
    try {
        const response = await axiosGet(API.STAFF.BOOKING, payload);
        if (response.data.status === errorCode.success || response.data.status === errorCode.updateSuccess) {
            return response.data;
        }

        return rejectWithValue(response as ErrorType);
    } catch (err: any) {
        if (!err.response) {
            throw err;
        }
        return rejectWithValue(err.response.data as ErrorType);
    }
});

export const staffSlice = createSlice({
    name: 'shop/staff/details',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(staffList.pending, (state) => {
                state.loading = true;
            })
            .addCase(staffList.fulfilled, (state, { payload }: any) => {
                state.loading = false;
            })
            .addCase(updateStaff.pending, (state) => {
                state.loading = true;
            })
            .addCase(updateStaff.fulfilled, (state, { payload }: any) => {
                state.loading = false;
                state.data = payload.data;
            })
            .addCase(updateStaff.rejected, (state, { payload }: any) => {
                state.loading = false;
                state.data = payload.data;
            })
            .addCase(staffList.rejected, (state, action: any) => {
                state.loading = false;
            })
            .addCase(locationList.pending, (state) => {
                state.loading = true;
            })
            .addCase(locationList.fulfilled, (state, { payload }: any) => {
                state.loading = false;
            })
            .addCase(locationList.rejected, (state, action: any) => {
                state.loading = false;
            })
            .addCase(deleteStaff.pending, (state) => {
                state.loading = true;
            })
            .addCase(deleteStaff.fulfilled, (state, { payload }: any) => {
                state.loading = false;
            })
            .addCase(deleteStaff.rejected, (state, action: any) => {
                state.loading = false;
            })
            .addCase(roleList.pending, (state) => {
                state.loading = true;
            })
            .addCase(roleList.fulfilled, (state, { payload }: any) => {
                state.loading = false;
            })
            .addCase(roleList.rejected, (state, action: any) => {
                state.loading = false;
            })
            .addCase(bookingDetails.pending, (state) => {
                state.loading = true;
            })
            .addCase(bookingDetails.fulfilled, (state, { payload }: any) => {
                state.loading = false;
            })
            .addCase(bookingDetails.rejected, (state, action: any) => {
                state.loading = false;
            });
    },
});

export const { reducer: accountReducer } = staffSlice;

export const selectStaffData = (state: RootState) => state.Staff.data;
export const selectLoading = (state: RootState) => state.Staff.loading;
export const selectError = (state: RootState) => state.Staff.error;
