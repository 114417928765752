import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { API } from 'src/constants/api';
import { axiosPost } from 'src/utils/requestClient';
import { IInitialState } from '../AccountSettings/ChangePassword.interface';
import { errorCode } from 'src/constants/errorCode';
import { RootState } from 'src/redux/store';

interface ErrorType {
    message: string;
    status?: string;
    data?: any;
}

const initialState: IInitialState = {
    loading: false,
    data: null,
    error: null,
};

export const company = createAsyncThunk('shop/company', async (payload: any, { rejectWithValue }) => {
    try {
        const response = await axiosPost(API.USER.COMPANY, payload);
        if (response.data.status === errorCode.updateSuccess) {
            return response.data;
        }

        return rejectWithValue(response as ErrorType);
    } catch (err: any) {
        if (!err.response) {
            throw err;
        }

        return rejectWithValue(err.response.data as ErrorType);
    }
});

export const CompanySlice = createSlice({
    name: 'company',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(company.pending, (state) => {
                state.loading = true;
            })
            .addCase(company.fulfilled, (state, { payload }: any) => {
                state.loading = false;
                state.data = payload;
            })
            .addCase(company.rejected, (state, { payload, error }: any) => {
                state.loading = false;
                state.data = payload;
                if (payload) {
                    state.error = payload;
                } else {
                    state.error = error as ErrorType;
                }
            });
    },
});

export const selectLoading = (state: RootState) => state.Profile.loading;
export const selectError = (state: RootState) => state.Profile.error;
