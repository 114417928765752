import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { API } from 'src/constants/api';
import { axiosGet } from 'src/utils/requestClient';
import { ErrorType, IInitialState } from './Country.interface';
import { errorCode } from 'src/constants/errorCode';

const initialState: IInitialState = {
    data: null,
    loading: false, // Add the loading property here
    error: null,
};

export const getCountry = createAsyncThunk('/shop/country', async (payload, { rejectWithValue }) => {
    try {
        const response = await axiosGet(API.LOCATION.COUNTRY);
        if (response.data.status === errorCode.success || response.data.status === errorCode.updateSuccess) {
            return response.data;
        }

        return rejectWithValue(response as ErrorType);
    } catch (err: any) {
        if (!err.response) {
            throw err;
        }
        return rejectWithValue(err.response.data as ErrorType);
    }
});

export const CountrySlice = createSlice({
    name: 'shop/country',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getCountry.pending, (state) => {
                state.loading = true;
            })
            .addCase(getCountry.fulfilled, (state, { payload }: any) => {
                state.loading = false;
                state.data = {
                    ...state.data,
                    country: payload.data,
                };
            })
            .addCase(getCountry.rejected, (state, action: any) => {
                state.loading = false;
            });
    },
});
export const { reducer: accountReducer } = CountrySlice;
