import React, { FC, useEffect, useRef, useState } from 'react';
import { ICalulationDetails, ISidebar, ISidebarSchema, ISidebarState } from './Sidebar.Interface';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { selectShopInfo, shopDetail } from 'src/app/Auth/Login/Login.slice';
import { getStaffDetails } from 'src/app/Staff/Staff.slice';
import Waitlist from './WaitList/WaitList';
import AppointmentDetails from './AppointmentDetails';
import { bookingCreate, bookingUpdate, initAction, selectCalendarData, setData, setGetBookingList, updateAppointmentStatus } from '../Calendar.slice';
import { errorCode } from 'src/constants/errorCode';
import { toast } from 'react-toastify';
import PaymentData from './PaymentData';
import BarLoader from 'src/components/BarLoader';
import SlotNotAvailable from './SlotNotAvailable';
import BlockTime from './BlockTime';
import moment from 'moment-timezone';
import { format } from 'date-fns';
import { LuArrowLeftToLine } from 'react-icons/lu';
import Button from 'src/components/Button';
import { axiosGet } from 'src/utils/requestClient';
import { API } from 'src/constants/api';

const Sidebar: FC<ISidebar> = ({ slotInfo, isLoading, setIsLoading, handleClose }) => {
    const dispatch = useAppDispatch();
    const shop: any = useAppSelector(selectShopInfo);

    const calendarData: any = useAppSelector(selectCalendarData);
    // const bookedSlotInfo = calendarData.bookingInfo;
    const [timeZone, setTimeZone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone);
    const [isBtnDisable, setIsBtnDisable] = useState<boolean>(false);
    const sidebarRef = useRef<HTMLDivElement>(null);

    // const staffInfo = calendarData.staffInfo;
    const action = calendarData.action;
    const shopId = shop.id;
    const initialSidebarState = {
        disabledServices: [],
        serviceArrayList: [],
        selectedDate: moment(calendarData.selectedDate).toDate(),
        selectedTime: moment(calendarData.selectedDate).toDate(),
        selectedOption: null,
        serviceOptionShow: true,
    };
    console.log('shop', shop);

    // useEffect(() => {
    //     const handleClickOutside = (event: { target: any }) => {
    //         if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
    //             handleClose(); // Call handleClose if click is outside the sidebar
    //         }
    //     };

    //     // Add click event listener
    //     document.addEventListener('mousedown', handleClickOutside);
    //     return () => {
    //         // Remove event listener on cleanup
    //         document.removeEventListener('mousedown', handleClickOutside);
    //     };
    // }, [handleClose]);
    const [sidebarState, setSidebarState] = useState<ISidebarState>(initialSidebarState);

    // const [clientArrayList, setClientArrayList] = useState([]);
    const [appointmentCalulation, setAppointmentCalulation] = useState<ICalulationDetails | null>(null);
    const [cancelChargeError, setCancelChargeError] = useState('');
    const schema = Yup.object().shape({
        staff_id: Yup.number().required('Staff is required'),
        user_id: Yup.number().required('Client is required'),
        payment_method: Yup.string().required('Payment method is required'),
        services: Yup.array()
            .of(
                Yup.object().shape({
                    id: Yup.number().required('Service is required'),
                    qty: Yup.number().required('Quantity is required'),
                }),
            )
            .min(1, 'At least one service must be selected'),
        booking_date: Yup.date().required('Booking date is required'),
        booking_time: Yup.string().required('Booking time is required'),
    });

    const methods = useForm<ISidebarSchema>({
        resolver: yupResolver(schema) as any,
        defaultValues: {
            services: null,
            payment_method: 'pay_online',
        },
    });

    useEffect(() => {
        if (calendarData.isCharge) {
            if (calendarData.chargeableAmount.charge !== null && calendarData.chargeableAmount.charge <= 0) {
                setCancelChargeError('please enter valid amount');
            } else {
                setCancelChargeError('');
            }
        } else {
            setCancelChargeError('');
        }
    }, [calendarData.isCharge, calendarData.chargeableAmount.charge]);

    useEffect(() => {
        if (calendarData.selectedLocation) {
            const filteredData = calendarData.locationInfoList.filter((item: any) => item.id === calendarData.selectedLocation.id);
            moment.tz.setDefault(filteredData[0].timezone);
            setTimeZone(filteredData[0].timezone);
        }
    }, [calendarData.selectedLocation]);

    const {
        handleSubmit,
        setValue,
        control,
        formState: { errors },
    } = methods;
    // const onSubmit = async (data: any, update: boolean = false) => {
    //     console.log('data', data);
    // };
    const onSubmit = async (data: any, update: boolean = false) => {
        setIsBtnDisable(true);

        const services = data.services.map((item: any) => ({
            id: item.id,
            quantity: item.qty,
            is_custom_price: item.is_custom_price,
            price: item.price,
        }));
        const newData = {
            ...data, // Copy existing data
            services: services, // Replace services with the new array
            booking_date: format(data.booking_date, 'yyyy-MM-dd'), // Replace services with the new array
        };

        if (update) {
            const finalOutput = {
                shop_id: shopId,
                location_id: calendarData.selectedLocation.id,
                id: calendarData.bookingInfo.id,
                payload: newData,
            };
            const result = await dispatch(bookingUpdate(finalOutput));
            if (result.type === bookingUpdate.fulfilled.toString()) {
                setIsLoading(true);
                const info = await getBookingInfo(result.payload.data.id);

                const updateState = {
                    bookingInfo: info,
                    action: { ...initAction, bookedAppointment: true },
                    getBookingList: true,
                };
                dispatch(setData(updateState));
                dispatch(shopDetail());
                setIsBtnDisable(false);
            }

            if (result.type === bookingUpdate.rejected.toString()) {
                const response = result.payload.data;
                if (response.status === errorCode.unprocessable) {
                    if (response.data) {
                        Object.keys(response.data).forEach((field) => {
                            // setError(field as FieldKey, {
                            //     type: 'manual',
                            //     message: response.data[field][0],
                            // });
                        });
                    }
                    toast.error(response.message);
                    setIsBtnDisable(false);

                    return;
                }
            }
        } else {
            const finalOutput = {
                shop_id: shopId,
                location_id: calendarData.selectedLocation.id,
                payload: newData,
            };
            const result = await dispatch(bookingCreate(finalOutput));
            if (result.type === bookingCreate.fulfilled.toString()) {
                setIsLoading(true);
                const info = await getBookingInfo(result.payload.data.id);

                const updateState = {
                    // isSidebarOpen: false,
                    bookingInfo: info,
                    action: { ...initAction, bookedAppointment: true },
                    getBookingList: true,
                };
                dispatch(setData(updateState));
                dispatch(shopDetail());
                setIsBtnDisable(false);
            }
            if (result.type === bookingCreate.rejected.toString()) {
                const response = result.payload.data;
                if (response.status === errorCode.unprocessable) {
                    if (response.data) {
                        Object.keys(response.data).forEach((field) => {
                            // setError(field as FieldKey, {
                            //     type: 'manual',
                            //     message: response.data[field][0],
                            // });
                        });
                    }
                    setIsBtnDisable(false);

                    toast.error(response.message);
                    return;
                }
            }
        }
    };

    const { fields, remove } = useFieldArray({
        control,
        keyName: 'uuid',
        name: 'services',
    });

    const onHandleCloseSidebar = async () => {
        await remove();
        setSidebarState(initialSidebarState);
        setAppointmentCalulation(null);
        setIsBtnDisable(false);
        // await dispatch(setClearBooking());
    };

    const getStaffDetailFun = async () => {
        const data = {
            shop_id: shopId,
            id: calendarData.selectedSlotStaffId,
        };
        const result = await dispatch(getStaffDetails(data));
        if (result.type === getStaffDetails.fulfilled.toString()) {
            const updateState = {
                staffInfo: result.payload.data,
            };
            await dispatch(setData(updateState));
            const staffOptionData = result.payload.data.services.map((item: any) => ({
                ...item,
                value: item.id,
                label: item.name,
            }));
            await setSidebarState((prevState) => ({
                ...prevState,
                serviceArrayList: staffOptionData,
            }));
            setIsLoading(false);
            // await handleBookedSlot();
        }
    };

    const handleBookedSlot = async () => {
        if (calendarData.bookingInfo) {
            setSidebarState((prevState) => ({
                ...prevState,
                serviceOptionShow: true,
            }));

            const restructuredData = await calendarData.bookingInfo?.booking_services.map((item: any) => ({
                name: item.service.name,
                price: parseFloat(item.price),
                staff_name: `${calendarData.staffInfo.first_name} ${calendarData.staffInfo.last_name}`, // Assuming staff_service_id is the staff name or ID
                time_slot: moment(`${calendarData.bookingInfo.booking_date} ${calendarData.bookingInfo.booking_start_time}`).format('hh:mm A'),
                duration: item.duration,
                qty: item.quantity,
                is_custom_price: item.is_custom_price,
                id: item.service.id,
            }));
            const idArray = await restructuredData.map((item: { id: any }) => item.id);

            const newCalculationDetails: ICalulationDetails = {
                appointmentCalulation: {
                    subtotal: Number(calendarData.bookingInfo.subtotal),
                    taxes_and_fees: Number(calendarData.bookingInfo.taxes_and_fees),
                    total: Number(calendarData.bookingInfo.total),
                    tips: calendarData.bookingInfo.tips ? Number(calendarData.bookingInfo.tips) : 0,
                },
            };
            await setAppointmentCalulation(newCalculationDetails);
            await setValue('services', restructuredData);
            await setValue('user_id', calendarData.bookingInfo.user.id);

            const selectedDate = moment(
                moment(moment(`${calendarData.bookingInfo.booking_date}`).format('YYYY-MM-DD'))
                    .toDate()
                    .toLocaleString('en-US', { timeZone: timeZone }),
            ).toDate();
            const selectedTime = moment(
                moment(moment(`${calendarData.bookingInfo.booking_date} ${calendarData.bookingInfo.booking_start_time}`).format('YYYY-MM-DD HH:mm:ss'))
                    .toDate()
                    .toLocaleString('en-US', { timeZone: timeZone }),
            ).toDate();
            setSidebarState((prevState) => ({
                ...prevState,
                disabledServices: idArray,
                selectedDate: selectedDate,
                selectedTime: selectedTime,
                selectedOption: calendarData.bookingInfo.user,
            }));
            setIsLoading(false);
        }
    };

    const handleReschedule = async () => {
        if (calendarData.bookingInfo) {
            setSidebarState((prevState) => ({
                ...prevState,
                serviceOptionShow: true,
            }));
            const restructuredData = await calendarData.bookingInfo.booking_services.map((item: any) => {
                const serviceInfo: any = sidebarState.serviceArrayList.find((service: any) => service.id === item.service.id);

                return {
                    name: item.service.name,
                    price: parseFloat(serviceInfo.price.price),
                    staff_name: `${calendarData.staffInfo.first_name} ${calendarData.staffInfo.last_name}`, // Assuming staff_service_id is the staff name or ID
                    time_slot: moment(new Date(), timeZone).format('hh:mm A'),
                    is_custom_price: serviceInfo.is_custom_price,
                    duration: serviceInfo.price.duration,
                    qty: item.quantity,
                    id: item.service.id,
                };
            });
            const idArray = await restructuredData.map((item: { id: any }) => item.id);

            const newCalculationDetails: ICalulationDetails = {
                appointmentCalulation: {
                    subtotal: Number(calendarData.bookingInfo.subtotal),
                    taxes_and_fees: Number(calendarData.bookingInfo.taxes_and_fees),
                    total: Number(calendarData.bookingInfo.total),
                    tips: calendarData.bookingInfo.tips ? Number(calendarData.bookingInfo.tips) : 0,
                },
            };
            await setAppointmentCalulation(newCalculationDetails);
            await setValue('services', restructuredData);
            await setValue('user_id', calendarData.bookingInfo.user.id);
            const currentDateInTimeZone = moment.tz(moment(), timeZone);

            setSidebarState((prevState) => ({
                ...prevState,
                disabledServices: idArray,
                selectedDate: moment(currentDateInTimeZone).toDate(),
                selectedTime: moment(currentDateInTimeZone).toDate(),
                selectedOption: calendarData.bookingInfo.user,
            }));
            setIsLoading(false);
        }
    };

    const handleServiceCalulation = async () => {
        if (fields.length > 0) {
            const subtotal: number = fields.reduce((acc, item) => {
                const qty = item.qty ?? 0;
                const price: number = item.price || 0;
                return acc + qty * price;
            }, 0);
            const filteredLocationData = calendarData.locationInfoList.filter((item: any) => item.id === calendarData.selectedLocation.id)[0];
            const totalTax = parseFloat(filteredLocationData.sales_tax.gst) + parseFloat(filteredLocationData.sales_tax.pst) + parseFloat(filteredLocationData.sales_tax.hst);
            const taxesAndFees = (subtotal * totalTax) / 100;

            const tips = calendarData.bookingInfo && calendarData.bookingInfo.tips ? Number(calendarData.bookingInfo.tips) : 0;
            const total = subtotal + taxesAndFees + tips;
            setAppointmentCalulation({
                appointmentCalulation: {
                    subtotal,
                    taxes_and_fees: taxesAndFees,
                    total,
                    tips,
                },
            });
        } else {
            setAppointmentCalulation(null);
        }
    };

    const handleSlotDateTime = async () => {
        if (timeZone && calendarData.selectedDate) {
            setSidebarState((prevState) => ({
                ...prevState,
                // isEditable: true,
                selectedDate: moment(calendarData.selectedDate, timeZone).toDate(),
                selectedTime: moment(calendarData.selectedDate, timeZone).toDate(),
            }));

            // const updateState = {
            //     action: { ...initAction, ['updateAppointment']: true },
            // };
            // await dispatch(setData(updateState));
        }
    };
    const getBookingInfo = async (id: number) => {
        let payload = {
            shop_id: shopId,
            id: id,
        };

        try {
            const response = await axiosGet(API.BOOKING.GET, payload, {});
            if (response.data?.status === errorCode.success || response.data?.status === errorCode.updateSuccess) {
                return response.data.data;
                // setDefaultCard(response.data.data.user.card);
                return;
            }

            throw response.data;
        } catch (err: any) {
            toast.error(err?.message);
            return null;
        } finally {
        }
    };
    useEffect(() => {
        const fetchData = async () => {
            await setIsLoading(true);

            // await onHandleCloseSidebar();
            if (calendarData.action.newAppointment) {
                await onHandleCloseSidebar();
                await handleSlotDateTime();
            }

            if (calendarData.action.bookedAppointment || calendarData.action.updateAppointment || calendarData.action.cancelAppointment) {
                await handleBookedSlot();
            }
            await getStaffDetailFun();
        };
        if (calendarData.action.completedPaymentAppointment) {
            setIsLoading(true);
            handleSubmitForm();
        } else if (
            calendarData.isSidebarOpen &&
            calendarData.selectedSlotStaffId &&
            (calendarData.action.newAppointment || calendarData.action.bookedAppointment || calendarData.action.updateAppointment || calendarData.action.cancelAppointment)
        ) {
            fetchData();
        } else if (calendarData.action.blockTime) {
            setIsLoading(false);
        } else if (calendarData.action.waitList) {
            setIsLoading(false);
        } else {
            if (!calendarData.action.paymentAppointment && !calendarData.action.rescheduleAppointment) {
                onHandleCloseSidebar();
            } else if (calendarData.action.rescheduleAppointment) {
                getStaffDetailFun();
                handleReschedule();
            }
        }
    }, [calendarData.action, calendarData.selectedSlotStaffId]);

    useEffect(() => {
        onHandleCloseSidebar();
        handleClose();
    }, [calendarData.selectedLocation]);
    useEffect(() => {
        setValue('payment_method', 'pay_online');
    }, []);

    useEffect(() => {
        const isValidDate = moment(sidebarState.selectedTime).isValid();
        if (isValidDate) {
            setValue('booking_date', format(sidebarState.selectedDate, 'yyyy-MM-dd'));
        }
    }, [sidebarState.selectedDate]);

    useEffect(() => {
        const isValidDate = moment(sidebarState.selectedTime).isValid();
        if (isValidDate) {
            setValue('booking_time', format(sidebarState.selectedTime, 'HH:mm:ss'));
        }
    }, [sidebarState.selectedTime]);

    useEffect(() => {
        if (calendarData.staffInfo) {
            if (calendarData.isSidebarOpen && calendarData.selectedSlotStaffId && (calendarData.action.bookedAppointment || calendarData.action.updateAppointment)) {
                handleBookedSlot();
            }
            setValue('staff_id', calendarData.staffInfo.id);
        }
    }, [calendarData.staffInfo]);

    useEffect(() => {
        handleServiceCalulation();
    }, [fields]);

    const updateBookingStatus = async (id: number, status: string, paymentMethod: any = null) => {
        let payload;

        if (status === 'canceled') {
            if (cancelChargeError || (calendarData.isCharge && Number(calendarData.chargeableAmount.charge) <= 0)) {
                setCancelChargeError('please enter valid amount ');
                return;
            }
            if (calendarData.isCharge) {
                payload = {
                    charge: Number(calendarData.chargeableAmount.charge),
                    charge_type: calendarData.chargeableAmount.charge_type === '%' ? 'percentage' : 'value',
                    status,
                };
            } else {
                payload = { status };
            }
        } else if (status === 'completed') {
            // const paymentInfo = calendarData.payments.payments.map((payment: { type: string }) => {
            //     if (payment.type === 'card') {
            //         return { ...payment, type: 'stripe' };
            //     } else {
            //         return payment;
            //     }
            // });

            payload = {
                payment_method: calendarData.payments.payments,
                status,
            };

            if (calendarData.payments.tip) {
                payload = {
                    ...payload,
                    tip: calendarData.payments.tip,
                };
            }
            if (calendarData.payments.extra_payment_method) {
                payload = {
                    ...payload,
                    extra_payment_method: calendarData.payments.extra_payment_method,
                };
            }
            // 'stripe'
        } else {
            payload = { status };
        }
        const data = {
            shop_id: shopId,
            id: id,
            payload: payload,
        };
        const result = await dispatch(updateAppointmentStatus(data));
        if (result.type === updateAppointmentStatus.fulfilled.toString()) {
            setIsLoading(true);
            const info = await getBookingInfo(result.payload.data.id);

            const updateState = {
                // isSidebarOpen: false,
                bookingInfo: info,
                action: { ...initAction, bookedAppointment: true },
                getBookingList: true,
            };
            dispatch(setData(updateState));
            dispatch(shopDetail());
            setIsBtnDisable(false);
        }
        if (result.type === updateAppointmentStatus.rejected.toString()) {
            const response = result.payload.data;

            if (response.status === errorCode.unprocessable) {
                toast.error(response.message);
            }
        }
    };
    // const sendReceipt = async () => {
    //     const data = {
    //         params: {
    //             shop_id: shopId,
    //             id: bookedSlotInfo.id,
    //         },
    //         payload: {
    //             is_send_mail: true,
    //         },
    //     };
    //     const result = await dispatch(getReceipt(data));
    //     if (result.type === getReceipt.fulfilled.toString()) {
    //     }
    //     if (result.type === getReceipt.rejected.toString()) {
    //         const response = result.payload.data;
    //         console.log('result.payload.data', result.payload.data);
    //         if (response.status === errorCode.unprocessable) {
    //             toast.error(response.message);
    //         }
    //     }
    // };
    const handleSubmitForm = async () => {
        if (action.bookedAppointment) {
            if (calendarData.bookingInfo && calendarData.bookingInfo.status === 'pending') {
                await updateBookingStatus(calendarData.bookingInfo.id, 'confirmed');
            } else if (calendarData.bookingInfo.status === 'confirmed') {
                const updateState = {
                    action: { ...initAction, paymentAppointment: true },
                    // getBookingList: true,
                };
                await dispatch(setData(updateState));
            }
        } else if (action.updateAppointment) {
            await handleSubmit((e) => {
                onSubmit(e, true);
            })();
        } else if (action.cancelAppointment) {
            if (calendarData.bookingInfo.status === 'confirmed') {
                if (cancelChargeError || (calendarData.isCharge && Number(calendarData.chargeableAmount.charge) <= 0)) {
                    if (!cancelChargeError) {
                        setCancelChargeError('Please enter valid amount.');
                    }
                    return;
                } else {
                    const updateState = {
                        cancelAppointment: {
                            type: 'confirm',
                            status: true,
                            message: '',
                        },
                    };
                    await dispatch(setData(updateState));

                    return;
                }
            }
        } else if (action.completedPaymentAppointment) {
            if (calendarData.bookingInfo.status === 'confirmed') {
                await updateBookingStatus(calendarData.bookingInfo.id, 'completed');
                await dispatch(setGetBookingList(true));
            }
        } else {
            await handleSubmit((e) => onSubmit(e))();
        }

        // await dispatch(setGetBookingList(true));
    };

    const handleWishlist = async () => {
        const updateState = {
            action: { ...initAction, waitList: true },
            isSidebarOpen: true,
        };
        setIsLoading(false);
        await dispatch(setData(updateState));
    };
    return (
        <div
            ref={sidebarRef}
            className={`sideModal calendar_sideModal bg-white z-10 fixed bottom-0 top-[61px] right-[2px] transition delay-150 duration-300 ease-in-out drop-shadow-[-1px_0px_0px_rgba(219,219,219,0.40)] ${
                calendarData.isSidebarOpen ? 'w-[361px] 2xl:w-[391px]' : 'w-[62px]'
            }`}
        >
            <div className={` py-8 ${calendarData.isSidebarOpen ? 'hidden' : ''}`}>
                <Button className="flex mx-auto" onClick={handleWishlist}>
                    <LuArrowLeftToLine className="w-6 h-5" onClick={handleWishlist} />
                </Button>
            </div>
            <div className={`h-full  ${calendarData.isSidebarOpen ? ' ' : 'hidden'}`}>
                {!isLoading ? (
                    <FormProvider {...methods}>
                        <form onSubmit={handleSubmit((e) => onSubmit(e))} className="h-full flex flex-col">
                            {calendarData.action.newAppointment || calendarData.action.bookedAppointment || calendarData.action.updateAppointment || calendarData.action.cancelAppointment ? (
                                <>
                                    <AppointmentDetails
                                        setSidebarState={setSidebarState}
                                        sidebarState={sidebarState}
                                        bookedSlotInfo={calendarData.bookingInfo}
                                        control={control}
                                        errors={errors}
                                        appointmentCalulation={appointmentCalulation}
                                        handleSubmitForm={handleSubmitForm}
                                        cancelChargeError={cancelChargeError}
                                        isBtnDisable={isBtnDisable}
                                        setIsBtnDisable={setIsBtnDisable}
                                        handleClose={handleClose}
                                    />
                                </>
                            ) : calendarData.action.rescheduleAppointment ? (
                                <AppointmentDetails
                                    setSidebarState={setSidebarState}
                                    sidebarState={sidebarState}
                                    bookedSlotInfo={calendarData.bookingInfo}
                                    control={control}
                                    errors={errors}
                                    appointmentCalulation={appointmentCalulation}
                                    handleSubmitForm={handleSubmitForm}
                                    cancelChargeError={cancelChargeError}
                                    isBtnDisable={isBtnDisable}
                                    setIsBtnDisable={setIsBtnDisable}
                                    handleClose={handleClose}
                                />
                            ) : calendarData.action.blockTime ? (
                                <BlockTime />
                            ) : calendarData.action.paymentAppointment ? (
                                <PaymentData />
                            ) : calendarData.action.waitList ? (
                                <Waitlist />
                            ) : !calendarData.action.isSlotAvailable ? (
                                <SlotNotAvailable />
                            ) : (
                                <Waitlist />
                            )}
                        </form>
                    </FormProvider>
                ) : (
                    <BarLoader />
                )}
            </div>
        </div>
    );
};

export default Sidebar;
