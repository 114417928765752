import React from 'react';
import { Controller } from 'react-hook-form';

import Button from 'src/components/Button';
import { IProps } from './Location.interface';
import Map from 'src/components/Map/Map';
import AddNewForm from './Location.AddNewForm';
import AutoCompleteGoogle from 'src/components/AutoCompleteGoogle/AutoCompleteGoogle';

const AddNew = ({ chooseFile, cropData, uploadProgress, showProgressBar, handleClick, status, handleAutocompleteChange, validForm, formData, locationAddress, isValidAddress, values }: IProps) => (
    <div className="flex flex-col h-full justify-between">
        <div className="">
            <h3 className="fwpo_heading_title w-[480px] mx-auto text-center mb-10">Create new location</h3>
            <AddNewForm Controller={Controller} chooseFile={chooseFile} cropData={cropData} uploadProgress={uploadProgress} showProgressBar={showProgressBar} />
            {status && (
                <>
                    <h3 className="font-semibold text-xl leading-[124%] -tracking-[0.24px] mb-3">Map location</h3>
                    <p className="text-secondaryTxtColor leading-[140%] -tracing-[0.14px] mb-5">Drag the map so the pin matches the exact location of your business location.</p>
                    <AutoCompleteGoogle
                        inputName="locationAddress"
                        placeholder="Enter address"
                        className="form_control"
                        onChangeFunc={handleAutocompleteChange?.('locationAddress')}
                        onBlur={handleAutocompleteChange?.('locationAddress')}
                        errorText={!validForm?.locationAddress ? 'Address is required' : null}
                        value={formData?.locationAddress.fullAddress || locationAddress}
                    />
                    {!isValidAddress?.locationAddress && 'Please select valid address'}
                    {isValidAddress?.locationAddress &&
                        status &&
                        formData?.locationAddress &&
                        formData?.locationAddress &&
                        (formData?.locationAddress?.latitude ?? 0) !== 0 &&
                        (formData?.locationAddress?.longitude ?? 0) !== 0 && (
                            <div className="rounded-3xl h-[240px] bg-secondaryTxtColor overflow-hidden">
                                <Map address={formData?.locationAddress} id={'consigneeMap'} onReverseAddressFunc={handleAutocompleteChange?.('locationAddress')} isCurrentLocation={true} />
                            </div>
                        )}
                </>
            )}
        </div>
        <div className="flex w-full px-10 justify-center mt-10">
            <Button
                className="fl-btn btn_primary w-[420px] 2xl:w-[572px]"
                onClick={handleClick}
                type="button"
                // disabled={loading}
            >
                Continue
            </Button>
        </div>
    </div>
);

export default AddNew;
