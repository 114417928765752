import React, { memo, useEffect, useRef, useState } from 'react';
import { ICustomDropdownWithDate } from './CustomDropdownWithDate.interface';
import DateSelect from '../DateSelect/DateSelect';
import moment from 'moment';
import { Images } from 'src/theme';
import Button from '../Button';
import { BiSolidChevronDown } from 'react-icons/bi';
import { FaCheck } from 'react-icons/fa6';
import { format } from 'date-fns';

const CustomDropdownWithDate: React.FC<ICustomDropdownWithDate> = ({ data, handleChangeValue, value, subSwitch, handleItemClick, setSubSwitch }) => {
    const [Switch, setSwitch] = useState(false);
    const [startDate, setStartDate] = useState(moment().clone().subtract(10, 'days').toDate());
    const [endDate, setEndDate] = useState(moment().toDate());
    // useEffect(() => {
    //     const handler = () => {
    //         setSwitch(false);
    //         setSubSwitch?.(false);
    //     };
    //     window.addEventListener('click', handler);
    //     return () => {
    //         window.removeEventListener('click', handler);
    //     };
    // }, [setSubSwitch]);

    const handlerInputClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();

        setSwitch(!Switch);
        setSubSwitch?.(!subSwitch || false);
    };

    const onItemClick = (e: React.MouseEvent<HTMLLIElement>, itemValue: any) => {
        let selectedData = {
            ...itemValue,
            startDate: startDate,
            endDate: endDate,
        };
        setSwitch(false);
        handleChangeValue(selectedData);
        handleItemClick && handleItemClick(e);
    };

    const divRef = useRef<HTMLDivElement>(null);
    const closeHandler = () => {
        setSwitch(false);
        setSubSwitch?.(false);
    };

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (divRef.current && !divRef.current.contains(event.target as Node)) {
                closeHandler();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [divRef]);

    const endDateRef = useRef<any>(null);

    useEffect(() => {
        if (endDateRef.current) {
            endDateRef.current.setOpen(true);
        }
    }, [startDate]);

    return (
        <div className="w-[290px] relative mx-[6px] bg-white " ref={divRef}>
            <Button
                className="w-full py-3 px-2 flex justify-between items-center border border-lineColor alltime_btn rounded-full focus:border-primary active:border-primary"
                onClick={handlerInputClick}
            >
                <span className="text-sm ms-2 text-primary font-medium -tracking-[-0.14px]">{value.label}</span>
                <BiSolidChevronDown className="ml-1 text-secondaryTxtColor" size={20} />
            </Button>
            {Switch && (
                <div className="absolute z-10 top-[100%] mt-2 right-0">
                    <div className="bg-white rounded-3xl shadow-[0px_5.333px_16px_0px_rgba(146,_140,_151,_0.25)] w-350 px-5 py-4 relative">
                        <div className="flex items-center mb-[10px]" onClick={() => {}}>
                            <DateSelect
                                label="from"
                                inputName="start_date"
                                className="form_control_date w-[132px] form_input_control"
                                labelClassName="font-normal mb-0 mr-1"
                                placeholder="dd/mm/YYYY"
                                dateFormat="dd-MM-Y"
                                isFormate={false}
                                value={format(startDate, 'dd.MM.Y')}
                                selected={startDate}
                                onChangeValue={(date: any) => {
                                    if (moment(date).isAfter(endDate)) {
                                        setEndDate(moment(date).toDate());
                                    }
                                    setStartDate(moment(date).toDate());
                                }}
                                icon={<img src={Images.iconDateCalendar} alt="" className="w-5 " />}
                                selectsStart
                            />

                            <DateSelect
                                ref={endDateRef}
                                label="to"
                                inputName="end_date"
                                className="form_control_date w-[132px] form_input_control"
                                labelClassName="font-normal mb-0 mx-1"
                                placeholder="dd/mm/YYYY"
                                dateFormat="dd-MM-Y"
                                value={format(endDate, 'dd.MM.Y')}
                                selected={endDate}
                                isFormate={false}
                                minDate={startDate}
                                startDate={startDate}
                                onChangeValue={(date: any) => {
                                    setEndDate(moment(date).toDate());
                                    let selectedData = {
                                        label: 'Custom',
                                        value: 'custom',
                                        startDate: startDate,
                                        endDate: date,
                                    };
                                    handleChangeValue(selectedData);
                                    setSwitch(false);
                                }}
                                icon={<img src={Images.iconDateCalendar} alt="" className="w-5 " />}
                                selectsEnd
                            />
                        </div>
                        <ul className="" key="all">
                            {data.map((item: any) => (
                                <li key={item.value} className={`py-[10px] cursor-pointer flex items-center selected`} onClick={(e) => onItemClick(e, item)}>
                                    {item.label}
                                    {value.value === item.value && <FaCheck size={20} className="text-primary ml-auto" />}
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            )}
        </div>
    );
};

export default memo(CustomDropdownWithDate);
