import React, { useEffect, useRef } from 'react';

const AutoCompleteGoogle = ({ inputName, placeholder, errorText, labelcontainer, className, label, required, onChangeFunc, name, error, disabled, ...props }: any) => {
    let autoComplete: any;
    const autoCompleteRef = useRef(null);

    useEffect(() => {
        if (window.google && window.google.maps && window.google.maps.places) {
            autoComplete = new window.google.maps.places.Autocomplete(autoCompleteRef.current, {
                // types: ['establishment'],
                componentRestrictions: { country: ['us', 'ca'] },
            });
            autoComplete.setFields(['address_components', 'formatted_address', 'geometry']);
            autoComplete.addListener('place_changed', handlePlaceSelect);
        }
        // return () => autoComplete.removeListener('place_changed', handlePlaceSelect);
    }, []);

    const handlePlaceSelect = () => {
        const addressObject = autoComplete.getPlace();
        if (addressObject?.geometry) {
            let latitude = addressObject.geometry.location.lat();
            let longitude = addressObject.geometry.location.lng();
            const fullAddress = addressObject.formatted_address;
            let city = '';
            let state = '';
            let postcode = '';
            let address = '';
            let country = '';
            let stateCode = '';
            let countryCode = '';

            addressObject.address_components.forEach((component: any) => {
                const componentType = component.types;

                if (componentType.includes('premise')) {
                    address += `${component.long_name} `;
                } else if (componentType.includes('street_number')) {
                    address += `${component.long_name} `;
                } else if (componentType.includes('route')) {
                    address += `${component.short_name} `;
                } else if (componentType.includes('sublocality')) {
                    address += `${component.long_name} `;
                } else if (componentType.includes('postal_code')) {
                    postcode = `${component.long_name}${postcode}`;
                } else if (componentType.includes('postal_code_suffix')) {
                    postcode = `${postcode}-${component.long_name}`;
                } else if (componentType.includes('locality')) {
                    city = component.long_name;
                } else if (componentType.includes('administrative_area_level_1')) {
                    state = component.long_name;
                    stateCode = component.short_name;
                } else if (componentType.includes('country')) {
                    country = component.long_name;
                    countryCode = component.short_name;
                }
            });

            let extraAddress;
            if (address) {
                extraAddress = fullAddress.substring(0, fullAddress.indexOf(address.split(' ')[0]));
            }
            const address1 = `${extraAddress} ${address}`;

            const addressObj = {
                fullAddress,
                address1,
                city,
                state,
                country,
                postal: postcode,
                latitude,
                longitude,
                stateCode,
                countryCode,
            };
            onChangeFunc(addressObj);
        }
    };

    const handleChange = (event: any) => {
        const { value } = event.target;
        onChangeFunc(value);
    };

    return (
        <div className="position-relative mb-2">
            <div className={` flex flex-col  w-full`}>
                {label && (
                    <label htmlFor={inputName} className="fl-field-title-label">
                        {label}
                    </label>
                )}

                <input
                    className={`form_input_control ${className} ${error && 'is-invalid'}`}
                    type="text"
                    autoComplete={inputName}
                    ref={autoCompleteRef}
                    onChange={handleChange}
                    required={required}
                    {...props}
                    placeholder={placeholder}
                    onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                            e.preventDefault(); // Prevent form submission
                        }
                    }}
                    disabled={disabled}
                />
            </div>
        </div>
    );
};

export default AutoCompleteGoogle;
