import React from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import DateSelect from 'src/components/DateSelect/DateSelect';
import InputWithLabel from 'src/components/InputWithLabel';
import { Images } from 'src/theme';

const CommissionPayout = ({ shop_payment, control, errors, isEdit }: any) => {
    const { t } = useTranslation();
    return (
        <>
            <div className="flex -mx-[10px] flex-wrap mt-6">
                <div className="px-[10px] mb-5 w-1/2 grow-0 shrink-0 basis-[50%]">
                    <Controller
                        name="commission"
                        control={control}
                        render={({ field: { onChange, value } }: any) => (
                            <InputWithLabel
                                label={t('Enter staff comission')}
                                value={value}
                                onChange={onChange}
                                placeholder={t('Enter staff comission')}
                                name="commission"
                                disabled={!isEdit}
                                error={!!errors.staff_commission}
                            />
                        )}
                    />
                </div>
                <div className="px-[10px] mb-5 w-1/2">
                    <Controller
                        name="start_date"
                        control={control}
                        render={({ field: { onChange, value } }: any) => (
                            <DateSelect
                                label={t('Start date')}
                                inputName="start_date"
                                className={`form_control_date w-full form_input_control ${errors?.start_date && 'is-invalid'}`}
                                placeholder="yyyy-mm-dd"
                                dateFormat="yyyy-MM-dd"
                                disabled={!isEdit}
                                onChangeValue={onChange}
                                icon={<img src={Images.iconDateCalendar} alt="" className="w-5 " />}
                                value={value}
                                minDate={new Date()}
                                onChangeRaw={() => onchange}
                            />
                        )}
                    />
                </div>
                <div className="px-[10px] w-1/2 mb-4">
                    <InputWithLabel
                        label={t('Commission payout frequency')}
                        placeholder={t('Commission payout frequency')}
                        value={shop_payment?.commission_frequency ?? ''}
                        disabled
                        onChange={() => {}}
                        name={''}
                    />
                </div>
                {(shop_payment?.commission_frequency === 'weekly' || shop_payment?.commission_frequency === 'bi-Weekly' || shop_payment?.commission_frequency === 'tri-Weekly') && (
                    <div className="px-[10px] w-1/2 mb-4">
                        <InputWithLabel label={t('Payment day')} placeholder={t('Payment day')} value={shop_payment?.commission_payment_day ?? ''} disabled name={''} onChange={() => {}} />
                    </div>
                )}
            </div>
        </>
    );
};

export default CommissionPayout;
