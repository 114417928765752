import { Label, Radio } from 'flowbite-react';
import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import Button from 'src/components/Button';
import DateSelect from 'src/components/DateSelect/DateSelect';
import InputWithLabel from 'src/components/InputWithLabel';
import { Images } from 'src/theme';
import { ICalculateDueDateAndRent, IProps } from './Staff.interface';
import { useTranslation } from 'react-i18next';
import { ROUTES } from 'src/constants/routes';
import moment from 'moment';

const PayStructure = ({ setStep, onClick, shop_payment, isLoading }: IProps) => {
    const { control, formState, watch, setValue } = useFormContext();

    const errors: any = formState.errors;
    const firstName = watch('first_name');
    const payStructureType = watch('pay_structure_type');

    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleNavigateToPayment = () => {
        navigate(ROUTES.SETTINGS.PAYMENT);
    };
    const { clearErrors } = useFormContext();

    const handleInputChange = (name: string, onChange: any) => (event: any) => {
        onChange(event);
        clearErrors(name);
    };

    const startdatewatch = watch('start_date');
    const effectivedatewatch = watch('effective_date');
    const rentmoneywatch = watch('rent_money');

    const getDayNumber = (dayName: string) => {
        const days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
        return days.indexOf(dayName.toLowerCase());
    };

    const [formattedCommissionDate, setFormattedCommissionDate] = useState('');
    const [formattedRentDate, setFormattedRentDate] = useState('');
    const [rentPendingPrice, setRentPendingPrice] = useState('');

    const calculateDueDateAndRent = ({ currentDateTime, paymentType, shopPayment, moneywatch, weekNumber }: ICalculateDueDateAndRent) => {
        let dueDate;
        let rent;
        if (paymentType === 'commission') {
            // const weekNumber = currentDateTime.week();
            const commissionDayNumber = getDayNumber(shopPayment.commission_payment_day);
            const commissionDay = shopPayment.commission_payment_day;
            const currentDateDay = getDayNumber(currentDateTime.format('dddd'));
            // weekly
            if (shop_payment?.commission_frequency === 'weekly') {
                if (shop_payment?.commission_payment_day === currentDateTime.format('dddd').toLowerCase()) {
                    dueDate = currentDateTime.add(7, 'days');
                } else {
                    dueDate = currentDateTime.clone().day(commissionDayNumber);
                    if (dueDate.isBefore(currentDateTime, 'day')) {
                        dueDate.add(7, 'days');
                    }
                }
            }
            // bi-weekly
            else if (shop_payment?.commission_frequency === 'bi_weekly') {
                if (weekNumber % 2 === 0) {
                    if (currentDateDay < commissionDayNumber) {
                        dueDate = currentDateTime.clone().startOf('isoWeek').day(commissionDay);
                    } else {
                        dueDate = currentDateTime.clone().add(2, 'week').startOf('isoWeek').day(commissionDay);
                    }
                } else {
                    dueDate = currentDateTime.clone().add(1, 'week').startOf('isoWeek').day(commissionDay);
                }

                if (currentDateTime.format('dddd') === 'Sunday') {
                    dueDate = dueDate.clone().add(1, 'week');
                }
            }
            // month
            else {
                dueDate = currentDateTime.clone().add(1, 'month').startOf('month');
            }
        } else {
            const rentDay = getDayNumber(shopPayment?.rent_payment_day);
            const currentDateDay = getDayNumber(currentDateTime.format('dddd'));
            // weekly
            if (shop_payment?.rent_frequency === 'weekly') {
                if (shop_payment?.commission_payment_day === currentDateTime.format('dddd').toLowerCase()) {
                    dueDate = currentDateTime.add(7, 'days');
                } else {
                    dueDate = currentDateTime.clone().day(rentDay);
                    if (dueDate.isBefore(currentDateTime, 'day')) {
                        dueDate.add(7, 'days');
                    }
                }
            }
            // bi-weekly
            else if (shop_payment?.rent_frequency === 'bi_weekly') {
                // const weekNumber = currentDateTime.week();
                if (weekNumber % 2 === 0) {
                    if (currentDateDay < rentDay) {
                        dueDate = currentDateTime.clone().startOf('isoWeek').day(shop_payment?.rent_payment_day);
                    } else {
                        dueDate = currentDateTime.clone().add(2, 'week').startOf('isoWeek').day(shop_payment?.rent_payment_day);
                    }
                } else {
                    dueDate = currentDateTime.clone().add(1, 'week').startOf('isoWeek').day(shop_payment?.rent_payment_day);
                }

                if (currentDateTime.format('dddd') === 'Sunday') {
                    dueDate = dueDate.clone().add(1, 'week');
                }
            }
            // month
            else {
                dueDate = currentDateTime.clone().add(1, 'month').startOf('month');
            }
            if (dueDate) {
                const differenceInDays = dueDate.diff(currentDateTime, 'days');
                if (!isNaN(differenceInDays)) {
                    if (shop_payment?.rent_frequency === 'weekly') {
                        rent = (moneywatch * differenceInDays) / 7;
                    } else if (shop_payment?.rent_frequency === 'bi_weekly') {
                        rent = (moneywatch * differenceInDays) / 14;
                    } else {
                        const daysInMonth = moment(currentDateTime, 'YYYY-MM').daysInMonth();
                        const dateOfSelectedValue = currentDateTime.date();
                        let pendingDays = daysInMonth - dateOfSelectedValue;
                        pendingDays += 1;
                        rent = (pendingDays * moneywatch) / daysInMonth;
                    }
                }
            }
        }

        return { dueDate, rent };
    };

    useEffect(() => {
        if (shop_payment && ((payStructureType === 'commission' && startdatewatch) || (payStructureType !== 'commission' && effectivedatewatch && rentmoneywatch))) {
            const currentDateTime = moment(payStructureType === 'commission' ? startdatewatch : effectivedatewatch);
            const weekNumber = currentDateTime.week();
            const { dueDate, rent } = calculateDueDateAndRent({
                currentDateTime,
                paymentType: payStructureType,
                shopPayment: shop_payment,
                moneywatch: rentmoneywatch,
                weekNumber: weekNumber,
            });
            if (dueDate) {
                const formattedDueDate = dueDate.format('YYYY/MM/DD');
                if (payStructureType === 'commission') {
                    setFormattedCommissionDate(formattedDueDate);
                } else {
                    setFormattedRentDate(formattedDueDate);
                    if (rent) {
                        setRentPendingPrice(rent.toFixed(2).toString());
                    }
                }
            }
        }
    }, [startdatewatch, payStructureType, effectivedatewatch, rentmoneywatch]);

    return (
        <div>
            <div className="mb-10 text-center ">
                <h3 className="fwpo_heading_title w-[480px] mx-auto">{t('Select pay structure')}</h3>
            </div>
            <Controller
                name={`pay_structure_type`}
                control={control}
                render={({ field: { onChange, value } }: any) => (
                    <>
                        <div className="flex flex-wrap -m-[10px]">
                            <div className="flex px-[10px] w-1/2">
                                <div className="flex w-full justify-between items-center px-5 py-6 border border-lineColor bg-white drop-shadow-cardShadow rounded-2xl custom-round-radio-block">
                                    <div className="flex items-center">
                                        <i className="w-[50px] h-[50px] bg-inputBg rounded-2xl flex items-center justify-center">
                                            <img src={Images.iconMoneyOutlinePrimary} alt="" className="w-[25px]" />
                                        </i>
                                        <span className="text-base font-bold leading-[140%] -tracking-[0.16px] ml-4">{t('Commission')}</span>
                                    </div>
                                    {''}
                                    <Radio
                                        className="custom-round-radio"
                                        id="commission"
                                        name="pay_structure_type"
                                        value={'commission'}
                                        onChange={(e: any) => {
                                            setValue('rent_money', '');
                                            setValue('effective_date', null);
                                            setRentPendingPrice('');
                                            setFormattedRentDate('');
                                            onChange(e);
                                        }}
                                        checked={value === 'commission'}
                                    />
                                    <Label className="custom-round-label" htmlFor="commission"></Label>
                                </div>
                            </div>

                            <div className="flex px-[10px] w-1/2">
                                <div className="flex w-full justify-between items-center px-5 py-6 border border-lineColor bg-white drop-shadow-cardShadow rounded-2xl custom-round-radio-block">
                                    <div className="flex items-center">
                                        <i className="w-[50px] h-[50px] bg-inputBg rounded-2xl flex items-center justify-center">
                                            <img src={Images.iconRentOutlinePrimary} alt="" className="w-[25px]" />
                                        </i>
                                        <span className="text-base font-bold leading-[140%] -tracking-[0.16px] ml-4">{t('Rent')}</span>
                                    </div>
                                    {''}
                                    <Radio
                                        className="custom-round-radio"
                                        id="Rent"
                                        name="pay_structure_type"
                                        value={'rent'}
                                        onChange={(e: any) => {
                                            setValue('commission', '');
                                            setValue('start_date', null);
                                            setFormattedCommissionDate('');
                                            onChange(e);
                                        }}
                                        checked={value === 'rent'}
                                    />
                                    <Label className="custom-round-label" htmlFor="Rent"></Label>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            />
            {payStructureType === 'commission' ? (
                <div className="flex mt-8 -mx-[10px] mb-5">
                    <Controller
                        name="commission"
                        control={control}
                        render={({ field: { onChange, value } }: any) => (
                            <>
                                <div className="w-1/2 px-[10px] ">
                                    <InputWithLabel
                                        label={t('Enter staff comission(%)')}
                                        placeholder={t('Please enter staff commission')}
                                        onChange={handleInputChange('commission', onChange)}
                                        value={value}
                                        name="commission"
                                        type="number"
                                        error={!!errors.commission}
                                        max={100}
                                    />
                                    {errors && errors?.commission && <p className="text-error">{errors?.commission?.message}</p>}
                                </div>
                            </>
                        )}
                    />
                    <Controller
                        name="start_date"
                        control={control}
                        render={({ field: { onChange, value } }: any) => (
                            <>
                                <div className="w-1/2 px-[10px]">
                                    <DateSelect
                                        label={t('Employee start date')}
                                        inputName="Employee start date"
                                        className={`form_control_date w-full form_input_control ${errors?.start_date && 'is-invalid'}`}
                                        placeholder="yyyy-mm-dd"
                                        dateFormat="yyyy-MM-dd"
                                        errorText={''}
                                        onChangeValue={handleInputChange('start_date', onChange)}
                                        icon={<img src={Images.iconDateCalendar} alt="" className="w-5 " />}
                                        value={value}
                                        minDate={new Date()}
                                        onChangeRaw={() => onchange}
                                    />
                                    {errors && errors?.start_date && <p className="text-error">{errors?.start_date?.message}</p>}
                                </div>
                            </>
                        )}
                    />
                </div>
            ) : (
                <div className="flex mt-8 -mx-[10px] mb-5">
                    <Controller
                        name="rent_money"
                        control={control}
                        render={({ field: { onChange, value } }: any) => (
                            <>
                                <div className="w-1/2 px-[10px] ">
                                    <InputWithLabel
                                        label={t('Enter rent money')}
                                        placeholder={t('Please enter rent money')}
                                        onChange={onChange}
                                        value={value}
                                        name="rent_money"
                                        type="number"
                                        error={!!errors.rent_money}
                                    />

                                    {errors && errors?.rent_money && <p className="text-error">{errors?.rent_money?.message}</p>}
                                </div>
                            </>
                        )}
                    />
                    <Controller
                        name="effective_date"
                        control={control}
                        render={({ field: { onChange, value } }: any) => (
                            <>
                                <div className="w-1/2 px-[10px]">
                                    <DateSelect
                                        label={t('Effective date')}
                                        inputName="effective_date"
                                        className={`form_control_date w-full form_input_control ${errors?.effective_date && 'is-invalid'}`}
                                        placeholder="yyyy-mm-dd"
                                        dateFormat="yyyy-MM-dd"
                                        errorText={''}
                                        onChangeValue={onChange}
                                        icon={<img src={Images.iconDateCalendar} alt="" className="w-5 " />}
                                        value={value}
                                        minDate={new Date()}
                                        onChangeRaw={() => onchange}
                                    />
                                    {errors && errors?.effective_date && <p className="text-error">{errors?.effective_date?.message}</p>}
                                </div>
                            </>
                        )}
                    />
                </div>
            )}
            {payStructureType === 'commission' ? (
                <>
                    <div>
                        <h3 className="text-base -tracking-[0.16px] leading-[140%] mb-1 flex items-center">
                            {t('Commission payout frequency')}: <span className="font-bold ml-2">{shop_payment && shop_payment?.commission_frequency}</span>
                            <img src={Images.iconEditOutlinePrimary} alt="" className="w-4 h-4 ml-2 cursor-pointer" onClick={handleNavigateToPayment} />
                        </h3>
                        <p className="text-xs text-secondaryTxtColor leading-[140%] -tracking-[0.12px]">{t('commission_frequency_description')}</p>
                    </div>
                    <div className="flex mt-5 -mx-[10px] mb-5">
                        <div className="w-1/2 px-[10px] ">
                            <InputWithLabel
                                label={t('Commission payout frequency')}
                                placeholder={t('Commission payout frequency')}
                                value={shop_payment?.commission_frequency ?? ''}
                                disabled
                                onChange={() => {}}
                                name={''}
                            />
                        </div>
                        {(shop_payment?.commission_frequency === 'weekly' || shop_payment?.commission_frequency === 'bi_weekly') && (
                            <div className="px-[10px] w-1/2">
                                <InputWithLabel label={t('Payment day')} placeholder={t('Payment day')} value={shop_payment?.commission_payment_day ?? ''} disabled name={''} onChange={() => {}} />
                            </div>
                        )}
                    </div>
                    <div className="flex width-[832px] py-3 px-4 border rounded-3xl gap-2">
                        {t('staff_pay_structure_commission_description', {
                            first_name: firstName,
                            formattedCommissionDate: formattedCommissionDate,
                        })}
                    </div>
                </>
            ) : (
                <>
                    <div>
                        <h3 className="text-base -tracking-[0.16px] leading-[140%] mb-1 flex items-center">
                            {t('Rent collection frequency')}: <span className="font-bold ml-2">{shop_payment && shop_payment?.rent_frequency}</span>
                            <img src={Images.iconEditOutlinePrimary} alt="" className="w-4 h-4 ml-2 cursor-pointer" onClick={handleNavigateToPayment} />
                        </h3>
                        <p className="text-xs text-secondaryTxtColor leading-[140%] -tracking-[0.12px]">{t('rent_frequency_description')}</p>
                    </div>
                    <div className="flex mt-5 -mx-[10px] mb-5">
                        <div className="w-1/2 px-[10px] ">
                            <InputWithLabel
                                label={t('Enter rent money')}
                                placeholder={t('Please enter rent money')}
                                value={shop_payment?.rent_frequency ?? ''}
                                disabled
                                onChange={() => {}}
                                name={''}
                            />
                            {shop_payment?.rent_frequency === 'monthly' && (
                                <p className="text-xs text-secondaryTxtColor leading-[140%] -tracking-[0.12px]">{t('The default payment day is the first day of the month.')}</p>
                            )}
                        </div>

                        {(shop_payment?.rent_frequency === 'weekly' || shop_payment?.rent_frequency === 'bi_weekly') && (
                            <div className="px-[10px] w-1/2">
                                <InputWithLabel label={t('Payment day')} placeholder={t('Payment day')} value={shop_payment?.rent_payment_day ?? ''} disabled name={''} onChange={() => {}} />
                            </div>
                        )}
                    </div>
                    <div className="flex width-[832px] py-3 px-4 border rounded-3xl gap-2">
                        {t('staff_pay_structure_rent_description', {
                            first_name: firstName,
                            formattedRentDate: formattedRentDate,
                            rentPendingPrice: rentPendingPrice,
                        })}
                    </div>
                </>
            )}

            <div className="flex w-full px-10 justify-center mt-14">
                <Button
                    // className="fl-btn btn_primary w-[420px] mt-5"
                    className="fl-btn btn_primary w-[420px] absolute bottom-6 p-4"
                    type="button"
                    onClick={onClick}
                    isLoading={isLoading}
                >
                    {t('Add Staff Member')}
                </Button>
            </div>
        </div>
    );
};

export default PayStructure;
