import React, { FC, useState } from 'react';
import Button from 'src/components/Button';
import { useAppDispatch } from 'src/redux/hooks';
import { deleteStripeCard } from './MyAccount.slice';
import { toast } from 'react-toastify';
import { IRemoveCard } from './MyAccount.interface';

const RemoveCard: FC<IRemoveCard> = ({ paymentMethodId, setPaymentMethodId, getCard, handleClose, handleModalClose }) => {
    const dispatch = useAppDispatch();
    const [isLoading, setIsLoading] = useState(false);

    const handlePayCardDelete = async () => {
        setIsLoading(true);
        const result = await dispatch(deleteStripeCard({ payment_method_id: paymentMethodId }));
        if (result.type === deleteStripeCard.fulfilled.toString()) {
            handleClose();
            getCard();
            handleModalClose();
            setPaymentMethodId('');
            return;
        }
        if (result.type === deleteStripeCard.rejected.toString()) {
            const response = result.payload.data;
            toast.error(response.message);
        }
        setIsLoading(false);
    };
    const handlePayCardNotDelete = () => {
        handleModalClose();
        setPaymentMethodId('');
    };
    return (
        <>
            {' '}
            <div className="text-center">
                <h3 className="mb-5 text-xl font-bold text-mainTextColor -tracking-[0.6px] ">Remove payment method?</h3>
                <p className="mb-8 text-sm font-normal text-secondaryTxtColor -tracking-[0.3px]">Are you sure you want to remove this card? This action cannot be undone.</p>
                <div className="w-full">
                    <Button className="w-full fl-btn px-[10px] btn_primary text-base text-center justify-center font-bold mb-3" onClick={handlePayCardNotDelete}>
                        Not now
                    </Button>
                    <Button isLoading={isLoading} disabled={isLoading} className="w-full fl-btn text-base font-bold btn_outline_black px-[10px] justify-center" onClick={handlePayCardDelete}>
                        {"Yes, I'm sure"}
                    </Button>
                </div>
            </div>
        </>
    );
};

export default RemoveCard;
