import React from 'react';

export const MdOutlineMoreVert = ({ stroke, currentColor }: { stroke: string; currentColor: string }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <rect x="0.5" y="0.5" width="23" height="23" rx="7.5" stroke={stroke} />
        <path
            d="M10.4131 12.0006C10.4131 12.8753 11.1249 13.5871 11.9996 13.5871C12.8743 13.5871 13.5862 12.8753 13.5862 12.0006C13.5862 11.1259 12.8743 10.4141 11.9996 10.4141C11.1249 10.4141 10.4131 11.1259 10.4131 12.0006Z"
            fill={currentColor}
        />
        <path
            d="M10.4131 17.2897C10.4131 18.1644 11.1249 18.8762 11.9996 18.8762C12.8743 18.8762 13.5862 18.1644 13.5862 17.2897C13.5862 16.415 12.8743 15.7031 11.9996 15.7031C11.1249 15.7031 10.4131 16.415 10.4131 17.2897Z"
            fill={currentColor}
        />
        <path
            d="M10.4131 6.71154C10.4131 7.58625 11.1249 8.29808 11.9996 8.29808C12.8743 8.29808 13.5862 7.58625 13.5862 6.71154C13.5862 5.83683 12.8743 5.125 11.9996 5.125C11.1249 5.125 10.4131 5.83683 10.4131 6.71154Z"
            fill={currentColor}
        />
    </svg>
);
