import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { API } from 'src/constants/api';
import { errorCode } from 'src/constants/errorCode';
import { RootState } from 'src/redux/store';

import { axiosPost, axiosPatch, axiosGet } from 'src/utils/requestClient';
import { IActionType, IBookingState } from './Calendar.Interface';
import { generateTimeRanges } from 'src/utils/global-functions';
import moment from 'moment';

interface ErrorType {
    message: string;
    status?: string;
    data?: any;
}
export const initAction: IActionType = {
    newAppointment: false,
    bookedAppointment: false,
    updateAppointment: false,
    cancelAppointment: false,
    confirmedCancelAppointment: false,
    paymentAppointment: false,
    completedPaymentAppointment: false,
    blockTime: false,
    rescheduleAppointment: false,
    waitList: true,
};

const bookingClearState = {
    selectedStaff: null,
    bookingInfo: null,
    // staffInfo: [],
    // selectedSlotStaffId: null,
    // selectedClientInfo: {},
    isSidebarOpen: false,
    // action: initAction,
};
const initialState: IBookingState = {
    data: {
        staffList: [],
        serviceList: [],
        clientList: [],
        resourceList: [],
        locationList: [],
        locationInfoList: [],
        selectedStaff: null,
        selectedLocation: null,
        selectedDate: moment().toDate(),
        selectedTime: moment().toDate(),
        bookingInfo: null,
        isNewBooking: false,
        bookingType: 'new',
        staffInfo: [],
        selectedSlotStaffId: null,
        selectedClientInfo: {},
        isSidebarOpen: true,
        events: [],
        action: initAction,
        getBookingList: false,
        // paymentType: null,
        isCharge: true,
        removeBlockTime: {
            isRemoveBlockTime: false,
            blockTimeId: null,
            staffId: null,
        },
        isSlotAvailable: true,
        chargeableAmount: {
            charge: null,
            charge_type: '$',
        },
        payments: null,
        cancelAppointment: {
            type: null,
            status: false,
            message: '',
        },
    },
    loading: false,
    error: null,
};

export const bookingList = createAsyncThunk('/shop/booking/list', async (payload: any, { rejectWithValue }) => {
    try {
        const response = await axiosPost(API.BOOKING.LIST_BY_DATE, payload.payload, {
            shop_id: payload.shop_id,
        });
        if (response.data.status === errorCode.success || response.data.status === errorCode.updateSuccess) {
            return response.data;
        }

        return rejectWithValue(response as ErrorType);
    } catch (err: any) {
        if (!err.response) {
            throw err;
        }
        return rejectWithValue(err.response.data as ErrorType);
    }
});

export const bookingCreate = createAsyncThunk('/shop/booking/create', async (payload: any, { rejectWithValue }) => {
    try {
        const response = await axiosPost(API.BOOKING.CREATE, payload.payload, {
            shop_id: payload.shop_id,
            location_id: payload.location_id,
        });
        if (response.data.status === errorCode.success || response.data.status === errorCode.updateSuccess) {
            return response.data;
        }

        return rejectWithValue(response as ErrorType);
    } catch (err: any) {
        if (!err.response) {
            throw err;
        }
        return rejectWithValue(err.response.data as ErrorType);
    }
});

export const bookingUpdate = createAsyncThunk('/shop/booking/update', async (payload: any, { rejectWithValue }) => {
    try {
        const response = await axiosPatch(API.BOOKING.UPDATE, payload.payload, {
            shop_id: payload.shop_id,
            location_id: payload.location_id,
            id: payload.id,
        });
        if (response.data.status === errorCode.success || response.data.status === errorCode.updateSuccess) {
            return response.data;
        }

        return rejectWithValue(response as ErrorType);
    } catch (err: any) {
        if (!err.response) {
            throw err;
        }
        return rejectWithValue(err.response.data as ErrorType);
    }
});
export const getReceipt = createAsyncThunk('/shop/booking/receipt', async (payload: any, { rejectWithValue }) => {
    try {
        const response = await axiosGet(API.BOOKING.RECEIPT, payload.params, payload.payload);
        if (response.data.status === errorCode.success || response.data.status === errorCode.updateSuccess) {
            return response.data;
        }

        return rejectWithValue(response as ErrorType);
    } catch (err: any) {
        if (!err.response) {
            throw err;
        }
        return rejectWithValue(err.response.data as ErrorType);
    }
});

export const updateAppointmentStatus = createAsyncThunk('/shop/booking/status', async (payload: any, { rejectWithValue }) => {
    try {
        const response = await axiosPatch(API.BOOKING.UPDATE_STATUS, payload.payload, {
            shop_id: payload.shop_id,
            id: payload.id,
        });
        if (response.data.status === errorCode.success || response.data.status === errorCode.updateSuccess) {
            return response.data;
        }

        return rejectWithValue(response as ErrorType);
    } catch (err: any) {
        if (!err.response) {
            throw err;
        }
        return rejectWithValue(err.response.data as ErrorType);
    }
});

export const blockTimeCreate = createAsyncThunk('/shop/blocktime/create', async (payload: any, { rejectWithValue }) => {
    try {
        const response = await axiosPost(API.STAFF_BLOCK_TIME.CREATE, payload.payload, payload.params);
        if (response.data.status === errorCode.success || response.data.status === errorCode.updateSuccess) {
            return response.data;
        }

        return rejectWithValue(response as ErrorType);
    } catch (err: any) {
        if (!err.response) {
            throw err;
        }
        return rejectWithValue(err.response.data as ErrorType);
    }
});
export const blockTimeUpdate = createAsyncThunk('/shop/blocktime/update', async (payload: any, { rejectWithValue }) => {
    try {
        const response = await axiosPatch(API.STAFF_BLOCK_TIME.UPDATE, payload.payload, payload.params);
        if (response.data.status === errorCode.success || response.data.status === errorCode.updateSuccess) {
            return response.data;
        }

        return rejectWithValue(response as ErrorType);
    } catch (err: any) {
        if (!err.response) {
            throw err;
        }
        return rejectWithValue(err.response.data as ErrorType);
    }
});

// export const blockTimeDelete = createAsyncThunk(
//     '/shop/blocktime/delete',
//     async (payload: any, { rejectWithValue }) => {
//         try {
//             const response = await axiosDelete(
//                 API.STAFF_BLOCK_TIME.UPDATE,
//                 payload.payload,
//                 payload.params,
//             );
//             if (
//                 response.data.status === errorCode.success ||
//                 response.data.status === errorCode.updateSuccess
//             ) {
//                 return response.data;
//             }

//             return rejectWithValue(response as ErrorType);
//         } catch (err: any) {
//             if (!err.response) {
//                 throw err;
//             }
//             return rejectWithValue(err.response.data as ErrorType);
//         }
//     },
// );

export const CalendarSlice = createSlice({
    name: '/shop/calendar',
    initialState,
    reducers: {
        setData: (state, { payload }) => {
            state.data = {
                ...state.data, // Copy existing properties
                ...payload, // Override with new properties
            };
        },
        addEvent: (state, { payload }) => {
            state.data.events.push(payload);
        },
        setOpenSidebar: (state, { payload }) => {
            state.data.isSidebarOpen = payload;
        },
        setBookingInfo: (state, { payload }) => {
            state.data.bookingInfo = payload;
        },
        setClearState: (state) => {
            state.data = { ...initialState.data };
        },
        setClearBooking: (state) => {
            state.data = {
                ...state.data, // Copy existing properties
                ...bookingClearState, // Override with new properties
            };
        },
        setGetBookingList: (state, { payload }) => {
            state.data.getBookingList = payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(bookingList.pending, (state) => {
                state.loading = true;
            })
            .addCase(bookingList.fulfilled, (state, { payload }: any) => {
                state.loading = false;
                const newArray = payload.data.block_times.flatMap((item: any) => generateTimeRanges(item.from, item.to, item));
                const events = [...payload.data.bookings, ...newArray];
                state.data.events = events;
            })
            .addCase(bookingList.rejected, (state, { payload }: any) => {
                state.loading = false;
                state.error = payload.data;
            });
    },
});

export const { reducer: accountReducer } = CalendarSlice;
export const { setData, addEvent, setOpenSidebar, setBookingInfo, setClearBooking, setGetBookingList } = CalendarSlice.actions;

export const selectCalendarData = (state: RootState) => state.CalendarInfo.data;
export const selectCalendarLoading = (state: RootState) => state.CalendarInfo.loading;
export const selectCalendarError = (state: RootState) => state.CalendarInfo.error;
