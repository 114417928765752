import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { API } from 'src/constants/api';
import { axiosPatch } from 'src/utils/requestClient';
import { IInitialState } from './ChangePassword.interface';
import { errorCode } from 'src/constants/errorCode';
import { RootState } from 'src/redux/store';

interface ErrorType {
    message: string;
    status?: string;
    data?: any;
}

const initialState: IInitialState = {
    loading: false,
    data: null,
    error: null,
};

export const profile = createAsyncThunk('shop/profile', async (payload: any, { rejectWithValue }) => {
    try {
        const response = await axiosPatch(API.USER.PROFILE, payload);
        if (response.data.status === errorCode.updateSuccess) {
            return response.data;
        }

        return rejectWithValue(response as ErrorType);
    } catch (err: any) {
        if (!err.response) {
            throw err;
        }

        return rejectWithValue(err.response.data as ErrorType);
    }
});

export const emailUpdate = createAsyncThunk('/shop/email', async (payload: any, { rejectWithValue }) => {
    try {
        const response = await axiosPatch(API.USER.UPDATE_EMAIL, payload);
        if (response.data.status === errorCode.success) {
            return response.data;
        }

        return rejectWithValue(response as ErrorType);
    } catch (err: any) {
        if (!err.response) {
            throw err;
        }

        return rejectWithValue(err.response.data as ErrorType);
    }
});

export const emailVerify = createAsyncThunk('/shop/email/verify', async (payload: any, { rejectWithValue }) => {
    try {
        const response = await axiosPatch(API.USER.VERIFY_EMAIL, payload);
        if (response.data.status === errorCode.success) {
            return response.data;
        }

        return rejectWithValue(response as ErrorType);
    } catch (err: any) {
        if (!err.response) {
            throw err;
        }

        return rejectWithValue(err.response.data as ErrorType);
    }
});

export const phoneUpdate = createAsyncThunk('/shop/phone', async (payload: any, { rejectWithValue }) => {
    try {
        const response = await axiosPatch(API.USER.UPDATE_PHONE, payload);
        if (response.data.status === errorCode.success) {
            return response.data;
        }

        return rejectWithValue(response as ErrorType);
    } catch (err: any) {
        if (!err.response) {
            throw err;
        }

        return rejectWithValue(err.response.data as ErrorType);
    }
});

export const phoneVerify = createAsyncThunk('/shop/phone/verify', async (payload: any, { rejectWithValue }) => {
    try {
        const response = await axiosPatch(API.USER.VERIFY_PHONE, payload);
        if (response.data.status === errorCode.updateSuccess) {
            return response.data;
        }

        return rejectWithValue(response as ErrorType);
    } catch (err: any) {
        if (!err.response) {
            throw err;
        }

        return rejectWithValue(err.response.data as ErrorType);
    }
});

export const ProfileSlice = createSlice({
    name: 'profile',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(profile.pending, (state) => {
                state.loading = true;
            })
            .addCase(profile.fulfilled, (state, { payload }: any) => {
                state.loading = false;
                state.data = payload;
            })
            .addCase(profile.rejected, (state, { payload, error }: any) => {
                state.loading = false;
                state.data = payload;
                if (payload) {
                    state.error = payload;
                } else {
                    state.error = error as ErrorType;
                }
            })
            .addCase(emailUpdate.pending, (state) => {
                state.loading = true;
            })
            .addCase(emailUpdate.fulfilled, (state, { payload }: any) => {
                state.loading = false;
                state.data = payload;
            })
            .addCase(emailUpdate.rejected, (state, { payload, error }: any) => {
                state.loading = false;
                state.data = payload;
                if (payload) {
                    state.error = payload;
                } else {
                    state.error = error as ErrorType;
                }
            })
            .addCase(emailVerify.pending, (state) => {
                state.loading = true;
            })
            .addCase(emailVerify.fulfilled, (state, { payload }: any) => {
                state.loading = false;
                state.data = payload;
            })
            .addCase(emailVerify.rejected, (state, { payload, error }: any) => {
                state.loading = false;
                state.data = payload;
                if (payload) {
                    state.error = payload;
                } else {
                    state.error = error as ErrorType;
                }
            })
            .addCase(phoneUpdate.pending, (state) => {
                state.loading = true;
            })
            .addCase(phoneUpdate.fulfilled, (state, { payload }: any) => {
                state.loading = false;
                state.data = payload;
            })
            .addCase(phoneUpdate.rejected, (state, { payload, error }: any) => {
                state.loading = false;
                state.data = payload;
                if (payload) {
                    state.error = payload;
                } else {
                    state.error = error as ErrorType;
                }
            })
            .addCase(phoneVerify.pending, (state) => {
                state.loading = true;
            })
            .addCase(phoneVerify.fulfilled, (state, { payload }: any) => {
                state.loading = false;
                state.data = payload;
            })
            .addCase(phoneVerify.rejected, (state, { payload, error }: any) => {
                state.loading = false;
                state.data = payload;
                if (payload) {
                    state.error = payload;
                } else {
                    state.error = error as ErrorType;
                }
            });
    },
});

export const selectLoading = (state: RootState) => state.Profile.loading;
export const selectError = (state: RootState) => state.Profile.error;
