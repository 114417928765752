import React from 'react';
import { useTranslation } from 'react-i18next';
import Popup from 'src/components/Popup';

const CommissionPopup = ({ isOpenPopup, handleClose }: any) => {
    const { t } = useTranslation();
    const commissionPopupContent = (
        <>
            <div className="text-center">
                <h3 className="font-bold text-xl -tracking-[0.4px] leading-[140%] mb-3">{t('Commission statement')}</h3>
                <p className="text-secondaryTxtColor  -tracking-[0.3px]">{t('Payment period: 02/12/2022-16/12/2022')}</p>
            </div>
        </>
    );

    return (
        <div>
            {isOpenPopup && (
                <Popup
                    size="max-w-[828px]"
                    className="p-8 pb-10"
                    handleClose={() => {
                        handleClose();
                    }}
                    content={commissionPopupContent}
                />
            )}
        </div>
    );
};

export default CommissionPopup;
