import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { API } from 'src/constants/api';
import { axiosDelete, axiosGet, axiosPatch, axiosPost } from 'src/utils/requestClient';
import { errorCode } from 'src/constants/errorCode';
import { RootState } from 'src/redux/store';

interface ErrorType {
    message: string;
    status?: string;
    data?: any;
}

const initialState: any = {
    list: [],
    loading: false,
    error: null,
    taxes: null,
};

export const locationListAction = createAsyncThunk('location/list', async (payload: any, { rejectWithValue }) => {
    try {
        const response = await axiosGet(API.LOCATION.LIST, payload);
        if (response.data.status === errorCode.success) {
            return response.data;
        }

        return rejectWithValue(response as ErrorType);
    } catch (err: any) {
        if (!err.response) {
            throw err;
        }
        return rejectWithValue(err.response.data as ErrorType);
    }
});
export const createLocationAction = createAsyncThunk('location/create', async (payload: any, { rejectWithValue }) => {
    try {
        const response: any = await axiosPost(API.LOCATION.CREATE, payload, {
            shop_id: payload?.shop_id,
        });
        if (response.data.status === errorCode.updateSuccess) {
            return response.data;
        }

        return rejectWithValue(response as ErrorType);
    } catch (err: any) {
        if (!err.response) {
            throw err;
        }
        return rejectWithValue(err.response.data as ErrorType);
    }
});
export const getSalesTaxAction = createAsyncThunk('location/saleTax', async (payload, { rejectWithValue }) => {
    try {
        const response: any = await axiosGet(API.SALES.LIST);
        if (response.data.status === errorCode.success) {
            return response.data;
        }

        return rejectWithValue(response as ErrorType);
    } catch (err: any) {
        if (!err.response) {
            throw err;
        }
        return rejectWithValue(err.response.data as ErrorType);
    }
});
export const updateLocationAction = createAsyncThunk('location/updateLocationAction', async (payload: any, { rejectWithValue }) => {
    try {
        const response: any = await axiosPatch(API.LOCATION.UPDATE, payload.payload, { shop_id: payload?.payload?.shop_id, id: payload?.id });
        if (response.data.status === errorCode.updateSuccess) {
            return response.data;
        }

        return rejectWithValue(response as ErrorType);
    } catch (err: any) {
        if (!err.response) {
            throw err;
        }
        return rejectWithValue(err.response.data as ErrorType);
    }
});
export const deleteLocationAction = createAsyncThunk('location/deleteLocationAction', async (payload: any, { rejectWithValue }) => {
    try {
        const response: any = await axiosDelete(API.LOCATION.DELETE, undefined, { shop_id: payload?.shop_id, id: payload?.id });
        if (response.data.status === errorCode.success) {
            return response.data;
        }

        return rejectWithValue(response as ErrorType);
    } catch (err: any) {
        if (!err.response) {
            throw err;
        }
        return rejectWithValue(err.response.data as ErrorType);
    }
});

export const locationSlice = createSlice({
    name: 'shop/location',
    initialState,
    reducers: {
        setListing: (state, action) => {
            state.list = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(locationListAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(locationListAction.fulfilled, (state, { payload }: any) => {
                state.loading = false;
                state.list = payload.data;
            })
            .addCase(locationListAction.rejected, (state, action: any) => {
                state.loading = false;
            })
            .addCase(getSalesTaxAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(getSalesTaxAction.fulfilled, (state, { payload }: any) => {
                state.loading = false;
                state.taxes = payload?.data;
            })
            .addCase(getSalesTaxAction.rejected, (state, action: any) => {
                state.loading = false;
            })
            .addCase(createLocationAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(createLocationAction.fulfilled, (state, { payload }: any) => {
                state.loading = false;
            })
            .addCase(createLocationAction.rejected, (state, action: any) => {
                state.loading = false;
            })
            .addCase(updateLocationAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(updateLocationAction.fulfilled, (state, { payload }: any) => {
                state.loading = false;
            })
            .addCase(updateLocationAction.rejected, (state, action: any) => {
                state.loading = false;
            })
            .addCase(deleteLocationAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(deleteLocationAction.fulfilled, (state, { payload }: any) => {
                state.loading = false;
            })
            .addCase(deleteLocationAction.rejected, (state, action: any) => {
                state.loading = false;
            });
    },
});

export const { setListing } = locationSlice.actions;

export const selectListing = (state: RootState) => state.Location.list;
export const selectTaxes = (state: RootState) => state.Location.taxes;
export const selectLoading = (state: RootState) => state.Location.loading;
export const selectError = (state: RootState) => state.Location.error;
