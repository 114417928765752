import React from 'react';

import { IoMdClose } from 'react-icons/io';
import { IoBan } from 'react-icons/io5';
import { SlArrowRight } from 'react-icons/sl';
import { TfiPencilAlt } from 'react-icons/tfi';

export const appointmentStatus = [
    { label: 'Pending', value: 'pending' },
    { label: 'Confirmed', value: 'confirmed' },
    { label: 'Declined', value: 'declined' },
];

export const actionBtnIcon = [
    {
        label: <span>Cancel / Delete</span>,
        value: 'cancel_appointment',
        icon1: <IoMdClose className="text-dangerText" size={20} />,
        icon1Position: 'left',
        icon2: <SlArrowRight className="text-secondaryTxtColor" />,
        icon2Position: 'right',
    },
    {
        label: <span>Decline</span>,
        value: 'declined_appointment',
        icon1: <IoBan className="text-dangerText" size={20} />,
        icon1Position: 'left',
        icon2: <SlArrowRight className="text-secondaryTxtColor" />,
        icon2Position: 'right',
    },
    {
        label: <span>Edit appointment</span>,
        value: 'edit_appointment',
        icon1: <TfiPencilAlt className="text-primary" size={20} />,
        icon1Position: 'left',
        icon2: <SlArrowRight className="text-secondaryTxtColor" />,
        icon2Position: 'right',
    },
    {
        label: <span>Reschedule appointment</span>,
        value: 'reschedule_appointment',
        icon1: <TfiPencilAlt className="text-primary" size={20} />,
        icon1Position: 'left',
        icon2: <SlArrowRight className="text-secondaryTxtColor" />,
        icon2Position: 'right',
    },
];
