import React from 'react';
import Footer from '../Footer';
import Header from './Header';

const SimpleLayout = ({ children }: any) => (
    <div className="min-h-screen">
        <Header />
        <div className="wrapper_content_step flex justify-center">
            <div className="flex flex-col items-center min-h-full w-full">{children}</div>
        </div>
        <Footer />
    </div>
);

export default SimpleLayout;
