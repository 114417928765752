import React from 'react';
import { IInputWithToggle } from './IInputWithToggle.interface';

const InputWithToggle: React.FC<IInputWithToggle> = ({
    error,
    onChange,
    value,
    name,
    placeholder,
    label,
    type,
    index,
    readOnly,
    className,
    toggleValue,
    toggleClassName,
    setselected,
    disabled = false,
    max,
}): JSX.Element => {
    const toggleChange = (val: string) => {
        setselected(val);
    };
    return (
        <div className={` flex flex-col  w-full`}>
            {type !== 'hidden' && label && (
                <label htmlFor={name} className="fl-field-title-label">
                    {label}
                </label>
            )}
            <div className="flex bg-inputBg rounded-full p-1 h-[48px] text-sm focus:border focus:border-primary">
                <input
                    className={`flex-1 w-full border-0 bg-transparent text-sm focus:border-0 focus:ring-0 ${className} ${error && 'is-invalid'} `}
                    type={type || 'text'}
                    name={name}
                    value={value}
                    disabled={disabled}
                    onChange={(e) => onChange(e, index)}
                    placeholder={placeholder}
                    readOnly={readOnly}
                    max={max}
                />
                <div className="flex items-center justify-center text-secondaryTxtColor">
                    <span
                        className={`flex items-center justify-center font-medium w-10 h-10 basis-10 grow-0 shrink-0 rounded-2xl cursor-pointer  ${toggleValue === '$' ? toggleClassName : ''}`}
                        onClick={() => toggleChange('$')}
                    >
                        $
                    </span>

                    <span
                        className={`flex items-center justify-center font-medium w-10 h-10 basis-10 grow-0 shrink-0 rounded-2xl cursor-pointer ${toggleValue === '%' ? toggleClassName : ''}`}
                        onClick={() => toggleChange('%')}
                    >
                        %
                    </span>
                </div>
            </div>
        </div>
    );
};

export default InputWithToggle;
