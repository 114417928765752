import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Images } from 'src/theme';
import { API } from 'src/constants/api';
import { axiosPost } from 'src/utils/requestClient';
import { errorCode } from 'src/constants/errorCode';
import { toast } from 'react-toastify';
import Drawer from 'src/components/Drawer';
import BarLoader from 'src/components/BarLoader';
import { DataTable } from 'primereact/datatable';
import { FilterMatchMode } from 'primereact/api';
import { Column } from 'primereact/column';
import { selectShopInfo } from 'src/app/Auth/Login/Login.slice';
import { useAppSelector } from 'src/redux/hooks';
import { capitalizeFirstLetterAndUnderscore, capitalizeFirstLetterSpace, getShortName, getStatusClass } from 'src/utils/global-functions';
import { Tooltip } from 'flowbite-react';
import { RxInfoCircled } from 'react-icons/rx';
import { useTranslation } from 'react-i18next';
import { usePhone } from 'src/hooks/usePhone';
interface IProps {
    isOpen: boolean;
    handleClose: () => void;
}
const LatestBookingActivityDrawer: React.FC<IProps> = ({ isOpen, handleClose }) => {
    const { getCustomFormatPhone } = usePhone();
    const [loading, setLoading] = useState(false);
    const [upcomingAppointment, setUpcomingAppointment] = useState<any[]>([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const shop = useAppSelector(selectShopInfo);
    const shopId = shop.id;
    const { t } = useTranslation();
    const [lazyState, setlazyState] = useState({
        first: 0,
        rows: 11,
        page: 1,
        sortField: null,
        sortOrder: null,
        filters: {
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        },
    });
    const onPage = (event: any) => {
        event.page = event.page + 1;
        setlazyState(event);
    };
    useEffect(() => {
        if (isOpen) {
            loadLazyData();
        }
    }, [lazyState, isOpen]);

    const loadLazyData = async () => {
        try {
            setLoading(true);

            const payloadData = {
                data: { ...lazyState, ...{ type: 'past' } },
                param: {
                    shop_id: shopId,
                },
            };
            const response = await axiosPost(API.BOOKING.LIST, payloadData.data, payloadData.param);
            if (response.data?.status === errorCode.success || response.data?.status === errorCode.updateSuccess) {
                setTotalRecords(response.data.data.totalRecords);
                setUpcomingAppointment(response.data.data.data);
                setLoading(false);
                return;
            }

            throw response.data;
        } catch (err: any) {
            toast.error(err?.message);
        } finally {
            setLoading(false);
        }
    };
    const getService = (row: any) => {
        const serviceData = row.booking_services;
        const serviceNames = serviceData.map((entry: any) => entry.staff_service.service.name);
        const servicesString = serviceNames.join(', ');

        return (
            <div className="text-xs font-normal leading-[140%] -tracking-[0.12px] flex items-center">
                <div className="mr-1">{serviceNames[0].length > 15 ? `${serviceNames[0].slice(0, 15)} ...` : serviceNames[0]}</div>
                {serviceNames.length > 1 || serviceNames[0].length > 15 ? (
                    <Tooltip className="bg-mainTextColor text-xs" content={servicesString}>
                        <RxInfoCircled className="text-sm" />
                    </Tooltip>
                ) : (
                    ''
                )}
            </div>
        );
    };
    const getStatus = (row: any) => {
        const statusClass = getStatusClass(row.status);
        return <span className={`text-xs font-normal leading-[140%] -tracking-[0.12px] ${statusClass}`}>{capitalizeFirstLetterAndUnderscore(row.status)}</span>;
    };

    const GetFullName = (row: any) => {
        const [isImageError, setIsImageError] = useState<boolean>(false);
        return (
            <div className="flex items-center">
                <figure className="w-[38px] h-[38px] basis-[38px] grow-0 shrink-0 rounded-full overflow-hidden mr-3 flex text-xs font-medium justify-center items-center bg-primary text-white">
                    {row.user.profile_image_url && !isImageError ? (
                        <img src={row.user.profile_image_url} alt="client" className="w-full h-full object-cover" onError={() => setIsImageError(true)} />
                    ) : row.user.full_name ? (
                        getShortName(`${row.user.full_name}`)
                    ) : (
                        '+'
                    )}
                </figure>
                <div className="">
                    <p className="text-sm font-medium mb-[2px] leading-[140%] -tracking-[0.12px]">
                        {row.user.full_name ? `${capitalizeFirstLetterSpace(`${row.user.full_name}`)}` : `${getCustomFormatPhone(row.user.phone, row.user.phone_country_code)}`}
                    </p>
                    <p className="text-xs font-medium leading-[150%] -tracking-[0.12px] text-secondaryTxtColor">
                        {moment(`${row.booking_date}' ' ${row.booking_start_time}`, 'YYYY-MM-DD HH:mm:ss').format('DD MMM YYYY • hh:mmA')}
                    </p>
                </div>
            </div>
        );
    };

    const GetLocation = (row: any) => (
        <div className="text-xs text-primary font-medium leading-[140%] -tracking-[0.12px] flex items-center">
            <i className="mr-1 w-4 h-4">
                <img src={Images.mapLocationPin} alt="map-pin" className="w-4 h-4" />
            </i>
            <p className="text-sm font-medium mb-[2px] leading-[140%] -tracking-[0.12px]">{row.location.name}</p>
        </div>
    );
    const customPaginatorTemplate = {
        layout: 'CurrentPageReport PrevPageLink PageLinks NextPageLink',
        CurrentPageReport: ({ first, last, totalRecords: totalRecordsNum }: { first: number; last: number; totalRecords: number }) => (
            <div className="text-mainTextColor flex items-center font-[SF-Pro] text-sm mr-auto">
                <span>
                    {first} - {last} of {totalRecordsNum}
                </span>
            </div>
        ),
    };
    return (
        <Drawer
            isOpen={isOpen}
            handleClose={handleClose}
            title={'Latest Booking Activity'}
            isEditable
            isEditClose
            handleClickOutside={handleClose}
            classDrawer="your-custom-styles"
            drawerHeight="your-height-value"
        >
            <div className="pt-5 w-full">
                <DataTable
                    paginatorClassName="flex relative justify-end"
                    paginatorTemplate={customPaginatorTemplate}
                    value={upcomingAppointment}
                    paginator={totalRecords > lazyState.rows}
                    lazy
                    first={lazyState.first}
                    rows={lazyState.rows}
                    totalRecords={totalRecords}
                    loading={loading}
                    onPage={onPage}
                    sortOrder={lazyState.sortOrder}
                    filters={lazyState.filters}
                    dataKey="id"
                    className="font-[SF-Pro] text-sm flex-1 text-mainTextColor rounded-3xl overflow-hidden fl-data-table"
                    loadingIcon={<BarLoader />}
                    globalFilterFields={['Client', 'Service', 'Location', 'Price', 'Professional', 'Status']}
                    emptyMessage="No records found."
                >
                    <Column field="Client" header={t('Client')} body={GetFullName} className=" font-normal text-mainTextColor"></Column>
                    <Column field="service" header={t('Services')} body={getService} className="text-xs font-normal"></Column>
                    <Column field="Location" header={t('Location')} body={GetLocation} className=" font-medium text-mainTextColor"></Column>
                    <Column field="Price" header={t('Price')} className="text-mainTextColor" body={(row: any) => `$${row.total}`}></Column>
                    <Column
                        field="Professional"
                        header={t('Professional')}
                        className="font-medium text-mainTextColor"
                        body={(row: any) => `${capitalizeFirstLetterSpace(`${row?.staff?.full_name}`)}`}
                    ></Column>
                    <Column field="Status" header={t('Status')} className="font-medium text-mainTextColor" body={(row: any) => getStatus(row)}></Column>
                </DataTable>
            </div>
        </Drawer>
    );
};

export default LatestBookingActivityDrawer;
