import React, { memo, useEffect, useRef, useState } from 'react';
import { HiChevronDown, HiChevronUp } from 'react-icons/hi';

import { IDropdownTick } from './DropdownTick.interface';

const DropdownTick: React.FC<IDropdownTick> = ({
    label,
    data,
    setselected,
    wraperClassName,
    className,
    subSwitch,
    handleItemClick,
    setSubSwitch,
    dropDownMenuClassName,
    dropDownMenuItemClassName,
    dropDownMenuLinkClassName,
    labelActiveIcon,
    labelIcon,
}) => {
    const [Switch, setSwitch] = useState(false);

    useEffect(() => {
        const handler = () => {
            setSwitch(false);
            setSubSwitch?.(false);
        };
        window.addEventListener('click', handler);
        return () => {
            window.removeEventListener('click', handler);
        };
    }, []);

    const handlerInputClick = (e: any) => {
        e.stopPropagation();
        setSwitch(!Switch);
        setSubSwitch?.(!subSwitch || false);
    };

    const onItemClick = (e: any, itemValue: any) => {
        setselected(itemValue);
        handleItemClick && handleItemClick(e);
    };
    const divRef = useRef<HTMLDivElement>(null);
    const closeHandler = () => {
        setSwitch(false);
        setSubSwitch?.(false);
    };
    useEffect(() => {
        // Function to handle click outside the div
        const handleClickOutside = (event: MouseEvent) => {
            if (divRef.current && !divRef.current.contains(event.target as Node)) {
                closeHandler();
            }
        };

        // Add event listener when the component mounts
        document.addEventListener('mousedown', handleClickOutside);

        // Remove the event listener when the component unmounts
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [divRef]);
    return (
        <div className={`relative ${wraperClassName}`} ref={divRef}>
            <div className="flex items-center" onClick={handlerInputClick}>
                <div className={className}>
                    {label ? (
                        <>
                            <span className="text">{label}</span>
                            <i className="icon ml-2 text-xl">{Switch || subSwitch ? <HiChevronUp /> : <HiChevronDown />}</i>
                        </>
                    ) : labelActiveIcon && labelIcon ? (
                        <span className="text">{!Switch ? labelActiveIcon : labelIcon}</span>
                    ) : labelIcon ? (
                        <span className="text">{labelIcon}</span>
                    ) : (
                        ''
                    )}
                </div>
            </div>
            {Switch && (
                <div id="dropdownDelay" className={`z-10 absolute  shadow-DropDown  bg-white divide-y divide-gray-100 rounded-3xl  p-5  right-0 ${dropDownMenuClassName}`}>
                    <ul className="text-sm flex flex-col " aria-labelledby="dropdownDelayButton">
                        {data.map((items: any, index: number) => (
                            <li className={`font-medium py-[10px] first:pt-0 last:pb-0 ${dropDownMenuItemClassName}`} key={index} value={items}>
                                <div className={`cursor-pointer hover:text-primary  ${dropDownMenuLinkClassName}`} onClick={(e) => onItemClick(e, items)}>
                                    {items}
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default memo(DropdownTick);
