import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { CountryCode, parse } from 'libphonenumber-js';

import Button from 'src/components/Button';
import InputWithLabel from 'src/components/InputWithLabel';
import Number from 'src/components/Number';
import SelectBox from 'src/components/SelectBox/SelectBox';
import { IProps } from './Staff.interface';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { selectUserInfo } from '../Auth/Login/Login.slice';
import { createRole, removeRole, roleList } from './Staff.slice';
import { getSelectBoxOptionsIdAsValue } from 'src/utils/global-functions';
import CustomSelectDropdown from 'src/components/CustomSelectDropdown';
import { errorCode } from 'src/constants/errorCode';
import { useTranslation } from 'react-i18next';
const optionAccType = [{ label: 'Service Provider', value: 'service_provider' }];
type FieldKey = 'first_name' | 'last_name' | 'email';

const Add = ({ onClick, customError, setCustomError }: IProps) => {
    const { t } = useTranslation();
    const { control, formState, setValue, getValues } = useFormContext();
    const roleId = getValues().shop_staff_role_id;
    const { clearErrors } = useFormContext();
    const handleInputChange = (name: FieldKey, onChange: any) => (event: any) => {
        onChange(event);
        clearErrors(name);
    };

    const errors: any = formState.errors;
    const [roleListArray, setRoleListArray] = useState([]);
    const user = useAppSelector(selectUserInfo);
    const shopId = user.shops[0].id;
    const dispatch = useAppDispatch();
    const [createRoleError, setCreateRoleError] = useState('');
    const [selectedValue, setSelectedValue] = useState('Select Role');
    const [handleCloseDropdown, setHandleCloseDropdown] = useState(false);

    const getRoleList = async () => {
        const data = {
            shop_id: shopId,
        };
        const result = await dispatch(roleList(data));
        if (result.type === roleList.fulfilled.toString()) {
            const roleData: any = getSelectBoxOptionsIdAsValue(result.payload.data);
            if (roleId) {
                const matchedRole = roleData.find((role: any) => role.id === roleId);
                setSelectedValue(matchedRole?.label);
            }

            setRoleListArray(roleData);
        }
    };

    const handleNumberChange = (
        country: CountryCode,
        phone: string,
        code: string,
        isValid: boolean,
        // country: CountryCode,
        // phone: string,
        // isValid: boolean,
    ) => {
        if (isValid) {
            setValue(`phone`, `+${code}${phone}`);
            setValue(`phone_country_code`, country);
        } else {
            setValue(`phone`, '');
            setCustomError && setCustomError('');
        }

        clearErrors('phone');
    };

    const handleRemoveClick = async (id: number) => {
        const data = {
            shop_id: shopId,
            id: id,
        };
        const result = await dispatch(removeRole(data));
        if (result.type === removeRole.fulfilled.toString()) {
            setValue('shop_staff_role_id', null);
            getRoleList();
            setHandleCloseDropdown(true);
        }
    };
    useEffect(() => {
        getRoleList();
    }, []);

    const handleItemClick = (value: any) => {
        setValue('shop_staff_role_id', value.id);
        setHandleCloseDropdown(true);
    };
    const handleAddClick = async (value: string) => {
        const submitData = {
            payload: { name: value },
            shop_id: shopId,
        };
        setCreateRoleError('');
        const result = await dispatch(createRole(submitData));
        if (result.type === createRole.fulfilled.toString()) {
            setValue('shop_staff_role_id', result.payload.data.id);
            getRoleList();
        }

        if (result.type === createRole.rejected.toString()) {
            const response = result.payload.data;
            if (response.status === errorCode.unprocessable) {
                setCreateRoleError(response.data.name[0]);
            }
        }
    };

    return (
        <>
            <div className="mb-10 text-center ">
                <h3 className="fwpo_heading_title w-[480px] mx-auto">{t('Add new staff')}</h3>
            </div>
            <div className="h-full flex flex-wrap -mx-[10px]">
                <div className="px-[10px] mb-5 w-1/2">
                    <Controller
                        name="first_name"
                        control={control}
                        render={({ field: { onChange, value } }: any) => (
                            <div className="w-full">
                                <InputWithLabel
                                    label={t('First Name')}
                                    placeholder={t('Enter Member First Name')}
                                    onChange={handleInputChange('first_name', onChange)}
                                    isFocus={true}
                                    value={value}
                                    name="first_name"
                                    error={!!errors.first_name}
                                />
                                {errors && errors?.first_name && <p className="text-error">{errors?.first_name?.message}</p>}
                            </div>
                        )}
                    />
                </div>
                <div className="px-[10px] mb-5 w-1/2">
                    <Controller
                        name="last_name"
                        control={control}
                        render={({ field: { onChange, value } }: any) => (
                            <div className="w-full">
                                <InputWithLabel
                                    label={t('Last Name')}
                                    placeholder={t('Enter Member Last Name')}
                                    onChange={handleInputChange('last_name', onChange)}
                                    value={value}
                                    name="last_name"
                                    error={!!errors.last_name}
                                />
                                {errors?.last_name && <p className="text-error">{errors?.last_name?.message}</p>}
                            </div>
                        )}
                    />
                </div>
                <div className="px-[10px] mb-5 w-1/2">
                    <label htmlFor="phone" className="fl-field-title-label">
                        {t('Phone Number')}
                    </label>
                    <Controller
                        name="phone"
                        control={control}
                        render={({ field: { onChange, value } }: any) => {
                            const parsedPhoneNumber = parse(value, getValues().phone_country_code);
                            const defaultValues = {
                                phone: parsedPhoneNumber.phone,
                                phone_country_code: parsedPhoneNumber.country,
                            };

                            return (
                                <>
                                    <div className="flex flex-col ">
                                        <Number getNumber={onChange} errors={errors} value={value} onNumberChange={handleNumberChange} defaultValues={defaultValues} />
                                    </div>

                                    {!errors.phone && !errors.phone_country_code && customError && <p className="text-error">{customError}</p>}
                                </>
                            );
                        }}
                    />
                    {/* {errors && errors?.phone && (
                        <p className="text-error">{errors?.phone?.message}</p>
                    )} */}
                </div>
                <div className="px-[10px] w-1/2">
                    <Controller
                        name="email"
                        control={control}
                        render={({ field: { onChange, value } }: any) => (
                            <div className="w-full">
                                <InputWithLabel
                                    label={t('E-mail')}
                                    placeholder={t('Enter member email')}
                                    onChange={handleInputChange('email', onChange)}
                                    value={value}
                                    name="email"
                                    error={!!errors.email}
                                />
                                {errors?.email && <p className="text-error">{errors?.email?.message}</p>}
                            </div>
                        )}
                    />
                </div>
                <div className="px-[10px] mb-5 w-1/2">
                    <Controller
                        name="account_type"
                        control={control}
                        render={({ field: { onChange, value } }: any) => (
                            <div className="w-full">
                                <SelectBox
                                    name="account_type"
                                    label={t('Account type')}
                                    options={optionAccType}
                                    value={optionAccType}
                                    errorText={errors.account_type && true}
                                    onChangeFunc={(selectedOption: any) => onChange(selectedOption.value)}
                                    placeholder={t('Select account type')}
                                />
                                {errors.account_type && <p className="text-error">{errors.account_type.message}</p>}
                            </div>
                        )}
                    />
                </div>
                <div className="px-[10px] mb-5 w-1/2">
                    <Controller
                        name="shop_staff_role_id"
                        control={control}
                        render={({ field: { onChange, value } }: any) => (
                            <div className="w-full">
                                <CustomSelectDropdown
                                    data={roleListArray}
                                    className={`bg-inputBg text-sm h-[48px] px-4 py-2 w-full border border-inputBg tracking-tight rounded-full text-center flex items-center focus:border-primary 
                                    ${errors.shop_staff_role_id && selectedValue === 'Select Role' && 'is-invalid'}`}
                                    label={selectedValue}
                                    dropDownMenuClassName="w-full"
                                    setselected={setSelectedValue}
                                    handleItemClick={handleItemClick}
                                    handleAddClick={(value2: string) => {
                                        handleAddClick(value2);
                                    }}
                                    handleCloseDropdown={handleCloseDropdown}
                                    createRoleError={createRoleError}
                                    handleRemoveClick={(id: number) => handleRemoveClick(id)}
                                />
                                {selectedValue === 'Select Role' && errors.shop_staff_role_id && <p className="text-error">{errors.shop_staff_role_id.message}</p>}
                            </div>
                        )}
                    />
                </div>
            </div>
            <div className="flex w-full px-10 justify-center mt-10">
                <Button className="fl-btn btn_primary w-[420px] " type="submit" onClick={onClick}>
                    {t('Continue')}
                </Button>
            </div>
        </>
    );
};

export default Add;
