/* eslint-disable max-lines-per-function */
import React from 'react';
import Button from 'src/components/Button';
import OpeningHour from '../Signup/WorkingHours/OpningHour';

const OpeningHours = ({ setStep, handleClick }: any) => (
    <div className="flex flex-col h-full justify-between">
        <div className="">
            <div className="mb-10 text-center ">
                <h3 className="fwpo_heading_title w-[480px] mx-auto mb-4">Working hours</h3>
                <p className="text-secondaryTxtColor leading-[140%] -tracing-[0.3px] ">
                    Opening hours for these locations are default working hours for your team and will be visible to your clients.
                </p>
            </div>
            <OpeningHour />
        </div>

        <div className="flex w-full px-10 justify-center mt-10">
            <Button
                className="fl-btn btn_primary w-[420px] 2xl:w-[572px]"
                // disabled={loading}
                onClick={handleClick}
                type="button"
            >
                Continue
            </Button>
        </div>
    </div>
);

export default OpeningHours;
