import React from 'react';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import Button from 'src/components/Button';
import InputPasswordWithLabel from 'src/components/InputPasswordWithLabel';
import { useAppDispatch } from 'src/redux/hooks';
import { changePassword } from './ChangePassword.slice';
import { errorCode } from 'src/constants/errorCode';
import { IFieldKey, IPasswordField } from './ChangePassword.interface';
import { useTranslation } from 'react-i18next';

const ChangePassword = () => {
    const { t } = useTranslation();

    const dispatch = useAppDispatch();

    const schema = Yup.object({
        old_password: Yup.string().required(t('Old password is a required')),
        password: Yup.string().required(t('Password is a required')),
        password_confirmation: Yup.string().required(t('Password confirmation is a required')),
    }).required();

    const {
        handleSubmit,
        control,
        formState: { errors },
        setError,
        reset,
    } = useForm<IPasswordField>({
        resolver: yupResolver(schema),
        defaultValues: {
            old_password: '',
            password: '',
            password_confirmation: '',
        },
    });

    const handleSave = async (data: IPasswordField) => {
        const result: any = await dispatch(changePassword(data));
        if (result.type === changePassword.fulfilled.toString()) {
            reset();
            // toast.success(result.payload.data);
        }
        if (result.type === changePassword.rejected.toString()) {
            const response = result.payload.data.data;
            if (response.status === errorCode.unprocessable) {
                if (response.data) {
                    Object.keys(response.data).forEach((field) => {
                        setError(field as IFieldKey, {
                            type: 'manual',
                            message: response.data[field][0],
                        });
                    });
                }
                return;
            }
            // toast.error(response.message);
        }
    };

    return (
        <form onSubmit={handleSubmit(handleSave)}>
            <div className="flex flex-wrap">
                <Controller
                    name="old_password"
                    control={control}
                    render={({ field: { onChange, value } }: any) => (
                        <div className="flex flex-col w-full">
                            <InputPasswordWithLabel
                                name="old_password"
                                label={t('Current password')}
                                placeholder={t('Current password')}
                                value={value}
                                onChange={onChange}
                                error={!!errors.old_password}
                            />
                            {errors.old_password && <p className="text-error">{errors.old_password.message}</p>}
                        </div>
                    )}
                />
                <Controller
                    name="password"
                    control={control}
                    render={({ field: { onChange, value } }: any) => (
                        <div className="flex flex-col w-full mt-5">
                            <InputPasswordWithLabel name="password" label={t('New password')} placeholder={t('New password')} value={value} onChange={onChange} error={!!errors.password} />
                            {errors.password && <p className="text-error">{errors.password.message}</p>}
                        </div>
                    )}
                />
                <Controller
                    name="password_confirmation"
                    control={control}
                    render={({ field: { onChange, value } }: any) => (
                        <div className="flex flex-col w-full mt-5">
                            <InputPasswordWithLabel
                                name="password_confirmation"
                                label={t('Confirm password')}
                                placeholder={t('Confirm password')}
                                value={value}
                                onChange={onChange}
                                error={!!errors.password_confirmation}
                            />
                            {errors.password_confirmation && <p className="text-error">{errors.password_confirmation.message}</p>}
                        </div>
                    )}
                />
            </div>
            <Button className={`fl-btn btn_primary mt-5`}>{t('Save changes')}</Button>
        </form>
    );
};

export default ChangePassword;
