import React, { useEffect, useMemo, useState } from 'react';
import Drawer from 'src/components/Drawer';
import { IProps, IMenu } from './AdminDetails.interface';
import Menu from './Menu';
import Info from './Info';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { selectUserInfo } from 'src/app/Auth/Login/Login.slice';
import { getAdminDetails } from '../Admin.slice';

import BarLoader from 'src/components/BarLoader';
const AdminDetails = ({ isDrawerOpen, handleDrawer, adminId, getAdminList }: IProps) => {
    const user = useAppSelector(selectUserInfo);
    const shopId = user.shops[0].id;
    const dispatch = useAppDispatch();

    const [adminInfo, setAdminInfo] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [activeStep, setActiveStep] = useState(1);
    const [isEditProfile, setIsEditProfile] = useState(false);
    const getAdminDetailFun = async () => {
        const data = {
            shop_id: shopId,
            id: adminId,
        };
        const result = await dispatch(getAdminDetails(data));
        if (result.type === getAdminDetails.fulfilled.toString()) {
            setAdminInfo(result.payload.data);
        }
        setIsLoading(false);
    };

    useEffect(() => {
        setIsLoading(true);
        setActiveStep(1);
        adminId && getAdminDetailFun();
    }, [adminId]);

    const MENU: IMenu[] = useMemo(
        () => [
            {
                name: 'Admin details',
                step: 1,
            },
        ],
        [],
    );

    const renderStepComponent = () => {
        if (activeStep === 1 && Object.keys(adminInfo).length > 0) {
            return <Info adminInfo={adminInfo} shopId={shopId} isEditProfile={isEditProfile} setIsEditProfile={setIsEditProfile} getAdminList={getAdminList} getAdminDetailFun={getAdminDetailFun} />;
        } else {
            return null;
        }
    };

    return (
        <Drawer
            isOpen={isDrawerOpen}
            isDisplayLoading={true}
            isLoading={isLoading}
            handleClose={() => {
                handleDrawer(false);
                setIsEditProfile(false);
            }}
            title="Admin Details"
            isEditable
            isEditClose
            handleClickOutside={() => {
                handleDrawer(false);
                setIsEditProfile(false);
            }}
            classDrawer="your-custom-styles"
            drawerHeight="your-height-value"
        >
            {!isLoading ? (
                <>
                    <Menu
                        menuList={MENU}
                        setActiveStep={setActiveStep}
                        adminInfo={adminInfo}
                        handleClose={() => handleDrawer(false)}
                        activeStep={activeStep}
                        setIsEditProfile={setIsEditProfile}
                        getAdminList={getAdminList}
                    />
                    {renderStepComponent()}
                </>
            ) : (
                <BarLoader className="p-0" />
            )}
        </Drawer>
    );
};

export default AdminDetails;
