import { Dispatch, SetStateAction } from 'react';
import { ICountStep } from 'src/components/Stepper/Stepper.interface';

export interface IFormData {
    hours?:
        | {
              day: string;
              status: NonNullable<boolean | undefined>;
              from_time_hours: string;
              from_time_minute: string;
              to_time_hours: string;
              to_time_minute: string;
              from_time_type: 'am' | 'pm';
              to_time_type: 'am' | 'pm';
          }[]
        | null
        | undefined;
    id: number;
}

export interface IPops {
    setStep: Dispatch<SetStateAction<ICountStep>>;
}
export interface IWorkingHoursForm {
    id: number;
    hours: IWorkingHoursValue[] | [];
}
export interface IWorkingHoursValue {
    day: 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday' | 'sunday';
    status: NonNullable<boolean | undefined>;
    from_time_hours: string;
    from_time_minute: string;
    to_time_hours: string;
    to_time_minute: string;
    from_time_type: 'am' | 'pm';
    to_time_type: 'am' | 'pm';
}
export interface ErrorType {
    message: string;
    status?: string;
    data?: any;
}
export interface IInitialState {
    data: any;
    loading: boolean;
    error:
        | {
              message: string | undefined;
              status?: string | undefined;
          }
        | undefined
        | null;
}
export const workingHoursData: IWorkingHoursValue[] = [
    {
        day: 'monday',
        status: false,
        from_time_hours: '00',
        from_time_minute: '00',
        to_time_hours: '00',
        to_time_minute: '00',
        from_time_type: 'am',
        to_time_type: 'pm',
    },
    {
        day: 'tuesday',
        status: false,
        from_time_hours: '00',
        from_time_minute: '00',
        to_time_hours: '00',
        to_time_minute: '00',
        from_time_type: 'am',
        to_time_type: 'pm',
    },
    {
        day: 'wednesday',
        status: false,
        from_time_hours: '00',
        from_time_minute: '00',
        to_time_hours: '00',
        to_time_minute: '00',
        from_time_type: 'am',
        to_time_type: 'pm',
    },
    {
        day: 'thursday',
        status: false,
        from_time_hours: '00',
        from_time_minute: '00',
        to_time_hours: '00',
        to_time_minute: '00',
        from_time_type: 'am',
        to_time_type: 'pm',
    },
    {
        day: 'friday',
        status: false,
        from_time_hours: '00',
        from_time_minute: '00',
        to_time_hours: '00',
        to_time_minute: '00',
        from_time_type: 'am',
        to_time_type: 'pm',
    },
    {
        day: 'saturday',
        status: false,
        from_time_hours: '00',
        from_time_minute: '00',
        to_time_hours: '00',
        to_time_minute: '00',
        from_time_type: 'am',
        to_time_type: 'pm',
    },
    {
        day: 'sunday',
        status: false,
        from_time_hours: '00',
        from_time_minute: '00',
        to_time_hours: '00',
        to_time_minute: '00',
        from_time_type: 'am',
        to_time_type: 'pm',
    },
];
