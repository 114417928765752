import React from 'react';
import { ImSpinner2 } from 'react-icons/im';

const Button = ({ onClick, children, className, form, disabled, isLoading = false, type = 'submit' }: any) => (
    <button
        disabled={disabled}
        onClick={onClick}
        className={`text-base  ${className} ${isLoading ? ' justify-center items-center ' : ''}${(disabled || isLoading) && 'opacity-50'}`}
        type={type}
        form={form || null}
    >
        <div className="relative h-full flex items-center justify-center">
            {isLoading && (
                <i className="relative btn-spin-icon">
                    <ImSpinner2
                        style={{
                            color: '#808080',
                        }}
                    />
                </i>
            )}
            {children}
        </div>
    </button>
);

export default Button;
