/* eslint-disable complexity */
import React, { memo, useEffect, useLayoutEffect } from 'react';
import { getCountries, getCountryCallingCode } from 'react-phone-number-input/input';
import { FaAngleDown, FaCheck } from 'react-icons/fa6';
import en from 'react-phone-number-input/locale/en.json';
import { Service } from 'src/components/AddForm/AddForm.interface';
// import { Images } from 'src/theme';
import { usePhone } from 'src/hooks/usePhone';

import { INumberList, ISelectedNumber } from './Number.interface';
import { CountryCode, formatIncompletePhoneNumber, parseIncompletePhoneNumber } from 'libphonenumber-js';
import IconClose from 'src/assets/img/icons/icon-close.svg';
import { useTranslation } from 'react-i18next';

const Number = ({ serviceList, index, setServiceList, getNumber, onNumberChange, disabled, errors, name, defaultValues, isFocus = false, isButtonClicked }: any) => {
    const { setFormatPhone, isPhoneValid, phoneInfo } = usePhone();
    const { t } = useTranslation();

    const getphoneinfo = phoneInfo(defaultValues?.phone, defaultValues?.phone_country_code);

    const initialSelectedNumber: ISelectedNumber = {
        code: getphoneinfo && getphoneinfo.countryCallingCode ? getphoneinfo.countryCallingCode : '1',
        number: getphoneinfo && getphoneinfo.nationalNumber ? getphoneinfo.nationalNumber : '',
        image: defaultValues && defaultValues.phone_country_code ? require(`src/assets/flags/${defaultValues.phone_country_code}.svg`) : require(`src/assets/flags/CA.svg`).default,
        name: en[defaultValues && defaultValues.phone_country_code ? (defaultValues.phone_country_code as CountryCode) : 'CA'],
        country: defaultValues && defaultValues.phone_country_code ? defaultValues.phone_country_code : 'CA',
    };

    const [Switch, setSwitch] = React.useState(false);
    const [selectedNumber, setSelectedNumber] = React.useState<ISelectedNumber>(initialSelectedNumber);
    const [numberList, setNumberList] = React.useState<INumberList[]>([]);
    const [isInit, setIsInit] = React.useState(true);

    useEffect(() => {
        const handler = () => setSwitch(false);
        window.addEventListener('click', handler);
        return () => {
            window.removeEventListener('click', handler);
        };
    }, []);

    const handlerInputClik = (e: any) => {
        e.stopPropagation();
        setSwitch(!Switch);
    };

    const handleCountryCode = (option: ISelectedNumber) => {
        setSelectedNumber({ ...selectedNumber, ...option });
    };

    const handleInputNumberChange = (e: any) => {
        let newValue = parseIncompletePhoneNumber(e.target.value);
        if (newValue === selectedNumber.number) {
            const newValueFormatted = formatIncompletePhoneNumber(newValue, selectedNumber.country);
            if (newValueFormatted.indexOf(e.target.value) === 0) {
                // Trim the last digit (or plus sign).
                newValue = newValue.slice(0, -1);
            }
        }
        setSelectedNumber({ ...selectedNumber, number: newValue });
    };

    useEffect(() => {
        if (getNumber) {
            getNumber && getNumber(`+${selectedNumber.code}${selectedNumber.number}`);
        }
        if (serviceList) {
            const list: Service['service'] | any = [...serviceList];

            list[index].phonenumber = `+${selectedNumber.code}${selectedNumber.number}`;
            setServiceList(list);
        }
        const isValid = isPhoneValid(selectedNumber.number, selectedNumber.country);
        onNumberChange(selectedNumber.country, selectedNumber.number, selectedNumber.code, isValid);
    }, [selectedNumber.code, selectedNumber.number, selectedNumber.country]); // TODO

    const [filterList, setFilterList] = React.useState<INumberList[]>([]);

    useLayoutEffect(() => {
        const fetchData = async () => {
            let list = getCountries().map((country) => ({
                image: require(`src/assets/flags/${country}.svg`),
                code: getCountryCallingCode(country),
                name: en[country],
                country: country,
            }));
            const priorityCountries = ['Canada', 'United States'];

            const customSortValue = (country: string) => {
                const priorityIndex = priorityCountries.indexOf(country);
                return priorityIndex !== -1 ? priorityIndex : priorityCountries.length + 1;
            };

            const shortedList = list.sort((a, b) => customSortValue(a.name) - customSortValue(b.name) || a.name.localeCompare(b.name));
            setNumberList(shortedList);
            setFilterList(shortedList);
        };

        fetchData();
    }, []);

    const [searchTerm, setSearchTerm] = React.useState('');

    const handleSearchTermClick = (e: { stopPropagation(): unknown; preventDefault: () => void }) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleSearchTermChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const term = e.target.value.toLowerCase();
        setSearchTerm(term);
    };

    const clearSearchTerm = (event: { stopPropagation(): unknown; preventDefault: () => void }) => {
        event.preventDefault();
        event.stopPropagation();
        setSearchTerm('');
    };

    useEffect(() => {
        if (isInit) {
            setIsInit(false);
            return;
        }
        const filteredList = numberList.filter((country) => country.name.toLowerCase().includes(searchTerm));
        if (searchTerm === '') {
            setFilterList(numberList);
        } else {
            setFilterList(filteredList);
        }
    }, [searchTerm]);

    return (
        <div className="relative">
            <div
                className={`fl-input-field fl-phone-field bg-inputBg  border-transparent border ${Switch && ''} ${
                    errors?.phonenumber || errors?.phone || (!isPhoneValid(selectedNumber.number, selectedNumber.country) && isButtonClicked) ? 'is-invalid' : 'bg-inputBg  border-transparent border'
                }`}
            >
                <span className={`fl-flag ${disabled ? '!cursor-default' : ''}`} onClick={handlerInputClik}>
                    <img src={selectedNumber.image} alt="" className="w-7 rounded" />
                    <FaAngleDown className="ml-2" />
                </span>
                <span className="ml-2 mr-1"> +{selectedNumber.code}</span>
                <input
                    className="text-sm py-3 bg-transparent rounded-r-3xl focus:ring-0 focus:ring-offset-0 focus-visible:outline-none w-full border-none"
                    type="text"
                    name={name}
                    disabled={disabled}
                    value={setFormatPhone(selectedNumber.code, selectedNumber.number, selectedNumber.country)}
                    onChange={handleInputNumberChange}
                    placeholder={setFormatPhone(selectedNumber.code, '8708708866', selectedNumber.country)}
                    autoFocus={isFocus}
                    maxLength={20}
                />
            </div>
            {errors && errors.phone ? <p className="text-error">{errors && errors.phone ? errors.phone.message : 'Invalid Phone number'}</p> : ''}
            {!disabled && Switch && (
                <div id="dropdownDelay" className="z-10 absolute top-15  bg-white rounded-3xl  w-full fl-dropdown-shadow">
                    <div className="px-4 pb-2 pt-4 ">
                        <div className="relative w-full">
                            <input
                                type="text"
                                placeholder={t('Search Country')}
                                value={searchTerm}
                                onChange={handleSearchTermChange}
                                onClick={handleSearchTermClick}
                                autoFocus
                                className="p-3 px-4 w-full border-0 rounded-full  bg-inputBg text-sm focus:bottom-0 focus: drop-shadow-none"
                            />
                            <button className="absolute right-3 top-[14px]" onClick={clearSearchTerm}>
                                <img src={IconClose} alt="" className="w-4" />
                            </button>
                        </div>
                    </div>
                    <ul className="country-code-dropdown-list" aria-labelledby="dropdownDelayButton">
                        {filterList.map((country: any, indexNumberCode: number) => (
                            <li
                                onClick={() => handleCountryCode(country)}
                                className={`country-code-dropdown-li ${selectedNumber.name === country.name && 'active'}`}
                                key={indexNumberCode}
                                value={country.code}
                            >
                                <div className="flex ">
                                    <img src={country.image} className="w-6 h-auto max-h-6 rounded-md" alt="" />
                                    <span className="w-10 text-sm font-normal mx-3">+{country.code}</span>
                                    <span className="text-sm  font-normal">{country.name}</span>
                                </div>
                                {selectedNumber.country === country.country && (
                                    // <img
                                    //     src={Tick}
                                    //     className="w-3 "
                                    //     alt=""
                                    // />
                                    <FaCheck size={16} />
                                )}
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default memo(Number);
