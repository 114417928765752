import React from 'react';
import Button from 'src/components/Button';
import Popup from 'src/components/Popup';

const ThemeApplyConfirm = ({ handleThemeApply, handleModalClose }: any) => {
    const content = (
        <div className="text-center">
            <h3 className="mb-5 text-xl font-bold text-mainTextColor -tracking-[0.2px]">Apply theme to all applications</h3>
            <p className="mb-8 text-sm font-normal text-secondaryTxtColor -tracking-[0.2px]">Are you sure you want to apply this set of parameters across all applications?</p>
            <div className="w-full">
                <Button className="w-full fl-btn px-[10px] btn_primary text-base text-center justify-center font-bold mb-3" onClick={handleModalClose}>
                    Not now
                </Button>
                <Button className="w-full fl-btn text-base font-bold btn_outline_black px-[10px] justify-center" onClick={handleThemeApply(0)}>
                    Yes, I&apos;m sure
                </Button>
            </div>
        </div>
    );
    return <Popup size="max-w-[407px]" className="p-8 pb-10" handleClose={handleModalClose} content={content} />;
};

export default ThemeApplyConfirm;
