import _ from 'lodash';
import moment from 'moment';
import { validType } from 'src/constants/fileTypeValidation';
import { LocalStorageKeys } from 'src/constants/keys';
import { ROUTES } from 'src/constants/routes';
import americanCardIcon from 'src/assets/img/cards/american.png';
import defaultCardIcon from 'src/assets/img/cards/credit-card.png';
import dinerCardIcon from 'src/assets/img/cards/diners.png';
import discoverCardIcon from 'src/assets/img/cards/discover.png';
import jcbCardIcon from 'src/assets/img/cards/jcb.png';
import masterCardIcon from 'src/assets/img/cards/mastercard.png';
import unionCardIcon from 'src/assets/img/cards/unionpay.png';
import visaCardIcon from 'src/assets/img/cards/visa.png';
import { useAppSelector } from 'src/redux/hooks';
import { selectRoleInfo } from 'src/redux/RolePermission/RolePermission.slice';
declare global {
    interface Window {
        google: any;
        initMap: () => void;
    }
}
export enum EStripeCardType {
    VISA = 'visa',
    MASTER = 'mastercard',
    AMERICAN = 'amex',
    DINER = 'diners',
    DISCOVER = 'discover',
    JCB = 'jcb',
    UNION = 'unionpay',
}
const logout = () => {
    localStorage.removeItem('persist:root');
    localStorage.removeItem(LocalStorageKeys.authToken);
    window.location.href = `${window.location.origin}/`;
};
const formateAmount = (amount: any) =>
    new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD', // Change the currency code as needed
    }).format(amount);

const endpointReplace = (url: string, params?: { [key: string]: string }) =>
    url.replace(/{(\w+)}/g, (match: string, param: string) => {
        if (params && params.hasOwnProperty(param)) {
            return params[param];
        }
        return match; // Keep the original placeholder if the parameter is missing
    });

const handleNavigate = (user: any) => {
    if (user) {
        const accountStep = user.shops[0].account_step;
        const isPasswordSet = user.is_password_set;
        const requiredSteps = ['complete_account', 'company', 'working_hours', 'booking_payment', 'stripe_account', 'payment'];
        const allStepsComplete = requiredSteps.every((step) => (step === 'stripe_account' ? accountStep[step] === true || accountStep[step] === 'pending' : accountStep[step] === true));

        if (isPasswordSet === false) {
            return ROUTES.NEW_PASSWORD;
        }
        if (!allStepsComplete) {
            return ROUTES.SIGNUP;
        }
        return ROUTES.DASHBOARD;
    }
    return ROUTES.DASHBOARD;
};

const getResourceOptions = (
    data: Array<any>,
    first_name: string = 'first_name',
    last_name: string = 'last_name',
    id: string = 'id',
    profile_image_url: string = 'profile_image_url',
    role: string = 'role',
) => {
    if (!Array.isArray(data)) {
        data = [data];
    }
    return data.map((res) => ({
        name: `${res[first_name]} ${res[last_name]}`,
        staff_id: res[id],
        profile_image_url: res[profile_image_url],
        role: res[role].name,
    }));
};

const getSelectBoxOptions = (data: Array<any>, value: string = 'name', label: string = 'name', id: string = 'id', shortName: string = 'shortname') => {
    if (!Array.isArray(data)) {
        data = [data];
    }
    return data.map((res) => ({
        value: res[value],
        label: res[label],
        id: res[id],
        shortName: res[shortName],
    }));
};
const getSelectBoxOptionsIdAsValue = (data: Array<any>, value: string = 'id', label: string = 'name', id: string = 'id') => {
    if (!Array.isArray(data)) {
        data = [data];
    }
    return data.map((res) => ({
        value: res[value],
        label: res[label],
        id: res[id],
    }));
};
const getSelectBoxStaffOptionsIdAsValue = (data: Array<any>, value: string = 'id', first_name: string = 'first_name', last_name: string = 'last_name', id: string = 'id') => {
    if (!Array.isArray(data)) {
        data = [data];
    }
    return data.map((res) => ({
        value: res[value],
        label: `${res[first_name]} ${res[last_name]}`,
        id: res[id],
    }));
};

const convertBase64ToFile = (appLogoImage: any) => {
    try {
        const imageExtension = appLogoImage.substring(appLogoImage.indexOf('/') + 1, appLogoImage.indexOf(';base64'));

        const currentTimestamp = Date.now();
        const randomNum = Math.floor(1000000000 + Math.random() * 9000000000);
        const filename = `${currentTimestamp}_${randomNum}.${imageExtension}`;

        const base64Data = appLogoImage.replace(/^data:[^;]+;base64,/, '');

        const uint8Array = Uint8Array.from(atob(base64Data), (c) => c.charCodeAt(0));

        const blob = new Blob([uint8Array], {
            type: 'application/octet-stream',
        });

        const convertedFile = new File([blob], filename, {
            type: 'application/octet-stream',
        });
        return {
            convertedFile: convertedFile,
            filename: filename,
        };
    } catch (error) {
        // console.error('Error converting base64 to file:', error);
    }
};

const capitalizeFirstLetter = (str: string) => (str.length > 0 ? str[0].toUpperCase() + str.slice(1) : str);

const getShortName = (name: string) => {
    const shortName = name
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase())
        .join('');
    return shortName;
};

function capitalizeFirstLetterAndUnderscore(str: string) {
    const words = str.split('_');
    const capitalizedWords = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1));
    const result = capitalizedWords.join(' ');
    return result;
}
function capitalizeFirstLetterSpace(str: string) {
    const words = str.split(' ');
    const capitalizedWords = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1));
    const result = capitalizedWords.join(' ');
    return result;
}

const checkFileTypeValidation = (uploadedFile: any, size: any) => {
    const validationResults = _.map(uploadedFile, (file: File) => {
        if (!validType.includes(file.type)) {
            return {
                result: false,
                message: 'Invalid file format. Please upload a valid image file.',
            };
        } else if (file.size > size * 1024) {
            return {
                result: false,
                message: `Image upload failed. Please ensure your image is within the maximum allowed size of ${size}kb`,
            };
        } else {
            return { result: true, message: '' };
        }
    });

    const validationResult = _.every(validationResults, (result: { result: boolean }) => result.result === true);

    return {
        result: validationResult,
        message: validationResult ? '' : validationResults[0].message,
    };
};

const generateTimeRanges = (startTime: Date, endTime: Date, item: any) => {
    const ranges = [];

    const start = moment(startTime, 'YYYY-MM-DD HH:mm:ss');
    const end = moment(endTime, 'YYYY-MM-DD HH:mm:ss');

    while (start.isBefore(end)) {
        const nextEnd = moment.min(start.clone().endOf('day'), end);

        ranges.push({
            ...item,
            startTime: start.format('YYYY-MM-DD HH:mm:ss'),
            endTime: nextEnd.format('YYYY-MM-DD HH:mm:ss'),
            status: 'block_time',
        });

        start.add(1, 'day').startOf('day');
    }

    return ranges;
};

const dateFormatTZtoDate = (str: string) => moment(str, moment.ISO_8601).format('DD/MM/YYYY');

const changeDateformat = (str: string, fromFormat: string, toFormat: string) => moment(str, fromFormat).format(toFormat);

const isUpcomingStatus = ['no_show', 'request_canceled', 'pending'];

const getStatusClass = (status: string) => {
    if (isUpcomingStatus.includes(status)) {
        return 'text-yellow-400';
    } else if (status === 'canceled' || status === 'declined' || status === 'expired') {
        return 'text-dangerText';
    } else if (status === 'confirmed') {
        return 'text-primary';
    } else {
        return 'text-green';
    }
};

const getCardTypeImage = (cardType: string) => {
    switch (cardType?.toUpperCase()) {
        case EStripeCardType.VISA.toUpperCase():
            return visaCardIcon;
        case EStripeCardType.MASTER.toUpperCase():
            return masterCardIcon;
        case EStripeCardType.AMERICAN.toUpperCase():
            return americanCardIcon;
        case EStripeCardType.DINER.toUpperCase():
            return dinerCardIcon;
        case EStripeCardType.DISCOVER.toUpperCase():
            return discoverCardIcon;
        case EStripeCardType.JCB.toUpperCase():
            return jcbCardIcon;
        case EStripeCardType.UNION.toUpperCase():
            return unionCardIcon;
        default:
            return defaultCardIcon;
    }
};
function formatName(name: string) {
    if (name === null) {
        return null;
    }
    const words = name.split(' ');
    const firstWordCapitalized = words[0].charAt(0).toUpperCase() + words[0].slice(1);
    const lastNameInitial = words.length > 1 ? `${words[1].charAt(0).toUpperCase()}.` : '';
    return `${firstWordCapitalized} ${lastNameInitial}`;
}

function formatShortName(firstName: string, lastName: string) {
    if (firstName === null || lastName === null) {
        return null;
    }
    const formattedFirstName = firstName.length > 0 ? firstName[0].toUpperCase() + firstName.slice(1) : firstName;

    const formattedLastName = `${lastName.charAt(0).toUpperCase()}.`;

    return `${formattedFirstName} ${formattedLastName}`;
}

function checkFieldIsZero(array: [], fieldName: string) {
    return array.every((item) => parseFloat(item[fieldName]) === 0);
}

function formatKpiContentLable(num: any) {
    const roundedValue = Number(num).toFixed(2);
    return new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(Number(roundedValue));
}

export {
    logout,
    formateAmount,
    endpointReplace,
    handleNavigate,
    getSelectBoxOptions,
    convertBase64ToFile,
    capitalizeFirstLetter,
    getShortName,
    getSelectBoxOptionsIdAsValue,
    checkFileTypeValidation,
    capitalizeFirstLetterAndUnderscore,
    dateFormatTZtoDate,
    changeDateformat,
    getSelectBoxStaffOptionsIdAsValue,
    capitalizeFirstLetterSpace,
    getResourceOptions,
    generateTimeRanges,
    getStatusClass,
    getCardTypeImage,
    formatName,
    formatShortName,
    checkFieldIsZero,
    formatKpiContentLable,
};
